import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { StyledForm } from 'src/components/Settings/common/layout'
import {
  StyledColumnsContainer,
  StyledContent,
} from 'src/components/Settings/Business/styled'
import Input from 'src/stories/Input'
import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { MutationBusinessInfoProps } from 'src/containers/Settings/Business'
import { UnknownObject } from 'src/utils/interfaces'
import HelperTooltip, { HelperTooltipWrapper } from 'src/stories/HelperTooltip'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Body, Heading } from 'src/stories/typography'
import Checkbox from 'src/stories/Checkbox'

const addressFormSchema = yup.object({
  street1: yup.string().required('Missing street'),
  street2: yup.string().default(''),
  city: yup.string().required('Missing city'),
  state: yup.string().required('Missing state'),
  zip: yup.string().zipCode('Invalid zip code').required('Missing zip code'),
  publishAddress: yup.boolean().default(false),
})

type AddressFormSchema = yup.InferType<typeof addressFormSchema>

interface AddressFormProps extends MutationBusinessInfoProps {
  baseDataAttribute: string
}

const AddressForm: React.FC<AddressFormProps> = ({
  baseDataAttribute,
  isLoading,
  update,
  onSubmitFinished,
}) => {
  const { isMediumScreen } = useScreenSizes()
  const { activeLocationV3 } = useLocationContext()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors: formErrors },
    setError,
  } = useForm<AddressFormSchema>({
    resolver: yupResolver(addressFormSchema),
    defaultValues: {
      street1: activeLocationV3.address.street1,
      street2: activeLocationV3.address.street2 ?? undefined,
      city: activeLocationV3.address.city,
      state: activeLocationV3.address.state,
      zip: activeLocationV3.address.zip,
      publishAddress: activeLocationV3.address.publishAddress,
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    try {
      await update({
        address: {
          street1: data.street1,
          street2: data.street2,
          city: data.city,
          state: data.state,
          zip: data.zip,
        },
        publishAddress: data.publishAddress,
      })

      onSubmitFinished()
    } catch (err: unknown) {
      const propertyNames =
        (
          (err as UnknownObject | undefined)?.data as
            | UnknownObject<`instance.address.${keyof AddressFormSchema}`[]>
            | undefined
        )?.propertyName || []

      propertyNames.forEach((element) => {
        const propertyName = element.split('.').pop()

        if (propertyName) {
          setError(propertyName as keyof AddressFormSchema, {
            message: `Invalid ${propertyName}`,
            type: 'server',
          })
        }
      })
    }
  })

  const disableForm = isSubmitting || isLoading

  const inputBaseProps = {
    verticallySpace: 4,
    disabled: disableForm,
  }

  return (
    <StyledForm
      data-cy={`${baseDataAttribute}-form-address`}
      onSubmit={onSubmit}
    >
      <StyledContent>
        <div>
          <Heading as="h3">Address</Heading>
          <Body>By law, every marketing email needs a physical address.</Body>
        </div>
        <div>
          <Input
            {...inputBaseProps}
            label="Address line 1:"
            errors={formErrors}
            {...register('street1')}
          />
          <Input
            {...inputBaseProps}
            label="Address line 2 (optional):"
            errors={formErrors}
            {...register('street2')}
          />
          <StyledColumnsContainer columns={isMediumScreen ? 3 : 1}>
            <Input
              {...inputBaseProps}
              label="City:"
              errors={formErrors}
              {...register('city')}
            />
            <Input
              {...inputBaseProps}
              label="State:"
              errors={formErrors}
              {...register('state')}
            />
            <Input
              {...inputBaseProps}
              label="Zip code:"
              errors={formErrors}
              {...register('zip')}
            />
          </StyledColumnsContainer>
          <HelperTooltipWrapper>
            <Checkbox
              {...inputBaseProps}
              label="Show my business address to contacts"
              errors={formErrors}
              {...register('publishAddress')}
            />
            <HelperTooltip>
              Checking this option displays your address in email campaigns, as
              it is legally required in marketing emails. If hidden, a default
              address appears in the email footer.
            </HelperTooltip>
          </HelperTooltipWrapper>
        </div>
        <ButtonForm
          baseDataAttribute={`${baseDataAttribute}-form-address`}
          disabled={disableForm}
        />
      </StyledContent>
    </StyledForm>
  )
}

export default AddressForm
