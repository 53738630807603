import { Button, ButtonProps } from 'src/stories/Button'
import { useTheme } from 'styled-components'

interface ButtonFormProps extends Omit<ButtonProps, 'label'> {
  label?: string | JSX.Element
}

const ButtonForm: React.FC<ButtonFormProps> = ({
  label = 'Save',
  style,
  ...props
}) => {
  const theme = useTheme()

  return (
    <div>
      <Button
        label={label}
        type="submit"
        size="large"
        style={{
          width: 'auto',
          minWidth: theme.space(30),
          ...style,
        }}
        {...props}
      />
    </div>
  )
}

export default ButtonForm
