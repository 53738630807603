import { ContactLegacy } from '../../client/interfaces/Common'
import { FeedbackCategory } from './types'

export const categorizeFeedback = (
  isPositive: boolean,
  denominator = 5,
  summarize = true,
  rating?: number
): FeedbackCategory | undefined => {
  if (typeof rating === 'number') {
    const calcRating = (rating * 5) / denominator

    if (calcRating <= 1) {
      return `${summarize ? 'negative' : 'very negative'}`
    } else if (calcRating <= 2) {
      return 'negative'
    } else if (calcRating <= 3) {
      return 'neutral'
    } else if (calcRating <= 4) {
      return 'positive'
    } else if (calcRating <= 5) {
      return `${summarize ? 'positive' : 'very positive'}`
    }
  } else {
    if (isPositive) {
      return 'positive'
    } else {
      return 'negative'
    }
  }

  return
}

export const getContactName = (contact?: ContactLegacy) => {
  if (contact?.firstName) {
    return `${contact.firstName} ${contact.lastName}`
  } else if (contact?.primaryPhoneNumber) {
    return `${contact.primaryPhoneNumber}`
  } else if (contact?.primaryEmailAddress) {
    return contact.primaryEmailAddress
  }

  return ''
}
