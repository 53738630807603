import { AxiosInstance } from 'axios'

import { CustomizeIntantResponsesRequests } from '../interfaces/InstantResponses'
import Resource from '../resource'
import Constants from '../../lib/Constants'

const actions = (client: AxiosInstance): CustomizeIntantResponsesRequests => ({
  updateInstantResponse: (params) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations',
      method: 'updateLocationAutoresponse',
      params,
    })
  },

  persistVoiceResponse: ({ locationId, voiceResponse }) => {
    const data = new FormData()

    data.append('recordingFile', voiceResponse)

    return client.post(
      `/v3/locations/${locationId}/voicemail/recording`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },

  deleteVoiceResponse: ({ locationId }) => {
    return client.delete(`/v3/locations/${locationId}/voicemail/recording`)
  },
})

export default Resource(actions)
