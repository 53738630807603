import { useParams } from 'react-router-dom'
import useInsightsContext from 'src/contexts/InsightsContext'
import { getActiveLocation } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import logger from 'src/utils/logger'
import { formatContact } from './utils'

import useAccountContext from 'src/contexts/AccountContext'
import {
  formatFeedback,
  formatMessageConversion,
  formatSmsInteraction,
  formatSmsOfferClaim,
  formatReview,
  FormattedActivity,
  formatLinkClick,
  formatEmailOfferClaim,
  formatFacebookAdClaim,
  formatEmailOfferRedemption,
  formatActivityWithText,
  formatContactJobVersion,
  formatCustomerCreationRollup,
} from './formatters'

export const useFormattedCustomerActivities = (dataAttribute: string) => {
  const { customerActivities } = useInsightsContext()
  const { locationId, merchantId } = useParams<UseLocationRouteParams>()
  const { locations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)

  return customerActivities.reduce<FormattedActivity[]>((a, activity) => {
    const timestamp = activity.timestamp as string

    const contact = formatContact(activity, merchantId!, locationId!)

    // https://github.com/signpost/glaze/blob/176c07dd06fd7f8bf77a3d32fe7c9a4350713e9b/app/helpers/CustomerActivitiesHelpers.js#L51
    switch (activity.documentType) {
      case 'Feedback':
        a.push(formatFeedback(activity, timestamp, contact))
        break
      case 'SmsInteraction':
        if (activity.interactionType === 'incoming_sms') {
          a.push(formatSmsInteraction(activity, timestamp, contact))
        }
        break
      case 'MessageConversion':
        a.push(
          formatMessageConversion(activity, timestamp, contact, activeLocation)
        )
        break
      case 'SmsOfferClaim':
        a.push(formatSmsOfferClaim(activity, timestamp, contact))
        break
      case 'Review':
        if (
          [4, 5].includes(activity.rating as number) &&
          activeLocation?.locationReviewSites?.length
        ) {
          a.push(
            formatReview(
              activity,
              timestamp,
              contact,
              activeLocation?.locationReviewSites
            )
          )
        }
        break
      case 'LinkClick':
        if (
          ['review_site', 'facebook', 'website', 'map'].includes(
            activity.linkTarget as string
          )
        ) {
          a.push(
            formatLinkClick(
              activity,
              timestamp,
              contact,
              activeLocation?.locationReviewSites
            )
          )
        }
        break
      case 'EmailOfferClaim':
        if (activity.messageType === 'NCO') {
          a.push(formatEmailOfferClaim(activity, timestamp, contact))
        }
        break
      case 'FacebookAdClaim':
        a.push(formatFacebookAdClaim(activity, timestamp, contact))
        break
      case 'EmailOfferRedemption':
        a.push(formatEmailOfferRedemption(activity, timestamp, contact))
        break
      case 'CustomerCreationRollup':
        a.push(
          formatCustomerCreationRollup(
            activity,
            timestamp,
            merchantId!,
            locationId!
          )
        )
        break
      case 'RemarketingSms':
      case 'TestimonialsPageViews':
        // No need to handle since this types are not being queried.
        // https://github.com/signpost/rhubarb/blob/aeed53cafee927d9b4faf13c98dfceae2e752d4b/lib/Annotations.js#L824-L841
        break
      case 'ContactEstimateVersion':
      case 'ContactJobVersion':
        if (!activity.deleted) {
          a.push(formatContactJobVersion(activity, timestamp, contact))
        }
        break
      case 'CreditCardInteraction':
      case 'PaymentInteraction':
        a.push(formatActivityWithText(activity, timestamp, contact, 'Payment'))
        break
      case 'SmsOptInIvr':
        a.push(
          formatActivityWithText(
            activity,
            timestamp,
            contact,
            'has opted in to Text Marketing List'
          )
        )
        break
      case 'PrepaidOfferPurchase':
        a.push(
          formatActivityWithText(
            activity,
            timestamp,
            contact,
            `purchased ${activity.messageTitle as string}`
          )
        )
        break
      case 'RemarketingEmail': // No handling in dashboard page
      case 'SmsOfferRedemption': // No handling in dashboard page
      case 'PhoneCallInteraction': // No handling in dashboard page
      case 'EmailInteraction': // No handling in dashboard page
      case 'ContactCreated': // No handling in dashboard page
      case 'ContactEdit': // No handling in dashboard page
      case 'Unsubscribe': // No handling in dashboard page
      case 'AppointmentInteraction': // No handling in dashboard page
      case 'AgentzInteraction': // No handling in dashboard page
        break

      default:
        logger.error(
          'No handling for documentType: ' + (activity.documentType as string)
        )
        break
    }

    return a
  }, [])
}
