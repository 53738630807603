import { useQuery } from '@tanstack/react-query'
import { ConversationsResource } from 'src/client'
import { PaginationDTO, SearchDTO } from 'src/client/interfaces/Common'
import logger from 'src/utils/logger'

const useConversationsQuery = ({
  locationId,
  pagination,
  search,
}: {
  locationId: number
  pagination: PaginationDTO
  search?: SearchDTO
}) => {
  return useQuery({
    queryKey: [
      'conversations',
      {
        locationId,
        pagination,
        search,
      },
    ] as const,
    queryFn: async ({ queryKey }) =>
      ConversationsResource.findConversations(queryKey[1]),
    onError: (error) => {
      logger.error('WAF useConversationsQuery - error', { error })
    },
    staleTime: 60_000,
  })
}

export default useConversationsQuery
