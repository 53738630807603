import React from 'react'
import styled from 'styled-components'

import { Media } from 'src/client/interfaces/Common'
import { CloseIcon } from 'src/stories/assets'
import DisplayMedia from 'src/components/DisplayMedia'

const StyledAttachmentsContainer = styled.div(({ theme }) => ({
  height: theme.space(20),
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.space(2),
  gap: theme.space(2),
}))

const StyledAttachmentContainer = styled.div(({ theme }) => ({
  width: theme.space(20),
  display: 'flex',
  position: 'relative',
}))

const StyledImageContainer = styled.div(({ theme }) => ({
  width: theme.space(20),
  display: 'flex',
  justifyContent: 'center',
  border: '2px solid gray',
  borderRadius: '10px',
  position: 'relative',
  overflow: 'hidden',
}))

const StyledDisplayMedia = styled(DisplayMedia)(({ theme }) => ({
  maxWidth: `${theme.space(19)}`,
  maxHeight: `${theme.space(19)}`,
}))

const StyledCloseButtonContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.primary_2,
  position: 'absolute',
  width: theme.space(4),
  height: theme.space(4),
  top: `${theme.space(-1.5)}`,
  right: `${theme.space(-1.5)}`,
  borderRadius: '50%',
  cursor: 'pointer',
}))

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: theme.space(2.5),
  width: theme.space(2.5),
  position: 'relative',
  top: theme.space(0.625),
  right: theme.space(-0.75),
  fill: theme.colors.base_0,
}))

export interface AttachmentsProps {
  images: (Media & { name: string })[]
  onRemoveSelectedAttachment: (idx: number) => void
  shouldPreventPreviews?: boolean
}
const Attachments: React.FC<AttachmentsProps> = ({
  images,
  onRemoveSelectedAttachment,
  shouldPreventPreviews,
}) => {
  return (
    <StyledAttachmentsContainer>
      {images.map((image, index) => (
        <StyledAttachmentContainer
          data-cy={`mh-attachment-container-${index}`}
          data-content-type={`mh-attachment-content-${image.contentType}`}
          key={image.name}
        >
          <StyledImageContainer>
            <StyledDisplayMedia
              url={image.url}
              contentType={image.contentType}
              replaceForIcons
              preventOpenPreview={shouldPreventPreviews}
            />
          </StyledImageContainer>

          <StyledCloseButtonContainer
            data-cy={`mh-attachments-close-button-${index}`}
            onClick={() => onRemoveSelectedAttachment(index)}
          >
            <StyledCloseIcon />
          </StyledCloseButtonContainer>
        </StyledAttachmentContainer>
      ))}
    </StyledAttachmentsContainer>
  )
}

export default Attachments
