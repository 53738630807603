import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Button } from 'src/stories/Button'
import useMhContext from 'src/contexts/MhContext'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { formatPhoneNumber, generateLocationUrl, slugify } from 'src/utils'
import { toggleContactSubscription } from 'src/containers/MessagingHub/utils'
import { getContactSubscriptionState } from 'src/components/MessagingHub/utils'
import NoteSection from 'src/components/MessagingHub/ContactDetailsPane/NoteSection'
import ContactLists from 'src/components/MessagingHub/ContactDetailsPane/ContactListsSection'
import LifecycleSection from 'src/components/MessagingHub/ContactDetailsPane/LifecycleSection'
import ContactInfoSection from 'src/components/MessagingHub/ContactDetailsPane/ContactInfoSection'
import SubscriptionSection from 'src/components/MessagingHub/ContactDetailsPane/SubscriptionSection'

const Container = styled.div(({ theme }) => ({
  padding: theme.space(6),
  paddingTop: theme.space(3),
  overflowY: 'auto',
  height: 'auto',
}))

const Divider = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.base_10}`,
  marginTop: theme.space(4),
  marginBottom: theme.space(4),
}))

// Sections

const Section = styled.div(() => ({}))

const SectionHeader = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const SectionHeaderLabel = styled.p(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.colors.base_40,
  fontWeight: 500,
  fontSize: '1rem',
}))
const SectionContent = styled.div(() => ({}))

interface SectionInterface {
  label: string
  link?: { onClick?: () => void; href?: string; label?: string } | false
  content: ReactElement
}

// Component

const generateDataCy = (label: string) => `mh-contact-details-${label}`

const ContactDetailsPane: React.FC = () => {
  const theme = useTheme()
  const { merchantId, locationId } = useParams<UseLocationRouteParams>()

  const listsRef = useRef<HTMLButtonElement>(null)

  const {
    segments,
    openConversationContactDetails,
    openConversationId: customerId,
  } = useMhContext()

  const [contactSubscriptionState, setContactSubscriptionState] =
    useState(false)
  const [contactCanBeToggled, setContactCanBeToggled] = useState(false)
  const [isEditingLifecycle, setIsEditingLifecycle] = useState(false)
  const [isEditingTextArea, setIsEditingTextArea] = useState(false)

  const customerProfileUrl = useMemo(
    () =>
      generateLocationUrl(
        merchantId!,
        locationId!,
        `/customers/${customerId ?? ''}`
      ),
    [customerId, locationId, merchantId]
  )

  const handleToggleContactSubscription = useCallback(async () => {
    if (contactCanBeToggled) {
      setContactSubscriptionState((s) => !s)

      const result = await toggleContactSubscription({
        subscribed: !contactSubscriptionState,
        customerId: openConversationContactDetails.id!,
      })

      setContactSubscriptionState(result)
    }
  }, [
    contactSubscriptionState,
    contactCanBeToggled,
    openConversationContactDetails,
  ])

  const generateNotesButtonLabels = (isEditing: boolean, notes?: string) => {
    if (notes) {
      return isEditing ? 'Cancel' : 'Edit'
    }

    return isEditing ? 'Cancel' : 'Add'
  }

  useEffect(() => {
    setIsEditingLifecycle(false)
    setIsEditingTextArea(false)

    const contactSubscriptionData = getContactSubscriptionState(
      openConversationContactDetails
    )

    setContactSubscriptionState(contactSubscriptionData.toggleState)
    setContactCanBeToggled(contactSubscriptionData.canBeToggled)
  }, [openConversationContactDetails])

  const sections: SectionInterface[] = useMemo(
    () => [
      {
        label: 'Contact Info',
        link: {
          href: customerProfileUrl,
        },
        content: (
          <ContactInfoSection
            locationId={+locationId!}
            contactId={openConversationContactDetails.id!}
            name={openConversationContactDetails.name || ''}
            hasWrittenName={!!openConversationContactDetails.hasWrittenName}
            email={openConversationContactDetails.email || ''}
            phone={formatPhoneNumber(
              openConversationContactDetails.phoneNumber || ''
            )}
            source={openConversationContactDetails.source || ''}
          />
        ),
      },
      {
        label: 'Subscription',
        content: (
          <SubscriptionSection
            onToggle={handleToggleContactSubscription}
            isSubscribed={contactSubscriptionState}
            disabled={!contactCanBeToggled}
          />
        ),
        link: false,
      },
      {
        label: 'Lifecycle',
        link: {
          label: isEditingLifecycle ? 'Cancel' : 'Edit',
          onClick: () => setIsEditingLifecycle((prev) => !prev),
        },
        content: (
          <LifecycleSection
            contactId={openConversationContactDetails.id!}
            isEditingLifecycle={isEditingLifecycle}
            lifecycleStage={
              openConversationContactDetails.lifecycleStage || 'UNKNOWN'
            }
          />
        ),
      },
      {
        label: 'Contact Lists',
        link: { label: 'Edit' },
        content: (
          <ContactLists
            contactId={openConversationContactDetails.id!}
            editRef={listsRef}
            segments={segments}
            contactSegments={openConversationContactDetails.segmentIds ?? []}
            primarySegmentId={
              openConversationContactDetails.primarySegmentId ?? -1
            }
          />
        ),
      },
      {
        label: 'Note',
        link: {
          label: generateNotesButtonLabels(
            isEditingTextArea,
            openConversationContactDetails.notes
          ),
          onClick: () => setIsEditingTextArea(!isEditingTextArea),
        },
        content: (
          <NoteSection
            text={openConversationContactDetails.notes}
            lastUpdated={`${openConversationContactDetails.updatedAt!}`}
            isEditingTextArea={isEditingTextArea}
          />
        ),
      },
    ],
    [
      locationId,
      segments,
      customerProfileUrl,
      openConversationContactDetails,
      handleToggleContactSubscription,
      contactSubscriptionState,
      contactCanBeToggled,
      isEditingLifecycle,
      isEditingTextArea,
    ]
  )

  return (
    <Container data-cy={generateDataCy('content-container')}>
      {sections.map((section, idx) => {
        const sectionName = slugify(section.label)

        return (
          <React.Fragment key={section.label}>
            {idx !== 0 && (
              <Divider data-cy={generateDataCy(`divider-${idx}`)} />
            )}
            <Section data-cy={generateDataCy(sectionName)}>
              <SectionHeader data-cy={generateDataCy(`${sectionName}-header`)}>
                <SectionHeaderLabel
                  data-cy={generateDataCy(`${sectionName}-label`)}
                >
                  {section.label}
                </SectionHeaderLabel>
                {section.link !== false && (
                  <Button
                    reference={
                      section.label === 'Contact Lists' ? listsRef : undefined
                    }
                    baseDataAttribute={generateDataCy(
                      `${
                        section.link?.label?.toLocaleLowerCase() ||
                        'contact-page'
                      }-${sectionName}-link`
                    )}
                    displayAsText
                    label={section.link?.label || 'Contact page'}
                    style={{ height: theme.space(8) }}
                    href={section.link?.href}
                    onClick={section.link?.onClick}
                  />
                )}
              </SectionHeader>
              <SectionContent
                data-cy={generateDataCy(`${sectionName}-content`)}
              >
                {section.content}
              </SectionContent>
            </Section>
          </React.Fragment>
        )
      })}
    </Container>
  )
}

export default ContactDetailsPane
