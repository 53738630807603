import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { IntegrationsResource, LocationsResource } from 'src/client'
import { HTTPRequestError } from 'src/client/interfaces/Common'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import Constants from 'src/lib/Constants'
import { ProTipIcon } from 'src/stories/assets'
import { Button } from 'src/stories/Button'
import { HelperWidget } from 'src/stories/HelperWidget'
import Input from 'src/stories/Input'
import { Col, Row } from 'src/stories/Layout'
import { getActiveLocation } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import logger from 'src/utils/logger'

const NON_SERVICE_AREA_URL =
  'https://developers-dot-devsite-v2-prod.appspot.com/my-business/content/tools/placeid-lookup'
const SERVICE_AREA_URL = 'https://developers.google.com/places/place-id'

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  marginBottom: theme.space(3),
}))

const StyledCopy = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(6),
}))

const StyledForm = styled.form(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledHelperWidgetAnchor = styled.a(({ theme }) => ({
  display: 'flex',
  textDecoration: 'none',
}))

const StyledHelperWidgetCopy = styled.p(({ theme }) => ({
  color: theme.colors.primary_2,
  fontSize: '1.4rem',
  lineHeight: '2rem',
  margin: 0,
}))

interface ConnectGoogleMessagesForm {
  email: string
  fullName: string
  googlePlaceId: string
  website: string
}

const connectGoogleMessagesSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  fullName: yup.string().required('Full name is required'),
  googlePlaceId: yup.string().required('Google Place ID is required'),
  website: yup
    .string()
    .url('Please enter a valid website')
    .required('Website is required'),
})

interface ConnectAccountStepProps {
  nextStep: () => void
  previousStep: () => void
}

const ConnectAccountStep: React.FC<ConnectAccountStepProps> = ({
  nextStep,
  previousStep,
}) => {
  const { user } = useAuthContext()
  const { locationId } = useParams<UseLocationRouteParams>()
  const { locations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)

  const {
    formState: { errors: formErrors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<ConnectGoogleMessagesForm>({
    reValidateMode: 'onChange',
    defaultValues: {
      email: user.emailAddress || '',
      fullName: user ? `${user.firstName} ${user.lastName}` : '',
      googlePlaceId: activeLocation?.googlePlaceExternalId || '',
      website: activeLocation?.website || '',
    },
    resolver: yupResolver(connectGoogleMessagesSchema),
  })

  const areMatchingDomains = (email: string, website: string) => {
    const websiteRegex = website.match(/^(?:https?:)?(?:\/\/)?([^/?]+)/i)
    const websiteHostname = websiteRegex && websiteRegex[1]
    const websiteDomain = websiteHostname?.replace('www.', '').toLowerCase()
    const emailDomain = email.substring(email.indexOf('@') + 1).toLowerCase()

    return websiteDomain === emailDomain
  }

  const onSubmit = async ({
    email,
    fullName,
    googlePlaceId,
    website,
  }: ConnectGoogleMessagesForm) => {
    if (!areMatchingDomains(email, website)) {
      toast.error(
        'Google requires the website domain to be the same as used in your ' +
          'email address. Google does not allow emails from gmail.com or other ' +
          'email providers.'
      )

      return
    }

    try {
      const { externalId, integrationStatus, locationTestUrl } =
        await IntegrationsResource.connectGoogleAccount({
          locationId: activeLocation!.locationId,
          email,
          fullName,
          googlePlaceId,
          userId: user.id,
          website,
        })

      await IntegrationsResource.connectIntegration({
        integration: Constants.INTEGRATIONS.GOOGLE_MESSAGES,
        locationId: activeLocation!.locationId,
        syncConfig: {
          externalId,
          locationTestUrl,
        },
        status: integrationStatus || Constants.INTEGRATIONS.STATUS.ACTIVE,
      })

      await LocationsResource.toggleFeatureCode({
        enable: true,
        featureCode: Constants.FEATURE_CODES.syncGoogleMessages,
        locationId: activeLocation!.locationId,
      })

      await LocationsResource.toggleFeatureCode({
        enable: true,
        featureCode: Constants.FEATURE_CODES.sendGoogleMessagesAutoresponse,
        locationId: activeLocation!.locationId,
      })

      nextStep()
    } catch (error) {
      logger.debug(
        'Google Messages Integration Wizard - connect to Google Messages',
        { error }
      )

      const requestError = error as HTTPRequestError
      let errorMessage =
        'There was an error trying to connect to your Google account. Please try again.'

      if (requestError?.data?.errorMessage === 'INVALID_GBM_ID') {
        errorMessage =
          'Your Google Place ID is invalid. Please try again ' +
          'or reach out to customer service to get more assistance.'
      }

      toast.error(errorMessage)
    }
  }

  return (
    <Row>
      <Col w={2}>
        <StyledTitle>Let's connect your Google account</StyledTitle>

        <StyledCopy>
          Your full name and email will be used by Google to contact you to
          verify the integration and your website will be used for further
          validation.
        </StyledCopy>

        <StyledCopy>
          Google requires your email address domain, which is whatever follows
          the @, to match your website domain. For example name@signpost.com and
          signpost.com. If these do not match you are not eligible for this
          feature.
        </StyledCopy>

        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Input
                errors={formErrors}
                maxLength={255}
                label="Full name"
                {...register('fullName')}
              />
            </Col>
            <Col>
              <Input
                errors={formErrors}
                maxLength={255}
                label="Email:"
                {...register('email')}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                errors={formErrors}
                maxLength={255}
                label="Website:"
                {...register('website')}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                errors={formErrors}
                maxLength={255}
                label="Google Place ID:"
                {...register('googlePlaceId')}
              />
            </Col>
          </Row>

          <Row direction="row-reverse">
            <Col w={2} />
            <Col w={1}>
              <Button
                disabled={isSubmitting}
                label={isSubmitting ? 'Connecting...' : 'Connect'}
                type="submit"
              />
            </Col>
            <Col w={1}>
              <Button
                action="secondary"
                disabled={isSubmitting}
                label="Back"
                onClick={previousStep}
              />
            </Col>
          </Row>
        </StyledForm>
      </Col>

      <Col>
        <HelperWidget
          icon={ProTipIcon}
          title="How to find your Google Place ID"
        >
          <>
            <StyledHelperWidgetAnchor href={SERVICE_AREA_URL} target="_blank">
              <StyledHelperWidgetCopy>
                ➜ Google Place ID for service area locations
              </StyledHelperWidgetCopy>
            </StyledHelperWidgetAnchor>

            <StyledHelperWidgetAnchor
              href={NON_SERVICE_AREA_URL}
              target="_blank"
            >
              <StyledHelperWidgetCopy>
                ➜ Google Place ID for non-service area locations
              </StyledHelperWidgetCopy>
            </StyledHelperWidgetAnchor>
          </>
        </HelperWidget>
      </Col>
    </Row>
  )
}

export default ConnectAccountStep
