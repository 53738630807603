import React, { useMemo } from 'react'
import { Route, Routes as ReactRoutes } from 'react-router-dom'

import Home from 'src/containers/Home'
import Login from 'src/containers/Login'
import NotFound from 'src/components/NotFound'
import Logout from 'src/components/Logout/Logout'
import LegacyPages from 'src/containers/LegacyPages'
import useAuthContext from 'src/contexts/AuthContext'
import LocationRoutes from 'src/routes/LocationRoutes'
import { PrivateRoute } from 'src/routes/PrivateRoute'
import { LocationRouteParams } from 'src/utils/interfaces'
import useAccountContext from 'src/contexts/AccountContext'
import LoginCodeExample from 'src/containers/LoginCodeExample'
import MultiLocationRoutes from 'src/routes/MultiLocationsRoute'
import ThumbtackOauthRedirect from 'src/components/ThumbtackOauthRedirect'
import { ConversationsContextProvider } from 'src/contexts/ConversationsListContext'
import { ConversationMessagesContextProvider } from 'src/contexts/ConversationMessagesContext'
import { LocationContextProvider } from 'src/contexts/LocationContext'

const Routes: React.FC = () => {
  const { user } = useAuthContext()
  const { locations } = useAccountContext()

  const allowedLocations = useMemo<LocationRouteParams[]>(
    () =>
      locations?.map(({ locationId, merchantId }) => ({
        locationId: String(locationId),
        merchantId: String(merchantId),
      })) || [],
    [locations]
  )

  return (
    <ReactRoutes>
      <Route path="/" element={<Home />} />
      <Route path="/login-code/:loginCode" element={<LoginCodeExample />} />
      <Route path="/login" element={<Login />} />

      <Route
        path="/user/:userId/*"
        element={
          <PrivateRoute
            allowedParams={[{ userId: user ? String(user.id) : '' }]}
            validateRouteParams={['userId']}
          >
            <MultiLocationRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path="/:merchantId/locations/:locationId/*"
        element={
          <PrivateRoute
            allowedParams={allowedLocations}
            validateRouteParams={['locationId', 'merchantId']}
          >
            {/* LocationContextProvider should be here which is the earliest place */}
            {/* where we have certainty that the `locationId` from the URL exists. */}
            <LocationContextProvider>
              <ConversationsContextProvider>
                <ConversationMessagesContextProvider>
                  <LocationRoutes />
                </ConversationMessagesContextProvider>
              </ConversationsContextProvider>
            </LocationContextProvider>
          </PrivateRoute>
        }
      />

      <Route
        path="/terms"
        element={
          <PrivateRoute
            allowedParams={allowedLocations}
            validateRouteParams={[]}
          >
            <LegacyPages />
          </PrivateRoute>
        }
      />
      <Route
        path="/oauth2-redirect-thumbtack"
        element={<ThumbtackOauthRedirect />}
      />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<NotFound />} />
    </ReactRoutes>
  )
}

export default Routes
