import { PaginationDTO, SearchDTO } from './Common'

export type ConversationEventType =
  | 'FEEDBACK'
  | 'REVIEW'
  | 'PUBLIC_REVIEW'
  | 'AGENTZ'
  | 'FACEBOOK'
  | 'GOOGLE'
  | 'ANGILEADS' // Formerly known as HomeAdvisor
  | 'LIVE_RECEPTIONIST'
  | 'PHONE_CALL'
  | 'SMS'
  | 'THUMBTACK'

export type InterviewJsonColumn = {
  question: string
  answer: string
}[]

export interface ConversationEventResourceFailureDetails {
  reason: string
}

export interface ConversationEventThumbtackMessageResource {
  text?: string
}

export interface ConversationEventThumbtackLeadResource {
  category?: string
  title?: string
  description?: string
  schedule?: string
  location?: {
    city: string
    state: string
    zipCode: string
  }
  travelPreferences?: string
  details?: {
    question: string
    answer: string
  }[]
}

export interface ConversationEventThumbtackResource {
  lead?: ConversationEventThumbtackLeadResource
  message?: ConversationEventThumbtackMessageResource
}

export interface ConversationEventLiveReceptionistResource {
  callSummaryDisplayFields?: {
    label?: string
    value?: string
  }[]
  s3Path?: string
  recordingUrl?: string
  recordingDeletedAt?: Date | null
}

export interface ConversationEventAgentzResource {
  replyUrl?: string
  type?: string
  asReplyTo?: number
}

export interface ConversationEventGoogleResource {
  asReplyTo?: number
}

export interface ConversationEventHomeadvisorResource {
  interview?: InterviewJsonColumn
  leadDescription?: string
  taskName?: string
  comments?: string
}

export interface ConversationEventMediaResource {
  url: string
  contentType: string
}

export interface ConversationEventReviewResource {
  rating?: number
  reviewSiteDisplayName?: string
  url?: string
}

export interface ConversationEventFeedbackResource {
  rating?: number
}

export interface ConversationEventPhonecallResource {
  duration?: number
  recordingUrl?: string
  transcriptionText?: string
}

export interface ConversationEventResource {
  id: number
  channelId: number | null
  eventType: ConversationEventType
  message: string
  timestamp?: Date
  eventDirection: 'incoming' | 'outgoing'
  isAutoResponse: boolean
  fingerprint?: string
  failureDetails?: ConversationEventResourceFailureDetails
  hasPhoneCallRecording: boolean
  phoneCallEventData?: ConversationEventPhonecallResource
  feedbackEventData?: ConversationEventFeedbackResource
  reviewEventData?: ConversationEventReviewResource
  publicReviewEventData?: ConversationEventReviewResource
  hasMedia: boolean
  media: ConversationEventMediaResource[]
  agentzEventData?: ConversationEventAgentzResource
  googleEventData?: ConversationEventGoogleResource
  angiLeadsEventData?: ConversationEventHomeadvisorResource
  liveReceptionistData?: ConversationEventLiveReceptionistResource
  thumbtackEventData?: ConversationEventThumbtackResource
}

export interface SegmentResource {
  id: number
  locationId: number
  name: string
  removedAt: string
  createdAt?: string
  updatedAt?: string
}

export interface ChannelResource {
  id: number
}

export interface ContactResource {
  id: number
  subscribed?: boolean
  segments: SegmentResource[]
  primaryEmailAddress?: string
  primaryPhoneNumber?: string
  firstName?: string
  lastName?: string
  addedAt: string
  channels: ChannelResource[]
}

export interface ConversationResource {
  id: number
  resourceType: 'ConversationResource'
  contact: ContactResource
  mostRecentEvent: ConversationEventResource
  contactId: number
  contactDisplayName?: string
  isConversationRead: boolean
  isConversationArchived: boolean
  isConversationDeleted: boolean
}

export interface ConversationsResponseDTO {
  data: ConversationResource[]
  total: number
}

/**
 * NOTE: As today 03-April-2023 this interface is not finished yet and
 * might change in the future, you can visit the controller's getOneEvent endpoint in the
 * main-api for further information:
 * https://github.com/signpost/main-api/blob/main/src/application/conversations/conversations.controller.ts#L78
 */
export interface ConversationEventResponse {
  channelId: number | null
  eventType: ConversationEventType
  message: string
  eventDirection: 'incoming' | 'outgoing'
  timestamp: Date
  isAutoResponse?: boolean
  liveReceptionistData?: ConversationEventLiveReceptionistResource
}

export interface PublicReviewsDTO {
  id: number
  locationId: number
  reviewSiteId: number
  reviewSiteName: string
  reviewSiteUrl: string
  reviewUrl: string
  authorName: string
  rating: number
  reviewText: string
  timestamp: Date
  hiddenFromUi: boolean
  drivenBySignpost: boolean
}

export interface PublicReviewsResponseDTO {
  data: PublicReviewsDTO[]
}

export interface ConversationEventsResponseDTO {
  total: number
  data: ConversationEventResource[]
}

export interface ConversationsQueryParameters {
  locationId: number
  pagination: PaginationDTO
  search?: SearchDTO
}

export interface PublicReviewsQueryParameters {
  locationId: number
  pagination: PaginationDTO
  reviewSites?: number[]
}

export interface ConversationEventsQueryParameters {
  locationId: number
  contactId: number
}

export interface ConversationEventDetailsParameters {
  locationId: number
  conversationId: number
  eventFingerprint: string
}

type EventDataKey =
  | 'phoneCallEventData'
  | 'feedbackEventData'
  | 'reviewEventData'
  | 'publicReviewEventData'
  | 'agentzEventData'
  | 'googleEventData'
  | 'angiLeadsEventData'
  | 'liveReceptionistData'
  | 'thumbtackEventData'

type ConversationEventResourceTypeFilter<
  T extends EventDataKey | '' = '',
  U extends EventDataKey = EventDataKey
> = Omit<ConversationEventResource, Exclude<U, T>> & {
  [P in T extends '' ? never : T]-?: Exclude<
    ConversationEventResource[P],
    undefined
  >
}

export type ConversationEventResourceType<T extends ConversationEventType> = {
  FEEDBACK: ConversationEventResourceTypeFilter<'feedbackEventData'>
  REVIEW: ConversationEventResourceTypeFilter<'reviewEventData'>
  PUBLIC_REVIEW: ConversationEventResourceTypeFilter<'publicReviewEventData'>
  AGENTZ: ConversationEventResourceTypeFilter<'agentzEventData'>
  FACEBOOK: ConversationEventResourceTypeFilter
  GOOGLE: ConversationEventResourceTypeFilter<'googleEventData'>
  ANGILEADS: ConversationEventResourceTypeFilter<'angiLeadsEventData'>
  LIVE_RECEPTIONIST: ConversationEventResourceTypeFilter<'liveReceptionistData'>
  PHONE_CALL: ConversationEventResourceTypeFilter<'phoneCallEventData'>
  SMS: ConversationEventResourceTypeFilter
  THUMBTACK: ConversationEventResourceTypeFilter<'thumbtackEventData'>
}[T] & { eventType: T }

export const conversationEventTypePredicate = <T extends ConversationEventType>(
  val: ConversationEventResource,
  type: T
): val is ConversationEventResourceType<T> => {
  return val.eventType === type
}

export interface ConversationsRequests {
  findConversations: (
    parameters: ConversationsQueryParameters
  ) => Promise<ConversationsResponseDTO>

  findPublicReviews: (
    parameters: PublicReviewsQueryParameters
  ) => Promise<PublicReviewsResponseDTO>

  findConversationEvents: (
    parameters: ConversationEventsQueryParameters
  ) => Promise<ConversationEventsResponseDTO>

  /**
   * Get details for a specific event in a conversation
   * @param parameters ContactsListParameters
   * @param parameters.locationId The location ID to gather the conversation event details
   * @param parameters.conversationId The conversation ID to gather the conversation details
   * @param parameters.eventFingerprint The event fingerprint
   * @returns
   */
  findConversationEvent: (
    parameters: ConversationEventDetailsParameters
  ) => Promise<ConversationEventResponse>
}
