import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useLocationContext } from 'src/contexts/LocationContext'
import Modal from 'src/stories/Modal'
import Input from 'src/stories/Input'
import PhoneNumberInput from 'src/stories/PhoneNumberInput'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import Select, { SelectOption } from 'src/stories/Select'
import Constants from 'src/lib/Constants'
import { getLifecycleDisplayLabel } from 'src/utils'
import { useEffect, useMemo, useState } from 'react'
import {
  useContactDetailsQuery,
  useCreateContactMutation,
  useSetContactLifecycleMutation,
  useSendContactReviewRequestMutation,
} from 'src/client'
import { useReviewSiteInformation } from 'src/hooks/useReviewSiteInformation'
import Checkbox from 'src/stories/Checkbox'

const Form = styled.form(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.space(6),
  marginBottom: theme.space(6),
}))

const StyledColumnsContainer = styled.div<{ columns: number }>(
  ({ theme, columns }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    columnGap: theme.space(3),
    rowGap: theme.space(6),
  })
)

const lifecycles: SelectOption[] = Object.values(
  Constants.CUSTOMERS.LifecycleStage
).filter(
  (stage) => stage.value !== Constants.CUSTOMERS.LifecycleStage.UNKNOWN.value
)

const formSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Please enter a valid email').default(''),
  phoneNumber: yup.string().phoneNumber().default(''),
  lifecycleStage: yup.string().default(lifecycles[0].value),
  sendReviewRequest: yup.boolean().default(true),
})

type FormSchema = yup.InferType<typeof formSchema>

interface Props {
  onHide: () => void
}

const AddContactModal: React.FC<Props> = ({ onHide }) => {
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const { locationId, activeLocation } = useLocationContext()
  const { reviewSiteName } = useReviewSiteInformation(activeLocation)

  const [ReviewRequestContactId, setReviewRequestContactId] = useState<number>()
  const { data: dataContactDetails } = useContactDetailsQuery(
    {
      locationId,
      contactId: ReviewRequestContactId!,
    },
    { enabled: !!ReviewRequestContactId }
  )
  const { mutateAsync: mutateCreateContact } = useCreateContactMutation()
  const { mutateAsync: mutateLifecycle } =
    useSetContactLifecycleMutation(locationId)
  const { mutate: mutateReviewRequest, isLoading: isLoadingReviewRequest } =
    useSendContactReviewRequestMutation()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    control,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(
    async ({ name, email, phoneNumber, lifecycleStage, sendReviewRequest }) => {
      if (!phoneNumber && !email)
        return toast.error(
          'Fill in at least one email or phone number contact field. Please try again.'
        )

      const contacts = []

      if (phoneNumber) contacts.push({ phoneNumber })
      if (email) contacts.push({ emailAddress: email })

      await mutateCreateContact(
        {
          locationId,
          name,
          contacts,
        },
        {
          onSuccess: async ({ customerId }) => {
            await mutateLifecycle({ customerId, lifecycleStage })

            if (sendReviewRequest) setReviewRequestContactId(customerId)
            else onHide()
          },
        }
      )
    }
  )

  useEffect(() => {
    if (dataContactDetails?.preferredManualCampaignChannelId)
      mutateReviewRequest(
        {
          contactId: dataContactDetails?.id,
          channelId: dataContactDetails.preferredManualCampaignChannelId,
          reviewSiteName,
          method:
            dataContactDetails.channels.find(
              ({ id }) =>
                id === dataContactDetails.preferredManualCampaignChannelId
            )?.type === 'phone'
              ? 'sms'
              : 'email',
          productOrigin: 'contactsPage',
        },
        {
          onSettled: () => onHide(),
        }
      )
  }, [dataContactDetails, mutateReviewRequest, onHide, reviewSiteName])

  const lifecycleOptions: SelectOption[] = useMemo(
    () =>
      lifecycles.map(({ value, label }) => {
        return {
          value,
          label: activeLocation
            ? getLifecycleDisplayLabel(activeLocation, value)
            : label,
        }
      }),
    [activeLocation]
  )

  const baseInputProps = {
    errors,
    verticallySpaced: false,
  }

  return (
    <Modal
      modalDataCy="contacts-add-contact"
      modalTitle="Add Contact"
      modalCanClose
      handleClose={onHide}
      modalActionsOptions={{
        callToAction: {
          label: 'Add Contact',
          onClick: onSubmit,
          // TODO: Change the disabled approach to avoid enabling the button before the useEffect runs
          shouldDisable: () => isSubmitting || isLoadingReviewRequest,
        },
      }}
    >
      <Form onSubmit={onSubmit}>
        <Input label="Name:" {...baseInputProps} {...register('name')} />
        <StyledColumnsContainer columns={isDesktop ? 2 : 1}>
          <Input
            label="Email:"
            type="email"
            {...baseInputProps}
            {...register('email')}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <PhoneNumberInput
                label="Phone number:"
                {...baseInputProps}
                {...field}
              />
            )}
          />
        </StyledColumnsContainer>
        <Controller
          control={control}
          name="lifecycleStage"
          render={({ field, formState }) => {
            return (
              <Select
                label="Lifecycle:"
                options={lifecycleOptions}
                errorText={formState.errors.lifecycleStage?.message}
                onChange={(value) => {
                  field.onChange(value)
                }}
                initialValue={field.value}
              />
            )
          }}
        />
        {activeLocation.locationReviewSites.filter(
          (reviewSite) => !reviewSite.removed
        ).length > 0 && (
          <Checkbox
            label="Send review request now"
            defaultChecked
            {...baseInputProps}
            {...register('sendReviewRequest')}
          />
        )}
      </Form>
    </Modal>
  )
}

export default AddContactModal
