import React from 'react'
import styled from 'styled-components'
import { Row } from 'src/stories/Layout'
import Toggle from 'src/stories/Toggle'

const StyledToggleContainer = styled(Row)(({ theme }) => ({
  justifyContent: 'flex-between',
  alignItems: 'center',
  marginTop: theme.space(2),
}))

const StyledSubscriptionStatus = styled.label(({ theme }) => ({
  fontWeight: 400,
  fontSize: '1.3rem',
  color: theme.colors.base_100,
}))

interface Props {
  isSubscribed: boolean
  disabled?: boolean
  onToggle: () => void
}

const generateDataCy = (label: string) =>
  `mh-contact-details-subscription-${label}`

const SubscriptionSection: React.FC<Props> = ({
  isSubscribed,
  onToggle,
  disabled,
}) => {
  return (
    <StyledToggleContainer>
      <StyledSubscriptionStatus data-cy={generateDataCy('status')}>
        {isSubscribed
          ? 'Subscribed to marketing campaigns'
          : 'Not subscribed to marketing campaigns'}
      </StyledSubscriptionStatus>
      <Toggle
        dataCy={generateDataCy(`toggle-${isSubscribed ? 'on' : 'off'}`)}
        onChange={onToggle}
        checked={isSubscribed}
        disabled={disabled}
      />
    </StyledToggleContainer>
  )
}

export default SubscriptionSection
