import { Route, Routes, useLocation } from 'react-router-dom'

import { BaseLegacyPageProps } from 'src/utils/interfaces'
import useSidebarRoutes from 'src/routes/hooks'
import WafLayout, { WafLayoutProps } from 'src/components/WafLayout'
import LegacyPages from 'src/containers/LegacyPages'
import { getLocationPreferredTerms } from 'src/utils'
import ConversionCampaigns from './Conversion'
import { useLocationContext } from 'src/contexts/LocationContext'

type CampaignsProps = BaseLegacyPageProps

const Campaigns: React.FC<CampaignsProps> = ({ isMultiLocation }) => {
  const location = useLocation()
  const { merchantId, locationId, activeLocation } = useLocationContext()
  const locationPreferredTerms = getLocationPreferredTerms(activeLocation)

  const campaignsPages: string[] = ['conversion']

  const { campaignSidebarRoutes } = useSidebarRoutes({
    merchantId,
    locationId,
    consumerTerms: locationPreferredTerms.consumerTerms,
  })

  const isValidPath = campaignsPages.some((path) =>
    location.pathname.includes(path)
  )

  const layoutProps: Omit<WafLayoutProps, 'children'> = {
    sidebarTitle: 'Campaigns',
    sidebarRoutes: campaignSidebarRoutes,
    styleOptions: {
      removePadding: !isValidPath,
      withMultiLocationHeader: isMultiLocation,
      invertedBackground: isValidPath,
    },
  }

  return (
    <Routes>
      {localStorage.getItem('SP_HAS_CONVERSION_CAMPAIGN_PAGE') && (
        <Route
          path="conversion"
          element={
            <WafLayout {...layoutProps} displayFooter>
              <ConversionCampaigns />
            </WafLayout>
          }
        />
      )}
      <Route
        path="*"
        element={
          <WafLayout {...layoutProps}>
            <LegacyPages
              withinLayout
              withMultiLocationHeader={isMultiLocation}
            />
          </WafLayout>
        }
      />
    </Routes>
  )
}

export default Campaigns
