import { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { LocationLegacy } from 'src/client/interfaces/Common'
import { getActiveLocation } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import useAccountContext from './AccountContext'
import useLocation from 'src/client/hooks/queries/useLocationQuery'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { Location } from 'src/client/interfaces/Locations'

interface LocationContextInterface {
  locationId: number
  merchantId: number
  activeLocation: LocationLegacy
  activeLocationV3: Location
}

export const LocationContext = createContext<LocationContextInterface>(
  {} as LocationContextInterface
)

export const useLocationContext = () => useContext(LocationContext)

export const LocationContextProvider: React.FCWithChildren = ({ children }) => {
  const params = useParams<UseLocationRouteParams>()
  const { locations } = useAccountContext()

  const locationId =
    params.locationId && !isNaN(+params.locationId)
      ? +params.locationId
      : undefined

  const merchantId =
    params.merchantId && !isNaN(+params.merchantId)
      ? +params.merchantId
      : undefined

  // Since it is a hook it has to come before any return statement.
  // Using non-null operator since the hook itself is disabled if locationId is not defined
  const { data: activeLocationV3, isLoading } = useLocation({
    locationId: locationId!,
  })

  if (!locationId || !merchantId || !activeLocationV3) {
    return null
  }

  const activeLocation = getActiveLocation(locations, locationId.toString())

  if (!activeLocation) {
    return null
  }

  return (
    <LocationContext.Provider
      value={{ locationId, merchantId, activeLocation, activeLocationV3 }}
    >
      {!isLoading ? children : <LoadingSpinner />}
    </LocationContext.Provider>
  )
}
