import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import LegacyPages from '../LegacyPages'
import AngiLeadsConnectionWizard from 'src/components/Integrations/AngiLeads/ConnectionWizard'
import GoogleMessagesConnectionWizard from 'src/components/Integrations/GoogleMessages/ConnectionWizard'
import ThumbtackConnectionWizard from 'src/components/Integrations/Thumbtack/ConnectionWizard'
import { BaseLegacyPageProps } from 'src/utils/interfaces'

type IntegrationsProps = BaseLegacyPageProps

const Integrations: React.FC<IntegrationsProps> = ({ isMultiLocation }) => {
  const location = useLocation()
  const [locationHash, setLocationHash] = useState<string>('')

  useEffect(() => {
    setLocationHash(location.hash)
  }, [location.hash])

  switch (locationHash) {
    case '#angi-leads':
      return <AngiLeadsConnectionWizard />
    case '#google-messages':
      return <GoogleMessagesConnectionWizard />
    case '#thumbtack':
      return <ThumbtackConnectionWizard />
    default:
      // Using Glaze's LegacyPages to handle 404
      return <LegacyPages withMultiLocationHeader={isMultiLocation} />
  }
}

export default Integrations
