import React from 'react'
import { useParams } from 'react-router-dom'

import {
  FeedbacksCampaignsWidget,
  FeedbacksCountWidget,
  RecentCustomerActivityWidget,
  RecentFeedbacksWidget,
  RecentReviewsWidget,
  ReviewsCampaignsWidget,
  ReviewsCountByReviewSiteWidget,
  ReviewsRatingByReviewSiteBreakdownWidget,
  ReviewsRatingByReviewSiteWidget,
  SummaryWidget,
  LRCallDetailsWidget,
  LRCallsByWeekdayWidget,
  LRCallsByWrapupCodeWidget,
  TotalInstantResponsesSentWidget,
  TotalContactsAddedFromLeadSourcesWidget,
} from 'src/components/Insights/Widgets'
import { generateLocationUrl } from 'src/utils'
import WafLayout from 'src/components/WafLayout'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import useInsightsContext from 'src/contexts/InsightsContext'
import InsightsContent from 'src/components/Insights/Content'
import { InsightsContentContainer } from 'src/components/Insights/styled'
import ReviewsBanner from 'src/components/Insights/Banners/ReviewsBanner'
import InboundLeadsPerMonthWidget from 'src/components/Insights/Widgets/InboundLeadsPerMonthWidget'
import RecentCustomerActivityBanner from 'src/components/Insights/Banners/RecentCustomerActivityBanner'

interface InsightsProps {
  isMultiLocation: boolean
}

const Insights: React.FC<InsightsProps> = ({ isMultiLocation }) => {
  const { merchantId, locationId } = useParams<UseLocationRouteParams>()
  const {
    customerActivities,
    isCustomerActivitiesLoading,
    reviewSiteData,
    isFeedbackLoading,
    shouldShowLiveReceptionist,
  } = useInsightsContext()
  const googleReviewSite = reviewSiteData.find(
    (rs) => rs.displayName === 'Google'
  )

  const isReviewCampaignWidgetInZeroState =
    !googleReviewSite || googleReviewSite.isZeroState
  const isCustomerActivityWidgetInZeroState = !customerActivities.length

  return (
    <WafLayout
      layoutTitle="Insights"
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
        removePadding: false,
        showDropShadow: true,
      }}
      displayFooter
    >
      <InsightsContentContainer>
        <InsightsContent
          title="Review Campaign"
          hasMore={!isReviewCampaignWidgetInZeroState}
          banner={
            isReviewCampaignWidgetInZeroState && !isFeedbackLoading ? (
              <ReviewsBanner baseDataAttribute="insights-signpost-review-campaign-banner" />
            ) : undefined
          }
          content={{
            elements: [
              <ReviewsRatingByReviewSiteWidget baseDataAttribute="insights-reviews-rating-by-review-site" />,
              <ReviewsCountByReviewSiteWidget baseDataAttribute="insights-reviews-count-by-review-site" />,
              <FeedbacksCountWidget baseDataAttribute="insights-feedbacks-count" />,
              <SummaryWidget baseDataAttribute="insights-summary" />,
            ],
          }}
          additionalContent={{
            elements: [
              {
                element: (
                  <ReviewsRatingByReviewSiteBreakdownWidget baseDataAttribute="insights-reviews-rating-by-review-site-breakdown" />
                ),
                rowStart: 1,
                rowEnd: 3,
              },
              <RecentReviewsWidget
                baseDataAttribute="insights-recent-reviews"
                link={generateLocationUrl(
                  merchantId!,
                  locationId!,
                  `/emails/review-us`
                )}
              />,
              <RecentFeedbacksWidget
                baseDataAttribute="insights-recent-feedbacks"
                link={generateLocationUrl(
                  merchantId!,
                  locationId!,
                  `/emails/get-feedback`
                )}
              />,
              <ReviewsCampaignsWidget baseDataAttribute="insights-reviews-campaigns" />,
              <FeedbacksCampaignsWidget baseDataAttribute="insights-feedbacks-campaigns" />,
            ],
            columns: 3,
          }}
        />
        {shouldShowLiveReceptionist && (
          <InsightsContent
            title="Live Receptionist"
            content={{
              elements: [
                <LRCallDetailsWidget baseDataAttribute="insights-live-receptionist-call-details" />,
                <LRCallsByWeekdayWidget baseDataAttribute="insights-live-receptionist-calls-by-weekday" />,
                <LRCallsByWrapupCodeWidget baseDataAttribute="insights-live-receptionist-calls-by-wrapup-code" />,
              ],
            }}
          />
        )}
        <InsightsContent
          title="Inbound Leads"
          content={{
            elements: [
              <TotalInstantResponsesSentWidget baseDataAttribute="insights-inbound-leads-total-instant-response" />,
              <InboundLeadsPerMonthWidget baseDataAttribute="insights-inbound-leads-per-month" />,
              <TotalContactsAddedFromLeadSourcesWidget baseDataAttribute="insights-inbound-leads-total-contacts-added-from-lead-sources" />,
            ],
          }}
        />
        <InsightsContent
          title="Recent Customer Activity"
          banner={
            isCustomerActivityWidgetInZeroState &&
            !isCustomerActivitiesLoading ? (
              <RecentCustomerActivityBanner baseDataAttribute="insights-signpost-recent-customer-activity-banner" />
            ) : undefined
          }
          content={{
            elements: [
              <RecentCustomerActivityWidget baseDataAttribute="insights-recent-customer-activity" />,
            ],
          }}
        />
      </InsightsContentContainer>
    </WafLayout>
  )
}

export default Insights
