import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ConnectAccountStep from './ConnectAccountStep'
import OverviewStep from './OverviewStep'
import SetupCompleteStep from './SetupCompleteStep'
import { useHomeAdvisorIntegrationData } from 'src/containers/Settings/InstantResponders/hooks'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import Wizard from 'src/stories/Wizard'
import { getActiveLocation, generateLocationUrl } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'

const AngiLeadsConnectionWizard: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const navigate = useNavigate()
  const { locationId, merchantId } = useParams<UseLocationRouteParams>()
  const { user } = useAuthContext()
  const { locations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)
  const { isConnected } = useHomeAdvisorIntegrationData({
    activeLocation,
    userId: user.id,
  })

  const nextStep = () => setCurrentIndex((index) => index + 1)
  const previousStep = () => setCurrentIndex((index) => index - 1)
  const exitWizard = () => {
    const instantRespondersSettingsSection = generateLocationUrl(
      merchantId!,
      locationId!,
      '/settings#section-settings-instant-responders'
    )

    navigate(instantRespondersSettingsSection)
  }

  // If Angi Leads integration is connected we redirect to the IR screen
  if (isConnected) {
    exitWizard()
  }

  const stepLabels = [{ label: 'Overview' }, { label: 'Enter account info' }]
  const stepContent = [
    <OverviewStep nextStep={nextStep} />,
    <ConnectAccountStep nextStep={nextStep} previousStep={previousStep} />,
    <SetupCompleteStep nextStep={exitWizard} />,
  ]

  return (
    <Wizard
      title="Connect Angi"
      onClickGoBack={exitWizard}
      steps={stepLabels}
      currentStepIndex={currentIndex}
      onClickStep={(index, step) => setCurrentIndex(index)}
      disabled={currentIndex === 2}
    >
      {stepContent[currentIndex]}
    </Wizard>
  )
}

export default AngiLeadsConnectionWizard
