import styled from 'styled-components'
import { useEffect, useState } from 'react'

import LoadingSpinner from 'src/stories/LoadingSpinner'
import usePageNotAvailableModal from 'src/hooks/usePageNotAvailableModal'
import useNavigationInterceptorContext from 'src/contexts/NavigationInterceptor'

interface LegacyPagesContainerProps {
  $withinLayout?: boolean
  $withMultiLocationHeader?: boolean
  $withoutHeader?: boolean
  $addStatusBarHeight?: boolean
}

const LegacyPagesContainer = styled.div<LegacyPagesContainerProps>(
  ({
    theme,
    $withinLayout,
    $withMultiLocationHeader,
    $withoutHeader,
    $addStatusBarHeight,
  }) => {
    let height = theme.heights.navbarHeightUnits
    let defaultHeight = '0px'

    if ($withMultiLocationHeader) {
      height += theme.heights.multiLocationHeaverHeightUnits
    }

    if ($addStatusBarHeight && !$withinLayout) {
      height += theme.heights.layoutStatusBar
      defaultHeight = theme.space(theme.heights.layoutStatusBar)
    }

    return {
      height: `calc(100% - ${
        $withinLayout || $withoutHeader ? defaultHeight : theme.space(height)
      })`,
      overflow: 'hidden',
    }
  }
)

const IFrame = styled.iframe({
  border: 'none',
})

interface LegacyPagesProps {
  withinLayout?: boolean
  withMultiLocationHeader?: boolean
  withoutHeader?: boolean
  withStatusBar?: boolean
}

const LegacyPages: React.FC<LegacyPagesProps> = ({
  withinLayout,
  withMultiLocationHeader,
  withoutHeader,
  withStatusBar,
}) => {
  const { iframeReady, iframeSrc, handleUnmountIframe, iframeRef } =
    useNavigationInterceptorContext()
  const { shouldShowModal } = usePageNotAvailableModal({ url: iframeSrc })

  const [loadedIframe, setLoadedIframe] = useState(true)

  useEffect(() => {
    return () => {
      handleUnmountIframe()
    }
  }, [handleUnmountIframe])

  useEffect(() => {
    setLoadedIframe(false)
  }, [iframeSrc])

  return (
    <>
      {shouldShowModal ? null : (
        <LegacyPagesContainer
          id="legacy-pages-container"
          data-cy="legacy-pages-container"
          $withinLayout={withinLayout}
          $withMultiLocationHeader={withMultiLocationHeader}
          $withoutHeader={withoutHeader}
          $addStatusBarHeight={withStatusBar}
        >
          {!loadedIframe && <LoadingSpinner />}

          {iframeReady && (
            <IFrame
              data-cy="legacy-app-iframe"
              ref={iframeRef}
              style={{ visibility: loadedIframe ? 'visible' : 'hidden' }}
              title="Glaze Legacy Page"
              src={iframeSrc}
              width="100%"
              height="100%"
              onLoad={() => setLoadedIframe(true)}
            />
          )}
        </LegacyPagesContainer>
      )}
    </>
  )
}

export default LegacyPages
