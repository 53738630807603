import { formatRelative } from 'date-fns'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import useInsightsContext from 'src/contexts/InsightsContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import Tooltip from 'src/stories/Tooltip'
import { generateLocationUrl } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { StyledNameIconTextContainer } from '../styled'
import { InsightsWidget } from './InsightsWidget'
import {
  StyledIconTextContainer,
  StyledTimestamp,
  StyledWidgetBlackText,
  StyledWidgetContentContainer,
} from './styled'
import { WidgetProps } from './types'
import Link from 'src/stories/Link'
import { Body } from 'src/stories/typography'

interface Props extends WidgetProps {
  link: string
}

const StyledMostRecentFeedback = styled.div<{ isExpandable: boolean }>(
  ({ isExpandable, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    border: isExpandable ? `2px solid ${theme.colors.critical}` : undefined,
    borderRadius: isExpandable ? theme.constants.borderRadius : 'none',
    padding: isExpandable
      ? [theme.space(3), theme.space(4)].join(' ')
      : 'unset',
    backgroundColor: isExpandable ? theme.colors.base_3 : 'unset',
  })
)

const StyledMostRecentFeedbackContent = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
}))

const StyledMostRecentFeedbackExpandedContent = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
})

const RecentFeedbacksWidget: React.FC<Props> = ({
  link,
  baseDataAttribute,
}) => {
  const { recentFeedbacks, isFeedbackLoading } = useInsightsContext()
  const { merchantId, locationId } = useParams<UseLocationRouteParams>()
  const theme = useTheme()
  const [isHoveringFbText, setIsHoveringFbText] = useState(false)
  const {
    is2ExtraLargeScreen,
    isExtraLargeScreen,
    isLargeScreen,
    isMediumScreen,
    is2ExtraSmallScreen,
    isExtraSmallScreen,
  } = useScreenSizes()

  const isIconTextHidingFunction = () => {
    if (!is2ExtraLargeScreen && !isExtraLargeScreen && isLargeScreen) {
      return true
    }

    if (
      !is2ExtraLargeScreen &&
      !isExtraLargeScreen &&
      !isLargeScreen &&
      isMediumScreen
    ) {
      return true
    }

    if (
      !is2ExtraLargeScreen &&
      !isExtraLargeScreen &&
      !isLargeScreen &&
      isMediumScreen
    ) {
      return true
    }

    if (is2ExtraSmallScreen && !isExtraSmallScreen) {
      return true
    }

    return false
  }

  const isIconTextHiding = isIconTextHidingFunction()

  if (isFeedbackLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  return (
    <InsightsWidget
      baseDataAttribute={baseDataAttribute}
      title="Most recent feedback"
      headerElement={
        <Button
          baseDataAttribute={baseDataAttribute + '-show-all-feedbacks'}
          label="➜ Show all feedback"
          href={link}
          displayAsText
          style={{ height: theme.space(5) }}
        />
      }
    >
      <StyledWidgetContentContainer
        $gapUnits={3}
        data-cy={baseDataAttribute + '-list'}
      >
        {recentFeedbacks.map((rfb, idx) => {
          const dataCy = `${baseDataAttribute}-feedback-${idx}`

          return (
            <StyledMostRecentFeedback
              key={`feedback-item-${idx}`}
              data-cy={dataCy + '-container'}
              isExpandable={rfb.isExpandable}
            >
              <StyledMostRecentFeedbackContent data-cy={dataCy}>
                <StyledNameIconTextContainer
                  data-cy={dataCy + '-icon-text-container'}
                >
                  <Body size="large">
                    <Link
                      baseDataAttribute={'name'}
                      href={rfb.contactProfileLink}
                    >
                      {rfb.name}
                    </Link>
                  </Body>
                  <StyledIconTextContainer data-cy={dataCy + '-sentiment'}>
                    {rfb.component}
                    {!isIconTextHiding && (
                      <StyledWidgetBlackText data-cy={dataCy + '-text'}>
                        {rfb.sentiment}
                      </StyledWidgetBlackText>
                    )}
                  </StyledIconTextContainer>
                </StyledNameIconTextContainer>
                <StyledTimestamp data-cy={dataCy + '-timestamp'}>
                  {formatRelative(new Date(rfb.timestamp), new Date())}
                </StyledTimestamp>
              </StyledMostRecentFeedbackContent>
              {rfb.isExpandable && (
                <StyledMostRecentFeedbackExpandedContent>
                  <Tooltip
                    fontSize="1.2rem"
                    text={rfb.feedbackText ? `“${rfb.feedbackText}”` : ''}
                    bottom="30%"
                    color={theme.colors.base_100}
                    fontWeight={300}
                    isActive={
                      !!rfb.feedbackText &&
                      !!(rfb.feedbackText.length > 85 && isHoveringFbText)
                    }
                    onMouseOver={() => setIsHoveringFbText(true)}
                    onMouseLeave={() => setIsHoveringFbText(false)}
                  >
                    <StyledWidgetBlackText
                      lighterColor
                      hideOverflow
                      data-cy={dataCy + '-detailed-text'}
                      onMouseOver={() => setIsHoveringFbText(true)}
                      onMouseLeave={() => setIsHoveringFbText(false)}
                    >
                      {rfb.feedbackText && <>&quot;{rfb.feedbackText}&quot;</>}
                    </StyledWidgetBlackText>
                  </Tooltip>
                  <Body>
                    {rfb.hasPhoneNumber ? (
                      <Link
                        href={generateLocationUrl(
                          merchantId!,
                          locationId!,
                          `/messaging-hub?open-conversation=${rfb.contactId}`
                        )}
                        baseDataAttribute={'respond-now'}
                      >
                        {'➜ Respond now'}
                      </Link>
                    ) : (
                      <Link
                        href={`mailto:${rfb.email}`}
                        baseDataAttribute={'email-customer'}
                      >
                        {'➜ Email this customer directly to respond'}
                      </Link>
                    )}
                  </Body>
                </StyledMostRecentFeedbackExpandedContent>
              )}
            </StyledMostRecentFeedback>
          )
        })}
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default RecentFeedbacksWidget
