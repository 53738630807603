import { useCallback, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import LeadInstantResponseSettings from './LeadInstantResponseSettings'
import logger from 'src/utils/logger'
import Constants from 'src/lib/Constants'
import { getActiveLocation, generateLocationUrl } from 'src/utils'
import useAccountContext from 'src/contexts/AccountContext'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { IntegrationsResource, LocationsResource } from 'src/client'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { useInstantResponder } from 'src/containers/Settings/InstantResponders/hooks'
import DisconnectConfirmationModal from '../DisconnectConfirmationModal'

interface ThumbtackInstantResponseSettingsProps {
  customIR: string | null
  isConnected: boolean
  isEnabled: boolean
  integrationStatus?: string
  integrationUpdatedAt: string | null
}

const ThumbtackInstantResponseSettings: React.FC<
  ThumbtackInstantResponseSettingsProps
> = ({
  isConnected,
  isEnabled,
  customIR,
  integrationStatus,
  integrationUpdatedAt,
}) => {
  const [isDisconnecting, setIsDisconnecting] = useState(false)

  const { locationId, merchantId } = useParams<UseLocationRouteParams>()
  const { locations, handleRefetchUserLocations } = useAccountContext()
  const { showModal, closeModal } = useModalNotificationsContext()
  const activeLocation = getActiveLocation(locations, locationId)
  const navigate = useNavigate()

  const { customIRMessage, isToggleEnabled, onToggle, onUpdateMessage } =
    useInstantResponder({
      customIR,
      isEnabled,
      locationId: activeLocation?.locationId,
      refetchLocationFn: handleRefetchUserLocations,
      source: 'thumbtack',
    })

  const handleOnConnect = () => {
    const thumbtackConnectionWizardUrl = generateLocationUrl(
      merchantId!,
      locationId!,
      '/integrations/connect#thumbtack'
    )

    navigate(thumbtackConnectionWizardUrl)
  }

  const handleConfirmDisconnect = useCallback(async () => {
    if (!activeLocation) return

    closeModal()

    setIsDisconnecting(true)

    try {
      await IntegrationsResource.disconnectIntegration({
        integration: Constants.INTEGRATIONS.THUMBTACK,
        locationId: activeLocation.locationId,
      })

      await LocationsResource.toggleFeatureCode({
        enable: false,
        featureCode: Constants.FEATURE_CODES.syncThumbtack,
        locationId: activeLocation.locationId,
      })

      await handleRefetchUserLocations()

      toast.success('Successfully disconnected')
    } catch (error) {
      logger.debug('Thumbtack Responder - disconnect Thumbtack', { error })
      toast.error(
        'There was an error trying to disconnect you from Thumbtack. Please try again.'
      )
    } finally {
      setIsDisconnecting(false)
    }
  }, [activeLocation, closeModal, handleRefetchUserLocations])

  const handleDisconnect = useCallback(() => {
    showModal({
      title: 'Disconnect Thumbtack',
      headerColor: 'critical',
      hideActionButtons: false,
      modalActionsOptions: {
        callToAction: {
          label: 'Disconnect',
          action: 'danger',
          onClick: handleConfirmDisconnect,
        },
      },
      customBody: <DisconnectConfirmationModal leadSourceName="Thumbtack" />,
      height: 'auto',
    })
  }, [handleConfirmDisconnect, showModal])

  return (
    <>
      <LeadInstantResponseSettings
        connected={isConnected}
        defaultMessage={Constants.INSTANT_RESPONSES.thumbtack.defaultMessage}
        message={customIRMessage}
        enabled={isToggleEnabled}
        onEnablementChange={onToggle}
        onUpdateMessage={onUpdateMessage}
        leadSourceName="Thumbtack"
        onClickConnect={handleOnConnect}
        onClickDisconnect={handleDisconnect}
        isDisconnecting={isDisconnecting}
        maxLength={160}
        integrationStatus={integrationStatus}
        connectable
        integrationUpdatedAt={integrationUpdatedAt}
        informationMessage="Responses will be sent as Thumbtack Messages. You can view these conversations in your Messaging Hub or Thumbtack App."
      />
    </>
  )
}

export default ThumbtackInstantResponseSettings
