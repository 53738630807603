import { CircularProgress } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const LoadingSpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export interface LoadingSpinnerProps {
  logoHeight?: string
  logoWidth?: string
}

const LoadingSpinner: React.FC<Partial<LoadingSpinnerProps>> = ({
  logoHeight = '40',
  logoWidth = '40',
}) => {
  return (
    <LoadingSpinnerContainer data-cy="loading-spinner-container">
      <div data-cy="loading-spinner">
        <CircularProgress
          style={{
            fill: '#318CE6',
            width: `${logoWidth}px`,
            height: `${logoHeight}px`,
          }}
        />
      </div>
    </LoadingSpinnerContainer>
  )
}

export default LoadingSpinner
