import { useParams } from 'react-router-dom'
import Highcharts, { Point } from 'highcharts'
import { useQuery } from '@tanstack/react-query'
import HighchartsReact from 'highcharts-react-official'
import { renderToStaticMarkup } from 'react-dom/server'
import styled, { ThemeProvider, useTheme } from 'styled-components'

import {
  StyledWidgetContentContainer,
  StyledWidgetMainStat,
  StyledMainStatContainer,
  StyledLegendItem,
} from 'src/components/Insights/Widgets/styled'
import { Button } from 'src/stories/Button'
import { InsightsResource } from 'src/client'
import { generateLocationUrl } from 'src/utils'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { WidgetProps } from 'src/components/Insights/Widgets/types'
import { InsightsWidget } from 'src/components/Insights/Widgets/InsightsWidget'
import { ReactComponent as ZeroStateBanner } from 'src/assets/contacts-added-from-lead-sources-zero-state.svg'
import { mockDemoTotalContactsAddedFromLeadSources } from 'src/contexts/InsightsContext/demo-data'

const StyledZeroContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.space(2),
}))

const StyledZeroText = styled.p(({ theme }) => ({
  fontWeight: 400,
  fontSize: '1.4rem',
  textAlign: 'center',
  width: '95%',
  margin: 0,
  marginTop: theme.space(4),
  marginBottom: theme.space(6),
}))

const StyledChartContainer = styled.div(({ theme }) => ({
  display: 'inline-grid',
}))

interface TransformedData {
  data: { name: string; y: number }[]
  total: number
}

const TotalContactsAddedFromLeadSourcesWidget: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()

  const { locationId, merchantId } = useParams<UseLocationRouteParams>()

  const { isLargeScreen, isExtraLargeScreen } = useScreenSizes()

  const isDemoLocation =
    !!locationId && locationId === process.env.REACT_APP_DEMO_LOCATION_ID

  const {
    data: contactsFromLeadSources,
    isError,
    isLoading,
  } = useQuery({
    queryKey: [
      'contactsFromLeadSourcesResponsesSent',
      {
        locationId,
      },
    ],
    queryFn: async () => {
      const { data } = await InsightsResource.getContactsFromLeadSources(
        +locationId!
      )

      let transformedData = data.reduce<TransformedData>(
        (acc, { source, amount }) => {
          // Note: If updating this format, also update the demo data used below.
          acc.data.push({ name: source, y: amount })
          acc.total += amount

          return acc
        },
        {
          data: [],
          total: 0,
        }
      )

      if (isDemoLocation) {
        transformedData = mockDemoTotalContactsAddedFromLeadSources
      }

      return transformedData
    },
    staleTime: 60_000,
  })

  const colors = {
    primary_1: theme.colors.primary_1,
    primary_2: theme.colors.primary_2,
    primary_1_45: theme.colors.primary_1_45,
    primary_1_25: theme.colors.primary_1_25,
    primary_1_10: theme.colors.primary_1_10,
    base_20: theme.colors.base_20,
  }

  const options: Highcharts.Options = {
    title: {
      text: '',
    },
    colors: Object.values(colors),
    chart: {
      type: 'pie',
      marginTop: !(!isExtraLargeScreen && isLargeScreen) ? 0 : 30,
      height: 200,
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2"><b>Lead Source</b></th></tr>',
      pointFormat:
        '<tr><td style="color: #666666">{point.name} {point.percentage:.0f}%</td></tr>',
      footerFormat: '</table>',
    },
    legend: {
      enabled: !(!isExtraLargeScreen && isLargeScreen),
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginBottom: 8,
      labelFormatter() {
        return renderToStaticMarkup(
          <ThemeProvider theme={theme}>
            <StyledLegendItem
              name={this.name}
              percentage={(this as Point).percentage ?? 0}
            />
          </ThemeProvider>
        )
      },
      useHTML: true,
      symbolRadius: 0,
    },
    plotOptions: {
      pie: {
        cursor: 'pointer',
        dataLabels: {
          enabled: !isExtraLargeScreen && isLargeScreen,
        },
        showInLegend: !(!isExtraLargeScreen && isLargeScreen),
      },
    },
    series: [
      {
        name: 'totalContactsAddedFromLeadSourcesWidgetPieChart',
        colorByPoint: true,
        data: contactsFromLeadSources?.data.sort((a, b) => b.y - a.y),
        type: 'pie',
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
        },
      },
    ],
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <InsightsWidget
      baseDataAttribute={baseDataAttribute}
      addDivider
      title="Total Contacts added from lead sources"
      subtitle={
        isError || contactsFromLeadSources?.data?.length === 0 ? undefined : (
          <StyledMainStatContainer
            data-cy={baseDataAttribute + '-main-stat-container'}
          >
            <StyledWidgetMainStat data-cy={baseDataAttribute + '-main-stat'}>
              {contactsFromLeadSources?.total}
            </StyledWidgetMainStat>
          </StyledMainStatContainer>
        )
      }
    >
      <StyledWidgetContentContainer data-cy={baseDataAttribute + '-pie-chart'}>
        {isError || contactsFromLeadSources?.data?.length === 0 ? (
          <StyledZeroContainer>
            <ZeroStateBanner style={{ marginTop: theme.space(2) }} />
            <StyledZeroText>
              Connect your Google, Facebook, Angis, and Thumbtack account and
              start tracking your lead source performance.
            </StyledZeroText>
            <Button
              label="Connect lead sources"
              baseDataAttribute={baseDataAttribute + '-connect-lead-sources'}
              href={generateLocationUrl(
                merchantId!,
                locationId!,
                `/settings#section-settings-instant-responders`
              )}
              style={{
                maxWidth: theme.space(55),
              }}
            />
          </StyledZeroContainer>
        ) : (
          <StyledChartContainer>
            <Button
              label="➜ Connect more lead sources"
              baseDataAttribute={baseDataAttribute + '-to-messaging-hub'}
              href={generateLocationUrl(
                merchantId!,
                locationId!,
                `/settings#section-settings-instant-responders`
              )}
              displayAsText
              style={{
                height: theme.space(5),
              }}
            />
            <HighchartsReact highcharts={Highcharts} options={options} />
          </StyledChartContainer>
        )}
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default TotalContactsAddedFromLeadSourcesWidget
