import React from 'react'
import styled, { useTheme } from 'styled-components'

import useInsightsContext from 'src/contexts/InsightsContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { InsightsWidget } from './InsightsWidget'
import {
  StyledIconTextContainer,
  StyledMainStatContainer,
  StyledWidgetBlackText,
  StyledWidgetContentContainer,
  StyledWidgetMainStat,
  StyledWidgetStat,
} from './styled'
import { WidgetProps } from './types'

const StyledSecondaryTotalFeedbackContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const StyledSecondaryTotalFeedbackInnerContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const FeedbacksCountWidget: React.FC<WidgetProps> = ({ baseDataAttribute }) => {
  const {
    feedbackSiteData,
    allTimeFeedbackCount,
    isFeedbackZeroState,
    isFeedbackLoading,
  } = useInsightsContext()
  const theme = useTheme()

  if (isFeedbackLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  return (
    <InsightsWidget
      addDivider
      baseDataAttribute={baseDataAttribute}
      title="Total Feedback"
      subtitle={
        <StyledMainStatContainer
          data-cy={baseDataAttribute + '-main-stat-container'}
        >
          <StyledWidgetMainStat
            useSecondaryColor={isFeedbackZeroState}
            data-cy={baseDataAttribute + '-main-stat'}
          >
            {isFeedbackZeroState
              ? '\u2013'
              : allTimeFeedbackCount.toLocaleString()}
          </StyledWidgetMainStat>
        </StyledMainStatContainer>
      }
    >
      <StyledWidgetContentContainer
        data-cy={baseDataAttribute + '-sentiments'}
        $gapUnits={4}
      >
        {feedbackSiteData &&
          Object.keys(feedbackSiteData).map((fsd, idx) => {
            const dataCy = `${baseDataAttribute}-${fsd}`

            return (
              <StyledSecondaryTotalFeedbackContainer
                data-cy={dataCy}
                key={`feedback-count-by-sentiment-item-${idx}`}
              >
                <StyledIconTextContainer data-cy={dataCy + '-label'}>
                  {
                    feedbackSiteData[fsd as keyof typeof feedbackSiteData]
                      .component
                  }
                  <StyledWidgetBlackText data-cy={dataCy + '-sentiment'}>
                    {
                      feedbackSiteData[fsd as keyof typeof feedbackSiteData]
                        .label
                    }
                  </StyledWidgetBlackText>
                </StyledIconTextContainer>
                <StyledSecondaryTotalFeedbackInnerContainer
                  data-cy={dataCy + '-stat'}
                >
                  <StyledWidgetStat
                    useSecondaryColor={isFeedbackZeroState}
                    data-cy={dataCy + '-feedback-count'}
                  >
                    {isFeedbackZeroState
                      ? '\u2013'
                      : feedbackSiteData[
                          fsd as keyof typeof feedbackSiteData
                        ].value.toLocaleString()}
                  </StyledWidgetStat>
                </StyledSecondaryTotalFeedbackInnerContainer>
              </StyledSecondaryTotalFeedbackContainer>
            )
          })}
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default FeedbacksCountWidget
