import React, {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import * as braze from '@braze/web-sdk'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation, useNavigate } from 'react-router-dom'

import logger from 'src/utils/logger'
import { AuthResource } from 'src/client'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import useSessionCookies from 'src/hooks/useSessionCookies'
import { isSafari } from 'src/utils'
import { HTTPRequestError, UserInterface } from 'src/client/interfaces/Common'

interface HandleLoginParams {
  email: string
  password: string
}
interface HandleForgotPasswordParams {
  email: string
  sendMagicLink?: boolean
}
interface HandleTokenLoginParams {
  token: string | null
  userEmailFingerprint: string | null
  oneTimePassword: string | null
}

interface AuthContextProps {
  loading: boolean
  user: UserInterface
  setIsLoading: React.Dispatch<SetStateAction<boolean>> | undefined
  handleLogin: (creds: HandleLoginParams) => Promise<void>
  handleTokenLogin: (creds: HandleTokenLoginParams) => Promise<void>
  handleTokenLogout: () => void
  handleLoginWithRedirect: () => void
  handleLogout: () => void
  refetchUser: () => Promise<void>
  fetchUserDataWithToken: (token: string) => Promise<void>
  handleForgotPassword: (params: HandleForgotPasswordParams) => Promise<void>
}

export const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps
)

export const AuthContextProvider: React.FCWithChildren = ({ children }) => {
  const navigate = useNavigate()

  const { logout, getAccessTokenSilently, loginWithRedirect, isLoading } =
    useAuth0()
  const { getSessionCookies, setSessionCookies, removeSessionCookies } =
    useSessionCookies()
  const { pathname, search } = useLocation()

  const [loading, setIsLoading] = useState(true)
  const [user, setUser] = useState<UserInterface>()

  const handleBrazeUnsub = useCallback(() => {
    try {
      if (
        process.env.REACT_APP_BRAZE_ID &&
        !isSafari(window.navigator) &&
        user &&
        braze.isPushSupported() &&
        !braze.isPushBlocked() &&
        braze.isPushPermissionGranted()
      ) {
        braze.unregisterPush()
      }
    } catch (err) {
      logger.info('Braze was not previously initialized')
    }
  }, [user])

  const handleAuth0Logout = useCallback(() => {
    void logout()
  }, [logout])

  const handleLogout = useCallback(async () => {
    try {
      await AuthResource.logout()
      handleBrazeUnsub()
    } catch (error) {
      logger.debug(
        `Logout resource failed. This is most likely because sessions were not provided: ${JSON.stringify(
          error
        )}`
      )
    }

    removeSessionCookies({ sid: true, rmsid: true, bearer: true })

    setUser(undefined)

    const excemptLogoutRedirections = ['/login', '/logout', '/']
    const loginRedirection = new URL('/login', window.location.origin)

    if (!excemptLogoutRedirections.includes(pathname)) {
      const currentUrl = new URL(pathname + search, window.location.origin)

      loginRedirection.searchParams.append('redirect', currentUrl.toString())
    }

    logger.debug('Handling logout, about to navigate to:', { loginRedirection })
    navigate(loginRedirection.pathname + loginRedirection.search)
  }, [navigate, pathname, search, handleBrazeUnsub, removeSessionCookies])

  const handleTokenLogout = useCallback(() => {
    try {
      handleAuth0Logout()
      handleBrazeUnsub()
      removeSessionCookies({ sid: true, rmsid: true, bearer: true })

      setUser(undefined)
    } catch (error) {
      logger.debug(
        `Logout resource failed. This is most likely because sessions were not provided: ${JSON.stringify(
          error
        )}`
      )
    }
  }, [handleBrazeUnsub, removeSessionCookies, handleAuth0Logout])

  const fetchUserDataWithToken = useCallback(
    async (token: string) => {
      try {
        setIsLoading(true)
        const { user: authorizedUser } = await AuthResource.grant({
          bearer: token,
        })

        setUser(authorizedUser)
      } catch (error) {
        handleTokenLogout()

        logger.error(
          `Failed to retrieve user with bearer token: ${JSON.stringify(error)}`
        )
      } finally {
        setIsLoading(false)
      }
    },
    [handleTokenLogout]
  )

  const fetchUserData = useCallback(
    async (rmsid: string, sid: string) => {
      try {
        const grantParams = {
          rememberMeSessionId: rmsid,
          sessionId: sid,
        }

        logger.debug('fetchUserData - before grant, sid', {
          sid: !!sid,
          sidLength: sid?.length,
        })
        logger.debug('fetchUserData - before grant, rmsid', {
          rmsid: !!rmsid,
          rmsidLength: rmsid?.length,
        })
        const { user: authorizedUser, sessionId: grantSessionId } =
          await AuthResource.grant(grantParams)

        setSessionCookies({ loginSessionId: grantSessionId })

        setUser(authorizedUser)

        logger.debug('fetchUserData - authorizedUser', { authorizedUser })
      } catch (error) {
        if ((error as HTTPRequestError)?.status === 401) {
          logger.debug('fetchUserData', { error })

          await handleLogout()
        } else {
          logger.error('fetchUserData', { error })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [handleLogout, setSessionCookies]
  )

  const handleLogin = async ({ email, password }: HandleLoginParams) => {
    logger.debug('Starting login')
    try {
      const {
        sessionId: loginSessionId,
        rememberMeSessionId: loginRememberMeSessionId,
      } = await AuthResource.login({
        email,
        password,
        rememberMe: true,
      })

      setSessionCookies({ loginSessionId, loginRememberMeSessionId })

      // fetchUserData will call setIsLoading
      await fetchUserData(loginRememberMeSessionId, loginSessionId)
    } catch (error) {
      setIsLoading(false)
      throw error
    }
  }

  const handleForgotPassword = async ({
    email,
    sendMagicLink,
  }: HandleForgotPasswordParams) => {
    await AuthResource.forgotPassword({ email, sendMagicLink })
  }

  const handleLoginWithRedirect = useCallback(() => {
    // If users are trying no navigate to any page of the MC,
    // a redirect param will be appended to the login URL.
    // The pathnames mentioned below are excluded when attaching
    // the redirect param to the login url
    const excemptRedirections = ['/login', '/logout', '/']
    const loginRedirection = new URL('/login', window.location.origin)
    const extraParams: Record<string, string> = {}

    if (!excemptRedirections.includes(pathname)) {
      const currentUrl = new URL(pathname + search, window.location.origin)

      loginRedirection.searchParams.append('redirect', currentUrl.toString())
    }

    if (pathname === '/login') {
      // If logged out users are landing on the login page, any of the
      // extra params below will be passed as extra params to Auth0's Login page.
      const allowedExtraParams = [
        'isSignup',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
      ]
      // The rest of the params mentioned below will be kept as part of the redirection URL
      const allowedRedirectParams = [...allowedExtraParams, 'page', 'redirect']
      const searchParams = new URLSearchParams(search)

      allowedRedirectParams.forEach((param) => {
        if (searchParams.has(param))
          loginRedirection.searchParams.append(param, searchParams.get(param)!)
      })

      allowedExtraParams.forEach((param) => {
        if (searchParams.has(param))
          extraParams[param] = searchParams.get(param)!
      })
    }

    void loginWithRedirect({
      authorizationParams: {
        redirect_uri: loginRedirection.toString(),
        ...extraParams,
      },
    })
  }, [loginWithRedirect, pathname, search])

  const handleTokenLogin = useCallback(
    async ({
      token,
      userEmailFingerprint,
      oneTimePassword,
    }: HandleTokenLoginParams) => {
      let loginSessionId, loginRememberMeSessionId

      logger.debug('Handling token login', {
        token,
        userEmailFingerprint,
        oneTimePassword,
      })

      if (oneTimePassword) {
        const { sessionId: newSID, rememberMeSessionId: newRMSID } =
          await AuthResource.loginOtp({
            otpEmailFingerprint: oneTimePassword,
          })

        loginSessionId = newSID
        loginRememberMeSessionId = newRMSID
      } else {
        let grantParams

        if (token) {
          grantParams = { oneTimeToken: token }
        } else if (userEmailFingerprint) {
          grantParams = { fingerprint: userEmailFingerprint }
        } else {
          throw new Error(
            'Token, userEmailFingerprint or otpEmailFingerprint is required for login'
          )
        }
        const { sessionId: newSID, rememberMeSessionId: newRMSID } =
          await AuthResource.grant(grantParams)

        loginSessionId = newSID
        loginRememberMeSessionId = newRMSID
      }

      setSessionCookies({ loginSessionId, loginRememberMeSessionId })

      await fetchUserData(loginRememberMeSessionId, loginSessionId)
    },
    [fetchUserData, setSessionCookies]
  )

  const verifyAuth = useCallback(async () => {
    try {
      const { bearer: foundBearer } = getSessionCookies()

      logger.debug(`From application Cookies: `, {
        wasRetreived: !!foundBearer,
      })

      const bearer = foundBearer ?? (await getAccessTokenSilently())

      logger.debug(`From Auth0 SDK: `, { wasRetreived: !!bearer })

      setSessionCookies({
        bearer,
      })

      if (bearer) {
        await fetchUserDataWithToken(bearer)
      } else {
        setIsLoading(false)
      }
    } catch (err) {
      const { error: auth0Error } = err as { error: string }

      if (auth0Error === 'interaction_required') {
        setIsLoading(false)

        return
      } else if (
        auth0Error === 'login_required' ||
        auth0Error === 'missing_refresh_token' ||
        auth0Error === 'consent_required'
      ) {
        void handleLoginWithRedirect()
      }
    }
  }, [
    getSessionCookies,
    getAccessTokenSilently,
    setSessionCookies,
    fetchUserDataWithToken,
    handleLoginWithRedirect,
  ])

  const refetchUser = useCallback(async () => {
    const { rmsid, sid, bearer: foundBearer } = getSessionCookies()
    const bearer = foundBearer ?? (await getAccessTokenSilently())

    if (bearer) {
      await fetchUserDataWithToken(bearer)
    } else if (rmsid || sid) {
      await fetchUserData(rmsid, sid)
    }
  }, [
    fetchUserData,
    fetchUserDataWithToken,
    getSessionCookies,
    getAccessTokenSilently,
  ])

  useEffect(() => {
    if (!user && !isLoading) {
      void verifyAuth()
    }
  }, [verifyAuth, user, isLoading])

  if (!user) return null

  const contextValue: AuthContextProps = {
    handleTokenLogout,
    handleLoginWithRedirect,
    user,
    loading,
    handleLogin,
    handleTokenLogin,
    handleLogout,
    handleForgotPassword,
    refetchUser,
    fetchUserDataWithToken,
    setIsLoading,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading ? children : <LoadingSpinner />}
    </AuthContext.Provider>
  )
}

const useAuthContext = () => useContext(AuthContext)

export default useAuthContext
