import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { LocationsResource } from 'src/client'
import logger from 'src/utils/logger'

const useToggleFeatureCodeMutation = (locationId: number) => {
  const queryClient = useQueryClient()

  return useMutation(LocationsResource.toggleFeatureCode, {
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({
          queryKey: ['user-facing-features', { locationId }],
        })
        await queryClient.invalidateQueries({
          queryKey: ['locations', { locationId }],
        })
      } catch (error) {
        logger.error('There was an error trying to refresh user locations.', {
          error,
        })
      }
    },
    onError: (error) => {
      toast.error('There was an error. Please try again.')
      logger.error('There was an error trying to update the feature code.', {
        error,
      })
    },
  })
}

export default useToggleFeatureCodeMutation
