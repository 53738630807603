import {
  createColumnHelper,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Row as ReactRow,
  SortingState,
  Table as ReactTable,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import { format, parseISO } from 'date-fns'
import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import {
  ContactsListDownloadParameters,
  Contact,
} from 'src/client/interfaces/Contacts'

import Contacts from 'src/client/resources/Contacts'
import {
  AnchorContainer,
  AutomatedCampaignsPillContainer,
  CheckboxContainer,
  PillContainer,
} from 'src/components/Contacts/StyledComponents'
import Toolbar from 'src/components/Contacts/Toolbar'

import Pill from 'src/stories/Pill'
import { TablePagination } from 'src/stories/table/TablePagination'

import useContactsQuery from 'src/client/hooks/queries/useContactsQuery'
import useContactsSegmentsQuery from 'src/client/hooks/queries/useContactsSegmentsQuery'
import { SelectOption } from 'src/stories/Select'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { formatPhoneNumber, generateLocationUrl } from 'src/utils'
import { useLocationContext } from 'src/contexts/LocationContext'
import { useContactsSearchParams } from 'src/components/ContactsTable/useContactsSearchParams'
import ContactsTableComponent from 'src/components/ContactsTable'
import Checkbox from 'src/stories/Checkbox'
import { DownloadIcon, TrashIcon } from 'src/stories/assets'
import Link from 'src/stories/Link'
import { Button } from 'src/stories/Button'

const FULL_SIZE = 1000 //symbolic number size for 100% width

const ContactsTable: React.FC = () => {
  const theme = useTheme()
  const { merchantId } = useLocationContext()
  const { isMediumScreen } = useScreenSizes()

  // Route and search parameters
  const {
    setSearchParams,
    locationId,
    pagination,
    search: searchQueryParam,
    segmentId: segmentIdQueryParam,
    sort: sortQueryParam,
  } = useContactsSearchParams()

  const tablePagination = useMemo<PaginationState>(
    () => ({
      pageIndex: pagination.skip ?? 0 / (pagination.take ?? 1),
      pageSize: pagination.take ?? 0,
    }),
    [pagination.skip, pagination.take]
  )

  const tableSorting = useMemo<SortingState>(
    () =>
      sortQueryParam
        ? [
            {
              id: sortQueryParam.field ?? '',
              desc: sortQueryParam.direction === 'DESC',
            },
          ]
        : [],
    [sortQueryParam]
  )

  const contactsQuery = useContactsQuery({
    locationId,
    pagination,
    search: searchQueryParam,
    segmentId: segmentIdQueryParam,
    sort: sortQueryParam,
  })

  const pageCount = Math.ceil(
    contactsQuery?.data?.total || 0 / (pagination.take ?? 1)
  )

  const segmentsQuery = useContactsSegmentsQuery(
    { locationId },
    {
      select: (segments): SelectOption<number>[] => [
        {
          label: 'Manage Lists',
          value: -1,
        },
        ...segments
          .filter(
            (segment) =>
              typeof segment.removedAt === 'undefined' ||
              segment.removedAt === null
          )
          .map((segment) => {
            return {
              label: segment.name,
              value: segment.id,
            }
          }),
      ],
    }
  )

  const columnHelper = createColumnHelper<Contact>()

  const handleSearch = (searchValue: string) => {
    table.resetPagination(true)

    if (searchValue.length) {
      setSearchParams((current) => {
        current.set('search', searchValue)

        return current
      })
    } else {
      setSearchParams((current) => {
        current.delete('search')

        return current
      })
    }
  }

  const handleSegmentSelect = (segmentId: number) => {
    // TODO implement me in a future card
    // I should go to the list / segment management page,
    // not filter the contacts by segment
  }

  const handleSort = (sort: Updater<SortingState>) => {
    const sortUpdate = functionalUpdate(sort, tableSorting)

    table.resetPagination(true)

    if (sortUpdate.length) {
      const { id, desc } = sortUpdate[0]

      setSearchParams((current) => {
        current.set('direction', desc ? 'DESC' : 'ASC')
        current.set('field', id)

        return current
      })
    } else {
      setSearchParams((current) => {
        current.delete('direction')
        current.delete('field')

        return current
      })
    }
  }

  const handlePagination = (paginate: Updater<PaginationState>) => {
    const { pageIndex: _pageIndex, pageSize: _pageSize } = functionalUpdate(
      paginate,
      tablePagination
    )

    setSearchParams((current) => {
      current.set('take', _pageSize.toString())
      current.set('skip', (_pageSize * _pageIndex).toString())

      return current
    })
  }

  const handleDownload = () => {
    if (locationId) {
      let params: ContactsListDownloadParameters = {
        locationId: +locationId,
        export: true,
      }

      if (sortQueryParam) {
        params = {
          ...params,
          sort: { ...sortQueryParam },
        }
      }

      if (searchQueryParam) {
        params = { ...params, search: searchQueryParam }
      }

      if (segmentIdQueryParam) {
        params = { ...params, segmentId: +segmentIdQueryParam }
      }

      void Contacts.downloadContactsList(params)
    }
  }

  const emptyPlaceholder = '-'

  const checkboxCell = columnHelper.display({
    id: 'select',
    size: 40,
    header: ({ table }: { table: ReactTable<Contact> }) => (
      <CheckboxContainer>
        <Checkbox
          name="checkbox"
          size="medium"
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      </CheckboxContainer>
    ),
    cell: ({ row }: { row: ReactRow<Contact> }) => (
      <CheckboxContainer>
        <Checkbox
          name="checkbox"
          size="medium"
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      </CheckboxContainer>
    ),
  })

  const columnsMediumScreen = [
    checkboxCell,
    columnHelper.accessor('subscribed', {
      header: 'automated campaigns',
      size: 80,
      cell: ({ row }: { row: ReactRow<Contact> }) => {
        const {
          original: { id, subscribed },
        } = row

        return (
          <AutomatedCampaignsPillContainer>
            <Pill
              backgroundColor={subscribed ? 'positive' : 'base_0'}
              color={subscribed ? 'base_0' : 'base_60'}
              hideDot={true}
              key={id}
              text={subscribed ? 'Subscribed' : 'Unsubscribed'}
              textAlign="center"
              width={theme.space(30)}
            />
          </AutomatedCampaignsPillContainer>
        )
      },
    }),
    columnHelper.accessor(
      (row) =>
        [row.firstName, row.lastName].filter(Boolean).join(' ') ||
        emptyPlaceholder,
      {
        id: 'fullName',
        header: 'name',
        size: 180,
      }
    ),
    columnHelper.accessor('primaryEmailAddress', {
      header: 'email',
      cell: (info) => info.getValue() || emptyPlaceholder,
      size: 180,
    }),
    columnHelper.accessor('primaryPhoneNumber', {
      header: 'phone',
      cell: (info) =>
        info.getValue()
          ? formatPhoneNumber(info.getValue()!)
          : emptyPlaceholder,
      size: 180,
    }),
    columnHelper.accessor('segments', {
      header: 'list',
      enableSorting: false,
      size: 180,
      cell: ({ row }: { row: ReactRow<Contact> }) => {
        const { original } = row

        if (!original.segments.length) return emptyPlaceholder

        return (
          <PillContainer>
            {original.segments.map((segment) => {
              const { id: key, name: text } = segment

              return (
                <Pill
                  key={key}
                  text={text}
                  maxWidth={theme.space(50)}
                  onClick={() => handleSegmentSelect(key)}
                />
              )
            })}
          </PillContainer>
        )
      },
    }),
    columnHelper.accessor('addedAt', {
      header: 'date added',
      enableSorting: false,
      size: 60,
      cell: ({ row }: { row: ReactRow<Contact> }) => {
        const { original } = row

        return format(parseISO(original.addedAt), 'MM/dd/yyyy')
      },
    }),
  ]

  const columns = [
    checkboxCell,
    columnHelper.accessor(
      (row) => {
        const name =
          [row.firstName, row.lastName].filter(Boolean).join(' ') || null

        return (
          name ??
          row.primaryPhoneNumber ??
          row.primaryEmailAddress ??
          emptyPlaceholder
        )
      },
      {
        id: 'contact',
        header: 'Contact',
        size: FULL_SIZE,
      }
    ),
  ]

  const table = useReactTable({
    columns: isMediumScreen ? columnsMediumScreen : columns,
    data: contactsQuery?.data?.data || [],
    debugTable: process.env.REACT_APP_ENVIRONMENT === 'development',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: handlePagination,
    onSortingChange: handleSort,
    pageCount,
    state: {
      pagination: tablePagination,
      sorting: tableSorting,
    },
  })

  return (
    <ContactsTableComponent
      toolbarComponent={
        <Toolbar
          search={searchQueryParam}
          selectedSegment={segmentIdQueryParam}
          segments={segmentsQuery?.data}
          onSegmentSelect={handleSegmentSelect}
          onSearch={handleSearch}
          isDesktop={isMediumScreen}
        />
      }
      footerComponent={
        <>
          <AnchorContainer>
            <Button
              label="Download contacts as CSV file"
              onClick={handleDownload}
              icon={DownloadIcon}
              baseDataAttribute={'download-link'}
              displayAsText
            />
            <Link
              href={generateLocationUrl(
                merchantId,
                locationId,
                '/deleted-contacts-proto'
              )}
              icon={TrashIcon}
              underline="none"
              baseDataAttribute={'deleted-contacts'}
            >
              Deleted contacts
            </Link>
          </AnchorContainer>
          <TablePagination table={table} />
        </>
      }
      table={table}
      loading={contactsQuery.isLoading}
    />
  )
}

export default ContactsTable
