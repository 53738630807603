import styled from 'styled-components'

export interface RestoreDefaultMessageModalProps {
  defaultMessage: string
}

const StyledContent = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  marginTop: 0,
  marginBottom: theme.space(4),
  alignSelf: 'start',
}))

const StyledDefaultMessage = styled.p(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '1.6rem',
  fontStyle: 'italic',
  marginTop: 0,
  marginBottom: theme.space(12),
}))

const RestoreDefaultMessageModal: React.FC<RestoreDefaultMessageModalProps> = ({
  defaultMessage,
}) => {
  return (
    <>
      <StyledContent>
        Resetting your text message will restore this default message:
      </StyledContent>

      <StyledDefaultMessage>"{defaultMessage}"</StyledDefaultMessage>
    </>
  )
}

export default RestoreDefaultMessageModal
