import { LocationLegacy } from '../../../client/interfaces/Common'

export const getIntegrationStatusData = (
  location: LocationLegacy,
  integration: 'FACEBOOK' | 'THUMBTACK' | 'HOMEADVISOR' | 'GOOGLE_MESSAGES'
) => {
  const int = location.integrations.find((i) => i.integration === integration)

  return {
    status: int?.status || 'inactive',
    updatedAt: int?.updatedAt || null,
  }
}
