import React from 'react'
import styled from 'styled-components'

export interface BaseInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  id: string
  name: string
  type: string
  error?: boolean
  cursor?: 'auto' | 'pointer'
  width: string | number
  height: string | number
  fontSize: string
  autoFocus?: boolean
  baseDataAttribute?: string
}

const BaseInput = styled.input<BaseInputProps>(
  ({ error, theme, disabled, cursor = 'auto', width, height, fontSize }) => ({
    fontSize,
    cursor,
    width,
    minWidth: width,
    height,
    minHeight: height,
    margin: 0,
    padding: `0 ${theme.space(4)}`,
    backgroundColor: disabled ? theme.colors.base_3 : theme.colors.base_0,
    color: error ? theme.colors.critical : theme.colors.base_100,
    border: `1px solid ${error ? theme.colors.critical : theme.colors.base_20}`,
    borderRadius: theme.constants.borderRadius,

    '&:not([disabled]):hover': {
      borderColor: error ? theme.colors.critical : theme.colors.base_50,
    },

    '&:not([disabled]):focus': {
      color: theme.colors.base_100,
      border: `2px solid ${
        error ? theme.colors.critical : theme.colors.primary_1
      }`,
      outline: 'none',
    },
  })
)

export default BaseInput
