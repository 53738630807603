import React from 'react'
import WafLayout from 'src/components/WafLayout'
import DeletedContactsTable from './DeletedContactsTable'
import { BottomSpace } from 'src/components/DeletedContacts/StyledComponents'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import styled from 'styled-components'
import { Heading } from 'src/stories/typography'

interface Props {
  isMultiLocation: boolean
}

const Header = styled.div<{ isDesktop: boolean }>(({ theme, isDesktop }) => ({
  display: 'flex',
  gap: theme.space(5),
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  padding: `0 ${theme.space(isDesktop ? 8 : 4)}`,
}))
const DeletedContacts: React.FC<Props> = ({ isMultiLocation }) => {
  const { isMediumScreen: isDesktop } = useScreenSizes()

  return (
    <WafLayout
      layoutTitle={
        <Header isDesktop={isDesktop}>
          <Heading as="h1" size="large">
            Deleted Contacts
          </Heading>
        </Header>
      }
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
        showDropShadow: true,
      }}
    >
      <DeletedContactsTable />
      <BottomSpace />
    </WafLayout>
  )
}

export default DeletedContacts
