import { useQuery } from '@tanstack/react-query'
import Locations from 'src/client/resources/LocationsLegacy'
import logger from 'src/utils/logger'

const useUserFacingFeatures = ({ locationId }: { locationId: number }) => {
  return useQuery({
    queryKey: [
      'user-facing-features',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) =>
      Locations.findUserFacingFeatures(lid),
    onError: (error) => {
      logger.error('WAF useUserFacingFeatures - error', { error })
    },
    refetchOnWindowFocus: false,
    enabled: !!locationId && !isNaN(locationId),
  })
}

export default useUserFacingFeatures
