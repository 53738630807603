import { useParams } from 'react-router-dom'
import LeadInstantResponseSettings from './LeadInstantResponseSettings'
import useAccountContext from 'src/contexts/AccountContext'
import { useInstantResponder } from 'src/containers/Settings/InstantResponders/hooks'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import Constants from 'src/lib/Constants'
import { getActiveLocation } from 'src/utils'

interface MCAInstantResponseSettingsProps {
  customIR: string | null
  isEnabled: boolean
}

const MCAInstantResponseSettings: React.FC<MCAInstantResponseSettingsProps> = ({
  customIR,
  isEnabled,
}) => {
  const { locationId } = useParams<UseLocationRouteParams>()
  const { locations, handleRefetchUserLocations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)

  const { customIRMessage, isToggleEnabled, onToggle, onUpdateMessage } =
    useInstantResponder({
      customIR,
      isEnabled,
      locationId: activeLocation?.locationId,
      refetchLocationFn: handleRefetchUserLocations,
      source: 'missedCall',
    })

  return (
    <LeadInstantResponseSettings
      leadSourceName="Missed Call Acknowledgment"
      connectable={false}
      defaultMessage={Constants.INSTANT_RESPONSES.missedCall.defaultMessage}
      enabled={isToggleEnabled}
      message={customIRMessage}
      onEnablementChange={onToggle}
      onUpdateMessage={onUpdateMessage}
      messageLabel="Text response to missed calls:"
      maxLength={160}
    />
  )
}

export default MCAInstantResponseSettings
