import React from 'react'
import styled from 'styled-components'

import { Button } from 'src/stories/Button'
import { Col, Row } from 'src/stories/Layout'

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  marginBottom: theme.space(3),
}))

const StyledCopy = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(6),
}))

const StyledHeadline = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '1.6rem',
  marginBottom: theme.space(2),
}))

const StyledDetails = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.4rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(8),
}))

interface OverViewStepProps {
  nextStep: () => void
  isConnecting: boolean
}
const OverviewStep: React.FC<OverViewStepProps> = ({
  nextStep,
  isConnecting = false,
}) => (
  <Row>
    <Col w={2}>
      <StyledTitle>Overview</StyledTitle>

      <StyledCopy>
        Connect your Thumbtack account to add your new contacts and use Instant
        Responders to automatically follow-up with leads from Thumbtack.
      </StyledCopy>

      <StyledHeadline>How it works:</StyledHeadline>

      <StyledDetails>
        <li>We will direct you through the Thumbtack connection process</li>
        <li>
          Once connected, new leads from Thumbtack will be automatically added
          to your account.
        </li>
        <li>
          Setup instant response to automatically follow-up with new leads,
          ensuring you are one of the first Home Service pros to reach out
        </li>
        <li>
          View and reply to all your Thumbtack messages right from your
          Messaging Hub
        </li>
      </StyledDetails>

      <Button
        action="primary"
        disabled={isConnecting}
        baseDataAttribute={`connection-wizard-angi-${
          isConnecting ? 'is-conecting' : 'get-started'
        }`}
        label={isConnecting ? 'Connecting...' : 'Get Started'}
        maxWidth={167}
        onClick={nextStep}
      />
    </Col>
  </Row>
)

export default OverviewStep
