import { formatRelative } from 'date-fns'
import React from 'react'
import { useTheme } from 'styled-components'

import useInsightsContext from 'src/contexts/InsightsContext'
import { MainReviewSites } from 'src/contexts/InsightsContext/types'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import Tooltip from 'src/stories/Tooltip'
import { InsightsWidget } from './InsightsWidget'
import {
  StyledTimestamp,
  StyledWidgetBlackText,
  StyledWidgetContentContainer,
  WidgetNameLink,
  WidgetReviewBubble,
} from './styled'
import { WidgetProps } from './types'

interface Props extends WidgetProps {
  link: string
}

const RecentReviewsWidget: React.FC<Props> = ({ link, baseDataAttribute }) => {
  const theme = useTheme()
  const { isReviewLoading, allTimeReviews } = useInsightsContext()
  const { is2ExtraLargeScreen } = useScreenSizes()

  if (isReviewLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  return (
    <InsightsWidget
      baseDataAttribute={baseDataAttribute}
      title="Most recent reviews"
      headerElement={
        <Button
          label="➜ Show all reviews"
          baseDataAttribute={baseDataAttribute + '-show-all-reviews'}
          href={link}
          displayAsText
          style={{
            height: theme.space(5),
          }}
        />
      }
    >
      <StyledWidgetContentContainer
        $gapUnits={3}
        data-cy={baseDataAttribute + '-list'}
      >
        {allTimeReviews.slice(0, 9).map((review, idx) => {
          const dataCy = `${baseDataAttribute}-review-${idx}`
          const relativeDate = formatRelative(
            new Date(review.timestamp),
            new Date()
          )
          // 10 since that is length of the formatted Date given by formatRelative
          // MM/dd/yyyy
          // 26 Max length `last Wednesday at 12:00 AM`
          const isVeryLongDate = relativeDate.length > 22

          return (
            <StyledWidgetContentContainer
              key={`review-item-${idx}`}
              data-cy={dataCy + '-container'}
            >
              <StyledWidgetContentContainer $gapUnits={1} $row>
                <WidgetNameLink
                  href={review.contactLink}
                  $is2ExtraLargeScreen={is2ExtraLargeScreen}
                  $isLongText={isVeryLongDate}
                >
                  {review.contactName}
                </WidgetNameLink>

                <div style={{ flexGrow: 1 }}>
                  <WidgetReviewBubble
                    dataCy={dataCy}
                    icon={review.siteDisplayName as MainReviewSites}
                    rating={review.rating}
                  />
                </div>

                <StyledTimestamp data-cy={dataCy + '-timestamp'} $alignRight>
                  {relativeDate}
                </StyledTimestamp>
              </StyledWidgetContentContainer>
              {review.reviewText && review.isExpandable && (
                <Tooltip
                  fontSize="1.2rem"
                  text={`“${review.reviewText}”`}
                  data-cy={dataCy + '-tooltip-container'}
                >
                  <StyledWidgetBlackText
                    lighterColor
                    data-cy={dataCy + '-text'}
                  >
                    &quot;{review.reviewText}&quot;
                  </StyledWidgetBlackText>
                </Tooltip>
              )}
            </StyledWidgetContentContainer>
          )
        })}
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default RecentReviewsWidget
