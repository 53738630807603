import styled from 'styled-components'

const StyledBaseText = styled.p(({ theme }) => ({
  margin: 0,
  fontSize: '1.6rem',
  fontWeight: '500',
  lineHeight: '2rem',
}))

const StyledPreview = styled(StyledBaseText)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const StyledContactNameText = styled(StyledPreview)(({ theme }) => ({
  color: theme.colors.base_100,
}))

export const StyledMessageTitleText = styled(StyledPreview)({
  fontSize: '1.4rem',
})

export const StyledFormattedTimeText = styled(StyledBaseText)(({ theme }) => ({
  color: theme.colors.base_40,
  fontSize: '1.2rem',
}))

export const StyledPreviewMessageText = styled(StyledPreview)({
  fontSize: '1.4rem',
  fontWeight: '400',
})

export const StyledPreviewMessageErrorText = styled(StyledPreview)(
  ({ theme }) => ({
    color: theme.colors.critical,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
  })
)
