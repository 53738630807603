import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ErrorObject,
  Media,
  WebSocketPageEvent,
} from 'src/client/interfaces/Common'
import logger from 'src/utils/logger'
import {
  getLastMessageReceivedFromMessagesList,
  getLegacyDocumentType,
  handleInflightErrors,
  handleUploadMedia,
  isAgentzUnreplyable,
  isFacebookUnreplyable,
  isFeedbackReviewUnreplyable,
  isGoogleMessagesUnreplyable,
  isHomeAdvisorUnreplyable,
  isRecentSmsFailed,
  isSmsAreaCodeUnreplyable,
  reportProcessDone,
} from 'src/contexts/MhContext/utils'
import Constants from 'src/lib/Constants'
import {
  ConversationEventResource,
  conversationEventTypePredicate,
} from 'src/client/interfaces/Conversations'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Segment } from 'src/client/interfaces/Segments'
import { getContactDisplayName } from 'src/client/formatters'
import { HandleSendMessage } from 'src/client/interfaces/Comms'
import useSegments from 'src/client/hooks/queries/useSegmentsQuery'
import { ConversationInfoEvent } from 'src/contexts/MhContext/types'
import {
  ContactsLegacyResource,
  ContactsResource,
  ConversationsLegacyResource,
} from 'src/client'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { OpenConversationContactDetails } from 'src/containers/MessagingHub/types'
import { ConversationListItem } from 'src/contexts/ConversationsListContext/types'
import { useConversationMessagesContext } from 'src/contexts/ConversationMessagesContext'
import { generateLocationUrl } from 'src/utils'
import { SmsRegistrationStatus } from 'src/client/interfaces/SmsRegistration'
import Link from 'src/stories/Link'

export interface UseMessagingHubData {
  // Conversations Pane
  conversationsListIsLoading: boolean
  isListTruncated: boolean
  conversationsList: ConversationListItem[]
  openConversationId?: number
  conversationListFilter: string
  segments: Segment[]
  isOpenConversationChangeInFlight: boolean
  isZeroState: boolean
  setOpenConversationId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  setConversationListFilter: React.Dispatch<React.SetStateAction<string>>
  refetchConversationsList: () => Promise<void>
  mutateConversationArchived: ReturnType<
    typeof useConversationsListContext
  >['mutateConversationArchived']
  mutateConversationRead: ReturnType<
    typeof useConversationsListContext
  >['mutateConversationRead']

  // Messages Pane
  loadingConversationContactDetails: boolean
  openConversationContactDetails: OpenConversationContactDetails
  loadingConversationMessages: boolean
  messagesList: ConversationEventResource[]
  infoEvents: ConversationInfoEvent[]
  isUnreplyable: boolean
  docTypeToReply: string
  isFeedbackReviewUnreachable: boolean
  handleSendMessage: HandleSendMessage
  getOpenConversationContactDetails: () => Promise<void>
  setIsOpenConversationChangeInFlight: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

/**
 * This hook is a helper to manage the data used by the MhContext (conversations, messages, contacts)
 *
 * **Do not use it directly**
 */
export const useMessagingHubData = (): UseMessagingHubData => {
  const navigate = useNavigate()

  // Conversations Pane
  const {
    refetch: refetchConversationsListData,
    isLoading: conversationsListIsLoading,
    isListTruncated,
    conversationsList,
    preventUiUpdates,
    getConversationListItem,
    setPreventUiUpdates,
    mutateConversationArchived,
    mutateConversationRead,
    socket,
    conversationListFilter,
    setConversationListFilter,
    openConversationId,
    setOpenConversationId,
  } = useConversationsListContext()

  const [
    isOpenConversationChangeInFlight,
    setIsOpenConversationChangeInFlight,
  ] = useState(false)

  // Contact
  const [
    loadingConversationContactDetails,
    setLoadingConversationContactDetails,
  ] = useState(true)
  const [openConversationContactDetails, setOpenConversationContactDetails] =
    useState<OpenConversationContactDetails>({})

  // Messages
  const {
    messagesList,
    isLoading: loadingConversationMessages,
    refetch: refetchMessagesList,
  } = useConversationMessagesContext()

  const [isUnreplyable, setIsUnreplyable] = useState(false)
  const [isFeedbackReviewUnreachable, setIsFeedbackReviewUnreachable] =
    useState(false)
  const [infoEvents, setInfoEvents] = useState<ConversationInfoEvent[]>([])

  const { activeLocationV3, locationId, merchantId } = useLocationContext()

  // Segments
  const { data: _segments, refetch } = useSegments({ locationId })
  /*
        TODO update this so Main API filters nulls instead of WAF
        The reason WAF is doing the filter instead of Main API is
        Main API doesn't have a convention for filtering nulls, so
        that will need to be designed. For now let's take the MVP
        path and have WAF do the filtering.

        https://github.com/signpost/main-api/issues/313
      */

  const segments = _segments
    ? _segments.filter((segment) => segment.removedAt === null)
    : []

  const isZeroState = conversationsList.length <= 0

  const _getOpenConversationContactDetails = useCallback(
    async (isZeroStateEnabled: boolean, conversationId?: number) => {
      if (isZeroStateEnabled || conversationId === -1) {
        setOpenConversationContactDetails({
          name: 'Hibu',
          email: Constants.Branding.companySupportEmail,
          phoneNumber: '(855) 606-4900',
        })

        setLoadingConversationContactDetails(false)
      } else {
        setLoadingConversationContactDetails(true)

        const conversation = getConversationListItem(conversationId!)
        const isConversation =
          typeof conversation !== 'undefined' && 'contactId' in conversation

        try {
          if (isConversation && conversationId) {
            const contact = await ContactsResource.getById(
              locationId,
              conversationId
            )

            const {
              channels,
              details: { emailOptIn, lifecycleStage, notes, smsOptIn, source },
              firstName,
              id,
              lastName,
              primaryEmailAddress,
              primaryPhoneNumber,
              primarySegmentId,
              subscribed,
              segments: contactSegments,
              updatedAt,
            } = contact

            const name = getContactDisplayName(contact)

            setOpenConversationContactDetails({
              id,
              name,
              hasWrittenName: !!firstName || !!lastName,
              email: primaryEmailAddress,
              phoneNumber: primaryPhoneNumber,
              emailOptIn,
              smsOptIn,
              merchantSubscribed: subscribed,
              isSmsReachable: channels.some((c) => c.type === 'phone'),
              lifecycleStage,
              source,
              segmentIds: contactSegments.map((segment) => segment.id),
              primarySegmentId,
              notes,
              updatedAt,
              channels,
            })

            if (contactSegments.length !== segments.length) {
              await refetch()
            }
          } else {
            setOpenConversationContactDetails({})
          }
        } catch (error) {
          logger.debug('Failed to fetch contact', { error })
        } finally {
          setLoadingConversationContactDetails(false)
        }
      }
    },
    [getConversationListItem, locationId, refetch, segments.length]
  )

  useEffect(() => {
    const initialize = async () => {
      await _getOpenConversationContactDetails(isZeroState, openConversationId)
      setIsOpenConversationChangeInFlight(false)
    }

    void initialize()
  }, [_getOpenConversationContactDetails, isZeroState, openConversationId])

  const getOpenConversationContactDetails = useCallback(async () => {
    await _getOpenConversationContactDetails(isZeroState, openConversationId)
  }, [openConversationId, isZeroState, _getOpenConversationContactDetails])

  const docTypeToReply = useMemo(() => {
    const messageToReply = getLastMessageReceivedFromMessagesList(messagesList)

    return messageToReply ? getLegacyDocumentType(messageToReply) : ''
  }, [messagesList])

  const refetchConversationsList = useCallback(async () => {
    await refetchConversationsListData()
  }, [refetchConversationsListData])

  /**
    Display a temporary placeholder "sent message", then actually send,
    and update the conversation list with fresh API data.
    This includes selecting what method & channel to use for the send,
    including in cases where the contact has multiple phone channels.
   */
  const handleSendMessage = useCallback<HandleSendMessage>(
    async (message, medias, sendAs, isCustomerNotMarketingSubscribed) => {
      if (
        messagesList.length &&
        !!openConversationId &&
        openConversationId > 0
      ) {
        // Note: "lastMessageReceived" can actually be "last message sent" as well.
        // See getLastMessageReceivedFromMessagesList() for more detail.
        // TODO: Rename lastMessageReceived here to match what it is & make it self-documenting.
        const lastMessageReceived =
          getLastMessageReceivedFromMessagesList(messagesList)!

        /*
          TODO long term, refactor this so it either:
          a) requests the whole model via REST
          b) waits for the socket paging event to know when to pull new messages
          c) receives model data in request response
        */

        // Now decide the right method to use to reply in this conversation.
        // The general rule is to favor replying via the same method as the contact's
        // last incoming message, and fall back to SMS otherwise (if possible).
        // (Also, determining sending param details like the interaction ID for
        // the asReplyTo parameter.)
        /**
         * TODO: Consolidate the "how should we reply / can we reply via the same
         * method / can we reply at all?" logic, which (as of 2022-08-29) is
         * partially replicated in 3 spots:
         *   1.) Here
         *   2.) Inside getNewMessageObject() and getOutgoingFromIncoming().
         *   3.) In the useEffect() that disables the Send button if the conversation
         *       isn't "replyable" and displays messages like "Facebook does not allow
         *       responses to conversations older than 24 hours. Please follow up with
         *       an email or text message directly.'"
         *
         * We may want to refactor to create a centralized helper for
         * "determine replyability and the details of how a reply should be sent".
         * Perhaps a helper that returns "can we reply, and if so
         * what would the sending details be, and if not what kind of a UI message
         * should we show?". Or break those pieces up into individual functions that
         * are shared between the "can we reply?" and "handle a reply" spots.
         *
         * This will also need to handle selecting the channelId in cases where we
         * can't just use the same as the last received message. We may need to carefully
         * check the requirements and what the mobile app is doing.
         *
         * Known issues we could address this way:
         *   1.) Cases like replying to a Facebook message after 24 hours aren't allowing
         *       falling back to sending via SMS, or adding "Reply below to send your
         *       message via Text instead." to the UI message like the mobile app does.
         *   2.) We're only using the asReplyTo parameter for Agentz messages,
         *       but it's supposed to be used for all replies. (This can cause overzealous
         *       duplicate-suppression in edge-cases. It also means we're missing
         *       nice-to-have reporting in the comms table.)
         *
         */

        let asReplyTo:
          | { agentzInteractionId: number }
          | { liveReceptionistInteractionId: number }
          | { googleMessagesInteractionId: number }
          | { thumbtackInteractionId: number }
          | undefined

        // Always favor replying via the exact same channel as the last message.
        // (But this might get overridden below.)
        let channelId = lastMessageReceived.channelId ?? 0
        let id = lastMessageReceived.id.toString()
        const documentType = getLegacyDocumentType(lastMessageReceived)

        // Agentz interactions
        if (conversationEventTypePredicate(lastMessageReceived, 'AGENTZ')) {
          if (lastMessageReceived.agentzEventData.replyUrl) {
            asReplyTo = {
              agentzInteractionId: lastMessageReceived.id,
            }
          } else {
            const { primaryPhoneChannelId } = await ContactsResource.getById(
              locationId,
              openConversationId
            )

            // If the Agentz chat has closed, we can't reply via Agentz any more.
            // The best we can do is use SMS. And we don't know what phone number
            // the contact was using in the chat (if any), so we'll use their
            // primary phone channel rather than to match a specific secondary
            // phone channel.
            channelId = primaryPhoneChannelId ?? -1
            id = Constants.COMMS.documentTypes.smsInteraction
          }
        }

        // Live Receptionist interactions
        if (
          conversationEventTypePredicate(
            lastMessageReceived,
            'LIVE_RECEPTIONIST'
          )
        ) {
          // Note: channelId and id are already handled correctly in sendMessage(),
          // because liveReceptionistInteraction is in smsDocumentTypes (guaranteed
          // to have a phone channel).
          asReplyTo = {
            liveReceptionistInteractionId: lastMessageReceived.id,
          }
        }

        // Google Messages interactions
        if (conversationEventTypePredicate(lastMessageReceived, 'GOOGLE')) {
          asReplyTo = {
            googleMessagesInteractionId: lastMessageReceived.id,
          }
        }

        // Thumbtack interactions
        if (conversationEventTypePredicate(lastMessageReceived, 'THUMBTACK')) {
          const { primaryPhoneChannelId } = await ContactsResource.getById(
            locationId,
            openConversationId
          )

          asReplyTo = {
            thumbtackInteractionId: lastMessageReceived.id,
          }

          if (!localStorage.getItem('SP_ENABLE_THUMBTACK_METHOD')) {
            channelId = primaryPhoneChannelId ?? -1
          }
        }

        // Review Request and Feedback Request comms
        if (
          conversationEventTypePredicate(lastMessageReceived, 'FEEDBACK') ||
          conversationEventTypePredicate(lastMessageReceived, 'REVIEW')
        ) {
          const { primaryPhoneChannelId } = await ContactsResource.getById(
            locationId,
            openConversationId
          )

          channelId = primaryPhoneChannelId ?? -1
          id = Constants.COMMS.documentTypes.smsInteraction
        }

        try {
          setPreventUiUpdates(true)

          const { channels } = openConversationContactDetails
          const channel = channels?.find((c) => c.id === channelId)

          if (sendAs?.type === 'feedback' && channel) {
            await ContactsLegacyResource.sendFeedbackRequest(
              openConversationId,
              channelId,
              channel.type === 'phone' ? 'sms' : 'email'
            )
          } else if (sendAs?.type === 'review' && channel) {
            await ContactsLegacyResource.sendReviewRequest(
              openConversationId,
              channelId,
              sendAs.reviewSiteName,
              channel.type === 'phone' ? 'sms' : 'email'
            )
          } else {
            let msgMedias: Media[] = []

            if (medias.length) {
              msgMedias = await handleUploadMedia(medias)
            }

            const { fingerprint } =
              await ConversationsLegacyResource.sendMessage(
                message,
                msgMedias,
                channelId,
                openConversationId,
                id,
                documentType,
                asReplyTo
              )

            logger.debug('Message sent', { message, fingerprint })
          }
          await refetchMessagesList()
        } catch (error) {
          if (!error) {
            logger.error(
              "There's been an error sending a message. error undefined"
            )
            toast.error(
              'Message not sent, Something went wrong. Please try again.'
            )
          } else {
            handleInflightErrors(
              error as ErrorObject,
              "There's been an error sending a message",
              !!sendAs,
              !!isCustomerNotMarketingSubscribed
            )
          }
        } finally {
          setPreventUiUpdates(false)
          void reportProcessDone()
        }
      }
    },
    [
      messagesList,
      openConversationId,
      locationId,
      setPreventUiUpdates,
      openConversationContactDetails,
      refetchMessagesList,
    ]
  )

  useEffect(() => {
    if (openConversationId && openConversationId !== -1 && socket) {
      const listener = (event: WebSocketPageEvent) => {
        const shouldRefetchMessages = event.some(({ documents }) =>
          documents.some((d) => d.contactId === +openConversationId)
        )

        logger.debug('WAF WS MhContext - poll - Handling page event', {
          event,
          shouldRefetchMessages,
        })

        if (shouldRefetchMessages) {
          void refetchMessagesList()
        }
      }

      socket.on('page', listener)

      return () => {
        socket.off('page', listener)
      }
    }
  }, [socket, openConversationId, navigate, refetchMessagesList])

  useEffect(() => {
    if (!preventUiUpdates) {
      const facebookUnreplyable = isFacebookUnreplyable(messagesList)
      const googleMessagesUnreplyable =
        isGoogleMessagesUnreplyable(messagesList)
      const smsAreaCodeUnreplyable = isSmsAreaCodeUnreplyable(messagesList)
      const showA2pRegisterCallToAction =
        !activeLocationV3.registrationStatus && isRecentSmsFailed(messagesList)
      const showA2pCheckRegistrationCallToAction =
        activeLocationV3?.registrationStatus &&
        activeLocationV3?.registrationStatus !==
          SmsRegistrationStatus.APPROVED &&
        isRecentSmsFailed(messagesList)
      const homeAdvisorUnreplyable = messagesList.length
        ? isHomeAdvisorUnreplyable(
            messagesList[0],
            !!openConversationContactDetails.isSmsReachable
          )
        : false
      const agentzUnreplyable = messagesList.length
        ? isAgentzUnreplyable(
            messagesList[0],
            !!openConversationContactDetails.isSmsReachable
          )
        : false
      const feedbackReviewUnreplyable = messagesList.length
        ? isFeedbackReviewUnreplyable(
            messagesList[0],
            !!openConversationContactDetails.isSmsReachable
          )
        : false
      const isTextable =
        openConversationContactDetails.smsOptIn === null ||
        openConversationContactDetails.smsOptIn

      const unreplayableConversation =
        !isTextable ||
        homeAdvisorUnreplyable ||
        agentzUnreplyable ||
        feedbackReviewUnreplyable ||
        facebookUnreplyable ||
        googleMessagesUnreplyable ||
        smsAreaCodeUnreplyable

      const feedbackReviewUnreachable =
        !(isTextable && openConversationContactDetails.isSmsReachable) &&
        !openConversationContactDetails.email

      const newInfoEvents: ConversationInfoEvent[] = []

      if (!isTextable && typeof isTextable !== 'undefined') {
        const contactDisplayName =
          openConversationContactDetails.name || 'Contact'

        newInfoEvents.unshift({
          type: 'warning',
          message: `${contactDisplayName} has opted out from receiving marketing messages at this time. You will be able to send messages once ${contactDisplayName} opts in.`,
          eventType: 'SMS',
        })
      }

      if (
        facebookUnreplyable &&
        process.env.REACT_APP_PREVENT_OLD_CONVERSATION_ERROR_MESSAGE !== 'true'
      ) {
        // TODO: make this able to handle the "Reply below to send your message via Text instead." scenario
        // like Biscuit's Helpers.getConversationConfig() does when SMS is available.

        newInfoEvents.unshift({
          type: 'warning',
          message:
            'Facebook does not allow responses to conversations older than 24 hours. Please follow up with an email or text message directly.',
          eventType: 'FACEBOOK',
        })
      }

      if (googleMessagesUnreplyable) {
        // TODO: make this able to handle the "Reply below to send your message via Text instead." scenario
        // like Biscuit's Helpers.getConversationConfig() does when SMS is available.
        newInfoEvents.unshift({
          type: 'warning',
          message:
            'Google Messages does not allow responses to conversations older than 72 hours. Please follow up with an email or text message directly.',
          eventType: 'GOOGLE',
        })
      }

      if (smsAreaCodeUnreplyable) {
        newInfoEvents.unshift({
          type: 'warning',
          message:
            'Contact was unable to receive instant text based on local messaging laws. Please follow up using another method.',
          eventType: 'SMS',
        })
      }

      if (showA2pRegisterCallToAction) {
        newInfoEvents.unshift({
          type: 'warning',
          message: (
            <span>
              Phone carriers require registration to prevent spam messages.
              Click{' '}
              <Link
                baseDataAttribute={'a2p-call-to-action-sms-registration'}
                href={generateLocationUrl(
                  merchantId,
                  locationId,
                  '/settings/business/sms-registration'
                )}
              >
                here
              </Link>{' '}
              to register your business and ensure successful message delivery.
            </span>
          ),
          eventType: 'SMS',
        })
      }

      if (showA2pCheckRegistrationCallToAction) {
        newInfoEvents.unshift({
          type: 'warning',
          message: (
            <span>
              Phone carriers require registration to prevent spam messages.
              Click{' '}
              <Link
                baseDataAttribute={'a2p-registration-call-to-action'}
                href={generateLocationUrl(
                  merchantId,
                  locationId,
                  '/settings/business'
                )}
              >
                here
              </Link>{' '}
              to check your pending registration status to ensure successful
              message delivery.
            </span>
          ),
          eventType: 'SMS',
        })
      }

      setIsUnreplyable(unreplayableConversation)
      setIsFeedbackReviewUnreachable(feedbackReviewUnreachable)
      setInfoEvents(newInfoEvents)
    }
  }, [
    messagesList,
    preventUiUpdates,
    openConversationContactDetails,
    activeLocationV3,
    locationId,
    merchantId,
  ])

  return {
    segments,
    // Conversations Pane
    conversationsListIsLoading,
    isListTruncated,
    conversationsList,
    openConversationId,
    isZeroState,
    conversationListFilter,
    setOpenConversationId,
    setConversationListFilter,
    refetchConversationsList,
    mutateConversationArchived,
    mutateConversationRead,

    // Messages Pane
    loadingConversationContactDetails,
    openConversationContactDetails,
    isOpenConversationChangeInFlight,
    loadingConversationMessages,
    messagesList,
    infoEvents,
    isUnreplyable,
    docTypeToReply,
    isFeedbackReviewUnreachable,
    handleSendMessage,
    getOpenConversationContactDetails,
    setIsOpenConversationChangeInFlight,
  }
}
