import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { BaseLegacyPageProps } from 'src/utils/interfaces'
import useSidebarRoutes from 'src/routes/hooks'
import LegacyPages from 'src/containers/LegacyPages'
import { getLocationPreferredTerms } from 'src/utils'
import Business from 'src/containers/Settings/Business'
import useAccountContext from 'src/contexts/AccountContext'
import ReviewSites from 'src/containers/Settings/ReviewSites'
import WafLayout, { WafLayoutProps } from 'src/components/WafLayout'
import InstantResponders from 'src/containers/Settings/InstantResponders'
import SmsRegistration from 'src/containers/Settings/Business/SmsRegistration'
import { useLocationContext } from 'src/contexts/LocationContext'

type SettingsProps = BaseLegacyPageProps

const Settings: React.FC<SettingsProps> = ({ isMultiLocation }) => {
  const { locationId, merchantId, activeLocation } = useLocationContext()
  const { locations } = useAccountContext()
  const location = useLocation()

  const locationPreferredTerms = getLocationPreferredTerms(activeLocation)

  const { settingSidebarRoutes } = useSidebarRoutes({
    merchantId,
    locationId,
    locations,
    consumerTerms: locationPreferredTerms.consumerTerms,
  })

  const settingsHashes = ['#section-settings-instant-responders']
  const settingsPages: string[] = ['business', 'review-sites']

  const isValidHash = settingsHashes.includes(location.hash)
  const isValidPath = settingsPages.some((path) =>
    location.pathname.includes(path)
  )

  const isValid = isValidHash || isValidPath

  const wafLayoutProps: Omit<WafLayoutProps, 'children'> = {
    sidebarTitle: 'Settings',
    sidebarRoutes: settingSidebarRoutes,
    styleOptions: {
      removePadding: !isValid,
      withMultiLocationHeader: isMultiLocation,
      invertedBackground: isValid,
    },
  }

  return (
    <Routes>
      <Route path="business/sms-registration" element={<SmsRegistration />} />
      <Route
        path="business"
        element={
          <WafLayout {...wafLayoutProps} displayFooter>
            <Business />
          </WafLayout>
        }
      />
      <Route
        path="review-sites"
        element={
          <WafLayout {...wafLayoutProps} displayFooter>
            <ReviewSites />
          </WafLayout>
        }
      />
      <Route
        path="*"
        element={
          <WafLayout {...wafLayoutProps}>
            {({ locationHash, locationPath }) => {
              switch (locationHash) {
                // TODO: Consider extracting this into its own route.
                case '#section-settings-instant-responders':
                  return <InstantResponders />
                case '#section-settings-business':
                // @ts-expect-error Allow fallthrough
                case '':
                  if (locationPath.includes('settings')) {
                    return <Navigate to="./business" replace />
                  }
                // fallthrough
                default:
                  return (
                    <LegacyPages
                      withinLayout
                      withMultiLocationHeader={isMultiLocation}
                    />
                  )
              }
            }}
          </WafLayout>
        }
      />
    </Routes>
  )
}

export default Settings
