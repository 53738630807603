import { toast } from 'react-toastify'
import { ConversationsLegacyResource } from 'src/client'
import Contacts from 'src/client/resources/Contacts'
import Constants from 'src/lib/Constants'
import logger from 'src/utils/logger'

/**
 * Method to modify conversation as read status
 * @param conversationContactId
 * @param setConversationReadStatus
 * @param conversationReadStatus
 * @param preventServerCall
 */
export const modifyConversationReadStatus = (
  conversationContactId: number,
  setConversationReadStatus: (
    conversationShouldBeRead: boolean,
    contactId: number
  ) => void,
  conversationReadStatus: 'read' | 'unread',
  preventServerCall?: boolean
) => {
  const contactId = conversationContactId
  const conversationShouldBeRead = conversationReadStatus === 'read'

  if (!preventServerCall && !!contactId && contactId > 0) {
    void ConversationsLegacyResource.markConversationRead(
      contactId,
      conversationShouldBeRead
    ).catch((error: unknown) => {
      logger.error('Error marking conversation as read', { error })
    })
  }

  setConversationReadStatus(conversationShouldBeRead, contactId)
}

/**
 * Method to toggle the contact subscription
 * @param params
 * @returns the subscriptions status if the request completed successfully, if not, the current subscription status
 */
export const toggleContactSubscription = async (params: {
  customerId: number
  subscribed: boolean
}) => {
  try {
    await Contacts.updateContactSubscription(params)

    return params.subscribed
  } catch (error) {
    logger.error('Failed to update contact subscription', { error })
    toast.error(Constants.ERRORS.default)

    return !params.subscribed
  }
}
