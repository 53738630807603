import { AxiosInstance } from 'axios'

import Constants from 'src/lib/Constants'
import {
  AddChannelsRequest,
  ContactLegacyRequests,
  CreateResponse,
} from 'src/client/interfaces/ContactsLegacy'
import Resource from 'src/client/resource'
import { reduceAllSettledResponse } from 'src/utils'
import { Contact } from 'src/client/interfaces/Contacts'

// https://github.com/signpost/rhubarb/blob/master/lib/DocumentIndexTemplates.js#L1663
const actions = (client: AxiosInstance): ContactLegacyRequests => {
  const createCustomer = (
    locationId: number,
    contacts: ({ emailAddress: string } | { phoneNumber: string })[],
    nameData:
      | {
          unparsedName?: string
        }
      | { firstName?: string; lastName?: string } = {}
  ): Promise<CreateResponse> =>
    client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Customers',
      method: 'create',
      params: {
        locationId,
        nameData,
        contacts,
        undelete: true,
        mergeCustomers: true,
      },
    })

  const sendUserTriggeredComm = (
    contactId: number,
    channelId: number,
    method: 'sms' | 'email',
    commType: string,
    reviewSiteName?: string,
    productOrigin?: 'messagingHub' | 'contactsPage'
  ): Promise<{ fingerprint: string }> =>
    client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Customers',
      method: 'sendUserTriggeredComm',
      params: {
        customerId: contactId,
        channelId,
        method,
        commType,
        reviewSiteName,
        productOrigin,
      },
    })

  const sendReviewRequest = async (
    contactId: number,
    channelId: number,
    reviewSiteName: string,
    method: 'sms' | 'email' = 'sms',
    productOrigin: 'messagingHub' | 'contactsPage' = 'messagingHub'
  ): Promise<{ fingerprint: string }> =>
    sendUserTriggeredComm(
      contactId,
      channelId,
      method,
      Constants.COMMS.interactionTypes.reviewUs,
      reviewSiteName,
      productOrigin
    )

  const sendFeedbackRequest = async (
    contactId: number,
    channelId: number,
    method: 'sms' | 'email' = 'sms'
  ): Promise<{ fingerprint: string }> =>
    sendUserTriggeredComm(
      contactId,
      channelId,
      method,
      Constants.COMMS.interactionTypes.consumerFeedback
    )

  return {
    /**
     * TODO AND NOTE - This function is going to be deprecated! Before using
     * this function, consider an approach that utilizes Main API instead.
     * @param contactsIds
     * @returns
     */
    getContactsForConversations: (contactsIds) => {
      return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
        module: 'Documents',
        method: 'mgetByKey',
        params: {
          scope: 'Customers',
          key: 'docId',
          values: contactsIds,
        },
      })
    },
    /**
     * NOTE it's possible to have someone with a phone number and not home advisor
     * @param locationId - The location ID to search
     * @param query - The contact value to search for
     * @returns A list of contacts matching the query
     */
    search: (
      locationId: number,
      query: string
    ): Promise<{ total: number; data: Contact[] }> =>
      client.get(`/v3/locations/${locationId}/contacts`, {
        params: { search: query },
      }),
    // https://github.com/signpost/glaze/blob/master/app/controllers/CustomersListController.js#L815
    create: async ({ locationId, contacts, name }) =>
      createCustomer(
        locationId,
        contacts,
        name
          ? {
              unparsedName: name,
            }
          : undefined
      ),
    sendReviewRequest,
    sendFeedbackRequest,
    deleteContacts: async (contactId) => {
      const contactIds = Array.isArray(contactId) ? contactId : [contactId]

      // TODO: Batches the requests, and waits for one batch to resolve before triggering the next.
      // Similar to https://github.com/signpost/essentials/blob/1e6f60cfb136248b2783c61fbcc4423a5f244c22/Sequencer.js#L165
      const results = await Promise.allSettled(
        contactIds.map((_contactId) =>
          client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
            module: 'Customers',
            method: 'delete',
            params: { customerId: _contactId },
          })
        )
      )

      return reduceAllSettledResponse(results)
    },
    /**
     * TODO AND NOTE - This function is going to be deprecated! Before using
     * this function, consider an approach that utilizes Main API instead.
     */
    setName: async ({ customerId, name }) => {
      return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
        module: 'Customers',
        method: 'setName',
        params: {
          customerId,
          nameData: {
            unparsedName: name,
          },
        },
      })
    },
    addChannels: async ({
      customerId,
      emailAddress,
      phoneNumber,
      mergeCustomers = false,
    }) => {
      const params: AddChannelsRequest = {
        customerId,
        mergeCustomers,
      }

      if (emailAddress !== undefined) {
        params.emailAddress = emailAddress
      }

      if (phoneNumber !== undefined) {
        params.phoneNumber = phoneNumber
      }

      return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
        module: 'Customers.Channels',
        method: 'add',
        params,
      })
    },
    /**
     * TODO AND NOTE - This function is going to be deprecated! Before using
     * this function, consider an approach that utilizes Main API instead.
     */
    setNote: async ({ customerId, notes }) => {
      return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
        module: 'Customers',
        method: 'updateNotes',
        params: {
          customerId,
          notes,
        },
      })
    },
    /**
     * TODO AND NOTE - This function is going to be deprecated! Before using
     * this function, consider an approach that utilizes Main API instead.
     */
    editCustomerFromSegment: async ({ segmentId, customerId, action }) => {
      return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
        module: 'Customers.Segments',
        method: action,
        params: {
          segmentId,
          customerId,
        },
      })
    },
    /**
     * TODO AND NOTE - This function is going to be deprecated! Before using
     * this function, consider an approach that utilizes Main API instead.
     */
    setLifecycle: async ({ customerId, lifecycleStage }) => {
      return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
        module: 'Customers',
        method: 'setLifecycle',
        params: {
          customerId,
          lifecycleStage,
        },
      })
    },
  }
}

export default Resource(actions)
