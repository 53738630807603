import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { HandleTabConversationChangeParams } from 'src/containers/MessagingHub/types'
import useAccountContext from 'src/contexts/AccountContext'
import useMhContext from 'src/contexts/MhContext'
import { isEditableConversationItem } from 'src/contexts/MhContext/utils'
import { Col } from 'src/stories/Layout'
import { getActiveLocation } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import {
  onArchive,
  onMarkAsSpam,
  onMarkRead,
  reduceEditableConversations,
} from '../utils'
import ConversationList, {
  SelectableConversationsList,
} from './ConversationList'
import ConversationListHeader from './ConversationListHeader'
import { isConversationResource } from 'src/contexts/ConversationsListContext/types'

export const StyledConversationsBox = styled(Col)(({ theme }) => ({
  minWidth: theme.space(84),
}))

interface ConversationsPaneProps {
  conversationsTab: string
  handleTabConversationChange: (
    params: HandleTabConversationChangeParams
  ) => void
  unreadConversationsCount: number
}

const ConversationsPane: React.FC<ConversationsPaneProps> = ({
  conversationsTab,
  handleTabConversationChange,
  unreadConversationsCount,
}) => {
  const { locationId } = useParams<UseLocationRouteParams>()
  const {
    isDesktop,
    openConversationId,
    sidebarValidColWidth,
    conversationsList,
    refetchConversationsList,
  } = useMhContext()
  const { locations } = useAccountContext()

  const activeLocation = getActiveLocation(locations, locationId)
  const [editMode, setEditMode] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [selectableConversationsList, setSelectableConversationsList] =
    useState<SelectableConversationsList[]>([])

  const toggleEditMode = () => {
    setEditMode((curr) => {
      if (curr) {
        setSelectAll(!curr)
        toggleConversation(
          selectableConversationsList.map((s) => s.id),
          !curr
        )
      }

      return !curr
    })
  }

  const toggleConversation = (conversationIds: number[], checked: boolean) => {
    setSelectableConversationsList((list) => {
      return list.map((item) => {
        const shouldToggle = conversationIds.some(
          (id) => id === item.id && isEditableConversationItem(item)
        )

        if (shouldToggle) {
          item.selected = checked
        }

        return item
      })
    })
  }

  useEffect(() => {
    setSelectableConversationsList((old) => {
      return conversationsList.reduce<SelectableConversationsList[]>((a, c) => {
        if (
          // "all" was the original dropdown value, but it hides archived, so we changed to "active".
          // We kept "all" here to let any saved bookmarks using "all" still work.
          ((conversationsTab === 'active' || conversationsTab === 'all') &&
            (!isConversationResource(c) || !c.isConversationArchived)) ||
          (conversationsTab === 'unread' &&
            isConversationResource(c) &&
            !c.isConversationRead) ||
          (conversationsTab === 'archived' &&
            isConversationResource(c) &&
            c.isConversationArchived)
        ) {
          a.push({
            ...c,
            selected: old.find((o) => o.id === c.id)?.selected ?? false,
          })
        }

        return a
      }, [])
    })
  }, [conversationsList, conversationsTab])

  return (
    <StyledConversationsBox
      unspaced
      w={sidebarValidColWidth}
      data-cy="conversations-pane"
    >
      <ConversationListHeader
        conversationsTab={conversationsTab}
        handleTabConversationChange={handleTabConversationChange}
        unreadConversationsCount={unreadConversationsCount}
        editMode={editMode}
        toggleEditMode={toggleEditMode}
        selectAll={selectAll}
        toggleSelectAll={(checked: boolean) => {
          setSelectAll(checked)
          toggleConversation(
            selectableConversationsList.map((s) => s.id),
            checked
          )
        }}
        selectedCount={
          selectAll
            ? selectableConversationsList.length
            : selectableConversationsList.reduce(
                (a, c) => a + (c.selected ? 1 : 0),
                0
              )
        }
        onArchiveClicked={() => {
          void onArchive(
            reduceEditableConversations(selectableConversationsList),
            activeLocation!.locationId
          ).then(() => {
            void refetchConversationsList()
            toggleEditMode()
          })
        }}
        onMarkAsSpamClicked={() => {
          void onMarkAsSpam(
            reduceEditableConversations(selectableConversationsList)
          ).then(() => {
            void refetchConversationsList()
            toggleEditMode()
          })
        }}
        onMarkReadClicked={() => {
          void onMarkRead(
            reduceEditableConversations(selectableConversationsList)
          ).then(() => {
            void refetchConversationsList()
            toggleEditMode()
          })
        }}
      />
      {/* Conversations List */}
      {(isDesktop || (!isDesktop && !openConversationId)) && (
        <ConversationList
          handleTabConversationChange={handleTabConversationChange}
          editMode={editMode}
          selectableConversationsList={selectableConversationsList}
          toggleConversation={(conversationId: number, checked: boolean) => {
            toggleConversation([conversationId], checked)
          }}
        />
      )}
    </StyledConversationsBox>
  )
}

export default ConversationsPane
