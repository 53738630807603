import {
  ApplicationMediaTypesInterface,
  ImageMediaTypesInterface,
  SupportedMediaTypesInterface,
  MediaRequests,
  VideoMediaTypesInterface,
} from '../interfaces/Media'
import axios, { AxiosInstance } from 'axios'
import Resource from '../resource'
import Constants from '../../lib/Constants'
import logger from '../../utils/logger'
import { Media } from '../interfaces/Common'
import { reduceAllSettledResponse } from '../../utils'
import { FileTooBigError } from '../../lib/Errors'

export const ImageMediaTypes: ImageMediaTypesInterface = {
  'image/jpeg': {
    contentType: 'image/jpeg',
    extensions: ['jpeg', 'jpg'],
  },
  'image/gif': {
    contentType: 'image/gif',
    extensions: ['gif'],
  },
  'image/png': {
    contentType: 'image/png',
    extensions: ['png'],
  },
  'image/bmp': {
    contentType: 'image/bmp',
    extensions: ['bmp', 'bm'],
  },
  'image/heic': {
    contentType: 'image/heic',
    extensions: ['heic'],
  },
}

export const VideoMediaTypes: VideoMediaTypesInterface = {
  'video/mp4': {
    contentType: 'video/mp4',
    extensions: ['mp4'],
  },
  'video/mpeg': {
    contentType: 'video/mpeg',
    extensions: ['mp4'],
  },
  'video/x-mp4': {
    contentType: 'video/x-mp4',
    extensions: ['mp4'],
  },
  'video/quicktime': {
    contentType: 'video/quicktime',
    extensions: ['mov'],
  },
  'video/m4v': {
    contentType: 'video/m4v',
    extensions: ['m4v'],
  },
}

export const ApplicationMediaTypes: ApplicationMediaTypesInterface = {
  'application/pdf': {
    contentType: 'application/pdf',
    extensions: ['pdf'],
  },
}

export const SupportedMediaTypes: SupportedMediaTypesInterface = {
  SMS: {
    [VideoMediaTypes['video/quicktime'].contentType]: {
      requiresTranscoding: false,
      ...VideoMediaTypes['video/quicktime'],
    },
    [VideoMediaTypes['video/x-mp4'].contentType]: {
      requiresTranscoding: false,
      ...VideoMediaTypes['video/x-mp4'],
    },
    [VideoMediaTypes['video/mpeg'].contentType]: {
      requiresTranscoding: false,
      ...VideoMediaTypes['video/mpeg'],
    },
    [VideoMediaTypes['video/mp4'].contentType]: {
      requiresTranscoding: false,
      ...VideoMediaTypes['video/mp4'],
    },
    [VideoMediaTypes['video/m4v'].contentType]: {
      requiresTranscoding: false,
      ...VideoMediaTypes['video/m4v'],
    },
    [ImageMediaTypes['image/jpeg'].contentType]: {
      requiresTranscoding: false,
      ...ImageMediaTypes['image/jpeg'],
    },
    [ImageMediaTypes['image/gif'].contentType]: {
      requiresTranscoding: false,
      ...ImageMediaTypes['image/gif'],
    },
    [ImageMediaTypes['image/png'].contentType]: {
      requiresTranscoding: false,
      ...ImageMediaTypes['image/png'],
    },
    [ImageMediaTypes['image/bmp'].contentType]: {
      requiresTranscoding: false,
      ...ImageMediaTypes['image/bmp'],
    },
    [ImageMediaTypes['image/heic'].contentType]: {
      requiresTranscoding: true,
      ...ImageMediaTypes['image/heic'],
    },
    [ApplicationMediaTypes['application/pdf'].contentType]: {
      requiresTranscoding: false,
      ...ApplicationMediaTypes['application/pdf'],
    },
  },
  FACEBOOK: {
    [ImageMediaTypes['image/jpeg'].contentType]: {
      ...ImageMediaTypes['image/jpeg'],
    },
    [ImageMediaTypes['image/gif'].contentType]: {
      ...ImageMediaTypes['image/gif'],
    },
    [ImageMediaTypes['image/png'].contentType]: {
      ...ImageMediaTypes['image/png'],
    },
    [ImageMediaTypes['image/bmp'].contentType]: {
      ...ImageMediaTypes['image/bmp'],
    },
    [ImageMediaTypes['image/heic'].contentType]: {
      ...ImageMediaTypes['image/heic'],
    },
    [VideoMediaTypes['video/quicktime'].contentType]: {
      ...VideoMediaTypes['video/quicktime'],
    },
    [VideoMediaTypes['video/x-mp4'].contentType]: {
      ...VideoMediaTypes['video/x-mp4'],
    },
    [VideoMediaTypes['video/mpeg'].contentType]: {
      ...VideoMediaTypes['video/mpeg'],
    },
    [VideoMediaTypes['video/mp4'].contentType]: {
      ...VideoMediaTypes['video/mp4'],
    },
    [VideoMediaTypes['video/m4v'].contentType]: {
      ...VideoMediaTypes['video/m4v'],
    },
  },
}

export const getSupportedMediaContentType = (
  commMethod: keyof SupportedMediaTypesInterface
) => {
  const mediaSupportedByMethod = SupportedMediaTypes[commMethod]
  const contentTypes: Record<string, string> = {}

  if (!mediaSupportedByMethod) return {}

  Object.keys(mediaSupportedByMethod).forEach((type) => {
    const extensions = mediaSupportedByMethod[type].extensions

    extensions.forEach((extension) => {
      contentTypes[extension] = type
    })
  })

  return contentTypes
}

const actions = (client: AxiosInstance): MediaRequests => {
  const isFileTypeImageType = (fileType: string) => fileType.startsWith('image')
  const getMediaUploadUrls: MediaRequests['getMediaUploadUrls'] = async (
    count
  ) =>
    client.post(Constants.Backend.Endpoints.MEDIA_UPLOAD_URLS, {
      count,
    })

  const getVideoFile: MediaRequests['getVideoFile'] = async (url) => {
    const data = await client.post<Blob, Blob>(
      Constants.Backend.Endpoints.GET_VIDEO_FILE,
      {
        url,
      },
      {
        responseType: 'blob',
      }
    )

    return { data }
  }

  const getPresignedUrlMedia: MediaRequests['getPresignedUrlMedia'] = async (
    url
  ) => {
    // Note: this uses the vanilla axios client so that the authorization header is not provided
    // when making requests to S3. AWS complains if we provide the authorization header on the
    // signed URLs that we use to download files from S3.
    const data = await axios.get<Blob, Blob>(url, {
      responseType: 'blob',
    })

    return { data }
  }

  const uploadMedia: MediaRequests['uploadMedia'] = async (
    media,
    method,
    url
  ) => {
    logger.info(`Uploading file ${media.name} to ${url}`)

    const contentTypes = getSupportedMediaContentType(method)
    const methodSizeLimits = Constants.MEDIA.maxMediaSizeBytes[method]

    if (
      !Object.values(contentTypes).includes(media.type) ||
      (isFileTypeImageType(media.type) &&
        media.size > methodSizeLimits.photo) ||
      (!isFileTypeImageType(media.type) && media.size > methodSizeLimits.video)
    ) {
      throw new FileTooBigError(media.type, methodSizeLimits)
    }

    // Create placeholder ID
    const fileId = `${media.name}_${new Date().getTime()}`

    // Get contentType
    const contentType = media.type

    const placeholderMedia = {
      fileId,
      presignedUrl: url,
      contentType,
      url: URL.createObjectURL(media),
    }

    // We use fetch here since the url is one we generate for the image
    // in order to get the blob.
    const fileData = await fetch(placeholderMedia.url)
    const imageBlob = await fileData.blob()

    // We use fetch here instead of axios since Axios requires more setup in order
    // to handle a Blob body, aside from it, the presigned url is one we generate on
    // the Backend and it points AWS S3 Bucket directly and we do not handle this request.
    await fetch(placeholderMedia.presignedUrl, {
      method: 'PUT',
      body: imageBlob,
    })

    return {
      url: placeholderMedia.presignedUrl,
      contentType: placeholderMedia.contentType,
    }
  }

  return {
    getMediaUploadUrls,
    getPresignedUrlMedia,
    getVideoFile,
    uploadMedia,
    bulkUploadMedia: async (medias, method) => {
      const urls = await getMediaUploadUrls(medias.length)

      const allSettled = await Promise.allSettled(
        medias.map<Promise<Media>>(async (media, idx) => {
          const url = urls[idx]

          if (!url) {
            const errorMessage = 'Missing URL to send Media'

            logger.error(errorMessage)
            throw new Error(errorMessage)
          }

          return uploadMedia(media, method, url)
        })
      )

      return reduceAllSettledResponse(allSettled)
    },
  }
}

export default Resource(actions)
