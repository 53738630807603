import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

interface PlaceHolderInterface {
  name: string
  audience: string
  totalEnrolled: string
  lastUpdated: string
}

const columnHelper = createColumnHelper<PlaceHolderInterface>()

type ColumnDefinition = ColumnDef<
  PlaceHolderInterface,
  PlaceHolderInterface[keyof PlaceHolderInterface]
>

export const useYourCampaignsTable = (data: PlaceHolderInterface[]) => {
  const { isSmallScreen, isMediumScreen } = useScreenSizes()

  const columns: ColumnDefinition[] = [
    columnHelper.accessor('name', {
      header: 'Campaign name',
      cell: (info) => <>{info.getValue()}</>,
    }),
    ...(isSmallScreen
      ? [
          columnHelper.accessor('audience', {
            header: 'Audience',
            cell: (info) => <>{info.getValue()}</>,
          }),
        ]
      : []),
    ...(isMediumScreen
      ? [
          columnHelper.accessor('totalEnrolled', {
            header: 'Total enrolled',
            cell: (info) => <>{info.getValue()}</>,
          }),
          columnHelper.accessor('lastUpdated', {
            header: 'Last Updated',
            cell: (info) => <>{info.getValue()}</>,
          }),
        ]
      : []),
    columnHelper.display({
      header: '',
      id: 'final-row',
      cell: () => {
        return <>...</>
      },
    }),
  ]

  const table = useReactTable<PlaceHolderInterface>({
    columns,
    data,
    debugTable: process.env.REACT_APP_ENVIRONMENT === 'development',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
  })

  return { table, columnsCount: columns.length }
}
