import styled from 'styled-components'

interface BannerGridProps {
  $gridColumns?: string
  $gridRows?: string
}
export const BannerGrid = styled.div<BannerGridProps>(
  ({ theme, $gridColumns, $gridRows }) => ({
    backgroundColor: theme.colors.primary_2_10,
    display: 'grid',
    gridTemplateColumns: $gridColumns || '45% 55%',
    gridTemplateRows: $gridRows || '100%',
    borderBottom: `1px solid ${theme.colors.base_20}`,
  })
)

interface BannerLeftItemProps {
  $marginLeft?: number
  $marginTop?: number
  $paddingBottom?: number
}

export const BannerLeftItem = styled.div<BannerLeftItemProps>(
  ({ theme, $marginLeft, $marginTop, $paddingBottom }) => ({
    marginLeft: theme.space($marginLeft || 16),
    marginTop: theme.space($marginTop || 11),
    paddingBottom: theme.space($paddingBottom || 12.75),
  })
)

export const BannerBigText = styled.div(({ theme }) => ({
  fontSize: '3.6rem',
  lineHeight: '4.8rem',
  color: theme.colors.base_100,
}))

export const BannerSmallText = styled.div(({ theme }) => ({
  fontSize: '1.3rem',
  color: theme.colors.base_90,
}))
