import React from 'react'
import Card from 'src/stories/Card'
import styled, { DefaultTheme } from 'styled-components'

import { CompanyIcon } from 'src/stories/assets'
import { IconWrapper } from 'src/containers/Campaigns/Conversion/PreBuiltTemplates/components/IconWrapper'

const Title = styled.p(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '1.8rem',
  lineHeight: '2.4rem',
  fontWeight: '400',
  margin: 0,
  marginTop: theme.space(3),
}))

const Subtitle = styled.p(({ theme }) => ({
  color: theme.colors.base_60,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  fontWeight: '400',
  margin: 0,
  marginTop: theme.space(6),
}))

const BuiltByLabel = styled.p(({ theme }) => ({
  color: theme.colors.base_40,
  fontSize: '1.4rem',
  lineHeight: '2.4rem',
  fontWeight: '500',
  margin: 0,
  marginRight: theme.space(2),
}))

const SignpostLabel = styled.p(({ theme }) => ({
  color: theme.colors.primary_1,
  fontSize: '1.4rem',
  lineHeight: '2.0rem',
  fontWeight: '500',
  margin: 0,
}))

const BuiltBySpan = styled.span(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.space(1),
  marginTop: theme.space(1),
}))

interface Props {
  icon: JSX.Element
  /** primary_2_15 by default */
  overrideIconWrapperColor?: keyof DefaultTheme['colors']
  title: string
  /** True by default */
  builtBySignpost?: boolean
  subtitle: string
  baseDataAttribute: string
}

const PreBuiltTemplateCard: React.FC<Props> = ({
  icon,
  overrideIconWrapperColor = 'primary_2_15',
  title,
  builtBySignpost = true,
  subtitle,
  baseDataAttribute,
}) => {
  return (
    <Card baseDataAttribute={baseDataAttribute}>
      <IconWrapper
        $color={overrideIconWrapperColor}
        data-cy={`${baseDataAttribute}-icon-wrapper`}
      >
        {icon}
      </IconWrapper>
      <Title data-cy={`${baseDataAttribute}-title`}>{title}</Title>
      {builtBySignpost && (
        <BuiltBySpan data-cy={`${baseDataAttribute}-built-by-signpost-wrapper`}>
          <BuiltByLabel data-cy={`${baseDataAttribute}-built-by-label`}>
            Built-by
          </BuiltByLabel>
          <CompanyIcon
            data-cy={`${baseDataAttribute}-built-by-signpost-icon`}
          />
          <SignpostLabel
            data-cy={`${baseDataAttribute}-built-by-signpost-label`}
          >
            Signpost
          </SignpostLabel>
        </BuiltBySpan>
      )}
      <Subtitle data-cy={`${baseDataAttribute}-subtitle`}>{subtitle}</Subtitle>
    </Card>
  )
}

export default PreBuiltTemplateCard
