import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import Locations from 'src/client/resources/LocationsLegacy'

import Constants from 'src/lib/Constants'
import logger from 'src/utils/logger'

const useUpdateLocationReviewSiteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (
      params: { locationId: number } & (
        | { googlePlaceExternalId: string }
        | { facebookUrl: string }
        | { yelpUrl: string }
      )
    ) => {
      const { locationId, ...reviewSiteParams } = params

      await Locations.updateMarketingInformation(
        params.locationId,
        reviewSiteParams
      )

      let reviewSiteId = 0

      if (
        (reviewSiteParams as { googlePlaceExternalId: string })
          .googlePlaceExternalId
      ) {
        reviewSiteId = Constants.ReviewSites.GOOGLE.id
      } else if ((reviewSiteParams as { facebookUrl: string }).facebookUrl) {
        reviewSiteId = Constants.ReviewSites.FACEBOOK.id
      } else if ((reviewSiteParams as { yelpUrl: string }).yelpUrl) {
        reviewSiteId = Constants.ReviewSites.YELP.id
      }

      if (reviewSiteId) {
        await Locations.addReviewSiteToLocation(locationId, reviewSiteId)
        await Locations.confirmReviewSite({ locationId, reviewSiteId })
      }

      return {
        locationId,
      }
    },
    {
      onSuccess: async ({ locationId }) => {
        try {
          await queryClient.invalidateQueries({
            queryKey: ['locations', { locationId }],
          })
          await queryClient.invalidateQueries({
            queryKey: ['public-information', { locationId }],
          })
          await queryClient.invalidateQueries({
            queryKey: ['review-sites', { locationId }],
          })

          toast.success('Review site updated')
        } catch (error) {
          logger.error(
            'There was an error trying to refresh the location information after updating review site.',
            { error }
          )
        }
      },
      onError: (error) => {
        toast.error(
          'There was an error trying to update the review site. Please try again.'
        )
        logger.error('There was an error trying to update the Review Site.', {
          error,
        })
      },
    }
  )
}

export default useUpdateLocationReviewSiteMutation
