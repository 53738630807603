import React from 'react'
import styled, { useTheme } from 'styled-components'

import useInsightsContext from 'src/contexts/InsightsContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { InsightsWidget } from './InsightsWidget'
import {
  StyledMainStatContainer,
  StyledWidgetContentContainer,
  StyledWidgetMainStat,
  StyledWidgetStat,
  WidgetReviewBubble,
} from './styled'
import { WidgetProps } from './types'

const StyledSecondaryReviewCountContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const StyledSecondaryReviewCountInnerContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const ReviewsCountByReviewSiteWidget: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const {
    allTimeReviewsCount,
    reviewSiteData,
    isReviewsZeroState,
    isReviewLoading,
  } = useInsightsContext()

  if (isReviewLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  return (
    <InsightsWidget
      addDivider
      baseDataAttribute={baseDataAttribute}
      title="All-time Reviews"
      subtitle={
        <StyledMainStatContainer
          data-cy={baseDataAttribute + '-main-stat-container'}
        >
          <StyledWidgetMainStat
            useSecondaryColor={isReviewsZeroState}
            data-cy={baseDataAttribute + '-main-stat'}
          >
            {isReviewsZeroState
              ? '\u2013'
              : allTimeReviewsCount.toLocaleString()}
          </StyledWidgetMainStat>
        </StyledMainStatContainer>
      }
    >
      <StyledWidgetContentContainer
        data-cy={baseDataAttribute + '-review-sites'}
      >
        {reviewSiteData.map((rsd, idx) => {
          const reviewSiteDataCy = `${baseDataAttribute}-${rsd.reviewSiteId!}`

          return (
            <StyledSecondaryReviewCountContainer
              key={`reviews-count-by-review-site-item-${idx}`}
              data-cy={reviewSiteDataCy}
            >
              <WidgetReviewBubble
                dataCy={reviewSiteDataCy}
                icon={rsd.displayName}
                reviewSiteName={rsd.displayName}
              />
              <StyledSecondaryReviewCountInnerContainer
                data-cy={reviewSiteDataCy + '-stat'}
              >
                <StyledWidgetStat
                  useSecondaryColor={rsd.isZeroState}
                  data-cy={reviewSiteDataCy + '-review-count'}
                >
                  {rsd.isZeroState
                    ? '\u2013'
                    : rsd.reviewCount?.toLocaleString()}
                </StyledWidgetStat>
              </StyledSecondaryReviewCountInnerContainer>
            </StyledSecondaryReviewCountContainer>
          )
        })}
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default ReviewsCountByReviewSiteWidget
