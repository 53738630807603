import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCustomVoiceResponder } from 'src/containers/Settings/InstantResponders/hooks'
import useAccountContext from 'src/contexts/AccountContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import AudioPlayer from 'src/stories/AudioPlayer'
import { Button } from 'src/stories/Button'
import Toggle from 'src/stories/Toggle'
import { generateLocationUrl, getActiveLocation } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import styled from 'styled-components'
import RecordVoiceResponseModal from '../RecordVoiceResponseModal'
import Link from 'src/stories/Link'

const StyledContainer = styled.section(({ theme }) => ({
  marginBottom: theme.space(4),
  width: '100%',
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  padding: `${theme.space(2)} ${theme.space(3)}`,
}))

const StyledHeader = styled.div<{ isDesktop: boolean; isEnabled: boolean }>(
  ({ theme, isEnabled, isDesktop }) => ({
    width: '100%',
    boxShadow: `inset 0 0 0 1px ${theme.colors.base_20}`,
    borderRadius: `${theme.constants.borderRadius} ${
      theme.constants.borderRadius
    } ${isEnabled ? '0px' : theme.constants.borderRadius} ${
      isEnabled ? '0px' : theme.constants.borderRadius
    }`,
    padding: `${theme.space(2)} ${theme.space(6)}`,
    display: 'flex',
    flexDirection: isDesktop ? 'row' : 'column',
    justifyContent: 'space-between',
    alignItems: isDesktop ? 'center' : 'flex-start',
  })
)

const StyledHeaderLeftItemsContainer = styled.div<{
  isDesktop?: boolean
}>(({ theme, isDesktop }) => ({
  display: 'flex',
  flexDirection: !isDesktop ? 'column' : 'row',
  justifyContent: !isDesktop ? 'space-between' : undefined,
  width: !isDesktop ? '100%' : undefined,
  columnGap: theme.space(4),
  rowGap: theme.space(2),
}))

const StyledHeaderItemContainer = styled.div<{ columnGap?: number }>(
  ({ theme, columnGap }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: columnGap ? theme.space(columnGap) : theme.space(8),
    rowGap: theme.space(2),
  })
)

const StyledToggleContainer = styled.label<{ isEnabled: boolean }>(
  ({ theme, isEnabled }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.space(4),
    fontSize: '1.4rem',
    color: isEnabled ? theme.colors.base_100 : theme.colors.base_50,
    transition: 'color 0.3s ease',
  })
)

const StyledBody = styled.div<{ bodyHeight: number; isEnabled: boolean }>(
  ({ theme, bodyHeight, isEnabled }) => ({
    width: '100%',
    borderTop: '0px',
    boxShadow: `inset 0 0 0 1px ${theme.colors.base_20}`,
    borderRadius: `0px 0px ${theme.constants.borderRadius} ${theme.constants.borderRadius}`,
    transition: 'all 0.3s ease-out',
    transitionProperty: 'max-height, border-radius',
    maxHeight: isEnabled ? `calc(${bodyHeight}px + ${theme.space(8)})` : 0,
    overflow: 'hidden',
  })
)

const StyledContent = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    padding: isDesktop
      ? `${theme.space(6)} ${theme.space(6)} ${theme.space(9)} ${theme.space(
          36
        )}`
      : theme.space(4),
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    gap: theme.space(2),
  })
)

const StyledContentTitle = styled.label(() => ({
  display: 'block',
  fontSize: '1.4rem',
  fontWeight: 500,
}))

const StyledContentSubtitle = styled.label(({ theme }) => ({
  color: theme.colors.base_50,
  display: 'block',
  fontSize: '1.4rem',
  marginTop: theme.space(2),
}))

const StyledAudioContainer = styled.div(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.space(2),
  paddingBottom: theme.space(2),
}))

const StyledRecordButtonContainer = styled.div(({ theme }) => ({
  display: 'inline-flex',
  marginTop: theme.space(2),
}))

const StyledDefaultMessage = styled.p(({ theme }) => ({
  fontSize: '1.6rem',
  fontWeight: 500,
  color: theme.colors.primary_1,
}))

interface InstantVoiceResponseSettingsProps {
  audioURL: string | null
  isEnabled: boolean
}

const InstantVoiceResponseSettings: React.FC<
  InstantVoiceResponseSettingsProps
> = ({ isEnabled, audioURL }) => {
  const { isSmallScreen: isDesktop } = useScreenSizes()
  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState(500)

  const { locationId, merchantId } = useParams<UseLocationRouteParams>()
  const { locations, handleRefetchUserLocations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)

  const {
    audioResponseURL,
    isToggleEnabled,
    onToggle,
    deleteAudio,
    saveAudio,
  } = useCustomVoiceResponder({
    locationId: activeLocation?.locationId,
    audioURL,
    isEnabled,
    refetchLocationFn: handleRefetchUserLocations,
  })

  const noAudioRecorded = !audioResponseURL

  const { showModal } = useModalNotificationsContext()

  useLayoutEffect(() => {
    setContentHeight(
      isToggleEnabled ? contentRef.current?.clientHeight ?? 500 : 0
    )
  }, [isToggleEnabled])

  const handleClickRecordButton = useCallback(() => {
    showModal({
      title: 'Record voice response',
      hideActionButtons: true,
      customBody: <RecordVoiceResponseModal onClickSave={saveAudio} />,
      height: 'auto',
    })
  }, [saveAudio, showModal])

  const phoneSettingsURL = generateLocationUrl(
    merchantId!,
    locationId!,
    `/settings#section-settings-phone`
  )

  return (
    <StyledContainer>
      <StyledHeader isEnabled={isToggleEnabled} isDesktop={isDesktop}>
        <StyledHeaderItemContainer columnGap={2}>
          <h2>Instant voice response</h2>
        </StyledHeaderItemContainer>
        <StyledHeaderLeftItemsContainer isDesktop={isDesktop}>
          <StyledToggleContainer isEnabled={isToggleEnabled}>
            <div>Play voice response</div>
            <Toggle
              dataCy={`settings-instant-responders-voice-response-toggle-${
                isToggleEnabled ? 'disable' : 'enable'
              }`}
              checked={isToggleEnabled}
              onChange={onToggle}
            />
          </StyledToggleContainer>
        </StyledHeaderLeftItemsContainer>
      </StyledHeader>
      <StyledBody isEnabled={isToggleEnabled} bodyHeight={contentHeight}>
        <StyledContent ref={contentRef} isDesktop={isDesktop}>
          <div>
            <StyledContentTitle>
              Your voice message played for missed calls:
            </StyledContentTitle>
            {noAudioRecorded ? (
              <>
                <StyledContentSubtitle>
                  No Instant Voice Response recorded. The default message will
                  be played to all missed calls:
                </StyledContentSubtitle>
                <StyledDefaultMessage>
                  "Thank you for calling. Please leave a message."
                </StyledDefaultMessage>
              </>
            ) : (
              <>
                <StyledContentSubtitle>
                  This message will be played to all missed calls.
                </StyledContentSubtitle>
                <StyledAudioContainer>
                  <AudioPlayer src={audioResponseURL ?? ''} />
                </StyledAudioContainer>
              </>
            )}
            <StyledRecordButtonContainer>
              <Button
                action="primary"
                maxHeight={32}
                label="Record a message"
                onClick={handleClickRecordButton}
                baseDataAttribute="settings-instant-responders-voice-response-record"
              />
            </StyledRecordButtonContainer>
          </div>
          <div>
            <Button
              action="danger"
              maxHeight={32}
              label="Delete"
              onClick={deleteAudio}
              disabled={noAudioRecorded}
              baseDataAttribute="settings-instant-responders-voice-response-delete"
            />
            <StyledLink
              baseDataAttribute="change-default-ivr"
              href={phoneSettingsURL}
            >
              Change default IVR voice
            </StyledLink>
          </div>
        </StyledContent>
      </StyledBody>
    </StyledContainer>
  )
}

export default InstantVoiceResponseSettings
