import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { ArrowIcon, HamburgerIcon } from './assets'
import Backdrop from './Backdrop'
import { Button } from './Button'
import Sidedrawer from './Sidedrawer'
import { Col, Row } from './Layout'

export interface WizardStep {
  label: string
  disabled?: boolean
}

export interface WizardLayoutProps {
  asideContent?: React.ReactNode
  fullWidth?: boolean
}

export interface WizardProps {
  title: string
  baseDateAttribute?: string
  onClickGoBack: () => void
  steps: WizardStep[]
  showSteps?: boolean
  currentStepIndex?: number
  onClickStep: (index: number, step: WizardStep) => void
  disabled: boolean
  disableGoBack?: boolean
}

const StyledWizardContainer = styled.div(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

const StyledHeader = styled.header<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    height: theme.heights.navbar,
    display: 'flex',
    padding: isDesktop ? `0 ${theme.space(7)}` : 0,
    backgroundColor: theme.colors.primary_1,
    alignItems: 'center',
    color: theme.colors.base_0,
    gap: theme.space(8),
  })
)

const StyledTitle = styled.h1(() => ({
  fontWeight: 500,
}))

const StyledBody = styled.div(() => ({
  display: 'flex',
  flex: 1,
}))

const StyledWizardSidebarContainer = styled.div(({ theme }) => ({
  width: theme.space(80),
  maxWidth: theme.space(80),
  borderRight: `2px solid ${theme.colors.base_20}`,
  padding: `${theme.space(10)} ${theme.space(3)}`,
}))

const StyledStepsTitle = styled.label(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.colors.base_50,
  fontSize: '1rem',
  fontWeight: 500,
  margin: `${theme.space(2)} 0`,
  display: 'block',
  paddingLeft: theme.space(5),
}))

const StyledStepButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(1),
}))

const StyledStepButton = styled.button<{ isCurrentStep: boolean }>(
  ({ theme, isCurrentStep }) => ({
    border: 'none',
    borderRadius: theme.constants.borderRadius,
    padding: `${theme.space(2)} ${theme.space(5)}`,
    background: isCurrentStep ? theme.colors.base_5 : 'transparent',
    textAlign: 'left',
    fontSize: '1.4rem',
    color: isCurrentStep ? theme.colors.base_100 : theme.colors.base_50,
    fontWeight: isCurrentStep ? 600 : 400,
    cursor: 'pointer',
    '&:hover:not([disabled])': {
      background: theme.colors.base_5,
    },
    '&:disabled': {
      cursor: 'initial',
    },
  })
)

const StyledStepNumber = styled.div<{ isCurrentStep: boolean }>(
  ({ theme, isCurrentStep }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
    background: isCurrentStep ? theme.colors.primary_2 : theme.colors.base_3,
    border: `1px solid ${
      isCurrentStep ? theme.colors.primary_2 : theme.colors.base_20
    }`,
    color: isCurrentStep ? theme.colors.base_0 : theme.colors.base_50,
    marginRight: theme.space(1),
  })
)

const StyledWizardContent = styled.div(({ theme }) => ({
  display: 'flex',
  flex: 1,
  padding: theme.space(8),
  height: `calc(100vh - ${theme.heights.navbar})`,
  overflow: 'auto',
}))

const StyledWizardStepsContainer = styled.div(({ theme }) => ({
  marginTop: theme.space(8),
}))

const StyledHamburgerIconContainer = styled.div(({ theme }) => ({
  background: theme.colors.base_0,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: `0 ${theme.space(4)}`,
}))

const StyledSidedrawerContainer = styled.div(({ theme }) => ({
  padding: `${theme.space(10)} ${theme.space(2)}`,
}))

const StyledWizardLayoutRow = styled(Row)(({ theme }) => ({
  rowGap: theme.space(12),
  columnGap: theme.space(22),
  maxWidth: theme.space(240),
}))

const WizardSidebar: React.FC<Omit<WizardProps, 'title'>> = ({
  onClickGoBack,
  steps,
  showSteps = true,
  currentStepIndex,
  disabled,
  disableGoBack = false,
  onClickStep,
  baseDateAttribute = 'wizard-sidebar',
}) => {
  const theme = useTheme()

  return (
    <>
      <Button
        displayAsText
        size="large"
        label="Go back"
        baseDataAttribute={`${baseDateAttribute}-go-back`}
        style={{ marginLeft: theme.space(3) }}
        icon={ArrowIcon}
        disabled={disableGoBack}
        onClick={onClickGoBack}
      />

      <StyledWizardStepsContainer>
        {showSteps && (
          <>
            <StyledStepsTitle>Steps</StyledStepsTitle>
            <StyledStepButtonsContainer data-cy="wizard-steps-buttons-container">
              {steps.length &&
                steps.map((step, index) => {
                  const disableStep = disabled || step?.disabled

                  return (
                    <StyledStepButton
                      key={step.label}
                      data-dd={
                        disableStep
                          ? 'wizard-step'
                          : `${baseDateAttribute}-${step.label}-step`
                      }
                      isCurrentStep={currentStepIndex === index}
                      disabled={disableStep}
                      onClick={() => onClickStep(index, step)}
                    >
                      <StyledStepNumber
                        isCurrentStep={currentStepIndex === index}
                      >
                        {index + 1}
                      </StyledStepNumber>
                      {step.label}
                    </StyledStepButton>
                  )
                })}
            </StyledStepButtonsContainer>
          </>
        )}
      </StyledWizardStepsContainer>
    </>
  )
}

export const WizardLayout: React.FCWithChildren<WizardLayoutProps> = ({
  asideContent,
  children,
  fullWidth = false,
}) => {
  const theme = useTheme()
  const { isLargeScreen: isDesktop } = useScreenSizes()
  const bottomSpace = { paddingBottom: theme.space(8) }

  return (
    <StyledWizardLayoutRow direction={isDesktop ? 'row' : 'column'}>
      <Col w={2} unspaced>
        <div style={isDesktop || !asideContent ? bottomSpace : {}}>
          {children}
        </div>
      </Col>
      {(asideContent || !fullWidth) && (
        <Col unspaced style={asideContent ? bottomSpace : {}}>
          <aside>{asideContent}</aside>
        </Col>
      )}
    </StyledWizardLayoutRow>
  )
}

const Wizard: React.FCWithChildren<WizardProps> = ({
  title,
  children,
  ...rest
}) => {
  const theme = useTheme()
  const { isLargeScreen: isDesktop } = useScreenSizes()
  const [openDrawer, setOpenDrawer] = useState(false)
  const sidebar = <WizardSidebar {...rest} />

  return (
    <StyledWizardContainer>
      <Backdrop show={openDrawer} />
      <StyledHeader isDesktop={isDesktop}>
        {!isDesktop && (
          <StyledHamburgerIconContainer
            onClick={() => setOpenDrawer((o) => !o)}
            data-cy="wizard-drawer-button"
          >
            <HamburgerIcon fill={theme.colors.primary_2} />
          </StyledHamburgerIconContainer>
        )}
        <StyledTitle>{title}</StyledTitle>
      </StyledHeader>
      <StyledBody>
        {isDesktop ? (
          <StyledWizardSidebarContainer>{sidebar}</StyledWizardSidebarContainer>
        ) : (
          <Sidedrawer
            onClose={() => setOpenDrawer(false)}
            open={openDrawer}
            showLogo={false}
          >
            <StyledSidedrawerContainer>{sidebar}</StyledSidedrawerContainer>
          </Sidedrawer>
        )}
        <StyledWizardContent>{children}</StyledWizardContent>
      </StyledBody>
    </StyledWizardContainer>
  )
}

export default Wizard
