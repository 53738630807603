import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import Header from 'src/components/Header'
import MultiLocationHeader from 'src/components/Header/MultiLocationHeader'
import TermsUpdateBanner from 'src/components/Insights/Banners/TermsUpdateBanner'
import LayoutPageExample from 'src/containers/LayoutPageExample/LayoutPageExample'
import LegacyPages from 'src/containers/LegacyPages'
import useAuthContext from 'src/contexts/AuthContext'
import Constants from 'src/lib/Constants'

export const multiLocationSlugs = {
  locations: 'Location',
  feedback: 'Feedback',
  reviews: 'Review',
  'scheduled-campaigns': 'Scheduled Campaigns',
  settings: 'Account Settings',
  'email-subscription': 'Communications Preferences',
}

const MultiLocationRoutes: React.FC = () => {
  const { user } = useAuthContext()
  const { pathname } = useLocation()

  const currentLocationSlug = `${pathname.split('/')[3]}`

  useEffect(() => {
    if (!currentLocationSlug) {
      document.title = `Overview - ${Constants.Branding.companyName}`
    } else {
      const slug =
        multiLocationSlugs[
          currentLocationSlug as keyof typeof multiLocationSlugs
        ]

      if (slug) {
        document.title = `${slug} - ${Constants.Branding.companyName}`
      } else {
        document.title = `${Constants.Branding.companyName}`
      }
    }
  }, [currentLocationSlug])

  return user ? (
    <>
      <TermsUpdateBanner />
      <MultiLocationHeader dark />
      <Header pageType="MultiLocation" />

      <Routes>
        {/* Example on how to strangle "/body-layout" route from glaze into web-app-frontend */}
        <Route path={`/body-layout`} element={<LayoutPageExample />} />

        <Route
          path="/settings"
          element={<LegacyPages withMultiLocationHeader withoutHeader />}
        />

        <Route
          path="/email-subscription"
          element={<LegacyPages withMultiLocationHeader withoutHeader />}
        />

        <Route path="*" element={<LegacyPages withMultiLocationHeader />} />
      </Routes>
    </>
  ) : null
}

export default MultiLocationRoutes
