import React, { ReactElement, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import MessageHeader from 'src/components/MessagingHub/MessagesPane/ZeroState/MessageHeader'
import YoutubeIframe from 'src/components/YoutubeIframe'
import useAccountContext from 'src/contexts/AccountContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Col, Row } from 'src/stories/Layout'
import { getActiveLocation, getLocationInformation } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'

interface StyledContainerInterface {
  $isDesktop: boolean
  $isMediumDesktop: boolean
}
const StyledContainer = styled(Row)<StyledContainerInterface>(
  ({ theme, $isDesktop, $isMediumDesktop }) => ({
    flexGrow: 1,
    margin: `${$isMediumDesktop ? theme.space(5) : 0} 0`,
    padding: `0 ${theme.space($isDesktop ? 20 : 10)}`,
    height: '100%',
    overflowY: 'scroll',
  })
)

const StyledContent = styled(Col)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: theme.space(130),
  margin: `${theme.space(5)} auto`,
  marginBottom: theme.space(10),
}))

const StyledBubble = styled.div(({ theme }) => ({
  marginTop: theme.space(2),
  padding: `${theme.space(3)} ${theme.space(4)}`,
  border: `1px solid ${theme.colors.base_40}`,
  borderRadius: '8px',
  backgroundColor: theme.colors.base_5,
  color: theme.colors.base_40,
  fontSize: '1.3rem',
}))

interface ZeroStateMessage {
  id: string
  shouldDisplay: () => boolean
  insertScrollerRefAfter?: boolean
  video?: {
    title: string
    url: string
    dataCy?: string
  }
  text?: ReactElement
}

interface ZeroStateProps {
  greeting: string
}

const ZeroState: React.FC<ZeroStateProps> = ({ greeting }) => {
  const scrollerRef = useRef<HTMLDivElement>(null)
  const { isLargeScreen: isLargeDesktop, isMediumScreen: isMediumDesktop } =
    useScreenSizes()

  const { locations } = useAccountContext()
  const { locationId } = useParams<UseLocationRouteParams>()
  const activeLocation = getActiveLocation(locations, locationId)
  const { isLiveReceptionistOnly } = getLocationInformation(activeLocation)

  const messages: ZeroStateMessage[] = [
    {
      id: 'welcome-message',
      shouldDisplay: () => !isLiveReceptionistOnly,
      insertScrollerRefAfter: false,
      text: (
        <>
          {greeting}Welcome to the Messaging Hub! <br />
          Communicating with leads and customers just got easier. Never miss a
          lead by bringing multiple sources into one central location in the
          Messaging Hub. If you have any questions, our Support Team’s email and
          phone number are located on the right side of this screen.{' '}
        </>
      ),
    },
  ]

  const filteredMessages = messages.filter((message) => message.shouldDisplay())

  useEffect(() => {
    scrollerRef.current?.scrollIntoView()
  }, [])

  return (
    <StyledContainer
      direction="column"
      $isDesktop={isLargeDesktop}
      $isMediumDesktop={isMediumDesktop}
    >
      <StyledContent>
        {filteredMessages.map((message) => {
          return (
            <React.Fragment key={message.id}>
              <MessageHeader />
              {message.video && (
                <YoutubeIframe
                  url={message.video.url}
                  title={message.video.url}
                />
              )}
              {message.text && (
                <StyledBubble>
                  <p>{message.text}</p>
                </StyledBubble>
              )}
            </React.Fragment>
          )
        })}
      </StyledContent>
    </StyledContainer>
  )
}

export default ZeroState
