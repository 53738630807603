import React from 'react'
import styled from 'styled-components'

import PreBuiltTemplateCard from 'src/containers/Campaigns/Conversion/PreBuiltTemplates/components/PreBuiltTemplateCard'
import { LeadIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

interface GridProps {
  /** defaults to 1 */
  columns?: number
}

const Grid = styled.div<GridProps>(({ theme, columns = 1 }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  gap: theme.space(5),
}))

interface Props {
  baseDataAttribute: string
}

const CampaignsPrebuiltTemplates: React.FC<Props> = ({ baseDataAttribute }) => {
  const { isMediumScreen } = useScreenSizes()

  return (
    <Grid columns={isMediumScreen ? 3 : 1}>
      <PreBuiltTemplateCard
        baseDataAttribute={`${baseDataAttribute}-new-lead-card`}
        icon={<LeadIcon />}
        title="New lead follow-up"
        subtitle="Instantly respond to new leads and continue to automatically follow up."
      />
      {/* Phase 2
      <PreBuiltTemplateCard
        baseDataAttribute={`${baseDataAttribute}-quote-card`}
        icon={<LeadIcon />}
        title="Quote follow-up"
        subtitle="Automatically send follow up texts and emails to quoted customers."
      /> */}
    </Grid>
  )
}

export default CampaignsPrebuiltTemplates
