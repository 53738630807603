import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import useAuthContext from 'src/contexts/AuthContext'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'

export const StyledLoginForm = styled.form(({ theme }) => ({
  width: '100%',
  padding: `${theme.space(8)} ${theme.space(7)}`,
  paddingBottom: theme.space(10),
  backgroundColor: theme.colors.base_3,
  border: `1px solid ${theme.colors.base_20}`,
}))

export const StyledOr = styled.span(({ theme }) => ({
  float: 'left',
  position: 'relative',
  padding: `0 ${theme.space(2)}`,
  marginTop: theme.space(-2),
  marginBottom: theme.space(-2),
  fontSize: '1.4rem',
  color: theme.colors.base_40,
  backgroundColor: theme.colors.base_3,
}))

export const StyledMagicContainer = styled.div(({ theme }) => ({
  width: '100%',
  padding: `${theme.space(8)} ${theme.space(7)}`,
  paddingTop: theme.space(10),
  backgroundColor: theme.colors.base_3,
  border: `1px solid ${theme.colors.base_20}`,
  borderTop: 'none',
}))

export const StyledValidationErrorText = styled.p(({ theme }) => ({
  color: theme.colors.critical,
  fontSize: '1.4rem',
}))

const loginSchema = yup
  .object({
    email: yup
      .string()
      .email('Please check your email address and try again.')
      .required('Please check your email address and try again.'),
    password: yup.string().required('Please enter your password.'),
  })
  .required()

interface LoginForm {
  email: string
  password: string
}

const PasswordLoginForm: React.FC = () => {
  const { handleLogin } = useAuthContext()
  const [loginError, setLoginError] = useState('')

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting, dirtyFields },
    resetField,
    watch,
    register,
  } = useForm<LoginForm>({
    resolver: yupResolver(loginSchema),
  })

  const formWatcher = watch()

  useEffect(() => {
    if (
      loginError &&
      Object.entries(dirtyFields).length === Object.entries(formWatcher).length
    ) {
      setLoginError('')
    }
  }, [dirtyFields, loginError, formWatcher])

  const onSubmit = handleSubmit(async (data) => {
    try {
      await handleLogin(data)
    } catch (error) {
      resetField('password')
      setLoginError('Invalid email address or password')
    }
  })

  return (
    <StyledLoginForm onSubmit={onSubmit}>
      <Input
        data-cy="login-email-field"
        label="Email"
        focusOnLoad
        autoComplete="email"
        errors={formErrors}
        {...register('email')}
      />

      <Input
        data-cy="login-password-field"
        label="Password"
        type="password"
        autoComplete="current-password"
        errors={formErrors}
        {...register('password')}
      />

      {loginError && (
        <StyledValidationErrorText>{loginError}</StyledValidationErrorText>
      )}
      <Button
        action="primary"
        baseDataAttribute="login-submit"
        type="submit"
        label="Log in"
        loading={!!isSubmitting}
      />
    </StyledLoginForm>
  )
}

export default PasswordLoginForm
