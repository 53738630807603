import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { ContactsLegacyResource } from 'src/client'
import useMhContext from 'src/contexts/MhContext'
import { Button } from 'src/stories/Button'
import logger from 'src/utils/logger'

interface StyledNoteTextProps {
  $hasText: boolean
  $isExpanded: boolean
}

const contactFormSchema = yup.object({
  notes: yup.string(),
})

const StyledNoteText = styled.label<StyledNoteTextProps>(
  ({ theme, $hasText, $isExpanded }) => ({
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: `${$isExpanded ? 'unset' : 4}`,
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: theme.space(5),
    wordBreak: 'break-word',
    color: $hasText ? theme.colors.base_100 : theme.colors.base_40,
  })
)

interface StyledTextboxProps {
  height?: number
  isOnFocus?: boolean
}
export const StyledForm = styled.form<StyledTextboxProps>(
  ({ theme, height, isOnFocus }) => ({
    ':disabled': {
      cursor: 'not-allowed',
    },
    padding: theme.space(3),
    backgroundColor: theme.colors.base_0,
    width: '100%',
    minHeight: theme.space(height ? height : 5),
    marginTop: theme.space(1),
    cursor: 'text',
    overflow: 'auto',
    border: `${
      isOnFocus
        ? '2px solid ' + theme.colors.primary_2
        : '1px solid ' + theme.colors.base_10
    }`,
    borderRadius: theme.constants.borderRadius,
    resize: 'none',
    '&:focus': {
      outline: 'none',
    },
    position: 'relative',
  })
)

export const StyledTextbox = styled.textarea<StyledTextboxProps>(
  ({ theme, height }) => ({
    ':disabled': {
      cursor: 'not-allowed',
    },
    backgroundColor: theme.colors.base_0,
    width: '100%',
    minHeight: theme.space(height ? height : 5),
    marginBottom: theme.space(2),
    cursor: 'text',
    overflow: 'auto',
    border: 0,
    resize: 'none',
    fontSize: '1.4rem',
    color: theme.colors.base_100,
    lineHeight: theme.space(5),
    '&:focus': {
      outline: 'none',
    },
  })
)

export const StyledDate = styled.p(({ theme }) => ({
  color: theme.colors.base_40,
  fontSize: '1.2rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
  margin: theme.space(2) + ' 0',
}))

const generateDataCy = (label: string, formPanel?: boolean) =>
  `mh-contact-details-notes-${formPanel ? 'form-' : ''}${label}`

interface NoteSectionProps {
  text?: string
  lastUpdated?: string
  isEditingTextArea: boolean
}
const NoteSection: React.FC<NoteSectionProps> = ({
  text,
  lastUpdated,
  isEditingTextArea,
}) => {
  const {
    openConversationContactDetails,
    getOpenConversationContactDetails,
    isSidedrawerOpen,
  } = useMhContext()

  const theme = useTheme()

  const [isTextboxOnFocus, setIsTextboxOnFocus] = useState(false)
  const [isNotesLabelClamped, setIsNotesLabelClamped] = useState(false)
  const [expandLabel, setExpandLabel] = useState(false)

  const notesElRef = useRef<HTMLLabelElement | null>(null)

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<{
    notes: string
  }>({
    reValidateMode: 'onChange',
    resolver: yupResolver(contactFormSchema),
  })

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (text !== data.notes) {
        await ContactsLegacyResource.setNote({
          customerId: openConversationContactDetails.id!,
          notes: data.notes,
        })

        await getOpenConversationContactDetails()
        toast.success('Contact updated successfully')
      }
    } catch (error) {
      logger.error('MH - Error updating customer notes', { error })
      toast.error('There was an error trying to update the customer notes.')
    }
  })

  useEffect(() => {
    // Detect if the incoming text is longer than
    // what is being shown to show Show More/Less
    // button
    if (isSidedrawerOpen) {
      if (notesElRef.current && !isEditingTextArea) {
        setIsNotesLabelClamped(
          notesElRef.current.offsetHeight < notesElRef.current.scrollHeight
        )
      }

      // Match local state with incoming text
      if (!!text) {
        setValue('notes', text)
      } else {
        setValue('notes', '')
        setIsNotesLabelClamped(false)
      }
    }
  }, [text, isEditingTextArea, setValue, isSidedrawerOpen])

  return (
    <>
      {isEditingTextArea ? (
        <StyledForm
          data-cy={generateDataCy('form', false)}
          onSubmit={onSubmit}
          isOnFocus={isTextboxOnFocus}
          onBlur={() => setIsTextboxOnFocus(false)}
          height={42}
        >
          <StyledTextbox
            data-cy={generateDataCy('textarea', true)}
            height={27}
            placeholder="Write your note here..."
            onFocus={() => setIsTextboxOnFocus(true)}
            {...register('notes')}
          />
          <Button
            baseDataAttribute={generateDataCy('submit', true)}
            label="Save"
            maxWidth={theme.space(20)}
            maxHeight={theme.space(7)}
            disabled={(!text && !watch().notes) || isSubmitting}
            type="submit"
            style={{
              position: 'absolute',
              right: theme.space(3),
              bottom: theme.space(3),
            }}
          />
        </StyledForm>
      ) : (
        <>
          <StyledNoteText
            data-cy={generateDataCy('text', false)}
            $hasText={!!text}
            ref={notesElRef}
            $isExpanded={expandLabel}
          >
            {text ||
              'Write a helpful note about this contact that only you will see.'}
          </StyledNoteText>
          {text && isNotesLabelClamped && (
            <Button
              displayAsText
              label={!expandLabel ? 'See More' : 'See Less'}
              baseDataAttribute={generateDataCy('save', false)}
              onClick={() => setExpandLabel(!expandLabel)}
              style={{
                width: '100%',
                height: theme.space(5),
              }}
            />
          )}
          {text && (
            <StyledDate data-cy={generateDataCy('date', false)}>
              {lastUpdated &&
                format(new Date(lastUpdated), 'MMM d, yyyy, h:mm a')}
            </StyledDate>
          )}
        </>
      )}
    </>
  )
}

export default NoteSection
