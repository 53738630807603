import styled, { DefaultTheme } from 'styled-components'

interface IconWrapperProps {
  $color: keyof DefaultTheme['colors']
}

export const IconWrapper = styled.div<IconWrapperProps>(
  ({ theme, $color }) => ({
    width: theme.space(10),
    height: theme.space(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors[$color],
    borderRadius: '20px',
  })
)
