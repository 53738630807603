import { useQuery } from '@tanstack/react-query'
import { ContactsListParameters } from 'src/client/interfaces/Contacts'
import Contacts from 'src/client/resources/Contacts'
import logger from 'src/utils/logger'

const useContactsQuery = ({
  locationId,
  pagination,
  search,
  segmentId,
  sort,
  deleted,
}: ContactsListParameters) => {
  return useQuery({
    queryKey: [
      'contacts',
      {
        locationId,
        pagination,
        search,
        segmentId,
        sort,
        deleted,
      },
    ] as const,
    queryFn: async ({ queryKey }) => Contacts.getContactsList(queryKey[1]),
    onError: (error) => {
      logger.error('WAF useContactsQuery - error', { error })
    },
    staleTime: 5_000,
  })
}

export default useContactsQuery
