import React from 'react'
import styled from 'styled-components'
import { SelectOption } from 'src/stories/Select'
import { useContactsContext } from 'src/contexts/ContactsContext'
import { createToolbarComponents } from './ToolbarComponents'

const ToolbarContainer = styled.div(({ theme }) => ({
  display: 'grid',
  padding: theme.space(4),
  gap: theme.space(5),
  background: theme.colors.base_0,
  borderBottom: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
}))

const ToolbarOptions = styled.div(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: theme.space(5),
}))

const SelectWrapper = styled.div(({ theme }) => ({
  gridColumn: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.space(4),
  width: '100%',
}))

const DesktopSelectWrapper = styled.div(() => ({
  gridColumn: 8,
}))

const WrapRow = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: theme.space(4),
  minWidth: theme.space(68),
}))

const StyledInputWrapper = styled.div(({ theme }) => ({
  maxWidth: theme.space(56),
  minWidth: theme.space(56),
}))

export interface ToolbarProps {
  search?: string
  selectedSegment?: number
  segments: SelectOption<number>[]
  onSearch: (searchValue: string) => void
  onSegmentSelect: (segmentId: number) => void
}

interface ToolbarComponentProps extends ToolbarProps {
  isDesktop: boolean
}

const ToolbarComponents = createToolbarComponents()
const baseDataAttribute = 'contacts-toolbar'

const Toolbar: React.FC<ToolbarProps> = ({
  search,
  segments,
  selectedSegment,
  onSearch,
  onSegmentSelect,
}) => {
  const { isFiltering, showFilters } = useContactsContext()

  return (
    <>
      <ToolbarContainer data-dd={baseDataAttribute}>
        <ToolbarOptions>
          <WrapRow>
            <ToolbarComponents.SearchInput
              onSearch={onSearch}
              search={search}
            />
            <ToolbarComponents.FilterButton />
          </WrapRow>
          {!showFilters && (
            <SelectWrapper>
              {isFiltering && <ToolbarComponents.ClearButton />}
              <ToolbarComponents.SegmentSelect
                onSegmentSelect={onSegmentSelect}
                segments={segments}
                selectedSegment={selectedSegment}
              />
            </SelectWrapper>
          )}
        </ToolbarOptions>
      </ToolbarContainer>

      {showFilters && <ToolbarComponents.ContactsFilter />}
    </>
  )
}

const DesktopToolbar: React.FC<ToolbarProps> = ({
  search,
  segments,
  selectedSegment,
  onSearch,
  onSegmentSelect,
}) => {
  const { isFiltering, showFilters } = useContactsContext()

  return (
    <ToolbarContainer data-dd={baseDataAttribute}>
      <ToolbarOptions>
        <WrapRow>
          <StyledInputWrapper>
            <ToolbarComponents.SearchInput
              onSearch={onSearch}
              search={search}
            />
          </StyledInputWrapper>
          <ToolbarComponents.FilterButton
            label={showFilters ? 'Hide filters' : 'Show filters'}
          />
          {isFiltering && <ToolbarComponents.ClearButton />}
        </WrapRow>
        <DesktopSelectWrapper>
          <ToolbarComponents.SegmentSelect
            onSegmentSelect={onSegmentSelect}
            segments={segments}
            selectedSegment={selectedSegment}
          />
        </DesktopSelectWrapper>
      </ToolbarOptions>
      {showFilters && <ToolbarComponents.DesktopContactsFilter />}
    </ToolbarContainer>
  )
}

const ToolbarComponent: React.FC<ToolbarComponentProps> = ({
  isDesktop,
  ...rest
}) => {
  return isDesktop ? <DesktopToolbar {...rest} /> : <Toolbar {...rest} />
}

export default ToolbarComponent
