import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { StyledForm } from 'src/components/Settings/common/layout'
import { StyledContent } from 'src/components/Settings/Business/styled'
import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import Input from 'src/stories/Input'
import { MutationBusinessInfoProps } from 'src/containers/Settings/Business'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Heading } from 'src/stories/typography'

const websiteFormSchema = yup.object({
  website: yup.string().url('Please enter a valid website URL').default(''),
})

type WebsiteFormSchema = yup.InferType<typeof websiteFormSchema>

interface WebsiteFormProps extends MutationBusinessInfoProps {
  baseDataAttribute: string
}

const WebsiteForm: React.FC<WebsiteFormProps> = ({
  baseDataAttribute,
  isLoading,
  update,
  onSubmitFinished,
}) => {
  const { activeLocationV3 } = useLocationContext()
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<WebsiteFormSchema>({
    resolver: yupResolver(websiteFormSchema),
    defaultValues: {
      website: activeLocationV3.businessInfo.website || '',
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    await update({
      website: data.website || null,
    })

    onSubmitFinished()
  })

  const disableForm = isSubmitting || isLoading

  return (
    <StyledForm
      data-cy={`${baseDataAttribute}-form-website`}
      onSubmit={onSubmit}
    >
      <StyledContent>
        <Heading as="h3">Website</Heading>
        <Input
          label="Business website:"
          verticallySpace={4}
          disabled={disableForm}
          errors={formErrors}
          {...register('website')}
        />
        <ButtonForm
          baseDataAttribute={`${baseDataAttribute}-form-website`}
          disabled={disableForm}
        />
      </StyledContent>
    </StyledForm>
  )
}

export default WebsiteForm
