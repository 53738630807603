import styled from 'styled-components'

export const CustomerActivityBannerSubtitle = styled.div(({ theme }) => ({
  fontSize: '2rem',
  lineHeight: '3.2rem',
  color: theme.colors.base_100,

  marginTop: theme.space(4),
  marginBottom: theme.space(8),
}))

export const CustomerActivityBannerImageItem = styled.div(({ theme }) => ({
  maxHeight: theme.space(130),
  display: 'grid',
  overflow: 'hidden',
}))

export const CustomerActivityBannerImage = styled.img(({ theme }) => ({
  marginTop: theme.space(4),
  paddingLeft: theme.space(7),
  height: theme.space(145),
}))
