import { useState, useMemo } from 'react'
import AddContactModal from 'src/components/AddContact'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'
import { UploadedListIcon, ManuallyAddedIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Heading } from 'src/stories/typography'
import { generateLocationUrl } from 'src/utils'
import styled from 'styled-components'

const Header = styled.div<{ isDesktop: boolean }>(({ theme, isDesktop }) => ({
  display: 'flex',
  gap: theme.space(5),
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  padding: `0 ${theme.space(isDesktop ? 8 : 4)}`,
}))

const ButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(5),
}))

const ContactsHeader: React.FC = () => {
  const baseDataAttribute = 'contacts-header'
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const { locationId, merchantId } = useLocationContext()
  const [showAddContactModal, setShowAddContactModal] = useState(false)

  const uploadContactsUrl = useMemo(
    () => generateLocationUrl(merchantId, locationId, 'upload-contacts'),
    [locationId, merchantId]
  )

  return (
    <Header isDesktop={isDesktop}>
      <Heading as="h1" size="large">
        Contacts
      </Heading>
      <ButtonsContainer>
        <Button
          label={isDesktop ? 'Upload Contacts' : 'Upload'}
          icon={UploadedListIcon}
          href={uploadContactsUrl}
          baseDataAttribute={`${baseDataAttribute}-upload-contacts`}
        />
        <Button
          label={isDesktop ? 'Add Contacts' : 'Add'}
          icon={ManuallyAddedIcon}
          onClick={() => setShowAddContactModal(true)}
          baseDataAttribute={`${baseDataAttribute}-add-contact`}
        />
        {showAddContactModal && (
          <AddContactModal onHide={() => setShowAddContactModal(false)} />
        )}
      </ButtonsContainer>
    </Header>
  )
}

export default ContactsHeader
