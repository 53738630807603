import React, { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { useClickOutside } from 'src/hooks/useClickOutside'
import { ArrowDownIcon } from 'src/stories/assets'
import HelperTooltip from './HelperTooltip'

const StyledSelectContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  flexGrow: 1,
}))

const StyledLabel = styled.label<Pick<SelectProps, 'hideLabel'>>(
  ({ theme, hideLabel }) => ({
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: theme.space(1),
    visibility: hideLabel ? 'hidden' : undefined,
  })
)

const StyledSelectPlaceholder = styled.div((_props) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const StyledHelpLabel = styled.label(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.colors.base_40,
  fontWight: '400',
  marginBottom: theme.space(1),
}))

const StyledValidationErrorText = styled.label(({ theme }) => ({
  marginTop: theme.space(2),
  color: theme.colors.critical,
  fontSize: '1.3rem',
}))

const Wrapper = styled.div((props) => ({
  position: 'relative',
  display: 'inline-block',
  width: 'auto',
}))

interface StyledSelectProps {
  $error?: boolean
  $height?: string
  $selected?: boolean
  darkBackground?: boolean
  displayAsText?: boolean
  disabled: boolean
}

const SelectInput = styled.div<StyledSelectProps>(
  ({
    theme,
    $error,
    $height,
    $selected,
    darkBackground,
    displayAsText,
    disabled,
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.space(2),
    height: $height || theme.space(11),
    padding: `0 ${theme.space(3)}`,
    backgroundColor: displayAsText
      ? 'inherit'
      : darkBackground
      ? theme.colors.base_10
      : theme.colors.base_0,
    color: displayAsText ? theme.colors.primary_2 : theme.colors.base_100,
    // Not using space function since this specific 2px is being set
    // to compensate the 1px added in each side by the border.
    width: `calc(100% - ${$selected ? '0' : '2px'})`,
    // Not using space function since this specific 1px is being set
    // to compensate the 1px added on the left side by the border.
    marginLeft: !$selected ? '1px' : undefined,
    // Not using space function since this specific 1px is being set
    // to compensate the 1px added on the right side by the border.
    marginRight: !$selected ? '1px' : undefined,
    border: displayAsText
      ? 'none'
      : `${$selected ? '2px' : '1px'} solid ${
          $error
            ? theme.colors.critical
            : $selected
            ? theme.colors.primary_1
            : theme.colors.base_20
        }`,
    borderRadius: theme.constants.borderRadius,
    fontSize: '1.5rem',

    '&:hover': {
      borderColor: $error
        ? theme.colors.critical
        : $selected
        ? theme.colors.primary_2
        : disabled
        ? theme.colors.base_20
        : theme.colors.primary_2,
    },

    '&:focus': {
      color: theme.colors.base_100,
      outline: 'none',
      border: `2px solid ${
        $error ? theme.colors.critical : theme.colors.primary_2
      }`,
    },
  })
)

const OptionsContainer = styled.div<{ $openDirection?: 'up' | 'down' }>(
  ({ theme, $openDirection = 'down' }) => ({
    marginTop: theme.space(2),
    zIndex: theme.zIndexes.select,
    position: 'absolute',
    bottom: $openDirection === 'up' ? '100%' : undefined,
    left: '0',
    width: '100%',
  })
)

const Options = styled.ul(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingTop: theme.space(1),
  paddingBottom: theme.space(1),
  background: theme.colors.base_0,
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
  boxSizing: 'border-box',
  color: theme.colors.base_100,
  fontSize: '1.5rem',
  maxHeight: theme.space(62),
  overflowY: 'auto',
}))

const Option = styled.li<{ selected?: boolean }>(({ theme, selected }) => ({
  listStyle: 'none',
  height: theme.space(8),
  lineHeight: theme.space(8),
  background: selected ? theme.colors.primary_2_10 : theme.colors.base_0,
  fontWeight: selected ? 500 : 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  paddingLeft: theme.space(4),
  ':hover': {
    background: selected ? theme.colors.primary_2_15 : theme.colors.base_5,
  },
}))

const Divider = styled.div(({ theme }) => ({
  height: theme.space(0.5),
  background: theme.colors.base_5,
  borderRadius: '0px',
}))

export interface SelectOption<T extends string | number = string> {
  label: string
  value: T
}

export interface SelectProps<TValue extends string | number = string> {
  dataCy?: string
  label?: string
  helpLabel?: string | JSX.Element
  helpTooltip?: string | JSX.Element
  hideLabel?: boolean
  errorText?: string
  height?: string
  disabled?: boolean
  displayAsText?: boolean
  darkBackground?: boolean
  openedOptionsDirection?: 'up' | 'down'
  inputStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
  options: SelectOption<TValue>[]
  onChange?: (value: TValue) => void
  initialValue?: TValue
  placeholder?: string
  children?: React.ReactNode
}

const placeholderSentry = 'PLACEHOLDER_SENTRY'

const Select = <TValue extends string | number = string>({
  label,
  helpLabel,
  helpTooltip,
  hideLabel,
  errorText,
  openedOptionsDirection = 'down',
  inputStyle,
  containerStyle,
  options,
  height,
  disabled = false,
  darkBackground = false,
  displayAsText = false,
  initialValue,
  onChange,
  dataCy = 'select',
  placeholder,
  children,
}: SelectProps<TValue>) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const [selectedOption, setSelectedOption] = useState<
    TValue | typeof placeholderSentry | undefined
  >(
    placeholder
      ? placeholderSentry
      : options.find((o) => o.value === initialValue)?.value ??
          options[0]?.value
  )

  const selectRef = useRef<HTMLDivElement>(null)

  useClickOutside({ ref: selectRef, onClickOutside: () => setIsOpen(false) })

  const toggling = () => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }

  const onOptionClicked = (value: TValue) => () => {
    setSelectedOption(value)
    setIsOpen(false)
    onChange?.(value)
  }

  useEffect(() => {
    const newOption = options.find((o) => o.value === initialValue)?.value

    if (newOption) {
      setSelectedOption(newOption)
    }
  }, [initialValue, options])

  return (
    <StyledSelectContainer style={containerStyle} data-cy={dataCy}>
      {label && (
        <StyledLabel data-cy={dataCy + '-label'} hideLabel={hideLabel}>
          {label}
        </StyledLabel>
      )}
      {helpLabel && (
        <StyledHelpLabel>
          {helpLabel}
          {helpTooltip && <HelperTooltip>{helpTooltip}</HelperTooltip>}
        </StyledHelpLabel>
      )}
      <Wrapper data-cy={dataCy + '-wrapper'}>
        <SelectInput
          data-cy={dataCy + '-input'}
          onClick={toggling}
          $error={!!errorText}
          $height={height}
          $selected={isOpen}
          disabled={disabled}
          displayAsText={displayAsText}
          darkBackground={darkBackground}
          style={inputStyle}
        >
          <StyledSelectPlaceholder>
            {selectedOption === placeholderSentry
              ? placeholder
              : options.find((o) => o.value === selectedOption)?.label}
          </StyledSelectPlaceholder>
          <ArrowDownIcon stroke={theme.colors.base_50} />
        </SelectInput>
        {isOpen && !!options.length && (
          <OptionsContainer
            ref={selectRef}
            $openDirection={openedOptionsDirection}
            data-cy={dataCy + '-options-container'}
          >
            <Options data-cy={dataCy + '-options'}>
              {options.map(({ label: optionLabel, value }, idx, arr) => (
                <React.Fragment key={`${optionLabel}-${value}`}>
                  <Option
                    onClick={onOptionClicked(value)}
                    selected={selectedOption === value}
                    data-cy={
                      dataCy +
                      `-option-${optionLabel
                        .split(' ')
                        .filter(Boolean)
                        .join('-')}`
                    }
                  >
                    {optionLabel}
                  </Option>
                  {idx + 1 !== arr.length && <Divider />}
                </React.Fragment>
              ))}
              {children && <Divider />}
              {children}
            </Options>
          </OptionsContainer>
        )}
      </Wrapper>
      {errorText && (
        <StyledValidationErrorText data-cy={dataCy + `-error-text`}>
          {errorText}
        </StyledValidationErrorText>
      )}
    </StyledSelectContainer>
  )
}

export default Select
