import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import useInsightsContext from 'src/contexts/InsightsContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { InsightsWidget } from './InsightsWidget'
import {
  StyledWidgetBlackText,
  StyledWidgetContentContainer,
  WidgetDataItem,
} from './styled'
import { WidgetProps } from './types'

const FeedbacksCampaignsWidget: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const { allTimeFeedbackCount, isFeedbackLoading, summaryFeedbacks } =
    useInsightsContext()

  const [feedbackData, setFeedbackData] = useState<{
    opened: number
    sent: number
    openedPercentage: string
    submittedPercentage: string
  }>({ opened: 0, sent: 0, openedPercentage: '0', submittedPercentage: '0' })

  useEffect(() => {
    if (summaryFeedbacks.email > 0) {
      const openedPercentage = (
        (summaryFeedbacks.emailOpens * 100) /
        summaryFeedbacks.email
      ).toFixed(1)
      const submittedPercentage = (
        (allTimeFeedbackCount * 100) /
        summaryFeedbacks.email
      ).toFixed(1)

      setFeedbackData({
        sent: summaryFeedbacks.email,
        opened: summaryFeedbacks.emailOpens,
        openedPercentage,
        submittedPercentage,
      })
    }
  }, [summaryFeedbacks, allTimeFeedbackCount])

  if (isFeedbackLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  return (
    <InsightsWidget
      baseDataAttribute={baseDataAttribute}
      title="Feedback Campaign"
    >
      <StyledWidgetContentContainer
        $gapUnits={3}
        data-cy={baseDataAttribute + '-container'}
      >
        <StyledWidgetBlackText data-cy={baseDataAttribute + '-label'}>
          Total requests:
        </StyledWidgetBlackText>
        <StyledWidgetContentContainer
          $gapUnits={2}
          data-cy={baseDataAttribute + '-data'}
        >
          <WidgetDataItem
            label="Sent Emails"
            value={`${feedbackData.sent} (100%)`}
            baseDataAttribute={baseDataAttribute + '-data-item'}
          />
          <WidgetDataItem
            label="Opened Emails"
            value={`${feedbackData.opened} (${feedbackData.openedPercentage}%)`}
            baseDataAttribute={baseDataAttribute + '-data-item'}
          />
          <WidgetDataItem
            label="Feedbacks Submitted"
            value={`${allTimeFeedbackCount} (${feedbackData.submittedPercentage}%)`}
            baseDataAttribute={baseDataAttribute + '-data-item'}
          />
        </StyledWidgetContentContainer>
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default FeedbacksCampaignsWidget
