import { AxiosInstance } from 'axios'

import { LocationLegacyRequests } from '../interfaces/LocationsLegacy'
import Resource from '../resource'
import Constants from '../../lib/Constants'

const actions = (client: AxiosInstance): LocationLegacyRequests => ({
  get: ({ locationIds }) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Documents',
      method: 'mgetByKey',
      params: {
        scope: 'Locations',
        key: 'docId',
        values: locationIds.map((id) => `Location.${id}`),
      },
    })
  },

  updateBusinessInfo: (params) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations',
      method: 'updateBusinessInfo',
      params,
    })
  },

  confirmBusinessInfo: (locationId) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations',
      method: 'confirmBusinessInfo',
      params: { locationId },
    })
  },

  updateMarketingInformation: (locationId, params) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations',
      method: 'updateMarketingInformation',
      params: {
        locationId,
        ...params,
      },
    })
  },

  addReviewSiteToLocation: (locationId, reviewSiteId) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations.ReviewSites',
      method: 'add',
      params: {
        locationId,
        reviewSiteId,
      },
    })
  },

  confirmReviewSite: ({ locationId, reviewSiteId }) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations.ReviewSites',
      method: 'confirm',
      params: {
        locationId,
        reviewSiteId,
      },
    })
  },

  toggleFeatureCode: (params) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations.Features',
      method: params.enable ? 'enable' : 'disable',
      params: {
        locationId: params.locationId,
        featureCode: params.featureCode,
      },
    })
  },

  getPublicInformation: (locationId) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'PublicLocations.Information',
      method: 'get',
      params: {
        locationId,
      },
    })
  },

  findUserFacingFeatures: (locationId) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations.Features',
      method: 'findUserFacing',
      params: {
        locationId,
      },
    })
  },

  getFeedback: (locationId: number) => {
    if (!locationId) {
      return Promise.resolve({ docIds: [], total: 0 })
    }

    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'ChannelEvents',
      method: 'findDocIdsByQuery',
      params: {
        template: {
          id: 'FEEDBACK',
          params: {
            locationId,
            sort: [
              {
                by: 'SUBMITTED_AT',
                direction: 'DESC',
              },
            ],
          },
        },
      },
    })
  },

  getReviews: (locationId: number) => {
    if (!locationId) {
      return Promise.resolve({ docIds: [], total: 0 })
    }

    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'ChannelEvents',
      method: 'findDocIdsByQuery',
      params: {
        template: {
          id: 'REVIEWS',
          params: {
            locationId,
            isConfirmed: true,
            sort: [
              {
                by: 'TIMESTAMP',
                direction: 'DESC',
              },
            ],
          },
        },
      },
    })
  },

  addSegment: ({ locationId, name }) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations.Segments',
      method: 'add',
      params: {
        locationId,
        name,
      },
    })
  },
  getLiveReceptionistReporting: (locationId: number) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations',
      method: 'liveReceptionistValueReporting',
      params: {
        locationId,
        // TODO: Implement filter by date
        // startDate,
        // endDate,
      },
    })
  },

  updateLoopConfigurations: (params) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations.LoopConfigurations',
      method: 'update',
      params: {
        ...params,
      },
    })
  },
})

export default Resource(actions)
