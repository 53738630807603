import React from 'react'
import {
  PreferredConsumerTerm as PreferredConsumerTermEnum,
  preferredConsumerTermMap,
} from 'src/client/interfaces/Common'
import { Body, Heading } from 'src/stories/typography'

interface Props {
  consumerTerm: PreferredConsumerTermEnum
}

const PreferredConsumerTerm: React.FC<Props> = ({ consumerTerm }) => {
  return (
    <>
      <Heading as="h3">How should we call your customers?</Heading>
      <Body>
        Selected:{' '}
        <Body as="span" color="darker" textTransform="capitalize">
          {preferredConsumerTermMap[consumerTerm].plural}
        </Body>
        <br />
        Example: &ldquo;Special offer for new{' '}
        <Body as="span" color="darker">
          {preferredConsumerTermMap[consumerTerm].plural}
        </Body>
        &rdquo;
      </Body>
    </>
  )
}

export default PreferredConsumerTerm
