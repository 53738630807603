import React from 'react'

import SmsRegistrationLayout, {
  StyledCopy,
} from 'src/components/Settings/Business/SmsRegistrationLayout'
import YoutubeIframe from 'src/components/YoutubeIframe'
import { Button } from 'src/stories/Button'
import { HelperWidget } from 'src/stories/HelperWidget'
import Link from 'src/stories/Link'
import { WizardLayout } from 'src/stories/Wizard'
import { ProTipIcon } from 'src/stories/assets'

const REGULATION_URL =
  'https://support.signpost.com/support/solutions/articles/35000221410-phone-carriers-require-registration-of-eins-for-businesses-using-local-10-digit-numbers'

const VIDEO_A2P_10DLC_URL = 'https://www.youtube.com/embed/BXspNVK8y3o'

interface OverviewStepProps {
  nextStep: () => void
}

const OverviewStep: React.FC<OverviewStepProps> = ({ nextStep }) => (
  <WizardLayout
    asideContent={
      <>
        <HelperWidget
          title="Before you get started"
          icon={ProTipIcon}
          copy={
            <ul>
              <li>
                Have your 9-digit EIN (Tax ID) handy (sole proprietors excluded)
              </li>
              <li>
                You can locate your EIN on your tax preparation documents or
                financing documents provided by your bank
              </li>
            </ul>
          }
        />
      </>
    }
  >
    <SmsRegistrationLayout
      title="Overview"
      buttonNext={
        <Button
          size="large"
          action="primary"
          label="Get Started"
          baseDataAttribute="sms-registration-get-started"
          onClick={nextStep}
        />
      }
    >
      <StyledCopy>
        In an effort to prevent spam messages, phone carriers are enforcing an
        effort nicknamed{' '}
        <Link
          baseDataAttribute={'A2P-10DLC'}
          href={REGULATION_URL}
          target="_blank"
        >
          "A2P 10DLC"
        </Link>{' '}
        to register business numbers. In order to utilize Signpost's text
        marketing features to generate reviews and communicate with customers,
        you must complete this SMS registration. Watch the video below to
        understand the impact of this regulation on your business and proceed
        with the registration.
      </StyledCopy>
      <YoutubeIframe
        url={VIDEO_A2P_10DLC_URL}
        title="Register Your Business Today!"
      />
    </SmsRegistrationLayout>
  </WizardLayout>
)

export default OverviewStep
