import React from 'react'
import styled from 'styled-components'

import SuccessfulImage from 'src/assets/thumbtack-successful-connection.png'
import { ProTipIcon } from 'src/stories/assets'
import { Button } from 'src/stories/Button'
import { HelperWidget } from 'src/stories/HelperWidget'
import { Col, Row } from 'src/stories/Layout'

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  marginBottom: theme.space(3),
}))

const StyledCopy = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(6),
  whiteSpace: 'pre-wrap',
}))

const StyledImgContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.positive_15,
  borderRadius: theme.constants.borderRadius,
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.space(8),
  paddingBottom: theme.space(17),
  paddingTop: theme.space(10),
}))

interface SetupCompleteStepProps {
  nextStep: () => void
}

const SetupCompleteStep: React.FC<SetupCompleteStepProps> = ({ nextStep }) => {
  return (
    <Row>
      <Col w={2}>
        <StyledTitle>Thumbtack set up complete</StyledTitle>

        <StyledCopy>
          You're all set! {'\n'}
          You'll see new leads added to your contacts. Use the default Instant
          Response message from the settings Instant Response page or revise it
          to better sound like your brand.
        </StyledCopy>

        <StyledImgContainer>
          <img src={SuccessfulImage} alt="Set up complete" />
        </StyledImgContainer>

        <Button
          action="primary"
          label="Finish"
          maxWidth={167}
          onClick={nextStep}
        />
      </Col>

      <Col>
        <HelperWidget
          copy={`Keep the momentum going by connecting more leads sources and set up
            instant responders so you never miss a lead!`}
          icon={ProTipIcon}
          title="Pro tip"
        />
      </Col>
    </Row>
  )
}

export default SetupCompleteStep
