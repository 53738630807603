import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import useInsightsContext from 'src/contexts/InsightsContext'
import { SmileyIcon, StarIcon } from 'src/stories/assets'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { InsightsWidget } from './InsightsWidget'
import {
  StyledWidgetContentContainer,
  StyledWidgetStat,
  StyledWidgetTitle,
  StyledSummarySectionContainer,
  SummaryRowItem,
} from './styled'

interface Props {
  baseDataAttribute: string
}

const SummaryWidget: React.FC<Props> = ({ baseDataAttribute }) => {
  const theme = useTheme()
  const {
    summaryReviews,
    summaryFeedbacks,
    isReviewLoading,
    isFeedbackLoading,
    isReviewsZeroState,
    isFeedbackZeroState,
  } = useInsightsContext()

  const summaryArray = useMemo(
    () => [
      {
        title: 'Review',
        icon: <StarIcon width={theme.space(5)} fill={theme.colors.accent_1} />,
        isZeroState: isReviewsZeroState,
        ...summaryReviews,
      },
      {
        title: 'Feedback',
        icon: <SmileyIcon width={theme.space(5)} />,
        isZeroState: isFeedbackZeroState,
        ...summaryFeedbacks,
      },
    ],
    [
      summaryFeedbacks,
      summaryReviews,
      theme,
      isFeedbackZeroState,
      isReviewsZeroState,
    ]
  )

  if (isFeedbackLoading || isReviewLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  return (
    <InsightsWidget
      title="How have we helped your business?"
      baseDataAttribute={baseDataAttribute}
    >
      <StyledWidgetContentContainer
        $gapUnits={4}
        data-cy={baseDataAttribute + '-summaries'}
      >
        {summaryArray.map((summary) => {
          const dataCy = `${baseDataAttribute}-${summary.title}-summary`

          return (
            <StyledWidgetContentContainer
              key={summary.title}
              $gapUnits={3}
              data-cy={dataCy}
            >
              <StyledSummarySectionContainer data-cy={dataCy + '-container'}>
                {summary.icon}
                <StyledWidgetTitle data-cy={dataCy + '-label'}>
                  Total {summary.title} Requests:
                </StyledWidgetTitle>
                <StyledWidgetStat data-cy={dataCy + '-value'}>
                  {summary.isZeroState
                    ? '\u2013'
                    : summary.total.toLocaleString()}
                </StyledWidgetStat>
              </StyledSummarySectionContainer>

              <SummaryRowItem
                label="Emails Sent"
                baseDataAttribute={dataCy}
                value={summary.email}
                color={'primary_2'}
                isZeroState={summary.isZeroState}
              />
              <SummaryRowItem
                label="Texts sent"
                baseDataAttribute={dataCy}
                value={summary.text}
                color={'positive'}
                isZeroState={summary.isZeroState}
              />
            </StyledWidgetContentContainer>
          )
        })}
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default SummaryWidget
