import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { HandleTabConversationChangeParams } from 'src/containers/MessagingHub/types'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import useMhContext from 'src/contexts/MhContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { ComposeIcon, QuestionIcon } from 'src/stories/assets'
import { Button } from 'src/stories/Button'
import { formatPhoneNumber, getLocationInformation } from 'src/utils'
import { getTCPAModalParams } from 'src/components/MessagingHub/TCPAModal'
import { hasAcceptedTCPA } from 'src/components/MessagingHub/utils'
import NewConversationModal from '../Modals/NewConversationModal'
import Tooltip from 'src/stories/Tooltip'

interface StyledContainerProps {
  isMediumDesktop?: boolean
}

const StyledContainer = styled.div<StyledContainerProps>(
  ({ isMediumDesktop, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: `${!isMediumDesktop ? theme.space(2.5) : 'unset'}`,
  })
)

interface CommonProps {
  isDesktop: boolean
}

const StyledTitle = styled.h1<CommonProps>(({ isDesktop, theme }) => ({
  margin: 0,
  marginRight: theme.space(isDesktop ? 8 : 4),
}))

const StyledForwardingPhoneContainer = styled.div<CommonProps>(
  ({ isDesktop, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginRight: theme.space(isDesktop ? 12 : 2),
  })
)

const StyledQuestionIcon = styled(QuestionIcon)(({ theme }) => ({
  marginRight: theme.space(5),
  cursor: 'pointer',
}))

const StyledForwardingPhone = styled.p(({ theme }) => ({
  color: theme.colors.base_40,
  fontSize: '1.3rem',
  marginLeft: theme.space(2),
}))

interface ConversationHeadingProps {
  handleTabConversationChange: (
    params: HandleTabConversationChangeParams
  ) => void
  isOpenConversation: boolean
}

const ConversationsActionsHeading: React.FC<ConversationHeadingProps> = ({
  handleTabConversationChange,
  isOpenConversation,
}) => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const theme = useTheme()
  const { refetchUser } = useAuthContext()
  const { user } = useAccountContext()
  const { showModal, closeModal } = useModalNotificationsContext()
  const { activeLocation, locationId } = useLocationContext()
  const { isLiveReceptionistOnly } = getLocationInformation(activeLocation)

  const { isDesktop, isMediumDesktop } = useMhContext()

  const [locationHasForwardingPhone, forwardingPhone] = useMemo(() => {
    return activeLocation.forwardingPhone
      ? [true, formatPhoneNumber(activeLocation.forwardingPhone)]
      : [false, '...processing']
  }, [activeLocation])

  const handleRenderModal = useCallback(() => {
    if (hasAcceptedTCPA(user)) {
      showModal({
        title: 'New Message',
        dataCy: 'mh-new-conversation-modal',
        hideActionButtons: true,
        customBody: (
          <NewConversationModal
            handleTabConversationChange={handleTabConversationChange}
            locationId={locationId.toString()}
          />
        ),
      })
    } else {
      showModal(
        getTCPAModalParams(() => {
          void refetchUser()
          closeModal()
        }, user)
      )
    }
  }, [
    locationId,
    user,
    showModal,
    closeModal,
    handleTabConversationChange,
    refetchUser,
  ])

  useEffect(() => {
    if (!!new URLSearchParams(search).get('openConversationModal')) {
      const newUrl = new URLSearchParams(search)

      newUrl.delete('openConversationModal')

      navigate(`?${newUrl.toString()}`)
      void handleRenderModal()
    }
  }, [search, handleRenderModal, navigate])

  const showPhoneNumber = !isOpenConversation || isMediumDesktop

  return (
    <StyledContainer isMediumDesktop={isMediumDesktop}>
      <StyledTitle isDesktop={isDesktop}>Inbox</StyledTitle>
      {!isLiveReceptionistOnly && showPhoneNumber && (
        <StyledForwardingPhoneContainer
          isDesktop={isDesktop}
          data-cy="mh-forwarding-number"
        >
          <StyledForwardingPhone
            style={{
              fontStyle: locationHasForwardingPhone ? 'normal' : 'italic',
            }}
          >
            {forwardingPhone}
          </StyledForwardingPhone>
          {!locationHasForwardingPhone && (
            <Tooltip
              text={`We are processing your Signpost Marketing Number. This is a shareable number you can hand out to your customers or your team, which allows you to use the Messaging Hub and Instant Responses.\n\nNumber still not showing up? This usually takes a couple minutes. Refresh your page and it should show up shortly.`}
              left={theme.space(0.5 * -1)}
              fontSize="1.2rem"
              color={theme.colors.base_60}
              textStyle={{ whiteSpace: 'pre-wrap' }}
              style={{ marginLeft: theme.space(2) }}
              fontWeight={400}
              widthUnits={60}
              position="bottom"
            >
              <StyledQuestionIcon fill={theme.colors.base_50} />
            </Tooltip>
          )}
        </StyledForwardingPhoneContainer>
      )}
      {
        <Button
          action="primary"
          label="New"
          baseDataAttribute="new-conversation"
          onClick={handleRenderModal}
          icon={ComposeIcon}
        />
      }
    </StyledContainer>
  )
}

export default ConversationsActionsHeading
