import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useRef,
  useState,
} from 'react'
import Input, { InputProps } from 'src/stories/Input'

export interface PhoneNumberInputProps
  extends Omit<InputProps, 'type' | 'onBlur' | 'onChange' | 'value'> {
  onChange?: (value: string) => void
  onBlur?: (value: string) => void
  value?: string
}

const getPhoneNumberDigits = (value: string) =>
  value.replace(/\D/g, '').substring(0, 10)

const formatPhoneNumber = (value: string) => {
  const cleanedValue = getPhoneNumberDigits(value)
  const areaCodeMatch = cleanedValue.match(/^\d{1,3}$/)
  const prefixMatch = cleanedValue.match(/^(\d{3})(\d{1,3})$/)
  const match = cleanedValue.match(/^(\d{3})(\d{3})(\d{1,4})$/)

  if (areaCodeMatch) {
    return `(${areaCodeMatch[0]}`
  }

  if (prefixMatch) {
    return `(${prefixMatch[1]}) ${prefixMatch[2]}`
  }

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return cleanedValue
}

const PhoneNumberInput = React.forwardRef<
  HTMLInputElement,
  PhoneNumberInputProps
>(({ value, onChange, onBlur, ...props }, ref) => {
  const [plainPhoneNumber, setPlainPhoneNumber] = useState(value ?? '')
  const phoneNumberInputRef = useRef<HTMLInputElement>(null)

  // Forward the ref to the Input component
  if (typeof ref === 'function') {
    ref(phoneNumberInputRef.current)
  } else if (ref) {
    ref.current = phoneNumberInputRef.current
  }

  const handleChange = () => {
    if (phoneNumberInputRef.current) {
      const inputValue = phoneNumberInputRef.current.value
      const plainValue = getPhoneNumberDigits(inputValue)

      phoneNumberInputRef.current.value = formatPhoneNumber(inputValue)
      setPlainPhoneNumber(plainValue)

      return plainValue
    }

    return ''
  }

  const onChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = handleChange()

    onChange?.(newValue)
  }

  const onBlurInput: FocusEventHandler<HTMLInputElement> = (e) => {
    const newValue = handleChange()

    onBlur?.(newValue)
  }

  return (
    <Input
      placeholder="(xxx) xxx-xxxx"
      {...props}
      type="tel"
      value={formatPhoneNumber(plainPhoneNumber)}
      ref={phoneNumberInputRef}
      onChange={onChangeInput}
      onBlur={onBlurInput}
    />
  )
})

export default PhoneNumberInput
