import React from 'react'
import styled, { useTheme } from 'styled-components'

import { StyledItem } from 'src/stories/Dropdown'
import { Col } from 'src/stories/Layout'
import { StyledContentContainer } from '../styled'
import { HandleTabConversationChangeParams } from 'src/containers/MessagingHub/types'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import useMhContext from 'src/contexts/MhContext'
import { isEditableConversationItem } from 'src/contexts/MhContext/utils'
import {
  ConversationListItem as ConversationListItemType,
  isPublicReview,
} from 'src/contexts/ConversationsListContext/types'
import ConversationListItem from 'src/components/MessagingHub/ConversationsPane/ConversationListItem'

export const StyledConversationsContainer = styled(Col)((props) => ({
  overflowY: 'scroll',
}))

export type SelectableConversationsList = ConversationListItemType & {
  selected: boolean
}

interface Props {
  handleTabConversationChange: (
    params: HandleTabConversationChangeParams
  ) => void
  editMode: boolean
  toggleConversation: (conversationId: number, checked: boolean) => void
  selectableConversationsList: SelectableConversationsList[]
}

const ConversationList: React.FC<Props> = ({
  handleTabConversationChange,
  selectableConversationsList,
  toggleConversation,
  editMode,
}) => {
  const theme = useTheme()
  const {
    isDesktop,
    isZeroState,
    openConversationId,
    conversationsListIsLoading,
    isListTruncated,
    isMediumDesktop,
  } = useMhContext()

  return (
    <StyledContentContainer
      data-cy="conversations-list"
      height={`calc(100% - ${theme.space(isDesktop ? 31 : 30)})`}
      $border="right"
      $isMediumDesktop={isMediumDesktop}
    >
      <StyledConversationsContainer unspaced>
        {conversationsListIsLoading && <LoadingSpinner />}
        {!conversationsListIsLoading &&
          selectableConversationsList.map((c, index) => (
            <ConversationListItem
              key={`conversation-item-${c.id}`}
              selected={c.id === openConversationId}
              conversationItem={c}
              index={index}
              checked={c.selected}
              toggleSelectConversation={toggleConversation}
              isEditDisabled={!isEditableConversationItem(c)}
              onClick={() =>
                handleTabConversationChange({
                  mode: 'conversation',
                  value: c.id.toString(),
                  doNavigate: !isZeroState && c.id !== -1,
                  preventServerCall: isPublicReview(c),
                })
              }
              editMode={editMode}
            />
          ))}
        {isListTruncated && (
          <StyledItem>
            Find older conversations using the search bar above
          </StyledItem>
        )}
      </StyledConversationsContainer>
    </StyledContentContainer>
  )
}

export default ConversationList
