import React, {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'

import { HandleSendMessage } from 'src/client/interfaces/Comms'
import { SmsRegistrationStatus } from 'src/client/interfaces/SmsRegistration'
import Attachments from 'src/components/MessagingHub/MessagesPane/Attachments'
import {
  AttachmentsContainer,
  HelperButtonsSection,
  MessageSendContainer,
  MessageSendFooter,
  StyledContainer,
  StyledErrorMessage,
  StyledPaperClipIcon,
  StyledTextbox,
} from 'src/components/MessagingHub/MessagesPane/MessageSend/styled'
import { useReviewSiteInformation } from 'src/hooks/useReviewSiteInformation'
import { CalculateMessageBoxHeight } from 'src/components/MessagingHub/MessagesPane/index'
import { getTCPAModalParams } from 'src/components/MessagingHub/TCPAModal'
import { useShowUpsellModal } from 'src/components/MessagingHub/UpsellModal/hooks'
import { hasAcceptedTCPA } from 'src/components/MessagingHub/utils'
import {
  ActiveConversationMessages,
  StoreActiveConversationParams,
} from 'src/containers/MessagingHub/types'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import Constants from 'src/lib/Constants'
import { Button, ButtonProps } from 'src/stories/Button'
import Tooltip from 'src/stories/Tooltip'
import { FeedbackIcon, QuestionIcon, ReviewIcon } from 'src/stories/assets'
import { generateLocationUrl, getLocationInformation } from 'src/utils'

const StyledQuestionIcon = styled(QuestionIcon)(({ theme }) => ({
  cursor: 'pointer',
  // Using !important since the button has a style
  // targeting svg (which normally will go at the beginning)
  marginRight: '0 !important',
}))

const ButtonLabelWithTooltip: React.FC<{
  label: string
  helperText: string
}> = ({ helperText, label }) => {
  const theme = useTheme()

  return (
    <>
      {label}
      <Tooltip
        text={helperText}
        fontSize="1.2rem"
        color={theme.colors.base_60}
        style={{
          marginLeft: theme.space(2),
          whiteSpace: 'pre-wrap',
        }}
        fontWeight={400}
        widthUnits={60}
      >
        <StyledQuestionIcon fill={theme.colors.base_50} />
      </Tooltip>
    </>
  )
}

const getMessageSendButtonProps = (
  theme: DefaultTheme,
  locationId: number,
  merchantId: number,
  onSendClick: () => void,
  isSmsConversation: boolean,
  registrationStatus?: SmsRegistrationStatus
): ButtonProps => {
  /**
   * If the reply method won't be SMS, we can always display the "Send" button.
   * If it will be SMS, the button's appearance will depend on the SMS registration status (see below)
   **/
  if (!isSmsConversation) {
    return {
      label: 'Send',
      onClick: onSendClick,
      maxWidth: theme.space(20),
    }
  }

  switch (registrationStatus) {
    case SmsRegistrationStatus.NOT_STARTED:
    case SmsRegistrationStatus.IN_PROGRESS:
    case SmsRegistrationStatus.PENDING_PHONE_VERIFICATION:
      return {
        label: (
          <ButtonLabelWithTooltip
            label="Registration pending"
            helperText="Your SMS Registration is pending approval, which usually takes a couple of days. Once approved, you can start texting customers without carrier restrictions."
          />
        ),
        action: 'secondary',
        maxWidth: theme.space(48),
        outline: true,
        onClick: onSendClick,
        style: {
          fontStyle: 'italic',
          cursor: 'default',
        },
      }

    case SmsRegistrationStatus.FAILED:
      return {
        label: (
          <ButtonLabelWithTooltip
            label="Registration not approved"
            helperText="Your SMS Registration was not approved. Please contact Signpost support at (855) 606-4900 for assistance."
          />
        ),
        action: 'danger',
        outline: true,
        onClick: onSendClick,
        maxWidth: theme.space(59),
        style: {
          fontStyle: 'italic',
          cursor: 'default',
        },
      }

    case SmsRegistrationStatus.APPROVED:
      return {
        label: 'Send',
        onClick: onSendClick,
        maxWidth: theme.space(20),
      }
    default:
      return {
        label: 'Register to start texting',
        action: 'confirm',
        maxWidth: theme.space(45),
        href: generateLocationUrl(
          merchantId,
          locationId,
          '/settings/business/sms-registration'
        ),
      }
  }
}

interface MessageSendProps {
  onSend: HandleSendMessage
  onAttach: () => void
  storeActiveConversation?: (params: StoreActiveConversationParams) => void
  calculateHeight?: CalculateMessageBoxHeight
  openConversationId?: number
  docTypeToReply?: string
  activeConversations?: ActiveConversationMessages
  errorMessage?: string
  textAreaRef?: React.RefObject<HTMLTextAreaElement>
  forceDisable: boolean
  disableMessageSendButtons: boolean
  withMargins?: boolean
  height?: number
  dayaCyUniquePrefix?: string
  onFocusTextBox?: (event: FocusEvent<HTMLTextAreaElement>) => void
  onChangeTextBox?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  receiverName?: string
  needsPolicyAcknowledgement?: boolean
  isInModal?: boolean
}

const MessageSend: React.FC<MessageSendProps> = ({
  activeConversations,
  openConversationId,
  docTypeToReply,
  errorMessage,
  textAreaRef,
  forceDisable,
  disableMessageSendButtons,
  withMargins = true,
  height,
  onSend,
  onAttach,
  storeActiveConversation,
  calculateHeight = () => null,
  dayaCyUniquePrefix,
  onFocusTextBox,
  onChangeTextBox,
  receiverName,
  isInModal,
}) => {
  const { refetchUser } = useAuthContext()
  const { user } = useAccountContext()
  const { activeLocation, activeLocationV3, locationId, merchantId } =
    useLocationContext()
  const { registrationStatus } = activeLocationV3
  const showUpsellModal = useShowUpsellModal()

  const { hasTwilioNumber, isLiveReceptionistOnly } =
    getLocationInformation(activeLocation)

  const { isLargeDesktop, isDesktop, isExtraLargeDesktop } = useMhContext()

  const isThumbtackReplyMethodEnabled = !!localStorage.getItem(
    'SP_ENABLE_THUMBTACK_METHOD'
  )

  const isFacebookConversation =
    docTypeToReply === Constants.COMMS.documentTypes.facebookComm ||
    docTypeToReply === Constants.COMMS.documentTypes.facebookInteraction

  const isSmsConversation = Constants.COMMS.getCommTypes({
    commDocTypes: 'smsDocumentTypes',
    isThumbtackReplyMethodEnabled,
  }).includes(docTypeToReply ?? '')

  let preventSending =
    isSmsConversation &&
    hasTwilioNumber &&
    registrationStatus !== SmsRegistrationStatus.APPROVED

  const generateDataCyAttribute = (tag: string, useLegacy = false) =>
    [
      ...(dayaCyUniquePrefix ? [dayaCyUniquePrefix] : []),
      useLegacy ? 'message-send' : 'mh-message-send',
      tag,
    ]
      .filter(Boolean)
      .join('-')

  const fileInputRef = useRef<HTMLInputElement>(null)
  const askForFeedbackButtonRef = useRef<HTMLButtonElement | null>(null)
  const askForFeedbackLabelRef = useRef<HTMLLabelElement | null>(null)

  const [inputText, setInputText] = useState('')
  const [feedbackOrReview, setFeedbackOrReview] = useState<
    'feedback' | 'review' | undefined
  >()
  const [selectedImages, setSelectedImages] = useState<File[]>([])

  const theme = useTheme()
  const { locationName, reviewSiteName, reviewSiteDisplayName } =
    useReviewSiteInformation(activeLocation)

  const calculateTextAreaHeight = useCallback(
    (el: React.KeyboardEvent<HTMLTextAreaElement>) => {
      calculateHeight(el, !!selectedImages.length)
    },
    [calculateHeight, selectedImages]
  )
  const { showModal, closeModal } = useModalNotificationsContext()
  const { isLargeScreen } = useScreenSizes()

  const onClickSend = useCallback(() => {
    if (preventSending) {
      return
    }

    setInputText('')
    setFeedbackOrReview(undefined)
    setSelectedImages([])
    if (fileInputRef.current) fileInputRef.current.value = ''

    return onSend(
      inputText,
      selectedImages,
      feedbackOrReview && {
        type: feedbackOrReview,
        reviewSiteName,
      }
    )
  }, [
    onSend,
    feedbackOrReview,
    inputText,
    reviewSiteName,
    selectedImages,
    preventSending,
  ])

  useEffect(() => {
    if (
      inputText &&
      activeConversations &&
      openConversationId &&
      activeConversations[openConversationId] !== inputText &&
      !feedbackOrReview
    ) {
      setInputText(activeConversations[openConversationId] || '')
    }
  }, [activeConversations, openConversationId, inputText, feedbackOrReview])

  useEffect(() => {
    setInputText('')
    setFeedbackOrReview(undefined)
  }, [openConversationId])

  useEffect(() => {
    if (feedbackOrReview || (!feedbackOrReview && !inputText)) {
      if (textAreaRef?.current) {
        calculateTextAreaHeight({
          currentTarget: textAreaRef.current,
        } as React.KeyboardEvent<HTMLTextAreaElement>)
      }
    }
  }, [
    feedbackOrReview,
    calculateHeight,
    textAreaRef,
    inputText,
    calculateTextAreaHeight,
  ])

  useEffect(() => {
    if (!!selectedImages.length) {
      onAttach()
    }
  }, [onAttach, selectedImages])

  const handleAttachmentsClick = () => {
    if (fileInputRef.current) fileInputRef.current.click()
  }

  const onSendClick = () => {
    if (!hasTwilioNumber && isLiveReceptionistOnly) {
      return showUpsellModal()
    }

    if (preventSending) {
      return
    }

    if (hasAcceptedTCPA(user)) {
      return onClickSend()
    }

    showModal(
      getTCPAModalParams(() => {
        void refetchUser()
        closeModal()
      }, user)
    )
  }

  const messageSendButtonProps = getMessageSendButtonProps(
    theme,
    locationId,
    merchantId,
    onSendClick,
    isSmsConversation,
    hasTwilioNumber ? registrationStatus : undefined
  )

  return (
    <StyledContainer
      withPadding={withMargins && !isInModal}
      $isLargeDesktop={isExtraLargeDesktop}
      $isDesktop={isLargeDesktop}
      $isSmallDesktop={isDesktop}
      $removeMargin={isInModal}
    >
      <HelperButtonsSection
        data-cy={generateDataCyAttribute('helper-buttons')}
        direction="row"
        height={theme.space(15)}
        flexGrow={0}
      >
        {reviewSiteName && (
          <Button
            baseDataAttribute={generateDataCyAttribute('ask-for-review')}
            label={isLargeScreen ? 'Ask for review' : 'Review'}
            onClick={() => {
              if (!hasTwilioNumber && isLiveReceptionistOnly) {
                return showUpsellModal()
              }
              setInputText(
                `Hi${
                  ` ${receiverName || ''}` || ''
                }, ${locationName}: Review us on ${reviewSiteDisplayName}: [your review link]`
              )
              setFeedbackOrReview('review')
            }}
            style={{
              padding: `0 ${theme.space(2)}`,
              marginRight: theme.space(2),
            }}
            outline
            icon={ReviewIcon}
            maxWidth={theme.space(40)}
            maxHeight={theme.space(8)}
            disabled={disableMessageSendButtons}
          />
        )}
        <Button
          baseDataAttribute={generateDataCyAttribute('ask-for-feedback')}
          label={isLargeScreen ? 'Ask for feedback' : 'Feedback'}
          onClick={() => {
            if (isLiveReceptionistOnly) {
              return showUpsellModal()
            }
            setInputText(
              `Hi${
                ` ${receiverName || ''}` || ''
              }, ${locationName}: We value your opinion! Please let us know how we did: [your feedback link]`
            )
            setFeedbackOrReview('feedback')
          }}
          style={{
            padding: `0 ${theme.space(2)}`,
          }}
          outline
          icon={FeedbackIcon}
          maxWidth={theme.space(45)}
          maxHeight={theme.space(8)}
          disabled={disableMessageSendButtons}
          reference={askForFeedbackButtonRef}
          labelReference={askForFeedbackLabelRef}
        />
      </HelperButtonsSection>

      {
        <MessageSendContainer error={!!errorMessage}>
          <StyledTextbox
            maxLength={1000}
            data-cy={generateDataCyAttribute('textarea', true)}
            ref={textAreaRef || null}
            height={
              height ? (!!selectedImages.length ? height - 20 : height) : height
            }
            rows={1}
            placeholder="Enter your message..."
            disabled={
              forceDisable ||
              !!feedbackOrReview ||
              !!(isFacebookConversation && selectedImages.length)
            }
            onKeyUp={calculateTextAreaHeight}
            onChange={(e) => {
              onChangeTextBox && onChangeTextBox(e)

              if (storeActiveConversation) {
                storeActiveConversation({
                  conversationId: openConversationId,
                  currentText: e.target.value,
                })
              }
              setInputText(e.target.value)
            }}
            value={inputText}
            onFocus={(e) => onFocusTextBox && onFocusTextBox(e)}
          />

          {!!selectedImages.length && (
            <Attachments
              shouldPreventPreviews={isInModal}
              images={selectedImages.map((selectedImage) => ({
                url: URL.createObjectURL(selectedImage),
                contentType: selectedImage.type,
                name: selectedImage.name,
              }))}
              onRemoveSelectedAttachment={(idx) => {
                setSelectedImages((images) =>
                  images.filter((a, i) => i !== idx)
                )
              }}
            />
          )}

          <MessageSendFooter>
            {
              <AttachmentsContainer
                disabled={disableMessageSendButtons}
                onClick={handleAttachmentsClick}
                data-cy={generateDataCyAttribute('attachments')}
              >
                <StyledPaperClipIcon disabled={disableMessageSendButtons} />
                <input
                  type="file"
                  disabled={disableMessageSendButtons}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  multiple
                  accept=".jpg,.jpeg,.png,.mp4,.mov,.m4v,.pdf"
                  onChange={({ target: { files } }) => {
                    if (isFacebookConversation) {
                      if (storeActiveConversation) {
                        storeActiveConversation({
                          conversationId: openConversationId,
                          currentText: '',
                        })
                      }
                      setInputText('')
                    }

                    const selectedFiles = Array.from(files || [])

                    setSelectedImages(selectedFiles)
                    fileInputRef.current!.value = ''
                  }}
                />
              </AttachmentsContainer>
            }

            <HelperButtonsSection direction="row">
              {feedbackOrReview && (
                <Button
                  baseDataAttribute={generateDataCyAttribute('cancel', true)}
                  label="Cancel"
                  onClick={() => {
                    setInputText('')
                    setFeedbackOrReview(undefined)
                  }}
                  maxWidth={theme.space(20)}
                  maxHeight={theme.space(8)}
                  outline
                />
              )}
              {feedbackOrReview && <div style={{ width: theme.space(2) }} />}
              <Button
                baseDataAttribute={generateDataCyAttribute('', true)}
                {...messageSendButtonProps}
                maxHeight={theme.space(8)}
                disabled={
                  (!inputText && !selectedImages.length) || forceDisable
                }
              />
            </HelperButtonsSection>
          </MessageSendFooter>
        </MessageSendContainer>
      }
      {!!errorMessage && (
        <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
      )}
    </StyledContainer>
  )
}

export default MessageSend
