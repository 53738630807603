import React from 'react'
import styled, { useTheme } from 'styled-components'

import { Row } from 'src/stories/Layout'
import MessageBubble from './MessageBubble'
import Constants from 'src/lib/Constants'

import {
  ReviewIcon,
  StarIcon,
  GoogleIcon as GoogleConversationalIcon,
  FacebookIcon,
  YelpIcon as YelpConversationalIcon,
} from 'src/stories/assets'
import {
  ConversationListItem,
  isConversationResource,
} from 'src/contexts/ConversationsListContext/types'
import { conversationEventTypePredicate } from 'src/client/interfaces/Conversations'
import {
  getConversationItemNameTitle,
  getConversationItemPreviewText,
  getConversationItemTimestamp,
} from 'src/contexts/ConversationsListContext/utils'

interface StyledContainerInterface {
  $isDesktop: boolean
}
const StyledContainer = styled(Row)<StyledContainerInterface>(
  ({ theme, $isDesktop }) => ({
    height: '100%',
    overflowY: 'scroll',
    scrollbarGutter: 'stable',
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingLeft: $isDesktop ? theme.space(4) : theme.space(3),
    paddingRight: theme.space(2),
  })
)

const StyledMessageContent = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledLabel = styled.strong(({ theme }) => ({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.space(3),
}))

const StyledTitle = styled.span(({ theme }) => ({
  margin: `0 ${theme.space(2)}`,
}))

const StyledLink = styled.a(({ theme }) => ({
  color: theme.colors.primary_2,
  marginTop: theme.space(3),
}))

interface ReviewBubbleProps {
  activeConversation: ConversationListItem
  isDesktop: boolean
  dataCy: string
  isUnconfirmed?: boolean
}
const ReviewBubble: React.FC<ReviewBubbleProps> = ({
  activeConversation,
  isUnconfirmed = true,
  isDesktop,
  dataCy,
}) => {
  const theme = useTheme()
  let ReviewSiteConversationalIcon = <ReviewIcon />
  let reviewSiteConversationalName = ''
  let reviewSiteId = 0
  let rating = 0

  if (isConversationResource(activeConversation)) {
    const { mostRecentEvent } = activeConversation

    if (conversationEventTypePredicate(mostRecentEvent, 'REVIEW')) {
      const reviewSite = mostRecentEvent.reviewEventData?.reviewSiteDisplayName

      reviewSiteId =
        Object.values(Constants.ReviewSites).find(
          (s) => s.displayName === reviewSite
        )?.id ?? 0

      rating = mostRecentEvent.reviewEventData?.rating ?? 0
    } else if (
      conversationEventTypePredicate(mostRecentEvent, 'PUBLIC_REVIEW')
    ) {
      const reviewSite =
        mostRecentEvent.publicReviewEventData.reviewSiteDisplayName

      reviewSiteId =
        Object.values(Constants.ReviewSites).find(
          (s) => s.displayName === reviewSite
        )?.id ?? 0

      rating = mostRecentEvent.publicReviewEventData.rating ?? 0
    }
  } else {
    reviewSiteId = activeConversation.reviewSiteId
    rating = activeConversation.rating
  }

  switch (reviewSiteId) {
    case Constants.ReviewSites.GOOGLE.id:
      ReviewSiteConversationalIcon = <GoogleConversationalIcon />
      reviewSiteConversationalName = ` on ${Constants.ReviewSites.GOOGLE.displayName}`
      break
    case Constants.ReviewSites.FACEBOOK.id:
      ReviewSiteConversationalIcon = <FacebookIcon />
      reviewSiteConversationalName = ` on ${Constants.ReviewSites.FACEBOOK.displayName}`
      break
    case Constants.ReviewSites.YELP.id:
      ReviewSiteConversationalIcon = <YelpConversationalIcon />
      reviewSiteConversationalName = ` on ${Constants.ReviewSites.YELP.displayName}`
      break
    default:
      break
  }

  const preview = getConversationItemPreviewText(activeConversation)

  const url = isConversationResource(activeConversation)
    ? conversationEventTypePredicate(
        activeConversation.mostRecentEvent,
        'REVIEW'
      )
      ? activeConversation.mostRecentEvent.reviewEventData.url
      : conversationEventTypePredicate(
          activeConversation.mostRecentEvent,
          'PUBLIC_REVIEW'
        )
      ? activeConversation.mostRecentEvent.publicReviewEventData.url
      : ''
    : activeConversation.reviewUrl

  const messageBubble = (
    <MessageBubble
      body=""
      contactName={getConversationItemNameTitle(activeConversation) ?? ''}
      date={getConversationItemTimestamp(activeConversation).toISOString()}
      icon="Review"
      sourceType="incoming"
      isEmptyBackground
      dataCy={dataCy}
    >
      <StyledMessageContent>
        <StyledLabel>
          {ReviewSiteConversationalIcon}

          <StyledTitle>Wrote a {rating || 5}-star review</StyledTitle>

          {Array.from(Array(rating || 5)).map((r, i) => (
            <StarIcon
              key={`mh-review-star-unconfirmed-conversational-${i}`}
              fill={theme.colors.accent_1}
            />
          ))}
        </StyledLabel>

        {preview && <div>{preview}</div>}

        {url && (
          <StyledLink target="_blank" href={url}>
            See Review{reviewSiteConversationalName}
          </StyledLink>
        )}
      </StyledMessageContent>
    </MessageBubble>
  )

  return isUnconfirmed ? (
    <StyledContainer $isDesktop={isDesktop}>{messageBubble}</StyledContainer>
  ) : (
    messageBubble
  )
}

export default ReviewBubble
