import { useState } from 'react'
import styled from 'styled-components'
import Select from 'src/stories/Select'
import { Body } from 'src/stories/typography'

const Container = styled.div(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.space(2),
  flexWrap: 'wrap',
  justifyContent: 'inherit',
}))

const StyledSelect = styled(Select<string>).attrs(() => ({
  displayAsText: true,
}))(() => ({}))

const CampaignsFilter: React.FC = () => {
  return (
    <Container>
      <Body size="large">Show me contacts who</Body>
      <Body size="large">
        <StyledSelect
          options={[
            { label: 'have', value: 'have' },
            { label: 'have not', value: 'have not' },
          ]}
        />
      </Body>
      <Body size="large">
        <StyledSelect
          options={[
            { label: 'received', value: 'received' },
            { label: 'opened', value: 'opened' },
            { label: 'completed the goal', value: 'completed the goal' },
            { label: 'written a review', value: 'written a review' },
            { label: 'submitted feedback', value: 'submitted feedback' },
          ]}
        />
        <Body size="large">
          <StyledSelect
            options={[
              { label: 'any', value: 'any' },
              { label: 'a specific campaign', value: 'a specific campaign' },
              { label: 'a One-time Campaign', value: 'a One-time Campaign' },
              { label: 'a Review us on Yelp', value: 'a Review us on Yelp' },
              {
                label: 'a Review us on The Knot',
                value: 'a Review us on The Knot',
              },
              {
                label: 'a Review us on Google',
                value: 'a Review us on Google',
              },
              {
                label: 'a Review us on Facebook',
                value: 'a Review us on Facebook',
              },
              { label: 'a Feedback', value: 'a Feedback' },
            ]}
          />
        </Body>
        <Body size="large">marketing campaign</Body>
      </Body>
    </Container>
  )
}

const SourceFilter: React.FC = () => {
  return <span>Source filter will go here</span>
}

const StatusFilter: React.FC = () => {
  type SelectedStatusType = 'subscribed' | 'opted in'
  const [selectedStatus, setSelectedStatus] =
    useState<SelectedStatusType>('subscribed')

  return (
    <Container>
      <Body size="large">Show me the contacts who</Body>
      <Body size="large">
        <StyledSelect
          options={[
            { label: 'are not', value: 'are not' },
            { label: 'are', value: 'are' },
          ]}
        />
      </Body>
      <Body size="large">
        <StyledSelect
          initialValue={selectedStatus}
          options={[
            { label: 'subscribed', value: 'subscribed' },
            { label: 'opted in', value: 'opted in' },
          ]}
          onChange={(_selectedStatus) =>
            setSelectedStatus(_selectedStatus as SelectedStatusType)
          }
        />
      </Body>
      <Body size="large">
        {selectedStatus === 'subscribed'
          ? 'to marketing emails'
          : 'to text marketing'}
      </Body>
    </Container>
  )
}

const ListsFilter: React.FC = () => {
  return <span>Lists filter will go here</span>
}

const LifecycleFilter: React.FC = () => {
  return (
    <Container>
      <Body size="large">Show me contacts who are</Body>
      <Body size="large">
        <StyledSelect
          options={[
            { label: 'Prospects', value: 'prospects' },
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
            { label: 'Unhappy', value: 'unhappy' },
            { label: 'Unknown', value: 'unknown' },
          ]}
        />
      </Body>
      <Body></Body>
    </Container>
  )
}

export const renderFilter = (selected: string) => {
  switch (selected) {
    case 'campaigns':
      return <CampaignsFilter />
    case 'source':
      return <SourceFilter />
    case 'status':
      return <StatusFilter />
    case 'lists':
      return <ListsFilter />
    case 'lifecycle':
      return <LifecycleFilter />
  }
}
