import React from 'react'
import { useTheme } from 'styled-components'
import useMarketingSettings from 'src/client/hooks/queries/useLoopConfigurationsQuery'

import PreferredConsumerTerm from 'src/components/Settings/Business/BusinessTerminologySection/PreferredConsumerTerm'
import PreferredServiceOfferingTerm from 'src/components/Settings/Business/BusinessTerminologySection/PreferredServiceOfferingTerm'
import ConsumerForm from 'src/components/Settings/Business/BusinessTerminologySection/forms/ConsumerForm'
import ServiceOfferingForm from 'src/components/Settings/Business/BusinessTerminologySection/forms/ServiceOfferingForm'
import { GetFormConfigProps } from 'src/containers/Settings/Business'
import { useLocationContext } from 'src/contexts/LocationContext'
import EditableFormContainer from 'src/stories/EditableFormContainer'
import SectionContainer from 'src/stories/SectionContainer'

interface Props {
  baseDataAttribute: string
  getFormConfig: GetFormConfigProps
}

const BusinessTerminologySection: React.FC<Props> = ({
  baseDataAttribute,
  getFormConfig,
}) => {
  const theme = useTheme()
  const { locationId } = useLocationContext()
  const { data: marketingSettings } = useMarketingSettings({ locationId })

  const consumerTerm = marketingSettings?.preferredConsumerTerm
  const serviceTerm = marketingSettings?.preferredServiceOfferingTerm

  const formConfigConsumerTerm = getFormConfig('consumer-term')
  const formConfigServiceOferingTerm = getFormConfig('service-offering-term')

  return (
    <SectionContainer
      title="Business Terminology"
      subtitle="Choose how we refer to your customers and their experiences with your business."
      childrenStyle={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.space(4),
      }}
    >
      <EditableFormContainer
        {...formConfigConsumerTerm}
        formComponent={
          consumerTerm && (
            <ConsumerForm
              baseDataAttribute={baseDataAttribute}
              consumerTerm={consumerTerm}
              onSubmitFinished={() =>
                formConfigConsumerTerm.setIsEditing(false)
              }
            />
          )
        }
      >
        {consumerTerm && <PreferredConsumerTerm consumerTerm={consumerTerm} />}
      </EditableFormContainer>
      <EditableFormContainer
        {...formConfigServiceOferingTerm}
        formComponent={
          serviceTerm && (
            <ServiceOfferingForm
              baseDataAttribute={baseDataAttribute}
              serviceTerm={serviceTerm}
              onSubmitFinished={() =>
                formConfigServiceOferingTerm.setIsEditing(false)
              }
            />
          )
        }
      >
        {serviceTerm && (
          <PreferredServiceOfferingTerm serviceTerm={serviceTerm} />
        )}
      </EditableFormContainer>
    </SectionContainer>
  )
}

export default BusinessTerminologySection
