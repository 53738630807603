import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import styled, { useTheme } from 'styled-components'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_rounded from 'highcharts-rounded-corners'
import { useQuery } from '@tanstack/react-query'

import LoadingSpinner from 'src/stories/LoadingSpinner'
import { InsightsWidget } from 'src/components/Insights/Widgets/InsightsWidget'
import {
  StyledMainStatContainer,
  StyledWidgetContentContainer,
  StyledWidgetMainStat,
} from 'src/components/Insights/Widgets/styled'
import { WidgetProps } from 'src/components/Insights/Widgets/types'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { InsightsResource } from 'src/client'
import { QuestionIcon } from 'src/stories/assets'
import Tooltip from 'src/stories/Tooltip'
import { mockDemoInboundLeadsPerMonth } from 'src/contexts/InsightsContext/demo-data'

HC_rounded(Highcharts)

// If updating this, also update mockDemoInboundLeadsPerMonth.
const AMOUNT_OF_MONTHS_BACK = 5

interface ExtendedPlotSeriesOptions extends Highcharts.PlotSeriesOptions {
  borderRadiusTopLeft: number
  borderRadiusTopRight: number
}

const StyledQuestionIcon = styled(QuestionIcon)(({ theme }) => ({
  marginLeft: theme.space(2.5),
  marginBottom: `-${theme.space(0.5)}`,
  cursor: 'pointer',
}))

const StyledHighchartWrapper = styled.div(() => ({
  display: 'inline-grid',
  minWidth: 0,
}))

const InboundLeadsPerMonthWidget: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const { locationId } = useParams<UseLocationRouteParams>()
  const theme = useTheme()

  const [isZeroState, setIsZeroState] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  const isDemoLocation =
    !!locationId && locationId === process.env.REACT_APP_DEMO_LOCATION_ID

  const { isLoading, data: leadsPerMonth } = useQuery({
    queryKey: [
      'inboundLeadsPerMonthByLocationId',
      {
        locationId,
      },
    ],
    queryFn: async () => {
      const { data } = await InsightsResource.getInboundLeadsPerMonth(
        +locationId!
      )

      let transformedData = data.map((m) => {
        // Note: If updating this format, also update the demo data used below.
        return {
          ...m,
          addedPeriod: +m.addedPeriod.split('-')[1] - 1,
        }
      })

      if (isDemoLocation) {
        transformedData = mockDemoInboundLeadsPerMonth
      }

      const _isZeroState = !transformedData.find(
        (monthData) => monthData?.totalContacts > 0
      )

      setIsZeroState(_isZeroState)

      return transformedData
    },
    staleTime: 60_000,
    onError: () => {
      setIsZeroState(true)
    },
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  const fontStyles: Highcharts.CSSObject = {
    fontSize: '1.4rem',
    color: theme.colors.base_40,
  }

  const plotSeriesOptions: ExtendedPlotSeriesOptions = {
    borderRadiusTopLeft: 5,
    borderRadiusTopRight: 5,
    point: {
      events: {
        mouseOver() {
          let series = this.series

          series.points.forEach((p) => {
            p.graphic?.css({
              opacity: 0.5,
            })
          })

          this.graphic?.css({
            opacity: 1,
          })
        },
        mouseOut() {
          let series = this.series

          series.points.forEach((p) => {
            p.graphic?.css({
              opacity: 1,
            })
          })
        },
      },
    },
  }

  const options: Highcharts.Options = {
    xAxis: {
      categories: leadsPerMonth?.map((monthData) =>
        format(new Date().setMonth(monthData.addedPeriod), 'MMM')
      ),
      labels: {
        style: fontStyles,
      },
    },
    yAxis: {
      tickAmount: 4,
      title: {
        text: '',
      },
      labels: {
        style: { ...fontStyles, fontSize: '1.2rem' },
        enabled: !isZeroState,
      },
      min: isZeroState ? 0 : null,
      max: isZeroState ? 100 : null,
    },
    title: {
      text: '',
    },
    series: [
      {
        type: 'column',
        data: leadsPerMonth?.map((monthData, idx) => ({
          y: monthData?.totalContacts,
          color: `${
            idx === AMOUNT_OF_MONTHS_BACK - 1
              ? theme.colors.primary_2
              : theme.colors.base_10
          }`,
        })),
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter() {
        return `<b>Leads</b><br/>${this.x!}: ${this.y!}`
      },
    },
    chart: {
      type: 'column',
      marginTop: 20,
      height: 150,
      spacingLeft: 0,
      spacingRight: 0,
    },
    plotOptions: {
      series: plotSeriesOptions,
    },
    accessibility: {
      enabled: false,
    },
  }

  return (
    <InsightsWidget
      addDivider
      baseDataAttribute={baseDataAttribute}
      title="Number of leads per month"
      subtitle={
        <StyledMainStatContainer
          data-cy={baseDataAttribute + '-main-stat-container'}
        >
          <StyledWidgetMainStat data-cy={baseDataAttribute + '-main-stat'}>
            {!(leadsPerMonth && leadsPerMonth.length > 0)
              ? 0
              : leadsPerMonth[AMOUNT_OF_MONTHS_BACK - 1].totalContacts}
          </StyledWidgetMainStat>
        </StyledMainStatContainer>
      }
      headerElement={
        <>
          <Tooltip
            text="Represents the total number of customers who have reached out and initiated a conversation."
            onMouseOver={() => setShowTooltip(true)}
            onMouseOut={() => setShowTooltip(false)}
            isActive={showTooltip}
            left={theme.space(0.5 * -1)}
            color={theme.colors.base_40}
          >
            <StyledQuestionIcon
              fill={theme.colors.base_50}
              onMouseOver={() => setShowTooltip(true)}
              onMouseOut={() => setShowTooltip(false)}
            />
          </Tooltip>
        </>
      }
      isHeaderElementNextToTitle={true}
    >
      <StyledWidgetContentContainer
        data-cy={baseDataAttribute + '-graph'}
        $gapUnits={4}
      >
        <StyledHighchartWrapper>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </StyledHighchartWrapper>
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default InboundLeadsPerMonthWidget
