import { BottomSpace } from 'src/components/Contacts/StyledComponents'
import WafLayout from 'src/components/WafLayout'
import Table from 'src/containers/Contacts/ContactsTable'
import ContactsHeader from 'src/containers/Contacts/Header'

interface Props {
  isMultiLocation: boolean
}

const ContactsProto: React.FC<Props> = ({ isMultiLocation }) => {
  return (
    <WafLayout
      layoutTitle={<ContactsHeader />}
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
        showDropShadow: true,
      }}
    >
      <Table />
      <BottomSpace />
    </WafLayout>
  )
}

export default ContactsProto
