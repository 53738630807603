import { AxiosInstance } from 'axios'

import { UserRequests } from '../interfaces/Users'
import Resource from '../resource'
import Constants from '../../lib/Constants'

const actions = (client: AxiosInstance): UserRequests => ({
  get: ({ userId }) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Users',
      method: 'get',
      params: {
        userId,
        expand: [
          'attachedAgencyIds',
          'attachedMerchantIds',
          'attachedLocationIds',
          'associatedAgencyIds',
          'policyAcks',
        ],
      },
    })
  },
  reportPolicyAcknowledgement: async ({ policyType = 'TOS', ...params }) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Users',
      method: 'reportPolicyAcknowledgement',
      params: { ...params, policyType },
    })
  },
})

export default Resource(actions)
