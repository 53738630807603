import { flexRender, Table as ReactTable } from '@tanstack/react-table'
import React from 'react'
import { useTheme } from 'styled-components'

import { Contact } from 'src/client/interfaces/Contacts'
import {
  FooterContainer,
  HeaderContentContainer,
  StyledAnchor,
  StyledZeroStateTextContainer,
  TableContainer,
  TableWrapper,
} from './StyledComponents'

import LoadingSpinner from 'src/stories/LoadingSpinner'
import { Table, TBody, Td, Th, THead, Tr } from 'src/stories/table'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

const FULL_SIZE = 1000 //symbolic number size for 100% width

interface ContactsTableProps {
  toolbarComponent?: React.ReactNode
  footerComponent?: React.ReactNode
  table: ReactTable<Contact>
  loading: boolean
}

const ContactsTable: React.FC<ContactsTableProps> = ({
  toolbarComponent,
  footerComponent,
  table,
  loading,
}) => {
  const theme = useTheme()
  const { isMediumScreen } = useScreenSizes()

  const emptyPlaceholder = '-'

  return (
    <TableContainer>
      {toolbarComponent !== undefined ? toolbarComponent : null}
      <TableWrapper>
        <Table style={{ tableLayout: isMediumScreen ? 'auto' : 'fixed' }}>
          <THead>
            {table.getHeaderGroups().map((group) => (
              <Tr backgroundColor="base_10" key={group.id}>
                {group.headers.map((column) => (
                  <Th
                    key={column.id}
                    style={{
                      width:
                        column.getContext().column.getSize() === FULL_SIZE
                          ? '100%'
                          : column.getContext().column.getSize(),
                    }}
                  >
                    {column.isPlaceholder ? null : (
                      <HeaderContentContainer
                        clickable={column.column.getCanSort()}
                        key={column.id}
                        onClick={column.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          column.column.columnDef.header,
                          column.getContext()
                        )}
                        {{
                          asc: '🔼',
                          desc: '🔽',
                        }[column.column.getIsSorted() as string] ?? null}
                      </HeaderContentContainer>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const value = cell.getValue()
                    const {
                      column: { id: columnId },
                      row: {
                        original: { id: contactId },
                      },
                    } = cell

                    const isLinkedCell =
                      [
                        'fullName',
                        'primaryEmailAddress',
                        'primaryPhoneNumber',
                      ].includes(columnId) && typeof value !== 'undefined'

                    const renderCell = flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )

                    if (isLinkedCell && value !== emptyPlaceholder)
                      return (
                        <Td key={cell.id}>
                          <StyledAnchor href={`customers/${contactId}`}>
                            {renderCell}
                          </StyledAnchor>
                        </Td>
                      )

                    return <Td key={cell.id}>{renderCell}</Td>
                  })}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  {loading ? (
                    <LoadingSpinner logoHeight={theme.space(15)} />
                  ) : (
                    <StyledZeroStateTextContainer>
                      No results
                    </StyledZeroStateTextContainer>
                  )}
                </Td>
              </Tr>
            )}
          </TBody>
        </Table>
      </TableWrapper>
      <FooterContainer>
        {footerComponent !== undefined ? footerComponent : null}
      </FooterContainer>
    </TableContainer>
  )
}

export default ContactsTable
