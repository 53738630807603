import styled from 'styled-components'
import { Col, Row } from 'src/stories/Layout'

export interface DisconnectConfirmationModalProps {
  leadSourceName: string
}

const StyledContent = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  marginTop: 0,
  marginBottom: theme.space(12),
}))

const DisconnectConfirmationModal: React.FC<
  DisconnectConfirmationModalProps
> = ({ leadSourceName }) => {
  return (
    <>
      <Row>
        <Col>
          <StyledContent>
            <strong>This may take up to 1 minute.</strong>
            <br />
            <br />
            Disconnecting from this integration will stop us from automatically
            adding new leads from {leadSourceName} and sending instant
            responders.
          </StyledContent>
        </Col>
      </Row>
    </>
  )
}

export default DisconnectConfirmationModal
