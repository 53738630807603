import { createContext, useContext, useMemo } from 'react'
import { useConversationEventsQuery } from 'src/client'
import {
  ConversationEventResource,
  ConversationEventsResponseDTO,
} from 'src/client/interfaces/Conversations'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { useParams } from 'react-router-dom'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { QueryObserverResult } from '@tanstack/react-query'
import { isConversationResource } from '../ConversationsListContext/types'

export interface ConversationMessagesContextType {
  messagesList: ConversationEventResource[]
  isLoading: boolean
  refetch: ReturnType<typeof useConversationEventsQuery>['refetch']
}

const ConversationMessagesContext =
  createContext<ConversationMessagesContextType>({
    messagesList: [],
    isLoading: false,
    refetch: () =>
      Promise.resolve(
        {} as QueryObserverResult<ConversationEventsResponseDTO, unknown>
      ),
  })

export const ConversationMessagesContextProvider: React.FCWithChildren = ({
  children,
}) => {
  const { locationId } = useParams<UseLocationRouteParams>()

  const {
    openConversationId,
    getConversationListItem,
    isLoading: conversationListIsLoading,
  } = useConversationsListContext()

  const openConversation =
    !conversationListIsLoading &&
    typeof openConversationId !== 'undefined' &&
    openConversationId > 0
      ? getConversationListItem(openConversationId)
      : undefined

  const {
    data: conversationEventsData,
    isLoading,
    refetch,
  } = useConversationEventsQuery({
    locationId: Number(locationId),
    contactId: openConversationId,
    enabled:
      typeof openConversation !== 'undefined' &&
      openConversation &&
      isConversationResource(openConversation),
  })

  const messagesList = useMemo(
    () => conversationEventsData?.data ?? [],
    [conversationEventsData?.data]
  )

  return (
    <ConversationMessagesContext.Provider
      value={{ messagesList, isLoading, refetch }}
    >
      {children}
    </ConversationMessagesContext.Provider>
  )
}

export const useConversationMessagesContext = () =>
  useContext(ConversationMessagesContext)

export default ConversationMessagesContextProvider
