import { useMutation, useQueryClient } from '@tanstack/react-query'
import logger from 'src/utils/logger'
import { toast } from 'react-toastify'

import { ContactsLegacyResource } from 'src/client'

const useCreateContactMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(ContactsLegacyResource.create, {
    onSuccess: async ({ customerId, type }, { locationId }) => {
      toast.success(
        type === 'newCustomer' ? 'New contact created' : 'Contact updated'
      )

      await queryClient.invalidateQueries({
        queryKey: ['contacts'],
      })

      await queryClient.invalidateQueries({
        queryKey: ['contactDetails', { locationId, contactId: customerId }],
      })
    },
    onError: (error) => {
      toast.error(
        'There was an error trying to create a new contact. Please try again.'
      )
      logger.error('WAF useCreateContactMutation - error', {
        error,
      })
    },
  })
}

export default useCreateContactMutation
