import React from 'react'
import { useTheme } from 'styled-components'
import useInsightsContext from 'src/contexts/InsightsContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import Timeline from 'src/stories/Timeline'
import { useFormattedCustomerActivities } from './hooks'

interface Props {
  baseDataAttribute: string
}

const RecentCustomerActivityWidget: React.FC<Props> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const { isCustomerActivitiesLoading } = useInsightsContext()
  const formattedActivities = useFormattedCustomerActivities(
    baseDataAttribute + '-activity'
  )
  const { isExtraSmallScreen } = useScreenSizes()

  return (
    <>
      {isCustomerActivitiesLoading ? (
        <LoadingSpinner logoHeight={theme.space(15)} />
      ) : (
        <>
          <Timeline
            isMobile={!isExtraSmallScreen}
            dataCy={baseDataAttribute + '-timeline'}
            events={formattedActivities.map(
              (
                {
                  content: ContentComponent,
                  timestamp,
                  details: DetailsComponent,
                },
                idx
              ) => {
                const dataAttribute =
                  baseDataAttribute + '-activity-' + idx.toString()

                return {
                  event: (
                    <ContentComponent dataCy={dataAttribute + '-content'} />
                  ),
                  details: DetailsComponent && (
                    <DetailsComponent dataCy={dataAttribute + '-details'} />
                  ),
                  timestamp,
                  // TODO: figure out what events should be true.
                  isSignpostEvent: false,
                }
              }
            )}
          />
        </>
      )}
    </>
  )
}

export default RecentCustomerActivityWidget
