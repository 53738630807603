import { useQuery } from '@tanstack/react-query'
import Locations from 'src/client/resources/Locations'
import logger from 'src/utils/logger'

const useLocationReviewSites = ({ locationId }: { locationId: number }) => {
  return useQuery({
    queryKey: [
      'review-sites',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) =>
      Locations.getReviewSites(lid),
    onError: (error) => {
      logger.error('WAF useLocationReviewSites - error', { error })
    },
    refetchOnWindowFocus: false,
    enabled: !!locationId && !isNaN(locationId),
  })
}

export default useLocationReviewSites
