import React from 'react'
import styled from 'styled-components'
import { UsersResource } from 'src/client'
import { UserInterface } from 'src/client/interfaces/Common'
import { UpdateModalParams } from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'

const Title = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontWeight: 500,
  fontSize: '2.1rem',
  marginTop: 0,
}))

const Description = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontWeight: 400,
  fontSize: '1.4rem',
}))

const SubtitleHeading = styled.p(({ theme }) => ({
  color: theme.colors.base_100,
  fontWeight: 400,
  fontSize: '1.6rem',
}))

const Disclaimer = styled.em(({ theme }) => ({
  color: theme.colors.base_50,
  fontWeight: 400,
  fontSize: '1.3rem',
}))

const List = styled.ul(({ theme }) => ({
  listStyle: 'none',
  display: 'inline-flex',
  flexDirection: 'column',
  gap: theme.space(4),
  paddingLeft: theme.space(2),
  marginTop: theme.space(5),
  marginBottom: theme.space(8),
}))

const Bullet = styled.li(({ theme }) => ({
  color: theme.colors.base_100,
  fontWeight: 400,
  fontSize: '1.4rem',
  ':before': {
    content: "'✓'",
  },
}))

const StyledAnchor = styled.a(({ theme }) => ({
  color: theme.colors.primary_2,
  textDecoration: 'none',
}))

interface Props {
  onAcknowledge: () => void
}

const TCPAModal: React.FC<Props> = ({ onAcknowledge }) => {
  return (
    <>
      <Title>Here's what you need to know about communication laws</Title>
      <Description>
        Signpost makes it easy to reach out to your customers. Our solutions
        have been built with general communication laws in consideration to make
        it easy for you to send text messages and emails with confidence.
        However, it is important to note that it is your responsibility to make
        sure messages sent comply with federal and local laws. Please read the
        following{' '}
        <StyledAnchor
          href="https://terms.signpost.com/#Communications"
          target="_blank"
          rel="noreferrer"
        >
          notice
        </StyledAnchor>{' '}
        for additional details.
      </Description>
      <SubtitleHeading>Top compliance tips:</SubtitleHeading>
      <Disclaimer>*This is not legal advice*</Disclaimer>
      <List>
        <Bullet>
          Only send text messages to someone who has permitted you to do so.
        </Bullet>
        <Bullet>
          If you don't have permission, ask for opt-in consent and record the
          response.
        </Bullet>
        <Bullet>
          If someone opts-out of communication with your business, honor that.
        </Bullet>
      </List>
      <Button label="Acknowledge" onClick={onAcknowledge} maxWidth="40%" />
    </>
  )
}

export default TCPAModal

export const getTCPAModalParams = (
  onComplete: () => void,
  user: UserInterface
): UpdateModalParams => ({
  canClose: false,
  hideActionButtons: true,
  customBody: (
    <TCPAModal
      onAcknowledge={async () => {
        if (user) {
          await UsersResource.reportPolicyAcknowledgement({
            policyType: 'TCPA',
            policyVersion: 'v1',
            userId: user.id,
          })

          onComplete()
        }
      }}
    />
  ),
  dataCy: 'mh-tcpa-modal',
  height: 'auto',
})
