import styled from 'styled-components'
import {
  FeedbackIcon,
  MobilePhoneIcon,
  ReviewIcon,
  StarIcon,
} from 'src/stories/assets'

export const ActivityTextWrapper = styled.p(({ theme }) => ({
  margin: 0,
  fontSize: '1.6rem',
  color: theme.colors.base_60,
  overflowWrap: 'anywhere',
}))

export const ActivityLink = styled.a(({ theme }) => ({
  color: theme.colors.primary_2,
}))

export const ActivityDetailsWrapper = styled.span(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(2),
}))

export const ActivityDetailsSentiment = styled.span(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(1),
  fontSize: '1.6rem',
}))

export const ActivityDetailsSentimentReview = styled.span(() => ({
  fontSize: '1.6rem',
}))

export const ActivityDetailsText = styled.p(() => ({
  margin: 0,
  fontSize: '1.4rem',
}))

export const StyledIcons = {
  Feedback: styled(FeedbackIcon)(({ theme }) => ({
    stroke: theme.colors.base_50,
    fill: theme.colors.base_100,
  })),
  MobilePhone: styled(MobilePhoneIcon)(({ theme }) => ({
    stroke: theme.colors.base_50,
  })),
  Review: styled(ReviewIcon)(({ theme }) => ({
    stroke: theme.colors.base_50,
  })),
  YellowStar: styled(StarIcon)(({ theme }) => ({
    fill: theme.colors.accent_1,
  })),
}
