import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { StyledContent } from 'src/components/Settings/Business/styled'
import { StyledForm } from 'src/components/Settings/common/layout'
import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import { MutationBusinessInfoProps } from 'src/containers/Settings/Business'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Heading } from 'src/stories/typography'

export const StyledLabel = styled.label((props) => ({
  fontSize: '1.4rem',
  fontWeight: 'bold',
}))

const StyledTextarea = styled.textarea<{ height?: number; error?: boolean }>(
  ({ theme, height, error }) => ({
    width: '100%',
    minHeight: theme.space(height ? height : 50),
    marginTop: theme.space(1),
    padding: `${theme.space(2)} ${theme.space(3)}`,
    backgroundColor: theme.colors.base_0,
    color: error ? theme.colors.critical : theme.colors.base_100,
    border: `1px solid ${error ? theme.colors.critical : theme.colors.base_20}`,
    borderRadius: theme.constants.borderRadius,
    fontSize: '1.6rem',
    fontFamily: 'Roboto',

    ':disabled': {
      cursor: 'not-allowed',
    },

    '&:hover': {
      borderColor: error ? theme.colors.critical : theme.colors.base_50,
    },

    '&:focus': {
      color: theme.colors.base_100,
      outline: 'none',
      border: `2px solid ${
        error ? theme.colors.critical : theme.colors.primary_1
      }`,
    },
  })
)

const descriptionFormSchema = yup.object({
  description: yup.string().default(''),
})

type DescriptionFormSchema = yup.InferType<typeof descriptionFormSchema>

interface DescriptionFormProps extends MutationBusinessInfoProps {
  baseDataAttribute: string
}

const DescriptionForm: React.FC<DescriptionFormProps> = ({
  baseDataAttribute,
  isLoading,
  update,
  onSubmitFinished,
}) => {
  const { activeLocationV3 } = useLocationContext()
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<DescriptionFormSchema>({
    resolver: yupResolver(descriptionFormSchema),
    defaultValues: {
      description: activeLocationV3.businessInfo.description,
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    await update(data)
    onSubmitFinished()
  })

  const disableForm = isSubmitting || isLoading

  return (
    <StyledForm
      data-cy={`${baseDataAttribute}-form-description`}
      onSubmit={onSubmit}
    >
      <StyledContent>
        <Heading as="h3">Description</Heading>
        <div>
          <StyledLabel>Business description:</StyledLabel>
          <StyledTextarea
            height={50}
            disabled={disableForm}
            {...register('description')}
          />
        </div>
        <ButtonForm
          baseDataAttribute={`${baseDataAttribute}-form-description`}
          disabled={disableForm}
        />
      </StyledContent>
    </StyledForm>
  )
}

export default DescriptionForm
