import 'react-toastify/dist/ReactToastify.css'

import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Routes from 'src/routes'
import theme from 'src/stories/theme'
import Vendors from 'src/containers/Vendors'
import ErrorBoundary from 'src/contexts/ErrorBoundary'
import UserlessRoutes from 'src/routes/UserlessRoutes'
import { AuthContextProvider } from 'src/contexts/AuthContext'
import { AccountContextProvider } from 'src/contexts/AccountContext'
import { NavigationInterceptorProvider } from 'src/contexts/NavigationInterceptor'
import { ModalNotificationsContextProvider } from 'src/contexts/ModalNotificationsContext'

const queryClient = new QueryClient()

const REDIRECT_URL = window.location.origin

const App: React.FC = () => {
  // TODO: handle http->https redirects at the ALB
  //
  // This would our listener rules to:
  // 1) Have different definitions for the HTTP and HTTPS ports
  // 2) Trigger a redirect (instead of forwarding to a target group)
  //
  // As of Feb 2022, neither is easy to define in keel
  useEffect(() => {
    if (window.location.protocol === 'http:') {
      const httpsHref = window.location.href.replace(
        window.location.protocol,
        'https:'
      )

      window.location.replace(httpsHref)
    }
  }, [])

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_URL!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      useRefreshTokens={true}
      useRefreshTokensFallback={false}
      authorizationParams={{
        redirect_uri: REDIRECT_URL,
        audience: process.env.REACT_APP_BACKEND_HOST,
      }}
      cacheLocation="localstorage"
    >
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ErrorBoundary>
              <AuthContextProvider>
                <AccountContextProvider>
                  <ModalNotificationsContextProvider>
                    <Vendors>
                      <NavigationInterceptorProvider>
                        <Routes />
                        <ToastContainer
                          autoClose={5000}
                          hideProgressBar
                          rtl={false}
                          bodyStyle={{ fontSize: '1.3rem' }}
                        />
                      </NavigationInterceptorProvider>
                    </Vendors>
                  </ModalNotificationsContextProvider>
                </AccountContextProvider>
              </AuthContextProvider>

              <UserlessRoutes />
            </ErrorBoundary>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </Auth0Provider>
  )
}

export default App
