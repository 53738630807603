import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { LocationLegacy, UserInterface } from 'src/client/interfaces/Common'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import { getLoginRedirect } from 'src/lib/Constants'
import { generateLocationUrl, generateUserUrl, isInternalUser } from 'src/utils'
import { UnknownObject } from 'src/utils/interfaces'
import logger from 'src/utils/logger'
import {
  attachRedirectionableParams,
  generateUserDefaultRedirect,
  getTOsRedirectUrl,
  userRequiresTosAgreement,
} from './utils'

export const handleRedirection = ({
  search,
  userLocations,
  authorizedUser,
}: {
  search: string
  userLocations: LocationLegacy[]
  authorizedUser: UserInterface
}) => {
  const logRedirectionEvent = (message: string, ...rest: unknown[]) =>
    logger.debug(`handleRedirection - ${message}`, { ...rest })

  let redirection = {
    internalNav: true,
    url: '',
  }
  const urlSearchParams = new URLSearchParams(search)
  const redirectInfo = getLoginRedirect(urlSearchParams.get('page')!)

  logRedirectionEvent('urlSearchParams', urlSearchParams)
  logRedirectionEvent('redirectInfo', redirectInfo)

  const userLocation = userLocations.find(
    (ul) => !!ul.agencyId && !!ul.agencySubdomain
  )

  const isAgencyRedirect =
    !isInternalUser(authorizedUser) &&
    !!userLocation?.agencyId &&
    !!userLocation?.agencySubdomain

  if (urlSearchParams.has('redirect')) {
    const redirectionUrl = new URL(urlSearchParams.get('redirect')!)

    redirection.url = redirectionUrl.toString()
    logRedirectionEvent(
      'urlSearchParams has redirect, setting redirection.url to',
      redirection.url
    )
  } else if (redirectInfo && !isInternalUser(authorizedUser)) {
    if (redirectInfo.type === 'Location') {
      redirection.url = generateLocationUrl(
        userLocations[0].merchantId,
        userLocations[0].locationId,
        redirectInfo.path
      )
      logRedirectionEvent(
        'redirectInfo.type is Location, setting redirection.url to',
        redirection.url
      )
    } else if (redirectInfo.type === 'User') {
      logRedirectionEvent(
        'redirectInfo.type is User, setting redirection.url to',
        redirection.url
      )
      redirection.url = generateUserUrl(authorizedUser.id, redirectInfo.path)
    } else {
      throw new Error(
        `redirectInfo.type is of unknown type: ${redirectInfo.type}`
      )
    }
  } else {
    redirection = generateUserDefaultRedirect(
      authorizedUser,
      userLocations,
      isAgencyRedirect
    )
    logRedirectionEvent(
      'no redirect found and no redirectInfo, redirection set to',
      redirection
    )
  }

  redirection.url = attachRedirectionableParams(
    window.location.origin,
    redirection.url,
    urlSearchParams
  )
  logRedirectionEvent(
    'redirection.url after attachRedirectionableParams',
    redirection.url
  )

  if (userRequiresTosAgreement(userLocations, authorizedUser)) {
    const termsUrl = getTOsRedirectUrl(redirection.url)

    redirection.url = termsUrl
    logRedirectionEvent(
      'urlSearchParams has redirect, Terms of Service to',
      redirection.url
    )
  }

  if (redirection.internalNav) {
    if (isAgencyRedirect) {
      const url = new URL(window.location.href)
      const redirectionPathname = new URL(redirection.url).pathname

      logRedirectionEvent(
        'redirecting to agency domain',
        redirection.url,
        redirectionPathname
      )
      url.hostname = url.hostname.replace(
        /.+(\..+)*(?=\..+\..+)/i,
        userLocation.agencySubdomain
      )
      url.pathname = redirectionPathname
      logRedirectionEvent('1. about to navigate to', url.toString())
      window.location.assign(url.toString())
    } else {
      logRedirectionEvent('2. about to navigate to', redirection.url)
      window.location.assign(redirection.url)
    }
  } else {
    logRedirectionEvent('3. about to navigate to', redirection.url)
    window.location.assign(redirection.url)
  }
}

const Login: React.FC = () => {
  const { handleTokenLogin, user } = useAuthContext()
  const { locations } = useAccountContext()
  const { search } = useLocation()

  const [loggingInWithToken, setLogginInWithToken] = useState(false)

  const doTokenLogin = useCallback(
    async (
      token: string | null,
      userEmailFingerprint: string | null,
      oneTimePassword: string | null
    ) => {
      try {
        await handleTokenLogin({ token, userEmailFingerprint, oneTimePassword })
      } catch (error) {
        setLogginInWithToken(false)

        // as of July 2022, all auth errors caused by userEmailFingerprint are 401s due to
        // expired fingerprints. Suppressing them from the RUM errors for now. This risks
        // suppressing 5XX errors, but those would appear in the Glaze logs for the proxy endpoint
        if (userEmailFingerprint) {
          logger.info('Error handling token login - userEmailFingerprint')

          return
        }

        if (!error) {
          logger.error('Error handling token login, undefined error', {
            token: !!token,
            userEmailFingerprint: !!userEmailFingerprint,
            oneTimePassword: !!oneTimePassword,
          })

          return
        }

        const customError = error as Error & UnknownObject<boolean>

        customError.token = !!token
        customError.userEmailFingerprint = !!userEmailFingerprint
        customError.oneTimePassword = !!oneTimePassword
        logger.error('Error handling token login', customError)
      }
    },
    [handleTokenLogin]
  )

  useEffect(() => {
    const params = new URLSearchParams(search)

    const token = params.get('token')
    const userEmailFingerprint = params.get('userEmailFingerprint')
    const oneTimePassword = params.get('oneTimePassword')

    const loggingInWithTokenValue =
      token || userEmailFingerprint || oneTimePassword

    setLogginInWithToken(!!loggingInWithTokenValue)

    if (loggingInWithTokenValue) {
      void doTokenLogin(token, userEmailFingerprint, oneTimePassword)
    }
  }, [doTokenLogin, search])

  useEffect(() => {
    if (locations && user) {
      handleRedirection({
        search,
        userLocations: locations,
        authorizedUser: user,
      })
    }
  }, [locations, user, search])

  if (loggingInWithToken) {
    return <p>We are magically logging you in. Stand by...</p>
  }

  return null
}

export default Login
