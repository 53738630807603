import React from 'react'
import SuccessfullySubmittedBanner from 'src/assets/sms-registration-successfully-submitted.webp'
import SmsRegistrationLayout, {
  StyledCopy,
} from 'src/components/Settings/Business/SmsRegistrationLayout'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Button } from 'src/stories/Button'
import { WizardLayout } from 'src/stories/Wizard'
import styled from 'styled-components'

interface FinishStepProps {
  nextStep: () => void
}

const StyledImg = styled.img<{ $isSmallScreen: boolean }>(
  ({ theme, $isSmallScreen }) => ({
    display: 'flex',
    width: '100%',
    maxWidth: theme.space(150),
    minWidth: $isSmallScreen ? '100%' : theme.space(138.5),
    marginBottom: theme.space(8),
  })
)

const FinishStep: React.FC<FinishStepProps> = ({ nextStep }) => {
  /**
   * isSmallScreen return true when screen size is greater
   * or equal to 640x960
   */
  const { isSmallScreen: greaterThanSmallScreen } = useScreenSizes()

  return (
    <WizardLayout>
      <SmsRegistrationLayout
        title="SMS registration successfully submitted!"
        buttonNext={
          <Button
            size="large"
            action="primary"
            label="Finish"
            baseDataAttribute="sms-registration-finish"
            onClick={nextStep}
          />
        }
      >
        <StyledCopy>
          Congratulations! We will now submit your registration for
          verification, which may take up to 15 days. You can check your status
          in the Settings Page. Once approved, you can start engaging with your
          contacts through text messages and text campaigns without worrying
          about phone carriers blocking your messages.
        </StyledCopy>

        <StyledImg
          $isSmallScreen={!greaterThanSmallScreen}
          src={SuccessfullySubmittedBanner}
          alt="SMS registration successfully submitted"
        />
      </SmsRegistrationLayout>
    </WizardLayout>
  )
}

export default FinishStep
