import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import useInsightsContext from 'src/contexts/InsightsContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { InsightsWidget } from './InsightsWidget'
import {
  StyledWidgetBlackText,
  StyledWidgetContentContainer,
  WidgetDataItem,
} from './styled'
import { WidgetProps } from './types'

const ReviewsCampaignsWidget: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const { allTimeReviewsCount, summaryReviews, isReviewLoading } =
    useInsightsContext()

  const [reviewData, setReviewData] = useState<{
    opened: number
    sent: number
    openedPercentage: string
    submittedPercentage: string
  }>({ opened: 0, sent: 0, openedPercentage: '0', submittedPercentage: '0' })

  useEffect(() => {
    if (summaryReviews.email > 0) {
      const openedPercentage = (
        (summaryReviews.emailOpens * 100) /
        summaryReviews.email
      ).toFixed(1)
      const submittedPercentage = (
        (allTimeReviewsCount * 100) /
        summaryReviews.email
      ).toFixed(1)

      setReviewData({
        sent: summaryReviews.email,
        opened: summaryReviews.emailOpens,
        openedPercentage,
        submittedPercentage,
      })
    }
  }, [summaryReviews, allTimeReviewsCount])

  if (isReviewLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  return (
    <InsightsWidget
      baseDataAttribute={baseDataAttribute}
      title="Review Campaign"
    >
      <StyledWidgetContentContainer
        $gapUnits={3}
        data-cy={baseDataAttribute + '-container'}
      >
        <StyledWidgetBlackText data-cy={baseDataAttribute + '-label'}>
          Total requests:
        </StyledWidgetBlackText>
        <StyledWidgetContentContainer
          $gapUnits={2}
          data-cy={baseDataAttribute + '-data'}
        >
          <WidgetDataItem
            label="Sent Emails"
            value={`${reviewData.sent} (100%)`}
            baseDataAttribute={baseDataAttribute + '-data-item'}
          />
          <WidgetDataItem
            label="Opened Emails"
            value={`${reviewData.opened} (${reviewData.openedPercentage}%)`}
            baseDataAttribute={baseDataAttribute + '-data-item'}
          />
          <WidgetDataItem
            label="Reviews Submitted"
            value={`${allTimeReviewsCount} (${reviewData.submittedPercentage}%)`}
            baseDataAttribute={baseDataAttribute + '-data-item'}
          />
        </StyledWidgetContentContainer>
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default ReviewsCampaignsWidget
