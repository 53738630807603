import { useState, useEffect } from 'react'
import logger from 'src/utils/logger'

interface UseOauthProps {
  onCode: (code: string) => void
  integrationName: 'THUMBTACK'
}

const useOauth = ({ onCode, integrationName }: UseOauthProps) => {
  const [externalPopup, setExternalPopup] = useState<Window | null>()

  const onConnect = (url: string) => {
    setExternalPopup(window.open(url))
  }

  useEffect(() => {
    if (!externalPopup) {
      return
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer)

        return
      }

      let currentUrl: string | undefined

      try {
        currentUrl = externalPopup.location.href
      } catch {
        // skip cross-origin frame access errors
        return
      }

      if (!currentUrl) {
        return
      }

      const searchParams = new URL(currentUrl).searchParams

      const oauthCode = searchParams.get('code')

      if (oauthCode) {
        externalPopup.close()

        try {
          onCode(oauthCode)
        } catch (error) {
          logger.info(`${integrationName} integration onCode function failed`, {
            error,
          })
        } finally {
          setExternalPopup(null)
          timer && clearInterval(timer)
        }
      }
    }, 500)
  }, [externalPopup, integrationName, onCode])

  return {
    onConnect,
  }
}

export default useOauth
