import { format, parseISO } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import { DotIcon, CompanyIcon } from './assets'

export interface TimelineEvent {
  isSignpostEvent?: boolean
  event: string | JSX.Element
  details?: string | JSX.Element
  /** String ISO Date String or Date */
  timestamp: string | Date
}

export interface TimelineProps {
  events: TimelineEvent[]
  dataCy?: string
  isMobile?: boolean
}

const DotContainer = styled.div(({ theme }) => ({
  width: theme.space(6),
  height: theme.space(6),
  display: 'flex',
  alignSelf: 'flex-start',
  justifyContent: 'center',
  padding: theme.space(1),
}))

const StyledDotIcon = styled(DotIcon)(({ theme }) => ({
  width: '100%',
  height: '100%',
  fill: theme.colors.base_30,
}))

const StyledSignpostIcon = styled(CompanyIcon)(({ theme }) => ({
  width: '100%',
  height: '100%',
}))

const LinePlaceholder = styled.div(({ theme }) => ({
  width: theme.space(2.75),
  height: theme.space(1),
}))

const Details = styled.div<{
  $lastItem?: boolean
  $additionalGap?: boolean
}>(({ theme, $lastItem, $additionalGap }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.space(3),
  borderLeft: !$lastItem ? `2px solid ${theme.colors.base_10}` : undefined,
  minHeight: theme.space(5),
  width: '100%',
  paddingTop: theme.space(1),
  paddingBottom: theme.space(1),
  gap: $additionalGap ? theme.space(2) : undefined,
}))

const Row = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

const Text = styled.div(({ theme }) => ({
  margin: 0,
  flexGrow: 1,
  fontSize: '1.6rem',
  lineHeight: '2rem',
}))

export const Timestamp = styled.span(({ theme }) => ({
  color: theme.colors.base_40,
  fontSize: '1.2rem',
  marginBottom: theme.space(3),
}))

const Timeline: React.FC<TimelineProps> = ({
  events,
  dataCy = 'timeline',
  isMobile,
}) => {
  return (
    <div data-cy={dataCy}>
      {events.map(({ event, timestamp, details, isSignpostEvent }, idx) => {
        const formattedTimestamp = format(
          typeof timestamp === 'string' ? parseISO(timestamp) : timestamp,
          'MM/dd/yyyy'
        )

        const dataAttribute = `${dataCy}-${idx}-`

        const timestampComponent = (
          <Timestamp data-cy={dataAttribute + 'timestamp'}>
            {formattedTimestamp}
          </Timestamp>
        )

        return (
          <div key={idx} data-cy={dataAttribute + 'wrapper'}>
            <Row data-cy={dataAttribute + 'content-container'}>
              <DotContainer data-cy={dataAttribute + 'dot-container'}>
                {isSignpostEvent ? (
                  <StyledSignpostIcon
                    data-cy={dataAttribute + 'signpost-dot'}
                  />
                ) : (
                  <StyledDotIcon data-cy={dataAttribute + 'dot'} />
                )}
              </DotContainer>
              <Text data-cy={dataAttribute + 'text-wrapper'}>{event}</Text>
              {!isMobile && timestampComponent}
            </Row>
            <Row data-cy={dataAttribute + 'details-container'}>
              <LinePlaceholder data-cy={dataAttribute + 'line'} />
              <Details
                data-cy={dataAttribute + 'details-wrapper'}
                $lastItem={events.length - 1 === idx && !details && !isMobile}
                $additionalGap={isMobile && !!details}
              >
                <div data-cy={dataAttribute + 'details'}>{details}</div>
                {isMobile && timestampComponent}
              </Details>
            </Row>
          </div>
        )
      })}
    </div>
  )
}

export default Timeline
