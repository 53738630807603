import { MutationObserverResult } from '@tanstack/react-query'
import { BusinessInfoInterface } from 'src/client/interfaces/Common'

export interface MutationBusinessProps
  extends Pick<MutationObserverResult, 'isLoading'> {
  update: (newValue: Partial<BusinessInfoInterface>) => Promise<void>
}

export enum SmsRegistrationWizardSteps {
  OVERVIEW = 0,
  BUSINESS_INFO = 1,
  PRIMARY_CONTACT_INFO = 2,
  REVIEW_AND_SUBMIT = 3,
  FINISH = 4,
}
