import { BaseLegacyPageProps } from 'src/utils/interfaces'
import useSidebarRoutes from 'src/routes/hooks'
import WafLayout from 'src/components/WafLayout'
import LegacyPages from 'src/containers/LegacyPages'
import { getLocationPreferredTerms } from 'src/utils'
import { useLocationContext } from 'src/contexts/LocationContext'

type ReviewsProps = BaseLegacyPageProps

const Reviews: React.FC<ReviewsProps> = ({ isMultiLocation }) => {
  const { merchantId, locationId, activeLocation } = useLocationContext()

  const locationPreferredTerms = getLocationPreferredTerms(activeLocation)

  const { reviewsSidebarRoutes } = useSidebarRoutes({
    merchantId,
    locationId,
    consumerTerms: locationPreferredTerms.consumerTerms,
  })

  return (
    <WafLayout
      sidebarTitle="Reviews"
      sidebarRoutes={reviewsSidebarRoutes}
      styleOptions={{
        removePadding: true,
        withMultiLocationHeader: isMultiLocation,
      }}
    >
      <LegacyPages withinLayout withMultiLocationHeader={isMultiLocation} />
    </WafLayout>
  )
}

export default Reviews
