import React from 'react'

import { Routes as ReactRoutes } from 'react-router-dom'

const UserlessRoutes: React.FC = () => {
  return (
    <ReactRoutes>
      {/* Previously, it was used for self signup route, which has been discontinued.
      If you need to insert a route that doesn't require the user to be logged in, do so here.
      
      e.g: <Route path="/signup" element={<Signup />}*/}
    </ReactRoutes>
  )
}

export default UserlessRoutes
