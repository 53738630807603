import { useEffect, useRef, useState } from 'react'
import { intervalToDuration, format } from 'date-fns'
import styled, { useTheme } from 'styled-components'
import Slider from 'react-slider'
import { MediaResource } from 'src/client'
import logger from 'src/utils/logger'
import { downloadFile } from 'src/components/MessagingHub/utils'
import { DownloadIcon, PlayIcon, PauseIcon } from 'src/stories/assets'

const StyledContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '25vw',
  maxWidth: theme.space(95),
}))

const StyledExpirationLabel = styled.span(() => ({
  fontWeight: 400,
}))

const StyledDownloadContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.space(1),
  marginTop: theme.space(2),
}))

const StyledSliderContainer = styled.div(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.space(1),
}))

const StyledLabel = styled.strong(({ theme }) => ({
  margin: 0,
  marginBottom: theme.space(1),
  color: theme.colors.base_80,
  fontWeight: 500,
}))

const StyledPlayIconContainer = styled.div(({ theme }) => ({
  width: theme.space(6),
  height: theme.space(6),
  marginRight: theme.space(3),
}))

const StyledTimer = styled.div(({ theme }) => ({
  marginLeft: theme.space(3),
  paddingTop: theme.space(1),
}))

const StyledSlider = styled(Slider)(({ theme }) => ({
  '&.horizontalSlider': {
    width: '100%',
    maxWidth: theme.space(125),
    margin: 'auto',
  },

  '.playerThumb': {
    cursor: 'pointer',
    position: 'absolute',
    zIndex: theme.zIndexes.layout + 1,
    background: theme.colors.primary_1_70,
    borderRadius: '100%',
    display: 'block',
    boxShadow: '0 0 2px 0 rgb(0 0 0 / 44%)',
  },

  '.playerThumb.active': {
    backgroundColor: theme.colors.primary_1_70,
  },

  '.playerTrack': {
    position: 'relative',
    background: theme.colors.base_20,
  },

  '.playerTrack.playerTrack-0': {
    background: theme.colors.primary_1_70,
  },

  '&.horizontalSlider .playerTrack': {
    height: theme.space(1),
  },

  '&.horizontalSlider .playerThumb': {
    width: theme.space(3),
    height: theme.space(3),
    top: theme.space(-1),
  },
}))

const Link = styled.a(({ theme }) => ({
  fontWeight: 400,
  color: theme.colors.primary_2,
  marginLeft: theme.space(2),
  display: 'inline',
  textDecorationLine: 'underline',
  cursor: 'pointer',
}))

interface PhoneCallRecordingPlayerProps {
  recordingUrl?: string
  downloadEnabled?: boolean
  recordingDeletedDate?: string
}

const PhoneCallRecordingPlayer: React.FC<PhoneCallRecordingPlayerProps> = ({
  recordingUrl,
  downloadEnabled,
  recordingDeletedDate,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null)

  const [audioPercentage, setAudioPercentage] = useState(0)
  const [currentTimestamp, setCurrentTimestamp] = useState('00:00')
  const [isPlaying, setIsPlaying] = useState(false)
  const [file, setFile] = useState<Blob | undefined>(undefined)
  const [filename, setFilename] = useState('')
  const theme = useTheme()

  const handlePlayButton = () => {
    setIsPlaying((currState) => {
      currState ? audioRef.current?.pause() : audioRef.current?.play()

      return !currState
    })
  }

  const handleNavigateAudio = (value?: number) => {
    if (value && audioRef.current) {
      audioRef.current.pause()
      setIsPlaying(false)

      setAudioPercentage(value)

      // What percentage of the total duration is value
      audioRef.current.currentTime = (audioRef.current.duration * value) / 100
    }
  }

  const setTimeUpdater = (audioElement: HTMLAudioElement) => {
    audioElement.addEventListener('timeupdate', (e) => {
      // What percentage of the total duration is the current time
      const currPercentage = Math.floor(
        (audioElement.currentTime * 100) / audioElement.duration
      )

      const durationInterval = intervalToDuration({
        start: 0,
        end: Math.floor(audioElement.currentTime * 1000),
      })
      const minutes = durationInterval.minutes?.toString() || '00'
      const seconds = durationInterval.seconds?.toString() || '00'

      const newTimestamp = `${minutes.length === 1 ? `0${minutes}` : minutes}:${
        seconds.length === 1 ? `0${seconds}` : seconds
      }`

      setCurrentTimestamp(newTimestamp)
      setAudioPercentage(currPercentage)

      if (currPercentage === 100) {
        setIsPlaying(false)
      }
    })
  }

  useEffect(() => {
    const getAudioFile = async () => {
      try {
        if (!recordingUrl || !downloadEnabled || recordingDeletedDate) {
          return
        }

        const { data } = await MediaResource.getPresignedUrlMedia(recordingUrl)
        const timestamp = format(Date.now(), 'yyyyMMdd_HHmmss')

        setFilename(`recording_${timestamp}`)
        setFile(data)
      } catch (err) {
        logger.error('Error retrieving recording', { err })
      }
    }

    void getAudioFile()
  }, [recordingUrl, downloadEnabled, recordingDeletedDate])

  useEffect(() => {
    const current = audioRef.current

    if (current) {
      current.addEventListener('loadeddata', () => setTimeUpdater(current), {
        once: true,
      })
    }
  }, [])

  return (
    <StyledContainer>
      <StyledLabel>Call recording:</StyledLabel>
      {recordingDeletedDate ? (
        <StyledExpirationLabel>
          {`Call recording expired on ${recordingDeletedDate}`}
        </StyledExpirationLabel>
      ) : (
        <>
          {recordingUrl && (
            <>
              <audio ref={audioRef} src={recordingUrl} />

              <StyledSliderContainer>
                <StyledPlayIconContainer onClick={handlePlayButton}>
                  {isPlaying ? (
                    <PauseIcon width={28} height={28} />
                  ) : (
                    <PlayIcon width={28} height={28} />
                  )}
                </StyledPlayIconContainer>

                <StyledSlider
                  className="horizontalSlider"
                  thumbClassName="playerThumb"
                  trackClassName="playerTrack"
                  renderThumb={(props) => <div {...props} />}
                  onChange={(value) => handleNavigateAudio(value as number)}
                  value={audioPercentage}
                />

                <StyledTimer>{currentTimestamp}</StyledTimer>
              </StyledSliderContainer>
              {downloadEnabled && file && (
                <StyledDownloadContainer>
                  <DownloadIcon fill={theme.colors.primary_2} />
                  <Link onClick={() => downloadFile({ file, filename })}>
                    Download recording
                  </Link>
                </StyledDownloadContainer>
              )}
            </>
          )}
        </>
      )}
    </StyledContainer>
  )
}

export default PhoneCallRecordingPlayer
