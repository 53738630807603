import { useQuery } from '@tanstack/react-query'

import Locations from 'src/client/resources/Locations'
import logger from 'src/utils/logger'

const useMarketingSettings = ({ locationId }: { locationId: number }) =>
  useQuery({
    queryKey: [
      'loop-configurations',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) =>
      Locations.getMarketingSettings(lid),
    onError: (error: unknown) =>
      logger.error(`WAF ${useMarketingSettings.name} - error`, { error }),
    refetchOnWindowFocus: false,
    enabled: !!locationId && !isNaN(locationId),
  })

export default useMarketingSettings
