import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import {
  formatPhoneNumber,
  isPhoneNumberValid,
  unFormatPhoneNumber,
} from 'src/utils'
import logger from 'src/utils/logger'
import {
  StyledNewConvoSearchContainer,
  StyledNewConvoSearchItem,
  StyledSearchItemText,
} from 'src/components/MessagingHub/styled'
import { Row, Col } from 'src/stories/Layout'
import { ContactsLegacyResource } from 'src/client'
import { useDebouncer } from 'src/hooks/useDebounce'
import Dropdown, { closeDropdownEvent } from 'src/stories/Dropdown'
import MessageSend from 'src/components/MessagingHub/MessagesPane/MessageSend'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { useHandleOnSend } from 'src/components/MessagingHub/ConversationsPane/hooks'
import { HandleTabConversationChangeParams } from 'src/containers/MessagingHub/types'
import { StyledTabsSearchInput } from 'src/components/MessagingHub/ConversationsPane/styled'
import { Contact } from 'src/client/interfaces/Contacts'
import { getContactDisplayName } from 'src/client/formatters'

const StyledModalContainer = styled(Row)(({ theme }) => ({
  width: '100%',
  maxHeight: '100%',
}))

interface Props {
  locationId?: string
  handleTabConversationChange: (
    params: HandleTabConversationChangeParams
  ) => void
}

const NewConversationModal: React.FC<Props> = ({
  locationId,
  handleTabConversationChange,
}) => {
  const { closeModal } = useModalNotificationsContext()
  const inputRef = useRef<HTMLInputElement>(null)
  const [newConversationInputValue, setNewConversationInputValue] = useState('')
  const [newConversationContact, setNewConversationContact] = useState<number>()
  const [newConversationContactChannelId, setNewConversationContactChannelId] =
    useState<number>()
  const [isSearching, setIsSearching] = useState(false)
  const [contactsSearch, setContactsSearch] = useState<Contact[]>([])

  const isValidPhoneNumber = useMemo(() => {
    const formattedPhoneNumber = formatPhoneNumber(newConversationInputValue)

    return isPhoneNumberValid(formattedPhoneNumber)
  }, [newConversationInputValue])

  const unFormattedNewConversationInputValue = unFormatPhoneNumber(
    newConversationInputValue
  )

  const shouldShowDropdown = !(
    unFormattedNewConversationInputValue &&
    unFormattedNewConversationInputValue.length >= 10 &&
    contactsSearch.length === 0
  )

  useEffect(() => {
    if (!newConversationContact && isValidPhoneNumber) {
      setNewConversationInputValue(formatPhoneNumber(newConversationInputValue))
    }
  }, [isValidPhoneNumber, newConversationContact, newConversationInputValue])

  const handleOnSend = useHandleOnSend({
    newConversationInputValue,
    newConversationContact,
    newConversationContactChannelId,
    closeModal,
    locationId,
    handleTabConversationChange,
  })

  const handleContactSearch = useDebouncer<
    ChangeEventHandler<HTMLInputElement>
  >(
    useCallback(
      async (e) => {
        const { value: inputValue } = e.target

        if (inputValue.length >= 3) {
          const unFormattedPhoneNumber = unFormatPhoneNumber(inputValue)

          try {
            const { data: contacts } = await ContactsLegacyResource.search(
              +locationId!,
              unFormattedPhoneNumber || inputValue
            )

            let contactsWithPhone = contacts.filter((c) => {
              return typeof c.primaryPhoneChannelId !== 'undefined'
            })

            if (contactsWithPhone.length > 10) contactsWithPhone.length = 10

            setContactsSearch(contactsWithPhone)
          } catch (error) {
            logger.debug('Unable to find contact', { error })
          } finally {
            setIsSearching(false)
          }
        } else {
          setContactsSearch([])
          setIsSearching(false)
        }
      },
      [locationId]
    )
  )

  return (
    <StyledModalContainer direction="column">
      <Row>
        <Col>
          <StyledTabsSearchInput
            ref={inputRef}
            data-cy="search-input"
            name="search-input"
            label="Enter a name or phone number:"
            value={newConversationInputValue}
            onChange={(e) => {
              setNewConversationContact(undefined)
              setNewConversationInputValue(e.target.value)
              setIsSearching(true)
              handleContactSearch(e)
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MessageSend
            onAttach={() => logger.debug('Attach')}
            onSend={handleOnSend}
            height={50}
            errorMessage=""
            disableMessageSendButtons={false}
            forceDisable={!newConversationContact && !isValidPhoneNumber}
            withMargins={false}
            dayaCyUniquePrefix="modal"
            isInModal
          />
        </Col>
      </Row>

      <Dropdown
        anchor={inputRef}
        hideArrow
        alignment="left"
        shouldShowDropdown={shouldShowDropdown}
      >
        <StyledNewConvoSearchContainer
          data-cy={`mh-new-conversation-modal-search-items`}
        >
          {isSearching && (
            <StyledNewConvoSearchItem>
              <StyledSearchItemText>Loading...</StyledSearchItemText>
            </StyledNewConvoSearchItem>
          )}
          {!isSearching && contactsSearch.length === 0 && (
            <StyledNewConvoSearchItem
              data-cy={`mh-new-conversation-modal-no-search-item`}
            >
              No contacts found
            </StyledNewConvoSearchItem>
          )}
          {!isSearching &&
            contactsSearch.length > 0 &&
            contactsSearch.map((cs, idx) => (
              <StyledNewConvoSearchItem
                data-cy={`mh-new-conversation-modal-search-item-${idx}`}
                key={idx}
                onClick={() => {
                  setNewConversationInputValue(getContactDisplayName(cs))
                  setNewConversationContact(cs.id)
                  setNewConversationContactChannelId(cs.primaryPhoneChannelId)
                  inputRef.current!.dispatchEvent(closeDropdownEvent)
                }}
              >
                {
                  <StyledSearchItemText
                    data-cy={`mh-new-conversation-modal-search-item-${idx}-name`}
                  >
                    {cs.firstName || cs.lastName
                      ? getContactDisplayName(cs)
                      : ''}
                  </StyledSearchItemText>
                }
                {cs.primaryPhoneNumber && (
                  <StyledSearchItemText
                    data-cy={`mh-new-conversation-modal-search-item-${idx}-number`}
                  >{`${formatPhoneNumber(
                    cs.primaryPhoneNumber
                  )}`}</StyledSearchItemText>
                )}
              </StyledNewConvoSearchItem>
            ))}
        </StyledNewConvoSearchContainer>
      </Dropdown>
    </StyledModalContainer>
  )
}

export default NewConversationModal
