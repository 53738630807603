import React, { useRef } from 'react'
import { Button } from 'src/stories/Button'
import Dropdown, {
  DropdownItem,
  DropdownItemsContainer,
  closeDropdownEvent,
} from 'src/stories/Dropdown'
import { useTheme } from 'styled-components'

interface Props {
  baseDataAttribute: string
  isDesktop: boolean
  onManage: () => void
  onGoToReviewSite: () => void
}

const OptionButton: React.FC<Props> = ({
  baseDataAttribute,
  isDesktop,
  onGoToReviewSite,
  onManage,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const theme = useTheme()

  return (
    <>
      <Button
        baseDataAttribute={baseDataAttribute}
        reference={buttonRef}
        outline
        label="View options"
        style={
          isDesktop
            ? {
                width: theme.space(27),
                height: theme.space(8),
              }
            : {}
        }
      />
      <Dropdown
        anchor={buttonRef}
        hideArrow
        alignment="right"
        scrollableContainerQuerySelector="[data-cy='content-container']"
        style={{
          borderRadius: theme.constants.borderRadius,
        }}
      >
        <DropdownItemsContainer>
          <DropdownItem
            data-cy={`${baseDataAttribute}-goto-rs`}
            data-dd={`${baseDataAttribute}-goto-rs`}
            onClick={() => {
              onGoToReviewSite()
              buttonRef.current?.dispatchEvent(closeDropdownEvent)
            }}
          >
            Go to review site
          </DropdownItem>
          <DropdownItem
            data-cy={`${baseDataAttribute}-manage`}
            data-dd={`${baseDataAttribute}-manage`}
            onClick={() => {
              onManage()
              buttonRef.current?.dispatchEvent(closeDropdownEvent)
            }}
          >
            Manage
          </DropdownItem>
        </DropdownItemsContainer>
      </Dropdown>
    </>
  )
}

export default OptionButton
