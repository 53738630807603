import React, { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import SuccessfulImage from 'src/assets/google-messages-successful-connection.png'
import useAccountContext from 'src/contexts/AccountContext'
import { ProTipIcon } from 'src/stories/assets'
import { Button } from 'src/stories/Button'
import { HelperWidget } from 'src/stories/HelperWidget'
import { Col, Row } from 'src/stories/Layout'

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  marginBottom: theme.space(3),
}))

const StyledCopy = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(6),
}))

const StyledImgContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.positive_15,
  borderRadius: theme.constants.borderRadius,
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.space(8),
  paddingBottom: theme.space(10),
  paddingTop: theme.space(10),
}))

interface SetupCompleteStepProps {
  nextStep: () => void
}

const SetupCompleteStep: React.FC<SetupCompleteStepProps> = ({ nextStep }) => {
  const { handleRefetchUserLocations } = useAccountContext()
  const [isRefreshing, setIsRefreshing] = useState(false)

  const onFinish = async () => {
    setIsRefreshing(true)

    try {
      await handleRefetchUserLocations()

      // No need to set isRefreshing to false on sucess
      // because handleRefetchUserLocations will force a page refresh
    } catch (error) {
      setIsRefreshing(false)

      toast.error(
        'There was an error trying to update the integration status. Please refresh the page.'
      )
    }
  }

  return (
    <Row>
      <Col w={2}>
        <StyledTitle>Your Google account is connecting</StyledTitle>

        <StyledCopy>
          You're all set! Google will reach out to confirm your information.
          This may take anywhere from 1-3 days. Automatically follow up with new
          leads from Google with Instant Responders. Use the default Instant
          Response message or revise it to better sound like your brand.
        </StyledCopy>

        <StyledImgContainer>
          <img src={SuccessfulImage} alt="Set up complete" />
        </StyledImgContainer>

        <Button
          action="primary"
          label="Finish"
          loading={isRefreshing}
          maxWidth={167}
          onClick={onFinish}
        />
      </Col>

      <Col>
        <HelperWidget
          copy={`Keep the momentum going by connecting more lead sources and set up
            instant responders so you never miss a lead!`}
          icon={ProTipIcon}
          title="Pro tip"
        />
      </Col>
    </Row>
  )
}

export default SetupCompleteStep
