import { useQuery } from '@tanstack/react-query'
import Segments from 'src/client/resources/Segments'
import logger from 'src/utils/logger'

const useSegments = ({ locationId }: { locationId: number }) => {
  return useQuery({
    queryKey: [
      'segments',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) => Segments.get(lid),
    onError: (error) => {
      logger.error('WAF useSegmentsQuery - error', { error })
    },
  })
}

export default useSegments
