import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  formatPhoneNumber,
  isPhoneNumberValid,
  slugify,
  unFormatPhoneNumber,
} from 'src/utils'
import logger from 'src/utils/logger'
import Input from 'src/stories/Input'
import Constants from 'src/lib/Constants'
import { Button } from 'src/stories/Button'
import { ContactsLegacyResource, ContactsResource } from 'src/client'
import { DetailsLabel, DetailsText } from './styled'
import useMhContext from 'src/contexts/MhContext'
import { AddChannelsResponse } from 'src/client/interfaces/ContactsLegacy'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import {
  AgentzIcon,
  AngiLeadsIcon,
  AppointmentsIcon,
  CloverIcon,
  ConstantContactIcon,
  EmailIcon,
  FacebookIcon,
  FreshbooksIcon,
  GoogleIcon,
  HousecallProIcon,
  JobnimbusIcon,
  LeapIcon,
  MailchimpIcon,
  ManuallyAddedIcon,
  MobilePhoneIcon,
  OfferLandingPageIcon,
  PaypalIcon,
  ApiIcon,
  QuickbooksIcon,
  ReferralIcon,
  CompanyIcon,
  SquareIcon,
  StripeIcon,
  SynchroteamIcon,
  ThumbtackIcon,
  TransactionIcon,
  UploadedListIcon,
  ZapierIcon,
  PhoneIcon,
  WebchatIcon,
} from 'src/stories/assets'

const getIcon = (iconName = '') => {
  const icons: Record<
    string,
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  > = {
    agentz: AgentzIcon,
    'constant-contact': ConstantContactIcon,
    email: EmailIcon,
    facebook: FacebookIcon,
    freshbooks: FreshbooksIcon,
    'angi-leads': AngiLeadsIcon,
    'phone-forwarding': MobilePhoneIcon,
    quickbooks: QuickbooksIcon,
    signpost: CompanyIcon,
    google: GoogleIcon,
    thumbtack: ThumbtackIcon,
    appointments: AppointmentsIcon,
    housecallpro: HousecallProIcon,
    jobnimbus: JobnimbusIcon,
    'offer-landing-page': OfferLandingPageIcon,
    leap: LeapIcon,
    mailchimp: MailchimpIcon,
    'manually-added': ManuallyAddedIcon,
    paypal: PaypalIcon,
    'public-api': ApiIcon,
    referral: ReferralIcon,
    square: SquareIcon,
    stripe: StripeIcon,
    transaction: TransactionIcon,
    'uploaded-list': UploadedListIcon,
    zapier: ZapierIcon,
    clover: CloverIcon,
    synchroteam: SynchroteamIcon,
    phone: PhoneIcon,
    webchat: WebchatIcon,
  }

  const Icon = icons[iconName]

  return Icon ? <Icon fill="current" /> : null
}

const contactFormSchema = yup
  .object({
    name: yup.string(),
    phone: yup
      .string()
      .test('Is valid phone', 'Please enter a valid phone number', (value) => {
        if (!value) {
          return true
        }

        return isPhoneNumberValid(value || '')
      }),
    email: yup.string().email('Please check your email address and try again.'),
  })
  .required()

const SourceTypeSpan = styled.span(({ theme }) => ({
  height: theme.space(3),
}))

const StyledInformationText = styled.p<{ spaced?: boolean }>(
  ({ theme, spaced }) => ({
    fontSize: '1.4rem',
    marginBottom: theme.space(spaced ? 7 : 0),
  })
)

const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

const StyledNameValueContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))

const generateDataCy = (label: string, formPanel?: boolean) =>
  `mh-contact-details-contact-info-${formPanel ? 'form-' : ''}${label}`

type ContactFormType = 'name' | 'email' | 'phone'

interface ContactForm {
  type: ContactFormType
  locationId: number
  contactId: number
  hasWrittenName: boolean
  name: string
  phone: string
  email: string
  reloadCustomer: () => Promise<void>
  reloadConversations: () => Promise<void>
  resetOpenConversation: () => void
}

const ContactInfoSectionForm: React.FC<ContactForm> = ({
  type,
  locationId,
  contactId,
  hasWrittenName,
  name,
  phone,
  email,
  reloadCustomer,
  reloadConversations,
  resetOpenConversation,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { closeModal } = useModalNotificationsContext()

  const [mergeInformation, setMergeInformation] = useState<{
    contactId: number
    name: string
    phone: string
    email: string
    conflictField: 'email' | 'phone'
  }>()

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting, dirtyFields },
    watch,
    register,
    setValue,
  } = useForm<ContactForm>({
    reValidateMode: 'onBlur',
    resolver: yupResolver(contactFormSchema),
  })

  const formWatcher = watch()

  const onSubmit = handleSubmit(async (data) => {
    if (
      (dirtyFields.name && data.name?.length === 0) ||
      (dirtyFields.email && data.email?.length === 0) ||
      (dirtyFields.phone && data.phone?.length === 0)
    ) {
      toast.error('Empty contact information is not allowed')

      return
    }

    try {
      let updated

      if (dirtyFields.name) {
        await ContactsLegacyResource.setName({
          customerId: contactId,
          name: data.name,
        })

        updated = 'name'
      }

      if (dirtyFields.email || dirtyFields.phone) {
        try {
          const params = {
            customerId: contactId,
            emailAddress: dirtyFields.email ? data.email : undefined,
            phoneNumber: dirtyFields.phone
              ? unFormatPhoneNumber(data.phone)
              : undefined,
            mergeCustomers: !!mergeInformation,
          }

          await ContactsLegacyResource.addChannels(params)
        } catch (error) {
          interface ApiError {
            data?: AddChannelsResponse
          }

          const err = error as ApiError

          if (err.data?.message === Constants.ERRORS.contactsUnmergable) {
            const [existingCustomer] = err.data.existingCustomers

            const contact = await ContactsResource.getById(
              locationId,
              existingCustomer
            )

            setMergeInformation({
              contactId: contact.id,
              name: `${contact.firstName ?? ''} ${contact.lastName ?? ''}`,
              phone: formatPhoneNumber(contact.primaryPhoneNumber ?? ''),
              email: contact.primaryEmailAddress ?? '',
              conflictField: dirtyFields.email ? 'email' : 'phone',
            })

            return
          } else {
            throw error
          }
        }

        updated = 'phone|email'
      }

      if (!!updated) {
        if (!!mergeInformation) {
          navigate('?')
          resetOpenConversation()
        } else {
          await reloadCustomer()
        }

        setMergeInformation(undefined)
        await reloadConversations()
        toast.success('Contact updated successfully')
        closeModal()
      }
    } catch (error) {
      logger.error('MH - Error updating customer', { error })
      toast.error('There was an error trying to update the customer.')
    }
  })

  useEffect(() => {
    setValue('name', hasWrittenName ? name : '')
    setValue('phone', phone)
    setValue('email', email)
  }, [email, hasWrittenName, name, phone, setValue])

  useEffect(() => {
    const { phone: formPhone } = formWatcher

    if (formPhone?.length === 10) {
      setValue('phone', formatPhoneNumber(formPhone))
    }
  }, [formWatcher, setValue])

  return (
    <>
      <form onSubmit={onSubmit} style={{ width: '100%' }}>
        {type === 'name' && (
          <Input
            label="Name"
            autoComplete="name"
            data-cy={generateDataCy('name', true)}
            errors={formErrors}
            {...register('name')}
          />
        )}
        {type === 'phone' && (
          <Input
            label="Phone"
            autoComplete="phone"
            data-cy={generateDataCy('phone', true)}
            errors={formErrors}
            disabled={!!mergeInformation}
            {...register('phone')}
          />
        )}
        {type === 'email' && (
          <Input
            label="Email"
            autoComplete="email"
            data-cy={generateDataCy('email', true)}
            errors={formErrors}
            disabled={!!mergeInformation}
            {...register('email')}
          />
        )}

        {!!mergeInformation && (
          <div>
            <StyledInformationText>
              <strong>
                {mergeInformation.conflictField === 'email'
                  ? mergeInformation.email
                  : formatPhoneNumber(mergeInformation.phone)}
              </strong>{' '}
              is already attached to a different contact
            </StyledInformationText>
            <StyledInformationText>
              <strong>Name:</strong> {mergeInformation.name}
            </StyledInformationText>
            <StyledInformationText>
              <strong>Phone:</strong> {mergeInformation.phone}
            </StyledInformationText>
            <StyledInformationText>
              <strong>Email:</strong> {mergeInformation.email}
            </StyledInformationText>
            <StyledInformationText spaced>
              Do you want to merge these contacts?
            </StyledInformationText>
          </div>
        )}

        <StyledButtonsContainer>
          <Button
            action="secondary"
            type="button"
            label="Cancel"
            baseDataAttribute={generateDataCy('cancel', true)}
            style={{ marginRight: theme.space(6) }}
            disabled={isSubmitting}
            onClick={
              !!mergeInformation
                ? () => setMergeInformation(undefined)
                : closeModal
            }
          />

          <Button
            action="primary"
            type="submit"
            baseDataAttribute={generateDataCy('merge-save', true)}
            label={!!mergeInformation ? 'Merge' : 'Save'}
            disabled={isSubmitting}
          />
        </StyledButtonsContainer>
      </form>
    </>
  )
}

interface Item {
  label: string
  value: string | ReactElement
}

interface Props {
  locationId: number
  contactId: number
  name: string
  hasWrittenName: boolean
  phone: string
  email: string
  source: string
}

const ContactInfoSection: React.FC<Props> = ({
  locationId,
  contactId,
  name,
  hasWrittenName,
  email,
  phone,
  source,
}) => {
  const theme = useTheme()
  const { showModal } = useModalNotificationsContext()
  const {
    getOpenConversationContactDetails,
    refetchConversationsList,
    setOpenConversationId,
  } = useMhContext()

  const formButton = useCallback(
    (type: ContactFormType, label: string) => (
      <Button
        style={{ width: theme.space(6) }}
        displayAsText
        label={label}
        baseDataAttribute={generateDataCy(`${type}-${label}-edit`, false)}
        onClick={() =>
          showModal({
            title: 'Contact Info',
            height: 'auto',
            dataCy: 'mh-contact-details-contact-info',
            width: theme.space(110),
            hideActionButtons: true,
            customBody: (
              <ContactInfoSectionForm
                type={type}
                name={name}
                phone={phone}
                email={email}
                hasWrittenName={hasWrittenName}
                locationId={locationId}
                contactId={contactId}
                reloadCustomer={getOpenConversationContactDetails}
                reloadConversations={refetchConversationsList}
                resetOpenConversation={() => setOpenConversationId(undefined)}
              />
            ),
          })
        }
      />
    ),
    [
      locationId,
      contactId,
      email,
      getOpenConversationContactDetails,
      hasWrittenName,
      name,
      phone,
      refetchConversationsList,
      setOpenConversationId,
      showModal,
      theme,
    ]
  )

  const Icon = getIcon(Constants.CUSTOMERS.MappedSourceTypes[source]?.iconName)
  const items: Item[] = useMemo(() => {
    return [
      {
        label: 'Name',
        value: (
          <>
            {hasWrittenName ? (
              <StyledNameValueContainer>
                <>{name}</>
                <>{formButton('name', 'Edit')}</>
              </StyledNameValueContainer>
            ) : (
              formButton('name', 'Add')
            )}
          </>
        ),
      },
      {
        label: 'Phone',
        value: <>{phone ? phone : formButton('phone', 'Add')}</>,
      },
      {
        label: 'Email',
        value: <>{email ? email : formButton('email', 'Add')}</>,
      },
      {
        label: 'Source',
        value: (
          <>
            {Icon}
            <SourceTypeSpan>
              {Constants.CUSTOMERS.MappedSourceTypes[source]?.displayName}
            </SourceTypeSpan>
          </>
        ),
      },
    ]
  }, [hasWrittenName, name, formButton, phone, email, Icon, source])

  return (
    <>
      {items.map((item) => {
        const slugified = slugify(item.label)

        return (
          <div key={item.label} data-cy={generateDataCy(slugified)}>
            <DetailsLabel data-cy={`${generateDataCy(slugified)}-label`}>
              {item.label}:
            </DetailsLabel>
            <DetailsText data-cy={`${generateDataCy(slugified)}-text`}>
              {item.value}
            </DetailsText>
          </div>
        )
      })}
    </>
  )
}

export default ContactInfoSection
