import React from 'react'
import styled from 'styled-components'

const StyledRoundDot = styled.div(({ theme }) => ({
  height: theme.space(2),
  width: theme.space(2),
  borderRadius: '48px',
  position: 'relative',
  bottom: theme.space(1),
  right: theme.space(1),
  background: theme.colors.critical,
  border: `2px solid ${theme.colors.critical_15}`,
}))

const RoundDot: React.FC = ({ ...props }) => {
  return <StyledRoundDot {...props} />
}

export default RoundDot
