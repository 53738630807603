import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { LocationBusinessType } from 'src/client/interfaces/Common'
import {
  SmsRegistrationBusinessIndustry,
  SmsRegistrationRepresentativePosition,
  SmsRegistrationSoleProprietorshipBusinessIndustry,
} from 'src/client/interfaces/SmsRegistration'

const isNotSoleProprietorship = (businessType: string) =>
  businessType !== LocationBusinessType.SOLE_PROPRIETORSHIP

const smsRegistrationFormSchema = yup.object({
  businessName: yup.string().required('Missing business name'),
  businessType: yup
    .mixed<LocationBusinessType>()
    .required('Please select a business type'),
  ein: yup
    .string()
    .typeError('Please enter a valid 9-digit EIN')
    .when('businessType', {
      is: isNotSoleProprietorship,
      then: (schema) =>
        schema
          .matches(/^\d{9}$/, 'Please enter a valid 9-digit EIN') // matches exactly nine digits
          .required('Missing EIN'),
      otherwise: (schema) => schema.notRequired(),
    }),
  websiteUrl: yup
    .string()
    .max(100, 'Website URL cannot be longer than 100 characters')
    .when('businessType', {
      is: isNotSoleProprietorship,
      then: (schema) =>
        schema.required('Website URL is required for non-sole proprietors'),
      otherwise: (schema) => schema.optional(),
    }),
  addressLine1: yup.string().required('Missing street'),
  addressLine2: yup.string(),
  city: yup.string().required('Missing city'),
  state: yup
    .string()
    .usState('Invalid state abbreviation')
    .required('Missing state abbreviation'),
  postalCode: yup
    .string()
    .zipCode('Invalid ZIP code')
    .required('Missing ZIP code'),
  representativeFirstName: yup.string().required('First name is required'),
  representativeLastName: yup.string().required('Last name is required'),
  representativeTitle: yup.string().when(['businessType'], {
    is: isNotSoleProprietorship,
    then: (schema) => schema.required('Job title is required'),
    otherwise: (schema) => schema.optional(),
  }),
  representativePosition: yup
    .mixed<SmsRegistrationRepresentativePosition>()
    .when(['businessType'], {
      is: isNotSoleProprietorship,
      then: (schema) => schema.required('Please select a job position'),
      otherwise: (schema) => schema.nullable(),
    }),
  representativePhoneNumber: yup
    .string()
    .phoneNumber()
    .typeError('Please enter a valid phone number')
    .required('Phone number is required'),
  representativeEmail: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  businessIndustry: yup
    .mixed<
      | SmsRegistrationSoleProprietorshipBusinessIndustry
      | SmsRegistrationBusinessIndustry
    >()
    .required('Please select a business industry')
    .when('businessType', {
      is: isNotSoleProprietorship,
      then: (schema) =>
        schema.oneOf(
          Object.values(SmsRegistrationBusinessIndustry),
          'Please select a business industry'
        ),
      otherwise: (schema) =>
        schema.oneOf(
          Object.values(SmsRegistrationSoleProprietorshipBusinessIndustry),
          'Please select a business industry'
        ),
    }),
})

export type SmsRegistrationFormSchema = yup.InferType<
  typeof smsRegistrationFormSchema
>

export const SmsRegistrationFormProvider: React.FCWithChildren = ({
  children,
}) => {
  const formMethods = useForm<SmsRegistrationFormSchema>({
    resolver: yupResolver(smsRegistrationFormSchema),

    mode: 'onChange',
  })

  return <FormProvider {...formMethods}>{children}</FormProvider>
}

const useSmsRegistrationFormContext = () =>
  useFormContext<SmsRegistrationFormSchema>()

export default useSmsRegistrationFormContext
