import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ConversationsResponseDTO } from 'src/client/interfaces/Conversations'
import logger from 'src/utils/logger'

const useSetConversationReadMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    // TODO: replace with actual API call, will be done in another ticket
    async ({
      locationId,
      conversationId,
      isConversationRead,
    }: {
      locationId: number
      conversationId: number
      isConversationRead: boolean
    }) => Promise.resolve({ locationId, conversationId, isConversationRead }),
    {
      onSuccess: (params) => {
        queryClient.setQueriesData<ConversationsResponseDTO>(
          ['conversations'],
          (val) =>
            !val
              ? val
              : {
                  ...val,
                  data: val.data.map((c) => ({
                    ...c,
                    isConversationRead:
                      params.conversationId === c.id
                        ? params.isConversationRead
                        : c.isConversationRead,
                  })),
                }
        )
      },
      onError: (error) => {
        logger.error('WAF useSetConversationRead - error', { error })
      },
    }
  )
}

export default useSetConversationReadMutation
