import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ConversationsResponseDTO } from 'src/client/interfaces/Conversations'
import logger from 'src/utils/logger'

const useSetConversationArchivedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    // TODO: replace with actual API call, will be done in another ticket
    async ({
      locationId,
      conversationId,
      isConversationArchived,
    }: {
      locationId: number
      conversationId: number
      isConversationArchived: boolean
    }) =>
      Promise.resolve({
        locationId,
        conversationId,
        isConversationArchived,
      }),
    {
      onSuccess: (params) => {
        queryClient.setQueriesData<ConversationsResponseDTO>(
          ['conversations'],
          (val) =>
            !val
              ? val
              : {
                  ...val,
                  data: val.data.map((c) => ({
                    ...c,
                    isConversationArchived:
                      params.conversationId === c.id
                        ? params.isConversationArchived
                        : c.isConversationArchived,
                  })),
                }
        )
      },
      onError: (error) => {
        logger.error('WAF useSetConversationArchived - error', { error })
      },
    }
  )
}

export default useSetConversationArchivedMutation
