import { ReactElement, useEffect, useRef, useState } from 'react'
import styled, { DefaultTheme } from 'styled-components'

import { useClickOutside } from 'src/hooks/useClickOutside'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { CloseIcon as TimesIcon } from './assets'
import { Button, ButtonProps } from './Button'

const StyledModalContainer = styled.div(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.colors.base_50_60,
  overflowY: 'auto',
  zIndex: theme.zIndexes.modal,
}))

interface StyledModalLayoutProps {
  $height?: string
  $width?: string
  isOverflowing: boolean
}
const StyledModalLayout = styled.div<StyledModalLayoutProps>(
  ({ theme, isOverflowing, $width, $height }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: !$height ? theme.space(132) : 'auto',
    maxWidth: '90%',
    height: $height,
    width: $width || theme.space(150),
    marginTop: isOverflowing ? theme.space(20) : 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 'auto',
  })
)

interface StyledModalHeaderProps {
  $color: keyof DefaultTheme['colors']
  $reducedPadding: boolean
}

interface StyledModalContainerProps {
  maxSpacedModal?: boolean
  $headerShadow?: boolean
}

const StyledModalHeader = styled.div<StyledModalHeaderProps>(
  ({ $color, $reducedPadding, theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: theme.space(15),
    padding: `${theme.space(5)} ${theme.space($reducedPadding ? 3 : 8)}`,
    borderTopLeftRadius: theme.constants.largeBorderRadius,
    borderTopRightRadius: theme.constants.largeBorderRadius,
    backgroundColor: theme.colors[$color],
  })
)

interface StyledModalTitleProps {
  $titleColor: keyof DefaultTheme['colors']
  $titleFontSize: string
  $titleFontWeight: number
}
const StyledHeaderTitle = styled.div<StyledModalTitleProps>(
  ({ $titleColor, $titleFontSize, $titleFontWeight, theme }) => ({
    margin: 0,
    fontSize: $titleFontSize,
    fontWeight: $titleFontWeight,
    color: theme.colors[$titleColor],
  })
)

interface StyledCloseXIconProps {
  $closeIconColor: keyof DefaultTheme['colors']
}
const StyledCloseXIcon = styled(TimesIcon)<StyledCloseXIconProps>(
  ({ $closeIconColor, theme }) => ({
    marginRight: theme.space(1),
    fill: theme.colors[$closeIconColor],
    cursor: 'pointer',
  })
)

const StyledModalContentContainer = styled.div<StyledModalContainerProps>(
  ({ theme, maxSpacedModal, $headerShadow }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: theme.colors.base_0,
    borderBottomLeftRadius: theme.constants.largeBorderRadius,
    borderBottomRightRadius: theme.constants.largeBorderRadius,
    borderTopLeftRadius: maxSpacedModal ? theme.constants.largeBorderRadius : 0,
    borderTopRightRadius: maxSpacedModal
      ? theme.constants.largeBorderRadius
      : 0,
    boxShadow: $headerShadow
      ? `${theme.space(0)} ${theme.space(6)} ${theme.space(3)} ${theme.space(
          -4
        )} ${theme.colors.base_20} inset`
      : 'unset',
  })
)

interface StyledModalContentProps {
  $reducedPadding?: boolean
}
const StyledModalContent = styled.div<StyledModalContentProps>(
  ({ theme, $reducedPadding }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    padding: theme.space($reducedPadding ? 0 : 8),
    paddingBottom: theme.space($reducedPadding ? 0 : 12),
  })
)

const StyledEmptySpace = styled.div(({ theme }) => ({
  height: theme.space(20),
  width: '100%',
}))

interface StyledActionsWrapperProps {
  size?: 'small' | 'medium' | 'large'
}

const StyledActionsWrapper = styled.div<StyledActionsWrapperProps>(
  ({ theme, size = 'large' }) => ({
    display: 'flex',
    alignSelf: 'start',
    flexDirection: size === 'small' ? 'column' : 'row',
    gap: theme.space(4),
    width: size === 'small' ? '100%' : size === 'medium' ? '50%' : '60%',
  })
)

export interface ModalActionsOptions {
  cancel?: {
    onClick?: () => void
    label: string
    action?: ButtonProps['action']
    shouldDisable?: () => boolean
  }
  callToAction?: {
    hide?: boolean
    onClick?: () => void
    label: string
    action?: ButtonProps['action']
    shouldDisable?: () => boolean
  }
}

export interface ModalProps {
  /**
   * Title of the Modal, present at the top/header of the component
   */
  modalTitle: string
  /**
   * Custom data attribute. Can be used to set data-cy attributes as selectors for tests
   */
  modalDataCy: string
  /**
   * Hides the modal's header for a simplified modal design
   */
  maxSpacedModal?: boolean
  /**
   * If modal can be closed. If false, X icon is hidden
   */
  modalCanClose?: boolean
  /**
   * Close handler of the Modal, coming from the component controlling the modal state
   */
  handleClose: () => void
  /**
   * Color of the modal header, 'primary_1' by default
   */
  headerColor?: keyof DefaultTheme['colors']
  /**
   * Title color of the modal header, 'base_0' by default
   */
  headerTitleColor?: keyof DefaultTheme['colors']
  /**
   * Title font size of the modal header, '1.2rem' by default
   */
  headerTitleFontSize?: string
  /**
   * Title font weight of the modal header, '1.2rem' by default
   */
  headerTitleFontWeight?: number
  /**
   * Shadow below the header. Defaults to false
   */
  headerShadow?: boolean
  /**
   * Reduce the modal padding for modals containing other components
   */
  reducedPadding?: boolean
  /**
   * Overwrites default height of the modal body
   */
  height?: string
  /**
   * Overwrites default width of the modal body
   */
  width?: string
  /**
   * Flag to hide the action buttons when not needed.
   */
  hideActions?: boolean
  /**
   * Actions options
   */
  modalActionsOptions?: ModalActionsOptions
  /**
   * Component to display to the right of the modal content.
   */
  rightComponent?: ReactElement
}

const Modal: React.FCWithChildren<ModalProps> = ({
  modalTitle,
  modalDataCy,
  maxSpacedModal = false,
  modalCanClose = true,
  handleClose,
  headerColor = 'primary_1',
  headerTitleColor = 'base_0',
  headerTitleFontSize = '2rem',
  headerTitleFontWeight = 500,
  reducedPadding = false,
  headerShadow = false,
  height,
  width,
  hideActions,
  modalActionsOptions,
  rightComponent,
  children,
}) => {
  const {
    callToAction = { label: 'Call to action' },
    cancel: cancelAction = { label: 'Cancel' },
  } = modalActionsOptions ?? {}
  const containerRef = useRef<HTMLDivElement | null>(null)
  const modalBodyRef = useRef<HTMLDivElement | null>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const screenSizes = useScreenSizes()

  const onClickOutside = () => {
    if (modalCanClose) {
      handleClose()
    }
  }

  useClickOutside({
    ref: modalBodyRef,
    onClickOutside,
  })

  useEffect(() => {
    if (containerRef.current) {
      setIsOverflowing(
        containerRef.current.scrollHeight > containerRef.current.clientHeight ||
          containerRef.current.scrollWidth > containerRef.current.clientWidth
      )
    }
  }, [containerRef])

  return (
    <>
      <StyledModalContainer ref={containerRef} data-cy={modalDataCy}>
        <StyledModalLayout
          $height={height}
          $width={width}
          data-cy={modalDataCy + '-layout'}
          isOverflowing={isOverflowing}
          ref={modalBodyRef}
        >
          {!maxSpacedModal && (
            <StyledModalHeader
              $color={headerColor}
              data-cy={modalDataCy + '-header'}
              $reducedPadding={reducedPadding}
            >
              <StyledHeaderTitle
                $titleColor={headerTitleColor}
                $titleFontSize={headerTitleFontSize}
                $titleFontWeight={headerTitleFontWeight}
                data-cy={modalDataCy + '-header-title'}
              >
                {modalTitle}
              </StyledHeaderTitle>
              {modalCanClose && (
                <StyledCloseXIcon
                  $closeIconColor={headerTitleColor}
                  onClick={handleClose}
                  data-cy="close-modal"
                />
              )}
            </StyledModalHeader>
          )}

          <StyledModalContentContainer
            data-cy={modalDataCy + '-content-container'}
            maxSpacedModal={maxSpacedModal}
            $headerShadow={headerShadow}
          >
            <StyledModalContent
              $reducedPadding={reducedPadding}
              data-cy={modalDataCy + '-content'}
            >
              {children}
              {!hideActions && (
                <StyledActionsWrapper
                  data-cy={modalDataCy + '-actions'}
                  size={
                    screenSizes.isLargeScreen
                      ? 'large'
                      : screenSizes.isMediumScreen
                      ? 'medium'
                      : 'small'
                  }
                >
                  <Button
                    baseDataAttribute={modalDataCy + '-cancel-action'}
                    onClick={() => {
                      onClickOutside()
                      cancelAction.onClick?.()
                    }}
                    label={cancelAction.label ?? 'Cancel'}
                    action={cancelAction.action ?? 'secondary'}
                    disabled={cancelAction.shouldDisable?.()}
                  />
                  <Button
                    baseDataAttribute={modalDataCy + '-cta-action'}
                    label={callToAction.label ?? 'Call to action'}
                    onClick={callToAction.onClick}
                    action={callToAction.action ?? 'primary'}
                    type="submit"
                    form={modalDataCy}
                    disabled={callToAction.shouldDisable?.()}
                  />
                </StyledActionsWrapper>
              )}
            </StyledModalContent>
            {screenSizes.isLargeScreen && rightComponent}
          </StyledModalContentContainer>

          {isOverflowing && <StyledEmptySpace onClick={onClickOutside} />}
        </StyledModalLayout>
      </StyledModalContainer>
    </>
  )
}

export default Modal
