import React from 'react'
import { Controller } from 'react-hook-form'
import styled, { useTheme } from 'styled-components'

import { LocationBusinessType } from 'src/client/interfaces/Common'
import {
  SmsRegistrationBusinessIndustry,
  SmsRegistrationRepresentativePosition,
  SmsRegistrationSoleProprietorshipBusinessIndustry,
} from 'src/client/interfaces/SmsRegistration'
import { StyledWidgetDivider } from 'src/components/Insights/Widgets/styled'
import SmsRegistrationLayout, {
  StyledCopy,
} from 'src/components/Settings/Business/SmsRegistrationLayout'
import { StyledColumnsContainer } from 'src/components/Settings/Business/styled'
import useSmsRegistrationFormContext from 'src/containers/Settings/Business/SmsRegistration/SmsRegistrationFormContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Button } from 'src/stories/Button'
import { HelperWidget } from 'src/stories/HelperWidget'
import Input from 'src/stories/Input'
import PhoneNumberInput from 'src/stories/PhoneNumberInput'
import SectionContainer from 'src/stories/SectionContainer'
import Select, { SelectOption } from 'src/stories/Select'
import { WizardLayout } from 'src/stories/Wizard'
import { ProTipIcon } from 'src/stories/assets'

export const representativePositionOptions: SelectOption<SmsRegistrationRepresentativePosition>[] =
  [
    {
      value: SmsRegistrationRepresentativePosition.DIRECTOR,
      label: 'Director',
    },
    {
      value: SmsRegistrationRepresentativePosition.GM,
      label: 'GM',
    },
    {
      value: SmsRegistrationRepresentativePosition.VP,
      label: 'VP',
    },
    {
      value: SmsRegistrationRepresentativePosition.CEO,
      label: 'CEO',
    },
    {
      value: SmsRegistrationRepresentativePosition.CFO,
      label: 'CFO',
    },
    {
      value: SmsRegistrationRepresentativePosition.GENERAL_COUNSEL,
      label: 'General Counsel',
    },
    {
      value: SmsRegistrationRepresentativePosition.OTHER,
      label: 'Other',
    },
  ]

export const soleProprietorBusinessIndustryOptions: SelectOption<SmsRegistrationSoleProprietorshipBusinessIndustry>[] =
  [
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.AGRICULTURE,
      label: 'Agriculture',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.COMMUNICATION,
      label: 'Communication',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.CONSTRUCTION,
      label: 'Construction',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.EDUCATION,
      label: 'Education',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.ENERGY,
      label: 'Energy',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.ENTERTAINMENT,
      label: 'Entertainment',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.FINANCIAL,
      label: 'Financial',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.GAMBLING,
      label: 'Gambling',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.GOVERNMENT,
      label: 'Government',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.HEALTHCARE,
      label: 'Healthcare',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.HOSPITALITY,
      label: 'Hospitality',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.HUMAN_RESOURCES,
      label: 'Human resources',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.INSURANCE,
      label: 'Insurance',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.LEGAL,
      label: 'Legal',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.MANUFACTURING,
      label: 'Manufacturing',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.NGO,
      label: 'Nongovernmental organization',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.POLITICAL,
      label: 'Political',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.POSTAL,
      label: 'Postal',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.PROFESSIONAL,
      label: 'Professional',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.REAL_ESTATE,
      label: 'Real estate',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.RETAIL,
      label: 'Retail',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.TECHNOLOGY,
      label: 'Technology',
    },
    {
      value: SmsRegistrationSoleProprietorshipBusinessIndustry.TRANSPORTATION,
      label: 'Transportation',
    },
  ]

export const nonSoleProprietorBusinessIndustryOptions: SelectOption<SmsRegistrationBusinessIndustry>[] =
  [
    {
      value: SmsRegistrationBusinessIndustry.AGRICULTURE,
      label: 'Agriculture',
    },
    { value: SmsRegistrationBusinessIndustry.AUTOMOTIVE, label: 'Automotive' },
    { value: SmsRegistrationBusinessIndustry.BANKING, label: 'Banking' },
    {
      value: SmsRegistrationBusinessIndustry.CONSTRUCTION,
      label: 'Construction',
    },
    { value: SmsRegistrationBusinessIndustry.CONSUMER, label: 'Consumer' },
    { value: SmsRegistrationBusinessIndustry.EDUCATION, label: 'Education' },
    {
      value: SmsRegistrationBusinessIndustry.ELECTRONICS,
      label: 'Electronics',
    },
    { value: SmsRegistrationBusinessIndustry.ENERGY, label: 'Energy' },
    {
      value: SmsRegistrationBusinessIndustry.ENGINEERING,
      label: 'Engineering',
    },
    {
      value: SmsRegistrationBusinessIndustry.FAST_MOVING_CONSUMER_GOODS,
      label: 'Fast moving consumer goods',
    },
    { value: SmsRegistrationBusinessIndustry.FINANCIAL, label: 'Financial' },
    {
      value: SmsRegistrationBusinessIndustry.FINTECH,
      label: 'Financial technology',
    },
    {
      value: SmsRegistrationBusinessIndustry.FOOD_AND_BEVERAGE,
      label: 'Food and beverage',
    },
    { value: SmsRegistrationBusinessIndustry.GOVERNMENT, label: 'Government' },
    { value: SmsRegistrationBusinessIndustry.HEALTHCARE, label: 'Healthcare' },
    {
      value: SmsRegistrationBusinessIndustry.HOSPITALITY,
      label: 'Hospitality',
    },
    { value: SmsRegistrationBusinessIndustry.INSURANCE, label: 'Insurance' },
    { value: SmsRegistrationBusinessIndustry.JEWELRY, label: 'Jewelry' },
    { value: SmsRegistrationBusinessIndustry.LEGAL, label: 'Legal' },
    {
      value: SmsRegistrationBusinessIndustry.MANUFACTURING,
      label: 'Manufacturing',
    },
    { value: SmsRegistrationBusinessIndustry.MEDIA, label: 'Media' },
    {
      value: SmsRegistrationBusinessIndustry.NOT_FOR_PROFIT,
      label: 'Not for profit',
    },
    {
      value: SmsRegistrationBusinessIndustry.OIL_AND_GAS,
      label: 'Oil and gas',
    },
    { value: SmsRegistrationBusinessIndustry.ONLINE, label: 'Online' },
    {
      value: SmsRegistrationBusinessIndustry.PROFESSIONAL_SERVICES,
      label: 'Professional Services',
    },
    {
      value: SmsRegistrationBusinessIndustry.RAW_MATERIALS,
      label: 'Raw materials',
    },
    {
      value: SmsRegistrationBusinessIndustry.REAL_ESTATE,
      label: 'Real estate',
    },
    { value: SmsRegistrationBusinessIndustry.RELIGION, label: 'Religion' },
    { value: SmsRegistrationBusinessIndustry.RETAIL, label: 'Retail' },
    { value: SmsRegistrationBusinessIndustry.TECHNOLOGY, label: 'Technology' },
    {
      value: SmsRegistrationBusinessIndustry.TELECOMMUNICATIONS,
      label: 'Telecommunications',
    },
    {
      value: SmsRegistrationBusinessIndustry.TRANSPORTATION,
      label: 'Transportation',
    },
    { value: SmsRegistrationBusinessIndustry.TRAVEL, label: 'Travel' },
  ]

interface PrimaryContactInfoStepProps {
  nextStep: () => void
  previousStep: () => void
}

const StyledDivider = styled(StyledWidgetDivider)(({ theme }) => ({
  marginBottom: theme.space(6),
}))

const PrimaryContactInfoStep: React.FC<PrimaryContactInfoStepProps> = ({
  nextStep,
  previousStep,
}) => {
  const { register, formState, trigger, control, getValues } =
    useSmsRegistrationFormContext()
  const theme = useTheme()
  const { isMediumScreen } = useScreenSizes()

  const isSoleProprietor =
    getValues('businessType') === LocationBusinessType.SOLE_PROPRIETORSHIP

  const baseInputProps = {
    containerStyle: { marginBottom: theme.space(6) },
    errors: formState.errors,
  }

  return (
    <WizardLayout
      asideContent={
        isSoleProprietor && (
          <HelperWidget
            title="Why do I need to provide my mobile number?"
            icon={ProTipIcon}
            copy="A verification request will be sent to your mobile number upon submitting this registration. Please respond with a “Yes” within 24 hours to complete the process."
          />
        )
      }
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault()

          const isValid = await trigger([
            'representativeFirstName',
            'representativeLastName',
            'representativeTitle',
            'representativePosition',
            'representativeEmail',
            'representativePhoneNumber',
            'businessIndustry',
          ])

          if (isValid) {
            nextStep()
          }
        }}
      >
        <SmsRegistrationLayout
          title="Now enter the primary contact info"
          buttonNext={
            <Button
              label="Continue"
              type="submit"
              baseDataAttribute="sms-registration-contact-info-continue"
              disabled={Object.values(formState.errors).some(Boolean)}
            />
          }
          buttonBack={
            <Button
              action="secondary"
              label="Back"
              baseDataAttribute="sms-registration-contact-info-back"
              onClick={() => {
                previousStep()
              }}
            />
          }
        >
          <StyledCopy>
            Rest assured, the information you provide here is solely for
            registration purposes and will not be visible to your contacts.
          </StyledCopy>
          <SectionContainer
            style={{
              paddingBottom: theme.space(8),
            }}
          >
            <StyledColumnsContainer columns={isMediumScreen ? 2 : 1}>
              <Input
                label="First name:"
                {...register('representativeFirstName')}
                {...baseInputProps}
              />
              <Input
                label="Last name:"
                {...register('representativeLastName')}
                {...baseInputProps}
              />
            </StyledColumnsContainer>
            {!isSoleProprietor && (
              <StyledColumnsContainer columns={isMediumScreen ? 2 : 1}>
                <Input
                  label="Job title:"
                  {...register('representativeTitle')}
                  {...baseInputProps}
                />
                <Controller
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <Select
                        label="Job Position:"
                        placeholder="Select"
                        options={representativePositionOptions}
                        onChange={(value) => {
                          field.onChange(value)
                          field.onBlur()
                        }}
                        errorText={error?.message}
                        initialValue={field.value}
                        containerStyle={{ marginBottom: theme.space(6) }}
                      />
                    )
                  }}
                  name={'representativePosition'}
                />
              </StyledColumnsContainer>
            )}
            <Input
              label="Email:"
              {...register('representativeEmail')}
              {...baseInputProps}
            />
            <Controller
              control={control}
              name="representativePhoneNumber"
              render={({ field, formState: { errors } }) => (
                <PhoneNumberInput
                  label={
                    isSoleProprietor ? 'Mobile phone number:' : 'Phone number:'
                  }
                  helpLabel={
                    isSoleProprietor
                      ? 'A verification text message will be sent to this number to confirm your registration.'
                      : undefined
                  }
                  {...baseInputProps}
                  errors={errors}
                  {...field}
                />
              )}
            />
            <StyledDivider />
            <Controller
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <Select
                    label="Business industry:"
                    helpLabel="Select the closest matching industry that applies to your business."
                    placeholder="Select"
                    options={
                      isSoleProprietor
                        ? soleProprietorBusinessIndustryOptions
                        : nonSoleProprietorBusinessIndustryOptions
                    }
                    onChange={(value) => {
                      field.onChange(value)
                      field.onBlur()
                    }}
                    errorText={error?.message}
                    initialValue={field.value}
                  />
                )
              }}
              name={'businessIndustry'}
            />
          </SectionContainer>
        </SmsRegistrationLayout>
      </form>
    </WizardLayout>
  )
}

export default PrimaryContactInfoStep
