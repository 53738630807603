import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { IntegrationsResource, LocationsResource } from 'src/client'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import Constants from 'src/lib/Constants'
import { ProTipIcon } from 'src/stories/assets'
import { Button } from 'src/stories/Button'
import { HelperWidget } from 'src/stories/HelperWidget'
import Input from 'src/stories/Input'
import { Col, Row } from 'src/stories/Layout'
import { getActiveLocation } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import logger from 'src/utils/logger'

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  marginBottom: theme.space(3),
}))

const StyledCopy = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(6),
}))

const StyledForm = styled.form(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

interface ConnectAngiLeadsForm {
  accountNumber: string
  firstName: string
  lastName: string
  email: string
}

const connectAngiLeadsSchema = yup.object({
  accountNumber: yup
    .string()
    .matches(/^\d+$/, 'Please enter a valid account number')
    .required('Valid account number is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
})

interface ConnectAccountStepProps {
  nextStep: () => void
  previousStep: () => void
}

const ConnectAccountStep: React.FC<ConnectAccountStepProps> = ({
  nextStep,
  previousStep,
}) => {
  const { user } = useAuthContext()
  const { locationId } = useParams<UseLocationRouteParams>()
  const { locations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)

  const {
    formState: { errors: formErrors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<ConnectAngiLeadsForm>({
    reValidateMode: 'onChange',
    defaultValues: {
      email: user.emailAddress || '',
      firstName: user.firstName || '',
      lastName: user.lastName || '',
    },
    resolver: yupResolver(connectAngiLeadsSchema),
  })

  const onSubmit = async ({
    accountNumber,
    email,
    firstName,
    lastName,
  }: ConnectAngiLeadsForm) => {
    try {
      await IntegrationsResource.sendAngiLeadsConnectEmail({
        locationId: activeLocation!.locationId,
        accountNumber,
        companyName: activeLocation!.companyName,
        email,
        firstName,
        lastName,
      })

      await IntegrationsResource.connectIntegration({
        integration: Constants.INTEGRATIONS.HOMEADVISOR,
        locationId: activeLocation!.locationId,
        syncConfig: {
          externalId: accountNumber,
          userId: user.id,
        },
      })

      await LocationsResource.toggleFeatureCode({
        enable: true,
        featureCode: Constants.FEATURE_CODES.syncHomeadvisor,
        locationId: activeLocation!.locationId,
      })

      await LocationsResource.toggleFeatureCode({
        enable: true,
        featureCode: Constants.FEATURE_CODES.sendHomeadvisorAutoresponse,
        locationId: activeLocation!.locationId,
      })

      nextStep()
    } catch (error) {
      logger.debug('Angi Leads Integration Wizard - connect to Angi Leads', {
        error,
      })

      toast.error(
        'There was an error trying to connect to your Angi account. Please try again.'
      )
    }
  }

  return (
    <Row>
      <Col w={2}>
        <StyledTitle>Let's connect your Angi account</StyledTitle>

        <StyledCopy>
          Please enter your account number and the contact information
          associated with your Angi account.
        </StyledCopy>

        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Input
                errors={formErrors}
                maxLength={255}
                label="Account number:"
                {...register('accountNumber')}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                errors={formErrors}
                maxLength={255}
                label="First name:"
                {...register('firstName')}
              />
            </Col>
            <Col>
              <Input
                errors={formErrors}
                maxLength={255}
                label="Last name:"
                {...register('lastName')}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                errors={formErrors}
                maxLength={255}
                label="Email:"
                {...register('email')}
              />
            </Col>
          </Row>

          <Row direction="row-reverse">
            <Col w={2} />
            <Col w={1}>
              <Button
                disabled={isSubmitting}
                label={isSubmitting ? 'Connecting...' : 'Connect'}
                type="submit"
              />
            </Col>
            <Col w={1}>
              <Button
                action="secondary"
                disabled={isSubmitting}
                label="Back"
                onClick={previousStep}
              />
            </Col>
          </Row>
        </StyledForm>
      </Col>

      <Col>
        <HelperWidget
          copy={`You can find your Angi account number by logging in, 
            then opening the Account tab, and going to “Statement.” Your 
            account number is included there with your account overview.`}
          icon={ProTipIcon}
          title="Need help?"
        />
      </Col>
    </Row>
  )
}

export default ConnectAccountStep
