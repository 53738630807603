import { LocationBusinessType } from 'src/client/interfaces/Common'

export enum SmsRegistrationBusinessIndustry {
  AUTOMOTIVE = 'AUTOMOTIVE',
  AGRICULTURE = 'AGRICULTURE',
  BANKING = 'BANKING',
  CONSTRUCTION = 'CONSTRUCTION',
  CONSUMER = 'CONSUMER',
  EDUCATION = 'EDUCATION',
  ENGINEERING = 'ENGINEERING',
  ENERGY = 'ENERGY',
  OIL_AND_GAS = 'OIL_AND_GAS',
  FAST_MOVING_CONSUMER_GOODS = 'FAST_MOVING_CONSUMER_GOODS',
  FINANCIAL = 'FINANCIAL',
  FINTECH = 'FINTECH',
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY = 'HOSPITALITY',
  INSURANCE = 'INSURANCE',
  LEGAL = 'LEGAL',
  MANUFACTURING = 'MANUFACTURING',
  MEDIA = 'MEDIA',
  ONLINE = 'ONLINE',
  PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES',
  RAW_MATERIALS = 'RAW_MATERIALS',
  REAL_ESTATE = 'REAL_ESTATE',
  RELIGION = 'RELIGION',
  RETAIL = 'RETAIL',
  JEWELRY = 'JEWELRY',
  TECHNOLOGY = 'TECHNOLOGY',
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  TRANSPORTATION = 'TRANSPORTATION',
  TRAVEL = 'TRAVEL',
  ELECTRONICS = 'ELECTRONICS',
  NOT_FOR_PROFIT = 'NOT_FOR_PROFIT',
}

export enum SmsRegistrationSoleProprietorshipBusinessIndustry {
  AGRICULTURE = 'AGRICULTURE',
  COMMUNICATION = 'COMMUNICATION',
  CONSTRUCTION = 'CONSTRUCTION',
  EDUCATION = 'EDUCATION',
  ENERGY = 'ENERGY',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FINANCIAL = 'FINANCIAL',
  GAMBLING = 'GAMBLING',
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY = 'HOSPITALITY',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  INSURANCE = 'INSURANCE',
  LEGAL = 'LEGAL',
  MANUFACTURING = 'MANUFACTURING',
  NGO = 'NGO',
  POLITICAL = 'POLITICAL',
  POSTAL = 'POSTAL',
  PROFESSIONAL = 'PROFESSIONAL',
  REAL_ESTATE = 'REAL_ESTATE',
  RETAIL = 'RETAIL',
  TECHNOLOGY = 'TECHNOLOGY',
  TRANSPORTATION = 'TRANSPORTATION',
}

export enum SmsRegistrationRepresentativePosition {
  DIRECTOR = 'DIRECTOR',
  GM = 'GM',
  VP = 'VP',
  CEO = 'CEO',
  CFO = 'CFO',
  GENERAL_COUNSEL = 'GENERAL_COUNSEL',
  OTHER = 'OTHER',
}

export enum SmsRegistrationStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_PHONE_VERIFICATION = 'PENDING_PHONE_VERIFICATION',
  APPROVED = 'APPROVED',
  FAILED = 'FAILED',
}

export interface CreateSmsRegistrationRequest {
  locationId: number
  businessType: LocationBusinessType
  businessIdentifier?: string
  phoneNumber: string
  websiteUrl?: string
  representativeFirstName: string
  representativeLastName: string
  representativeTitle?: string
  representativePosition?: SmsRegistrationRepresentativePosition
  representativePhoneNumber: string
  businessName: string
  businessIndustry:
    | SmsRegistrationBusinessIndustry
    | SmsRegistrationSoleProprietorshipBusinessIndustry
  representativeEmailAddress: string
  addressStreet1: string
  addressStreet2?: string
  addressCity: string
  addressState: string
  addressPostalCode: string
}

export interface SmsRegistrationRequests {
  create: (params: CreateSmsRegistrationRequest) => Promise<void>
}
