import styled from 'styled-components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ActiveConversationMessages,
  HandleTabConversationChangeParams,
  StoreActiveConversationParams,
} from './types'
import { Row } from 'src/stories/Layout'
import Constants from 'src/lib/Constants'
import WafLayout from 'src/components/WafLayout'
import useMhContext from 'src/contexts/MhContext'
import {
  isConversationResource,
  isPublicReview,
} from 'src/contexts/ConversationsListContext/types'
import useAuthContext from 'src/contexts/AuthContext'
import { modifyConversationReadStatus } from './utils'
import MessagesPane from 'src/components/MessagingHub/MessagesPane'
import { EmptyFn, UseLocationRouteParams } from 'src/utils/interfaces'
import ConversationsPane from 'src/components/MessagingHub/ConversationsPane'
import ContactDetailsPane from 'src/components/MessagingHub/ContactDetailsPane'
import { countUnreadConversations } from 'src/contexts/ConversationsListContext/utils'
import ConversationsActionsHeading from 'src/components/MessagingHub/ConversationsPane/ActionsHeading'

const StyledMessagingHub = styled(Row)(({ theme }) => ({
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
  background: theme.colors.base_0,
  overflow: 'hidden',
}))

interface MessagingHubProps {
  isMultiLocation: boolean
}

const MessagingHub: React.FC<MessagingHubProps> = ({ isMultiLocation }) => {
  const authContext = useAuthContext()

  const navigate = useNavigate()

  const { search } = useLocation()
  const { locationId } = useParams<UseLocationRouteParams>()

  const {
    isDesktop,
    isMediumDesktop,
    isSidedrawerOpen,

    // Conversations Pane
    conversationsListIsLoading,
    conversationsList,
    openConversationId,
    isZeroState,
    mutateConversationRead,

    // Messages Pane
    openConversationContactDetails,
    setOpenConversationId,
    setIsOpenConversationChangeInFlight,
  } = useMhContext()

  // Conversations
  const [conversationsTab, setConversationTab] = useState(
    new URLSearchParams(search).get('active-tab') || 'active'
  )

  // Inputs / Message Send
  const [activeConversationMessages, setActiveConversationMessages] =
    useState<ActiveConversationMessages>({})

  const [buttonState, setButtonState] = useState(false)

  const storeActiveConversation = ({
    conversationId,
    currentText,
  }: StoreActiveConversationParams) => {
    setActiveConversationMessages((prevState) => ({
      ...prevState,
      [conversationId!]: currentText,
    }))
  }

  const handleTabConversationChange = useCallback(
    ({
      mode,
      value,
      doNavigate = true,
      preventServerCall = false,
    }: HandleTabConversationChangeParams) => {
      const newUrl = new URLSearchParams(search)
      let newQueryParam: string

      switch (mode) {
        case 'tab':
          newQueryParam = 'active-tab'
          setConversationTab(value)
          break
        case 'conversation':
          newQueryParam = 'open-conversation'
          setIsOpenConversationChangeInFlight(true)
          setOpenConversationId(Number(value))

          if (
            value &&
            authContext.user.roles.some(
              (r) =>
                r === Constants.USER_ROLES.admin ||
                r === Constants.USER_ROLES.merchant
            )
          ) {
            modifyConversationReadStatus(
              Number(value),
              (conversationShouldBeRead, conversationId) =>
                mutateConversationRead({
                  locationId: Number(locationId),
                  conversationId,
                  isConversationRead: conversationShouldBeRead,
                }),
              'read',
              preventServerCall
            )
          }

          break
      }

      if (doNavigate) {
        newUrl.set(newQueryParam, value.toString())
        navigate(`?${newUrl.toString()}`)
      } else {
        newUrl.delete('open-conversation')
        navigate(`?${newUrl.toString()}`)
      }
    },
    [
      search,
      setOpenConversationId,
      authContext.user.roles,
      mutateConversationRead,
      locationId,
      navigate,
      setIsOpenConversationChangeInFlight,
    ]
  )

  const handleSidedrawerState = useCallback(
    (newState: boolean) => {
      const searchParams = new URLSearchParams(search)

      searchParams.set('open-contact-profile', newState.toString())
      setButtonState(newState)
      navigate(`?${searchParams.toString()}`)
    },
    [search, navigate]
  )

  useEffect(() => {
    if (
      conversationsList.length >= 1 &&
      !openConversationId &&
      !conversationsListIsLoading
    ) {
      if (isMediumDesktop) {
        handleTabConversationChange({
          mode: 'conversation',
          value: conversationsList[0].id.toString(),
          doNavigate: !isZeroState && conversationsList[0].id !== -1,
        })
      }
    }
  }, [
    conversationsList,
    openConversationId,
    isZeroState,
    handleTabConversationChange,
    isMediumDesktop,
    conversationsListIsLoading,
  ])

  const openConversationIsPublicReview = useMemo(() => {
    const openConversation = conversationsList.find(
      (c) => c.id === openConversationId
    )

    return openConversation && isPublicReview(openConversation)
  }, [conversationsList, openConversationId])

  return (
    <WafLayout
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
        removePadding: false,
      }}
      heading={{
        titleSlot: (
          <ConversationsActionsHeading
            handleTabConversationChange={handleTabConversationChange}
            isOpenConversation={!!openConversationId}
          />
        ),
        openSidedrawerButtonLabel: 'Contact Profile',
        sidedrawerTitle: openConversationContactDetails.name || '',
        sidedrawerContent: <ContactDetailsPane />,
        openSidedrawerButtonOnClick: () => handleSidedrawerState(true),
        closeSidedrawerButtonOnClick: () => handleSidedrawerState(false),
        isSidedrawerButtonDisabled:
          openConversationIsPublicReview ||
          openConversationId === -1 ||
          buttonState,
      }}
      isSidedrawerOpen={isSidedrawerOpen}
    >
      <StyledMessagingHub
        height={`${isMediumDesktop ? '97%' : '98%'}`}
        data-cy="messaging-hub-container"
      >
        {(isMediumDesktop || (!isMediumDesktop && !openConversationId)) && (
          <ConversationsPane
            unreadConversationsCount={countUnreadConversations(
              conversationsList.filter(isConversationResource)
            )}
            conversationsTab={conversationsTab}
            handleTabConversationChange={handleTabConversationChange}
          />
        )}
        {/* Messages / Active Conversation */}
        {(isDesktop || (!isDesktop && !!openConversationId)) && (
          <MessagesPane
            activeConversations={activeConversationMessages}
            storeActiveConversation={storeActiveConversation}
            hasMoreMessages={false}
            getMore={EmptyFn}
            activeConversation={
              conversationsList.find((c) => c.id === openConversationId)!
            }
            handleTabConversationChange={handleTabConversationChange}
          />
        )}
      </StyledMessagingHub>
    </WafLayout>
  )
}

export default MessagingHub
