import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slider'
import { intervalToDuration } from 'date-fns'
import { PauseIcon, PlayIcon } from './assets'

export interface AudioPlayerProps {
  src?: string
}

const StyledAudioPlayerContainer = styled.div(({ theme }) => ({
  borderRadius: theme.constants.borderRadius,
  border: `2px solid ${theme.colors.primary_2}`,
  width: theme.space(78),
  padding: theme.space(3),
  background: theme.colors.base_5,
  display: 'flex',
  alignItems: 'center',
}))

const StyledSliderContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(2),
}))

const StyledPlayIconContainer = styled.div(({ theme }) => ({
  width: theme.space(4),
  height: theme.space(4),
  cursor: 'pointer',
}))

const StyledTimer = styled.div(({ theme }) => ({
  color: theme.colors.primary_1,
  display: 'block',
  fontSize: '1.4rem',
}))

const StyledSlider = styled(Slider)(({ theme }) => ({
  '&.horizontalSlider': {
    flex: 1,
    height: theme.space(4),
    maxWidth: theme.space(125),
  },

  '& .playerThumb': {
    cursor: 'pointer',
    position: 'absolute',
    zIndex: theme.zIndexes.layout + 1,
    background: theme.colors.primary_2,
    borderRadius: '100%',
    display: 'block',
    boxShadow: '0 0 2px 0 rgb(0 0 0 / 44%)',
  },

  '& .playerThumb.active': {
    backgroundColor: theme.colors.primary_2,
    outline: `2px solid ${theme.colors.primary_2_15}`,
  },

  '& .playerTrack': {
    position: 'relative',
    background: theme.colors.base_0,
    borderRadius: '100px',
    border: `1px solid #D4EAFF`,
    top: 'calc(50% - 5px)',
  },

  '& .playerTrack.playerTrack-0': {
    background: theme.colors.primary_2,
    border: `1px solid ${theme.colors.primary_2}`,
  },

  '&.horizontalSlider .playerTrack': {
    height: theme.space(2),
  },

  '&.horizontalSlider .playerThumb': {
    width: theme.space(3),
    height: theme.space(3),
    // Centering the absolute-positioned thumb
    // 50% of the parent's height - half of the thumb's height - the border width
    top: `calc(50% - ${theme.space(3)} / 2 - 1px)`,
  },
}))

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
  const audioRef = useRef<HTMLAudioElement>(null)

  const [audioPercentage, setAudioPercentage] = useState(0)
  const [currentTimestamp, setCurrentTimestamp] = useState('00:00')
  const [isPlaying, setIsPlaying] = useState(false)

  const disabled = !src

  const handlePlayButton = () => {
    if (disabled) return

    setIsPlaying((currState) => {
      currState ? audioRef.current?.pause() : audioRef.current?.play()

      return !currState
    })
  }

  const handleNavigateAudio = (value?: number) => {
    if (value && audioRef.current) {
      audioRef.current.pause()
      setIsPlaying(false)

      setAudioPercentage(value)

      // What percentage of the total duration is value
      audioRef.current.currentTime = (audioRef.current.duration * value) / 100
    }
  }

  const setTimeUpdater = (audioElement: HTMLAudioElement) => {
    audioElement.addEventListener('timeupdate', (e) => {
      // What percentage of the total duration is the current time
      const currPercentage = Math.floor(
        (audioElement.currentTime * 100) / audioElement.duration
      )

      const durationInterval = intervalToDuration({
        start: 0,
        end: Math.floor(audioElement.currentTime * 1000),
      })
      const minutes = durationInterval.minutes?.toString() || '00'
      const seconds = durationInterval.seconds?.toString() || '00'

      const newTimestamp = `${minutes.length === 1 ? `0${minutes}` : minutes}:${
        seconds.length === 1 ? `0${seconds}` : seconds
      }`

      setCurrentTimestamp(newTimestamp)
      setAudioPercentage(currPercentage)

      if (currPercentage === 100) {
        setIsPlaying(false)
      }
    })
  }

  useEffect(() => {
    const current = audioRef.current

    if (current) {
      current.addEventListener('loadeddata', () => setTimeUpdater(current), {
        once: true,
      })
    }
  }, [])

  return (
    <StyledAudioPlayerContainer>
      <audio ref={audioRef} src={src} />

      <StyledSliderContainer>
        <StyledPlayIconContainer
          onClick={handlePlayButton}
          data-cy="audio-player-play-button"
        >
          {isPlaying ? (
            <PauseIcon
              width={15}
              height={15}
              data-cy="audio-player-pause-icon"
            />
          ) : (
            <PlayIcon width={15} height={15} data-cy="audio-player-play-icon" />
          )}
        </StyledPlayIconContainer>

        <StyledSlider
          className="horizontalSlider"
          thumbClassName="playerThumb"
          trackClassName="playerTrack"
          renderThumb={(props) => <div {...props} />}
          onChange={(value) => handleNavigateAudio(value as number)}
          value={audioPercentage}
          disabled={disabled}
        />
        <StyledTimer>{disabled ? '--:--' : currentTimestamp}</StyledTimer>
      </StyledSliderContainer>
    </StyledAudioPlayerContainer>
  )
}

export default AudioPlayer
