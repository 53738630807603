import React from 'react'
import styled from 'styled-components'
import { createToolbarComponents } from './ToolbarComponents'

const ToolbarContainer = styled.div(({ theme }) => ({
  display: 'grid',
  padding: theme.space(4),
  gap: theme.space(5),
  background: theme.colors.base_0,
  borderBottom: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
}))

const ToolbarOptions = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.space(5),
  justifyContent: 'flex-start',
}))

export interface ToolbarProps {
  search?: string
  onSearch: (searchValue: string) => void
  isDesktop: boolean
}

const StyledInputWrapper = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) =>
    isDesktop
      ? {
          maxWidth: theme.space(56),
          minWidth: theme.space(56),
        }
      : { width: '100%' }
)

const ToolbarComponents = createToolbarComponents()
const baseDataAttribute = 'deleted-contacts-toolbar'

const Toolbar: React.FC<ToolbarProps> = ({ search, onSearch, isDesktop }) => {
  return (
    <ToolbarContainer data-dd={baseDataAttribute}>
      <ToolbarOptions>
        <StyledInputWrapper isDesktop={isDesktop}>
          <ToolbarComponents.SearchInput onSearch={onSearch} search={search} />
        </StyledInputWrapper>
        <ToolbarComponents.BackButton />
      </ToolbarOptions>
    </ToolbarContainer>
  )
}

export default Toolbar
