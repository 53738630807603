import { useCallback, useMemo } from 'react'

import { getCookie, setCookie, removeCookie } from 'src/utils'

interface SessionCookieInfo {
  loginSessionId?: string
  loginRememberMeSessionId?: string
  bearer?: string
}

interface SessionCookies {
  sid: string
  rmsid: string
  bearer: string
}

const useSessionCookies = () => {
  // Storybook has issues compiling just process.env.REACT_APP_ENVIRONMENT!
  // Doing this as a workaround
  const environment = useMemo(() => `${process.env.REACT_APP_ENVIRONMENT!}`, [])
  const sidKey = useMemo(() => `SID-${environment}`, [environment])
  const rmsidKey = useMemo(() => `RMSID-${environment}`, [environment])
  const bearerKey = useMemo(() => `BEARER-${environment}`, [environment])
  const domain = useMemo(() => `.${process.env.REACT_APP_PARENT_DOMAIN!}`, [])
  const cookiesDefaultOptions = useMemo(
    () => ({
      path: '/',
      secure: true,
      domain,
    }),
    [domain]
  )

  const getSessionCookies = useCallback((): SessionCookies => {
    const sid = getCookie(sidKey)
    const rmsid = getCookie(rmsidKey)
    const bearer = getCookie(bearerKey)

    return { sid, rmsid, bearer }
  }, [sidKey, rmsidKey, bearerKey])

  const setSessionCookies = useCallback(
    ({
      loginSessionId,
      loginRememberMeSessionId,
      bearer,
    }: SessionCookieInfo): void => {
      let keys: { key: string; value: string; expires: Date | undefined }[] = []

      if (bearer) {
        keys = [{ key: bearerKey, value: bearer, expires: undefined }]
      } else if (loginSessionId || loginRememberMeSessionId) {
        let rmsidExpires = new Date()

        rmsidExpires.setDate(rmsidExpires.getDate() + 90)

        if (!!loginSessionId)
          keys.push({ key: sidKey, value: loginSessionId, expires: undefined })
        if (!!loginRememberMeSessionId)
          keys.push({
            key: rmsidKey,
            value: loginRememberMeSessionId,
            expires: rmsidExpires,
          })
      }

      keys.forEach(({ key, value, expires }) => {
        setCookie(key, value, {
          ...cookiesDefaultOptions,
          expires: value ? expires : new Date(1),
        })
      })
    },
    [sidKey, rmsidKey, bearerKey, cookiesDefaultOptions]
  )

  const removeSessionCookies = useCallback(
    ({
      sid = false,
      rmsid = false,
      bearer = false,
    }: {
      sid?: boolean
      rmsid?: boolean
      bearer?: boolean
    }) => {
      if (sid) removeCookie(sidKey, cookiesDefaultOptions)
      if (rmsid) removeCookie(rmsidKey, cookiesDefaultOptions)
      if (bearer) removeCookie(bearerKey, cookiesDefaultOptions)
    },
    [rmsidKey, sidKey, bearerKey, cookiesDefaultOptions]
  )

  return { getSessionCookies, setSessionCookies, removeSessionCookies }
}

export default useSessionCookies
