import { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'src/stories/Button'
import Select from 'src/stories/Select'
import { renderFilter } from './FilterComponents'
import { useContactsContext } from 'src/contexts/ContactsContext'
import { CancelRounded } from 'src/stories/assets'

interface FilterProps {
  handleFilter: () => void
}

const FiltersContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.base_5,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.space(2),
  padding: theme.space(4),
  width: '100%',
}))

const DesktopFiltersContainer = styled(FiltersContainer)(({ theme }) => ({
  flexDirection: 'row',
  padding: theme.space(2),
}))

const FilterSelectContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(4),
  flex: 2,
  width: '100%',
}))

const RenderedFilterContainer = styled.div(() => ({
  justifyContent: 'space-evenly',
  flex: 8,
}))

const RenderedFilterDesktopContainer = styled.div(() => ({
  justifyContent: 'flex-start',
  flex: 8,
}))

const FilterButtonContainer = styled.div(() => ({
  flex: 2,
  width: '100%',
}))

const OPTIONS = [
  { label: 'Campaigns', value: 'campaigns' },
  { label: 'Source', value: 'source' },
  { label: 'Status', value: 'status' },
  { label: 'Lists', value: 'lists' },
  { label: 'Lifecycle', value: 'lifecycle' },
]
const baseDataAttribute = 'contacts-toolbar'

const Filter: React.FC<FilterProps> = ({ handleFilter }) => {
  const [selectedFilter, setSelectedFilter] = useState('status')
  const { isFiltering, setIsFiltering } = useContactsContext()

  return (
    <FiltersContainer>
      <FilterSelectContainer>
        {isFiltering && (
          <Button
            label={'Clear filters'}
            onClick={() => {
              setIsFiltering(false)
            }}
            icon={CancelRounded}
            iconStyles={{ height: 20, width: 20 }}
            displayAsText
            action="primary"
            size="large"
            style={{ width: 'fit-content' }}
            baseDataAttribute={`${baseDataAttribute}-clear-filters`}
          />
        )}
        <Select
          options={OPTIONS}
          onChange={(selected: string) => setSelectedFilter(selected)}
        ></Select>
      </FilterSelectContainer>
      <RenderedFilterContainer>
        {renderFilter(selectedFilter)}
      </RenderedFilterContainer>
      <FilterButtonContainer>
        <Button size="large" onClick={handleFilter} label="Filter" />
      </FilterButtonContainer>
    </FiltersContainer>
  )
}

const DesktopFilter: React.FC<FilterProps> = ({ handleFilter }) => {
  const [selectedFilter, setSelectedFilter] = useState('status')

  return (
    <DesktopFiltersContainer>
      <FilterSelectContainer>
        <Select
          options={OPTIONS}
          onChange={(selected: string) => setSelectedFilter(selected)}
        ></Select>
      </FilterSelectContainer>
      <RenderedFilterDesktopContainer>
        {renderFilter(selectedFilter)}
      </RenderedFilterDesktopContainer>
      <FilterButtonContainer>
        <Button size="large" onClick={handleFilter} label="Filter" />
      </FilterButtonContainer>
    </DesktopFiltersContainer>
  )
}

export { Filter, DesktopFilter }
