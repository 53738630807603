import styled from 'styled-components'
import { Col, Row } from 'src/stories/Layout'

const StyledContent = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  marginTop: 0,
  marginBottom: theme.space(12),
}))

const DisconnectGBMConfirmationModal: React.FC = () => {
  return (
    <>
      <Row>
        <Col>
          <StyledContent>
            <strong>Important Notice</strong>
            <br />
            <br />
            Google Business Messages is being discontinued by Google. Once
            disconnected, you will not be able to reconnect this integration.
          </StyledContent>
        </Col>
      </Row>
    </>
  )
}

export default DisconnectGBMConfirmationModal
