import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import logger from 'src/utils/logger'

const StyledNotFoundContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.primary_1,
  margin: `${theme.space(25)} 0 0 0`,
}))

const StyledSubtitle = styled.h3(({ theme }) => ({
  color: theme.colors.base_50,
  marginTop: 0,
  fontWeight: 'normal',
  fontSize: '2rem',
}))

const NotFound: React.FC = () => {
  const { user } = useAuthContext()
  const { locations } = useAccountContext()

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === '/oauth2-redirect') {
      logger.error('WAF oauth2-redirect incorrect navigation or redirection', {
        user: user.id,
        location: (locations && locations[0]?.id) || 'not avaialable',
      })
    }
  }, [pathname, user, locations])

  return (
    <StyledNotFoundContainer>
      <StyledTitle>error: 404</StyledTitle>
      <StyledSubtitle>Whoops, this page doesn&apos;t exist.</StyledSubtitle>
    </StyledNotFoundContainer>
  )
}

export default NotFound
