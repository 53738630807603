import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

interface ContactsContextInterface {
  showFilters: boolean
  setShowFilters: Dispatch<SetStateAction<boolean>>
  isFiltering: boolean
  setIsFiltering: Dispatch<SetStateAction<boolean>>
  handleFilter: () => void
}

export const ContactsContext = createContext<ContactsContextInterface>(
  {} as ContactsContextInterface
)

export const useContactsContext = () => useContext(ContactsContext)

export const ContactsContextProvider: React.FCWithChildren = ({ children }) => {
  const [showFilters, setShowFilters] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)

  const handleFilter = useCallback(() => {
    setShowFilters(false)
    setIsFiltering(true)
  }, [])

  return (
    <ContactsContext.Provider
      value={{
        showFilters,
        isFiltering,
        setShowFilters,
        setIsFiltering,
        handleFilter,
      }}
    >
      {children}
    </ContactsContext.Provider>
  )
}
