import React, { useMemo } from 'react'
import { BannerBigText, BannerGrid, BannerLeftItem } from '../styled'
import {
  CustomerActivityBannerImage,
  CustomerActivityBannerImageItem,
  CustomerActivityBannerSubtitle,
} from './styled'
import { useTheme } from 'styled-components'
import { Button } from 'src/stories/Button'
import { useParams } from 'react-router-dom'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { generateLocationUrl } from 'src/utils'

import ActivitiesImage from 'src/assets/ReviewsBanner/image_zero_state_activities.png'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { WidgetProps } from 'src/components/Insights/Widgets/types'

const RecentCustomerActivityBanner: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const { isLargeScreen, isExtraLargeScreen } = useScreenSizes()

  const { merchantId, locationId } = useParams<UseLocationRouteParams>()

  const customerEmailSettings = useMemo(
    () =>
      generateLocationUrl(
        merchantId!,
        locationId!,
        'settings#section-settings-email'
      ),
    [locationId, merchantId]
  )

  const customerUploadContacts = useMemo(
    () => generateLocationUrl(merchantId!, locationId!, 'upload-contacts'),
    [locationId, merchantId]
  )

  const gridColumns = useMemo(() => {
    if (isLargeScreen) {
      if (isExtraLargeScreen) {
        return undefined
      }

      return '50% 50%'
    }

    return '100%'
  }, [isLargeScreen, isExtraLargeScreen])

  return (
    <BannerGrid $gridColumns={gridColumns} data-cy={baseDataAttribute}>
      <BannerLeftItem data-cy={baseDataAttribute + '-actions'}>
        <BannerBigText>Add contacts to keep customers engaged.</BannerBigText>

        <CustomerActivityBannerSubtitle>
          Start sending texts, review campaigns, promotions and more.
        </CustomerActivityBannerSubtitle>

        <Button
          label="Connect your email"
          href={customerEmailSettings}
          baseDataAttribute={baseDataAttribute + '-connect-your-email'}
          maxWidth={theme.space(60)}
          linkStyle={{
            marginBottom: theme.space(8),
          }}
        />

        <Button
          label="Upload a contact file"
          baseDataAttribute={baseDataAttribute + '-upload-contact-file'}
          outline
          maxWidth={theme.space(60)}
          href={customerUploadContacts}
          style={{
            backgroundColor: theme.colors.base_0,
          }}
        />
      </BannerLeftItem>

      {isLargeScreen && (
        <CustomerActivityBannerImageItem
          data-cy={baseDataAttribute + '-image-container'}
        >
          <CustomerActivityBannerImage
            src={ActivitiesImage}
            data-cy={baseDataAttribute + '-image'}
          />
        </CustomerActivityBannerImageItem>
      )}
    </BannerGrid>
  )
}

export default RecentCustomerActivityBanner
