import styled from 'styled-components'

import { Body } from 'src/stories/typography'

export const BottomSpace = styled.div(({ theme }) => ({
  height: theme.space(3),
}))

export const AutomatedCampaignsPillContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}))

export const CheckboxContainer = styled.div(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const AnchorContainer = styled(Body)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.space(6),
}))

export const PillContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.space(1),
}))
