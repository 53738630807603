import React, { MouseEventHandler, useCallback, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import {
  ActiveConversationMessages,
  HandleTabConversationChangeParams,
  StoreActiveConversationParams,
} from 'src/containers/MessagingHub/types'
import { modifyConversationReadStatus } from 'src/containers/MessagingHub/utils'
import useAuthContext from 'src/contexts/AuthContext'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import useMhContext from 'src/contexts/MhContext'
import { ArrowIcon } from 'src/stories/assets'
import { Button } from 'src/stories/Button'
import { Col } from 'src/stories/Layout'
import logger from 'src/utils/logger'
import { getContactSubscriptionState } from 'src/components/MessagingHub/utils'
import Conversation from './Conversation'
import MessageSend from './MessageSend'
import ZeroState from './ZeroState'
import {
  ConversationListItem,
  isConversationResource,
  isPublicReview,
} from 'src/contexts/ConversationsListContext/types'
import { useParams } from 'react-router-dom'
import { UseLocationRouteParams } from 'src/utils/interfaces'

const StyledConversationsBox = styled(Col)((props) => ({
  minWidth: 0,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

interface StyledConversationsWrapperContainerProps {
  isMediumDesktop: boolean
}

const StyledConversationsWrapperContainer =
  styled.div<StyledConversationsWrapperContainerProps>(
    ({ isMediumDesktop, theme }) => ({
      height: !isMediumDesktop ? `calc(100% - ${theme.space(4)})` : '100%',
      minWidth: 0,
      display: 'flex',
      flexDirection: 'column',
    })
  )

const StyledBackButtonContainer = styled.div(({ theme }) => ({
  padding: `${theme.space(2)} ${theme.space(4)}`,
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.base_10}`,
}))

interface Props {
  activeConversations: ActiveConversationMessages
  hasMoreMessages: boolean
  storeActiveConversation: (params: StoreActiveConversationParams) => void
  getMore: () => void
  activeConversation: ConversationListItem
  handleTabConversationChange: (
    params: HandleTabConversationChangeParams
  ) => void
}

export type CalculateMessageBoxHeight = (
  el: React.KeyboardEvent<HTMLTextAreaElement>,
  attachmentsAreDisplayed: boolean
) => void

const MessagesPane: React.FC<Props> = ({
  activeConversations,
  hasMoreMessages,
  getMore,
  storeActiveConversation,
  activeConversation,
  handleTabConversationChange,
}) => {
  const { user } = useAuthContext()
  const { preventUiUpdates } = useConversationsListContext()
  const {
    loadingConversationMessages,
    isOpenConversationChangeInFlight,
    contentValidColWidth,
    openConversationId,
    openConversationContactDetails,
    isUnreplyable,
    docTypeToReply,
    isFeedbackReviewUnreachable,
    isZeroState,
    handleSendMessage,
    mutateConversationRead,
    mutateConversationArchived,
    isMediumDesktop,
  } = useMhContext()

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const theme = useTheme()

  const [computedTextboxHeight, setComputedTextboxHeight] = useState(
    theme.space(32)
  )

  const { locationId } = useParams<UseLocationRouteParams>()

  // Calculates the height of the text box, as well as the remaining space to be removed from the messages
  // container, by using the textbox current scroll height. It helps keep the relationship between the
  // custom behavior of the textbox and the messages list as the user types into the box
  const calculateMessageBoxHeight: CalculateMessageBoxHeight = (
    el,
    attachmentsAreDisplayed
  ) => {
    const boxAbsoluteHeightWoTextbox = 36

    if (textAreaRef.current) {
      let baseHeight = 0

      if (attachmentsAreDisplayed) baseHeight = 5

      if (el.currentTarget.scrollTop === 0) {
        setComputedTextboxHeight(theme.space(baseHeight + 32))
        textAreaRef.current.style.height = theme.space(0)
        textAreaRef.current.style.overflowY = 'hidden'
      }

      if (
        el.currentTarget.scrollHeight > 30 &&
        el.currentTarget.scrollHeight <= 100
      ) {
        setComputedTextboxHeight(
          theme.space(
            baseHeight +
              boxAbsoluteHeightWoTextbox +
              el.currentTarget.scrollHeight / 4
          )
        )
        textAreaRef.current.style.height = `${el.currentTarget.scrollHeight}px`
        textAreaRef.current.style.overflowY = 'hidden'
      } else if (el.currentTarget.scrollHeight > 100) {
        setComputedTextboxHeight(theme.space(baseHeight + 54))
        textAreaRef.current.style.height = theme.space(25)
        textAreaRef.current.style.overflowY = 'scroll'
      }
    }
  }

  const markConversationAsRead = useCallback(() => {
    if (
      activeConversation &&
      isConversationResource(activeConversation) &&
      !activeConversation?.isConversationRead &&
      activeConversation.id !== -1
    ) {
      modifyConversationReadStatus(
        openConversationId!,
        (conversationShouldBeRead, conversationId) =>
          mutateConversationRead({
            locationId: Number(locationId),
            conversationId,
            isConversationRead: conversationShouldBeRead,
          }),
        'read'
      )
    }
  }, [
    activeConversation,
    locationId,
    mutateConversationRead,
    openConversationId,
  ])

  const displayZeroState = isZeroState || openConversationId === -1
  const displayUnconfirmedReview =
    activeConversation && isPublicReview(activeConversation)

  const goBackToConversationsListOnMobile: MouseEventHandler<
    HTMLButtonElement
  > = () => {
    handleTabConversationChange({
      mode: 'conversation',
      value: '',
      doNavigate: false,
    })
  }

  return !!openConversationId ? (
    <StyledConversationsBox
      w={contentValidColWidth}
      unspaced
      data-cy="messages-pane"
    >
      {!isMediumDesktop && (
        <StyledBackButtonContainer>
          <Button
            label="Back to Conversations"
            displayAsText
            onClick={goBackToConversationsListOnMobile}
            size="medium"
            icon={ArrowIcon}
          />
        </StyledBackButtonContainer>
      )}
      {!!openConversationId && (
        <StyledConversationsWrapperContainer isMediumDesktop={isMediumDesktop}>
          {displayZeroState && (
            <ZeroState
              greeting={user.firstName ? `Hi, ${user.firstName}. ` : ''}
            />
          )}

          {!displayZeroState && (
            <StyledConversationsWrapperContainer
              isMediumDesktop={isMediumDesktop}
            >
              {/* Conversation/Messages list */}
              <Conversation
                textboxHeight={computedTextboxHeight}
                contactName={openConversationContactDetails.name}
                contactPhone={openConversationContactDetails.phoneNumber}
                contactEmail={openConversationContactDetails.email}
                getMore={getMore}
                hasMoreMessages={hasMoreMessages}
                activeConversation={activeConversation}
              />

              {/* Text box, attach and send */}
              <MessageSend
                onAttach={() => logger.debug('Attach')}
                onSend={(message, media, sendAs) => {
                  const { toggleState } = getContactSubscriptionState(
                    openConversationContactDetails
                  )

                  handleSendMessage(message, media, sendAs, !toggleState)

                  if (
                    isConversationResource(activeConversation) &&
                    activeConversation.isConversationArchived
                  )
                    mutateConversationArchived({
                      locationId: Number(locationId),
                      conversationId: openConversationId,
                      isConversationArchived: false,
                    })
                }}
                openConversationId={openConversationId}
                activeConversations={activeConversations}
                storeActiveConversation={storeActiveConversation}
                errorMessage=""
                textAreaRef={textAreaRef}
                calculateHeight={calculateMessageBoxHeight}
                docTypeToReply={docTypeToReply}
                forceDisable={
                  loadingConversationMessages ||
                  isOpenConversationChangeInFlight ||
                  preventUiUpdates ||
                  isUnreplyable ||
                  displayUnconfirmedReview
                }
                disableMessageSendButtons={isFeedbackReviewUnreachable}
                onFocusTextBox={markConversationAsRead}
                onChangeTextBox={markConversationAsRead}
                receiverName={openConversationContactDetails.name}
              />
            </StyledConversationsWrapperContainer>
          )}
        </StyledConversationsWrapperContainer>
      )}
    </StyledConversationsBox>
  ) : null
}

export default MessagesPane
