import { useQuery } from '@tanstack/react-query'
import { formatRelative } from 'date-fns'
import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ConversationsResource } from 'src/client'
import { ConversationEventResourceType } from 'src/client/interfaces/Conversations'
import MessageBubble from 'src/components/MessagingHub/MessagesPane/MessageBubble'
import PhoneCallRecordingPlayer from 'src/components/MessagingHub/MessagesPane/PhoneCallRecordingPlayer'
import { ReceptionistIcon } from 'src/stories/assets'
import { formatPhoneNumber } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import logger from 'src/utils/logger'

const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Section = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const StyledReceptionistIcon = styled(ReceptionistIcon)(({ theme }) => ({
  fill: theme.colors.base_100,
}))

const Label = styled.strong(({ theme }) => ({
  margin: 0,
  color: theme.colors.base_80,
}))

const Text = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.colors.base_80,
  fontWeight: 400,
  display: 'inline',
}))

const Link = styled.a(({ theme }) => ({
  fontWeight: 500,
  color: theme.colors.primary_2,
  margin: 0,
  display: 'inline',
}))

interface Props {
  message: ConversationEventResourceType<'LIVE_RECEPTIONIST'>
  contactName?: string
  contactId: number
  idx: number
}

const useConversationEventDetailsQuery = ({
  contactId,
  fingerprint,
  locationId,
  enabled,
}: {
  contactId: number
  fingerprint: string
  locationId: number
  enabled: boolean
}) => {
  return useQuery({
    queryKey: [
      'conversations/events',
      { locationId, conversationId: contactId, eventFingerprint: fingerprint },
    ] as const,
    queryFn: async ({ queryKey }) =>
      ConversationsResource.findConversationEvent(queryKey[1]),
    onError: (error) => {
      logger.error('Failed to retrieve conversation event details', {
        error,
      })
    },
    staleTime: 120_000,
    enabled,
  })
}

const LiveReceptionistMessageContent: React.FC<Props> = memo(
  ({ message, contactId, contactName, idx }) => {
    const enableLrRecordingCmp = window.localStorage.getItem(
      'SP_HAS_LIVE_RECEPTIONIST_RECORDING'
    )
    const displayFields =
      message.liveReceptionistData?.callSummaryDisplayFields?.map(
        (field, id) => {
          let value = <Text>{field.value}</Text>

          if (field.label === 'Phone') {
            value = (
              <Link href={`tel:${field.value ?? ''}`}>
                {formatPhoneNumber(field.value ?? '')}
              </Link>
            )
          } else if (field.label === 'email') {
            value = (
              <Link href={`mailto:${field.value ?? ''}`}>{field.value}</Link>
            )
          }

          return (
            <div key={id}>
              <Label>{field.label}: </Label>
              {value}
            </div>
          )
        }
      )

    const { locationId } = useParams<UseLocationRouteParams>()

    const { data } = useConversationEventDetailsQuery({
      locationId: +locationId!,
      contactId,
      fingerprint: message.fingerprint!, // using the non null operator because the query will be disabled if no fingerprint exists
      enabled: !!(message.fingerprint && message.liveReceptionistData.s3Path),
    })
    const recordingUrl = data?.liveReceptionistData?.recordingUrl
    const recordingExpDate = data?.liveReceptionistData?.recordingDeletedAt
      ? formatRelative(
          new Date(data?.liveReceptionistData?.recordingDeletedAt),
          new Date()
        )
      : ''

    const timestamp = (
      message.timestamp ? new Date(message.timestamp) : new Date()
    ).toISOString()

    return (
      <Container>
        <Section>
          <MessageBubble
            key={`individual-message-${idx}-live-receptionist`}
            body=""
            date={timestamp}
            contactName={contactName || ''}
            sourceType="incoming"
            icon="Phone"
            isEmptyBackground={false}
            dataCy={`mh-messages-list-message-bubble-lr-${idx}`}
          >
            <Container>
              <Section>
                <Label>
                  <StyledReceptionistIcon /> Live Receptionist Call Summary:
                </Label>
              </Section>
              <Section>{displayFields}</Section>
            </Container>
          </MessageBubble>
        </Section>
        {(recordingUrl || recordingExpDate) && enableLrRecordingCmp && (
          <Section>
            <MessageBubble
              key={`live-receptionist-recording`}
              date={timestamp}
              contactName={''}
              sourceType="incoming"
              isEmptyBackground={false}
              dataCy={`mh-messages-list-message-bubble-lr-recording-${idx}`}
              isSimplified
            >
              <PhoneCallRecordingPlayer
                recordingUrl={recordingUrl}
                downloadEnabled={true}
                recordingDeletedDate={recordingExpDate}
              />
            </MessageBubble>
          </Section>
        )}
      </Container>
    )
  }
)

export default LiveReceptionistMessageContent
