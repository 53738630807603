import ReactGA from 'react-ga4'
import { isBefore } from 'date-fns'
import * as braze from '@braze/web-sdk'
import { useLocation } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import React, { useCallback, useEffect } from 'react'

import {
  getActiveLocation,
  getCookie,
  getLocationInformation,
  isInternalUser,
  isSafari,
} from 'src/utils'
import logger from 'src/utils/logger'
import Constants from 'src/lib/Constants'
import useAuthContext from 'src/contexts/AuthContext'
import useAccountContext from 'src/contexts/AccountContext'
import { LocationLegacy } from 'src/client/interfaces/Common'

const getConnectedIntegrations = (location: LocationLegacy) => {
  return (
    (location.integrations &&
      location.integrations.filter((i) =>
        ['active', 'error'].includes(i.status)
      )) ||
    []
  )
}

const getUserTrackingProperties = (locations: LocationLegacy[]) => {
  if (!locations.length) return {}
  const firstLocation = locations[0]
  const locationProperties = locations.reduce(
    (acc, cur) => {
      let firstActivatedAt = cur.firstActivatedAt

      if (acc.firstActivatedAt) {
        firstActivatedAt = isBefore(
          new Date(acc.firstActivatedAt),
          new Date(cur.firstActivatedAt)
        )
          ? acc.firstActivatedAt
          : cur.firstActivatedAt
      }

      const connectedIntegrationsForLocation = getConnectedIntegrations(
        cur
      ).map((i) => i.integration)

      const connectedIntegrations = Array.from(
        new Set([
          ...acc.connectedIntegrations,
          ...connectedIntegrationsForLocation,
        ])
      )

      const yelpCategories = Array.from(
        new Set([...cur.yelpCategories, ...acc.yelpCategories])
      )

      return {
        ...acc,
        firstActivatedAt,
        connectedIntegrations,
        yelpCategories,
      } as LocationLegacy
    },
    { ...firstLocation, yelpCategories: [], connectedIntegrations: [] }
  )

  return {
    firstActivatedAt: locationProperties.firstActivatedAt,
    totalCoreDataIntegrations: locationProperties.connectedIntegrations.filter(
      (i) => Constants.INTEGRATIONS.CORE_DATA.includes(i)
    ).length,
    connectedIntegrations: locationProperties.connectedIntegrations.join(','),
    yelpCategories: locationProperties.yelpCategories.join(','),
    accountType: firstLocation && firstLocation.accountType,
    nationalGroup: firstLocation && firstLocation.nationalGroup,
    type: Constants.ANALYTICS.UserTypes.MERCHANT,
  }
}

const Vendors: React.FCWithChildren = ({ children }) => {
  const { pathname } = useLocation()
  const { user } = useAuthContext()
  const { locations, pageLocationId } = useAccountContext()
  const activeLocation = getActiveLocation(locations, pageLocationId)

  useEffect(() => {
    if (
      process.env.REACT_APP_BRAZE_ID &&
      !isSafari(window.navigator) &&
      user &&
      locations &&
      locations.length > 0 &&
      locations[0].locationId &&
      !['login', 'logout'].some((path) => pathname.includes(path))
    ) {
      const brazeUser = `${locations[0].locationId}-${user.id}`

      braze.initialize(process.env.REACT_APP_BRAZE_ID, {
        enableLogging: true,
        baseUrl: 'https://sdk.iad-05.braze.com',
        manageServiceWorkerExternally: true,
      })

      braze.changeUser(brazeUser)

      if (braze.isPushSupported() && !braze.isPushBlocked()) {
        document.body.addEventListener(
          'click',
          () => braze.requestPushPermission(),
          { once: true, capture: true }
        )
      }
    }
  }, [user, locations, pathname])

  const initializer = useCallback(() => {
    const shouldDisable = getCookie('e2eTesting') === 'e2eTestsRunning'

    if (
      !shouldDisable &&
      ((user && !isInternalUser(user) && locations) ||
        pathname.includes('login'))
    ) {
      logger.debug('Initializing scripts')

      const userTrackingProperties = getUserTrackingProperties(locations ?? [])

      try {
        const {
          hasTwilioNumber,
          isLiveReceptionistOnly,
          hasSecurusSubscription,
          hasActiveSignpostSubscription,
          isDualClient,
        } = getLocationInformation(activeLocation)

        datadogRum.setUser({
          ...userTrackingProperties,
          locationId: pageLocationId,
          isDualClient,
          userId: user.id,
          merchantId: activeLocation?.merchantId,
          hasTwilioNumber,
          isLiveReceptionistOnly,
          hasSecurusSubscription,
          hasActiveSignpostSubscription,
        })
      } catch (error) {
        logger.error('Error initializing data dog user tracking', { error })
      }
    }
  }, [user, pathname, locations, activeLocation, pageLocationId])

  useEffect(() => {
    void initializer()
  }, [initializer])

  useEffect(() => {
    const getGaUserRole = () => {
      let userRole = ''

      if (user.attachedAgencyIds && user.attachedAgencyIds.length > 0) {
        userRole = 'AGENCY'
      } else if (
        user.attachedMerchantIds &&
        user.attachedMerchantIds.length > 0
      ) {
        userRole = 'MERCHANT'
      } else if (user.roles && user.roles.length > 0) {
        if (user.roles.includes('ROLE_ACCOUNT_MANAGER')) {
          userRole = 'AM'
        }

        if (
          user.roles.includes('ROLE_ADMIN') ||
          user.roles.includes('ROLE_DEV')
        ) {
          userRole = 'ADMIN'
        }
      }

      return userRole
    }

    ReactGA.initialize(
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK_ID as string,
      {
        gtagOptions: {
          userId: user.id,
          dimension1: user.id,
          dimension2: getGaUserRole(),
        },
      }
    )
  }, [user])

  return <>{children}</>
}

export default Vendors
