import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  InstantResponsesResource,
  IntegrationsResource,
  LocationsResource,
} from '../../../client'
import { LocationLegacy } from '../../../client/interfaces/Common'
import Constants from '../../../lib/Constants'
import { isFeatureCodeEnabled } from '../../../utils'
import logger from '../../../utils/logger'
import { getIntegrationStatusData } from './utils'

interface UseIntegrationDataResponse {
  isConnected: boolean
  isEnabled: boolean
  customIR: string | null
  integrationStatus?: string
  integrationUpdatedAt: string | null
}
interface UseHomeAdvisorIntegrationDataParams {
  activeLocation?: LocationLegacy
  userId: number
}

export const useHomeAdvisorIntegrationData = ({
  activeLocation,
  userId,
}: UseHomeAdvisorIntegrationDataParams): UseIntegrationDataResponse => {
  const [isConnected, setIsConnected] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const [customIR, setCustomIR] = useState<string | null>(null)
  const [integrationStatus, setIntegrationStatus] = useState<string>()
  const [integrationUpdatedAt, setIntegrationUpdatedAt] = useState<
    string | null
  >(null)

  useEffect(() => {
    const getHomeAdvisorConnectionStatus = () => {
      const homeadvisorIntegrationFeature = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.syncHomeadvisor,
        activeLocation
      )

      return { status: !!homeadvisorIntegrationFeature }
    }

    const getHomeAdvisorIRData = () => {
      const homeAdvisorAutoresponseFeature = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.sendHomeadvisorAutoresponse,
        activeLocation
      )

      return {
        autoresponse: activeLocation!.homeadvisorAutoresponse,
        enabled: !!homeAdvisorAutoresponseFeature,
      }
    }

    const getHomeAdvisorIntegrationData = () => {
      if (activeLocation) {
        const { status } = getHomeAdvisorConnectionStatus()
        const { autoresponse, enabled } = getHomeAdvisorIRData()
        const { status: intStatus, updatedAt } = getIntegrationStatusData(
          activeLocation,
          'HOMEADVISOR'
        )

        setIntegrationStatus(intStatus)
        setIsEnabled(enabled)
        setCustomIR(autoresponse)
        setIsConnected(status)
        setIntegrationUpdatedAt(updatedAt)
      }
    }

    void getHomeAdvisorIntegrationData()
  }, [activeLocation, userId])

  return {
    isConnected,
    isEnabled,
    customIR,
    integrationStatus,
    integrationUpdatedAt,
  }
}

interface UseFacebookIntegrationDataParams {
  activeLocation?: LocationLegacy
  userId: number
}

interface UseFacebookIntegrationDataResponse
  extends UseIntegrationDataResponse {
  isLoading: boolean
  integrationStatus?: string
}

export const useFacebookIntegrationData = ({
  activeLocation,
  userId,
}: UseFacebookIntegrationDataParams): UseFacebookIntegrationDataResponse => {
  const [isLoading, setIsLoading] = useState(true)
  const [isConnected, setIsConnected] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const [integrationStatus, setIntegrationStatus] = useState<string>()
  const [customIR, setCustomIR] = useState<string | null>(null)
  const [integrationUpdatedAt, setIntegrationUpdatedAt] = useState<
    string | null
  >(null)

  useEffect(() => {
    const getFacebookConnectionStatus = async () => {
      const facebookIntegrationFeature = activeLocation!.features.find(
        (feature) =>
          feature.featureCode === Constants.FEATURE_CODES.facebookIntegration &&
          feature.enabled
      )

      if (!facebookIntegrationFeature) {
        return { status: false }
      }

      let isValid = false

      try {
        const { validForMessaging } =
          await IntegrationsResource.checkFacebookPageToken({
            locationId: activeLocation!.locationId,
            userId,
          })

        isValid = validForMessaging
      } catch (error) {
        toast.error('Could not get Facebook connection status')

        logger.debug('Error getFacebookConnectionStatus', { error })
      }

      return { status: isValid }
    }

    const getFacebookIRData = () => {
      const sendFacebookAutoresponseFeature = activeLocation!.features.find(
        (feature) =>
          feature.featureCode ===
            Constants.FEATURE_CODES.sendFacebookAutoresponse && feature.enabled
      )

      return {
        autoresponse: activeLocation!.facebookAutoresponse,
        enabled: !!sendFacebookAutoresponseFeature,
      }
    }

    const getFacebookIntegrationData = async () => {
      if (activeLocation) {
        const { status } = await getFacebookConnectionStatus()
        const { autoresponse, enabled } = getFacebookIRData()

        const { status: intStatus, updatedAt } = getIntegrationStatusData(
          activeLocation,
          'FACEBOOK'
        )

        setIntegrationStatus(intStatus)
        setIsEnabled(enabled)
        setCustomIR(autoresponse)
        setIsConnected(status)
        setIsLoading(false)
        setIntegrationUpdatedAt(updatedAt)
      }
    }

    void getFacebookIntegrationData()
  }, [activeLocation, userId])

  return {
    isLoading,
    isConnected,
    isEnabled,
    customIR,
    integrationStatus,
    integrationUpdatedAt,
  }
}

interface UseGoogleMessagesIntegrationDataParams {
  activeLocation?: LocationLegacy
}

interface UseGoogleMessagesIntegrationDataResponse
  extends UseIntegrationDataResponse {
  canConnect: boolean
  integrationStatus?: string
}

export const useGoogleMessagesIntegrationData = ({
  activeLocation,
}: UseGoogleMessagesIntegrationDataParams): UseGoogleMessagesIntegrationDataResponse => {
  const [canConnect, setCanConnect] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const [customIR, setCustomIR] = useState<string | null>(null)
  const [integrationStatus, setIntegrationStatus] = useState<string>()
  const [integrationUpdatedAt, setIntegrationUpdatedAt] = useState<
    string | null
  >(null)

  useEffect(() => {
    const getGoogleMessagesFeatureAvailability = () => {
      const googleMessagesFeatureAvailable = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.canSyncGoogleMessages,
        activeLocation
      )

      return { availability: !!googleMessagesFeatureAvailable }
    }

    const getGoogleMessagesConnectionStatus = () => {
      const googleMessagesIntegrationFeature = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.syncGoogleMessages,
        activeLocation
      )

      return { status: !!googleMessagesIntegrationFeature }
    }

    const getGoogleMessagesIRData = () => {
      const googleMessagesAutoresponseFeature = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.sendGoogleMessagesAutoresponse,
        activeLocation
      )

      return {
        autoresponse: activeLocation!.googleMessagesAutoresponse,
        enabled: !!googleMessagesAutoresponseFeature,
      }
    }

    const getGoogleMessagesIntegrationData = () => {
      if (activeLocation) {
        const { availability } = getGoogleMessagesFeatureAvailability()
        const { status } = getGoogleMessagesConnectionStatus()
        const { autoresponse, enabled } = getGoogleMessagesIRData()
        const { status: intStatus, updatedAt } = getIntegrationStatusData(
          activeLocation,
          'GOOGLE_MESSAGES'
        )

        setIntegrationStatus(intStatus)
        setCanConnect(availability)
        setIsEnabled(enabled)
        setCustomIR(autoresponse)
        setIsConnected(status)
        setIntegrationUpdatedAt(updatedAt)
      }
    }

    void getGoogleMessagesIntegrationData()
  }, [activeLocation])

  return {
    canConnect,
    isConnected,
    isEnabled,
    customIR,
    integrationStatus,
    integrationUpdatedAt,
  }
}

interface UseMissedCallAcknowledgmentDataResponse {
  isEnabled: boolean
  customIR: string | null
}

interface UseMCAIntegrationDataParams {
  activeLocation?: LocationLegacy
}

export const useMissedCallAcknowledgmentData = ({
  activeLocation,
}: UseMCAIntegrationDataParams): UseMissedCallAcknowledgmentDataResponse => {
  const [customIR, setCustomIR] = useState<string | null>(null)
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    const getMCAIRData = () => {
      const missedCallAutoresponseFeature = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.missedCallAutoresponse,
        activeLocation
      )

      return {
        autoresponse: activeLocation!.missedCallAutoresponse,
        enabled: !!missedCallAutoresponseFeature,
      }
    }

    const getMCAIntegrationData = () => {
      if (activeLocation) {
        const { autoresponse, enabled } = getMCAIRData()

        setIsEnabled(enabled)
        setCustomIR(autoresponse)
      }
    }

    void getMCAIntegrationData()
  }, [activeLocation])

  return {
    isEnabled,
    customIR,
  }
}

interface UseCustomVoiceResponderDataResponse {
  isEnabled: boolean
  audioURL: string | null
}

interface UseCustomVoiceResponderData {
  activeLocation?: LocationLegacy
}

export const useCustomVoiceResponderData = ({
  activeLocation,
}: UseCustomVoiceResponderData): UseCustomVoiceResponderDataResponse => {
  const [audioURL, setAudioURL] = useState<string | null>(null)
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    if (activeLocation) {
      const audioResponderFeature = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.enableVoiceMail,
        activeLocation
      )

      const greetingURL = activeLocation.greetingUrl
      const enabled = !!audioResponderFeature

      setIsEnabled(enabled)
      setAudioURL(greetingURL)
    }
  }, [activeLocation])

  return {
    isEnabled,
    audioURL,
  }
}

interface UseInstantResponderParams {
  customIR: string | null
  isEnabled: boolean
  locationId: number | undefined
  refetchLocationFn: () => Promise<void>
  source:
    | 'facebook'
    | 'missedCall'
    | 'homeadvisor'
    | 'thumbtack'
    | 'googleMessages'
    | 'sms'
}

export const useInstantResponder = ({
  customIR,
  isEnabled,
  locationId,
  refetchLocationFn,
  source,
}: UseInstantResponderParams) => {
  const [customIRMessage, setCustomIRMessage] = useState(customIR)
  const [isToggleEnabled, setIsToggleEnabled] = useState(isEnabled)

  useEffect(() => {
    setCustomIRMessage(customIR)
  }, [customIR])

  useEffect(() => {
    setIsToggleEnabled(isEnabled)
  }, [isEnabled])

  const onToggle = useCallback(async () => {
    if (!locationId) {
      logger.error('locationId is undefined - useInstantResponseToggle hook')

      return
    }

    const { featureCode } = Constants.INSTANT_RESPONSES[source]

    setIsToggleEnabled(!isToggleEnabled)

    try {
      await LocationsResource.toggleFeatureCode({
        enable: !isToggleEnabled,
        featureCode,
        locationId,
      })

      await refetchLocationFn()
    } catch (error) {
      toast.error(
        `Instant response not ${
          !isToggleEnabled ? 'enabled' : 'disabled'
        }, something went wrong. Please try again.`
      )
      logger.debug(`Fail on useInstantResponseToggle - ${featureCode} - '`, {
        error,
      })
    }
  }, [isToggleEnabled, locationId, refetchLocationFn, source])

  const onUpdateMessage = useCallback(
    async (message: string | null) => {
      if (!locationId) {
        logger.error(
          'locationId is undefined - useUpdateInstantResponseMessage hook'
        )

        return
      }

      // If the message is null, it will set the default message value.
      const newIrMessage = message || null
      const { deliveryMethod, type } = Constants.INSTANT_RESPONSES[source]

      setCustomIRMessage(newIrMessage)

      try {
        if (
          localStorage.getItem('SP_ENABLE_THUMBTACK_METHOD') &&
          type === 'THUMBTACK'
        ) {
          await Promise.allSettled(
            ['sms', 'thumbtack'].map(async (dm: string) => {
              await InstantResponsesResource.updateInstantResponse({
                deliveryMethod: dm,
                locationId,
                message: newIrMessage,
                type,
              })
            })
          )
        } else {
          await InstantResponsesResource.updateInstantResponse({
            deliveryMethod,
            locationId,
            message: newIrMessage,
            type,
          })
        }

        await refetchLocationFn()

        toast.success(
          !!newIrMessage ? 'Message saved' : 'Default message restored'
        )
      } catch (error) {
        toast.error(
          'Instant response not updated, something went wrong. Please try again.'
        )
        logger.debug(`Error on useUpdateInstantResponseMessage - ${source} -`, {
          error,
        })
      }
    },
    [locationId, refetchLocationFn, source]
  )

  return {
    customIRMessage,
    isToggleEnabled,
    onToggle,
    onUpdateMessage,
  }
}

interface UseCustomVoiceResponderParams {
  audioURL: string | null
  isEnabled: boolean
  locationId: number | undefined
  refetchLocationFn: () => Promise<void>
}

export const useCustomVoiceResponder = ({
  audioURL,
  isEnabled,
  locationId,
  refetchLocationFn,
}: UseCustomVoiceResponderParams) => {
  const [audioResponseURL, setAudioResponseURL] = useState(audioURL)
  const [isToggleEnabled, setIsToggleEnabled] = useState(isEnabled)

  useEffect(() => {
    setAudioResponseURL(audioURL)
  }, [audioURL])

  useEffect(() => {
    setIsToggleEnabled(isEnabled)
  }, [isEnabled])

  const onToggle = useCallback(async () => {
    if (!locationId) {
      logger.error('locationId is undefined - useCustomVoiceResponder hook')

      return
    }

    const featureCode = Constants.FEATURE_CODES.enableVoiceMail

    setIsToggleEnabled(!isToggleEnabled)

    try {
      await LocationsResource.toggleFeatureCode({
        enable: !isToggleEnabled,
        featureCode,
        locationId,
      })

      await refetchLocationFn()
    } catch (error) {
      toast.error(
        `Voice Response not ${
          !isToggleEnabled ? 'enabled' : 'disabled'
        }, something went wrong. Please try again.`
      )
      logger.debug(`Fail on useCustomVoiceResponder - ${featureCode} - '`, {
        error,
      })
    }
  }, [isToggleEnabled, locationId, refetchLocationFn])

  const saveAudio = useCallback(
    async (audioBlob: Blob) => {
      if (!locationId) {
        logger.error('locationId is undefined - useCustomVoiceResponder hook')

        return
      }

      try {
        const { url: returnedURL } =
          await InstantResponsesResource.persistVoiceResponse({
            locationId,
            voiceResponse: audioBlob,
          })

        setAudioResponseURL(returnedURL)

        await refetchLocationFn()

        toast.success('Voice Response saved')
      } catch (error) {
        toast.error(
          'Custom voice response not updated, something went wrong. Please try again.'
        )
        logger.debug(`Error on useCustomVoiceResponder onSaveAudioURL`, {
          error,
        })
      }
    },
    [locationId, refetchLocationFn]
  )

  const deleteAudio = useCallback(async () => {
    if (!locationId) {
      logger.error('locationId is undefined - useCustomVoiceResponder hook')

      return
    }

    try {
      await InstantResponsesResource.deleteVoiceResponse({
        locationId,
      })

      setAudioResponseURL(null)

      await refetchLocationFn()

      toast.success('Voice Response deleted')
    } catch (error) {
      toast.error(
        'Custom voice response not deleted, something went wrong. Please try again.'
      )
      logger.debug(`Error on useCustomVoiceResponder deleteAudioUrl`, {
        error,
      })
    }
  }, [locationId, refetchLocationFn])

  return {
    isToggleEnabled,
    onToggle,
    audioResponseURL,
    saveAudio,
    deleteAudio,
  }
}

type UseThumbtackDataResponse = UseIntegrationDataResponse

interface UseThumbtackIntegrationDataParams {
  activeLocation?: LocationLegacy
}

export const useThumbtackIntegrationData = ({
  activeLocation,
}: UseThumbtackIntegrationDataParams): UseThumbtackDataResponse => {
  const [isConnected, setIsConnected] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const [customIR, setCustomIR] = useState<string | null>(null)
  const [integrationStatus, setIntegrationStatus] = useState<string>()

  const [integrationUpdatedAt, setIntegrationUpdatedAt] = useState<
    string | null
  >(null)

  useEffect(() => {
    const getTTData = () => {
      const thumbtackAutoresponseFeature = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.sendThumbtackAutoresponse,
        activeLocation
      )

      return {
        autoresponse: activeLocation!.thumbtackAutoresponse,
        enabled: !!thumbtackAutoresponseFeature,
      }
    }

    const getThumbtackConnectionStatus = () => {
      const thumbtackFeature = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.syncThumbtack,
        activeLocation
      )

      return { status: !!thumbtackFeature }
    }

    const getThumbtackIntegrationData = () => {
      if (activeLocation) {
        const { autoresponse, enabled } = getTTData()
        const { status } = getThumbtackConnectionStatus()
        const { status: intStatus, updatedAt } = getIntegrationStatusData(
          activeLocation,
          'THUMBTACK'
        )

        setIntegrationStatus(intStatus)
        setIsConnected(status)
        setIsEnabled(enabled)
        setCustomIR(autoresponse)
        setIntegrationUpdatedAt(updatedAt)
      }
    }

    void getThumbtackIntegrationData()
  }, [activeLocation])

  return {
    isConnected,
    isEnabled,
    customIR,
    integrationStatus,
    integrationUpdatedAt,
  }
}

interface UseSMSIntegrationDataParams {
  activeLocation?: LocationLegacy
}

export const useSMSIntegrationData = ({
  activeLocation,
}: UseSMSIntegrationDataParams): UseMissedCallAcknowledgmentDataResponse => {
  const [customIR, setCustomIR] = useState<string | null>(null)
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    const getSMSIRData = () => {
      const smsAutoresponseFeature = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.sendSMSAutoresponse,
        activeLocation
      )

      return {
        autoresponse: activeLocation!.smsAutoresponse,
        enabled: !!smsAutoresponseFeature,
      }
    }

    const getSMSIntegrationData = () => {
      if (activeLocation) {
        const { autoresponse, enabled } = getSMSIRData()

        setIsEnabled(enabled)
        setCustomIR(autoresponse)
      }
    }

    void getSMSIntegrationData()
  }, [activeLocation])

  return {
    isEnabled,
    customIR,
  }
}
