import { formatDuration, intervalToDuration } from 'date-fns'

/**
 * Method to format seconds to a duration
 * @param duration in seconds
 * @returns a formatted duration that only includes hours, minutes and seconds; bigger units (e.g. days) will be ignored
 */
export const formatDurationFromSeconds = (duration: number) =>
  formatDuration(intervalToDuration({ start: 0, end: duration * 1000 }), {
    zero: false,
    format: ['hours', 'minutes', 'seconds'],
  })

/**
 * Method to format the address, everything separated by comma
 * @param street1
 * @param street2
 * @param city
 * @param state
 * @param zip
 * @returns the comma separated address, omitting empty fields.
 */
export const formatAddress = (
  street1: string,
  street2: string,
  city: string,
  state: string,
  zip?: string
) => [street1, street2, city, state, zip].filter(Boolean).join(', ')

// This implementation to format numbers is slightly different than
// what other components (e.g.glaze) do.
// https://github.com/signpost/essentials/blob/master/Formatters.js#L25-L39
// We may want in the future also support numbers from other countries.
/**
 * Method to format a phone number
 * @param phoneNumber
 * @returns the formatted phone number like `(xxx) xxx-xxxx`
 *
 * **Disclaimer:**
 * This implementation to format numbers is slightly different than
 * what other components (e.g.glaze) do.
 * https://github.com/signpost/essentials/blob/master/Formatters.js#L25-L39
 * We may want in the future also support numbers from other countries.
 */
export const formatPhoneNumber = (phoneNumber: string | number) =>
  phoneNumber
    .toString()
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')

/**
 * Method to reverse a phone formatting
 * @param phoneNumber
 * @returns the number only version of a formatted number
 */
export const unFormatPhoneNumber = (phoneNumber: string) =>
  phoneNumber
    .match(/\(([^)]+)\) (\d{3})-(\d{1,4})/i)
    ?.slice(1, 4)
    .join('')
