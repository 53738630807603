import styled from 'styled-components'

export const FooterContainer = styled.div(({ theme }) => ({
  padding: `${theme.space(2)} ${theme.space(3)}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap-reverse',
  gap: theme.space(2),
  background: theme.colors.base_5,
  borderTop: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
}))

export const HeaderContentContainer = styled.div<{ clickable: boolean }>(
  ({ clickable }) => ({
    cursor: clickable ? 'pointer' : 'default',
  })
)

export const TableContainer = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
}))

export const StyledAnchor = styled.a(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  color: theme.colors.base_60,
  cursor: 'pointer',
  textWrap: 'nowrap',
}))

export const StyledZeroStateTextContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}))

export const TableWrapper = styled.div({
  width: '100%',
  overflowX: 'auto',
})
