import { useQuery } from '@tanstack/react-query'
import { ConversationsResource } from 'src/client'
import logger from 'src/utils/logger'

const useConversationEventsQuery = ({
  locationId,
  contactId,
  enabled,
}: {
  locationId: number
  contactId?: number
  enabled?: boolean
}) => {
  return useQuery({
    queryKey: [
      'conversationEvents',
      {
        locationId,

        // This will never actually be zero, doing this just to avoid having to use the non-null assertion operator
        contactId: contactId ?? 0,
      },
    ] as const,
    queryFn: async ({ queryKey }) =>
      ConversationsResource.findConversationEvents(queryKey[1]),
    onError: (error) => {
      logger.error('WAF useConversationEventsQuery - error', { error })
    },
    enabled,
    staleTime: 60_000,
  })
}

export default useConversationEventsQuery
