import { useLocation } from 'react-router-dom'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { isInternalUser } from 'src/utils'
import logger from 'src/utils/logger'
import useAuthContext from 'src/contexts/AuthContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { LocationsResource, UsersResource } from 'src/client'
import { LocationLegacy, UserInterface } from 'src/client/interfaces/Common'

interface AccountContextProps {
  user: UserInterface
  pageUser: UserInterface
  pageLocationId: string
  locations: LocationLegacy[]
  handleRefetchUserLocations: () => Promise<void>
}

export const AccountContext = React.createContext<AccountContextProps>(
  {} as AccountContextProps
)

export const AccountContextProvider: React.FCWithChildren = ({ children }) => {
  const { pathname } = useLocation()

  const pageUserId = (/\/user\/(\d+)/.exec(pathname) || [])[1]
  const pageLocationId = (/\/\d+\/locations\/(\d+)/.exec(pathname) || [])[1]

  const { user } = useAuthContext()
  const [loading, setIsLoading] = useState(true)
  const [pageUser, setPageUser] = useState<UserInterface>()
  const [locations, setLocations] = useState<LocationLegacy[]>()

  const fetchUserLocations = useCallback(
    async (authorizedUser?: UserInterface, forceRefresh?: boolean) => {
      if (!authorizedUser || (!!locations && !forceRefresh)) {
        return
      }

      let userLocations: LocationLegacy[] = []

      if (isInternalUser(authorizedUser)) {
        let pageLocationIds: Array<number> = []

        if (pageUserId) {
          const _pageUser = await UsersResource.get({
            userId: Number(pageUserId),
          })

          setPageUser(_pageUser)

          pageLocationIds = _pageUser.attachedLocationIds || []
        } else if (pageLocationId) {
          pageLocationIds = [Number(pageLocationId)]
          setPageUser(authorizedUser)
        }

        userLocations = (
          await LocationsResource.get({
            locationIds: pageLocationIds,
          })
        ).docs
      } else {
        setPageUser(authorizedUser)

        userLocations = (
          await LocationsResource.get({
            locationIds: authorizedUser.attachedLocationIds,
          })
        ).docs
      }

      setLocations(userLocations)

      setIsLoading(false)

      logger.debug('fetchUserLocations', { userLocations })
    },
    [pageLocationId, pageUserId, locations]
  )

  const handleRefetchUserLocations = async () => {
    await fetchUserLocations(user, true)
  }

  useEffect(() => {
    if (!user) {
      setPageUser(undefined)
      setLocations(undefined)
      setIsLoading(false)
    } else {
      void fetchUserLocations(user)
    }
  }, [user, fetchUserLocations])

  if (
    !isInternalUser(user) &&
    (!locations || locations.length === 0 || !pageUser)
  )
    return null

  const contextValue: AccountContextProps = {
    user,
    pageUser: pageUser ?? user,
    pageLocationId,
    locations: locations ?? [],
    handleRefetchUserLocations,
  }

  return (
    <AccountContext.Provider value={contextValue}>
      {!loading ? children : <LoadingSpinner />}
    </AccountContext.Provider>
  )
}

const useAccountContext = () => useContext(AccountContext)

export default useAccountContext
