import React from 'react'
import { SmsRegistrationStatus } from 'src/client/interfaces/SmsRegistration'
import styled, { DefaultTheme, useTheme } from 'styled-components'

const statusColors: Record<
  SmsRegistrationStatus,
  keyof DefaultTheme['colors']
> = {
  [SmsRegistrationStatus.NOT_STARTED]: 'base_0',
  [SmsRegistrationStatus.IN_PROGRESS]: 'warning',
  [SmsRegistrationStatus.PENDING_PHONE_VERIFICATION]: 'warning',
  [SmsRegistrationStatus.APPROVED]: 'positive',
  [SmsRegistrationStatus.FAILED]: 'critical',
}

const statusTexts: Record<SmsRegistrationStatus, string> = {
  [SmsRegistrationStatus.NOT_STARTED]: '',
  [SmsRegistrationStatus.IN_PROGRESS]: 'Pending',
  [SmsRegistrationStatus.PENDING_PHONE_VERIFICATION]: 'Pending',
  [SmsRegistrationStatus.APPROVED]: 'Approved',
  [SmsRegistrationStatus.FAILED]: 'Not approved',
}

interface StatusIndicatorProps {
  status: SmsRegistrationStatus
}

const StyledSpan = styled.div<{ $color: string }>(({ theme, $color }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '1rem',
  color: $color || 'currentcolor',
}))

const StyledRoundDot = styled.div(({ theme }) => ({
  height: '0.5em',
  width: '0.5em',
  borderRadius: '50%',
  position: 'relative',
  backgroundColor: 'currentcolor',
  boxShadow: '0 0 3px 0 currentcolor',
  marginRight: theme.space(2),
  marginLeft: theme.space(4),
}))

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => {
  const theme = useTheme()
  const currentColor = theme.colors[statusColors[status]]

  if (status === SmsRegistrationStatus.NOT_STARTED) {
    return <></>
  }

  return (
    <StyledSpan $color={currentColor}>
      <StyledRoundDot />
      {statusTexts[status]}
    </StyledSpan>
  )
}

export default StatusIndicator
