import { minutesToHours, secondsToMinutes } from 'date-fns'
import { InsightsWidget } from './InsightsWidget'
import styled, { useTheme } from 'styled-components'
import useInsightsContext from 'src/contexts/InsightsContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { WidgetProps } from './types'

const StyledWidgetLargeStat = styled.h1(({ theme }) => ({
  margin: 0,
  fontSize: '6.4rem',
  lineHeight: '6.4rem',
  fontWeight: 500,
  color: theme.colors.primary_1,
}))

const StyledWidgetTerinaryStat = styled.h1(({ theme }) => ({
  margin: 0,
  paddingTop: theme.space(2),
  fontSize: '3.2rem',
  fontWeight: 500,
  lineHeight: '3.75rem',
  color: theme.colors.positive,
}))

const StyledWidgetBlackBoldText = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.colors.base_100,
  fontSize: '1.4rem',
  fontWeight: 500,
  lineHeight: '2rem',
}))

const StyledWidgetBlackBoldSmallerText = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.colors.base_100,
  fontSize: '1.2rem',
  fontWeight: 500,
  lineHeight: '1.6rem',
}))

const StyledWidgetGrayText = styled.span(({ theme }) => ({
  marginLeft: theme.space(2.5),
  color: theme.colors.base_60,
}))

const WidgetInfoBubble = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: 0,
  backgroundColor: `${theme.colors.primary_2_15}`,
  color: theme.colors.base_100,
  fontSize: '1.4rem',
  fontWeight: 500,
  flexGrow: 1,
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: '6px',
  height: theme.space(24.5),
}))

const WidgetSpamCountArea = styled.div(({ theme }) => ({
  paddingLeft: theme.space(3),
}))

const WidgetInfoText = styled.p(({ theme }) => ({
  fontSize: '1.2rem',
  lineHeight: '1.6rem',
  fontWeight: 400,
  paddingLeft: theme.space(3.5),
}))

const WidgetInfoTextBold = styled.span(({ theme }) => ({
  fontWeight: 700,
}))

const LRCallDetailsWidget: React.FC<WidgetProps> = ({ baseDataAttribute }) => {
  const { liveReceptionistReportingData, isLiveReceptionistLoading } =
    useInsightsContext()

  const theme = useTheme()

  if (isLiveReceptionistLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  const {
    estimatedMinutesSavedFromSolicitorCalls,
    avgDurationSeconds,
    numOfCalls,
    numOfSolicitorCalls,
  } = liveReceptionistReportingData

  const hoursSave = minutesToHours(estimatedMinutesSavedFromSolicitorCalls)
  const minSave = estimatedMinutesSavedFromSolicitorCalls % 60
  const timeSaved = `${hoursSave}h ${minSave}min`

  const minDur = secondsToMinutes(avgDurationSeconds)
  const secDur = avgDurationSeconds % 60
  const callDuration = `${minDur}m ${secDur}s`

  return (
    <InsightsWidget
      addDivider
      title="Call Details"
      subtitle={
        <div>
          <StyledWidgetLargeStat
            data-cy={baseDataAttribute + '-calls-answered'}
          >
            {numOfCalls}
          </StyledWidgetLargeStat>
          <StyledWidgetBlackBoldText>
            Calls Answered
            <StyledWidgetGrayText>
              (Avg duration: {callDuration})
            </StyledWidgetGrayText>
          </StyledWidgetBlackBoldText>
        </div>
      }
      baseDataAttribute={baseDataAttribute}
    >
      <WidgetInfoBubble data-cy={baseDataAttribute + '-spam-calls-filtered'}>
        <WidgetSpamCountArea>
          <StyledWidgetTerinaryStat
            data-cy={baseDataAttribute + '-spam-calls-filtered-value'}
          >
            {numOfSolicitorCalls}
          </StyledWidgetTerinaryStat>
          <StyledWidgetBlackBoldSmallerText>
            Spam calls filtered
          </StyledWidgetBlackBoldSmallerText>
        </WidgetSpamCountArea>
        <WidgetInfoText>
          Your Live Receptionist identified&nbsp;
          <WidgetInfoTextBold>
            {numOfSolicitorCalls} spam calls
          </WidgetInfoTextBold>{' '}
          and saved you a total of{' '}
          <WidgetInfoTextBold data-cy={baseDataAttribute + '-time-saved-value'}>
            {timeSaved}.<br />
          </WidgetInfoTextBold>
          (No charges for spam calls)
        </WidgetInfoText>
      </WidgetInfoBubble>
    </InsightsWidget>
  )
}

export default LRCallDetailsWidget
