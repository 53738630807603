import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

const Login: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const { loginCode } = useParams<{ loginCode: string }>()

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2500)
  }, [])

  return (
    <>
      {loading ? (
        <>
          <p>{loginCode}</p>
          <p>We are magically logging you in. Stand by...</p>
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  )
}

export default Login
