import styled from 'styled-components'

export const StyledContent = styled.div(({ theme }) => ({
  '& > *:not(:last-child)': {
    marginBottom: theme.space(5),
  },
}))

interface StyledColumnsContainerProps {
  columns: number
}

export const StyledColumnsContainer = styled.div<StyledColumnsContainerProps>(
  ({ theme, columns }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    columnGap: theme.space(4),
  })
)
