import { useQuery } from '@tanstack/react-query'

import Contacts from 'src/client/resources/Contacts'
import logger from 'src/utils/logger'

const useContactDetails = (
  {
    locationId,
    contactId,
  }: {
    locationId: number
    contactId: number
  },
  { enabled = true }
) =>
  useQuery({
    queryKey: [
      'contactDetails',
      {
        locationId,
        contactId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, { locationId: lid, contactId: cid }] }) =>
      Contacts.getById(lid, cid),
    onError: (error) =>
      logger.error(`WAF ${useContactDetails.name} - error`, { error }),
    enabled,
  })

export default useContactDetails
