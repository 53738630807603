import React, { ChangeEventHandler, useCallback } from 'react'
import { Button } from 'src/stories/Button'
import { ArrowIcon } from 'src/stories/assets'
import styled from 'styled-components'

import { useNavigate } from 'react-router-dom'
import { useLocationContext } from 'src/contexts/LocationContext'
import { generateLocationUrl } from 'src/utils'

import Input from 'src/stories/Input'
import { useDebouncer } from 'src/hooks/useDebounce'

const baseDataAttribute = 'deleted-contacts-toolbar'

interface SearchInputProps {
  search?: string
  onSearch: (searchValue: string) => void
}

interface FilterButtonProps {
  label?: string
}

const ArrowIconRight = styled(ArrowIcon)(() => ({
  transform: `rotate(180deg)`,
}))

const StyledInput = styled(Input).attrs(() => ({
  containerStyle: {
    width: '100%',
    maxWidth: '100%',
    minWidth: '80%',
  },
}))(() => ({
  maxWidth: '100%',
  minWidth: '80%',
}))

const BackButton: React.FC<FilterButtonProps> = ({ label }) => {
  const { merchantId, locationId } = useLocationContext()
  const navigate = useNavigate()

  return (
    <Button
      label={'Back to Contacts'}
      onClick={() => {
        const contactsPageUrl = generateLocationUrl(
          merchantId,
          locationId,
          'customer-contacts-proto'
        )

        navigate(contactsPageUrl)
      }}
      endIcon={ArrowIconRight}
      iconStyles={{ height: 20, width: 20 }}
      displayAsText
      action="primary"
      size="large"
      style={{ width: 'fit-content' }}
      baseDataAttribute={`${baseDataAttribute}-show-filters`}
    />
  )
}

const SearchInput: React.FC<SearchInputProps> = ({ search, onSearch }) => {
  const handleContactSearch = useDebouncer<
    ChangeEventHandler<HTMLInputElement>
  >(
    useCallback(
      (e) => {
        onSearch(e.target.value.trim())
      },
      [onSearch]
    ),
    400
  )

  return (
    <StyledInput
      placeholder="Search..."
      defaultValue={search || undefined}
      type="search"
      name="search"
      verticallySpaced={false}
      onChange={handleContactSearch}
    />
  )
}

const createToolbarComponents = () => {
  return {
    BackButton,
    SearchInput,
  }
}

export { createToolbarComponents }
