import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'
import * as yup from 'yup'

import useUpdateLoopConfigurationsMutation from 'src/client/hooks/mutations/useUpdateLoopConfigurationsMutation'
import {
  PreferredConsumerTerm as PreferredConsumerTermEnum,
  preferredConsumerTermMap,
} from 'src/client/interfaces/Common'
import PreferredConsumerTerm from 'src/components/Settings/Business/BusinessTerminologySection/PreferredConsumerTerm'
import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import { StyledForm } from 'src/components/Settings/common/layout'
import { useLocationContext } from 'src/contexts/LocationContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import Select from 'src/stories/Select'

const schema = yup.object({
  preferredConsumerTerm: yup.mixed<PreferredConsumerTermEnum>().required(),
})

type FormSchema = yup.InferType<typeof schema>

interface Props {
  baseDataAttribute: string
  consumerTerm: PreferredConsumerTermEnum
  onSubmitFinished: () => void
}

const ConsumerForm: React.FC<Props> = ({
  baseDataAttribute,
  consumerTerm,
  onSubmitFinished,
}) => {
  const theme = useTheme()
  const { locationId } = useLocationContext()
  const { isSmallScreen } = useScreenSizes()
  const { mutateAsync: updateConsumerTerm, isLoading } =
    useUpdateLoopConfigurationsMutation(locationId)
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<FormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      preferredConsumerTerm: consumerTerm,
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    if (data.preferredConsumerTerm) {
      await updateConsumerTerm({
        locationId,
        preferredConsumerTerm: data.preferredConsumerTerm,
      })
    }

    onSubmitFinished()
  })

  const disableForm = isSubmitting || isLoading

  return (
    <StyledForm
      data-cy={`${baseDataAttribute}-form-consumer-term`}
      onSubmit={onSubmit}
    >
      <PreferredConsumerTerm consumerTerm={consumerTerm} />
      <Controller
        name="preferredConsumerTerm"
        control={control}
        render={({
          field,
          fieldState: { isTouched, isDirty, error },
          formState: { errors },
        }) => {
          return (
            <Select
              initialValue={field.value}
              disabled={disableForm}
              errorText={errors.preferredConsumerTerm?.message}
              options={Object.values(PreferredConsumerTermEnum).map(
                (value) => ({
                  value,
                  label: preferredConsumerTermMap[value].pluralSentenceCase,
                })
              )}
              onChange={(value) => {
                field.onChange(value)
              }}
              containerStyle={{
                width: isSmallScreen ? '50%' : undefined,
                marginTop: theme.space(5),
              }}
            />
          )
        }}
      />
      <ButtonForm
        baseDataAttribute={`${baseDataAttribute}-form-consumer-term`}
        disabled={disableForm}
        style={{ marginTop: theme.space(4) }}
      />
    </StyledForm>
  )
}

export default ConsumerForm
