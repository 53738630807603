import React from 'react'
import styled from 'styled-components'

import { Heading, Subtitle } from 'src/stories/typography'

const ContainerStyled = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
  marginTop: theme.space(4),
  marginBottom: theme.space(6),
}))

interface Props {
  title: string
  subtitle?: string | JSX.Element
  baseDataAttribute: string
}

const PageLayout: React.FCWithChildren<Props> = ({
  title,
  subtitle,
  baseDataAttribute,
  children,
}) => {
  return (
    <>
      <ContainerStyled>
        <Heading
          as="h1"
          size="extraLarge"
          data-cy={`${baseDataAttribute}-title`}
        >
          {title}
        </Heading>
        {subtitle && (
          <Subtitle data-cy={`${baseDataAttribute}-subtitle`}>
            {subtitle}
          </Subtitle>
        )}
      </ContainerStyled>
      {children}
    </>
  )
}

export default PageLayout
