import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTheme } from 'styled-components'

import { PaymentMethods } from 'src/client/interfaces/Common'
import { StyledForm } from 'src/components/Settings/common/layout'
import {
  StyledContent,
  StyledColumnsContainer,
} from 'src/components/Settings/Business/styled'
import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { useLocationContext } from 'src/contexts/LocationContext'
import { MutationBusinessInfoProps } from 'src/containers/Settings/Business'
import { Body, Heading } from 'src/stories/typography'
import Checkbox from 'src/stories/Checkbox'

export const PAYMENT_METHODS: Record<PaymentMethods, string> = {
  VISA: 'Visa',
  DINERSCLUB: 'Diners Club',
  TRAVELERSCHECK: 'Travelers Check',
  MASTERCARD: 'MasterCard',
  CASH: 'Cash',
  FINANCING: 'Financing',
  AMERICANEXPRESS: 'American Express',
  CHECK: 'Check',
  INVOICE: 'Invoice',
  DISCOVER: 'Discover',
}

const paymentsFormSchema = yup.object(
  Object.keys(PAYMENT_METHODS).reduce(
    (a, key) => ({ ...a, [key as PaymentMethods]: yup.boolean() }),
    {} as Record<PaymentMethods, yup.BooleanSchema<boolean>>
  )
)

type PaymentsFormSchema = yup.InferType<typeof paymentsFormSchema>

interface PaymentsFormProps extends MutationBusinessInfoProps {
  baseDataAttribute: string
}

const PaymentsForm: React.FC<PaymentsFormProps> = ({
  baseDataAttribute,
  isLoading,
  update,
  onSubmitFinished,
}) => {
  const theme = useTheme()
  const { isMediumScreen } = useScreenSizes()
  const { activeLocationV3 } = useLocationContext()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<PaymentsFormSchema>({
    resolver: yupResolver(paymentsFormSchema),
    defaultValues: Object.keys(PAYMENT_METHODS).reduce(
      (a, c) => ({
        ...a,
        [c as PaymentMethods]:
          activeLocationV3.businessInfo.paymentMethods.includes(
            c as PaymentMethods
          ),
      }),
      {} as PaymentsFormSchema
    ),
  })

  const onSubmit = handleSubmit(async (data) => {
    await update({
      paymentMethods: Object.entries(data).reduce(
        (a, [key, value]) => a.concat(value ? [key as PaymentMethods] : []),
        [] as PaymentMethods[]
      ),
    })

    onSubmitFinished()
  })

  const disableForm = isSubmitting || isLoading

  return (
    <StyledForm
      data-cy={`${baseDataAttribute}-form-payments`}
      onSubmit={onSubmit}
    >
      <StyledContent>
        <div>
          <Heading as="h3">Payments</Heading>
          <Body>Choose the payment methods that your business accepts.</Body>
        </div>
        <StyledColumnsContainer columns={isMediumScreen ? 3 : 1}>
          {Object.entries(PAYMENT_METHODS).map(([key, display], index, arr) => (
            <Checkbox
              key={key}
              label={display}
              style={
                index === arr.length - 1
                  ? { marginBottom: 0 }
                  : { marginBottom: theme.space(4) }
              }
              disabled={disableForm}
              {...register(key as PaymentMethods)}
            />
          ))}
        </StyledColumnsContainer>
        <div>
          <ButtonForm
            baseDataAttribute={`${baseDataAttribute}-form-payments`}
            disabled={disableForm}
          />
        </div>
      </StyledContent>
    </StyledForm>
  )
}

export default PaymentsForm
