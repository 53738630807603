import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { IntegrationsResource } from 'src/client'
import { HTTPRequestError } from 'src/client/interfaces/Common'
import useAccountContext from 'src/contexts/AccountContext'
import logger from 'src/utils/logger'

const useConnectFacebookMutation = () => {
  const { handleRefetchUserLocations } = useAccountContext()

  return useMutation(IntegrationsResource.connectFacebook, {
    onSuccess: async () => {
      toast.success('Facebook connected successfully.')

      try {
        await handleRefetchUserLocations()
      } catch (error) {
        logger.error(
          'There was an error trying to refresh the Business Information.',
          { error }
        )
      }
    },
    onError: (error) => {
      logger.error('There was an error trying to connect to Facebook.', {
        error,
      })

      const requestError = error as HTTPRequestError

      if (requestError?.data?.errorMessage === 'Not authorized for page') {
        toast.error(
          'You are trying to connect to a Facebook page different than the one previously set up. ' +
            'Please select the correct Facebook page or update it in the Business settings option.'
        )
      } else {
        toast.error(
          'There was an error trying to connect to your Facebook page. Please try again.'
        )
      }
    },
  })
}

export default useConnectFacebookMutation
