import {
  FeedbackNeutralIcon,
  FeedbackVNegativeIcon,
  FeedbackVPositiveIcon as FeedbackVeryPositiveIcon,
} from 'src/stories/assets'

import { GetInstantResponsesTotalAndRepliedResponse } from 'src/client/interfaces/Insights'
import { InsightsContextInterface } from './types'

import { format, subMonths } from 'date-fns'

/**
 * Mock data for a demo location in production, for all Insights widgets involving simple numbers & stats,
 * not specific contact data.
 *
 * Note: The Inbound Leads portion of the Insights page does not use InsightsContext,
 * so those widgets have separate handling for applying the mock data.
 *
 * ** What if I'm here because I'm updating InsightsContextInterface for new Insights projects?
 * Answer: To unblock yourself, you can put in zero-state or reasonable simulated data here.
 * Ideally you'll also talk to PMs and/or sales leadership about what simulated data
 * should be added to the demo location, for the new insights UI you're adding.
 */
export const mockDemoInsightsContext: InsightsContextInterface = {
  isReviewLoading: false,
  isReviewsZeroState: false,
  allTimeReviewsCount: 407,
  reviewSiteData: [
    {
      reviewSiteId: 2,
      rating: 4.9,
      reviewCount: 212,
      displayName: 'Google',
      sortAs: 1,
      isZeroState: false,
      signpostRating: 4.9,
      signpostCount: 212,
      reviews: [],
      reviewsByRating: {
        // ReviewsRatingByReviewSiteBreakdownWidget only uses the array length
        5: new Array(190).fill({
          contactName: 'Fake Demo Page',
          isExpandable: false,
        }),
        4: new Array(10).fill({
          contactName: 'Fake Demo Page',
          isExpandable: false,
        }),
        3: new Array(2).fill({
          contactName: 'Fake Demo Page',
          isExpandable: false,
        }),
        2: [],
        1: [],
      },
    },
    {
      reviewSiteId: 4,
      rating: 4.7,
      reviewCount: 110,
      displayName: 'Facebook',
      sortAs: 2,
      isZeroState: false,
      signpostRating: 4.7,
      signpostCount: 110,
      reviews: [],
      reviewsByRating: {
        // ReviewsRatingByReviewSiteBreakdownWidget only uses the array length
        5: new Array(85).fill({
          contactName: 'Fake Demo Page',
          isExpandable: false,
        }),
        4: new Array(17).fill({
          contactName: 'Fake Demo Page',
          isExpandable: false,
        }),
        3: new Array(8).fill({
          contactName: 'Fake Demo Page',
          isExpandable: false,
        }),
        2: [],
        1: [],
      },
    },
    {
      reviewSiteId: 1,
      rating: 4.8,
      reviewCount: 85,
      displayName: 'Yelp',
      sortAs: 3,
      isZeroState: false,
      signpostRating: 4.8,
      signpostCount: 85,
      reviews: [],
      reviewsByRating: {
        // ReviewsRatingByReviewSiteBreakdownWidget only uses the array length
        5: new Array(74).fill({
          contactName: 'Fake Demo Page',
          isExpandable: false,
        }),
        4: new Array(8).fill({
          contactName: 'Fake Demo Page',
          isExpandable: false,
        }),
        3: new Array(3).fill({
          contactName: 'Fake Demo Page',
          isExpandable: false,
        }),
        2: [],
        1: [],
      },
    },
  ],
  summaryReviews: {
    total: 4987,
    email: 4029,
    emailOpens: 976,
    text: 958,
  },
  reviewSiteRatings: [
    {
      reviewSiteId: 1,
      rating: 4.8,
      reviewCount: 85,
    },
    {
      reviewSiteId: 2,
      rating: 4.6,
      reviewCount: 212,
    },
    {
      reviewSiteId: 4,
      rating: 4.7,
      reviewCount: 110,
    },
  ],
  allTimeReviews: [], // Replaced in InsightsContext with the real loaded list from the demo location's contacts.
  isFeedbackLoading: false,
  isFeedbackZeroState: false,
  allTimeFeedbackCount: 90,
  feedbackSiteData: {
    positive: {
      value: 79,
      component: <FeedbackVeryPositiveIcon />,
      label: 'v. positive / positive',
    },
    neutral: {
      value: 9,
      component: <FeedbackNeutralIcon />,
      label: 'neutral',
    },
    negative: {
      value: 2,
      component: <FeedbackVNegativeIcon />,
      label: 'v. negative / negative',
    },
  },
  summaryFeedbacks: {
    total: 2955,
    email: 2895,
    emailOpens: 695,
    text: 60,
  },
  recentFeedbacks: [], // Replaced in InsightsContext with the real loaded list from the demo location's contacts.
  isCustomerActivitiesLoading: false,
  customerActivities: [], // Replaced in InsightsContext with the real loaded list from the demo location's contacts.
  shouldShowLiveReceptionist: true,
  isLiveReceptionistLoading: false,
  liveReceptionistReportingData: {
    startDate: null,
    endDate: null,
    numOfCalls: 251,
    numOfSolicitorCalls: 56,
    estimatedMinutesSavedFromSolicitorCalls: 0,
    planUsageSeconds: 0,
    avgDurationSeconds: 0,
    estimatedMinutesSavedPerSolicitorCall: 0,
    callNumbersByWrapupCode: {
      callBackRequest: 36,
      scheduling: 22,
      solicitor: 56,
      billing: 69,
      quote: 23,
      complaint: 61,
      other: 12,
    },
    callsByWeekday: {
      sunday: 7,
      monday: 70,
      tuesday: 53,
      wednesday: 32,
      thursday: 43,
      friday: 25,
      saturday: 9,
    },
  },
  isMhDataLoading: false,
  mhAvgResponseTime: {
    days: 1,
    hours: 3,
    minutes: 46,
  },
}

/**
 * Mock demo data for TotalContactsAddedFromLeadSourcesWidget
 */
export const mockDemoTotalContactsAddedFromLeadSources = {
  data: [
    { name: 'Angi Leads', y: 142 },
    { name: 'Thumbtack', y: 130 },
    { name: 'Facebook', y: 94 },
    { name: 'Google Messages', y: 26 },
    { name: 'Live Receptionist', y: 74 },
    { name: 'Phone Forwarding', y: 46 },
  ],
  total: 512,
}

/**
 * Mock demo data for InboundLeadsPerMonthWidget
 *
 */
const AMOUNT_OF_MONTHS_BACK = 5

export const mockDemoInboundLeadsPerMonth = [
  {
    totalContacts: 15,
    // Should be current month - 5, see below.
    // e.g. If current date is August, this should be 3, and the next
    // element should have addedPeriod=4, etc.
    addedPeriod: 0,
  },
  {
    totalContacts: 10,
    addedPeriod: 0,
  },
  {
    totalContacts: 3,
    addedPeriod: 0,
  },
  {
    totalContacts: 4,
    addedPeriod: 0,
  },
  {
    totalContacts: 18, // This one is the current month.
    addedPeriod: 0,
  },
]
const currentDate = new Date()

for (let i = AMOUNT_OF_MONTHS_BACK; i > 0; i--) {
  // Note: This could be simpler!
  mockDemoInboundLeadsPerMonth[AMOUNT_OF_MONTHS_BACK - i].addedPeriod = +format(
    subMonths(currentDate, i),
    'M'
  )
}

/**
 * Mock demo data for TotalInstantResponsesSentWidget
 */
export const mockDemoTotalInstantResponsesSent: GetInstantResponsesTotalAndRepliedResponse =
  {
    // Sales wants 100% response rate
    totalInstantResponsesSent: 76,
    totalInstantResponsesRepliedByUser: 76,
  }
