import React from 'react'
import styled from 'styled-components'

const Container = styled.div(({ theme }) => ({
  margin: `${theme.space(1)} 0 ${theme.space(4)} 0`,
  alignSelf: 'start',
}))

const Text = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.5rem',
  fontWeight: 400,
  margin: `${theme.space(1)} 0 ${theme.space(8)} 0`,
}))

interface Props {
  message: string
}

const ConfirmationModal: React.FC<Props> = ({ message }) => {
  return (
    <Container>
      <Text>{message}</Text>
    </Container>
  )
}

export default ConfirmationModal
