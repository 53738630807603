import React from 'react'
import styled from 'styled-components'

import MessagingHubExample from 'src/assets/google-messages-mh-example.png'
import { Button } from 'src/stories/Button'
import { Col, Row } from 'src/stories/Layout'

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  marginBottom: theme.space(3),
}))

const StyledCopy = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(6),
}))

const StyledHeadline = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '1.6rem',
  marginBottom: theme.space(2),
}))

const StyledDetails = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.4rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(8),
}))

interface OverViewStepProps {
  nextStep: () => void
}
const OverviewStep: React.FC<OverViewStepProps> = ({ nextStep }) => (
  <Row>
    <Col w={2}>
      <StyledTitle>Overview</StyledTitle>

      <StyledCopy>
        Connect your Google account to add your new contacts to Signpost and use
        Instant Response to automatically follow up with leads.
      </StyledCopy>

      <StyledHeadline>How it works:</StyledHeadline>

      <StyledDetails>
        <li>
          You'll need your Google Place ID to connect your account. We'll show
          you how to find this.
        </li>
        <li>
          Google will reach out to confirm your information. This may take
          anywhere from 1-3 days.
        </li>
        <li>
          Set up instant responses to automatically follow up with new leads,
          ensuring you are one of the first businesses to reach out.
        </li>
        <li>
          View and reply to all your Google Business Messages right from your
          Messaging Hub.
        </li>
      </StyledDetails>

      <Button
        action="primary"
        baseDataAttribute="connection-wizard-google-get-started"
        label="Get Started"
        maxWidth={167}
        onClick={nextStep}
      />
    </Col>

    <Col>
      <img
        src={MessagingHubExample}
        alt="Messaging Hub example"
        style={{ maxWidth: '90%' }}
      />
    </Col>
  </Row>
)

export default OverviewStep
