// The instrumentation needs to load first so
// accurate performance metrics are reported.
import 'src/assets/datadog-instrumentation'
import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import 'src/assets/index.css'
import 'typeface-roboto'
import 'src/utils/yupMethods' // import needs to go before App in order to get Yup methods initialized before everything.

import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const container = document.getElementById('root')

const root = ReactDOMClient.createRoot(container!)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
