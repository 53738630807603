import React, { useEffect } from 'react'
import useAuthContext from 'src/contexts/AuthContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'

const Logout: React.FC = () => {
  const { handleTokenLogout } = useAuthContext()

  useEffect(() => {
    handleTokenLogout()
  }, [handleTokenLogout])

  return <LoadingSpinner />
}

export default Logout
