import { AxiosInstance } from 'axios'

import { ConversationsRequests } from 'src/client/interfaces/Conversations'
import Resource from 'src/client/resource'

const actions = (client: AxiosInstance): ConversationsRequests => {
  const findConversations: ConversationsRequests['findConversations'] = ({
    locationId,
    pagination,
    search,
  }) => {
    return client.get(`/v3/locations/${locationId}/conversations`, {
      params: {
        ...pagination,
        ...search,
        take: 200,
        skip: 0,
      },
    })
  }

  const findPublicReviews: ConversationsRequests['findPublicReviews'] = ({
    locationId,
    pagination,
    reviewSites,
  }) => {
    return client.get(`/v3/locations/${locationId}/public-reviews`, {
      params: {
        ...pagination,
        reviewSites,
      },
    })
  }

  const findConversationEvents: ConversationsRequests['findConversationEvents'] =
    ({ locationId, contactId }) => {
      return client.get(
        `/v3/locations/${locationId}/conversations/${contactId}/events`
      )
    }

  const findConversationEvent: ConversationsRequests['findConversationEvent'] =
    ({ locationId, conversationId, eventFingerprint }) => {
      return client.get(
        `/v3/locations/${locationId}/conversations/${conversationId}/events/${eventFingerprint}`
      )
    }

  return {
    findConversations,
    findPublicReviews,
    findConversationEvents,
    findConversationEvent,
  }
}

export default Resource(actions)
