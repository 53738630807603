import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useTheme } from 'styled-components'

import { getLifecycleDisplayLabel } from 'src/utils'
import logger from 'src/utils/logger'
import Select from 'src/stories/Select'
import Constants from 'src/lib/Constants'
import { ContactsLegacyResource } from 'src/client'
import { DetailsLabel, DetailsText } from './styled'
import useMhContext from 'src/contexts/MhContext'
import { useParams } from 'react-router-dom'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import useAccountContext from 'src/contexts/AccountContext'

const lifecycles: LifecyclesStage[] = Object.values(
  Constants.CUSTOMERS.LifecycleStage
)

interface LifecyclesStage {
  label: string
  value: string
}

interface Props {
  lifecycleStage: string
  contactId: number
  isEditingLifecycle: boolean
}

const LifecycleSection: React.FC<Props> = ({
  lifecycleStage,
  contactId,
  isEditingLifecycle,
}) => {
  const theme = useTheme()
  const { getOpenConversationContactDetails } = useMhContext()
  const { locations } = useAccountContext()
  const { locationId } = useParams<UseLocationRouteParams>()

  const [isLoading, setIsLoading] = useState(false)
  const [currentLifecycle, setCurrentLifecycle] = useState<LifecyclesStage>()

  const lifecycleOptions: LifecyclesStage[] = useMemo(
    () =>
      lifecycles.map(({ value, label }) => {
        const activeLocation = locations?.find(
          (l) => l.locationId === +locationId!
        )

        return {
          value,
          label: activeLocation
            ? getLifecycleDisplayLabel(activeLocation, value)
            : label,
        }
      }),
    [locationId, locations]
  )

  const handleChangeLifecycle = async (value: string) => {
    setIsLoading(true)

    try {
      await ContactsLegacyResource.setLifecycle({
        customerId: contactId,
        lifecycleStage: value,
      })

      await getOpenConversationContactDetails()
    } catch (error) {
      logger.error('MH - Error editing lifecycle', { error })
      toast.error('There was an error editing customer lifecycle')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const current = lifecycleOptions.find((l) => l.value === lifecycleStage)

    setCurrentLifecycle(current)
  }, [lifecycleOptions, lifecycleStage])

  if (isEditingLifecycle) {
    return (
      <Select
        darkBackground
        height={theme.space(8)}
        dataCy="mh-contact-details-lifecycle-edit-form-select"
        initialValue={currentLifecycle?.value}
        disabled={isLoading}
        onChange={handleChangeLifecycle}
        options={lifecycleOptions}
      />
    )
  } else {
    return (
      <>
        <DetailsLabel>Lifecycle:</DetailsLabel>
        <DetailsText>{currentLifecycle?.label}</DetailsText>
      </>
    )
  }
}

export default LifecycleSection
