import { useMutation, useQueryClient } from '@tanstack/react-query'
import SmsRegistration from 'src/client/resources/SmsRegistration'
import logger from 'src/utils/logger'

const useCreateSmsRegistrationMutation = (locationId: number) => {
  const queryClient = useQueryClient()

  return useMutation(SmsRegistration.create, {
    onSuccess: async (params) => {
      await queryClient.invalidateQueries({
        queryKey: ['locations', { locationId }],
      })
    },
    onError: (error) => {
      logger.error('WAF useSmsRegistrationMutationMutation - error', {
        error,
      })
    },
  })
}

export default useCreateSmsRegistrationMutation
