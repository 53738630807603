import {
  createColumnHelper,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Row as ReactRow,
  SortingState,
  Table as ReactTable,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import React, { useMemo } from 'react'

import { Contact } from 'src/client/interfaces/Contacts'
import {
  CheckboxContainer,
  StyledCellAnchor,
  StyledCheckbox,
} from 'src/components/DeletedContacts/StyledComponents'

import { TablePagination } from 'src/stories/table/TablePagination'
import useContactsQuery from 'src/client/hooks/queries/useContactsQuery'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { formatPhoneNumber } from 'src/utils'
import Toolbar from 'src/components/DeletedContacts/Toolbar'
import ContactsTable from 'src/components/ContactsTable'
import { useContactsSearchParams } from 'src/components/ContactsTable/useContactsSearchParams'

const FULL_SIZE = 1000

const DeletedContactsTable: React.FC = () => {
  const { isMediumScreen } = useScreenSizes()

  // Route and search parameters
  const {
    setSearchParams,
    locationId,
    pagination,
    search: searchQueryParam,
    sort: sortQueryParam,
  } = useContactsSearchParams()

  const tablePagination = useMemo<PaginationState>(
    () => ({
      pageIndex: pagination.skip ?? 0 / (pagination.take ?? 1),
      pageSize: pagination.take ?? 0,
    }),
    [pagination.skip, pagination.take]
  )

  const tableSorting = useMemo<SortingState>(
    () =>
      sortQueryParam
        ? [
            {
              id: sortQueryParam.field ?? '',
              desc: sortQueryParam.direction === 'DESC',
            },
          ]
        : [],
    [sortQueryParam]
  )

  const deletedContactsQuery = useContactsQuery({
    locationId,
    pagination,
    search: searchQueryParam,
    sort: sortQueryParam,
    deleted: true,
  })

  const pageCount = Math.ceil(
    deletedContactsQuery?.data?.total || 0 / (pagination.take ?? 1)
  )

  const columnHelper = createColumnHelper<Contact>()

  const handleSearch = (searchValue: string) => {
    table.resetPagination(true)

    if (searchValue.length) {
      setSearchParams((current) => {
        current.set('search', searchValue)

        return current
      })
    } else {
      setSearchParams((current) => {
        current.delete('search')

        return current
      })
    }
  }

  const handleSort = (sort: Updater<SortingState>) => {
    const sortUpdate = functionalUpdate(sort, tableSorting)

    table.resetPagination(true)

    if (sortUpdate.length) {
      const { id, desc } = sortUpdate[0]

      setSearchParams((current) => {
        current.set('direction', desc ? 'DESC' : 'ASC')
        current.set('field', id)

        return current
      })
    } else {
      setSearchParams((current) => {
        current.delete('direction')
        current.delete('field')

        return current
      })
    }
  }

  const handlePagination = (paginate: Updater<PaginationState>) => {
    const { pageIndex: _pageIndex, pageSize: _pageSize } = functionalUpdate(
      paginate,
      tablePagination
    )

    setSearchParams((current) => {
      current.set('take', _pageSize.toString())
      current.set('skip', (_pageSize * _pageIndex).toString())

      return current
    })
  }

  const emptyPlaceholder = '-'

  const checkboxCell = columnHelper.display({
    id: 'select',
    size: 40,
    header: ({ table }: { table: ReactTable<Contact> }) => (
      <CheckboxContainer>
        <StyledCheckbox
          type="checkbox"
          name="checkbox"
          checked={table.getIsAllRowsSelected()}
          verticallySpaced={false}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      </CheckboxContainer>
    ),
    cell: ({ row }: { row: ReactRow<Contact> }) => (
      <CheckboxContainer>
        <StyledCheckbox
          type="checkbox"
          name="checkbox"
          checked={row.getIsSelected()}
          verticallySpaced={false}
          onChange={row.getToggleSelectedHandler()}
        />
      </CheckboxContainer>
    ),
  })

  const columnsMediumScreen = [
    checkboxCell,
    columnHelper.accessor(
      (row) =>
        [row.firstName, row.lastName].filter(Boolean).join(' ') ||
        emptyPlaceholder,
      {
        id: 'fullName',
        header: 'name',
        size: 180,
      }
    ),
    columnHelper.accessor('primaryEmailAddress', {
      header: 'email',
      cell: (info) => info.getValue() || emptyPlaceholder,
      size: 180,
    }),
    columnHelper.accessor('primaryPhoneNumber', {
      header: 'phone',
      cell: (info) =>
        info.getValue()
          ? formatPhoneNumber(info.getValue()!)
          : emptyPlaceholder,
      size: 180,
    }),
    columnHelper.accessor('id', {
      header: '',
      size: 80,
      cell: () => {
        return <StyledCellAnchor>Undelete</StyledCellAnchor>
      },
    }),
  ]

  const columns = [
    checkboxCell,
    columnHelper.accessor(
      (row) => {
        const name =
          [row.firstName, row.lastName].filter(Boolean).join(' ') || null

        return (
          name ??
          row.primaryEmailAddress ??
          row.primaryPhoneNumber ??
          emptyPlaceholder
        )
      },
      {
        id: 'contact',
        header: 'Contact',
        size: FULL_SIZE,
      }
    ),
    columnHelper.accessor('id', {
      header: '',
      size: 80,
      cell: () => {
        return <StyledCellAnchor>Undelete</StyledCellAnchor>
      },
    }),
  ]

  const table = useReactTable({
    columns: isMediumScreen ? columnsMediumScreen : columns,
    data: deletedContactsQuery?.data?.data || [],
    debugTable: process.env.REACT_APP_ENVIRONMENT === 'development',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: handlePagination,
    onSortingChange: handleSort,
    pageCount,
    state: {
      pagination: tablePagination,
      sorting: tableSorting,
    },
  })

  return (
    <ContactsTable
      toolbarComponent={
        <Toolbar
          search={searchQueryParam}
          onSearch={handleSearch}
          isDesktop={isMediumScreen}
        />
      }
      footerComponent={<TablePagination table={table} />}
      table={table}
      loading={deletedContactsQuery.isLoading}
    />
  )
}

export default DeletedContactsTable
