import styled, { useTheme } from 'styled-components'

import useAuthContext from 'src/contexts/AuthContext'
import { LocationIcon } from 'src/stories/assets'
import { StyledNavigationItem } from 'src/stories/Dropdown'
import { generateUserUrl } from 'src/utils'

const StyledLocationIcon = styled(LocationIcon)(({ theme }) => ({
  cursor: 'pointer',
  height: '1.7rem',
  marginRight: theme.space(3),
}))

const ReturnToMultiLocationView = () => {
  const { user } = useAuthContext()

  const theme = useTheme()

  return (
    <StyledNavigationItem to={generateUserUrl(user.id, '')}>
      <StyledLocationIcon fill={theme.colors.base_50} />
      Location Overview
    </StyledNavigationItem>
  )
}

export default ReturnToMultiLocationView
