import React from 'react'
import { useTheme } from 'styled-components'

import useInsightsContext from 'src/contexts/InsightsContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import ProgressBar from 'src/stories/ProgressBar'
import {
  ReviewSiteDataItem,
  ReviewSiteHeading,
  ReviewSiteRating,
  ReviewSiteRatingContainer,
} from './styled'
import {
  StyledWidgetBlackText,
  StyledWidgetContentContainer,
  StyledWidgetStat,
  StyledWidgetMainStat,
  WidgetReviewBubble,
} from '../styled'
import { InsightsWidget } from '../InsightsWidget'

interface Props {
  baseDataAttribute: string
}

const showAsPercentage = (num: number, total: number, decimalPlaces = 0) =>
  ((num / total) * 100).toFixed(decimalPlaces)

const ReviewsRatingByReviewSiteBreakdownWidget: React.FC<Props> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const { reviewSiteData, isReviewLoading } = useInsightsContext()

  if (isReviewLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  return (
    <InsightsWidget
      title="How are we rating across review sites?"
      baseDataAttribute={baseDataAttribute}
    >
      <StyledWidgetContentContainer
        data-cy={baseDataAttribute + '-review-sites'}
      >
        {reviewSiteData.map((rsd, idx) => {
          const dataCy = `${baseDataAttribute}-${rsd.reviewSiteId!}`

          return (
            <ReviewSiteDataItem key={dataCy} data-cy={dataCy}>
              <ReviewSiteHeading data-cy={dataCy + '-heading'}>
                <WidgetReviewBubble
                  dataCy={dataCy}
                  rating={rsd.signpostRating}
                  icon={rsd.displayName}
                  reviewSiteName={rsd.displayName}
                />

                <StyledWidgetMainStat data-cy={dataCy + '-review-count'}>
                  {rsd.signpostCount.toLocaleString()}
                </StyledWidgetMainStat>
              </ReviewSiteHeading>
              {[5, 4, 3, 2, 1].map((rating, ratingIdx) => {
                const count =
                  rsd.reviewsByRating[rating.toString()]?.length || 0
                const total = rsd.signpostCount || 0
                const ratingDataCy = `${dataCy}-rating-${rating}`

                return (
                  <ReviewSiteRatingContainer
                    key={ratingIdx}
                    data-cy={ratingDataCy}
                  >
                    <ReviewSiteRating
                      data-cy={ratingDataCy + '-label-container'}
                    >
                      <StyledWidgetBlackText data-cy={ratingDataCy + '-label'}>
                        {rating}&nbsp;{rating === 1 ? 'star' : 'stars'}
                      </StyledWidgetBlackText>
                      <ProgressBar
                        progress={total && (count / total) * 100}
                        dataCy={ratingDataCy + '-progress-bar'}
                      />
                    </ReviewSiteRating>
                    <StyledWidgetStat data-cy={ratingDataCy + '-info'}>
                      {count ? (
                        <>
                          {showAsPercentage(count, total)}%&nbsp;&#40;
                          {count.toLocaleString()}&nbsp;Reviews&#41;
                        </>
                      ) : (
                        <>-</>
                      )}
                    </StyledWidgetStat>
                  </ReviewSiteRatingContainer>
                )
              })}
            </ReviewSiteDataItem>
          )
        })}
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default ReviewsRatingByReviewSiteBreakdownWidget
