import { AxiosInstance } from 'axios'

import { CommsRequests } from '../interfaces/Comms'
import Resource from '../resource'
import Constants from '../../lib/Constants'

const actions = (client: AxiosInstance): CommsRequests => ({
  find: ({ fingerprint }) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Comms',
      method: 'find',
      params: {
        fingerprint,
      },
    })
  },
})

export default Resource(actions)
