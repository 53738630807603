import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'
import * as yup from 'yup'

import useUpdateLoopConfigurationsMutation from 'src/client/hooks/mutations/useUpdateLoopConfigurationsMutation'
import {
  PreferredServiceOfferingTerm as PreferredServiceOfferingTermEnum,
  preferredServiceOfferingTermMap,
} from 'src/client/interfaces/Common'
import PreferredServiceOfferingTerm from 'src/components/Settings/Business/BusinessTerminologySection/PreferredServiceOfferingTerm'
import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import { StyledForm } from 'src/components/Settings/common/layout'
import { useLocationContext } from 'src/contexts/LocationContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import Select from 'src/stories/Select'

const schema = yup.object({
  preferredServiceOfferingTerm: yup
    .mixed<PreferredServiceOfferingTermEnum>()
    .required(),
})

type FormSchema = yup.InferType<typeof schema>

interface Props {
  baseDataAttribute: string
  serviceTerm: PreferredServiceOfferingTermEnum
  onSubmitFinished: () => void
}

const ServiceOfferingForm: React.FC<Props> = ({
  baseDataAttribute,
  serviceTerm,
  onSubmitFinished,
}) => {
  const theme = useTheme()
  const { isSmallScreen } = useScreenSizes()
  const { locationId } = useLocationContext()
  const { mutateAsync: updateServiceTerm, isLoading } =
    useUpdateLoopConfigurationsMutation(locationId)
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<FormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      preferredServiceOfferingTerm: serviceTerm,
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    if (data.preferredServiceOfferingTerm) {
      await updateServiceTerm({
        locationId,
        preferredServiceOfferingTerm: data.preferredServiceOfferingTerm,
      })
    }

    onSubmitFinished()
  })

  const disableForm = isSubmitting || isLoading

  return (
    <StyledForm
      data-cy={`${baseDataAttribute}-form-service-offering-term`}
      onSubmit={onSubmit}
    >
      <PreferredServiceOfferingTerm serviceTerm={serviceTerm} />
      <Controller
        name="preferredServiceOfferingTerm"
        control={control}
        render={({
          field,
          fieldState: { isTouched, isDirty, error },
          formState: { errors },
        }) => {
          return (
            <Select
              initialValue={field.value}
              disabled={disableForm}
              errorText={errors.preferredServiceOfferingTerm?.message}
              options={Object.values(PreferredServiceOfferingTermEnum).map(
                (value) => ({
                  value,
                  label:
                    preferredServiceOfferingTermMap[value].sentenceCaseNoun,
                })
              )}
              onChange={(value) => {
                field.onChange(value)
              }}
              containerStyle={{
                width: isSmallScreen ? '50%' : undefined,
                marginTop: theme.space(5),
              }}
            />
          )
        }}
      />
      <ButtonForm
        baseDataAttribute={`${baseDataAttribute}-form-service-offering-term`}
        disabled={disableForm}
        style={{ marginTop: theme.space(4) }}
      />
    </StyledForm>
  )
}

export default ServiceOfferingForm
