import { useParams, useSearchParams } from 'react-router-dom'
import { SortDirection } from 'src/client/interfaces/Common'
import { ContactsListParameters } from 'src/client/interfaces/Contacts'
import { UseLocationRouteParams } from 'src/utils/interfaces'

const DEFAULT_TAKE = 50

export const useContactsSearchParams = (): ContactsListParameters & {
  searchParams: ReturnType<typeof useSearchParams>[0]
  setSearchParams: ReturnType<typeof useSearchParams>[1]
} => {
  const { locationId } = useParams<UseLocationRouteParams>()
  const [searchParams, setSearchParams] = useSearchParams()

  const direction = searchParams.has('direction')
    ? (searchParams.get('direction') as SortDirection)
    : undefined
  const field = searchParams.get('field') || undefined
  const search = searchParams.get('search') || undefined
  const segmentId = searchParams.has('segmentId')
    ? +(searchParams.get('segmentId') as string)
    : undefined
  const take = searchParams.has('take')
    ? +(searchParams.get('take') as string)
    : DEFAULT_TAKE
  const skip = searchParams.has('skip')
    ? +(searchParams.get('skip') as string)
    : 0

  return {
    searchParams,
    setSearchParams,
    locationId: +locationId!,
    pagination: { take, skip },
    search,
    segmentId,
    deleted: true,
    sort:
      direction && field
        ? {
            direction,
            field,
          }
        : undefined,
  }
}
