import React, { useMemo } from 'react'

import { BannerBigText, BannerGrid, BannerLeftItem } from '../styled'
import {
  ReviewsBannerGoogleSearchImage,
  ReviewsBannerIPhoneImage,
  ReviewsBannerItem,
  ReviewsBannerSmallText,
  ReviewsBannerStepsContainer,
} from './styled'
import Step1 from 'src/assets/ReviewsBanner/step_1.png'
import Step2 from 'src/assets/ReviewsBanner/step_2.png'
import Step3 from 'src/assets/ReviewsBanner/step_3.png'
import GoogleSearchDesktop from 'src/assets/ReviewsBanner/google_search_desktop.png'
import iPhoneNotification from 'src/assets/ReviewsBanner/signpost_app_notification_on_iphone.png'
import { useParams } from 'react-router-dom'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { generateLocationUrl } from 'src/utils'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { WidgetProps } from 'src/components/Insights/Widgets/types'
import { Button } from 'src/stories/Button'
import { useTheme } from 'styled-components'

const ReviewsBanner: React.FC<WidgetProps> = ({ baseDataAttribute }) => {
  const { merchantId, locationId } = useParams<UseLocationRouteParams>()
  const { isLargeScreen, isExtraLargeScreen } = useScreenSizes()
  const theme = useTheme()

  const reviewSitesSettingsPage = useMemo(
    () =>
      generateLocationUrl(merchantId!, locationId!, 'settings/review-sites'),
    [locationId, merchantId]
  )

  const gridColumns = useMemo(() => {
    if (isLargeScreen) {
      if (isExtraLargeScreen) {
        return undefined
      }

      return '60% 40%'
    }

    return '100%'
  }, [isLargeScreen, isExtraLargeScreen])

  return (
    <BannerGrid $gridColumns={gridColumns} data-cy={baseDataAttribute}>
      <BannerLeftItem data-cy={baseDataAttribute + '-actions'}>
        <BannerBigText>
          Add your Google page to start getting online reviews
        </BannerBigText>

        <ReviewsBannerSmallText>This is how it works</ReviewsBannerSmallText>

        <ReviewsBannerStepsContainer data-cy={baseDataAttribute + '-steps'}>
          <img
            src={Step1}
            alt="Step 1"
            data-cy={baseDataAttribute + '-step-1'}
          />
          <img
            src={Step2}
            alt="Step 2"
            data-cy={baseDataAttribute + '-step-2'}
          />
          <img
            src={Step3}
            alt="Step 3"
            data-cy={baseDataAttribute + '-step-3'}
          />
        </ReviewsBannerStepsContainer>

        <Button
          label="Add your Google Page"
          href={reviewSitesSettingsPage}
          baseDataAttribute={baseDataAttribute + '-cta'}
          maxWidth={theme.space(60)}
        />
      </BannerLeftItem>

      {isLargeScreen && (
        <ReviewsBannerItem data-cy={baseDataAttribute + '-image-container'}>
          <ReviewsBannerGoogleSearchImage
            data-cy={baseDataAttribute + '-image'}
            src={GoogleSearchDesktop}
            alt="Google Desktop Search"
          />

          <ReviewsBannerIPhoneImage
            src={iPhoneNotification}
            alt=""
            data-cy={baseDataAttribute + '-phone-image'}
          />
        </ReviewsBannerItem>
      )}
    </BannerGrid>
  )
}

export default ReviewsBanner
