import styled, { DefaultTheme } from 'styled-components'

import Anchor, { AnchorProps } from '../private/Anchor'

const StyledLink = styled(Anchor)<
  Pick<LinkProps, 'underline' | 'disabled' | 'fontWeight'>
>(({ theme, disabled, underline, fontWeight = 'bold' }) => {
  const baseStyle = {
    fontWeight: theme.typography.weights[fontWeight],
    textDecoration: underline === 'always' ? 'underline' : 'none',
  }

  const enabledStyle = {
    ...baseStyle,
    color: theme.colors.primary_2,
    '&:hover': {
      color: theme.colors.primary_2_70,
      textDecoration:
        underline === 'always' || underline === 'hover' ? 'underline' : 'none',
    },
  }

  const disabledStyle = { ...baseStyle, color: theme.colors.base_10 }

  return disabled ? disabledStyle : enabledStyle
})

const StyledIcon = styled.div<{ endPosition?: boolean }>(({ endPosition }) => ({
  marginRight: endPosition ? 0 : '0.3em',
  marginLeft: endPosition ? '0.3em' : 0,
  width: '1.15em',
  height: '1.15em',
  verticalAlign: 'sub',
}))

export interface LinkProps extends AnchorProps {
  /**
   * Specifies the underline.
   * @default 'always'
   */
  underline?: 'always' | 'hover' | 'none'
  /**
   * Specifies the font weight.
   * @default 'bold'
   */
  fontWeight?: keyof DefaultTheme['typography']['weights']
  /**
   * Icon to precede the children.
   * Prioritize using this prop, if it is not enough you can add the icon desired as a children.
   */
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  /**
   * Icon to succeed the children.
   * Prioritize using this prop, if it is not enough you can add the icon desired as a children.
   */
  endIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  /*
   * The `className` prop allow you to extend the style of this component.
   * https://styled-components.com/docs/basics#styling-any-component
   */
  className?: string
  baseDataAttribute?: string
}

interface ExcludeProps {
  /** Use `baseDataAttribute` prop instead */
  'data-cy'?: never
  /** Use `baseDataAttribute` prop instead */
  'data-dd'?: never
}

const Link: React.FCWithChildren<LinkProps & ExcludeProps> = ({
  children,
  href,
  target,
  rel,
  disabled,
  underline = 'always',
  fontWeight = 'bold',
  icon: Icon,
  endIcon: EndIcon,
  className,
  baseDataAttribute = 'Link',
}) => {
  const anchorProps = {
    href,
    target,
    rel,
    disabled,
    className,
  }

  return (
    <StyledLink
      {...anchorProps}
      underline={underline}
      fontWeight={fontWeight}
      data-cy={`${baseDataAttribute}-link`}
      data-dd={`${baseDataAttribute}-link`}
    >
      {Icon && <StyledIcon as={Icon} data-cy={`${baseDataAttribute}-icon`} />}
      {children}
      {EndIcon && (
        <StyledIcon
          as={EndIcon}
          endPosition
          data-cy={`${baseDataAttribute}-icon`}
        />
      )}
    </StyledLink>
  )
}

export default Link
