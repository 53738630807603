import { intervalToDuration } from 'date-fns'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import {
  ContactsLegacyResource,
  ConversationsLegacyResource,
  LocationsResource,
} from 'src/client'
import { LiveReceptionistReportingResponse } from 'src/client/interfaces/LocationsLegacy'
import CustomerActivitiesResource from 'src/client/resources/CustomerActivities'
import { extractFeedbackOutcome } from 'src/components/MessagingHub/styled'
import Constants from 'src/lib/Constants'

import {
  FeedbackNeutralIcon,
  FeedbackVNegativeIcon,
  FeedbackVPositiveIcon as FeedbackVeryPositiveIcon,
} from 'src/stories/assets'

import { generateLocationUrl, getActiveLocation } from 'src/utils'
import { UnknownObject, UseLocationRouteParams } from 'src/utils/interfaces'
import useAccountContext from '../AccountContext'
import {
  AllTimeReviews,
  FeedbackCategory,
  FeedbackSiteData,
  InsightsContextInterface,
  InsightsSummaryStats,
  MainReviewSites,
  ProcessedFeedback,
  ReviewSiteData,
} from './types'
import { mockDemoInsightsContext } from './demo-data'
import { categorizeFeedback, getContactName } from './utils'

const totalFeedbackWidgetData: FeedbackSiteData = {
  positive: {
    value: 0,
    component: <FeedbackVeryPositiveIcon />,
    label: 'v. positive / positive',
  },
  neutral: { value: 0, component: <FeedbackNeutralIcon />, label: 'neutral' },
  negative: {
    value: 0,
    component: <FeedbackVNegativeIcon />,
    label: 'v. negative / negative',
  },
}

const zeroStateSummaryStats = { total: 0, email: 0, emailOpens: 0, text: 0 }

const zeroStateLiveReceptionistReporting = {
  startDate: null,
  endDate: null,
  numOfCalls: 0,
  numOfSolicitorCalls: 0,
  estimatedMinutesSavedFromSolicitorCalls: 0,
  planUsageSeconds: 0,
  avgDurationSeconds: 0,
  estimatedMinutesSavedPerSolicitorCall: 0,
  callNumbersByWrapupCode: {
    callBackRequest: 0,
    scheduling: 0,
    solicitor: 0,
    billing: 0,
    quote: 0,
    complaint: 0,
    other: 0,
  },
  callsByWeekday: {
    // Warning: As of 2023-08-01, the order of these
    // attributes seems to matter to how they're displayed in the
    // widget. Sunday must come first. It comes first in the data from Core.
    sunday: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
  },
}

const InsightsContext = createContext<InsightsContextInterface>({
  // Reviews Data
  isReviewLoading: false,
  isReviewsZeroState: true,
  allTimeReviewsCount: 0,
  reviewSiteData: [],
  summaryReviews: {
    total: 0,
    email: 0,
    emailOpens: 0,
    text: 0,
  },
  reviewSiteRatings: [],
  allTimeReviews: [],
  // Feedback Data
  isFeedbackLoading: false,
  isFeedbackZeroState: true,
  allTimeFeedbackCount: 0,
  feedbackSiteData: undefined,
  summaryFeedbacks: {
    total: 0,
    email: 0,
    emailOpens: 0,
    text: 0,
  },
  recentFeedbacks: [],
  // Customer Activities Data
  isCustomerActivitiesLoading: false,
  customerActivities: [],
  // Live Receptionist Data
  shouldShowLiveReceptionist: false,
  isLiveReceptionistLoading: false,
  liveReceptionistReportingData: {
    startDate: '',
    endDate: '',
    numOfCalls: 0,
    numOfSolicitorCalls: 0,
    estimatedMinutesSavedFromSolicitorCalls: 0,
    planUsageSeconds: 0,
    avgDurationSeconds: 0,
    estimatedMinutesSavedPerSolicitorCall: 0,
    callNumbersByWrapupCode: {
      callBackRequest: 0,
      scheduling: 0,
      solicitor: 0,
      billing: 0,
      quote: 0,
      complaint: 0,
      other: 0,
    },
    callsByWeekday: {
      // Warning: As of 2023-08-01, the order of these
      // attributes seems to matter to how they're displayed in the
      // widget. Sunday must come first. It comes first in the data from Core.
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    },
  },
  isMhDataLoading: false,
  mhAvgResponseTime: { days: 0, hours: 0, minutes: 0 },
})

export const InsightsContextProvider: React.FCWithChildren = ({ children }) => {
  const { merchantId, locationId } = useParams<UseLocationRouteParams>()

  const isDemoLocation =
    !!locationId && locationId === process.env.REACT_APP_DEMO_LOCATION_ID

  const { locations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)

  // Reviews Data
  const [isReviewLoading, setIsReviewLoading] = useState(false)
  const [isReviewsZeroState, setIsReviewsZeroState] = useState(true)
  const [allTimeReviewsCount, setAllTimeReviewsCount] = useState(0)
  const [reviewSiteData, setReviewSiteData] = useState<ReviewSiteData[]>([])
  const [allTimeReviews, setAllTimeReviews] = useState<AllTimeReviews[]>([])
  const [summaryReviews, setSummaryReviews] = useState<InsightsSummaryStats>(
    zeroStateSummaryStats
  )

  // Feedbacks Data
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false)
  const [isFeedbackZeroState, setIsFeedbackZeroState] = useState(true)
  const [allTimeFeedbackCount, setAllTimeReviewFeedbackCount] = useState(0)
  const [feedbackSiteData, setFeedbackSiteData] = useState<FeedbackSiteData>({
    positive: { ...totalFeedbackWidgetData.positive },
    neutral: { ...totalFeedbackWidgetData.neutral },
    negative: { ...totalFeedbackWidgetData.negative },
  })
  const [summaryFeedbacks, setSummaryFeedbacks] =
    useState<InsightsSummaryStats>(zeroStateSummaryStats)

  const [recentFeedbacks, setRecentFeedbacks] = useState<ProcessedFeedback[]>(
    []
  )
  // Customer Activities Data
  const [isCustomerActivitiesLoading, setIsCustomerActivitiesLoading] =
    useState(false)
  const [customerActivities, setCustomerActivities] = useState<UnknownObject[]>(
    []
  )
  // Live Receptionist Data
  const [shouldShowLiveReceptionist, setShouldShowLiveReceptionist] =
    useState(false)
  const [isLiveReceptionistLoading, setIsLiveReceptionistLoading] =
    useState(false)
  const [liveReceptionistReportingData, setLiveReceptionistReportingData] =
    useState<LiveReceptionistReportingResponse>(
      zeroStateLiveReceptionistReporting
    )

  // MH Data
  const [isMhDataLoading, setIsMhDataLoading] = useState(false)
  const [mhAvgResponseTime, setMhAvgResponseTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  })

  const processReviewsData = useCallback(async () => {
    setIsReviewLoading(true)

    const { docIds } = await LocationsResource.getReviews(+locationId!)

    const { docs: _reviews } =
      await ConversationsLegacyResource.getConversationsPreviews([...docIds])

    const { docs: reviewsContacts } =
      await ContactsLegacyResource.getContactsForConversations(
        _reviews.map((r) => `Contact.${r.contactId}`)
      )

    const { reviews, reviewsByReviewSiteId } = _reviews.reduce(
      (a, r) => {
        const foundContact = reviewsContacts.find(
          (c) => c.contactId === r.contactId
        )
        const contactName = getContactName(foundContact)

        let isExpandable = false

        if (!a.hasExpandedReview) {
          isExpandable = !!r.reviewText
          a.hasExpandedReview = true
        }

        const review: AllTimeReviews = {
          ...r,
          contactName,
          contact: foundContact,
          contactLink:
            foundContact &&
            `${generateLocationUrl(
              merchantId!,
              locationId!,
              `/customers/${foundContact.contactId}`
            )}`,
          isExpandable,
        }

        if (review.reviewSiteId) {
          const rsId = review.reviewSiteId.toString()

          if (!a.reviewsByReviewSiteId[rsId]) {
            a.reviewsByReviewSiteId[rsId] = []
          }

          a.reviewsByReviewSiteId[rsId].push(review)
        }

        a.reviews.push(review)

        return a
      },
      {
        reviews: [] as AllTimeReviews[],
        reviewsByReviewSiteId: {} as UnknownObject<AllTimeReviews[]>,
        hasExpandedReview: false,
      }
    )

    setAllTimeReviews(reviews)

    const processedData: ReviewSiteData[] = []
    const normalizedSentEmailReviews = activeLocation?.totalReviewEmailSent || 0
    const normalizedSentTextReviews = activeLocation?.totalReviewSmsSent || 0
    const normalizedOpenedEmailReviews =
      activeLocation?.totalReviewEmailOpens || 0
    const totalSentReviews =
      normalizedSentTextReviews + normalizedSentEmailReviews

    const normalizedSentEmailFeedbacks =
      activeLocation?.totalFeedbackEmailSent || 0
    const normalizedSentTextFeedbacks =
      activeLocation?.totalFeedbackSmsSent || 0
    const normalizedOpenedEmailFeedbacks =
      activeLocation?.totalFeedbackEmailOpens || 0

    const totalSentFeedbacks =
      normalizedSentEmailFeedbacks + normalizedSentTextFeedbacks

    let totalReviewCount = 0

    Object.entries(Constants.ReviewSites).forEach(([key, value]) => {
      const reviewSiteByReviewSiteId = reviewsByReviewSiteId[value.id] || []
      const reviewSite = activeLocation?.reviewSiteRatings.find(
        (rsr) => rsr.reviewSiteId === value.id
      )

      let sortAs = 0

      // Sort as: Google, Facebook, Yelp. If the review site is not present for the location,
      // it will be sorted in the same order, after the existing sites.
      if (value.id === 2) {
        sortAs = 1
      } else if (value.id === 4) {
        sortAs = 2
      } else if (value.id === 1) {
        sortAs = 3
      }

      if (reviewSite && reviewSiteByReviewSiteId) {
        const signpostRating = reviewSiteByReviewSiteId.length
          ? reviewSiteByReviewSiteId.reduce(
              (a, c) => (c.rating ? a + c.rating : a),
              0
            ) / reviewSiteByReviewSiteId.length
          : null

        const reviewsByRating = reviewSiteByReviewSiteId.reduce((a, c) => {
          const rsRating =
            typeof c.rating === 'number' ? c.rating.toString() : 'N/A'

          if (!a[rsRating]) {
            a[rsRating] = []
          }

          a[rsRating].push(c)

          return a
        }, {} as UnknownObject<AllTimeReviews[]>)

        processedData.push({
          ...reviewSite,
          displayName: value.displayName as MainReviewSites,
          sortAs,
          isZeroState: false,
          signpostRating: signpostRating && +signpostRating.toPrecision(2),
          signpostCount: reviewSiteByReviewSiteId.length,
          reviews: reviewSiteByReviewSiteId,
          reviewsByRating,
        })

        setIsReviewsZeroState(false)
      } else {
        processedData.push({
          displayName: value.displayName as MainReviewSites,
          reviewSiteId: value.id,
          sortAs: sortAs + 3,
          isZeroState: true,
          reviews: [],
          reviewsByRating: {},
          signpostCount: 0,
          signpostRating: null,
        })
      }

      totalReviewCount += reviewSite?.reviewCount || 0
    })

    processedData.sort((a, b) => a.sortAs - b.sortAs)

    setAllTimeReviewsCount(totalReviewCount)
    setReviewSiteData(processedData)
    setSummaryReviews({
      total: totalSentReviews,
      email: normalizedSentEmailReviews,
      emailOpens: normalizedOpenedEmailReviews,
      text: normalizedSentTextReviews,
    })
    setSummaryFeedbacks({
      total: totalSentFeedbacks,
      email: normalizedSentEmailFeedbacks,
      emailOpens: normalizedOpenedEmailFeedbacks,
      text: normalizedSentTextFeedbacks,
    })

    setIsReviewLoading(false)
  }, [activeLocation, locationId, merchantId])

  const processFeedbacksData = useCallback(async () => {
    setIsFeedbackLoading(true)

    const { docIds } = await LocationsResource.getFeedback(+locationId!)

    const { docs: _feedbacks } =
      await ConversationsLegacyResource.getConversationsPreviews(docIds)

    const { docs: feedbacksContacts } =
      await ContactsLegacyResource.getContactsForConversations(
        _feedbacks.map((fb) => `Contact.${fb.contactId}`)
      )

    const maxRecentFeedbacks = 5

    const { feedbackData, mostRecentFeedbacks } = _feedbacks.reduce(
      (accum, feedback, idx) => {
        const {
          rating,
          feedbackRatingDenominator,
          isPositive,
          feedbackText,
          contactId,
          timestamp,
        } = feedback

        // Process for total Feedback widget
        // includes categorizing and assigning the
        // right amount of feedbacks by category
        const feedbackCategory = categorizeFeedback(
          !!isPositive,
          feedbackRatingDenominator,
          undefined,
          rating ? rating : undefined
        )

        const foundContact = feedbacksContacts.find(
          (c) => c.contactId === contactId
        )
        const contactName = getContactName(foundContact)
        const contactEmail = foundContact?.primaryEmailAddress || ''
        const hasPhoneNumber = !!foundContact?.primaryPhoneNumber

        const transformedFb: ProcessedFeedback = {
          contactId,
          name: contactName,
          email: contactEmail,
          contactProfileLink: `${generateLocationUrl(
            merchantId!,
            locationId!,
            `/customers/${contactId}`
          )}`,
          component: extractFeedbackOutcome(
            feedbackRatingDenominator ? feedbackRatingDenominator : 0,
            !!isPositive,
            feedbackText,
            rating ? rating : undefined
          ).feedbackIcon,
          sentiment: `${categorizeFeedback(
            !!isPositive,
            feedbackRatingDenominator ? feedbackRatingDenominator : undefined,
            false,
            rating ? rating : undefined
          )!}`,
          isExpandable: false,
          feedbackText,
          timestamp,
          hasPhoneNumber,
        }

        if (feedbackCategory) {
          if (
            (['negative', 'very negative'] as FeedbackCategory[]).includes(
              feedbackCategory
            )
          ) {
            accum.negativeFeedbacksCount++
            transformedFb.isExpandable = true
          } else {
            accum.positiveFeedbacksCount++
          }

          const feedbackDataKey =
            feedbackCategory as keyof typeof accum.feedbackData
          const maxNegativeFeedbackCount = 3
          const {
            positiveFeedbacksCount,
            negativeFeedbacksCount,
            remainingRecentFeedbacks,
          } = accum
          const shouldDisplay =
            maxNegativeFeedbackCount < remainingRecentFeedbacks
          const allow1negative =
            negativeFeedbacksCount <= 1 && remainingRecentFeedbacks >= 3
          const allow2negatives =
            negativeFeedbacksCount <= 2 && remainingRecentFeedbacks >= 2
          const allow3negatives =
            negativeFeedbacksCount <= 3 && remainingRecentFeedbacks >= 3
          const allowUpToMaxPositives =
            positiveFeedbacksCount === 5 && remainingRecentFeedbacks === 1

          if (
            shouldDisplay ||
            allow3negatives ||
            allow2negatives ||
            allow1negative ||
            allowUpToMaxPositives
          ) {
            accum.mostRecentFeedbacks.push(transformedFb)
          }

          accum.remainingRecentFeedbacks--
          accum.feedbackData[feedbackDataKey].value += 1
        }

        return accum
      },
      {
        negativeFeedbacksCount: 0,
        positiveFeedbacksCount: 0,
        remainingRecentFeedbacks: maxRecentFeedbacks,
        feedbackData: {
          positive: { ...totalFeedbackWidgetData.positive },
          neutral: { ...totalFeedbackWidgetData.neutral },
          negative: { ...totalFeedbackWidgetData.negative },
        },
        mostRecentFeedbacks: [] as ProcessedFeedback[],
      }
    )

    if (_feedbacks.length > 0) setIsFeedbackZeroState(false)

    setRecentFeedbacks(mostRecentFeedbacks)
    setAllTimeReviewFeedbackCount(_feedbacks.length)
    setFeedbackSiteData(feedbackData)
    setIsFeedbackLoading(false)
  }, [locationId, merchantId])

  const processLiveReceptionistReporting = useCallback(async () => {
    // Note: As of 2022-12-27, the presence of a securusPhoneNumber is being used
    // as a proxy for hasActiveLRAccount
    const hasActiveLRAccount = !!activeLocation?.securusPhoneNumber

    setShouldShowLiveReceptionist(hasActiveLRAccount)

    if (hasActiveLRAccount) {
      setIsLiveReceptionistLoading(true)

      const data = await LocationsResource.getLiveReceptionistReporting(
        +locationId!
      )

      setLiveReceptionistReportingData(data)
      setIsLiveReceptionistLoading(false)
    }
  }, [locationId, activeLocation?.securusPhoneNumber])

  const processMessagingHubData = useCallback(() => {
    setIsMhDataLoading(true)
    const avgResponseTimeInSeconds = 100000

    const { days, hours, minutes } = intervalToDuration({
      start: 0,
      end: avgResponseTimeInSeconds ? avgResponseTimeInSeconds * 1000 : 0,
    })

    setMhAvgResponseTime({ days: days!, hours: hours!, minutes: minutes! })
    setIsMhDataLoading(false)
  }, [])

  useEffect(() => {
    const fetchAndProcessFeedbackData = async () => {
      await processFeedbacksData()
    }

    const fetchAndProcessReviewsData = async () => {
      await processReviewsData()
    }

    const fetchAndProcessLiveReceptionistReportingData = async () => {
      await processLiveReceptionistReporting()
    }

    const fetchAndProcessMessagingHubData = () => {
      processMessagingHubData()
    }

    void fetchAndProcessFeedbackData()
    void fetchAndProcessReviewsData()
    void fetchAndProcessLiveReceptionistReportingData()
    void fetchAndProcessMessagingHubData()
  }, [
    processReviewsData,
    processFeedbacksData,
    processLiveReceptionistReporting,
    processMessagingHubData,
  ])

  useEffect(() => {
    const _locationId = locationId && +locationId

    if (_locationId) {
      setIsCustomerActivitiesLoading(true)
      void CustomerActivitiesResource.getActivitiesByLocationId({
        locationId: _locationId,
      })
        .then((a) => setCustomerActivities(a))
        .finally(() => {
          setIsCustomerActivitiesLoading(false)
        })
    }
  }, [locationId])

  let contextValues: InsightsContextInterface = {
    // Review Data
    isReviewLoading,
    isReviewsZeroState,
    allTimeReviewsCount,
    reviewSiteData,
    summaryReviews,
    reviewSiteRatings: activeLocation?.reviewSiteRatings || [],
    allTimeReviews,
    // Feedback Data
    isFeedbackLoading,
    isFeedbackZeroState,
    allTimeFeedbackCount,
    feedbackSiteData,
    summaryFeedbacks,
    recentFeedbacks,
    // Customer Activities Data
    isCustomerActivitiesLoading,
    customerActivities,
    // Live Receptionist Data
    shouldShowLiveReceptionist,
    isLiveReceptionistLoading,
    liveReceptionistReportingData,
    // MH Data
    isMhDataLoading,
    mhAvgResponseTime,
  }

  if (isDemoLocation) {
    // Future: We might add curated mock customer activities to the demo.
    // But that would involve creating mock customer/conversation objects with IDs,
    // with buttons & link that should take you to real contact pages.
    // So we're more likely to create the real data in the database.
    // Instead, for InsightsContext involving more than simple stats displays,
    // we can show the real activities & other data loaded above.
    contextValues = {
      ...mockDemoInsightsContext,
      isReviewLoading,
      isFeedbackLoading,
      isCustomerActivitiesLoading,
      isLiveReceptionistLoading,
      allTimeReviews,
      recentFeedbacks,
      customerActivities,
    }
  }

  return (
    <InsightsContext.Provider value={contextValues}>
      {children}
    </InsightsContext.Provider>
  )
}

const useInsightsContext = () => useContext(InsightsContext)

export default useInsightsContext
