import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import styled, { useTheme } from 'styled-components'
import { format } from 'date-fns'
import { toDate } from 'date-fns-tz'

import RestoreDefaultMessageModal from '../RestoreDefaultMessageModal'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Button } from 'src/stories/Button'
import Toggle from 'src/stories/Toggle'
import Tooltip from 'src/stories/Tooltip'
import { useLocationRouteParams } from 'src/routes/hooks'
import { useShowUpsellModal } from 'src/components/MessagingHub/UpsellModal/hooks'
import { getActiveLocation, getLocationInformation } from 'src/utils'
import useAccountContext from 'src/contexts/AccountContext'
import InfoCard from 'src/components/Settings/common/InfoCard'
import { RestoreIcon } from 'src/stories/assets'

interface CommonLeadInstantResponseSettingsProps {
  leadSourceName: string
  enabled?: boolean
  messageLabel?: string
  defaultMessage: string
  message?: string | null
  maxLength?: number
  isDesktop?: boolean
  onEnablementChange?: (value: boolean) => void
  onUpdateMessage?: (value: string | null) => Promise<void>
  tooltipMessage?: string | null
  isToggleDisabled?: boolean
  informationMessage?: string | null
}

interface ConnectableLeadInstantResponseSettingsProps {
  connectable: true
  connected: boolean
  integrationUpdatedAt: string | null
  integrationStatus?: string
  isConnecting?: boolean
  isDisconnecting?: boolean
  onClickConnect?: () => void
  onClickDisconnect?: () => void
}

interface UnconnectableLeadInstantResponseSettingsProps {
  connectable: false
}

export type LeadInstantResponseSettingsProps =
  CommonLeadInstantResponseSettingsProps &
    (
      | ConnectableLeadInstantResponseSettingsProps
      | UnconnectableLeadInstantResponseSettingsProps
    )

const StyledContainer = styled.section(({ theme }) => ({
  marginBottom: theme.space(4),
  width: '100%',
}))

const StyledHeader = styled.div<
  Pick<LeadInstantResponseSettingsProps, 'enabled' | 'isDesktop'>
>(({ theme, enabled, isDesktop }) => ({
  width: '100%',
  boxShadow: `inset 0 0 0 1px ${theme.colors.base_20}`,
  borderRadius: `${theme.constants.borderRadius} ${
    theme.constants.borderRadius
  } ${enabled ? '0px' : theme.constants.borderRadius} ${
    enabled ? '0px' : theme.constants.borderRadius
  }`,
  padding: `${theme.space(2)} ${theme.space(6)}`,
  display: 'flex',
  flexDirection: isDesktop ? 'row' : 'column',
  justifyContent: 'space-between',
  alignItems: isDesktop ? 'center' : 'flex-start',
}))

const StyledHeaderLeftItemsContainer = styled.div<{
  connected?: boolean
  isDesktop?: boolean
  connectable?: boolean
}>(({ theme, connectable, connected, isDesktop }) => ({
  display: 'flex',
  flexDirection: (!connectable || connected) && !isDesktop ? 'column' : 'row',
  justifyContent: !connected && !isDesktop ? 'space-between' : undefined,
  width: !connected && !isDesktop ? '100%' : undefined,
  columnGap: theme.space(4),
  rowGap: theme.space(2),
}))

const StyledHeaderItemContainer = styled.div<{ columnGap?: number }>(
  ({ theme, columnGap }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: columnGap ? theme.space(columnGap) : theme.space(8),
    rowGap: theme.space(2),
  })
)

const StyledConnectionIndicator = styled.div<{ error?: boolean }>(
  ({ theme, error }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: error ? theme.colors.critical : theme.colors.positive,
    fontSize: '1.2rem',
    gap: theme.space(2),
  })
)

const StyledDot = styled.div<{ error?: boolean }>(({ theme, error }) => ({
  width: theme.space(2),
  height: theme.space(2),
  backgroundColor: error ? theme.colors.critical : theme.colors.positive,
  borderRadius: '50%',
  boxShadow: `0 0 0 2px ${
    error ? theme.colors.critical_70 : theme.colors.positive_15
  }`,
}))

const StyledUpdatedAtContainer = styled.label(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(4),
  fontSize: '1.2rem',
  color: theme.colors.base_40,
}))

const StyledToggleContainer = styled.label<
  Pick<LeadInstantResponseSettingsProps, 'enabled'>
>(({ theme, enabled }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(4),
  fontSize: '1.4rem',
  color: enabled ? theme.colors.base_100 : theme.colors.base_50,
  transition: 'color 0.3s ease',
}))

const StyledBody = styled.div<
  { bodyHeight: number } & Pick<LeadInstantResponseSettingsProps, 'enabled'>
>(({ theme, bodyHeight, enabled }) => ({
  width: '100%',
  borderTop: '0px',
  boxShadow: `inset 0 0 0 1px ${theme.colors.base_20}`,
  borderRadius: `0px 0px ${theme.constants.borderRadius} ${theme.constants.borderRadius}`,
  transition: 'all 0.3s ease-out',
  transitionProperty: 'max-height, border-radius',
  maxHeight: enabled ? `calc(${bodyHeight}px + ${theme.space(8)})` : 0,
  overflow: 'hidden',
}))

const StyledContent = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    padding: isDesktop
      ? `${theme.space(6)} ${theme.space(6)} ${theme.space(9)} ${theme.space(
          36
        )}`
      : theme.space(4),
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    gap: theme.space(2),
  })
)

const StyledTextboxTitle = styled.label(() => ({
  display: 'block',
  fontSize: '1.4rem',
  fontWeight: 500,
}))

const StyledTextbox = styled.textarea(({ theme }) => ({
  padding: theme.space(4),
  backgroundColor: theme.colors.base_0,
  color: theme.colors.base_100,
  border: `2px solid ${theme.colors.base_20}`,
  fontSize: '1.6rem',
  resize: 'none',
  borderRadius: theme.constants.borderRadius,
  marginTop: theme.space(2),
  width: '100%',
  '&:hover': {
    borderColor: theme.colors.base_50,
  },
  '&:focus': {
    color: theme.colors.base_100,
    outline: 'none',
    border: `2px solid ${theme.colors.primary_1}`,
  },
}))

const StyledMaxChar = styled.label(({ theme }) => ({
  color: theme.colors.base_50,
  display: 'block',
  fontSize: '1.2rem',
  marginTop: theme.space(1),
}))

const StyledButtonsContainer = styled.div(({ theme }) => ({
  marginTop: theme.space(4),
  display: 'flex',
  gap: theme.space(2),
}))

const TooltipLogo = styled.div(({ theme }) => ({
  height: theme.space(5),
  width: theme.space(5),
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  fontSize: '1.4rem',
  border: `2px solid ${theme.colors.base_30}`,
  color: theme.colors.base_30,
}))

const LeadInstantResponseSettings: React.FC<
  LeadInstantResponseSettingsProps
> = ({
  leadSourceName,
  enabled,
  messageLabel,
  defaultMessage,
  message,
  maxLength,
  onUpdateMessage,
  onEnablementChange,
  isToggleDisabled,
  informationMessage,
  ...props
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState(500)
  const theme = useTheme()
  const { isSmallScreen: isDesktop } = useScreenSizes()
  const [textboxMessage, setTextboxMessage] = useState('')
  const [isRestoringMessage, setIsRestoringMessage] = useState(false)
  const { showModal, closeModal } = useModalNotificationsContext()
  const { locations } = useAccountContext()
  let { locationId } = useLocationRouteParams()
  const showUpsellModal = useShowUpsellModal()

  const activeLocation = getActiveLocation(locations, locationId)
  const { hasTwilioNumber, isLiveReceptionistOnly } =
    getLocationInformation(activeLocation)

  useEffect(() => {
    setTextboxMessage(message ?? '')
  }, [message])

  useLayoutEffect(() => {
    setContentHeight(enabled ? contentRef.current?.clientHeight ?? 500 : 0)
  }, [enabled])

  const handleConfirmRestoreMessage = useCallback(async () => {
    closeModal()
    setIsRestoringMessage(true)
    await onUpdateMessage?.(null)
    setTextboxMessage('')
    setIsRestoringMessage(false)
  }, [closeModal, onUpdateMessage])

  const handleRestoreMessage = useCallback(() => {
    showModal({
      title: 'Reset Instant Response Text Message',
      hideActionButtons: false,
      modalActionsOptions: {
        callToAction: {
          label: 'Reset text message',
          onClick: handleConfirmRestoreMessage,
        },
      },
      customBody: (
        <RestoreDefaultMessageModal defaultMessage={defaultMessage} />
      ),
      height: 'auto',
    })
  }, [defaultMessage, handleConfirmRestoreMessage, showModal])

  const ConnectionIndicator = () => {
    if (!props.connectable || !props.connected) {
      return null
    }

    const isError = props.integrationStatus === 'error'

    return (
      <StyledConnectionIndicator error={isError}>
        <StyledDot error={isError} />
        <div>
          {isError
            ? 'Error (Please disconnect and connect the integration again)'
            : 'Connected'}
        </div>
      </StyledConnectionIndicator>
    )
  }

  const QuestionTooltip = () => {
    if (!props.tooltipMessage) {
      return null
    }

    return (
      <Tooltip
        widthUnits={60}
        fontSize="1.3rem"
        color={theme.colors.base_80}
        text={props.tooltipMessage}
        fontWeight={400}
      >
        <TooltipLogo>?</TooltipLogo>
      </Tooltip>
    )
  }

  return (
    <StyledContainer>
      <StyledHeader enabled={enabled} isDesktop={isDesktop}>
        <StyledHeaderItemContainer columnGap={2}>
          <h2>{leadSourceName}</h2>
          <ConnectionIndicator />
          <QuestionTooltip />
        </StyledHeaderItemContainer>
        <StyledHeaderLeftItemsContainer
          connectable={props.connectable}
          connected={props.connectable && props.connected}
          isDesktop={isDesktop}
        >
          <StyledHeaderItemContainer>
            {props.connectable && props.integrationUpdatedAt && (
              <StyledUpdatedAtContainer>
                <div>
                  {props.connected ? 'Connected' : 'Disconnected'} on{' '}
                  {/*
                    Setting a fixed database timezone is a work around to avoid changing a legacy concat query that isn't using the ISO format.
                    Query link: https://github.com/signpost/rhubarb/blob/7d949f3eb61b2986a00d7b74d382fd8381468c2d/sql/documents/Locations.sql#L242-L249
                  */}
                  {format(
                    toDate(props.integrationUpdatedAt, { timeZone: 'UTC' }),
                    "MM/dd/yy' at 'hh:mm a"
                  )}
                </div>
              </StyledUpdatedAtContainer>
            )}
          </StyledHeaderItemContainer>

          {props.connectable && !props.connected && (
            <Button
              baseDataAttribute={`settings-instant-responders-${leadSourceName}-connect`}
              action="primary"
              outline
              label={props.isConnecting ? 'Connecting...' : 'Connect'}
              loading={props.isConnecting}
              style={{ minWidth: theme.space(25) }}
              maxHeight={32}
              maxWidth={theme.space(25)}
              onClick={() => {
                const shouldShowUpsellModal =
                  !hasTwilioNumber && isLiveReceptionistOnly

                if (shouldShowUpsellModal) {
                  return showUpsellModal()
                } else if (props.onClickConnect) {
                  return props.onClickConnect()
                }
              }}
            />
          )}
          {(!props.connectable || (props.connectable && props.connected)) && (
            <StyledToggleContainer enabled={enabled}>
              <div>Send Instant Response</div>
              <Toggle
                dataCy={`settings-instant-responders-${leadSourceName}-IR-toggle-${
                  enabled ? 'disable' : 'enable'
                }`}
                disabled={isToggleDisabled}
                checked={isToggleDisabled ? false : enabled}
                onChange={(e) => {
                  onEnablementChange?.(e.target.checked)
                }}
              />
            </StyledToggleContainer>
          )}
        </StyledHeaderLeftItemsContainer>
      </StyledHeader>
      <StyledBody
        enabled={
          enabled &&
          !isToggleDisabled &&
          (!props.connectable || (props.connectable && props.connected))
        }
        bodyHeight={contentHeight}
      >
        <StyledContent ref={contentRef} isDesktop={isDesktop}>
          <div>
            <StyledTextboxTitle>
              {messageLabel
                ? messageLabel
                : `Message sent to incoming ${leadSourceName} leads:`}
            </StyledTextboxTitle>
            <StyledTextbox
              rows={5}
              cols={isDesktop ? 60 : 40}
              maxLength={maxLength}
              placeholder={defaultMessage}
              value={textboxMessage}
              onChange={(e) => setTextboxMessage(e.target.value)}
            />
            {maxLength && (
              <StyledMaxChar>
                Characters: {textboxMessage.length}/{maxLength}
              </StyledMaxChar>
            )}
            {localStorage.getItem('SP_ENABLE_THUMBTACK_METHOD') &&
              informationMessage && <InfoCard content={informationMessage} />}
            <StyledButtonsContainer>
              <div>
                <Button
                  action="primary"
                  maxHeight={32}
                  label="Update Response"
                  baseDataAttribute={`settings-instant-responders-${leadSourceName}-updateIR`}
                  disabled={
                    !!textboxMessage ? textboxMessage === message : !message
                  }
                  onClick={() =>
                    onUpdateMessage?.(
                      textboxMessage === '' ? null : textboxMessage
                    )
                  }
                />
              </div>
              {message && (
                <Button
                  displayAsText
                  icon={RestoreIcon}
                  label={
                    isRestoringMessage
                      ? 'Restoring default message...'
                      : 'Restore default message'
                  }
                  baseDataAttribute={`settings-instant-responders-${leadSourceName}-restoreIR`}
                  onClick={handleRestoreMessage}
                  style={{
                    fontSize: '1.4rem',
                    margin: `${theme.space(2)} ${theme.space(2)}`,
                  }}
                />
              )}
            </StyledButtonsContainer>
          </div>
          <div>
            {props.connectable && (
              <Button
                action="danger"
                maxHeight={32}
                label={
                  props.isDisconnecting ? 'Disconnecting...' : 'Disconnect'
                }
                loading={props.isDisconnecting}
                onClick={props.onClickDisconnect}
                baseDataAttribute={`settings-instant-responders-${leadSourceName}-disconnect`}
              />
            )}
          </div>
        </StyledContent>
      </StyledBody>
    </StyledContainer>
  )
}

export default LeadInstantResponseSettings
