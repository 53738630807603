import React, { memo } from 'react'
import styled from 'styled-components'

import { formatPhoneNumber } from 'src/utils'
import { ConversationEventResourceType } from 'src/client/interfaces/Conversations'

const StyledContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledSection = styled.div<{
  removeBottom?: boolean
}>(({ removeBottom, theme }) => ({
  marginBottom: removeBottom ? 0 : theme.space(5),
}))

const StyledLabel = styled.strong((props) => ({ margin: 0 }))

const StyledText = styled.p<{ inline?: boolean }>(({ inline }) => ({
  margin: 0,
  display: inline ? 'inline' : 'initial',
}))

const StyledLink = styled.a((props) => ({ margin: 0, display: 'block' }))

const StyledSubSection = styled.span((props) => ({
  margin: 0,
  display: 'block',
}))

interface Props {
  message: ConversationEventResourceType<'THUMBTACK'>
  contactName: string
  phoneNumber?: string
}

const ThumbtackLeadContent: React.FC<Props> = memo(
  ({ message, contactName, phoneNumber }) => {
    const { thumbtackEventData } = message

    if (!thumbtackEventData.lead) return null

    const { lead } = thumbtackEventData

    return (
      <StyledContainer>
        <StyledSection>
          <StyledLabel>{lead.title}</StyledLabel>
        </StyledSection>
        <StyledSection>
          <StyledLabel>{contactName}</StyledLabel>
          {phoneNumber && (
            <StyledLink href={`tel:${phoneNumber}`}>
              {formatPhoneNumber(phoneNumber)}
            </StyledLink>
          )}
        </StyledSection>
        <StyledSection removeBottom={!lead.description}>
          <StyledSubSection>
            <StyledLabel>Lead Type: </StyledLabel>
            <StyledText inline>{lead.category}</StyledText>
          </StyledSubSection>
        </StyledSection>
        <StyledSection removeBottom>
          <StyledText>{lead.description}</StyledText>
        </StyledSection>
      </StyledContainer>
    )
  }
)

export default ThumbtackLeadContent
