import { useCallback, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FacebookLogin, {
  SuccessResponse,
} from '@greatsumini/react-facebook-login'
import styled from 'styled-components'

import LeadInstantResponseSettings from './LeadInstantResponseSettings'
import DisconnectConfirmationModal from '../DisconnectConfirmationModal'
import { useInstantResponder } from 'src/containers/Settings/InstantResponders/hooks'
import useAccountContext from 'src/contexts/AccountContext'
import Constants from 'src/lib/Constants'
import { Button } from 'src/stories/Button'
import { Col, Row } from 'src/stories/Layout'
import { generateLocationUrl } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import logger from 'src/utils/logger'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import useConnectFacebookMutation from 'src/client/hooks/mutations/useConnectFacebookMutation'
import useDisconnectFacebookMutation from 'src/client/hooks/mutations/useDisconnectFacebookMutation'

const HiddenFbButton = styled.div(() => ({
  display: 'none',
}))

const StyledModalText = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  marginBottom: theme.space(12),
  marginTop: 0,
}))

interface FacebookInstantResponseSettingsProps {
  customIR: string | null
  isConnected: boolean
  isEnabled: boolean
  integrationStatus?: string
  integrationUpdatedAt: string | null
}

const FacebookInstantResponseSettings: React.FC<
  FacebookInstantResponseSettingsProps
> = ({
  customIR,
  isConnected,
  isEnabled,
  integrationStatus,
  integrationUpdatedAt,
}) => {
  const navigate = useNavigate()
  const [isConnecting, setIsConnecting] = useState(false)
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const { locationId, merchantId } = useParams<UseLocationRouteParams>()
  const { showModal, closeModal } = useModalNotificationsContext()
  const { mutateAsync: mutateConnectFacebook } = useConnectFacebookMutation()
  const { mutateAsync: mutateDisconnectFacebook } =
    useDisconnectFacebookMutation()
  const { locations, handleRefetchUserLocations } = useAccountContext()
  const activeLocation = locations?.find((l) => l.locationId === +locationId!)
  const fbRef = useRef<HTMLButtonElement | null>(null)

  const { customIRMessage, isToggleEnabled, onToggle, onUpdateMessage } =
    useInstantResponder({
      customIR,
      isEnabled,
      locationId: activeLocation?.locationId,
      refetchLocationFn: handleRefetchUserLocations,
      source: 'facebook',
    })

  const connectFacebook = async (userInfo: SuccessResponse) => {
    if (!userInfo.accessToken) {
      logger.debug('Facebook Instant Responder - no access token')

      return
    }

    setIsConnecting(true)

    await mutateConnectFacebook({
      locationId: +locationId!,
      userToken: userInfo.accessToken,
    })

    setIsConnecting(false)
  }

  const handleConnect = useCallback(() => {
    if (activeLocation?.facebookUrl) {
      fbRef.current?.click()
    } else {
      const businessSettingsSection = generateLocationUrl(
        merchantId!,
        locationId!,
        '/settings/review-sites'
      )

      const modalBody = (
        <>
          <Row>
            <Col>
              <StyledModalText>
                You haven't added a Facebook page yet! Please go to your
                settings page to add one.
              </StyledModalText>
            </Col>
          </Row>

          <Row direction="row-reverse">
            <Col w={4}>
              <Button
                label="Connect"
                onClick={() => {
                  closeModal()
                  navigate(businessSettingsSection)
                }}
                href={businessSettingsSection}
              />
            </Col>
            <Col w={4}>
              <Button action="secondary" label="Cancel" onClick={closeModal} />
            </Col>
          </Row>
        </>
      )

      showModal({
        title: 'Connect Facebook',
        customBody: modalBody,
        height: 'auto',
        hideActionButtons: true,
      })
    }
  }, [activeLocation, closeModal, locationId, merchantId, navigate, showModal])

  const handleConfirmDisconnect = useCallback(async () => {
    if (!activeLocation) return

    closeModal()

    setIsDisconnecting(true)

    await mutateDisconnectFacebook(+locationId!)

    setIsDisconnecting(false)
  }, [activeLocation, closeModal, locationId, mutateDisconnectFacebook])

  const handleDisconnect = useCallback(() => {
    showModal({
      title: 'Disconnect Facebook',
      headerColor: 'critical',
      hideActionButtons: false,
      modalActionsOptions: {
        callToAction: {
          label: 'Disconnect',
          action: 'danger',
          onClick: handleConfirmDisconnect,
        },
      },
      customBody: <DisconnectConfirmationModal leadSourceName="Facebook" />,
      height: 'auto',
    })
  }, [handleConfirmDisconnect, showModal])

  return (
    <>
      <LeadInstantResponseSettings
        connectable
        connected={isConnected}
        defaultMessage={Constants.INSTANT_RESPONSES.facebook.defaultMessage}
        enabled={isToggleEnabled}
        isConnecting={isConnecting}
        isDisconnecting={isDisconnecting}
        leadSourceName="Facebook"
        message={customIRMessage}
        onClickConnect={handleConnect}
        onEnablementChange={onToggle}
        onUpdateMessage={onUpdateMessage}
        onClickDisconnect={handleDisconnect}
        integrationStatus={integrationStatus}
        integrationUpdatedAt={integrationUpdatedAt}
      />

      <HiddenFbButton>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
          onSuccess={connectFacebook}
          scope={Constants.facebookLoginPermissions.join()}
          render={(renderProps) => (
            <button onClick={renderProps.onClick} ref={fbRef} />
          )}
        />
      </HiddenFbButton>
    </>
  )
}

export default FacebookInstantResponseSettings
