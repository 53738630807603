import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Constants from 'src/lib/Constants'

const ThumbtackOauthRedirect: React.FC = () => {
  const { search } = useLocation()

  useEffect(() => {
    const query = new URLSearchParams(search)
    const state = query.get('state')

    if (state === 'THUMBTACK_MOBILE') {
      const { schemes } = Constants.MOBILE_APP
      const env = process.env.REACT_APP_ENVIRONMENT as
        | keyof typeof schemes
        | undefined

      const mobileAppScheme = schemes[env ?? 'development']

      window.location.href = `${mobileAppScheme}://?${search}`
    }
  }, [search])

  return null
}

export default ThumbtackOauthRedirect
