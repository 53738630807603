import React from 'react'
import { Button } from 'src/stories/Button'
import Link from 'src/stories/Link'
import styled from 'styled-components'

const StyledConfirmationOptions = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    display: 'flex',
    columnGap: theme.space(4),
    flexDirection: isDesktop ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: 'right',
    rowGap: theme.space(4),
    width: '100%',
    marginLeft: theme.space(isDesktop ? 4 : 0),
  })
)

const StyledConfirmationLabel = styled.p(({ theme }) => ({
  fontSize: '1.6rem',
  lineHeight: '2rem',
  margin: theme.space(0),
}))

const StyledConfirmationButtons = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    display: 'flex',
    gap: theme.space(3),
    width: isDesktop ? theme.space(37) : '100%',
  })
)

interface Props {
  label: string
  reviewSiteUrl: string
  isDesktop: boolean
  isLoading: boolean
  onClickYes: () => void
  onClickNo: () => void
}

const ConfirmReviewSite: React.FC<Props> = ({
  label,
  reviewSiteUrl,
  isDesktop,
  isLoading,
  onClickYes,
  onClickNo,
}) => {
  return (
    <StyledConfirmationOptions isDesktop={isDesktop}>
      <StyledConfirmationLabel>
        Is this your{' '}
        <Link
          baseDataAttribute={`${label}-review-page`}
          href={reviewSiteUrl}
          target="_blank"
        >{`${label} review page`}</Link>
        ?
      </StyledConfirmationLabel>
      <StyledConfirmationButtons isDesktop={isDesktop}>
        <Button label="Yes" loading={isLoading} onClick={onClickYes} />
        <Button
          label="No"
          action="secondary"
          loading={isLoading}
          onClick={onClickNo}
        />
      </StyledConfirmationButtons>
    </StyledConfirmationOptions>
  )
}

export default ConfirmReviewSite
