import { datadogLogs } from '@datadog/browser-logs'
import pino from 'pino'
import { UnknownObject } from './interfaces'

datadogLogs.init({
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN!,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  env: process.env.REACT_APP_ENVIRONMENT,
  service: 'web-app-frontend-WebAppFrontend',
  version: process.env.REACT_APP_SOURCE_MAP_VERSION,
})

const pinoLogger = pino({
  level: 'debug',
  browser: {
    asObject: false,
  },
  transport: {
    targets: [
      {
        target: 'pino-datadog-transport',
        options: {
          ddClientConf: {
            authMethods: {
              apiKeyAuth: process.env.REACT_APP_DD_CLIENT_TOKEN,
            },
          },
        },
        level: 'error',
      },
    ],
  },
})

const logger = {
  debug: (message: string, optionalParams?: UnknownObject) => {
    optionalParams
      ? pinoLogger.debug(optionalParams, message)
      : pinoLogger.debug(message)
  },

  info: (message: string, optionalParams?: UnknownObject) => {
    optionalParams
      ? pinoLogger.info(optionalParams, message)
      : pinoLogger.info(message)
  },

  error: (message: string, optionalParams?: UnknownObject) => {
    optionalParams
      ? pinoLogger.error(optionalParams, message)
      : pinoLogger.error(message)
  },
}

export default logger
