import React, { useEffect, useState } from 'react'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Button } from 'src/stories/Button'
import styled, { useTheme } from 'styled-components'
import OptionButton from 'src/components/Settings/ReviewSites/ReviewSiteItem/OptionsButton'
import Input from 'src/stories/Input'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ConfirmReviewSite from 'src/components/Settings/ReviewSites/ReviewSiteItem/ConfirmReviewSite'
import useConfirmReviewSiteMutation from 'src/client/hooks/mutations/useConfirmReviewSiteMutation'
import HelperTooltip, { HelperTooltipWrapper } from 'src/stories/HelperTooltip'
import SectionContainer, { SectionAction } from 'src/stories/SectionContainer'
import { StyledForm } from 'src/components/Settings/common/layout'

const ReviewSiteLabel = styled.p<{ shouldGrow: boolean }>(({ shouldGrow }) => ({
  lineHeight: '2rem',
  fontSize: '1.6rem',
  ...(shouldGrow ? { flexGrow: 1 } : {}),
}))

const HeadingContainer = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    display: 'flex',
    flexDirection: isDesktop ? 'row' : 'column',
    justifyContent: 'space-between',
    alignItems: isDesktop ? 'center' : 'flex-start',
    ...(isDesktop ? {} : { gap: theme.space(4) }),
  })
)

const ReviewSite = styled.div<{ fullWidth?: boolean }>(
  ({ theme, fullWidth }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.space(5),
    ...(fullWidth ? { width: '100%' } : {}),
  })
)

const ConnectedLabel = styled.div(({ theme }) => ({
  margin: 0,
  color: theme.colors.positive,
  fontSize: '1.2rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'end',
  gap: theme.space(2),
  flexGrow: 1,
}))

const Dot = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.positive_15,
  width: theme.space(4),
  height: theme.space(4),
  borderRadius: theme.constants.largeBorderRadius,
}))

const InnerDot = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.positive,
  width: theme.space(2),
  height: theme.space(2),
  borderRadius: theme.constants.largeBorderRadius,
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

const reviewSiteFormSchema = yup.object({
  reviewSiteUrl: yup.string().default(''),
})

type ReviewSiteFormData = yup.InferType<typeof reviewSiteFormSchema>

interface Props {
  baseDataAttribute: string
  label: string
  isConnected?: boolean
  shouldDisplayConfirmationBanner?: boolean
  locationId: number
  reviewSiteUrl?: string
  reviewSiteId?: number
  pageUrl?: string
  iconComponent: React.FC<React.SVGProps<SVGSVGElement>>
  inputLabel: string
  inputTooltip: JSX.Element
  inputHelper?: JSX.Element
  onSubmit: (data: ReviewSiteFormData) => Promise<void>
}

const ReviewSiteItem: React.FC<Props> = ({
  baseDataAttribute,
  iconComponent: IconComponent,
  label,
  isConnected = false,
  pageUrl,
  shouldDisplayConfirmationBanner = false,
  locationId,
  reviewSiteUrl,
  reviewSiteId,
  inputLabel,
  inputHelper,
  inputTooltip,
  onSubmit,
}) => {
  const theme = useTheme()
  const [showChildren, setShowChildren] = useState(false)
  const {
    mutateAsync: mutateConfirmReviewSites,
    isLoading: isLoadingConfirmReviewSite,
  } = useConfirmReviewSiteMutation()
  const screenSizes = useScreenSizes()

  const isDesktop = screenSizes.isSmallScreen

  const {
    formState: { errors: formErrors, isSubmitting },
    handleSubmit,
    register,
    reset,
  } = useForm<ReviewSiteFormData>({
    reValidateMode: 'onChange',
    resolver: yupResolver(reviewSiteFormSchema),
  })

  useEffect(() => {
    reset({ reviewSiteUrl: pageUrl })
  }, [reset, pageUrl])

  const SetupOrCancelButton = (
    <Button
      label="Set up"
      baseDataAttribute={`${baseDataAttribute}-set-up`}
      onClick={() => setShowChildren(true)}
      style={
        isDesktop
          ? {
              width: theme.space(27),
              height: theme.space(8),
            }
          : {
              width: '100%',
            }
      }
    />
  )

  const showConnectedLabel =
    isConnected &&
    !shouldDisplayConfirmationBanner &&
    (!showChildren || isDesktop)

  return (
    <SectionContainer
      variant="primary"
      data-cy={`${baseDataAttribute}-container`}
      action={
        showChildren ? (
          <SectionAction
            label="Cancel"
            onClick={() => setShowChildren(false)}
            style={{
              height: theme.space(13),
            }}
          />
        ) : undefined
      }
    >
      <HeadingContainer
        isDesktop={isDesktop}
        data-cy={`${baseDataAttribute}-heading-container`}
      >
        <ReviewSite
          data-cy={`${baseDataAttribute}-rs`}
          fullWidth={showChildren}
        >
          <IconComponent width={theme.space(11)} height={theme.space(11)} />
          <ReviewSiteLabel
            shouldGrow={!showConnectedLabel}
            data-cy={`${baseDataAttribute}-rs-label`}
          >
            {label}
          </ReviewSiteLabel>
          {showConnectedLabel && (
            <ConnectedLabel data-cy={`${baseDataAttribute}-rs-connected-label`}>
              <Dot>
                <InnerDot />
              </Dot>
              Connected
            </ConnectedLabel>
          )}
        </ReviewSite>
        {shouldDisplayConfirmationBanner &&
          !showChildren &&
          reviewSiteUrl &&
          reviewSiteId && (
            <ConfirmReviewSite
              label={label}
              reviewSiteUrl={reviewSiteUrl}
              isDesktop={isDesktop}
              isLoading={isLoadingConfirmReviewSite}
              onClickYes={() =>
                mutateConfirmReviewSites({ locationId, reviewSiteId })
              }
              onClickNo={() => setShowChildren(true)}
            />
          )}
        {isConnected && !showChildren && !shouldDisplayConfirmationBanner && (
          <OptionButton
            baseDataAttribute={`${baseDataAttribute}-options`}
            isDesktop={isDesktop}
            onGoToReviewSite={() => {
              if (reviewSiteUrl) window.open(reviewSiteUrl)
            }}
            onManage={() => {
              setShowChildren(true)
            }}
          />
        )}
        {!showChildren && !isConnected && SetupOrCancelButton}
      </HeadingContainer>
      {showChildren && (
        <StyledForm
          onSubmit={handleSubmit(async (data) => {
            try {
              await onSubmit(data)
            } catch {
              // No-op
            } finally {
              reset()
              setShowChildren(false)
            }
          })}
          style={{ paddingTop: theme.space(4) }}
        >
          <Input
            data-cy={`${baseDataAttribute}-input`}
            label={
              <HelperTooltipWrapper>
                <span>{inputLabel}: </span>
                <HelperTooltip>{inputTooltip}</HelperTooltip>
              </HelperTooltipWrapper>
            }
            errors={formErrors}
            disabled={isSubmitting}
            {...register('reviewSiteUrl')}
          />
          {inputHelper}
          <Button
            size="medium"
            label="Save"
            type="submit"
            disabled={isSubmitting}
            baseDataAttribute={`${baseDataAttribute}-form-submit`}
            style={{
              marginTop: theme.space(2),
              ...(isDesktop
                ? {
                    width: theme.space(31),
                    height: theme.space(12),
                  }
                : {}),
            }}
          />
        </StyledForm>
      )}
    </SectionContainer>
  )
}

export default ReviewSiteItem
