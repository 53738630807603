import { useMutation } from '@tanstack/react-query'
import logger from 'src/utils/logger'
import { toast } from 'react-toastify'

import { ContactsLegacyResource } from 'src/client'

const useSendContactReviewRequestMutation = () => {
  return useMutation(
    ({
      contactId,
      channelId,
      reviewSiteName,
      method,
      productOrigin,
    }: {
      contactId: number
      channelId: number
      reviewSiteName: string
      method?: 'sms' | 'email'
      productOrigin?: 'messagingHub' | 'contactsPage'
    }) =>
      ContactsLegacyResource.sendReviewRequest(
        contactId,
        channelId,
        reviewSiteName,
        method,
        productOrigin
      ),
    {
      onError: (error) => {
        toast.error(
          `There was an error trying to send the review request to the contact. Please try again.`
        )
        logger.error('WAF useSendContactReviewRequestMutation - error', {
          error,
        })
      },
    }
  )
}

export default useSendContactReviewRequestMutation
