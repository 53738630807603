import React from 'react'

import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { GridContainer, WidgetCard } from '../styled'
import { ContentBodySection } from './styled'

interface ContentSectionElement {
  element: JSX.Element
  columnStart?: number
  columnEnd?: number
  rowStart?: number
  rowEnd?: number
}

export interface ContentSectionData {
  elements: (JSX.Element | ContentSectionElement)[]
  columns?: number
}

interface ContentSectionProps {
  baseDataCy: string
  data: ContentSectionData
}

const ContentSection: React.FC<ContentSectionProps> = ({
  baseDataCy,
  data,
}) => {
  const {
    isLargeScreen: isLarge,
    isMediumScreen: isMedium,
    isSmallScreen: isSmall,
  } = useScreenSizes()

  const columnCount = isLarge
    ? data.columns || data.elements.length
    : isMedium
    ? data.elements.length > 1
      ? 2
      : 1
    : 1

  return (
    <ContentBodySection data-cy={baseDataCy} $isSmallDesktop={isSmall}>
      <GridContainer
        $columns={columnCount}
        $isSmallScreen={isSmall}
        data-cy={baseDataCy + '-grid'}
      >
        {data.elements.map((card, idx) => {
          const el = card as ContentSectionElement

          return (
            <WidgetCard
              key={idx}
              data-cy={baseDataCy + '-widget'}
              $columnStart={el.columnStart}
              $columnEnd={el.columnEnd}
              $rowStart={el.rowStart}
              $rowEnd={el.rowEnd}
            >
              {el.element || card}
            </WidgetCard>
          )
        })}
      </GridContainer>
    </ContentBodySection>
  )
}

export default ContentSection
