import { useMemo } from 'react'
import { ValidColW } from 'src/stories/Layout'
import useScreenSizes from '../stories/hooks/useScreenSizes'

export interface UseWAFLayout {
  contentValidColWidth: ValidColW
  sidebarValidColWidth: ValidColW
  isDesktop: boolean
  isMediumDesktop: boolean
  isLargeDesktop: boolean
  isExtraLargeDesktop: boolean
}

export const useWAFLayout = (): UseWAFLayout => {
  const { isSmallScreen, isMediumScreen, isLargeScreen, isExtraLargeScreen } =
    useScreenSizes()

  const [sidebarValidColWidth, contentValidColWidth] = useMemo<
    [ValidColW, ValidColW]
  >(() => {
    if (isExtraLargeScreen && isLargeScreen) {
      return [3, 9]
    } else if (isLargeScreen && isMediumScreen) {
      return [4, 8]
    } else if (isMediumScreen) {
      return [5, 7]
    } else {
      return [6, 6]
    }
  }, [isMediumScreen, isLargeScreen, isExtraLargeScreen])

  return {
    isDesktop: isSmallScreen,
    isMediumDesktop: isMediumScreen,
    isLargeDesktop: isLargeScreen,
    isExtraLargeDesktop: isExtraLargeScreen,
    sidebarValidColWidth,
    contentValidColWidth,
  }
}
