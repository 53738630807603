import {
  ConversationResource,
  PublicReviewsDTO,
} from 'src/client/interfaces/Conversations'

export type ConversationListItem = ConversationResource | PublicReviewsDTO

export const isConversationResource = (
  value: ConversationListItem
): value is ConversationResource => {
  return (
    'resourceType' in value && value.resourceType === 'ConversationResource'
  )
}

export const isPublicReview = (
  value: ConversationListItem
): value is PublicReviewsDTO => {
  return !isConversationResource(value)
}
