import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import Constants from 'src/lib/Constants'
import { getCookie } from 'src/utils'

import { UnknownObject } from 'src/utils/interfaces'

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST,
})

export const axiosRequestInterceptor = (
  request: AxiosRequestConfig<unknown | UnknownObject>
) => {
  const { headers } = request

  const bearer = getCookie(`BEARER-${process.env.REACT_APP_ENVIRONMENT!}`)

  if (headers) {
    headers[Constants.HeaderKeys.bearer] = `Bearer ${bearer}`
  }

  return request
}

client.interceptors.request.use(axiosRequestInterceptor)

client.interceptors.response.use(
  (response: AxiosResponse<unknown | UnknownObject>) => {
    const { data } = response

    return data
  },
  async (error: unknown) => {
    // No 401 redirections at this levels. It's handled by the AuthContext
    return Promise.reject((error as AxiosError).response)
  }
)

export default client
