import React from 'react'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import styled from 'styled-components'

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  fontWeight: '500',
  marginTop: theme.space(0),
  marginBottom: theme.space(3),
}))

export const StyledCopy = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(6),
}))

const StyledButtonContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(5),
}))

const StyledButtonWrapper = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    width: isDesktop ? theme.space(42) : '100%',
    marginTop: theme.space(8),
  })
)

interface SmsRegistrationLayoutProps {
  title: string
  buttonBack?: React.ReactNode
  buttonNext: React.ReactNode
}

const SmsRegistrationLayout: React.FCWithChildren<
  SmsRegistrationLayoutProps
> = ({ title, buttonBack, buttonNext, children }) => {
  const { isLargeScreen: isDesktop } = useScreenSizes()

  return (
    <>
      <StyledTitle>{title}</StyledTitle>
      {children}
      <StyledButtonContainer>
        {buttonBack && (
          <StyledButtonWrapper isDesktop={isDesktop}>
            {buttonBack}
          </StyledButtonWrapper>
        )}
        <StyledButtonWrapper isDesktop={isDesktop}>
          {buttonNext}
        </StyledButtonWrapper>
      </StyledButtonContainer>
    </>
  )
}

export default SmsRegistrationLayout
