import { useEffect, useMemo } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import Header from 'src/components/Header'
import Reviews from 'src/containers/Reviews'
import Settings from 'src/containers/Settings'
import Insights from 'src/containers/Insights'
import Campaigns from 'src/containers/Campaigns'
import LegacyPages from 'src/containers/LegacyPages'
import ContactsProto from 'src/containers/Contacts'
import useAuthContext from 'src/contexts/AuthContext'
import Integrations from 'src/containers/Integrations'
import MessagingHub from 'src/containers/MessagingHub'
import { MhContextProvider } from 'src/contexts/MhContext'
import useAccountContext from 'src/contexts/AccountContext'
import { isInternalUser as isInternalUserFn } from 'src/utils'
import { InsightsContextProvider } from 'src/contexts/InsightsContext'
import MultiLocationHeader from 'src/components/Header/MultiLocationHeader'
import useNavigationInterceptorContext from 'src/contexts/NavigationInterceptor'
import { ContactsContextProvider } from 'src/contexts/ContactsContext'
import DeletedContacts from 'src/containers/DeletedContacts'
import Constants from 'src/lib/Constants'
import TermsUpdateBanner from 'src/components/Insights/Banners/TermsUpdateBanner'

export const locationSlug = {
  'messaging-hub': 'Messaging Hub',
  dashboard: 'Insights',
  insights: 'Insights',
  'customer-contacts': 'Contacts',
  emails: 'Reviews',
  campaigns: 'Campaigns',
  settings: 'Settings',
  integrations: 'Integrations',
}

const LocationRoutes: React.FC = () => {
  const { user } = useAuthContext()
  const { locations } = useAccountContext()
  const { pathname } = useLocation()
  const { setPageTitle } = useNavigationInterceptorContext()

  const currentLocationSlug = `${pathname.split('/')[4]}`

  useEffect(() => {
    const slug = locationSlug[currentLocationSlug as keyof typeof locationSlug]

    if (slug) {
      setPageTitle(`${slug} - ${Constants.Branding.companyName}`)
    } else {
      setPageTitle(Constants.Branding.companyName)
    }

    return () => {
      setPageTitle(Constants.Branding.companyName)
    }
  }, [currentLocationSlug, setPageTitle])

  // Path should be used to define new routes the strangling Glaze pages.
  // And example can be found at ./MultiLocationsRoute.tsx
  // const { path } = useRouteMatch()
  const campaignRoutes = useMemo(
    () => [
      '/campaigns/*',
      '/campaigns/all',
      'scheduled-campaigns/:scheduledCampaignType', // scheduledCampaignType = scheduled | drafts | sent
      '/campaigns/lifecycle/:lifecyleType', // lifecycleType = prospect | active | inactive
      '/campaigns/create-your-own/new',
      '/campaigns/content-library',
    ],
    []
  )

  const settingsRoutes = useMemo(
    () => ['/settings/*', '/email-customization'],
    []
  )

  const integrationRoutes = useMemo(() => ['/integrations/connect'], [])

  const isInternalUser = useMemo(() => isInternalUserFn(user), [user])

  const isMultiLocation = useMemo(
    () => (locations || []).length > 1 || isInternalUser,
    [locations, isInternalUser]
  )

  return user ? (
    <>
      <TermsUpdateBanner />
      {isMultiLocation && (
        <MultiLocationHeader isInternalUser={isInternalUser} />
      )}
      <Header pageType="Location" />

      <Routes>
        <Route
          path={'emails/:emailType'}
          element={<Reviews isMultiLocation={isMultiLocation} />}
        />

        <Route
          path="/dashboard"
          element={<Navigate to="../insights" replace />}
        />

        <Route
          path="/insights"
          element={
            <InsightsContextProvider>
              <Insights isMultiLocation={isMultiLocation} />
            </InsightsContextProvider>
          }
        />

        <Route
          path={'/messaging-hub'}
          element={
            <MhContextProvider>
              <MessagingHub isMultiLocation={isMultiLocation} />
            </MhContextProvider>
          }
        />

        {campaignRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            element={<Campaigns isMultiLocation={isMultiLocation} />}
          />
        ))}

        <Route
          path={'/customer-contacts-proto'}
          element={
            <ContactsContextProvider>
              <ContactsProto isMultiLocation={isMultiLocation} />
            </ContactsContextProvider>
          }
        />

        <Route
          path={'/deleted-contacts-proto'}
          element={<DeletedContacts isMultiLocation={isMultiLocation} />}
        />

        {settingsRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            element={<Settings isMultiLocation={isMultiLocation} />}
          />
        ))}

        {integrationRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            element={<Integrations isMultiLocation={isMultiLocation} />}
          />
        ))}

        <Route
          path="*"
          element={<LegacyPages withMultiLocationHeader={isMultiLocation} />}
        />
      </Routes>
    </>
  ) : null
}

export default LocationRoutes
