import {
  ConversationResource,
  conversationEventTypePredicate,
} from 'src/client/interfaces/Conversations'
import logger from '../../utils/logger'
import { ConversationListItem, isConversationResource } from './types'
import { displayUserFriendlyMessage } from 'src/contexts/MhContext/utils'

export const createZeroState = (
  userFirstName?: string,
  isConversationRead?: boolean
): ConversationResource => ({
  id: -1,
  resourceType: 'ConversationResource',
  contactDisplayName: 'Hibu',
  isConversationArchived: false,
  isConversationDeleted: false,
  isConversationRead: !!isConversationRead,
  contactId: -1,
  contact: {
    id: -1,
    addedAt: new Date().toISOString(),
    channels: [],
    segments: [],
  },
  mostRecentEvent: {
    id: -1,
    channelId: null,
    eventDirection: 'incoming',
    eventType: 'SMS' /* ignored */,
    isAutoResponse: false,
    hasMedia: false,
    hasPhoneCallRecording: false,
    media: [],
    message: `${
      userFirstName ? `Hi, ${userFirstName}. ` : ''
    }Welcome to your messaging hub, a central part of your account.`,
    timestamp: new Date(),
  },
})

export const reduceSingleReviewFeedbackConversationList = (
  conversationsList: ConversationListItem[]
) => {
  let counters: Record<string, true> = {}
  const demoList: ConversationListItem[] = []

  conversationsList.forEach((l) => {
    if (isConversationResource(l)) {
      if (conversationEventTypePredicate(l.mostRecentEvent, 'REVIEW')) {
        if (!counters[l.mostRecentEvent.eventType]) {
          counters[l.mostRecentEvent.eventType] = true
          demoList.push(l)
        }
      } else if (
        conversationEventTypePredicate(l.mostRecentEvent, 'FEEDBACK')
      ) {
        const outcome =
          (l.mostRecentEvent.feedbackEventData.rating ?? 0) > 3
            ? 'Positive'
            : 'Negative'

        if (!counters[`${outcome}${l.mostRecentEvent.eventType}`]) {
          counters[`${outcome}${l.mostRecentEvent.eventType}`] = true
          demoList.push(l)
        }
      } else {
        demoList.push(l)
      }
    } else {
      if (!counters['publicReviews']) {
        counters['publicReviews'] = true
        demoList.push(l)
      }
    }
  })

  return demoList
}

export const countUnreadConversations = (
  conversationsList: ConversationResource[]
): number =>
  conversationsList.filter((conversation) => !conversation.isConversationRead)
    .length

export const isAnyConversationUnread = (
  conversationList: ConversationResource[]
): boolean =>
  conversationList.some(({ isConversationRead }) => !isConversationRead)

export const getConversationItemTimestamp = (
  conversationItem: ConversationListItem
) => {
  return isConversationResource(conversationItem)
    ? conversationItem.mostRecentEvent.timestamp
      ? new Date(conversationItem.mostRecentEvent.timestamp)
      : new Date()
    : new Date(conversationItem.timestamp)
}

export const getConversationItemNameTitle = (
  conversationItem: ConversationListItem
) => {
  return isConversationResource(conversationItem)
    ? conversationItem.contactDisplayName
    : conversationItem.authorName
}

export const getConversationItemPreviewText = (
  conversationItem: ConversationListItem
) => {
  if (isConversationResource(conversationItem)) {
    const { mostRecentEvent } = conversationItem

    if (conversationEventTypePredicate(mostRecentEvent, 'FEEDBACK')) {
      return ''
    }

    if (conversationEventTypePredicate(mostRecentEvent, 'THUMBTACK')) {
      if (mostRecentEvent.eventDirection === 'outgoing') {
        return mostRecentEvent.message ?? ''
      }

      return mostRecentEvent.thumbtackEventData?.lead
        ? mostRecentEvent.thumbtackEventData.lead.title
        : mostRecentEvent.thumbtackEventData.message?.text ?? ''
    }

    return mostRecentEvent.message
  } else {
    return conversationItem.reviewText
  }
}

export const getConversationItemPreviewErrorText = (
  conversationItem: ConversationListItem
) => {
  if (!isConversationResource(conversationItem)) return false

  const reason = conversationItem.mostRecentEvent.failureDetails?.reason

  return reason && displayUserFriendlyMessage(reason).message
}

export const playNotificationAudio = async (
  notificationAudio: HTMLAudioElement
) => {
  return notificationAudio.play().catch((err: Error) => {
    const browserConsentErrors = [
      "play() failed because the user didn't interact with the document first",
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
      'The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
    ]

    if (browserConsentErrors.some((ce) => err.message.includes(ce))) {
      logger.info(
        'LocationContext - poll - notification sound not played due to consent error'
      )
    } else {
      throw err
    }
  })
}
