import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  differenceInMinutes,
  differenceInMonths,
  intlFormatDistance,
} from 'date-fns'

import {
  handleInflightErrors,
  handleUploadMedia,
  reportProcessDone,
} from 'src/contexts/MhContext/utils'
import logger from 'src/utils/logger'
import Constants from 'src/lib/Constants'
import { unFormatPhoneNumber } from 'src/utils'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { HandleSendMessage } from 'src/client/interfaces/Comms'
import { ErrorObject, Media } from 'src/client/interfaces/Common'
import {
  ContactsLegacyResource,
  ContactsResource,
  ConversationsLegacyResource,
} from 'src/client'
import { HandleTabConversationChangeParams } from 'src/containers/MessagingHub/types'

interface UseHandleOnSendParams {
  newConversationContact?: number
  newConversationContactChannelId?: number
  newConversationInputValue: string
  locationId?: string
  handleTabConversationChange: (
    params: HandleTabConversationChangeParams
  ) => void
  closeModal: () => void
}

export const useHandleOnSend = ({
  newConversationContact,
  newConversationContactChannelId,
  newConversationInputValue,
  locationId,
  closeModal,
  handleTabConversationChange,
}: UseHandleOnSendParams) => {
  const { refetch: refetchConversationsList } = useConversationsListContext()

  const handleOnSend = useCallback<HandleSendMessage>(
    async (inputText, medias, sendAs) => {
      const shouldCreateContact = !(
        newConversationContact && newConversationContactChannelId
      )
      const phoneNumber = unFormatPhoneNumber(newConversationInputValue)
      let channelId = newConversationContactChannelId
      let contactId = newConversationContact

      logger.debug('Handling onSend', { shouldCreateContact, phoneNumber })

      try {
        if (shouldCreateContact && phoneNumber) {
          const { newChannels, customerId } =
            await ContactsLegacyResource.create({
              locationId: parseInt(locationId ?? '', 10),
              contacts: [{ phoneNumber }],
            })

          contactId = customerId
          channelId = newChannels[0]?.id

          logger.debug('Created customer', {
            customerId,
            newChannelId: newChannels[0]?.id,
          })

          if (!newChannels[0] && locationId) {
            const { primaryPhoneChannelId } = await ContactsResource.getById(
              +locationId,
              customerId
            )

            logger.debug('Found channelId', { primaryPhoneChannelId })

            channelId = primaryPhoneChannelId
          }
        }

        if ((inputText || medias.length) && channelId && contactId) {
          if (sendAs?.type === 'feedback' && channelId) {
            await ContactsLegacyResource.sendFeedbackRequest(
              contactId,
              channelId,
              'sms'
            )
          } else if (sendAs?.type === 'review' && channelId) {
            await ContactsLegacyResource.sendReviewRequest(
              contactId,
              channelId,
              sendAs.reviewSiteName,
              'sms'
            )
          } else {
            let msgMedias: Media[] = []

            if (medias.length) {
              msgMedias = await handleUploadMedia(medias)
            }

            await ConversationsLegacyResource.sendMessage(
              inputText,
              msgMedias,
              channelId,
              contactId,
              Constants.COMMS.documentTypes.smsInteraction,
              Constants.COMMS.documentTypes.smsInteraction
            )
          }

          await refetchConversationsList()

          handleTabConversationChange({
            mode: 'conversation',
            value: contactId.toString(),
          })

          closeModal()
        }
      } catch (error) {
        if (!error) {
          logger.error(
            "There's been an error sending a message. error undefined (New Conversation)"
          )
          toast.error(
            'Message not sent, Something went wrong. Please try again.'
          )
        } else {
          handleInflightErrors(
            error as ErrorObject,
            "There's been an error sending a message. (New Conversation)",
            !!sendAs,
            false
          )
        }
      } finally {
        void reportProcessDone()
      }
    },
    [
      newConversationContact,
      newConversationContactChannelId,
      newConversationInputValue,
      locationId,
      refetchConversationsList,
      handleTabConversationChange,
      closeModal,
    ]
  )

  return handleOnSend
}

export const useFormattedTimestamp = (date: Date) => {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date())
    }, 60_000)

    return () => void clearInterval(intervalId)
  }, [])

  return useMemo(() => {
    if (differenceInMinutes(currentDate, new Date(date)) < 1) return 'now'

    const numMonths = differenceInMonths(currentDate, new Date(date))
    const overrideDateUnitQuarter = numMonths >= 3 && numMonths < 12

    return intlFormatDistance(new Date(date), currentDate, {
      numeric: 'always',
      style: 'narrow',
      unit: overrideDateUnitQuarter ? 'month' : undefined,
    })
  }, [currentDate, date])
}
