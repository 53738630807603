import React from 'react'
import styled from 'styled-components'

const Section = styled.section(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(4),
}))

const Title = styled.p(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2rem',
  lineHeight: '3.2rem',
  fontWeight: '500',
  margin: 0,
}))

interface Props {
  title: string
  baseDataAttribute: string
}

const ConversionCampaignSectionLayout: React.FCWithChildren<Props> = ({
  children,
  title,
  baseDataAttribute,
}) => {
  return (
    <Section data-cy={baseDataAttribute}>
      <Title data-cy={`${baseDataAttribute}-title`}>{title}</Title>
      {children}
    </Section>
  )
}

export default ConversionCampaignSectionLayout
