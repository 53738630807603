import { isAfter } from 'date-fns'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import usePublicInformation from 'src/client/hooks/queries/usePublicInformationQuery'
import useUserFacingFeatures from 'src/client/hooks/queries/useUserFacingFeaturesQuery'
import { LocationLegacy } from 'src/client/interfaces/Common'
import Constants from 'src/lib/Constants'
import SectionContainer from 'src/stories/SectionContainer'
import { isFeatureCodeEnabled } from 'src/utils'
import PageLayout from 'src/components/WafLayout/PageLayout'
import { FacebookIcon, GoogleIcon, YelpIcon } from 'src/stories/assets'
import ReviewSiteItem from 'src/components/Settings/ReviewSites/ReviewSiteItem'
import Toggle from 'src/stories/Toggle'
import useUpdateLocationReviewSiteMutation from 'src/client/hooks/mutations/useUpdateLocationReviewSiteMutation'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import useToggleFeatureCodeMutation from 'src/client/hooks/mutations/useToggleFeatureCodeMutation'
import WarningCard from 'src/components/Settings/common/WarningCard'
import { useLocationContext } from 'src/contexts/LocationContext'
import useLocationReviewSites from 'src/client/hooks/queries/useLocationReviewSitesQuery'

const StyledToggleContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-between',
  gap: theme.space(3),
}))

const StyledToggleLabel = styled.label(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.colors.base_100,
}))

const FindGooglePlaceIdLink = styled.a(({ theme }) => ({
  textDecoration: 'none',
  color: theme.colors.primary_2,
  fontWeight: 500,
  lineHeight: '1.6rem',
  fontSize: '1.4rem',
  marginBottom: theme.space(2),
}))

const ReviewSites: React.FC = () => {
  const baseDataAttribute = 'settings-review-sites'
  const theme = useTheme()
  const { locationId, activeLocationV3 } = useLocationContext()

  const { data: publicInformation, isLoading: isLoadingPublicInformation } =
    usePublicInformation({
      locationId,
    })
  const { data: reviewSitesResponse, isLoading: isLoadingReviewSites } =
    useLocationReviewSites({
      locationId,
    })
  const { data: userFacingFeatures, isLoading: isLoadingUserFacingFeatures } =
    useUserFacingFeatures({
      locationId,
    })
  const { mutateAsync: updateLocationReviewSite } =
    useUpdateLocationReviewSiteMutation()
  const { mutate: toggleFeatureCode, isLoading: isLoadingToggleFeatureCode } =
    useToggleFeatureCodeMutation(locationId)

  const showLoading =
    isLoadingPublicInformation ||
    isLoadingUserFacingFeatures ||
    isLoadingReviewSites

  const locationFeatureCodes = {
    features: userFacingFeatures || [],
  } as LocationLegacy

  const hasPublishingEnabled = isFeatureCodeEnabled(
    Constants.FEATURE_CODES.publishPresence,
    locationFeatureCodes
  )
  const hasPhoneCaptureEnabled = isFeatureCodeEnabled(
    Constants.FEATURE_CODES.ivr,
    locationFeatureCodes
  )
  const hasPublishingPhoneCaptureEnabled = isFeatureCodeEnabled(
    Constants.FEATURE_CODES.publishForwardingNumber,
    locationFeatureCodes
  )
  const hasFacebookConnected = isFeatureCodeEnabled(
    Constants.FEATURE_CODES.facebookIntegration,
    locationFeatureCodes
  )
  const hasGoogleConnected = isFeatureCodeEnabled(
    Constants.FEATURE_CODES.googleIntegration,
    locationFeatureCodes
  )
  const socialMediaText =
    !hasFacebookConnected && !hasGoogleConnected
      ? 'social media profiles (once connected)'
      : hasFacebookConnected && hasGoogleConnected
      ? 'Facebook and Google profiles'
      : hasFacebookConnected
      ? 'Facebook profile'
      : 'Google profile'

  const isAfterReleaseDate = isAfter(
    activeLocationV3?.firstActivatedAt
      ? new Date(activeLocationV3?.firstActivatedAt)
      : new Date(),
    new Date('2021-05-13T00:00:00.000Z') // https://github.com/signpost/glaze/blob/c9a3044ae8daa7bf3f7430b33d9a3c75a3700eb4/app/helpers/ViewHelpers.js#L2458-L2463
  )

  const googleReviewSite = reviewSitesResponse?.data.find(
    (rs) => rs.reviewSiteId === Constants.ReviewSites.GOOGLE.id
  )
  const googleReviewUrl =
    (publicInformation?.googlePlaceExternalId && googleReviewSite?.reviewUrl) ||
    ''
  const displayGoogleConfirmationBanner =
    !!googleReviewSite && !googleReviewSite.confirmedAt && isAfterReleaseDate

  const facebookReviewSite = reviewSitesResponse?.data.find(
    (rs) => rs.reviewSiteId === Constants.ReviewSites.FACEBOOK.id
  )
  const facebookReviewUrl =
    (publicInformation?.facebookUrl && facebookReviewSite?.reviewUrl) || ''
  const displayFacebookConfirmationBanner =
    !!facebookReviewSite &&
    !facebookReviewSite.confirmedAt &&
    isAfterReleaseDate

  const yelpReviewSite = reviewSitesResponse?.data.find(
    (rs) => rs.reviewSiteId === Constants.ReviewSites.YELP.id
  )

  const yelpReviewUrl =
    (publicInformation?.yelpUrl && yelpReviewSite?.reviewUrl) || ''
  const displayYelpConfirmationBanner =
    !!yelpReviewSite && !yelpReviewSite.confirmedAt && isAfterReleaseDate

  const allReviewSitesConfirmed = reviewSitesResponse?.data?.every((rs) =>
    Boolean(rs.confirmedAt)
  )

  return (
    <PageLayout
      title="Review Sites Settings"
      subtitle="Connect and manage sites where customers review your business."
      baseDataAttribute={baseDataAttribute}
    >
      <SectionContainer
        title="Review Sites"
        baseDataAttribute={`${baseDataAttribute}-review-container`}
        subtitle="By connecting these review sites, you can streamline the process of collecting and requesting reviews."
        childrenStyle={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.space(4),
        }}
      >
        {showLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <StyledToggleContainer>
              <Toggle
                dataCy={`${baseDataAttribute}-publish-presence-toggle-${
                  hasPublishingEnabled ? 'on' : 'off'
                }`}
                checked={hasPublishingEnabled}
                disabled={isLoadingToggleFeatureCode}
                onChange={() => {
                  if (locationId && !isNaN(+locationId)) {
                    toggleFeatureCode({
                      enable: !hasPublishingEnabled,
                      featureCode: Constants.FEATURE_CODES.publishPresence,
                      locationId,
                    })
                  }
                }}
              />
              <StyledToggleLabel>
                Update my business info on my {socialMediaText}
              </StyledToggleLabel>
            </StyledToggleContainer>
            {hasPhoneCaptureEnabled && (
              <StyledToggleContainer>
                <Toggle
                  dataCy={`${baseDataAttribute}-publish-phone-toggle-${
                    hasPublishingPhoneCaptureEnabled ? 'on' : 'off'
                  }`}
                  checked={hasPublishingPhoneCaptureEnabled}
                  disabled={isLoadingToggleFeatureCode || !hasPublishingEnabled}
                  onChange={() => {
                    if (locationId && !isNaN(+locationId)) {
                      toggleFeatureCode({
                        enable: !hasPublishingPhoneCaptureEnabled,
                        featureCode:
                          Constants.FEATURE_CODES.publishForwardingNumber,
                        locationId,
                      })
                    }
                  }}
                />
                <StyledToggleLabel>
                  Publish my text marketing number on my {socialMediaText}
                </StyledToggleLabel>
              </StyledToggleContainer>
            )}
            {!allReviewSitesConfirmed && (
              <WarningCard content="Verify if these review pages belong to your business to ensure Signpost directs your customers to the correct page." />
            )}
            <ReviewSiteItem
              baseDataAttribute={`${baseDataAttribute}-google`}
              label="Google"
              inputLabel="Google Place ID"
              iconComponent={GoogleIcon}
              isConnected={!!publicInformation?.googlePlaceExternalId}
              shouldDisplayConfirmationBanner={displayGoogleConfirmationBanner}
              locationId={locationId}
              reviewSiteUrl={googleReviewUrl}
              reviewSiteId={googleReviewSite?.reviewSiteId}
              pageUrl={publicInformation?.googlePlaceExternalId ?? ''}
              onSubmit={async (data) => {
                await updateLocationReviewSite({
                  locationId: +locationId,
                  googlePlaceExternalId: data.reviewSiteUrl,
                })
              }}
              inputTooltip={
                <>
                  Add your Google Place ID to generate an optimized review link.
                  Click “Find your Google Place ID” below and include your
                  business name in the lookup search for accurate results.
                  <br />
                  <br />
                  For assistance, please contact Signpost support.
                </>
              }
              inputHelper={
                <FindGooglePlaceIdLink
                  href="https://developers.google.com/places/place-id"
                  target="_blank"
                  rel="noreferrer"
                  data-dd={`${baseDataAttribute}-find-google-place-id-link`}
                  data-cy={`${baseDataAttribute}-find-google-place-id-link`}
                >
                  Find your Google Place ID
                </FindGooglePlaceIdLink>
              }
            />
            <ReviewSiteItem
              baseDataAttribute={`${baseDataAttribute}-facebook`}
              inputLabel="Facebook Page"
              label="Facebook"
              iconComponent={FacebookIcon}
              isConnected={!!publicInformation?.facebookUrl}
              shouldDisplayConfirmationBanner={
                displayFacebookConfirmationBanner
              }
              locationId={locationId}
              reviewSiteUrl={facebookReviewUrl}
              reviewSiteId={facebookReviewSite?.reviewSiteId}
              pageUrl={publicInformation?.facebookUrl ?? ''}
              onSubmit={async (data) => {
                await updateLocationReviewSite({
                  locationId: +locationId,
                  facebookUrl: data.reviewSiteUrl,
                })
              }}
              inputTooltip={
                <>
                  Add your Facebook page to generate an optimized review link
                  that leads directly to your reviews page, making it easy for
                  your contacts to write reviews.
                </>
              }
            />
            <ReviewSiteItem
              baseDataAttribute={`${baseDataAttribute}-yelp`}
              inputLabel="Yelp Page"
              label="Yelp"
              iconComponent={YelpIcon}
              isConnected={!!publicInformation?.yelpUrl}
              shouldDisplayConfirmationBanner={displayYelpConfirmationBanner}
              locationId={locationId}
              reviewSiteUrl={yelpReviewUrl}
              reviewSiteId={yelpReviewSite?.reviewSiteId}
              pageUrl={publicInformation?.yelpUrl ?? ''}
              onSubmit={async (data) => {
                await updateLocationReviewSite({
                  locationId: +locationId,
                  yelpUrl: data.reviewSiteUrl,
                })
              }}
              inputTooltip={
                <>
                  Add your Yelp page to generate an optimized review link that
                  leads directly to your reviews page, making it easy for your
                  contacts to write reviews.
                </>
              }
            />
          </>
        )}
      </SectionContainer>
    </PageLayout>
  )
}

export default ReviewSites
