import styled from 'styled-components'
import { Col, Row } from 'src/stories/Layout'
import { Button } from 'src/stories/Button'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { useCallback, useEffect, useRef, useState } from 'react'
import logger from 'src/utils/logger'
import AudioPlayer from 'src/stories/AudioPlayer'
import { intervalToDuration } from 'date-fns'
import { MicrophoneIcon } from 'src/stories/assets'

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  fontWeight: '500',
  marginTop: theme.space(0),
  marginBottom: theme.space(3),
}))

const StyledContent = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  marginTop: 0,
  marginBottom: theme.space(8),
}))

const StyledAudioPlayerContainer = styled.p(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: theme.space(2),
  marginBottom: theme.space(8),
}))

const StyledTimer = styled.p(({ theme }) => ({
  color: theme.colors.primary_2,
  fontSize: '2.4rem',
  marginTop: 0,
  marginBottom: theme.space(8),
  textAlign: 'center',
}))

const StyledPermissionDenied = styled.p(({ theme }) => ({
  color: theme.colors.critical,
  fontSize: '1.6rem',
  marginTop: 0,
  marginBottom: theme.space(8),
  textAlign: 'center',
}))

const StyledActionsWrapper = styled.div<{ size: 'small' | 'medium' | 'large' }>(
  ({ theme, size = 'large' }) => ({
    display: 'flex',
    alignSelf: 'start',
    flexDirection: size === 'small' ? 'column' : 'row',
    gap: theme.space(4),
    width: size === 'small' ? '100%' : size === 'medium' ? '50%' : '60%',
  })
)

interface RecordVoiceResponseModalProps {
  onClickSave: (audioBlob: Blob) => void
}

const RecordVoiceResponseModal: React.FC<RecordVoiceResponseModalProps> = ({
  onClickSave,
}) => {
  const screenSizes = useScreenSizes()
  const { closeModal } = useModalNotificationsContext()

  const mediaRecorder = useRef<MediaRecorder>()

  const [permissionDenied, setPermissionDenied] = useState<boolean>()
  const [stream, setStream] = useState<MediaStream>()
  const [recordingStatus, setRecordingStatus] = useState<
    'idle' | 'recording' | 'finished'
  >('idle')
  const audioChunks = useRef<Blob[]>([])
  const [audioURL, setAudioURL] = useState<string>()
  const [timer, setTimer] = useState(0)

  const cleanUp = useCallback(() => {
    stream?.getTracks()?.forEach((track) => track.stop())
  }, [stream])

  const handleStartRecording = async () => {
    try {
      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      })

      setPermissionDenied(false)
      setStream(streamData)
      setRecordingStatus('recording')

      mediaRecorder.current = new MediaRecorder(streamData, {
        mimeType: 'audio/webm',
      })

      mediaRecorder.current.start()

      audioChunks.current = []

      mediaRecorder.current.ondataavailable = (e) => {
        if (typeof e.data === 'undefined' || e.data.size === 0) return
        audioChunks.current.push(e.data)
      }
    } catch (err: unknown) {
      if (err instanceof DOMException && err.name === 'NotAllowedError') {
        setPermissionDenied(true)
      } else if (err instanceof Error) {
        logger.error(`Error while initializing audio stream: ${err.toString()}`)
      }
    }
  }

  const handleStopRecording = () => {
    if (!mediaRecorder.current) return

    mediaRecorder.current.stop()

    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' })
      const newAudioURL = URL.createObjectURL(audioBlob)

      setAudioURL(newAudioURL)
      setRecordingStatus('finished')
    }
  }

  const handleSave = () => {
    const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' })

    onClickSave(audioBlob)

    cleanUp()
    closeModal()
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    if (recordingStatus === 'recording') {
      setTimer(0)
      intervalId = setInterval(() => {
        setTimer((oldValue) => oldValue + 1)
      }, 1000)
    }

    return () => {
      setTimer(0)
      if (intervalId) clearInterval(intervalId)
    }
  }, [recordingStatus])

  useEffect(() => {
    return () => cleanUp()
  }, [cleanUp])

  const timerDuration = intervalToDuration({ start: 0, end: timer * 1000 })

  return (
    <>
      <Row>
        <Col>
          <StyledTitle>Record your instant voice response</StyledTitle>
          <StyledContent>
            Press the button below to start and stop your recording. Your new
            message will replace your current voicemail message.
          </StyledContent>
          {recordingStatus === 'finished' ? (
            <StyledAudioPlayerContainer>
              <AudioPlayer src={audioURL} />
              <Button
                icon={MicrophoneIcon}
                label={'Record your message again'}
                displayAsText
                baseDataAttribute={'record-message-again-link'}
                onClick={() => {
                  cleanUp()
                  setRecordingStatus('idle')
                }}
              />
            </StyledAudioPlayerContainer>
          ) : (
            <StyledTimer>
              {`${
                timerDuration.minutes?.toString()?.padStart(2, '0') ?? '00'
              }:${timerDuration.seconds?.toString()?.padStart(2, '0') ?? '00'}`}
            </StyledTimer>
          )}
          {permissionDenied && (
            <StyledPermissionDenied>
              Please allow permission to use your device's microphone.
            </StyledPermissionDenied>
          )}
        </Col>
      </Row>

      <StyledActionsWrapper
        size={
          screenSizes.isLargeScreen
            ? 'large'
            : screenSizes.isMediumScreen
            ? 'medium'
            : 'small'
        }
      >
        <Button
          onClick={() => {
            closeModal()
          }}
          label="Cancel"
          action="secondary"
        />
        <Button
          label={
            recordingStatus === 'recording'
              ? 'Stop recording'
              : recordingStatus === 'finished'
              ? 'Save and use recording'
              : 'Start recording'
          }
          onClick={() => {
            switch (recordingStatus) {
              case 'recording':
                void handleStopRecording()
                break
              case 'finished':
                void handleSave()
                break
              case 'idle':
                void handleStartRecording()
                break
            }
          }}
          action={recordingStatus === 'finished' ? 'confirm' : 'primary'}
          type="submit"
        />
      </StyledActionsWrapper>
    </>
  )
}

export default RecordVoiceResponseModal
