import React from 'react'
import styled, { CSSObject, useTheme } from 'styled-components'

import BaseInput from '../private/forms/BaseInput'
import Label from '../private/forms/Label'
import ErrorMessage from '../private/forms/ErrorMessage'

const StyledContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  columnGap: theme.space(3),
}))

const StyledLabelContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
}))

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  name: string
  label?: string | JSX.Element
  errors?: Record<string, unknown>
  size?: 'medium' | 'large' // before creating 'small' size double check the need and accessibility
  focusOnLoad?: boolean
  style?: CSSObject
  baseDataAttribute?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      name,
      label,
      errors,
      focusOnLoad,
      size = 'large',
      style,
      baseDataAttribute = 'checkbox',
      ...props
    },
    ref
  ) => {
    const theme = useTheme()
    const hasError = !!(errors && errors[name])

    return (
      <StyledContainer
        style={style}
        data-cy={baseDataAttribute}
        data-dd={baseDataAttribute}
      >
        <BaseInput
          id={name}
          name={name}
          ref={ref}
          type="checkbox"
          error={hasError}
          autoFocus={focusOnLoad}
          cursor="pointer"
          width={size === 'medium' ? theme.space(5) : theme.space(6)}
          height={size === 'medium' ? theme.space(5) : theme.space(6)}
          fontSize="1.6rem"
          data-cy={baseDataAttribute + 'input'}
          data-dd={baseDataAttribute + 'input'}
          {...props}
        />

        <StyledLabelContainer>
          <Label
            name={name}
            labelWeight="medium"
            style={size === 'large' ? { marginTop: theme.space(0.5) } : {}}
          >
            {label}
          </Label>

          <ErrorMessage name={name} errors={errors} />
        </StyledLabelContainer>
      </StyledContainer>
    )
  }
)

export default Checkbox
