import {
  FeedbackVPositiveIcon,
  FeedbackPositiveIcon,
  FeedbackNeutralIcon,
  FeedbackNegativeIcon,
  FeedbackVNegativeIcon,
} from 'src/stories/assets'
import { formatPhoneNumber, generateLocationUrl } from 'src/utils'
import Constants from 'src/lib/Constants'
import { LocationLegacy, MessageInterface } from 'src/client/interfaces/Common'
import { UnknownObject } from 'src/utils/interfaces'

export const extractFeedbackOutcome = (
  rating: number,
  denominator: number,
  isPositive: boolean,
  feedbackText?: string
) => {
  let feedbackIcon = <></>
  let feedbackOutcomeText = ''
  let feedbackSentiment = ''

  if (typeof rating === 'number') {
    const calcRating = (rating * 5) / denominator

    if (calcRating <= 1) {
      feedbackIcon = <FeedbackVNegativeIcon />
      feedbackSentiment = 'very negative'
    } else if (calcRating <= 2) {
      feedbackIcon = <FeedbackNegativeIcon />
      feedbackSentiment = 'negative'
    } else if (calcRating <= 3) {
      feedbackIcon = <FeedbackNeutralIcon />
      feedbackSentiment = 'neutral'
    } else if (calcRating <= 4) {
      feedbackIcon = <FeedbackPositiveIcon />
      feedbackSentiment = 'positive'
    } else if (calcRating <= 5) {
      feedbackIcon = <FeedbackVPositiveIcon />
      feedbackSentiment = 'very positive'
    }
  } else {
    if (isPositive) {
      feedbackIcon = <FeedbackPositiveIcon />
      feedbackSentiment = 'would recommend'
      feedbackOutcomeText = 'positive'
    } else {
      feedbackIcon = <FeedbackNegativeIcon />
      feedbackSentiment = 'would not recommend'
      feedbackOutcomeText = 'negative'
    }
  }

  return { feedbackIcon, feedbackOutcomeText, feedbackText, feedbackSentiment }
}

export const getDisplayName = ({
  firstName,
  lastName,
  suffix,
  email,
  phoneNumber,
}: {
  firstName?: string
  lastName?: string
  suffix?: string
  email?: string
  phoneNumber?: string
}) => {
  const fullName = [firstName, lastName, suffix].filter(Boolean).join(' ')

  return (
    fullName || email || (phoneNumber && formatPhoneNumber(phoneNumber)) || ''
  )
}

export const getCampaignType = (
  messageLifecycleStage: string,
  messageContentType: string,
  location?: LocationLegacy
) => {
  if (messageContentType === Constants.Messages.ContentType.NEWSLETTER) {
    return 'Newsletter'
  }

  const consumerTermEnum = location?.loopConfigurations?.find(
    (lc) => lc.isLocationDefault
  )?.preferredConsumerTerm

  let consumerTerm = ''

  if (consumerTermEnum) {
    consumerTerm = Constants.CONSUMER_TERMINOLOGY.consumer[
      consumerTermEnum
    ].singular.replace(/^\w/, (l) => l.toUpperCase())
  }

  switch (messageLifecycleStage) {
    case Constants.CUSTOMERS.LifecycleStage.ACTIVE_CUSTOMER.value:
      return `Active ${consumerTerm} Campaign`

    case Constants.CUSTOMERS.LifecycleStage.INACTIVE_CUSTOMER.value:
      return 'Win Back Campaign'

    case Constants.CUSTOMERS.LifecycleStage.LEAD.value:
      return `New ${consumerTerm} Campaign`

    default:
      return 'One-Time Campaign'
  }
}

/** https://github.com/signpost/glaze/blob/176c07dd06fd7f8bf77a3d32fe7c9a4350713e9b/app/models/Message.js#L1013-L1028 */
export const getMessageEmailSubject = (
  message: MessageInterface,
  includeGreeting = false
) => {
  let emailSubject = ''

  if (message.content.subject?.text) {
    emailSubject =
      includeGreeting && message.content.subject.showPersonalizedGreeting
        ? `Hi @firstname! ${message.content.subject.text}`
        : message.content.subject.text
  }

  if (
    !emailSubject &&
    message.content.contentType === 'OFFER' &&
    message.content.OFFER?.shortDescription
  ) {
    emailSubject = message.content.OFFER.shortDescription
  }

  return emailSubject
}

export const formatContact = (
  activity: UnknownObject,
  merchantId: string,
  locationId: string
) => ({
  displayName: getDisplayName({
    firstName: activity.contactFirstName as string,
    lastName: activity.contactLastName as string,
    email: activity.contactPrimaryEmailAddress as string,
    phoneNumber: activity.contactPrimaryPhoneNumber as string,
  }),
  href:
    // this activity will not have a contact property
    activity.documentType !== 'CustomerCreationRollup'
      ? generateLocationUrl(
          merchantId,
          locationId,
          `customers/${(activity.contact as UnknownObject).contactId as string}`
        )
      : '',
})
