import styled, { useTheme } from 'styled-components'

import { StyledItem } from 'src/stories/Dropdown'
import { ExpandableContentCustomTrigger } from 'src/stories/ExpandableContent'
import { ArrowDownIcon } from 'src/stories/assets'

const StyledContainer = styled(StyledItem)(({ theme }) => ({
  width: theme.space(58),
}))

const ArrowIconRight = styled(ArrowDownIcon)(() => ({
  transform: `rotate(270deg)`,
}))

const CustomTrigger: (
  name: string,
  dataCy: string
) => ExpandableContentCustomTrigger = (name, dataCy) => {
  const theme = useTheme()

  return (toggle, expanded) => {
    return (
      <StyledContainer data-cy={dataCy} onClick={toggle}>
        {name}{' '}
        {!!expanded ? (
          <ArrowDownIcon
            stroke={theme.colors.base_50}
            color={theme.colors.base_50}
          />
        ) : (
          <ArrowIconRight
            stroke={theme.colors.base_50}
            color={theme.colors.base_50}
          />
        )}
      </StyledContainer>
    )
  }
}

export default CustomTrigger
