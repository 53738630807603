import React from 'react'
import styled, { DefaultTheme } from 'styled-components'

const Container = styled.div<{ heightUnits: number }>(
  ({ theme, heightUnits }) => ({
    backgroundColor: theme.colors.base_10,
    height: theme.space(heightUnits),
    width: '100%',
    borderRadius: theme.constants.borderRadius,
  })
)

const Content = styled.div<{
  width: number
  $color: keyof DefaultTheme['colors']
}>(({ theme, width, $color }) => ({
  width: `${width}%`,
  backgroundColor: theme.colors[$color],
  height: '100%',
  borderRight: width !== 100 ? `1px solid ${theme.colors.base_0}` : undefined,
  borderRadius: [
    theme.space(1),
    theme.space(width === 100 ? 1 : 0),
    theme.space(width === 100 ? 1 : 0),
    theme.space(1),
  ].join(' '),
}))

export interface ProgressBarProps {
  heightUnits?: number
  color?: keyof DefaultTheme['colors']
  progress: number
  dataCy?: string
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  heightUnits = 3,
  progress: _progress,
  color = 'accent_1',
  dataCy = 'progress-bar',
}) => {
  const progress = _progress < 0 ? 0 : _progress > 100 ? 100 : _progress

  return (
    <Container heightUnits={heightUnits} data-cy={dataCy}>
      <Content width={progress} $color={color} data-cy={dataCy + '-content'} />
    </Container>
  )
}

export default ProgressBar
