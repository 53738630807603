import { AxiosInstance } from 'axios'

import Resource from 'src/client/resource'
import {
  CreateSmsRegistrationRequest,
  SmsRegistrationRequests,
} from '../interfaces/SmsRegistration'

const actions = (client: AxiosInstance): SmsRegistrationRequests => {
  const create = async (
    smsRegistration: CreateSmsRegistrationRequest
  ): Promise<void> =>
    client.post(
      `/v3/locations/${smsRegistration.locationId}/sms-registrations`,
      {
        phoneNumber: smsRegistration.phoneNumber,
        businessType: smsRegistration.businessType,
        businessIndustry: smsRegistration.businessIndustry,
        businessIdentifier: smsRegistration.businessIdentifier,
        businessName: smsRegistration.businessName,
        websiteUrl: smsRegistration.websiteUrl,
        representativeTitle: smsRegistration.representativeTitle,
        representativePosition: smsRegistration.representativePosition,
        representativeFirstName: smsRegistration.representativeFirstName,
        representativeLastName: smsRegistration.representativeLastName,
        representativePhoneNumber: smsRegistration.representativePhoneNumber,
        representativeEmailAddress: smsRegistration.representativeEmailAddress,
        addressStreet1: smsRegistration.addressStreet1,
        addressStreet2: smsRegistration.addressStreet2,
        addressCity: smsRegistration.addressCity,
        addressState: smsRegistration.addressState,
        addressPostalCode: smsRegistration.addressPostalCode,
      }
    )

  return { create }
}

export default Resource(actions)
