import React, { ChangeEventHandler, useCallback } from 'react'
import { useContactsContext } from 'src/contexts/ContactsContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Select, { SelectOption } from 'src/stories/Select'
import {
  ArrowIcon,
  CancelRounded,
  FilterIcon,
  FilterOffIcon,
} from 'src/stories/assets'
import styled, { useTheme } from 'styled-components'
import AddContactListForm from './AddContactListForm'
import { useNavigate } from 'react-router-dom'
import { useLocationContext } from 'src/contexts/LocationContext'
import { generateLocationUrl } from 'src/utils'
import { Filter, DesktopFilter } from './Filter'
import Input from 'src/stories/Input'
import { useDebouncer } from 'src/hooks/useDebounce'

const baseDataAttribute = 'contacts-toolbar'

interface SegmentSelectProps {
  segments: SelectOption<number>[]
  selectedSegment?: number
  onSegmentSelect: (segmentId: number) => void
}

interface SearchInputProps {
  search?: string
  onSearch: (searchValue: string) => void
}

interface FilterButtonProps {
  label?: string
}

const ArrowIconRight = styled(ArrowIcon)(() => ({
  transform: `rotate(270deg)`,
}))

const StyledInput = styled(Input).attrs(() => ({
  containerStyle: {
    width: '100%',
    maxWidth: '100%',
    minWidth: '80%',
  },
}))(() => ({
  maxWidth: '100%',
  minWidth: '80%',
}))

const ClearButton: React.FC = () => {
  const { setIsFiltering } = useContactsContext()

  return (
    <Button
      label={'Clear filters'}
      onClick={() => {
        setIsFiltering(false)
      }}
      icon={CancelRounded}
      iconStyles={{ height: 20, width: 20 }}
      displayAsText
      action="primary"
      size="large"
      style={{ width: 'fit-content' }}
      baseDataAttribute={`${baseDataAttribute}-clear-filters`}
    />
  )
}

const FilterButton: React.FC<FilterButtonProps> = ({ label }) => {
  const { setShowFilters, showFilters } = useContactsContext()

  return (
    <Button
      label={label ?? ''}
      onClick={() => setShowFilters(!showFilters)}
      endIcon={ArrowIconRight}
      icon={showFilters ? FilterOffIcon : FilterIcon}
      iconStyles={{ height: 20, width: 20 }}
      displayAsText
      action="primary"
      size="large"
      style={{ width: 'fit-content' }}
      baseDataAttribute={`${baseDataAttribute}-show-filters`}
    />
  )
}

const SegmentSelect: React.FC<SegmentSelectProps> = ({
  onSegmentSelect,
  segments,
  selectedSegment,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { locationId, merchantId } = useLocationContext()
  const { showModal } = useModalNotificationsContext()

  return (
    <Select
      onChange={onSegmentSelect}
      initialValue={selectedSegment || -1}
      options={segments}
    >
      <Button
        label="Create new list"
        style={{ height: theme.space(8), width: '100%' }}
        displayAsText
        baseDataAttribute={`${baseDataAttribute}-create-list`}
        onClick={() =>
          showModal({
            dataCy: `${baseDataAttribute}-create-list`,
            title: 'Create a new list',
            height: 'auto',
            hideActionButtons: true,
            width: theme.space(110),
            headerTitleFontSize: '2rem',
            customBody: (
              <AddContactListForm
                baseDataAttribute={`${baseDataAttribute}-create-list`}
                locationId={locationId}
                onSubmitCallback={(listId) => {
                  const newContactListUrl = generateLocationUrl(
                    merchantId,
                    locationId,
                    `customer-lists/${listId}`
                  )

                  navigate(newContactListUrl)
                }}
              />
            ),
          })
        }
      />
    </Select>
  )
}

const ContactsFilter: React.FC = () => {
  const { handleFilter } = useContactsContext()

  return <Filter handleFilter={handleFilter} />
}

const DesktopContactsFilter: React.FC = () => {
  const { handleFilter } = useContactsContext()

  return <DesktopFilter handleFilter={handleFilter} />
}

const SearchInput: React.FC<SearchInputProps> = ({ search, onSearch }) => {
  const handleContactSearch = useDebouncer<
    ChangeEventHandler<HTMLInputElement>
  >(
    useCallback(
      (e) => {
        onSearch(e.target.value.trim())
      },
      [onSearch]
    ),
    400
  )

  return (
    <StyledInput
      placeholder="Search..."
      defaultValue={search || undefined}
      type="search"
      name="search"
      verticallySpaced={false}
      onChange={handleContactSearch}
    />
  )
}

const createToolbarComponents = () => {
  return {
    ClearButton,
    FilterButton,
    SegmentSelect,
    ContactsFilter,
    DesktopContactsFilter,
    SearchInput,
  }
}

export { createToolbarComponents }
