import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { LocationsResource } from 'src/client'
import logger from 'src/utils/logger'

const useUpdateBusinessInfoMutation = (locationId: number) => {
  const queryClient = useQueryClient()

  return useMutation(LocationsResource.updateBusinessInfo, {
    onSuccess: async () => {
      toast.success('Business Information updated')

      try {
        await queryClient.invalidateQueries({
          queryKey: ['locations', { locationId }],
        })
      } catch (error) {
        logger.error(
          'There was an error trying to refresh the Business Information.',
          { error }
        )
      }
    },
    onError: (error) => {
      logger.error(
        'There was an error trying to update the Business Information.',
        { error }
      )
    },
  })
}

export default useUpdateBusinessInfoMutation
