import React, { useCallback, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import {
  generateLocationUrl,
  generateMultipleLocationUrl,
  generateUserUrl,
  getLocationPreferredTerms,
  isFeatureCodeEnabled,
} from 'src/utils'
import {
  ArrowDownIcon,
  AccountIcon,
  EmailIcon,
  NewBadgeIcon,
} from 'src/stories/assets'
import RoundDot from 'src/stories/RoundDot'
import useSidebarRoutes from 'src/routes/hooks'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import useAuthContext from 'src/contexts/AuthContext'
import Navbar, { RouteItem } from 'src/stories/Navbar'
import MobileNav from 'src/components/Header/MobileNav'
import useAccountContext from 'src/contexts/AccountContext'
import Dropdown, { StyledNavigationItem } from 'src/stories/Dropdown'
import Constants, { hiddenNavbarPagesRegex } from 'src/lib/Constants'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { isConversationResource } from 'src/contexts/ConversationsListContext/types'
import { useLocationContext } from 'src/contexts/LocationContext'

const StyledAccountIcon = styled(AccountIcon)(({ theme }) => ({
  marginRight: theme.space(3),
  cursor: 'pointer',
}))

const NavbarContainer = styled.div(({ theme }) => ({
  height: theme.heights.navbar,
  display: 'flex',
}))

const StyledDropdownInnerContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.space(2)} 0`,
}))

const StyledArrowContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: theme.space(5),
  height: theme.space(5),
  cursor: 'pointer',
  color: theme.colors.base_0,
}))

const StyledNotificationContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: theme.space(6),
  cursor: 'pointer',
  marginLeft: theme.space(2),
}))

const StyledEmailIcon = styled(EmailIcon)(() => ({
  cursor: 'pointer',
}))

interface HeaderProps {
  pageType: 'Location' | 'MultiLocation' | ''
}

const Header: React.FC<HeaderProps> = ({ pageType }) => {
  const { merchantId, locationId, activeLocation } = useLocationContext()
  const { pathname } = useLocation()
  const { user } = useAuthContext()
  const { locations, pageUser } = useAccountContext()

  const theme = useTheme()
  const { isLargeScreen: isDesktop } = useScreenSizes()
  const navRef = useRef<HTMLImageElement>(null)

  const locationPreferredTerms = getLocationPreferredTerms(activeLocation)

  const { campaignSidebarRoutes, settingSidebarRoutes, reviewsSidebarRoutes } =
    useSidebarRoutes({
      merchantId,
      locationId,
      locations,
      consumerTerms: locationPreferredTerms.consumerTerms,
    })

  const { conversationsList } = useConversationsListContext()

  const MessageNotification = useCallback(() => {
    const hasUnreadConversations = conversationsList.some(
      (c) => isConversationResource(c) && !c.isConversationRead
    )

    return (
      <StyledNotificationContainer>
        <StyledEmailIcon fill={theme.colors.base_0} />
        {hasUnreadConversations && <RoundDot />}
      </StyledNotificationContainer>
    )
  }, [conversationsList, theme])

  const accountDropdown = useCallback(
    (userId: number) => (
      <React.Fragment key="account-dropdown">
        <StyledAccountIcon
          data-cy="navbar-item-account-icon"
          fill={theme.colors.base_0}
          onClick={() => {
            navRef.current?.click()
          }}
        />
        <StyledArrowContainer ref={navRef}>
          <ArrowDownIcon
            stroke={theme.colors.base_0}
            data-cy="navbar-item-arrow-down-icon"
          />
        </StyledArrowContainer>
        <Dropdown anchor={navRef}>
          <StyledDropdownInnerContainer data-cy="navbar-item-arrow-down-icon-dropdown-inner-container">
            <StyledNavigationItem
              to={generateUserUrl(userId, '/email-subscription')}
              data-cy="navbar-item-arrow-down-icon-inner-dropdown-item-1"
            >
              Communication Preferences
            </StyledNavigationItem>
            <StyledNavigationItem
              to="/logout"
              data-cy="navbar-item-arrow-down-icon-inner-dropdown-item-2"
            >
              Logout
            </StyledNavigationItem>
          </StyledDropdownInnerContainer>
        </Dropdown>
      </React.Fragment>
    ),
    [theme]
  )

  const routes = useMemo<RouteItem[]>(() => {
    if (user && pageUser && locations && locations.length > 0) {
      let evaluatedPageType = pageType

      // Live-receptionist-only locations have some nav bar options hidden. For MVP,
      // we don't need high-security validation to stop them from viewing the pages
      // if they type the URL or manage to navigate to them through other links.
      let liveReceptionistOnlyUX = isFeatureCodeEnabled(
        Constants.FEATURE_CODES.liveReceptionistOnlyUX,
        activeLocation
      )

      if (hiddenNavbarPagesRegex.some((r) => r.test(pathname))) {
        evaluatedPageType = ''
      }

      switch (evaluatedPageType) {
        case 'Location':
          // Live receptionist only needs to hide various menu buttons
          const LIVE_RECEPTIONIST_ONLY_UX_ROUTES = [
            'Messaging Hub',
            'Insights',
            'Settings',
            '', // Allow anything without a label
          ]

          let createdRoutes = [
            {
              to: generateLocationUrl(merchantId, locationId, '/messaging-hub'),
              label: 'Messaging Hub',
              rightIcon: MessageNotification(),
            },

            {
              to: generateLocationUrl(merchantId, locationId, '/insights'),
              label: 'Insights',
              leftIcon: (
                <NewBadgeIcon
                  height={18}
                  style={{ marginRight: theme.space(2) }}
                />
              ),
            },
            {
              to: generateLocationUrl(
                merchantId,
                locationId,
                '/customer-contacts'
              ),
              label: 'Contacts',
            },
            {
              to: generateLocationUrl(
                merchantId,
                locationId,
                '/emails/review-us'
              ),
              label: 'Reviews',
              additionalRoutes: generateMultipleLocationUrl(
                merchantId,
                locationId,
                [`/emails/get-feedback`]
              ),
              sidebarRoutes: reviewsSidebarRoutes,
            },
            {
              to: generateLocationUrl(
                merchantId,
                locationId,
                localStorage.getItem('SP_HAS_CONVERSION_CAMPAIGN_PAGE')
                  ? '/campaigns/conversion'
                  : '/campaigns/all'
              ),
              label: 'Campaigns',
              additionalRoutes: generateMultipleLocationUrl(
                merchantId,
                locationId,
                [
                  '/scheduled-campaigns/scheduled',
                  '/scheduled-campaigns/drafts',
                  '/scheduled-campaigns/sent',
                  '/campaigns/lifecycle/prospect',
                  '/campaigns/lifecycle/active',
                  '/campaigns/lifecycle/inactive',
                  '/campaigns/content-library',
                  '/campaigns/all',
                ]
              ),
              sidebarRoutes: campaignSidebarRoutes,
            },
            {
              to: generateLocationUrl(
                merchantId,
                locationId,
                '/settings/business'
              ),
              label: 'Settings',
              additionalRoutes: generateMultipleLocationUrl(
                merchantId,
                locationId,
                ['/email-customization', '/settings']
              ),
              sidebarRoutes: settingSidebarRoutes,
            },
            {
              to: generateLocationUrl(merchantId, locationId, '/integrations'),
              label: 'Integrations',
            },
            {
              to: '',
              label: '',
              isSpacer: true,
            },
            {
              to: '',
              label: '',
              render: accountDropdown(user.id),
            },
          ]

          if (liveReceptionistOnlyUX) {
            createdRoutes = createdRoutes.filter((r) =>
              LIVE_RECEPTIONIST_ONLY_UX_ROUTES.includes(r.label)
            )
          }

          return createdRoutes
        case 'MultiLocation':
          return [
            {
              to: generateUserUrl(pageUser.id, '/'),
              label: 'Overview',
            },
            {
              to: generateUserUrl(pageUser.id, '/locations'),
              label: 'Locations',
            },
            {
              to: generateUserUrl(pageUser.id, '/feedback?content=written'),
              label: 'Feedback',
            },
            {
              to: generateUserUrl(pageUser.id, '/reviews'),
              label: 'Reviews',
            },
            {
              to: generateUserUrl(
                pageUser.id,
                '/scheduled-campaigns/scheduled'
              ),
              label: 'Campaigns',
            },
            {
              to: '',
              label: '',
              isSpacer: true,
            },
            {
              to: '',
              label: '',
              render: accountDropdown(user.id),
            },
          ]
        default:
          return []
      }
    } else {
      return []
    }
  }, [
    user,
    pageUser,
    locations,
    pageType,
    activeLocation,
    pathname,
    merchantId,
    locationId,
    MessageNotification,
    theme,
    reviewsSidebarRoutes,
    campaignSidebarRoutes,
    settingSidebarRoutes,
    accountDropdown,
  ])

  if (!routes.length) return null

  return isDesktop ? (
    <Navbar routes={routes} dark={pageType === 'MultiLocation'} />
  ) : (
    <NavbarContainer data-cy="navbar-container">
      <MobileNav routes={routes} />
      <Navbar
        routes={[
          {
            to: '',
            label: '',
            isSpacer: true,
          },
          {
            to: '',
            label: '',
            render: accountDropdown(user.id),
          },
        ]}
        dark={pageType === 'MultiLocation'}
      />
    </NavbarContainer>
  )
}

export default Header
