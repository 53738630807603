import React from 'react'
import styled from 'styled-components'
import LoadingSpinner from 'src/stories/LoadingSpinner'

const Container = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}))

const StyledTitle = styled.span(({ theme }) => ({
  fontSize: '1.6rem',
  marginTop: theme.space(6),
}))

const StyledLabel = styled.span(({ theme }) => ({
  color: theme.colors.base_40,
  marginTop: theme.space(3),
  marginBottom: theme.space(3),
}))

const ConnectionInProgressModal: React.FC = () => {
  return (
    <Container>
      <LoadingSpinner />

      <StyledTitle>Connecting your account</StyledTitle>

      <StyledLabel>
        Setting up your integration. This may take up to 30 seconds.
      </StyledLabel>
    </Container>
  )
}

export default ConnectionInProgressModal
