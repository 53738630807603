import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { io, Socket } from 'socket.io-client'

import logger from '../../utils/logger'

const MAXIMUM_ALLOWED_ERRORS_PER_MINUTE = 6

const useAllowedErrors = () => {
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const [clientErrors, setClientErrors] = useState<Error[]>([])

  useEffect(() => {
    if (clientErrors.length > 0) {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      timeout.current = setTimeout(() => {
        setClientErrors([])
        timeout.current = null
      }, 60000)
    }

    if (clientErrors.length > MAXIMUM_ALLOWED_ERRORS_PER_MINUTE) {
      clientErrors.forEach((ce) => {
        logger.error('WAF WS - Connection Error', { clientErrors: ce })
      })

      setClientErrors([])
      timeout.current = null
    }
  }, [clientErrors])

  return {
    setClientErrors,
  }
}

interface JoinParams {
  userId: number
  locationId: number
}

interface UseWebsockets {
  socket: Socket
  join: (params: JoinParams) => void
}

export const useWebsockets = (): UseWebsockets => {
  const { setClientErrors } = useAllowedErrors()

  const socket = useMemo(() => {
    const socketInstance = io(
      `wss://${process.env.REACT_APP_LEGACY_DOMAIN!}/mh`,
      {
        reconnectionAttempts: 5,
        reconnectionDelay: 2000,
        transports: ['websocket'],
      }
    )

    socketInstance.on('connect_error', (err) => {
      setClientErrors((currState) => {
        const newState = [...currState]

        newState.push(err)

        return newState
      })
    })

    socketInstance.on('disconnect', () => {
      logger.debug('WAF WS - Disconnected from socket')
    })

    socketInstance.on('join', ({ roomId }: { roomId: string }) => {
      logger.debug(`WAF WS - Joined room successfully`, {
        roomId,
      })
    })

    return socketInstance
  }, [setClientErrors])

  const join = useCallback(
    ({ userId, locationId }: JoinParams) => {
      if (socket.connected) {
        socket.emit('join', { userId, locationId })
      } else {
        socket.on('connect', () => {
          socket.emit('join', { userId, locationId })
        })
      }
    },
    [socket]
  )

  useEffect(() => {
    return () => {
      if (socket.connected) {
        socket.disconnect()
      }
    }
  }, [socket])

  return {
    socket,
    join,
  }
}
