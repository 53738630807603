import React from 'react'
import SectionContainer, { SectionAction } from 'src/stories/SectionContainer'

export interface EditableFormContainerProps extends React.PropsWithChildren {
  /**
   * The action name to the header button
   */
  actionLabel?: string
  /**
   * isEditing define if the component should show the formComponent
   */
  isEditing?: boolean
  /**
   * Function to set the isEditing
   */
  setIsEditing?: (isEditing: boolean) => void
  /**
   * The form component
   */
  formComponent?: React.ReactElement
  /**
   * Disabled prop to prevent user of editing the component
   */
  disabled?: boolean
  /**
   * Base Data attribute.
   * Will append `-container`, and `-action-button` to containing elements
   */
  baseDataAttribute?: string
}

/**
 * Section container to separate different contents
 */
const EditableFormContainer: React.FCWithChildren<
  EditableFormContainerProps
> = ({
  isEditing,
  setIsEditing,
  actionLabel = 'Edit',
  formComponent,
  disabled,
  baseDataAttribute = 'editable-form-container',
  children,
}) => {
  return (
    <SectionContainer
      data-cy={`${baseDataAttribute}-container`}
      variant="primary"
      disabled={disabled}
      action={
        formComponent &&
        setIsEditing && (
          <SectionAction
            label={isEditing ? 'Cancel' : actionLabel}
            onClick={() => setIsEditing(!isEditing)}
            disabled={disabled}
          />
        )
      }
      baseDataAttribute={baseDataAttribute}
    >
      {isEditing ? formComponent : children}
    </SectionContainer>
  )
}

export default EditableFormContainer
