import React from 'react'
import styled from 'styled-components'
import { Button } from 'src/stories/Button'
import { useParams } from 'react-router-dom'
import { UseLocationRouteParams } from 'src/utils/interfaces'

import PageNotAvailableIcon from 'src/assets/page-not-available.png'
import { generateLocationUrl } from 'src/utils'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(3),
}))

const ImgContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const Title = styled.h2(({ theme }) => ({
  fontWeight: 500,
  fontSize: '2.4rem',
  color: theme.colors.base_100,
  textAlign: 'center',
}))

const Subtitle = styled.p(({ theme }) => ({
  fontWeight: 400,
  fontSize: '1.8rem',
  color: theme.colors.base_50,
  margin: 0,
  textAlign: 'center',
  marginBottom: theme.space(4),
}))

const PageNotAvailableModal: React.FC = () => {
  const { merchantId, locationId } = useParams<UseLocationRouteParams>()

  const redirectToMessagingHub = () => {
    const url = generateLocationUrl(merchantId!, locationId!, '/messaging-hub')

    window.location.assign(url)
  }

  return (
    <Container>
      <ImgContainer>
        <img
          src={PageNotAvailableIcon}
          alt="Page not available icon"
          style={{ maxWidth: '40%' }}
        />
      </ImgContainer>
      <Title>This page is not available on handheld devices</Title>
      <Subtitle>
        Because mobile devices offer limited screen space, we only offer this
        page on your desktop or laptop computer.
      </Subtitle>
      <Button
        label="Return to Messaging Hub"
        onClick={redirectToMessagingHub}
      />
    </Container>
  )
}

export default PageNotAvailableModal
