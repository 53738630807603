import React from 'react'
import { useParams } from 'react-router-dom'
import LeadInstantResponseSettings from './LeadInstantResponseSettings'
import useAccountContext from 'src/contexts/AccountContext'
import { useInstantResponder } from 'src/containers/Settings/InstantResponders/hooks'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import Constants from 'src/lib/Constants'
import { formatPhoneNumber, getActiveLocation } from 'src/utils'

interface SMSInstantResponseSettingsProps {
  customIR: string | null
  isEnabled: boolean
}

const SMSInstantResponseSettings: React.FC<SMSInstantResponseSettingsProps> = ({
  customIR,
  isEnabled,
}) => {
  const { locationId } = useParams<UseLocationRouteParams>()
  const { locations, handleRefetchUserLocations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)

  const { customIRMessage, isToggleEnabled, onToggle, onUpdateMessage } =
    useInstantResponder({
      customIR,
      isEnabled,
      locationId: activeLocation?.locationId,
      refetchLocationFn: handleRefetchUserLocations,
      source: 'sms',
    })

  let tooltipMessage = `In order to use the SMS instant response feature, you'll need your ${Constants.Branding.companyName} text marketing number. Contact our Support team at (855) 606-4900 to receive this number.`
  let leadSourceName = 'SMS instant response'

  if (activeLocation?.forwardingPhone) {
    const formattedPhoneNumber = formatPhoneNumber(
      activeLocation.forwardingPhone
    )

    tooltipMessage =
      'This is your text marketing number. Advertise this number, and anyone who texts you will receive your Instant Response text!'
    leadSourceName = `SMS instant response ${formattedPhoneNumber}`
  }

  return (
    <LeadInstantResponseSettings
      leadSourceName={leadSourceName}
      connectable={false}
      defaultMessage={Constants.INSTANT_RESPONSES.missedCall.defaultMessage}
      enabled={isToggleEnabled}
      message={customIRMessage}
      onEnablementChange={onToggle}
      onUpdateMessage={onUpdateMessage}
      messageLabel="Text response to incoming SMS:"
      maxLength={160}
      isToggleDisabled={!activeLocation?.forwardingPhone}
      tooltipMessage={tooltipMessage}
    />
  )
}

export default SMSInstantResponseSettings
