import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { Body } from '../../typography'

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
})

export interface LabelProps {
  name: string
  label?: string | JSX.Element
  labelWeight?: 'bold' | 'medium'
  description?: string | JSX.Element
  style?: CSSObject
  baseDataAttribute?: string
}

const Label: React.FCWithChildren<LabelProps> = ({
  name,
  label,
  description,
  labelWeight = 'bold',
  children,
  style,
  baseDataAttribute = 'label',
}) => {
  return (
    <StyledContainer
      data-cy={baseDataAttribute}
      data-dd={baseDataAttribute}
      style={style}
    >
      {(label || children) && (
        <Body as="label" color="darker" fontWeight={labelWeight} htmlFor={name}>
          {label || children}
        </Body>
      )}

      {description && <Body color="lighter">{description}</Body>}
    </StyledContainer>
  )
}

export default Label
