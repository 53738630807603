import React from 'react'
import styled from 'styled-components'

const StyledTitle = styled.h2({
  marginTop: '0',
})

const StyledCard = styled.div(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.largeBorderRadius,
  backgroundColor: theme.colors.base_0,
  padding: `${theme.space(4)} ${theme.space(5)}`,
}))

export interface CardProps {
  title?: string
  baseDataAttribute?: string
}

const Card: React.FCWithChildren<CardProps> = ({
  title,
  children,
  baseDataAttribute = 'Card',
}) => {
  return (
    <>
      {title && (
        <StyledTitle data-cy={`${baseDataAttribute}-title`}>
          {title}
        </StyledTitle>
      )}

      <StyledCard data-cy={baseDataAttribute}>{children}</StyledCard>
    </>
  )
}

export default Card
