import { useParams, useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import LeadInstantResponseSettings from './LeadInstantResponseSettings'
import { useInstantResponder } from 'src/containers/Settings/InstantResponders/hooks'
import Constants from 'src/lib/Constants'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import useAccountContext from 'src/contexts/AccountContext'
import { getActiveLocation, generateLocationUrl } from 'src/utils'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import DisconnectConfirmationModal from '../DisconnectConfirmationModal'
import { LocationsResource } from 'src/client'
import logger from 'src/utils/logger'
import { toast } from 'react-toastify'

interface HomeAdvisorInstantResponseSettingsProps {
  customIR: string | null
  isEnabled: boolean
  isConnected: boolean
  integrationStatus?: string
  integrationUpdatedAt: string | null
}

const HomeAdvisorInstantResponseSettings: React.FC<
  HomeAdvisorInstantResponseSettingsProps
> = ({
  customIR,
  isEnabled,
  isConnected,
  integrationStatus,
  integrationUpdatedAt,
}) => {
  const { locationId, merchantId } = useParams<UseLocationRouteParams>()
  const { locations, handleRefetchUserLocations } = useAccountContext()
  const { showModal, closeModal } = useModalNotificationsContext()
  const [isDisconnecting, setIsDisconnecting] = useState(false)

  const activeLocation = getActiveLocation(locations, locationId)
  const navigate = useNavigate()

  const { customIRMessage, isToggleEnabled, onToggle, onUpdateMessage } =
    useInstantResponder({
      customIR,
      isEnabled,
      locationId: activeLocation?.locationId,
      refetchLocationFn: handleRefetchUserLocations,
      source: 'homeadvisor',
    })

  const handleConnect = () => {
    const angiLeadsConnectionWizardUrl = generateLocationUrl(
      merchantId!,
      locationId!,
      '/integrations/connect#angi-leads'
    )

    navigate(angiLeadsConnectionWizardUrl)
  }

  const handleConfirmDisconnect = useCallback(async () => {
    if (!activeLocation) return

    closeModal()

    setIsDisconnecting(true)

    try {
      await LocationsResource.toggleFeatureCode({
        enable: false,
        featureCode: Constants.FEATURE_CODES.syncHomeadvisor,
        locationId: activeLocation.locationId,
      })

      await handleRefetchUserLocations()

      toast.success('Successfully disconnected')
    } catch (error) {
      logger.debug('Angi Leads Responder - disconnect angi leads', { error })
      toast.error(
        'There was an error trying to disconnect you from Angi Leads. Please try again.'
      )
    } finally {
      setIsDisconnecting(false)
    }
  }, [activeLocation, closeModal, handleRefetchUserLocations])

  const handleDisconnect = useCallback(() => {
    showModal({
      title: 'Disconnect Angi',
      headerColor: 'critical',
      hideActionButtons: false,
      modalActionsOptions: {
        callToAction: {
          label: 'Disconnect',
          action: 'danger',
          onClick: handleConfirmDisconnect,
        },
      },
      customBody: <DisconnectConfirmationModal leadSourceName="Angi" />,
      height: 'auto',
    })
  }, [handleConfirmDisconnect, showModal])

  return (
    <LeadInstantResponseSettings
      leadSourceName="Angi"
      connectable={true}
      connected={isConnected}
      onClickConnect={handleConnect}
      defaultMessage={Constants.INSTANT_RESPONSES.homeadvisor.defaultMessage}
      enabled={isToggleEnabled}
      message={customIRMessage}
      onEnablementChange={onToggle}
      isDisconnecting={isDisconnecting}
      onUpdateMessage={onUpdateMessage}
      onClickDisconnect={handleDisconnect}
      messageLabel="Message sent to incoming leads from Angi:"
      maxLength={160}
      integrationStatus={integrationStatus}
      integrationUpdatedAt={integrationUpdatedAt}
    />
  )
}

export default HomeAdvisorInstantResponseSettings
