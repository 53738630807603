import React from 'react'
import styled from 'styled-components'
import Constants from 'src/lib/Constants'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import Link from 'src/stories/Link'

const FooterContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
}))

const Spacer = styled.hr(({ theme }) => ({
  marginTop: theme.space(8),
  width: '100%',
  opacity: '20%',
}))

const FooterList = styled.ul<{
  $row?: boolean
}>(({ $row }) => ({
  display: 'flex',
  flexDirection: $row ? 'row' : 'column',
  listStyle: 'none',
  paddingLeft: '0rem',
  textAlign: 'left',
  margin: '0',
}))

const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: '1.2rem',
  margin: `0 ${theme.space(1)}`,
}))

const FooterText = styled.li(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: '400',
  lineHeight: '3.2rem',
  textDecoration: 'none',
  color: theme.colors.base_50,
}))

const CopyrightText = styled.p(({ theme }) => ({
  height: theme.space(8),
  fontSize: '1rem',
  fontWeight: '500',
  color: theme.colors.base_50,
  marginLeft: theme.space(2),
  marginBottom: theme.space(2),
  width: '90%',
}))

const Footer: React.FC = () => {
  const links = Constants.FooterLinks

  const { isSmallScreen } = useScreenSizes()

  const currentYear = new Date().getFullYear()

  return (
    <FooterContainer>
      <Spacer />
      <FooterList $row={isSmallScreen}>
        <FooterText>
          {links.map(({ text, url, dataAttribute }) => (
            <StyledLink
              underline="none"
              target="_blank"
              key={text}
              baseDataAttribute={dataAttribute}
              href={url}
            >
              {text}
            </StyledLink>
          ))}
          |
          <StyledLink
            underline="none"
            href={`mailto: ${Constants.Branding.companySupportEmail}`}
            baseDataAttribute="footer-email-link"
          >
            {Constants.Branding.companySupportEmail}
          </StyledLink>{' '}
          |
          <StyledLink
            underline="none"
            href="tel: (855) 606-4900"
            baseDataAttribute="footer-phone-link"
          >
            (855) 606-4900
          </StyledLink>
        </FooterText>
      </FooterList>
      <CopyrightText>
        &copy; {currentYear} {Constants.Branding.copyRightText}
      </CopyrightText>
    </FooterContainer>
  )
}

export default Footer
