import Constants from 'src/lib/Constants'
import { ContactLegacy } from 'src/client/interfaces/Common'
import { Contact } from 'src/client/interfaces/Contacts'
import { formatPhoneNumber } from 'src/utils/helpers/formatters'

/**
 * Method to get a display name from a Contact
 * @param contact
 * @returns the contact display name; if `firstName` and `lastName` are empty, fallback to phone number
 */
export const getContactDisplayName = ({
  firstName,
  lastName,
  primaryPhoneNumber,
}: Contact | ContactLegacy) => {
  const upperCase = (t: string) =>
    t.charAt(0).toUpperCase() + t.slice(1, t.length)

  return !!firstName && !!lastName
    ? `${upperCase(firstName)} ${upperCase(lastName)}`
    : (firstName && upperCase(firstName)) ||
        (lastName && upperCase(lastName)) ||
        formatPhoneNumber(primaryPhoneNumber!)
}

/**
 * Computes the ownership of a message. This method is provisional while we
 * figure out all edges of the system.
 * @param interactionType
 * @param documentType
 * @returns
 *
 */
export const computeMessageOwnership = (
  interactionType: string,
  documentType: string
) => {
  if (
    [
      Constants.COMMS.documentTypes.agentzInteraction,
      Constants.COMMS.documentTypes.homeAdvisorInteraction,
      Constants.COMMS.documentTypes.thumbtackInteraction,
    ].includes(documentType) ||
    interactionType?.includes('incoming')
  ) {
    return false
  }

  return true
}
