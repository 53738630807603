import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  useFacebookIntegrationData,
  useGoogleMessagesIntegrationData,
  useHomeAdvisorIntegrationData,
  useMissedCallAcknowledgmentData,
  useThumbtackIntegrationData,
  useSMSIntegrationData,
  useCustomVoiceResponderData,
} from './hooks'
import {
  FacebookInstantResponseSettings,
  GBMInstantResponseSettings,
  HomeAdvisorInstantResponseSettings,
  MCAInstantResponseSettings,
  ThumbtackInstantResponseSettings,
  SMSInstantResponseSettings,
} from 'src/components/Settings/InstantResponders/LeadInstantResponseSettings'
import useAuthContext from 'src/contexts/AuthContext'
import useAccountContext from 'src/contexts/AccountContext'
import Constants from 'src/lib/Constants'
import { Col, Row } from 'src/stories/Layout'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import PageLayout from 'src/components/WafLayout/PageLayout'
import InstantVoiceResponseSettings from 'src/components/Settings/InstantResponders/LeadInstantResponseSettings/InstantVoiceResponseSettings'

const StyledLeadSourceContainer = styled.div(({ theme }) => ({
  marginBottom: theme.space(6),
}))

const InstantResponders: React.FC = () => {
  const [leads, setLeads] = useState<{
    connected: string[]
    disconnected: string[]
  }>({ connected: [], disconnected: [] })

  const { locationId } = useParams<UseLocationRouteParams>()
  const { user } = useAuthContext()
  const { locations } = useAccountContext()
  const activeLocation = locations?.find((l) => l.locationId === +locationId!)

  const {
    customIR: facebookCustomIR,
    isConnected: isFacebookConnected,
    isLoading: isFacebookLoading,
    isEnabled: isFacebookEnabled,
    integrationStatus: facebookStatus,
    integrationUpdatedAt: facebookUpdatedAt,
  } = useFacebookIntegrationData({ activeLocation, userId: user.id })

  const {
    customIR: homeAdvisorIR,
    isConnected: isHomeAdvisorConnected,
    isEnabled: isHomeAdvisorEnabled,
    integrationStatus: homeAdvisorStatus,
    integrationUpdatedAt: homeAdvisorUpdatedAt,
  } = useHomeAdvisorIntegrationData({ activeLocation, userId: user.id })

  const { customIR: MCACustomIR, isEnabled: isMCAEnabled } =
    useMissedCallAcknowledgmentData({ activeLocation })

  const { customIR: SMSCustomIR, isEnabled: isSmsAutoResponseEnabled } =
    useSMSIntegrationData({ activeLocation })

  const {
    canConnect: canConnectGoogleMessages,
    customIR: googleMessagesIR,
    isEnabled: isGoogleMessagesEnabled,
    isConnected: isGoogleMessagesConnected,
    integrationStatus: googleMessagesStatus,
    integrationUpdatedAt: googleMessagesUpdatedAt,
  } = useGoogleMessagesIntegrationData({ activeLocation })

  const {
    customIR: thumbtackIR,
    isEnabled: isThumbtackEnabled,
    isConnected: isThumbtackConnected,
    integrationStatus: thumbtackStatus,
    integrationUpdatedAt: thumbtackUpdatedAt,
  } = useThumbtackIntegrationData({ activeLocation })

  const {
    isEnabled: isVoiceResponderEnabled,
    audioURL: voiceResponderAudioURL,
  } = useCustomVoiceResponderData({ activeLocation })

  // Any new integration should be listed in this array in order to be rendered
  // New integrations should be listed first
  const LeadsData = {
    [Constants.INTEGRATIONS.THUMBTACK]: {
      component: ThumbtackInstantResponseSettings,
      customIR: thumbtackIR,
      isConnected: isThumbtackConnected,
      isEnabled: isThumbtackEnabled,
      integrationStatus: thumbtackStatus,
      integrationUpdatedAt: thumbtackUpdatedAt,
    },
    ...(canConnectGoogleMessages &&
      isGoogleMessagesConnected && {
        [Constants.INTEGRATIONS.GOOGLE_MESSAGES]: {
          component: GBMInstantResponseSettings,
          customIR: googleMessagesIR,
          isConnected: isGoogleMessagesConnected,
          isEnabled: isGoogleMessagesEnabled,
          integrationStatus: googleMessagesStatus,
          integrationUpdatedAt: googleMessagesUpdatedAt,
        },
      }),
    [Constants.INTEGRATIONS.HOMEADVISOR]: {
      component: HomeAdvisorInstantResponseSettings,
      customIR: homeAdvisorIR,
      isConnected: isHomeAdvisorConnected,
      isEnabled: isHomeAdvisorEnabled,
      integrationStatus: homeAdvisorStatus,
      integrationUpdatedAt: homeAdvisorUpdatedAt,
    },
    [Constants.INTEGRATIONS.FACEBOOK]: {
      component: FacebookInstantResponseSettings,
      customIR: facebookCustomIR,
      isConnected: isFacebookConnected,
      isEnabled: isFacebookEnabled,
      integrationStatus: facebookStatus,
      integrationUpdatedAt: facebookUpdatedAt,
    },
  }

  useEffect(() => {
    const { connected, disconnected } = Object.keys(LeadsData).reduce(
      (acc, key) => {
        const lead = LeadsData[key]

        acc[lead.isConnected ? 'connected' : 'disconnected'].push(key)

        return acc
      },
      {
        connected: [] as string[],
        disconnected: [] as string[],
      }
    )

    setLeads({ connected, disconnected })

    // DO NOT SUPPRESS THIS LINT RULE. It's a bad, bug-prone practice that
    // should have alternate solutions.
    // TODO: Fix this & stop suppressing it here. See analysis at https://github.com/signpost/web-app-frontend/issues/964
    // Original comment on why it was suppressed:
    // exhaustive-deps lint disable due to the missing dep
    // is LeadsData and if we added it would create an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFacebookConnected,
    isHomeAdvisorConnected,
    canConnectGoogleMessages,
    isGoogleMessagesConnected,
    isThumbtackConnected,
  ])

  if (isFacebookLoading) {
    return <LoadingSpinner />
  }

  return (
    <PageLayout
      title="Instant Responders"
      subtitle="Communicate automatically with incoming calls and leads - respond even when you are busy."
      baseDataAttribute="settings-instant-responders"
    >
      <Row>
        <Col>
          <h1 data-cy="settings-instant-responders-phone-title">Phone</h1>
        </Col>
      </Row>

      {/*
        MCA IR and SMS components are located here since both are NOT an integration, hence
        is always available to enable or disable them, it does not require a connect flow.
      */}
      <Row>
        <Col>
          <MCAInstantResponseSettings
            customIR={MCACustomIR}
            isEnabled={isMCAEnabled}
          />
          <SMSInstantResponseSettings
            customIR={SMSCustomIR}
            isEnabled={isSmsAutoResponseEnabled}
          />
          <InstantVoiceResponseSettings
            isEnabled={isVoiceResponderEnabled}
            audioURL={voiceResponderAudioURL}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h1 data-cy="settings-instant-lead-services">Lead Services</h1>
        </Col>
      </Row>

      {leads.connected.map((leadName, idX) => {
        const {
          component: LeadIRSettings,
          customIR,
          isEnabled,
          integrationStatus,
          integrationUpdatedAt,
        } = LeadsData[leadName]

        return (
          <Row key={`connected-lead-${idX}`}>
            <Col>
              <StyledLeadSourceContainer>
                <LeadIRSettings
                  integrationStatus={integrationStatus}
                  customIR={customIR}
                  isConnected
                  isEnabled={isEnabled}
                  integrationUpdatedAt={integrationUpdatedAt ?? null}
                />
              </StyledLeadSourceContainer>
            </Col>
          </Row>
        )
      })}
      {leads.disconnected.map((leadName, idX) => {
        const {
          component: LeadIRSettings,
          customIR,
          isEnabled,
          integrationStatus,
          integrationUpdatedAt,
        } = LeadsData[leadName]

        return (
          <Row key={`more-leads-${idX}`}>
            <Col>
              <StyledLeadSourceContainer>
                <LeadIRSettings
                  integrationStatus={integrationStatus}
                  customIR={customIR}
                  isConnected={false}
                  isEnabled={isEnabled}
                  integrationUpdatedAt={integrationUpdatedAt ?? null}
                />
              </StyledLeadSourceContainer>
            </Col>
          </Row>
        )
      })}
    </PageLayout>
  )
}

export default InstantResponders
