import {
  LocationLegacy,
  LocationReviewSites,
} from 'src/client/interfaces/Common'
import Constants from 'src/lib/Constants'

interface ReducedLocationReviewSites extends LocationReviewSites {
  sortAs: number
  reviewSiteName: string
  reviewSiteDisplayName: string
}

export const useReviewSiteInformation = (location: LocationLegacy) => {
  const { locationReviewSites, emailPreferences } = location || {}

  const locationName = emailPreferences?.senderName || ''
  let reviewSiteName = ''
  let reviewSiteDisplayName = ''

  if (!locationReviewSites || locationReviewSites.length === 0) {
    return {
      locationName,
      reviewSiteName,
      reviewSiteDisplayName,
    }
  }

  const reducedLocationReviewSites = (locationReviewSites || []).reduce(
    (a, rs) => {
      if (rs.reviewSiteId === Constants.ReviewSites.GOOGLE.id) {
        a.push({
          ...rs,
          sortAs: 1,
          reviewSiteName: Constants.ReviewSites.GOOGLE.name,
          reviewSiteDisplayName: Constants.ReviewSites.GOOGLE.displayName,
        })
      }
      if (rs.reviewSiteId === Constants.ReviewSites.FACEBOOK.id) {
        a.push({
          ...rs,
          sortAs: 2,
          reviewSiteName: Constants.ReviewSites.FACEBOOK.name,
          reviewSiteDisplayName: Constants.ReviewSites.FACEBOOK.displayName,
        })
      }
      if (rs.reviewSiteId === Constants.ReviewSites.YELP.id) {
        a.push({
          ...rs,
          sortAs: 3,
          reviewSiteName: Constants.ReviewSites.YELP.name,
          reviewSiteDisplayName: Constants.ReviewSites.YELP.displayName,
        })
      }

      return a
    },
    [] as ReducedLocationReviewSites[]
  )

  const priorityLocationReviewSites = reducedLocationReviewSites.filter(
    (rs) => rs.highPriority
  )

  if (priorityLocationReviewSites.length > 0) {
    priorityLocationReviewSites.sort((rsa, rsb) => rsa.sortAs - rsb.sortAs)

    reviewSiteName = priorityLocationReviewSites[0].reviewSiteName
    reviewSiteDisplayName = priorityLocationReviewSites[0].reviewSiteDisplayName
  } else if (reducedLocationReviewSites.length > 0) {
    reducedLocationReviewSites.sort((rsa, rsb) => rsa.sortAs - rsb.sortAs)

    reviewSiteName = reducedLocationReviewSites[0].reviewSiteName
    reviewSiteDisplayName = reducedLocationReviewSites[0].reviewSiteDisplayName
  }

  return {
    locationName,
    reviewSiteName,
    reviewSiteDisplayName,
  }
}
