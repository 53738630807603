import React from 'react'
import { ErrorMessage as ErrorMessageHook } from '@hookform/error-message'

import { Body } from '../../typography'

export interface ErrorMessageProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  name: string
  errors?: Record<string, unknown>
  baseDataAttribute?: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  name,
  errors,
  baseDataAttribute = 'error-message',
  ...props
}) => {
  return (
    <>
      {errors && (
        <ErrorMessageHook
          errors={errors}
          name={name}
          render={({ message }) => (
            <Body as="label" color="critical" fontWeight="bold">
              {message}
            </Body>
          )}
          data-cy={baseDataAttribute}
          data-dd={baseDataAttribute}
          {...props}
        />
      )}
    </>
  )
}

export default ErrorMessage
