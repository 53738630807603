import React from 'react'
import styled, { useTheme } from 'styled-components'

import { Button } from 'src/stories/Button'
import { Td } from 'src/stories/table'

const EmptyTableTd = styled(Td)(({ theme }) => ({
  height: theme.space(63),
}))

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.space(6),
}))

const Heading = styled.p(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  lineHeight: '2.4rem',
  fontWeight: '400',
  textAlign: 'center',
  margin: 0,
}))

const SubHeading = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  fontWeight: '400',
  textAlign: 'center',
  margin: 0,
}))

interface Props {
  colSpan: number
}

const TableZeroState: React.FC<Props> = ({ colSpan }) => {
  const theme = useTheme()

  return (
    <EmptyTableTd colSpan={colSpan}>
      <Container>
        <Heading>You don&apos;t have any active conversion campaigns</Heading>
        <SubHeading>
          Once you create a campaign, it will show up here.
        </SubHeading>
        <Button
          label="Create conversion campaign"
          maxWidth={theme.space(60)}
          style={{
            marginTop: theme.space(2),
          }}
        />
      </Container>
    </EmptyTableTd>
  )
}

export default TableZeroState
