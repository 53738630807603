import { toast } from 'react-toastify'
import {
  ContactsLegacyResource,
  ConversationsLegacyResource,
} from '../../client'
import { UserInterface } from '../../client/interfaces/Common'
import { OpenConversationContactDetails } from '../../containers/MessagingHub/types'
import { isEditableConversationItem } from '../../contexts/MhContext/utils'
import Constants from '../../lib/Constants'
import { getCookie, isInternalUser } from '../../utils'
import logger from '../../utils/logger'
import { SelectableConversationsList } from './ConversationsPane/ConversationList'
import { isConversationResource } from 'src/contexts/ConversationsListContext/types'

/**
 * Method to determine if user has accepted TCPA policy
 * @param user
 * @returns whether the user has a policy acknowledgement for TCPA policy
 * or if it is internal user or there is a `disableTCPA` cookie for e2e tests.
 */
export const hasAcceptedTCPA = (user: UserInterface) =>
  isInternalUser(user) ||
  (getCookie('e2eTesting') === 'e2eTestsRunning' &&
    getCookie('disableTCPA', true)) ||
  !!user.policyAcks?.some((policy) => policy.policyType === 'TCPA')

export const getContactSubscriptionState = (
  contact: OpenConversationContactDetails
) => {
  const canBeToggled =
    !!(contact.email && contact.emailOptIn) ||
    !!(contact.name && (!!contact.smsOptIn || contact.smsOptIn === null))

  return {
    canBeToggled,
    toggleState: canBeToggled && !!contact.merchantSubscribed,
  }
}

interface ReducedConversationListToEdit {
  id: number
  resourceType?: 'ConversationResource'
}

export const reduceEditableConversations = (
  conversationsList: SelectableConversationsList[]
): ReducedConversationListToEdit[] =>
  conversationsList.reduce((a, c) => {
    if (c.selected && isEditableConversationItem(c)) {
      a.push({
        id: c.id,
        resourceType: isConversationResource(c) ? c.resourceType : undefined,
      })
    }

    return a
  }, [] as ReducedConversationListToEdit[])

const showNotificationOnMhActions = (
  action: string,
  requestLength: number,
  successCount: number
) => {
  let toastMethod: keyof typeof toast = 'success'
  let message = `${successCount} conversation${
    successCount > 1 ? 's' : ''
  } ${action}.`

  if (!successCount && requestLength) {
    toastMethod = 'warning'
    message = 'No updates were made, please try again or contact us for help.'
  }

  toast[toastMethod](message)
}

export const onArchive = async (
  conversations: ReducedConversationListToEdit[],
  locationId: number,
  archive = true
) => {
  try {
    const [conversationsToEdit, publicReviewsToEdit] = conversations.reduce(
      (a, c) => {
        a[c.resourceType === 'ConversationResource' ? 0 : 1].push(c.id)

        return a
      },
      [[], []] as [number[], number[]]
    )

    const [fulfilled, rejected] =
      await ConversationsLegacyResource.updateArchiveStatus(
        conversationsToEdit,
        archive
      )

    const [fulfilledReviews, rejectedReviews] =
      await ConversationsLegacyResource.updateUnconfirmedReviewArchiveStatus(
        publicReviewsToEdit,
        locationId,
        archive
      )

    const successCount = fulfilled.length + fulfilledReviews.length

    showNotificationOnMhActions(
      'archived',
      conversationsToEdit.length,
      successCount
    )

    if (rejected.length > 0 || rejectedReviews.length > 0) {
      logger.error('bulk action errors - archive', {
        rejected,
        rejectedReviews,
      })
    }
  } catch (error) {
    logger.error('bulk action errors - onArchive', { error })
    toast.error(Constants.ERRORS.default)
  }
}

export const onMarkRead = async (
  conversations: ReducedConversationListToEdit[],
  read = true
) => {
  try {
    const conversationsToEdit: number[] = []

    conversations.forEach((c) => {
      if (c.id !== -1 && c.resourceType === 'ConversationResource') {
        conversationsToEdit.push(c.id)
      }
    })

    const [, rejected] = await ConversationsLegacyResource.markConversationRead(
      conversationsToEdit,
      read
    )

    toast.success(
      `Conversation${conversationsToEdit.length > 1 ? 's' : ''} marked as read`
    )

    if (rejected.length > 0) {
      logger.error('bulk action errors - mark read', { rejected })
    }
  } catch (error) {
    logger.error('bulk action errors - onMarkRead', { error })
    toast.error(Constants.ERRORS.default)
  }
}

export const onMarkAsSpam = async (
  conversations: ReducedConversationListToEdit[]
) => {
  try {
    const conversationsToEdit: number[] = []

    conversations.forEach((c) => {
      if (c.resourceType === 'ConversationResource') {
        conversationsToEdit.push(c.id)
      }
    })

    const [fulfilled, rejected] = await ContactsLegacyResource.deleteContacts(
      conversationsToEdit
    )

    const successCount = fulfilled.length

    showNotificationOnMhActions(
      'marked as spam',
      conversationsToEdit.length,
      successCount
    )

    if (rejected.length > 0) {
      logger.error('bulk action errors - mark as spam', { rejected })
    }
  } catch (error) {
    logger.error('bulk action errors - onMarkAsSpam', { error })
    toast.error(Constants.ERRORS.default)
  }
}

export const downloadFile = ({
  file,
  filename,
}: {
  file: Blob
  filename: string
}) => {
  const audioUrl = URL.createObjectURL(file)
  const link = document.createElement('a')

  link.href = audioUrl
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
