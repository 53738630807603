import { useMutation } from '@tanstack/react-query'
import logger from 'src/utils/logger'
import { toast } from 'react-toastify'

import { ContactsLegacyResource } from 'src/client'

const useSetContactLifecycleMutation = (locationId: number) => {
  return useMutation(ContactsLegacyResource.setLifecycle, {
    onError: (error) => {
      toast.error(
        `There was an error trying to set the lifecycle to the contact. Please try again.`
      )
      logger.error('WAF useSetContactLifecycleMutation - error', {
        error,
      })
    },
  })
}

export default useSetContactLifecycleMutation
