import React from 'react'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import styled from 'styled-components'
import { Body } from 'src/stories/typography'

const StyledCard = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isDesktop ? 'row' : 'column',
    alignItems: 'center',
    columnGap: theme.space(4),
    rowGap: theme.space(2),
    backgroundColor: theme.colors.base_5,
    border: `2px solid ${theme.colors.positive_70}`,
    borderRadius: theme.constants.largeBorderRadius,
    padding: `${theme.space(2)} ${theme.space(3)}`,
    marginTop: theme.space(4),
  })
)

const StyledWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(3),
}))

export interface InfoCardProps extends React.PropsWithChildren {
  content?: string
}

// TODO: STR-758 - Combine InfoCard and WarningCard in a storybook component
const InfoCard: React.FCWithChildren<InfoCardProps> = ({
  content,
  children,
}) => {
  const { isSmallScreen } = useScreenSizes()

  return (
    <StyledCard isDesktop={isSmallScreen}>
      <StyledWrapper>
        <Body size="medium" color="positive" fontWeight="bold">
          {content}
        </Body>
      </StyledWrapper>
      {children}
    </StyledCard>
  )
}

export default InfoCard
