import { useQuery } from '@tanstack/react-query'
import { Segment } from 'src/client/interfaces/Segments'
import Contacts from 'src/client/resources/Contacts'
import logger from 'src/utils/logger'

const useContactsSegmentsQuery = <TData = Segment[]>(
  { locationId }: { locationId: number },
  options: { select?: (data: Segment[]) => TData } = {}
) => {
  return useQuery({
    queryKey: ['segments', locationId] as const,
    queryFn: async ({ queryKey }) => Contacts.getSegmentsList(queryKey[1]),
    onError: (error) => {
      logger.error('WAF useContactsSegmentsQuery - error', { error })
    },
    staleTime: 5_000,
    initialData: [],
    ...options,
  })
}

export default useContactsSegmentsQuery
