import { format, parseISO } from 'date-fns'
import React from 'react'
import {
  Feedback,
  LocationLegacy,
  LocationReviewSites,
} from 'src/client/interfaces/Common'
import { CustomerRollups } from 'src/client/interfaces/CustomerActivities'
import Constants from 'src/lib/Constants'
import { generateLocationUrl } from 'src/utils'
import { UnknownObject } from 'src/utils/interfaces'
import logger from 'src/utils/logger'
import {
  ActivityLink,
  ActivityDetailsSentiment,
  ActivityDetailsSentimentReview,
  ActivityDetailsText,
  ActivityDetailsWrapper,
  ActivityTextWrapper,
  StyledIcons,
} from './styled'
import {
  extractFeedbackOutcome,
  getCampaignType,
  getDisplayName,
} from './utils'

interface DataCyProps {
  dataCy: string
}

export interface FormattedActivity {
  content: React.FC<DataCyProps>
  details?: React.FC<DataCyProps>
  timestamp: string
}

export const formatFeedback = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string }
): FormattedActivity => {
  const feedbackDocument = activity as unknown as Feedback

  const { feedbackOutcomeText, feedbackIcon, feedbackText, feedbackSentiment } =
    extractFeedbackOutcome(
      feedbackDocument.rating,
      feedbackDocument.feedbackRatingDenominator,
      feedbackDocument.isPositive,
      feedbackDocument.feedbackText
    )

  const displayText = `gave you a ${
    feedbackOutcomeText || feedbackSentiment
  } feedback`

  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        {displayText}
      </ActivityTextWrapper>
    ),
    details: ({ dataCy }) => (
      <ActivityDetailsWrapper data-cy={dataCy}>
        <ActivityDetailsSentiment data-cy={dataCy + '-sentiment'}>
          {feedbackIcon} {feedbackSentiment}
        </ActivityDetailsSentiment>
        {feedbackText && (
          <ActivityDetailsText data-cy={dataCy + '-text'}>
            &quot;{feedbackText}&quot;
          </ActivityDetailsText>
        )}
      </ActivityDetailsWrapper>
    ),
  }
}

export const formatSmsInteraction = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string }
): FormattedActivity => {
  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        has opted in to Text Marketing List
      </ActivityTextWrapper>
    ),
  }
}

export const formatMessageConversion = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string },
  location?: LocationLegacy
): FormattedActivity => {
  const campaignType = getCampaignType(
    activity.messageLifecycleStage as string,
    activity.messageContentType as string,
    location
  )
  const messageDisplay = `${campaignType}: ${
    activity.messageEmailSubject as string
  }`

  let actionDisplay = ''

  switch (activity.conversionType) {
    case Constants.Messages.ConversionType.INCOMING_CALL:
    case Constants.Messages.ConversionType.INCOMING_SMS:
      const isIncomingCall =
        activity.conversionType ===
        Constants.Messages.ConversionType.INCOMING_CALL

      actionDisplay = isIncomingCall ? 'called' : 'texted you'
      break
    case Constants.Messages.ConversionType.VISIT_ARBITRARY_LANDING_PAGE:
    case Constants.Messages.ConversionType.VISIT_SIGNPOST_LANDING_PAGE:
    case Constants.Messages.ConversionType.LEGACY_CLAIM:
      actionDisplay =
        activity.conversionType ===
        Constants.Messages.ConversionType.VISIT_ARBITRARY_LANDING_PAGE
          ? 'visited the landing page for'
          : 'viewed more information about'
      break
    default:
  }

  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        {actionDisplay} <strong>{messageDisplay}</strong>
      </ActivityTextWrapper>
    ),
  }
}

export const formatSmsOfferClaim = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string }
): FormattedActivity => {
  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        claimed your New Customer Offer via Text
      </ActivityTextWrapper>
    ),
  }
}

export const formatReview = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string },
  locationReviewSites: LocationReviewSites[] = []
): FormattedActivity => {
  const reviewSite = Object.values(Constants.ReviewSites).find(
    (rs) => rs.name === activity.siteName
  )
  const locationReviewSite = locationReviewSites.find(
    (rs) => rs.reviewSiteId === activity.reviewSiteId
  )

  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        wrote a {activity.rating as string} star review on{' '}
        {reviewSite?.displayName}
      </ActivityTextWrapper>
    ),
    details: ({ dataCy }) => (
      <ActivityDetailsWrapper data-cy={dataCy}>
        <ActivityDetailsSentiment data-cy={dataCy + '-review'}>
          {Array.from(
            Array(Math.floor(activity.rating as number)),
            (_, idx) => (
              <StyledIcons.YellowStar key={idx} />
            )
          )}
        </ActivityDetailsSentiment>
        <ActivityDetailsSentimentReview>
          See Review on{' '}
          <ActivityLink
            href={locationReviewSite?.url}
            target="_blank"
            data-cy={dataCy + '-review-link'}
          >
            {reviewSite?.displayName}
          </ActivityLink>
        </ActivityDetailsSentimentReview>
      </ActivityDetailsWrapper>
    ),
  }
}

export const formatLinkClick = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string },
  locationReviewSites: LocationReviewSites[] = []
): FormattedActivity => {
  let helperText: string

  switch (activity.linkTarget) {
    case 'review_site':
      const locationReviewSite = locationReviewSites.find(
        (rs) => rs.reviewSiteId === activity.reviewSiteId
      )
      const reviewSite = Object.values(Constants.ReviewSites).find(
        (rs) => rs.id === locationReviewSite?.reviewSiteId
      )

      helperText = 'visited your page'
      if (locationReviewSite && reviewSite) {
        helperText = 'Visited your ' + reviewSite.displayName + ' Page'
      }

      break
    case 'facebook':
      helperText = 'visited your Facebook Page'
      break
    case 'website':
      helperText = 'visited your website'
      break
    case 'map':
      helperText = 'looked up your business address'
      break

    default:
      logger.error('Link target not handled: ', {
        linkTarget: activity.linkTarget,
      })
      break
  }

  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        {helperText}
      </ActivityTextWrapper>
    ),
  }
}

export const formatEmailOfferClaim = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string }
): FormattedActivity => {
  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        claimed your New Customer Offer via Email
      </ActivityTextWrapper>
    ),
  }
}

export const formatFacebookAdClaim = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string }
): FormattedActivity => {
  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        claimed your Facebook ad offer
      </ActivityTextWrapper>
    ),
  }
}

export const formatEmailOfferRedemption = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string }
): FormattedActivity => {
  let displayText = ''

  switch (activity.messageType) {
    case 'FacebookOffer':
      displayText = 'redeemed Facebook ad offer'
      break

    case 'NCO':
      displayText = 'redeemed email offer'
      break

    default:
      break
  }

  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        {displayText}
      </ActivityTextWrapper>
    ),
  }
}

export const formatActivityWithText = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string },
  displayText: string
): FormattedActivity => {
  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        {displayText}
      </ActivityTextWrapper>
    ),
  }
}

export const formatContactJobVersion = (
  activity: UnknownObject,
  timestamp: string,
  contact: { href: string; displayName: string }
): FormattedActivity => {
  const current = activity.version as UnknownObject
  const prior = activity.priorVersion as UnknownObject

  let workflowStageDisplayText: string =
    Constants.Messages.Jobs.ENUM_TO_DISPLAY[
      current.workflowStage as keyof typeof Constants.Messages.Jobs.ENUM_TO_DISPLAY
    ]

  if ((activity.documentType as string) === 'ContactEstimateVersion') {
    workflowStageDisplayText =
      Constants.Messages.Estimates.ENUM_TO_DISPLAY[
        current.workflowStage as keyof typeof Constants.Messages.Estimates.ENUM_TO_DISPLAY
      ]
  }

  const label = activity.url ? (
    <a href={activity.url as string} target="_blank" rel="noreferrer">
      {activity.displayLabel as string}
    </a>
  ) : (
    activity.displayLabel
  )

  let details: JSX.Element

  if (!prior || !prior.amount) {
    details = (
      <>
        {label} create in {workflowStageDisplayText}
      </>
    )
  } else {
    if (current.amount !== prior.amount) {
      details = (
        <>
          {label} amount changed to ${current.amount}
        </>
      )
    }
    if (current.workflowStage !== prior.workflowStage) {
      details = (
        <>
          {label} changed to ${current.amount}
        </>
      )
    }
    if (activity.documentType === 'ContactJobVersion') {
      if (current.startTimestamp !== prior.startTimestamp) {
        const startTime = format(
          parseISO(current.startTimestamp as string),
          'MMM D h:mm a'
        )

        details = (
          <>
            {label} start time changed to ${startTime}
          </>
        )
      }
      if (current.endTimestamp !== prior.endTimestamp) {
        const endTime = format(
          parseISO(current.endTimestamp as string),
          'MMM D h:mm a'
        )

        details = (
          <>
            {label} end time changed to ${endTime}
          </>
        )
      }
    }
  }

  return {
    timestamp,
    content: ({ dataCy }) => (
      <ActivityTextWrapper data-cy={dataCy}>
        <ActivityLink href={contact.href} data-cy={dataCy + '-link'}>
          {contact.displayName}
        </ActivityLink>{' '}
        {details}
      </ActivityTextWrapper>
    ),
  }
}

export const formatCustomerCreationRollup = (
  activity: UnknownObject,
  timestamp: string,
  merchantId: string,
  locationId: string
): FormattedActivity => {
  const customerCreationActivity = activity as unknown as CustomerRollups
  const formattedContacts = customerCreationActivity.rollup.map((rollup) => ({
    displayName: getDisplayName({
      firstName: rollup.firstName as string,
      lastName: rollup.lastName as string,
      email: rollup.primaryEmailAddress as string,
      phoneNumber: rollup.primaryPhoneNumber as string,
    }),
    href: generateLocationUrl(
      merchantId,
      locationId,
      `customers/${rollup.contactId as string}`
    ),
  }))

  return {
    timestamp,
    content: ({ dataCy }) => {
      return (
        <ActivityTextWrapper data-cy={dataCy}>
          {formattedContacts.map((contact, idx) => (
            <React.Fragment key={idx}>
              <ActivityLink
                href={contact.href}
                data-cy={`${dataCy}-${idx}-link`}
              >
                {contact.displayName}
              </ActivityLink>
              {idx !== formattedContacts.length - 1 && ', '}
            </React.Fragment>
          ))}
          {customerCreationActivity.total > 3
            ? ` and ${customerCreationActivity.total - 3} ${
                customerCreationActivity.total - 3 === 1 ? 'other' : 'others'
              }`
            : ''}{' '}
          joined your marketing list
        </ActivityTextWrapper>
      )
    },
  }
}
