import styled from 'styled-components'

export const ReviewSiteDataItem = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
}))

export const ReviewSiteHeading = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const ReviewSiteRatingContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const ReviewSiteRating = styled.div(({ theme }) => ({
  display: 'flex',
  width: '50%',
  gap: theme.space(2),
  alignItems: 'center',
}))
