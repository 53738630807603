import React from 'react'
import { useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import useInsightsContext from 'src/contexts/InsightsContext'
import { ReviewSiteData } from 'src/contexts/InsightsContext/types'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { MoreIcon, StarIcon } from 'src/stories/assets'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { InsightsWidget } from './InsightsWidget'
import {
  StyledMainStatContainer,
  StyledWidgetContentContainer,
  StyledWidgetMainStat,
  StyledWidgetStat,
  WidgetReviewBubble,
} from './styled'
import { WidgetProps } from './types'

const StyledSecondaryRatingsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const StyledSecondaryRatingsStatContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const ReviewSiteItem: React.FC<{
  dataCy: string
  reviewSite: ReviewSiteData
}> = ({ dataCy, reviewSite }) => {
  const theme = useTheme()
  const { isLargeScreen: isDesktop, isExtraLargeScreen: isLargeDesktop } =
    useScreenSizes()
  const { locationId, merchantId } = useParams<UseLocationRouteParams>()

  return (
    <StyledSecondaryRatingsContainer data-cy={dataCy}>
      <WidgetReviewBubble
        dataCy={dataCy}
        icon={reviewSite.displayName}
        reviewSiteName={reviewSite.displayName}
      />
      {reviewSite.isZeroState ? (
        <Button
          style={{ display: 'flex' }}
          action="primary"
          label={isLargeDesktop === isDesktop ? 'Add your Page' : 'Add'}
          icon={MoreIcon}
          href={`/${merchantId!}/locations/${locationId!}/settings/review-sites`}
        />
      ) : (
        <StyledSecondaryRatingsStatContainer data-cy={dataCy + '-stat'}>
          <StarIcon
            style={{ marginRight: theme.space(2) }}
            fill={theme.colors.accent_1}
            data-cy={dataCy + '-star-icon'}
          />
          <StyledWidgetStat data-cy={dataCy + '-rating'}>
            {reviewSite.rating || '-'}
          </StyledWidgetStat>
        </StyledSecondaryRatingsStatContainer>
      )}
    </StyledSecondaryRatingsContainer>
  )
}

const ReviewsRatingByReviewSiteWidget: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const { isReviewsZeroState, reviewSiteData, isReviewLoading } =
    useInsightsContext()

  if (isReviewLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  const [mainReviewSite, ...reviewSites] = reviewSiteData
  const mainReviewSiteDataCy = `${baseDataAttribute}-${
    mainReviewSite?.reviewSiteId || ''
  }`

  return (
    <InsightsWidget
      addDivider
      baseDataAttribute={baseDataAttribute}
      title={
        isReviewsZeroState
          ? 'Online Rating'
          : mainReviewSite && !mainReviewSite.isZeroState
          ? `${mainReviewSite.displayName} Rating`
          : ''
      }
      subtitle={
        isReviewsZeroState ? (
          'Add your Review sites to send review requests and to monitor your results.'
        ) : mainReviewSite ? (
          <StyledMainStatContainer data-cy={mainReviewSiteDataCy}>
            <StyledWidgetMainStat data-cy={mainReviewSiteDataCy + '-rating'}>
              {mainReviewSite.rating || '-'}
            </StyledWidgetMainStat>
            <StarIcon
              data-cy={mainReviewSiteDataCy + '-star-icon'}
              height={24}
              width={24}
              fill={theme.colors.accent_1}
              style={{
                margin: `0 ${theme.space(3)} 0 ${theme.space(1)}`,
              }}
            />
            <WidgetReviewBubble
              dataCy={mainReviewSiteDataCy}
              icon={mainReviewSite.displayName}
              reviewSiteName={mainReviewSite.displayName}
            />
          </StyledMainStatContainer>
        ) : undefined
      }
    >
      <StyledWidgetContentContainer
        data-cy={baseDataAttribute + '-review-sites'}
      >
        {isReviewsZeroState && mainReviewSite && (
          <ReviewSiteItem
            dataCy={mainReviewSiteDataCy}
            reviewSite={mainReviewSite}
          />
        )}
        {reviewSites.map((rsd, idx) => {
          const reviewSiteDataCy = `${baseDataAttribute}-${rsd.reviewSiteId!}`

          return (
            <ReviewSiteItem
              key={`reviews-rating-by-review-site-item-${idx}`}
              dataCy={reviewSiteDataCy}
              reviewSite={rsd}
            />
          )
        })}
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default ReviewsRatingByReviewSiteWidget
