import styled, { DefaultTheme, useTheme } from 'styled-components'

import {
  FacebookIcon,
  GoogleIcon,
  CompanyIcon,
  YelpIcon,
  StarIcon,
  HalfStarIcon,
} from 'src/stories/assets'

export const InsightsWidgetContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(3),
}))

export const StyledWidgetHeader = styled.div<{
  isElementNextToTitle?: boolean
}>(({ theme, isElementNextToTitle }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: `${isElementNextToTitle ? 'flex-start' : 'space-between'}`,
  padding: 0,
}))

export const StyledWidgetTitle = styled.h2<{ hasElementNext?: boolean }>(
  ({ theme, hasElementNext }) => ({
    margin: 0,
    fontSize: '1.6rem',
    fontWeight: 500,
    flexGrow: `${hasElementNext ? 'unset' : 1}`,
  })
)

export const StyledWidgetSubtitle = styled.p<{ topSpaced?: boolean }>(
  ({ theme, topSpaced = false }) => ({
    margin: 0,
    marginTop: theme.space(topSpaced ? 2 : 0),
    color: theme.colors.base_50,
    fontSize: '1.2rem',
    fontWeight: 400,
  })
)

export const StyledWidgetDivider = styled.div(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.base_20}`,
}))

export const StyledWidgetContentContainer = styled.div<{
  $gapUnits?: number
  $row?: boolean
  $grow?: boolean
  $spaceBetween?: boolean
}>(({ theme, $gapUnits = 2, $row, $grow, $spaceBetween }) => ({
  display: 'flex',
  flexDirection: $row ? 'row' : 'column',
  gap: theme.space($gapUnits),
  flexGrow: $grow ? 1 : undefined,
  justifyContent: $spaceBetween ? 'space-between' : undefined,
}))

export const StyledMainStatContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
}))

export const StyledWidgetMainStat = styled.h1<{ useSecondaryColor?: boolean }>(
  ({ theme, useSecondaryColor }) => ({
    margin: 0,
    fontSize: '4.4rem',
    fontWeight: 500,
    color: `${
      !!useSecondaryColor ? theme.colors.base_20 : theme.colors.primary_1
    }`,
  })
)

export const StyledWidgetStat = styled.p<{
  useSecondaryColor?: boolean
}>(({ theme, useSecondaryColor }) => ({
  margin: 0,
  fontSize: '1.4rem',
  fontWeight: 500,
  color: `${
    !!useSecondaryColor ? theme.colors.base_20 : theme.colors.primary_1
  }`,
}))

export const StyledIconTextContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(2),
}))

export const StyledWidgetBlackText = styled.p<{
  lighterColor?: boolean
  hideOverflow?: boolean
}>(({ theme, lighterColor, hideOverflow }) => ({
  display: '-webkit-box',
  margin: 0,
  color: !lighterColor ? theme.colors.base_100 : theme.colors.base_50,
  fontSize: '1.4rem',
  fontWeight: 400,
  flexGrow: 1,
  overflow: hideOverflow ? 'hidden' : undefined,
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflowX: 'hidden',
  minWidth: theme.space(11),
}))

export const StyledTimestamp = styled.p<{ $alignRight?: boolean }>(
  ({ theme, $alignRight }) => ({
    margin: 0,
    color: theme.colors.base_40,
    fontSize: '1.2rem',
    lineHeight: '2.6rem',
    textAlign: $alignRight ? 'right' : undefined,
  })
)

const StyledWidgetHeaderLogo = styled.h2(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.2rem',
  fontWeight: 500,
  margin: 0,
  color: theme.colors.base_50,
}))

const StyledWidgetHeaderSpan = styled.span(({ theme }) => ({
  fontSize: '1.4rem',
  fontWeight: 500,
  margin: 0,
  color: theme.colors.primary_1,
}))

export const DrivenBySignpost = () => {
  const theme = useTheme()

  return (
    <StyledWidgetHeaderLogo>
      Driven by <CompanyIcon style={{ margin: `0 ${theme.space(1)}` }} />
      <StyledWidgetHeaderSpan>Signpost</StyledWidgetHeaderSpan>
    </StyledWidgetHeaderLogo>
  )
}

const StyledWidgetDataItem = styled.div(({ theme }) => ({
  margin: 0,
  display: 'flex',
  justifyContent: 'space-between',
}))

export const WidgetDataItem: React.FC<{
  label: string
  value: string
  baseDataAttribute: string
}> = ({ baseDataAttribute, label, value }) => {
  const dataCy = `${baseDataAttribute}-${label
    .toLowerCase()
    .split(' ')
    .join('-')}`

  return (
    <StyledWidgetDataItem data-cy={dataCy}>
      <StyledWidgetBlackText lighterColor data-cy={dataCy + '-label'}>
        {label}:
      </StyledWidgetBlackText>
      <StyledWidgetStat data-cy={dataCy + '-value'}>{value}</StyledWidgetStat>
    </StyledWidgetDataItem>
  )
}

export const WidgetNameLink = styled.a<{
  $is2ExtraLargeScreen?: boolean
  $isLongText?: boolean
}>(({ theme, $is2ExtraLargeScreen, $isLongText }) => ({
  fontWeight: 400,
  fontSize: '1.6rem',
  paddingLeft: 0,
  paddingRight: 0,
  color: theme.colors.primary_2,
  height: theme.space(6),
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  maxWidth: $is2ExtraLargeScreen
    ? undefined
    : theme.space($isLongText ? 20 : 25),
}))

const StyledWidgetReviewBubbleContainer = styled.div<{
  $shouldGrow?: boolean
  $small?: boolean
}>(({ theme, $shouldGrow, $small }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `${theme.space(1)} ${theme.space(2)}`,
  borderRadius: '12px',
  border: `1px solid ${theme.colors.base_20}`,
  gap: theme.space(1),
  flexGrow: $shouldGrow ? 1 : undefined,
  maxWidth: $small ? theme.space(34.5) : undefined,
}))

const StyledWidgetReviewBubbleText = styled.p(({ theme }) => ({
  margin: 0,
  fontSize: '1.2rem',
  fontWeight: 500,
}))

export const WidgetReviewBubble: React.FC<{
  icon?: 'Google' | 'Facebook' | 'Yelp'
  reviewSiteName?: string
  rating?: number | null
  dataCy: string
  shouldGrow?: boolean
}> = ({ icon, reviewSiteName, dataCy, rating, shouldGrow }) => {
  const theme = useTheme()
  const getIcon = (name: 'Google' | 'Facebook' | 'Yelp') => {
    if (name === 'Google') {
      return <GoogleIcon width={15} height={15} data-cy={dataCy + '-icon'} />
    } else if (name === 'Facebook') {
      return <FacebookIcon width={16} height={16} data-cy={dataCy + '-icon'} />
    } else {
      return <YelpIcon width={15} height={15} data-cy={dataCy + '-icon'} />
    }
  }

  const showRating = typeof rating !== 'undefined'
  const _rating = rating || 0
  const filledStars = Math.floor(_rating)
  const halfStars = 5 % _rating > 0 ? 1 : 0
  const emptyStars = 5 - filledStars - halfStars

  return (
    <StyledWidgetReviewBubbleContainer
      data-cy={dataCy + '-bubble'}
      $shouldGrow={shouldGrow}
      $small={!reviewSiteName}
    >
      {icon && getIcon(icon)}
      {reviewSiteName && (
        <StyledWidgetReviewBubbleText data-cy={dataCy + '-label'}>
          {reviewSiteName}
        </StyledWidgetReviewBubbleText>
      )}
      {showRating && (
        <>
          {Array.from(Array(filledStars), (_, starIdx) => (
            <StarIcon
              key={`${dataCy}-star-${starIdx}`}
              fill={theme.colors.accent_1}
              height={12}
              width={12}
            />
          ))}
          {Array.from(Array(halfStars), (_, halfStarIdx) => (
            <HalfStarIcon
              key={`${dataCy}-half-star-${halfStarIdx}`}
              height={12}
              width={12}
            />
          ))}
          {Array.from(Array(emptyStars), (_, emptyStarIdx) => (
            <StarIcon
              key={`${dataCy}-empty-star-${emptyStarIdx}`}
              fill={theme.colors.base_10}
              height={12}
              width={12}
            />
          ))}
          <StyledWidgetStat data-cy={dataCy + '-rating'}>
            {_rating.toFixed(1) || '-'}
          </StyledWidgetStat>
        </>
      )}
    </StyledWidgetReviewBubbleContainer>
  )
}

export const StyledSummarySectionContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(2),
}))

const StyledRoundedSquare = styled.div<{
  $color?: keyof DefaultTheme['colors']
  $decreaseMargin?: boolean
}>(({ theme, $color, $decreaseMargin }) => ({
  width: theme.space(2),
  height: theme.space(2),
  backgroundColor: $color ? theme.colors[$color] : theme.colors.primary_1,
  borderRadius: '2px',
  alignSelf: 'center',
  marginLeft: $decreaseMargin ? theme.space(1) : theme.space(8),
}))

export const SummaryRowItem: React.FC<{
  color?: keyof DefaultTheme['colors']
  label: string
  value: number | string
  baseDataAttribute: string
  isZeroState: boolean
  decreaseMargin?: boolean
}> = ({
  color,
  baseDataAttribute,
  label,
  value,
  isZeroState,
  decreaseMargin,
}) => {
  const dataCy =
    `${baseDataAttribute}-` + label.toLowerCase().split(' ').join('-')

  return (
    <StyledSummarySectionContainer data-cy={dataCy}>
      <StyledRoundedSquare
        $color={color}
        data-cy={dataCy + '-square'}
        $decreaseMargin={decreaseMargin}
      />
      <StyledWidgetBlackText data-cy={dataCy + '-label'}>
        {label}
      </StyledWidgetBlackText>
      <StyledWidgetStat data-cy={dataCy + '-value'}>
        {isZeroState ? '\u2013' : value.toLocaleString()}
      </StyledWidgetStat>
    </StyledSummarySectionContainer>
  )
}

const StyledLegendName = styled.div(({ theme }) => ({
  width: theme.space(12),
  float: 'left',
  textAlign: 'left',
  paddingRight: theme.space(5),
  textTransform: 'capitalize',
}))

const StyledLegendPercentage = styled.div(({ theme }) => ({
  width: theme.space(38),
  textAlign: 'right',
  paddingLeft: theme.space(5),
  color: theme.colors.primary_1,
}))

export const StyledLegendItem: React.FC<{
  name: string
  percentage: number
}> = ({ name, percentage }) => {
  return (
    <div>
      <StyledLegendName>{name}</StyledLegendName>
      <StyledLegendPercentage>{percentage.toFixed(0)}%</StyledLegendPercentage>
    </div>
  )
}
