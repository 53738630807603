import React from 'react'
import {
  PreferredServiceOfferingTerm as PreferredServiceOfferingTermEnum,
  preferredServiceOfferingTermMap,
} from 'src/client/interfaces/Common'
import { Body, Heading } from 'src/stories/typography'

interface Props {
  serviceTerm: PreferredServiceOfferingTermEnum
}

const PreferredServiceOfferingTerm: React.FC<Props> = ({ serviceTerm }) => {
  return (
    <>
      <Heading as="h3">
        How should we refer to your customers&apos; experiences?
      </Heading>
      <Body>
        Selected:{' '}
        <Body as="span" color="darker" textTransform="capitalize">
          {preferredServiceOfferingTermMap[serviceTerm].noun}
        </Body>
        <br />
        Example: &ldquo;Thank you for your{' '}
        <Body as="span" color="darker">
          {preferredServiceOfferingTermMap[serviceTerm].noun}
        </Body>
        &rdquo;
      </Body>
    </>
  )
}

export default PreferredServiceOfferingTerm
