import { useState, useEffect, useCallback } from 'react'

import { useTheme } from 'styled-components'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import PageNotAvailableModal from 'src/components/Modals/PageNotAvailable'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

const blockListedUrls = ['campaigns', 'upload-contacts', 'integrations']

const usePageNotAvailableModal = ({ url }: { url: string }) => {
  const { showModal, closeModal } = useModalNotificationsContext()
  const [shouldShowModal, setShouldShowModal] = useState(false)
  const { isLargeScreen } = useScreenSizes()

  const theme = useTheme()

  const showPageNotAvailableModal = useCallback(() => {
    showModal({
      dataCy: 'mh-page-not-available-modal',
      width: theme.space(240),
      hideActionButtons: true,
      canClose: false,
      headerColor: 'base_0', //white
      maxSpacedModal: true,
      customBody: <PageNotAvailableModal />,
    })
  }, [showModal, theme])

  const validateUrl = useCallback(() => {
    // /[/?]/ Regex expression to separate by "/" or "?"
    const urlParts = url.split(/[/?]/)

    const isBlockListedUrl = urlParts.find((p) => blockListedUrls.includes(p))

    if (isBlockListedUrl && !isLargeScreen) {
      setShouldShowModal(true)
      showPageNotAvailableModal()
    }
  }, [url, showPageNotAvailableModal, isLargeScreen])

  useEffect(() => {
    if (shouldShowModal) {
      // If the screen resolution changed to an upper resolution
      // we should close the modal and set the shouldShowModal variable to false
      closeModal()
      setShouldShowModal(false)
    }
  }, [validateUrl, isLargeScreen, shouldShowModal, closeModal])

  return {
    shouldShowModal,
  }
}

export default usePageNotAvailableModal
