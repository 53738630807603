import { useQuery } from '@tanstack/react-query'
import { ConversationsResource } from 'src/client'
import { PaginationDTO } from 'src/client/interfaces/Common'
import logger from 'src/utils/logger'

const usePublicReviews = ({
  locationId,
  pagination,
  reviewSites,
}: {
  locationId: number
  pagination: PaginationDTO
  reviewSites?: number[]
}) => {
  return useQuery({
    queryKey: [
      'publicReviews',
      {
        locationId,
        pagination,
        reviewSites,
      },
    ] as const,
    queryFn: async ({ queryKey }) =>
      ConversationsResource.findPublicReviews(queryKey[1]),
    onError: (error) => {
      logger.error('WAF usePublicReviews - error', { error })
    },
    staleTime: 60_000,
  })
}

export default usePublicReviews
