import React from 'react'

import {
  BodyProps,
  HeadingProps,
  SubtitleProps,
  TextProps,
} from './Typography.types'
import {
  BodyStyled,
  HeadingStyled,
  SubtitleStyled,
  TextStyled,
} from './Typography.styles'

/**
 * A generic typography component to be used when no other component fits that text style.
 * WARNING: Avoid using, always check again with the design to confirm that is an exception.
 */
export const Text: React.FCWithChildren<TextProps> = ({
  as = 'span',
  color = 'medium',
  fontWeight = 'medium',
  textAlign = 'left',
  ...props
}) => {
  return (
    <TextStyled
      as={as}
      color={color}
      fontWeight={fontWeight}
      textAlign={textAlign}
      {...props}
    />
  )
}

export const Heading: React.FCWithChildren<HeadingProps> = ({
  size = 'medium',
  as = 'h2',
  ...props
}) => {
  return <HeadingStyled as={as} size={size} {...props} />
}

export const Subtitle: React.FCWithChildren<SubtitleProps> = ({
  size = 'medium',
  as = 'p',
  ...props
}) => {
  return <SubtitleStyled as={as} size={size} {...props} />
}

export const Body: React.FCWithChildren<BodyProps> = ({
  size = 'medium',
  as = 'p',
  color,
  fontWeight,
  ...props
}) => {
  return (
    <BodyStyled
      as={as}
      size={size}
      fontWeight={fontWeight}
      color={color}
      {...props}
    />
  )
}
