import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import OverviewStep from './OverviewStep'
import SetupCompleteStep from './SetupCompleteStep'
import useAccountContext from 'src/contexts/AccountContext'
import Wizard from 'src/stories/Wizard'
import { generateLocationUrl, getActiveLocation } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import useOauth from 'src/hooks/useOauth'
import { IntegrationsResource, LocationsResource } from 'src/client'
import Constants from 'src/lib/Constants'
import logger from 'src/utils/logger'
import Integrations from 'src/client/resources/Integrations'
import { useThumbtackIntegrationData } from 'src/containers/Settings/InstantResponders/hooks'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import ConnectionInProgressModal from 'src/components/Integrations/Modals/ConnectionInProgressModal'
import ConnectionFailedModal from 'src/components/Integrations/Modals/ConnectionFailedModal'

const url =
  `https://thumbtack.com/services/partner-connect/` +
  `?client_id=${process.env.REACT_APP_THUMBTACK_CLIENT_ID || ''}` +
  `&redirect_uri=${process.env.REACT_APP_THUMBTACK_REDIRECT_URI || ''}` +
  `&response_type=code&scope=messages`

const ThumbtackConnectionWizard: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [showSteps, setShowSteps] = useState(true)
  const [isConnecting, setIsConnecting] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(true)
  const navigate = useNavigate()
  const { locationId, merchantId } = useParams<UseLocationRouteParams>()
  const { showModal, closeModal } = useModalNotificationsContext()

  const { locations, handleRefetchUserLocations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)
  const { isConnected } = useThumbtackIntegrationData({ activeLocation })

  const onCode = async (code: string) => {
    try {
      const { locationExternalId } = await Integrations.handleThumbtackCode({
        locationId: activeLocation!.locationId,
        code,
      })

      await IntegrationsResource.connectIntegration({
        integration: Constants.INTEGRATIONS.THUMBTACK,
        locationId: activeLocation!.locationId,
        syncConfig: {
          externalId: locationExternalId,
        },
      })

      await LocationsResource.toggleFeatureCode({
        enable: true,
        featureCode: Constants.FEATURE_CODES.syncThumbtack,
        locationId: activeLocation!.locationId,
      })

      await LocationsResource.toggleFeatureCode({
        enable: true,
        featureCode: Constants.FEATURE_CODES.sendThumbtackAutoresponse,
        locationId: activeLocation!.locationId,
      })

      setShouldRedirect(false)
      await handleRefetchUserLocations()

      setShowSteps(false)
      setCurrentIndex(1)
      closeModal()
    } catch (error) {
      logger.debug('Thumbtack Instant Responder - connect to thumbtack', {
        error,
      })

      showModal({
        hideActionButtons: true,
        title: 'Integration error',
        height: 'auto',
        headerColor: 'critical',
        customBody: <ConnectionFailedModal integrationName={'Thumbtack'} />,
      })
    } finally {
      setIsConnecting(false)
    }
  }

  const { onConnect } = useOauth({ onCode, integrationName: 'THUMBTACK' })

  const nextStep = () => {
    showModal({
      hideActionButtons: true,
      title: 'Connection in progress',
      height: 'auto',
      canClose: false,
      customBody: <ConnectionInProgressModal />,
    })
    setIsConnecting(true)
    onConnect(url)
  }

  const exitWizard = () => {
    const instantRespondersSettingsSection = generateLocationUrl(
      merchantId!,
      locationId!,
      '/settings#section-settings-instant-responders'
    )

    navigate(instantRespondersSettingsSection)
  }

  // If Thumbtack integration is connected we redirect to the IR screen
  if (isConnected && shouldRedirect) {
    exitWizard()
  }

  const stepLabels = [{ label: 'Connect thumbtack' }]
  const stepContent = [
    <OverviewStep nextStep={nextStep} isConnecting={isConnecting} />,
    <SetupCompleteStep nextStep={exitWizard} />,
  ]

  return (
    <Wizard
      title="Connect Thumbtack"
      onClickGoBack={exitWizard}
      steps={stepLabels}
      showSteps={showSteps}
      currentStepIndex={currentIndex}
      onClickStep={(index) => setCurrentIndex(index)}
      disabled={false}
    >
      {stepContent[currentIndex]}
    </Wizard>
  )
}

export default ThumbtackConnectionWizard
