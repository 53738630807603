import React, { ReactElement } from 'react'
import { Table as ReactTable } from '@tanstack/react-table'
import styled, { useTheme } from 'styled-components'

import { ArrowIcon } from '../assets'
import { Button } from '../Button'
import Select from '../Select'
import { Body } from '../typography'

export interface PaginationProps<T> {
  includeFirstLastSelectors?: boolean
  table: ReactTable<T>
}

const StyledArrowIcon = styled(ArrowIcon)(({ theme }) => ({
  // Important is necessary because Button overrides SVG styles
  // and adds a margin-right
  marginRight: '0 !important',
}))

const PaginationContainer = styled.div(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  display: 'flex',
  gap: theme.space(3),
}))

const PaginationButton = styled(Button)(({ theme, disabled }) => ({
  display: 'flex',
  border: `1px solid ${theme.colors.base_60}`,
  height: theme.space(6),
  background: theme.colors.base_10,
  ':disabled': {
    cursor: 'auto !important',
    background: theme.colors.base_20,
  },
  alignItems: 'center',
}))

const PageText = styled(Body)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: 0,
}))

const FirstAndLastSelectorContainer = styled.span({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const ButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(2),
}))

export const TablePagination = <T extends object>({
  includeFirstLastSelectors = false,
  table,
}: PaginationProps<T>): ReactElement => {
  const theme = useTheme()
  const options = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  const currentIdx = table.getState().pagination.pageIndex
  const currentPageSize = table.getState().pagination.pageSize
  const initialRange = currentIdx * currentPageSize + 1
  const finalRange = initialRange + table.getRowModel().rows.length

  return (
    <PaginationContainer>
      <PageText>Show</PageText>
      <Select
        options={options}
        height={theme.space(8)}
        containerStyle={{ flexGrow: 0 }}
        openedOptionsDirection="up"
        initialValue={table.getState().pagination.pageSize}
        onChange={(size) => table.setPageSize(size)}
      />
      <PageText>
        {initialRange} - {finalRange}
      </PageText>
      <ButtonsContainer>
        {includeFirstLastSelectors && (
          <PaginationButton
            action="secondary"
            label={
              <FirstAndLastSelectorContainer>
                <StyledArrowIcon />
                <StyledArrowIcon />
              </FirstAndLastSelectorContainer>
            }
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.setPageIndex(0)}
          />
        )}
        <PaginationButton
          action="secondary"
          label={<StyledArrowIcon />}
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        />
        <PaginationButton
          action="secondary"
          label={<StyledArrowIcon style={{ transform: 'rotate(180deg)' }} />}
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
        />
        {includeFirstLastSelectors && (
          <PaginationButton
            action="secondary"
            label={
              <FirstAndLastSelectorContainer>
                <StyledArrowIcon style={{ transform: 'rotate(180deg)' }} />
                <StyledArrowIcon style={{ transform: 'rotate(180deg)' }} />
              </FirstAndLastSelectorContainer>
            }
            disabled={!table.getCanNextPage()}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          />
        )}
      </ButtonsContainer>
    </PaginationContainer>
  )
}
