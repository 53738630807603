import { format } from 'date-fns'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import Constants from 'src/lib/Constants'
import {
  ConversationResource,
  conversationEventTypePredicate,
} from 'src/client/interfaces/Conversations'
import useMhContext from 'src/contexts/MhContext'
import useAccountContext from 'src/contexts/AccountContext'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { formatDurationFromSeconds, getActiveLocation } from 'src/utils'
import { extractFeedbackOutcome } from 'src/components/MessagingHub/styled'
import ReviewBubble from 'src/components/MessagingHub/MessagesPane/ReviewBubble'
import MessageBubble from 'src/components/MessagingHub/MessagesPane/MessageBubble'
import ThumbtackLeadContent from 'src/components/MessagingHub/MessagesPane/ThumbtackLeadContent'
import PhoneCallRecordingPlayer from 'src/components/MessagingHub/MessagesPane/PhoneCallRecordingPlayer'
import HomeAdvisorMessageContent from 'src/components/MessagingHub/MessagesPane/HomeAdvisorMessageContent'
import LiveReceptionistMessageContent from 'src/components/MessagingHub/MessagesPane/LiveReceptionistMessageContent'

const StyledMessagesContainer = styled.div<{ $isDesktop: boolean }>(
  ({ theme, $isDesktop }) => ({
    height: '100%',
    overflowY: 'scroll',
    scrollbarGutter: 'stable',
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingLeft: $isDesktop ? theme.space(4) : theme.space(3),
    paddingRight: theme.space(2),
  })
)

const StyledLabel = styled.strong(() => ({ margin: 0 }))
const StyledParagraph = styled.p(() => ({ margin: 0 }))

const StyledFeedbackContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))
const StyledFeedbackOutcomeHeader = styled.div(({ theme }) => ({
  margin: 0,
  display: 'inline-grid',
  gridTemplateColumns: `${theme.space(4)} auto`,
}))
const StyledFeedbackOutcomeText = styled.p(({ theme }) => ({
  margin: 0,
  marginLeft: theme.space(2),
  color: theme.colors.base_100,
  fontWeight: 500,
}))
const StyledFeedbackText = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.colors.base_50,
  fontWeight: 400,
}))

interface MessagesListProps {
  contactName?: string
  contactPhone?: string
  contactEmail?: string
  getMore: () => void
  hasMoreMessages: boolean
}

const MessagesList: React.FC<MessagesListProps> = ({
  contactName,
  getMore,
  hasMoreMessages,
  contactEmail,
  contactPhone,
}) => {
  const { locations } = useAccountContext()
  let { locationId } = useParams<UseLocationRouteParams>()
  const {
    openConversationId,
    messagesList,
    infoEvents,
    isLargeDesktop: isDesktop,
  } = useMhContext()
  const activeLocation = getActiveLocation(locations, locationId)

  let locationName: string
  const locationReviewSites: {
    reviewSiteId: number
    reviewSiteUrl: string
    reviewSiteDisplayName: string
  }[] = []

  if (locations) {
    const currentLocation = locations.find((location) => {
      return locationId && location.locationId === +locationId
    })

    currentLocation?.locationReviewSites.forEach((lrs) => {
      locationReviewSites.push({
        reviewSiteId: lrs.reviewSiteId,
        reviewSiteUrl: lrs.url,
        reviewSiteDisplayName:
          Object.values(Constants.ReviewSites).find(
            (s) => s.id === lrs.reviewSiteId
          )?.displayName ?? '',
      })
    })

    locationName =
      currentLocation && currentLocation.name ? currentLocation.name : ''
  } else {
    locationName = ''
  }

  return (
    <StyledMessagesContainer
      id="scrollableDiv"
      data-cy="messages-list"
      $isDesktop={isDesktop}
    >
      <InfiniteScroll
        dataLength={messagesList.length}
        next={getMore}
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          overflow: 'hidden',
        }}
        inverse={true}
        hasMore={hasMoreMessages}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        {infoEvents.map((ie, idx) => {
          const icon =
            ie.eventType === 'FACEBOOK'
              ? 'Facebook'
              : ie.eventType === 'GOOGLE'
              ? 'GoogleMessages'
              : undefined

          return (
            <MessageBubble
              key={`individual-info-event-${idx}`}
              showAsLoading={false}
              body={ie.message}
              date={format(Date.now(), 'yyyy-MM-dd HH:mm:ss')}
              contactName={contactName ?? ''}
              sourceType="system"
              isWarning={true}
              isSimplified={true}
              isAutomatedMessage={false}
              icon={icon}
              isEmptyBackground={false}
              dataCy={`mh-messages-list-message-bubble-info-event${idx}`}
            />
          )
        })}
        {
          messagesList.reduce(
            (a, m, idx) => {
              const timestamp = new Date(m.timestamp ?? '').toISOString()

              // Instant Responses
              if (m.isAutoResponse) {
                let formattedInstantResponseType = ''
                let bodyDescription = 'Instant Response:'
                let errorMessage = ''

                if (m.eventType === 'THUMBTACK') {
                  formattedInstantResponseType = 'Thumbtack'
                  bodyDescription =
                    formattedInstantResponseType + ' ' + bodyDescription
                  errorMessage = m.failureDetails?.reason || ''
                }

                a.messages.push(
                  <MessageBubble
                    key={`individual-message-${idx}-ir`}
                    bodyDescription={bodyDescription}
                    sentViaLabel={formattedInstantResponseType}
                    body={m.message}
                    date={timestamp}
                    contactName="Hibu"
                    sourceType={m.eventDirection}
                    errorMessage={errorMessage}
                    isWarning={false /*!!m.isWarning*/}
                    isAutomatedMessage={true}
                    icon="Hibu"
                    dataCy={`mh-messages-list-message-bubble-${idx}`}
                  />
                )
              } else if (conversationEventTypePredicate(m, 'PHONE_CALL')) {
                // Transcript
                if (m.phoneCallEventData.transcriptionText) {
                  a.messages.push(
                    <MessageBubble
                      key={`individual-message-${idx}-call-transcript`}
                      body=""
                      sourceType={m.eventDirection}
                      contactName={contactName || ''}
                      date={timestamp}
                      isSimplified
                      dataCy={`mh-messages-list-message-bubble-${idx}`}
                    >
                      <StyledLabel>Call transcript:</StyledLabel>
                      <StyledParagraph>
                        {m.phoneCallEventData.transcriptionText}
                      </StyledParagraph>
                    </MessageBubble>
                  )
                }

                // Voicemail Recording
                if (m.phoneCallEventData.recordingUrl) {
                  // Call recording
                  a.messages.push(
                    <MessageBubble
                      key={`individual-message-${idx}-call-recording`}
                      dataCy={`mh-messages-list-call-recording-player-${idx}`}
                      body=""
                      sourceType={m.eventDirection}
                      contactName={contactName || ''}
                      date={timestamp}
                      icon="Phone"
                    >
                      <PhoneCallRecordingPlayer
                        recordingUrl={m.phoneCallEventData.recordingUrl}
                      />
                    </MessageBubble>
                  )

                  // Voice response acknowledgment
                  a.messages.push(
                    <MessageBubble
                      key={`individual-message-${idx}-response-acknowledgment`}
                      bodyDescription="Hibu played your voice message for missed calls"
                      sourceType={m.eventDirection}
                      isAutomatedMessage={true}
                      contactName="Hibu"
                      date={timestamp}
                      icon="Hibu"
                      dataCy={`mh-messages-list-message-bubble-${idx}`}
                    />
                  )
                }

                // Call message
                a.messages.push(
                  <MessageBubble
                    key={`individual-message-${idx}-incoming-call`}
                    bodyDescription={`Inbound call${
                      m.phoneCallEventData.duration
                        ? ` lasted ${formatDurationFromSeconds(
                            m.phoneCallEventData.duration
                          )}`
                        : ''
                    }`}
                    sourceType={m.eventDirection}
                    contactName={contactName || ''}
                    date={timestamp}
                    icon="Phone"
                    dataCy={`mh-messages-list-message-bubble-${idx}`}
                  />
                )
              } else if (conversationEventTypePredicate(m, 'REVIEW')) {
                const conversationItem: ConversationResource = {
                  id: m.id,
                  resourceType: 'ConversationResource',
                  contactId: openConversationId ?? 0,
                  contact: {
                    id: openConversationId ?? 0,
                    channels: [],
                    segments: [],
                    addedAt: timestamp,
                  },
                  isConversationDeleted: false,
                  mostRecentEvent: {
                    ...m,
                  },
                  contactDisplayName: contactName || '',
                  isConversationArchived: false,
                  isConversationRead: true,
                }

                a.messages.push(
                  <ReviewBubble
                    key={`individual-message-${idx}-review`}
                    isDesktop={isDesktop}
                    activeConversation={conversationItem}
                    isUnconfirmed={false}
                    dataCy={`mh-messages-list-message-bubble-${idx}`}
                  />
                )
              } else if (conversationEventTypePredicate(m, 'FEEDBACK')) {
                const denominator = activeLocation?.feedbackRatingDenominator

                const { feedbackIcon, feedbackOutcomeText, feedbackText } =
                  extractFeedbackOutcome(
                    denominator ?? 5,
                    true,
                    m.message,
                    m.feedbackEventData?.rating
                  )

                a.messages.push(
                  <MessageBubble
                    key={`individual-message-${idx}-feedback`}
                    body=""
                    date={timestamp}
                    contactName={contactName || ''}
                    sourceType={m.eventDirection}
                    icon="Feedback"
                    isEmptyBackground={true}
                    dataCy={`mh-messages-list-message-bubble-${idx}`}
                  >
                    <StyledFeedbackContainer>
                      <StyledFeedbackOutcomeHeader>
                        {feedbackIcon}
                        <StyledFeedbackOutcomeText>
                          {feedbackOutcomeText}
                        </StyledFeedbackOutcomeText>
                      </StyledFeedbackOutcomeHeader>
                      <StyledFeedbackText>{feedbackText}</StyledFeedbackText>
                    </StyledFeedbackContainer>
                  </MessageBubble>
                )
              } else if (
                conversationEventTypePredicate(m, 'LIVE_RECEPTIONIST')
              ) {
                a.messages.push(
                  <LiveReceptionistMessageContent
                    key={idx}
                    message={m}
                    contactId={openConversationId!}
                    contactName={contactName}
                    idx={idx}
                  />
                )
              } else {
                let fingerprint = m.fingerprint
                let body = m.message
                let media = m.media

                if (
                  conversationEventTypePredicate(m, 'AGENTZ') &&
                  m.agentzEventData.type ===
                    Constants.COMMS.interactionTypes.liveSupportMessage
                ) {
                  if (body.search('imageCarousels') !== -1) {
                    const jsonBody = JSON.parse(body) as {
                      imageCarousels: { url: string }[]
                    }

                    media = [
                      {
                        contentType: 'image',
                        url: jsonBody.imageCarousels[0].url,
                      },
                    ]
                  }
                }

                const isHomeAdvisorInteraction = conversationEventTypePredicate(
                  m,
                  'ANGILEADS'
                )

                const isFacebookInteraction =
                  conversationEventTypePredicate(m, 'FACEBOOK') &&
                  m.eventDirection === 'incoming'

                const isGoogleMessagesInteraction =
                  conversationEventTypePredicate(m, 'GOOGLE') &&
                  m.eventDirection === 'incoming'

                const isThumbtackInteraction = conversationEventTypePredicate(
                  m,
                  'THUMBTACK'
                )

                const isThumbtackLeadInteraction =
                  conversationEventTypePredicate(m, 'THUMBTACK') &&
                  m.thumbtackEventData?.lead

                const isLiveReceptionistInteraction =
                  conversationEventTypePredicate(m, 'LIVE_RECEPTIONIST')

                const isMissedCallAcknowledgement =
                  m.isAutoResponse && conversationEventTypePredicate(m, 'SMS')

                const isOutgoingReviewRequest =
                  m.eventDirection === 'outgoing' &&
                  (conversationEventTypePredicate(m, 'REVIEW') ||
                    conversationEventTypePredicate(m, 'PUBLIC_REVIEW'))

                const isOutgoingFeedbackRequest =
                  m.eventDirection === 'outgoing' &&
                  conversationEventTypePredicate(m, 'FEEDBACK')

                const icon = isMissedCallAcknowledgement
                  ? 'Hibu'
                  : isHomeAdvisorInteraction
                  ? 'Homeadvisor'
                  : isFacebookInteraction
                  ? 'Facebook'
                  : isGoogleMessagesInteraction
                  ? 'GoogleMessages'
                  : isThumbtackInteraction
                  ? 'Thumbtack'
                  : isLiveReceptionistInteraction
                  ? 'LiveReceptionist'
                  : isOutgoingReviewRequest
                  ? 'Review'
                  : isOutgoingFeedbackRequest
                  ? 'Feedback'
                  : undefined

                const message = (
                  <MessageBubble
                    key={`individual-message-${idx}`}
                    showAsLoading={false /*TODO: m.showAsLoading */}
                    body={body}
                    date={timestamp}
                    media={media}
                    contactName={
                      m.eventDirection === 'outgoing'
                        ? locationName
                        : contactName || ''
                    }
                    sourceType={m.eventDirection}
                    eventType={m.eventType}
                    errorMessage={m.failureDetails?.reason}
                    isWarning={false}
                    isSimplified={false}
                    isAutomatedMessage={
                      isMissedCallAcknowledgement ||
                      isOutgoingReviewRequest ||
                      isOutgoingFeedbackRequest
                    }
                    icon={icon}
                    isEmptyBackground={
                      isOutgoingReviewRequest || isOutgoingFeedbackRequest
                    }
                    dataCy={`mh-messages-list-message-bubble-${idx}`}
                  >
                    {isHomeAdvisorInteraction && (
                      <HomeAdvisorMessageContent
                        message={m}
                        contactName={contactName ?? ''}
                        email={contactEmail}
                        phoneNumber={contactPhone}
                      />
                    )}
                    {isThumbtackLeadInteraction && (
                      <ThumbtackLeadContent
                        message={m}
                        contactName={contactName ?? ''}
                        phoneNumber={contactPhone}
                      />
                    )}
                  </MessageBubble>
                )

                // https://github.com/signpost/biscuit/blob/master/src/redux/selectors/ChannelEvents.js#L10-L32
                if (!fingerprint) {
                  a.messages.push(message)
                } else if (!a.fingerprints[fingerprint]) {
                  a.fingerprints[fingerprint] = true

                  a.messages.push(message)
                }
              }

              return a
            },
            {
              messages: [] as JSX.Element[],
              fingerprints: {} as Record<string, boolean>,
            }
          ).messages
        }
      </InfiniteScroll>
    </StyledMessagesContainer>
  )
}

export default MessagesList
