import { isBefore, subDays } from 'date-fns'
import { toast } from 'react-toastify'
import { Segment } from 'src/client/interfaces/Segments'

import { MediaResource } from '../../client'
import client from '../../client/client'
import { ErrorObject, Media } from '../../client/interfaces/Common'
import Constants, { CommsSourceTypeKey } from '../../lib/Constants'
import { FileTooBigError } from '../../lib/Errors'
import { getCookie } from '../../utils'
import logger from '../../utils/logger'
import { ConversationListItem } from '../ConversationsListContext/types'
import {
  ConversationEventResource,
  ConversationEventType,
  conversationEventTypePredicate,
} from 'src/client/interfaces/Conversations'
/**
 * The general rule is that we reply directly (via the same message system) as the most
 recent incoming interaction, since that's what a contact would expect. Then we keep
 using that same method & channel.
 But some incoming message systems don't allow sending, so we fall back to using SMS when possible.
 */
export const getOutgoingEventType = (message: ConversationEventResource) => {
  if (
    (
      [
        'LIVE_RECEPTIONIST',
        'ANGILEADS',
        'SMS',
        'THUMBTACK',
        'FEEDBACK',
        'REVIEW',
      ] as const
    ).some((e) => conversationEventTypePredicate(message, e))
  ) {
    return 'SMS'
  }

  return message.eventType
}

export const getLegacyDocumentType = (message: ConversationEventResource) => {
  switch (message.eventType) {
    case 'AGENTZ':
      return message.eventDirection === 'incoming'
        ? Constants.COMMS.documentTypes.agentzInteraction
        : Constants.COMMS.documentTypes.agentzComm
    case 'ANGILEADS':
      return Constants.COMMS.documentTypes.homeAdvisorInteraction
    case 'FACEBOOK':
      return message.eventDirection === 'incoming'
        ? Constants.COMMS.documentTypes.facebookInteraction
        : Constants.COMMS.documentTypes.facebookComm
    case 'FEEDBACK':
      return Constants.COMMS.documentTypes.feedback
    case 'GOOGLE':
      return message.eventDirection === 'incoming'
        ? Constants.COMMS.documentTypes.googleMessagesInteraction
        : Constants.COMMS.documentTypes.googleMessagesComm
    case 'LIVE_RECEPTIONIST':
      return Constants.COMMS.documentTypes.liveReceptionistInteraction
    case 'PHONE_CALL':
      return Constants.COMMS.documentTypes.phoneCallInteraction
    case 'PUBLIC_REVIEW':
    case 'REVIEW':
      return Constants.COMMS.documentTypes.review
    case 'SMS':
      return Constants.COMMS.documentTypes.smsInteraction
    case 'THUMBTACK':
      return Constants.COMMS.documentTypes.thumbtackInteraction
  }
}

/**
 * lastMessageReceived will always be defined, since all conversations must have
    at least one message to exist.
    Clarification: This will usually return the most recent message (whether incoming
    or outgoing). An exception is during Agentz chats, where it matters to use
    the actual "last received".
    This "use the last message sent too" behavior was added as a shortcut, because in long
    conversations the Messaging Hub might not have the "last message received" loaded.
    This can prevent us from passing the correct "asReplyTo" interaction ID when sending,
    but as of 2022-08-29, the asReplyTo only has user impact in Agentz comms.

    TODO: Rename this function and the lastMessageReceived field to match what it
    is actually doing, or make it reliably return the actual lastMessageReceived.
    It needs to be refactored, compute outside of the
    handleSendMessage and pass down the components tree to where it's needed (HA and Agentz
    not replyable)
 */
export const getLastMessageReceivedFromMessagesList = (
  messagesList: ConversationEventResource[]
) =>
  messagesList.find((m) => {
    // During Agentz and Google chats, ensure that we find the most recent actual interaction,
    // because that interaction ID is needing for sending API functionality.
    if (
      (conversationEventTypePredicate(m, 'AGENTZ') ||
        conversationEventTypePredicate(m, 'GOOGLE') ||
        conversationEventTypePredicate(m, 'THUMBTACK')) &&
      m.eventDirection === 'outgoing'
    ) {
      return false
    }

    return true
  })

export const isHomeAdvisorUnreplyable = (
  lastMessage: ConversationEventResource,
  contactIsSmsReachable?: boolean
) =>
  conversationEventTypePredicate(lastMessage, 'ANGILEADS') &&
  !contactIsSmsReachable

export const isAgentzUnreplyable = (
  lastMessage: ConversationEventResource,
  contactIsSmsReachable?: boolean
) =>
  (conversationEventTypePredicate(lastMessage, 'AGENTZ') &&
    !lastMessage.agentzEventData.replyUrl &&
    !contactIsSmsReachable) ||
  (conversationEventTypePredicate(lastMessage, 'AGENTZ') &&
    lastMessage.agentzEventData.type ===
      Constants.COMMS.interactionTypes.liveSupportOutgoingMessage)

export const isFacebookUnreplyable = (
  listOfMessages?: ConversationEventResource[]
) => {
  if (!listOfMessages?.length) return false

  const facebookUnreplyable =
    conversationEventTypePredicate(listOfMessages[0], 'FACEBOOK') &&
    isBefore(
      listOfMessages[0].timestamp
        ? new Date(listOfMessages[0].timestamp)
        : new Date(),
      subDays(new Date(), Constants.COMMS.maxLimitDaysToReply.facebook)
    )

  return facebookUnreplyable
}

export const isGoogleMessagesUnreplyable = (
  listOfMessages?: ConversationEventResource[]
) => {
  if (!listOfMessages?.length) return false

  const googleMessagesUnreplyable =
    conversationEventTypePredicate(listOfMessages[0], 'GOOGLE') &&
    isBefore(
      listOfMessages[0].timestamp
        ? new Date(listOfMessages[0].timestamp)
        : new Date(),
      subDays(new Date(), Constants.COMMS.maxLimitDaysToReply.googleMessages)
    )

  return googleMessagesUnreplyable
}

export const isSmsAreaCodeUnreplyable = (
  listOfMessages?: ConversationEventResource[]
) => {
  if (!listOfMessages?.length) return false

  const isThumbtackReplyMethodEnabled = !!localStorage.getItem(
    'SP_ENABLE_THUMBTACK_METHOD'
  )

  const smsAreaCodeUnreplyable = Constants.COMMS.getCommTypes({
    commDocTypes: 'smsDocumentTypes',
    isThumbtackReplyMethodEnabled,
  }).some(
    (sdt) =>
      sdt === getLegacyDocumentType(listOfMessages[0]) &&
      listOfMessages[0].failureDetails?.reason ===
        Constants.COMMS.blockedAreaCodeReason
  )

  return smsAreaCodeUnreplyable
}

export const isRecentSmsFailed = (
  listOfMessages?: ConversationEventResource[]
) => {
  if (!listOfMessages?.length) return false

  const moreRecentOutgoingMessageHasFailed =
    listOfMessages.find(
      (message: ConversationEventResource) =>
        message.eventDirection === 'outgoing'
    )?.failureDetails?.reason === Constants.COMMS.failedDeliveryReason

  return moreRecentOutgoingMessageHasFailed
}

export const isFeedbackReviewUnreplyable = (
  lastMessage: ConversationEventResource,
  contactIsSmsReachable?: boolean
) =>
  (conversationEventTypePredicate(lastMessage, 'REVIEW') ||
    conversationEventTypePredicate(lastMessage, 'FEEDBACK')) &&
  !contactIsSmsReachable

export const handleUploadMedia = async (medias: File[]): Promise<Media[]> => {
  const [fulfilled, rejected] = await MediaResource.bulkUploadMedia(
    medias,
    'SMS'
  )

  rejected.forEach(({ reason }) => {
    let errorMessage = Constants.ERRORS.imageUploadError

    if (reason instanceof FileTooBigError) {
      errorMessage = reason.errorMessageToDisplay
    }

    logger.error(errorMessage)
    toast.error(errorMessage)
  })

  return fulfilled.map((f) => f.value)
}

export const isEditableConversationItem = (
  conversationItem: ConversationListItem
) => conversationItem.id !== -1

export type SegmentInterfaceWithIsPrimary = Segment & {
  isPrimary: boolean
}

export const sortSegments = (
  segments: Segment[],
  segmentIds: number[],
  primarySegmentId: number
): SegmentInterfaceWithIsPrimary[] => {
  let primarySegment: SegmentInterfaceWithIsPrimary[] = []

  const segmentsWithPrimary =
    segments.reduce(
      (a, segment) => {
        const hasSegmentId = segmentIds.some(
          (segmentId) => segmentId === segment.id
        )
        const isPrimary = segment.id === primarySegmentId

        const mappedSegment: SegmentInterfaceWithIsPrimary = {
          ...segment,
          isPrimary,
        }

        if (isPrimary) {
          primarySegment = [mappedSegment]
        }

        if (hasSegmentId && !isPrimary) {
          a.push(mappedSegment)
        }

        return a
      },

      [] as SegmentInterfaceWithIsPrimary[]
    ) || []

  return primarySegment.concat(
    segmentsWithPrimary.sort((a, b) => a.name.localeCompare(b.name))
  )
}

export const handleInflightErrors = (
  error: ErrorObject,
  defaultLogMessage: string,
  useOverride = false,
  isCustomerNotMarketingSubscribed: boolean
) => {
  let errorMessage = 'Message not sent, something went wrong. Please try again.'
  let logLevel: keyof typeof logger = 'error'

  if (error instanceof Error && error.message === 'Customer opted out') {
    errorMessage = `Message not sent, ${error.message}`
    logLevel = 'info'
  }

  // https://github.com/signpost/biscuit/blob/0169a114b392819b748a1be4797e46ceaab35671/src/redux/actions/Comms.js#L434
  if (error.status === 403) {
    // only occurs for internal users in production
    errorMessage = 'Your logged in user is not authorized to send messages'
    logLevel = 'info'
  } else if (error.status === 409) {
    errorMessage = "Message not sent, duplicated messages can't be sent"
    logLevel = 'info'
  } else if (error.status === 422) {
    // TODO: Improve this error check. Make it look for some other detail on the
    // error that definitely means "reached limit".
    // 422 could be other "invalid input" errors as well. But in practice,
    // it seems to only appear if we have a new bug with inputs, or when "reached limit".
    errorMessage =
      'Message not sent, You have reached the limit of messages sent ' +
      'to this contact without a response.'
    logLevel = 'info'
  } else if (
    error.status === 404 &&
    error.data.message === Constants.CORE_ERRORS.invalidSmsCustomer
  ) {
    if (isCustomerNotMarketingSubscribed) {
      errorMessage = Constants.ERRORS.customerNotSubscribed
      logLevel = 'info'
    }
  }

  if (useOverride) {
    if (error.data.message === Constants.ERRORS.duplicateSMS) {
      errorMessage = Constants.ERRORS.overrideDuplicateSMS
    } else if (error.data.message === Constants.ERRORS.ineligibleLocation) {
      errorMessage = Constants.ERRORS.overrideIneligibleLocation
    }
  }

  toast.error(errorMessage)

  if (logLevel) {
    logger[logLevel](defaultLogMessage, { error })
  }
}

export const displayUserFriendlyMessage = (
  errorMessage?: string,
  eventType?: ConversationEventType,
  eventDirection?: CommsSourceTypeKey
): { message: string; link?: string; wordWithLink?: string } => {
  if (!errorMessage) {
    return { message: '' }
  } else if (errorMessage === Constants.COMMS.blockedAreaCodeReason) {
    return { message: Constants.ERRORS.instantResponseNotSent }
  } else if (
    eventType === 'THUMBTACK' &&
    eventDirection === Constants.COMMS.sourceTypes.outgoing
  ) {
    // Note: The prompt to disconnect and reconnect only makes sense for failures that are
    // due to problems with the location's connection & credentials. In the future we might
    // want to improve this logic to distinguish failures that aren't due to that reason, so
    // we don't prompt them to pointlessly disconnect & reconnect.
    return {
      message:
        'Message not delivered. Click here to disconnect & re-connect your Thumbtack Instant Responder. Then try again.',
      link: '/settings#section-settings-instant-responders',
      wordWithLink: 'here',
    }
  } else {
    return { message: 'Message not delivered' }
  }
}

export const reportProcessDone = async () => {
  const isE2eTestRunning = getCookie('e2eTesting') === 'e2eTestsRunning'

  logger.debug('Reporting process done', { isE2eTestRunning })
  if (isE2eTestRunning) {
    try {
      await client.get(Constants.Backend.Endpoints.CYPRESS_DONE_ENDPOINT, {
        params: { resource: 'handleSendMessage' },
      })
    } catch {}
  }
}
