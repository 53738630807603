import { DefaultTheme } from 'styled-components'

// Colors
const baseHue = 0
const baseSaturation = 0

const base_100 = `hsl(${baseHue}, ${baseSaturation}%, 0%)`
const base_90 = `hsl(${baseHue}, ${baseSaturation}%, 10%)`
const base_80 = `hsl(${baseHue}, ${baseSaturation}%, 20%)`
const base_70 = `hsl(${baseHue}, ${baseSaturation}%, 30%)`
const base_60 = `hsl(${baseHue}, ${baseSaturation}%, 40%)`
const base_50_60 = `hsla(${baseHue}, ${baseSaturation}%, 50%, 0.6)`
const base_50 = `hsl(${baseHue}, ${baseSaturation}%, 50%)`
const base_40 = `hsl(${baseHue}, ${baseSaturation}%, 60%)`
const base_30 = `hsl(${baseHue}, ${baseSaturation}%, 70%)`
const base_20 = `hsl(${baseHue}, ${baseSaturation}%, 80%)`
const base_10 = `hsl(${baseHue}, ${baseSaturation}%, 90%)`
const base_5 = `hsl(${baseHue}, ${baseSaturation}%, 95%)`
const base_3 = `hsl(${baseHue}, ${baseSaturation}%, 97%)`
const base_0 = `hsl(${baseHue}, ${baseSaturation}%, 100%)`

const colors = {
  primary_1: 'rgba(0, 91, 181, 1)',
  primary_1_10: 'rgba(0, 91, 181, 0.1)',
  primary_1_25: 'rgba(0, 91, 181, 0.25)',
  primary_1_45: 'rgba(0, 91, 181, 0.45)',
  primary_1_70: 'rgba(0, 91, 181, 0.7)',
  primary_1_dark: 'rgba(0, 54, 107, 1)',
  primary_2: 'rgba(49, 140, 230, 1)',
  primary_2_10: 'rgba(49, 140, 230, 0.1)',
  primary_2_15: 'rgba(49, 140, 230, 0.15)',
  primary_2_70: 'rgba(49, 140, 230, 0.7)',
  warning: 'rgba(255, 121, 11, 1)',
  warning_70: 'rgba(255, 121, 11, 0.7)',
  critical: 'rgba(225, 30, 62, 1)',
  critical_15: 'rgba(225, 30, 62, .15)',
  critical_70: 'rgba(225, 30, 62, 0.7)',
  positive: 'rgba(104, 190, 0, 1)',
  positive_70: 'rgba(104, 190, 0, 0.7)',
  positive_15: 'rgba(104, 190, 0, 0.15)',
  info: 'rgba(0, 91, 181, 1)',
  accent_1: 'rgba(255, 191, 11, 1)',
  base_100,
  base_90,
  base_80,
  base_70,
  base_60,
  base_50,
  base_50_60,
  base_40,
  base_30,
  base_20,
  base_10,
  base_5,
  base_3,
  base_0,
}

// Typography
const typographyColors = {
  darker: colors.base_100,
  dark: colors.base_70,
  medium: colors.base_60,
  light: colors.base_50,
  lighter: colors.base_40,
}

const typographyWeights = {
  medium: 400,
  bold: 500,
  bolder: 600,
}

// Spacing
const spacingBaseValue = 4
const space = (value: number) => `${value * spacingBaseValue}px`

// Z-Indexing
const baseZIndex = 100
const onTopOf = 10

const layoutZIndex = baseZIndex
const tooltipZIndex = onTopOf + layoutZIndex
const selectZIndex = onTopOf + tooltipZIndex
const dropdownZIndex = onTopOf + selectZIndex
const backdropZIndex = onTopOf + dropdownZIndex
const sideDrawerZIndex = onTopOf + backdropZIndex
const modalZIndex = onTopOf + sideDrawerZIndex

// Breakpoints
const breakpoints: DefaultTheme['breakpoints'] = {
  xxl: [1440, 900],
  xl: [1280, 800],
  lg: [1020, 768],
  md: [720, 1280],
  sm: [640, 960],
  xs: [480, 800],
  xxs: [320, 480],
}
const getBreakpoint: DefaultTheme['getBreakpoint'] = (width) =>
  `(min-width: ${breakpoints[width][0]}px)`

const navbarHeightUnits = 18
const multiLocationHeaverHeightUnits = 9
const sidebarWidthUnits = 65
const getHeadersHeights = (include?: {
  navbar?: boolean
  multiLocationHeader?: boolean
  additionalHeight?: number
}) =>
  space(
    (include?.navbar ? navbarHeightUnits : 0) +
      (include?.multiLocationHeader ? multiLocationHeaverHeightUnits : 0) +
      (include?.additionalHeight ?? 0)
  )

const theme: DefaultTheme = {
  colors,
  space,
  zIndexes: {
    layout: layoutZIndex,
    tooltip: tooltipZIndex,
    select: selectZIndex,
    dropdown: dropdownZIndex,
    backdrop: backdropZIndex,
    sidedrawer: sideDrawerZIndex,
    modal: modalZIndex,
  },
  constants: {
    borderRadius: '4px',
    largeBorderRadius: '8px',
  },
  heights: {
    navbar: space(navbarHeightUnits),
    multiLocationHeader: space(multiLocationHeaverHeightUnits),
    navbarHeightUnits,
    multiLocationHeaverHeightUnits,
    layoutStatusBar: 15,
    getHeadersHeights,
  },
  widths: {
    sidebar: space(sidebarWidthUnits),
    sidebarWidthUnits,
  },
  breakpoints,
  getBreakpoint,
  typography: {
    colors: typographyColors,
    weights: typographyWeights,
    heading: {
      extraLarge: {
        fontSize: '2.4rem',
        lineHeight: '3.6rem',
        fontWeight: typographyWeights.bold,
        color: typographyColors.darker,
        textTransform: 'none',
      },
      large: {
        fontSize: '2.0rem',
        lineHeight: '3.0rem',
        fontWeight: typographyWeights.bold,
        color: typographyColors.darker,
        textTransform: 'none',
      },
      medium: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        fontWeight: typographyWeights.bold,
        color: typographyColors.darker,
        textTransform: 'none',
      },
      small: {
        fontSize: '1.4rem',
        lineHeight: '2rem',
        fontWeight: typographyWeights.bold,
        color: typographyColors.light,
        textTransform: 'uppercase',
      },
    },
    subtitle: {
      medium: {
        fontSize: '1.4rem',
        lineHeight: '2rem',
        fontWeight: typographyWeights.bold,
        color: typographyColors.medium,
        textTransform: 'none',
      },
    },
    body: {
      large: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        fontWeight: typographyWeights.medium,
        color: typographyColors.medium,
        textTransform: 'none',
      },
      medium: {
        fontSize: '1.4rem',
        lineHeight: '2rem',
        fontWeight: typographyWeights.medium,
        color: typographyColors.medium,
        textTransform: 'none',
      },
      small: {
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
        fontWeight: typographyWeights.medium,
        color: typographyColors.medium,
        textTransform: 'none',
      },
    },
  },
}

export default theme
