import { AxiosInstance } from 'axios'
import {
  addMonths,
  formatISO,
  isBefore,
  isSameMonth,
  isSameYear,
  parseISO,
  startOfMonth,
  subMonths,
} from 'date-fns'

import { getMessageEmailSubject } from '../../components/Insights/Widgets/RecentCustomerActivityWidget/utils'
import Constants from '../../lib/Constants'
import { UnknownObject } from '../../utils/interfaces'
import { MessageInterface } from '../interfaces/Common'
import {
  CustomerActivitiesRequests,
  CustomerRollups,
} from '../interfaces/CustomerActivities'
import { ClientResponse } from '../interfaces/generic'
import Resource from '../resource'

const mapActivities =
  (contacts: UnknownObject[], messages: MessageInterface[]) =>
  (a: UnknownObject): UnknownObject => {
    const activity = { ...a }
    const contactId =
      activity.contactId ||
      activity.referredContactId ||
      activity.referringContactId
    const messageId = activity.messageId || activity.offerId

    const foundContact = contacts.find((c) => c.contactId === contactId)
    const foundMessage = messages.find((c) => c.messageId === messageId)

    activity.contact = foundContact
    activity.message = foundMessage

    if (foundContact) {
      if (activity.contactId) {
        activity.contactFirstName =
          activity.contactFirstName || foundContact.firstName
        activity.contactLastName =
          activity.contactLastName || foundContact.lastName
        activity.contactPrimaryEmailAddress =
          activity.contactPrimaryEmailAddress ||
          foundContact.primaryEmailAddress
        activity.contactPrimaryPhoneNumber =
          activity.contactPrimaryPhoneNumber || foundContact.primaryPhoneNumber
      }
      if (activity.referredContactId) {
        activity.referredContactFirstName =
          activity.referredContactFirstName || foundContact.firstName
        activity.referredContactLastName =
          activity.referredContactLastName || foundContact.lastName
        activity.referredContactPrimaryEmailAddress =
          activity.referredContactPrimaryEmailAddress ||
          foundContact.primaryEmailAddress
        activity.referredContactPrimaryPhoneNumber =
          activity.referredContactPrimaryPhoneNumber ||
          foundContact.primaryPhoneNumber
      }
      if (activity.referringContactId) {
        activity.referringContactFirstName =
          activity.referringContactFirstName || foundContact.firstName
        activity.referringContactLastName =
          activity.referringContactLastName || foundContact.lastName
        activity.referringContactPrimaryEmailAddress =
          activity.referringContactPrimaryEmailAddress ||
          foundContact.primaryEmailAddress
        activity.referringContactPrimaryPhoneNumber =
          activity.referringContactPrimaryPhoneNumber ||
          foundContact.primaryPhoneNumber
      }
    }

    if (foundMessage) {
      // https://github.com/signpost/glaze/blob/176c07dd06fd7f8bf77a3d32fe7c9a4350713e9b/app/models/CustomerActivity.js#L372-L398
      const { type, lifecycleStage, content } = foundMessage

      activity.messageEmailTitle = foundMessage.emailTitle
      activity.messageEmailSubject = foundMessage.emailSubject
      activity.messageTitle = foundMessage.title
      activity.messageDescription = foundMessage.description

      if (type === 'CronutOffer') {
        activity.messageType = 'NCO'
      } else if (type === 'GPC') {
        const { contentType } = content

        activity.messageLifecycleStage = lifecycleStage
        activity.messageContentType = contentType
        activity.messageEmailSubject = getMessageEmailSubject(foundMessage)

        // const messageProps = messagePropsMap[contentType]
        switch (contentType) {
          case Constants.Messages.ContentType.NEWSLETTER:
            activity.messageType = 'Newsletter'
            activity.messageTitle = content[contentType]?.headline
            activity.messageDescription = content[contentType]?.body
            break
          case Constants.Messages.ContentType.OFFER:
            activity.messageType = 'ScheduledOffer'
            activity.messageTitle = content[contentType]?.shortDescription
            activity.messageDescription = content[contentType]?.longDescription
            break
          case Constants.Messages.ContentType.ANNOUNCEMENT:
            activity.messageType = 'Announcement'
            activity.messageTitle = content[contentType]?.headline
            activity.messageDescription = content[contentType]?.body
            break

          default:
            break
        }
      }
    } else {
      activity.messageType = 'FacebookOffer'
    }

    return activity
  }

const actions = (client: AxiosInstance): CustomerActivitiesRequests => {
  const getMessages = async (messageIds: string[]) => {
    const { docs: messages }: ClientResponse<MessageInterface> =
      !messageIds.length
        ? { docs: [], total: 0 }
        : await client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
            module: 'Messages',
            method: 'find',
            params: {
              esMethod: 'mget',
              esBody: { ids: messageIds },
            },
          })

    return messages
  }

  const getContacts = async (contactIds: string[]) => {
    const { docs: contacts }: ClientResponse = !contactIds.length
      ? { docs: [], total: 0 }
      : await client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
          module: 'Customers',
          method: 'find',
          params: {
            esMethod: 'mget',
            esBody: { ids: contactIds },
          },
        })

    return contacts
  }

  const getCustomerCreationRollups = async (locationId: number) => {
    let lastDate = new Date()
    const startDate = startOfMonth(subMonths(lastDate, 1))
    const dates = [] as Date[]

    while (isBefore(startDate, lastDate)) {
      dates.push(lastDate)
      lastDate = subMonths(lastDate, 1)
    }

    const rollups = await Promise.all(
      dates.map<Promise<CustomerRollups | undefined>>(async (date) => {
        const start = startOfMonth(date)
        const end = addMonths(start, 1)
        const { docs, total }: ClientResponse = await client.post(
          Constants.Backend.Endpoints.CORE_CLIENT_PROXY,
          {
            module: 'Documents',
            method: 'findByQuery',
            params: {
              scope: 'Customers',
              template: {
                id: 'CONTACT_LIST_FILTER_UI',
                params: {
                  locationId,
                  filterClauses: [
                    {
                      filter: 'WITH_MARKETABLE_CHANNEL_TYPE',
                      param: ['email', 'sms'],
                    },
                    {
                      filter: 'WITH_ADDED_AT_BOUNDS',
                      param: [formatISO(start), formatISO(end)],
                    },
                  ],
                  sort: [
                    {
                      by: 'ADDED_AT',
                      direction: 'DESC',
                    },
                  ],
                },
              },
              limit: 3,
              offset: 0,
              countAll: true,
            },
          }
        )

        if (total) {
          const rollup: CustomerRollups = {
            documentType: 'CustomerCreationRollup',
            timestamp: docs[0]!.addedAt as string,
            total,
            rollup: docs,
          }

          return rollup
        }
      })
    )

    const filteredRollups = rollups.filter((r) => !!r) as CustomerRollups[]

    const customerCreationRollupsToPreserve: CustomerRollups[] = []
    const customerCreationRollupsToMerge = filteredRollups.slice()
    const firstRollup = filteredRollups[0]
    const now = new Date()

    if (
      firstRollup &&
      isSameYear(parseISO(firstRollup.timestamp), now) &&
      isSameMonth(parseISO(firstRollup.timestamp), now)
    ) {
      const rollup = customerCreationRollupsToMerge.shift()

      if (rollup) {
        customerCreationRollupsToPreserve.push(rollup)
      }
    }

    return {
      customerCreationRollupsToPreserve,
      customerCreationRollupsToMerge,
    }
  }

  return {
    getActivitiesByLocationId: async ({ locationId, limit = 20 }) => {
      const [
        { docs: activities },
        { customerCreationRollupsToMerge, customerCreationRollupsToPreserve },
      ] = await Promise.all([
        client.post<ClientResponse, ClientResponse>(
          Constants.Backend.Endpoints.CORE_CLIENT_PROXY,
          {
            module: 'Documents',
            method: 'findByQuery',
            params: {
              scope: 'ChannelEvents',
              template: {
                id: 'CONTACT_ACTIVITY_HIGHLIGHT',
                params: {
                  locationId,
                  sort: [{ by: 'TIMESTAMP', direction: 'DESC' }],
                },
              },
              limit,
            },
          }
        ),
        getCustomerCreationRollups(locationId),
      ])

      const { messageIds, contactIds } = activities.reduce<{
        contactIds: string[]
        messageIds: string[]
      }>(
        (a, c) => {
          const contactId =
            c.contactId || c.referredContactId || c.referringContactId
          const messageId = c.messageId || c.offerId

          if (contactId) {
            a.contactIds.push(`Contact.${contactId as string}`)
          }
          if (messageId) {
            a.messageIds.push(`Message.${messageId as string}`)
          }

          return a
        },
        { contactIds: [], messageIds: [] }
      )

      const [contacts, messages] = await Promise.all([
        getContacts(contactIds),
        getMessages(messageIds),
      ])

      const populatedActivities = activities.map(
        mapActivities(contacts, messages)
      )

      const orderedActivities = [...populatedActivities]
      const size = limit - customerCreationRollupsToPreserve.length
      let insertIndex = 0

      customerCreationRollupsToMerge.forEach((rollup) => {
        const rollupTimestamp = parseISO(rollup.timestamp)
        const activityTimestamp = parseISO(
          // if orderedActivities is empty, then in the first loop, the '0'
          // will be undefined, so protect it with ?. subsequent loops will
          // always have insertIndex with a valid index.
          orderedActivities[insertIndex]?.timestamp as string
        )

        while (
          insertIndex < orderedActivities.length &&
          isBefore(rollupTimestamp, activityTimestamp)
        ) {
          insertIndex++
        }

        orderedActivities.splice(
          insertIndex,
          0,
          rollup as unknown as UnknownObject
        )
      })

      const activitiesToReturn = [
        ...(customerCreationRollupsToPreserve as unknown as UnknownObject[]),
        ...orderedActivities.slice(0, size),
      ]

      return activitiesToReturn
    },
  }
}

export default Resource(actions)
