import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { LocationLegacy } from 'src/client/interfaces/Common'
import useAccountContext from 'src/contexts/AccountContext'
import { UseLocationRouteParams } from 'src/utils/interfaces'

interface LocationSelectorItem {
  name: string
  locationAddresses: LocationLegacy[]
}

const mapMultiLocations = (locations: LocationLegacy[]) =>
  locations.reduce<LocationSelectorItem[]>((a, c) => {
    const location = a.find((l) => l.name === c.companyName)

    if (location) {
      location.locationAddresses.push(c)
    } else {
      const l: LocationSelectorItem = {
        name: c.companyName,
        locationAddresses: [c],
      }

      a.push(l)
    }

    return a
  }, [])

export const useMultiLocationHeaderHooks = () => {
  const [title, setTitle] = useState('All Locations Overview')
  const [dimmedLabel, setDimmedLabel] = useState('')
  const [label, setLabel] = useState('Go to a Location')
  const { locationId, merchantId } = useParams<UseLocationRouteParams>()
  const { locations: userLocations } = useAccountContext()
  const locations = useMemo<LocationSelectorItem[]>(
    () => mapMultiLocations(userLocations || []),
    [userLocations]
  )

  useEffect(() => {
    if (locationId && merchantId && userLocations) {
      const selectedLocation = userLocations.find(
        (l) =>
          l.locationId.toString() === locationId &&
          l.merchantId.toString() === merchantId
      )

      if (selectedLocation) {
        setTitle(
          `${selectedLocation.companyName}, ${selectedLocation.displayName}`
        )
        setDimmedLabel('Current Location:')
        setLabel(selectedLocation.name)
      } else {
        setTitle('All Locations Overview')
        setDimmedLabel('')
        setLabel('Go to a Location')
      }
    }
  }, [locationId, merchantId, userLocations])

  return { title, dimmedLabel, label, locations }
}
