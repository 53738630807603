import { AxiosInstance } from 'axios'
import { LocationsRequests } from 'src/client/interfaces/Locations'
import Resource from 'src/client/resource'

const actions = (client: AxiosInstance): LocationsRequests => {
  return {
    getLocation: (locationId) => {
      return client.get(`/v3/locations/${locationId}`)
    },
    getMarketingSettings: (locationId) => {
      return client.get(`/v3/locations/${locationId}/marketing-settings`)
    },
    getReviewSites: (locationId) => {
      return client.get(`/v3/locations/${locationId}/review-sites`)
    },
  }
}

export default Resource(actions)
