import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { InfoIcon } from 'src/stories/assets'
import { BannerSmallText } from '../styled'
import Link from 'src/stories/Link'
import {
  TermsUpdateBannerContainer,
  TermsUpdateBannerContent,
  StyledCloseIcon,
} from './styled'

const EXPIRY_DATE = new Date('2024-11-01T00:00:00Z').getTime()
const LINK_URL = 'https://hibu.com/legal/signpost-software'

const getStoredDate = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('bannerDismissedUntil')
  }

  return null
}

const isBannerVisible = () => {
  const storedDate = getStoredDate()

  return !storedDate || Number(storedDate) < Date.now()
}

const dismissBanner = (
  expiryDate: number,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  localStorage.setItem('bannerDismissedUntil', expiryDate.toString())
  setIsVisible(false)
}

const TermsUpdateBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    if (Date.now() < EXPIRY_DATE && isBannerVisible()) {
      setIsVisible(true)
    }
  }, [])

  const handleClose = () => {
    const newExpiryDate = new Date('2024-11-02T00:00:00Z').getTime()

    dismissBanner(newExpiryDate, setIsVisible)
  }

  if (!isVisible) {
    return null
  }

  return (
    <TermsUpdateBannerContainer>
      <TermsUpdateBannerContent>
        <InfoIcon fill="#fbcd4e" />
        <BannerSmallText>
          Our Terms & Conditions have moved. You can see them here:{' '}
          <Link
            fontWeight="medium"
            underline="none"
            target="_blank"
            href={LINK_URL}
          >
            {LINK_URL}
          </Link>
        </BannerSmallText>
      </TermsUpdateBannerContent>

      <StyledCloseIcon
        data-cy="terms-update-banner-close-button"
        onClick={handleClose}
        fill={theme.colors.base_100}
      />
    </TermsUpdateBannerContainer>
  )
}

export default TermsUpdateBanner
