import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { LocationsResource } from 'src/client'
import useAccountContext from 'src/contexts/AccountContext'
import logger from 'src/utils/logger'

const useConfirmBusinessInfoMutation = () => {
  const { handleRefetchUserLocations } = useAccountContext()

  return useMutation(LocationsResource.confirmBusinessInfo, {
    onSuccess: async () => {
      toast.success('Business Information confirmed')

      try {
        await handleRefetchUserLocations()
      } catch (error) {
        logger.error(
          'There was an error trying to refresh the Business Information.',
          { error }
        )
      }
    },
    onError: (error) => {
      logger.error(
        'There was an error trying to confirm the Business Information.',
        { error }
      )
    },
  })
}

export default useConfirmBusinessInfoMutation
