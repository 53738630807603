import React from 'react'
import styled from 'styled-components'

import PageLayout from 'src/components/WafLayout/PageLayout'
import ConversionCampaignSectionLayout from 'src/containers/Campaigns/Conversion/components/SectionLayout'
import CampaignsPrebuiltTemplates from 'src/containers/Campaigns/Conversion/PreBuiltTemplates'
import YourCampaigns from 'src/containers/Campaigns/Conversion/YourCampaigns'

const Separator = styled.div(({ theme }) => ({
  marginBottom: theme.space(21),
}))

const ConversionCampaigns = () => {
  const baseDataAttribute = 'campaigns-conversion'

  return (
    <PageLayout
      title="Conversion Campaigns"
      subtitle={
        <>
          Close more jobs and streamline your outreach efforts with
          Signpost&apos;s Conversion Campaign.
        </>
      }
      baseDataAttribute={baseDataAttribute}
    >
      <ConversionCampaignSectionLayout
        baseDataAttribute={`${baseDataAttribute}-pre-built`}
        title="Pre-built Templates"
      >
        <CampaignsPrebuiltTemplates
          baseDataAttribute={`${baseDataAttribute}-pre-built-templates`}
        />
      </ConversionCampaignSectionLayout>
      <Separator />
      <ConversionCampaignSectionLayout
        baseDataAttribute={`${baseDataAttribute}-list`}
        title="Your Conversion Campaigns"
      >
        <YourCampaigns
          baseDataAttribute={`${baseDataAttribute}-your-campaigns`}
        />
      </ConversionCampaignSectionLayout>
    </PageLayout>
  )
}

export default ConversionCampaigns
