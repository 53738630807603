import React from 'react'
import styled from 'styled-components'

import { Button } from 'src/stories/Button'
import { Col, Row } from 'src/stories/Layout'

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  marginBottom: theme.space(3),
}))

const StyledCopy = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(6),
}))

const StyledHeadline = styled.h1(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '1.6rem',
  marginBottom: theme.space(2),
}))

const StyledDetails = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.4rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(8),
}))

interface OverViewStepProps {
  nextStep: () => void
}
const OverviewStep: React.FC<OverViewStepProps> = ({ nextStep }) => (
  <Row>
    <Col w={2}>
      <StyledTitle>Overview</StyledTitle>

      <StyledCopy>
        Connect your Angi (formerly HomeAdvisor) account to add to your new
        contacts and use Instant Response to automatically follow-up with leads
        from Angi.
      </StyledCopy>

      <StyledHeadline>How it works:</StyledHeadline>

      <StyledDetails>
        <li>We will ask you for your Angi login information to connect</li>
        <li>
          Once connected, new leads from Angi will be automatically added to
          your account.
        </li>
        <li>
          Set up instant response to automatically follow-up with new leads,
          ensuring you are one of the first Home Service pros to reach out
        </li>
        <li>
          View and reply to all your Angi messages right from your Messaging Hub
        </li>
      </StyledDetails>

      <Button
        baseDataAttribute="connection-wizard-angi-get-started"
        action="primary"
        label="Get Started"
        maxWidth={167}
        onClick={nextStep}
      />
    </Col>
  </Row>
)

export default OverviewStep
