import styled from 'styled-components'
import { CloseIcon } from 'src/stories/assets'

export const TermsUpdateBannerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fefaee;
  padding: 8px;
  text-align: center;
  border: 1px solid #fbcd4e;
  border-radius: 5px;
  z-index: 1000;
  display: block;
`

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: ${(props) => props.theme.space(2)};
  right: ${(props) => props.theme.space(2)};
  cursor: pointer;
  width: 13px;
  height: 13px;

  &:hover {
    color: ${(props) => props.theme.colors.primary_2_70};
  }
`

export const TermsUpdateBannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 1.2rem;
`
