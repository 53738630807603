import styled from 'styled-components'

export const ReviewsBannerSmallText = styled.div(({ theme }) => ({
  fontSize: '1.6rem',
  color: theme.colors.base_40,
  marginTop: theme.space(6),
}))

export const ReviewsBannerStepsContainer = styled.div(({ theme }) => ({
  marginTop: theme.space(2),
  marginBottom: theme.space(8),
}))

export const ReviewsBannerItem = styled.div(({ theme }) => ({
  display: 'grid',
  overflow: 'hidden',
  gridTemplateColumns: 'repeat(20, 1fr)',
  position: 'relative',
}))

export const ReviewsBannerGoogleSearchImage = styled.img(({ theme }) => ({
  marginTop: theme.space(7.25),
  gridColumn: '1 / span 8',
  gridRow: 1,
}))

export const ReviewsBannerIPhoneImage = styled.img(({ theme }) => ({
  zIndex: theme.zIndexes.layout + 1,
  gridColumn: '10 / -1',
  gridRow: 1,
}))
