import { useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { Button, ButtonProps } from './Button'
import { NewBadgeIcon } from './assets'

interface SidedrawerProp {
  $sidedrawer?: boolean
}

const StyledSidebarTitle = styled.h3(({ theme }) => ({
  margin: `0 0 ${theme.space(4)} ${theme.space(2)}`,
  fontWeight: 500,
  fontSize: '1.6rem',
}))

const StyledRoutesGroupContainer = styled.div<SidedrawerProp>(
  ({ $sidedrawer, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.space($sidedrawer ? 6 : 8),
    '&:last-child': {
      marginBottom: 0,
    },
  })
)

const StyledGroupTitle = styled.p<SidedrawerProp>(({ $sidedrawer, theme }) => ({
  margin: `0 0 ${theme.space(3)} ${theme.space(2)}`,
  fontSize: '1.12rem',
  letterSpacing: '0.031rem',
  color: $sidedrawer ? theme.colors.base_100 : theme.colors.base_30,
}))

interface StyledNavLinkProps extends SidedrawerProp {
  $isActive: boolean
}
const StyledNavLink = styled(NavLink)<StyledNavLinkProps>(
  ({ $sidedrawer, $isActive, theme }) => ({
    color: $sidedrawer ? theme.colors.base_50 : theme.colors.base_100,
    backgroundColor: $isActive ? theme.colors.base_10 : 'transparent',
    textDecoration: 'none',
    fontSize: '1.4rem',
    fontWeight: $isActive ? 500 : 400,
    marginBottom: theme.space(1),
    padding: `${theme.space(2)} 0 ${theme.space(2)} ${theme.space(2)}`,
    borderRadius: theme.constants.borderRadius,
    '&:hover': {
      backgroundColor: theme.colors.base_10,
    },
  })
)

const StyledButtonWrapper = styled.div(({ theme }) => ({
  fontSize: '1.4rem',
  marginBottom: theme.space(2),
}))

const StyledSubHeader = styled(NavLink)<StyledNavLinkProps>(
  ({ $isActive, theme }) => ({
    color: theme.colors.base_100,
    textDecoration: 'none',
    fontSize: '1.4rem',
    marginRight: theme.space(4),
    marginTop: theme.space(2),
    padding: `${theme.space(1)} 0 ${theme.space(1)} ${theme.space(2)}`,
    borderRadius: theme.constants.borderRadius,
    fontWeight: 500,
    backgroundColor: $isActive ? theme.colors.base_10 : 'transparent',
    '&:hover': {
      backgroundColor: theme.colors.base_20,
    },
  })
)

const StyledSideBarContainer = styled.div<SidedrawerProp>(
  ({ $sidedrawer, theme }) => ({
    alignSelf: 'center',
    width: '78%',
    marginLeft: theme.space($sidedrawer ? 3 : 8),
  })
)

const StyledNavLinkLabel = styled.span(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(2),
}))

export interface SidebarRoutes {
  groupTitle?: string
  routes: {
    isNew?: boolean
    to: string
    label: string
    button?: boolean
    subHeader?: boolean
    buttonProps?: Partial<ButtonProps>
  }[]
}

export interface SidebarProps {
  title?: string
  sidedrawer?: boolean
  routes: SidebarRoutes[]
}

const Sidebar: React.FC<SidebarProps> = ({
  title,
  sidedrawer = false,
  routes,
}) => {
  const { pathname: locationPathname, hash: locationHash } = useLocation()
  const theme = useTheme()

  const computeActiveRoute = useCallback(
    ({ hash, pathname }: { pathname: string; hash: string }) => {
      return pathname === locationPathname && hash === locationHash
    },
    [locationHash, locationPathname]
  )

  return (
    <StyledSideBarContainer data-cy="sidebar" $sidedrawer={sidedrawer}>
      {title && (
        <StyledSidebarTitle data-cy="sidebar-title">{title}</StyledSidebarTitle>
      )}

      {routes.map((r, idxA) => {
        if (r.routes.length === 0) return null

        return (
          <StyledRoutesGroupContainer
            $sidedrawer={sidedrawer}
            key={`sidebar-routes-groups-${idxA}`}
          >
            {r.groupTitle && (
              <StyledGroupTitle
                data-cy="sidebar-group-title"
                $sidedrawer={sidedrawer}
              >
                {r.groupTitle.toUpperCase()}
              </StyledGroupTitle>
            )}

            {r.routes.map((rr, idxB) => {
              const { pathname: routePathname, hash: routeHash } = new URL(
                rr.to,
                window.location.origin
              )
              const buttonProps = rr.buttonProps || {}

              return rr.button ? (
                <StyledButtonWrapper
                  key={`sidebar-route-link-${idxB}-${idxA}`}
                  data-cy="sidebar-button-wrapper"
                >
                  <Button
                    action="primary"
                    baseDataAttribute={`${rr.label}-Button`}
                    label={rr.label}
                    {...buttonProps}
                    href={rr.to}
                  />
                </StyledButtonWrapper>
              ) : rr.subHeader ? (
                <StyledSubHeader
                  key={`sidebar-route-link-${idxB}-${idxA}`}
                  data-cy="sidebar-sub-header"
                  to={rr.to}
                  $isActive={computeActiveRoute({
                    hash: routeHash,
                    pathname: routePathname,
                  })}
                >
                  {rr.label}
                </StyledSubHeader>
              ) : (
                <StyledNavLink
                  key={`sidebar-route-link-${idxB}-${idxA}`}
                  data-cy={`sidebar-nav-link-${rr.label}`}
                  to={rr.to}
                  $sidedrawer={sidedrawer}
                  $isActive={computeActiveRoute({
                    hash: routeHash,
                    pathname: routePathname,
                  })}
                >
                  <StyledNavLinkLabel>
                    {rr.label}
                    {rr.isNew && (
                      <NewBadgeIcon
                        height={18}
                        style={{ marginRight: theme.space(2) }}
                      />
                    )}
                  </StyledNavLinkLabel>
                </StyledNavLink>
              )
            })}
          </StyledRoutesGroupContainer>
        )
      })}
    </StyledSideBarContainer>
  )
}

export default Sidebar
