import Constants from '../../lib/Constants'
import { UnknownObject } from 'src/utils/interfaces'

// These interfaces are not perfectly written. I believe we can polish
// them as we go through them in everyday work.

export interface HTTPRequestError {
  status: number
  statusText: string
  data: UnknownObject
  config: UnknownObject
  headers: UnknownObject
  request: UnknownObject
}

export type LocationFeature =
  | 'bulk_action_send_blast'
  | 'facebook_integration'
  | 'send_facebook_autoresponse'
  | 'should_allow_immediate_request_to_cancel'

export enum LocationBusinessType {
  PARTNERSHIP = 'PARTNERSHIP',
  CORPORATION = 'CORPORATION',
  CO_OPERATIVE = 'CO_OPERATIVE',
  LLC = 'LLC',
  NON_PROFIT = 'NON_PROFIT',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
}

export type Roles =
  | 'ROLE_MERCHANT_USER'
  | 'ROLE_ADMIN'
  | 'ROLE_DEV'
  | 'ROLE_ACCOUNT_MANAGER'
  | 'ROLE_PUBLICATION_MANAGER'
  | 'ROLE_SALES_TEAM'
  | 'ROLE_CS_LEADER'
  | 'ROLE_MARKETING_CONFIGURATION_MANAGER'

export interface CommInterface {
  id: number
  locationId?: number
  type: string
  channelId?: number
  fingerprint?: string
  offerId?: number
  referringCustomerId?: number
  customerId?: number
  sentAt: string
  userId?: number
  merchantId?: number
  deliveredAt?: string
  isTestComm: boolean
  sentSmsId?: number
  sendEmailId?: number
}

export interface ConversationInterface {
  contactId: number
  docId: string
  locationId: number
  timestamp: string
  totalContactConversationDocuments: number
}

export enum PreferredConsumerTerm {
  CLIENT = 'CLIENT',
  CUSTOMER = 'CUSTOMER',
  GUEST = 'GUEST',
  MEMBER = 'MEMBER',
  PARENT = 'PARENT',
  PATIENT = 'PATIENT',
  STUDENT = 'STUDENT',
}

export enum PreferredServiceOfferingTerm {
  BUSINESS = 'BUSINESS',
  STAY = 'STAY',
  VISIT = 'VISIT',
}

export const preferredConsumerTermMap = {
  [PreferredConsumerTerm.CLIENT]: {
    singular: 'client',
    plural: 'clients',
    pluralSentenceCase: 'Clients',
  },
  [PreferredConsumerTerm.CUSTOMER]: {
    singular: 'customer',
    plural: 'customers',
    pluralSentenceCase: 'Customers',
  },
  [PreferredConsumerTerm.GUEST]: {
    singular: 'guest',
    plural: 'guests',
    pluralSentenceCase: 'Guests',
  },
  [PreferredConsumerTerm.MEMBER]: {
    singular: 'member',
    plural: 'members',
    pluralSentenceCase: 'Members',
  },
  [PreferredConsumerTerm.PARENT]: {
    singular: 'parent',
    plural: 'parents',
    pluralSentenceCase: 'Parents',
  },
  [PreferredConsumerTerm.PATIENT]: {
    singular: 'patient',
    plural: 'patients',
    pluralSentenceCase: 'Patients',
  },
  [PreferredConsumerTerm.STUDENT]: {
    singular: 'student',
    plural: 'students',
    pluralSentenceCase: 'Students',
  },
} as const

export const preferredServiceOfferingTermMap = {
  [PreferredServiceOfferingTerm.BUSINESS]: {
    affirmativePastParticiple: 'have done business',
    negativePastParticiple: "haven't done business",
    noun: 'business',
    sentenceCaseNoun: 'Business',
  },
  [PreferredServiceOfferingTerm.STAY]: {
    affirmativePastParticiple: 'have stayed',
    negativePastParticiple: "haven't stayed",
    noun: 'stay',
    sentenceCaseNoun: 'Stay',
  },
  [PreferredServiceOfferingTerm.VISIT]: {
    affirmativePastParticiple: 'have visited',
    negativePastParticiple: "haven't visited",
    noun: 'visit',
    sentenceCaseNoun: 'Visit',
  },
} as const

export interface LoopConfigurationsInterface {
  feedbackEmailEnabled: boolean
  feedbackSmsEnabled: boolean
  id: number
  initialRemarketingDelay: null
  isLocationDefault: boolean
  lifecycleStage: string
  multipleReferralBonusEnabled: boolean
  multipleReferralBonusOfferId: number
  multipleReferralBonusThreshold: number
  ncoEmailEnabled: boolean
  ncoId: number
  ncoSmsEnabled: boolean
  preferredConsumerTerm: PreferredConsumerTerm
  preferredServiceOfferingTerm: PreferredServiceOfferingTerm
  proactiveCampaignSendingLimit?: null
  referralInstructionsEmailEnabled: boolean
  referralInstructionsSmsEnabled: boolean
  segmentId?: null
  sendNcoSmsOnOptIn: boolean
  singleReferralBonusEnabled: boolean
  singleReferralBonusOfferId?: null
  transactionTriggeredEmailsEnabled: boolean
}

// NOTE this interface isn't comprehensive
// and likely there are other available
// keys.

export interface Media {
  url: string
  contentType: string
}

interface PolicyAcksInterface {
  userId: number
  submittedAt: string
  userIpAddress: string
  policyVersion: string
  userInitials: string | null
  policyType: 'TOS' | 'TCPA'
}
export interface UserInterface {
  attachedAgencyIds: number[]
  attachedLocationIds: number[]
  attachedMerchantIds: number[]
  createdAt: string
  disabledAt: string
  emailAddress: string
  emailIsBouncing: boolean
  firstName: string
  lastName: string
  id: number
  leadId: number
  partnerUserExternalId: number
  mobilePhoneNumber: string
  onboarded: boolean
  phoneNumber: string
  preferredCommMethod: string
  roles: Roles[]
  policyAcks?: PolicyAcksInterface[]
}

export interface PhoneChannelDetails {
  phoneNumberId: number
  phoneNumber: string
  smsOptIn: boolean | null
}

export interface EmailChannelDetails {
  emailAddressId: number
  emailAddress: string
  emailOptIn: boolean | null
  emailIsBouncing: boolean
}

interface IntegrationsInterface {
  status: string
  integration: string
  updatedAt: string
}

export type Channels =
  | {
      id: number
      type: 'phone'
      details?: PhoneChannelDetails
    }
  | {
      id: number
      type: 'email'
      details?: EmailChannelDetails
    }

export interface LocationReviewSites {
  confirmedAt: string
  highPriority: boolean
  id: number
  lastCrawledAt: string
  pausedAt: string | null
  removedAt: string | null
  reviewSiteId: number
  reviewUrl: string
  url: string
  removed?: boolean
}

// NOTE this interface isn't comprehensive
// and likely there are other available
// keys.
export interface ContactLegacy {
  addedAt: string
  channelIds: number[]
  channels: Channels[]
  contactId: number
  conversationArchived: boolean
  conversationRead: boolean
  createdAt: string
  documentType: string
  emailAddresses: string[]
  emailMarketable: boolean
  emailOptIn: boolean
  smsOptIn: boolean | null
  firstName: string
  hasHumanFirstName: boolean
  id: string
  inferredGender: string
  lastName: string
  lifecycleStage: string
  locationId: number
  manuallyEdited: boolean
  merchantSubscribed: boolean
  primaryEmailAddress: string
  primaryEmailAddressDomain: string
  primaryEmailAddressId: number
  primaryEmailAddressIsBouncing: boolean
  primaryEmailChannelId: number
  primaryPhoneChannelId: number
  primaryPhoneNumber: string
  smsMarketable: boolean
  source: string
  updatedAt: string
  uploadedAt: string
  segmentIds: number[]
  primarySegmentId: number
  notes: string
}

// NOTE this interface isn't comprehensive
// and likely there are other available
// keys.
export interface ConversationPreviewInterface {
  body: string
  id: string
  contactId: number
  comments: string
  conversationArchived: boolean
  conversationRead: boolean
  documentType: string
  interactionType: string
  isConversation: boolean
  isReminder: boolean
  messageBody: string
  preview: string
  smsBody: string
  smsType?: string
  media?: Media[]
  status: string
  updatedAt: string
  uploadedAt: string
  timestamp: string
  failureDetails?: {
    reason?: string
  }
  rating?: number
  feedbackRatingDenominator?: number
  isPositive?: boolean
  channelId?: number
  confirmedReview?: boolean
  createdAt?: string
  judgedAt?: string
  linkLastClickedAt?: string
  locationId?: number
  merchantId?: number
  reviewSiteId?: number
  reviewText?: string
  siteDisplayName?: string
  siteName?: string
  feedbackText?: string
}

export interface ReviewSiteRatings {
  reviewSiteId: number
  rating: number | null
  reviewCount: number
}

/**
 * Based on https://github.com/signpost/glaze/blob/d8eac8f0f23f042385a26bcc8dc942c4250b6e0d/lib/Constants.js#L448-L459
 */
export type PaymentMethods =
  | 'AMERICANEXPRESS'
  | 'CASH'
  | 'CHECK'
  | 'DINERSCLUB'
  | 'DISCOVER'
  | 'FINANCING'
  | 'INVOICE'
  | 'MASTERCARD'
  | 'TRAVELERSCHECK'
  | 'VISA'

interface LocationBusinessDayHours {
  from: string
  to: string
}

export interface LocationAddressInterface {
  city: string
  state: string
  street1: string
  street2: string | null
  zip: string
}

export interface LocationLegacy {
  uploadedAt: string
  documentType: string
  id: string
  locationId: number
  status: string
  merchantId: number
  name: string
  createdInYoshimi: boolean
  isSelfSignup: boolean
  companyName: string
  displayName: string
  senderName: string
  senderEmail: string
  signpostId: string
  localPhone: string
  latitude: number
  longitude: number
  timezone: string
  feedbackRatingDenominator: number
  testimonialsAutoPost: boolean
  merchantSubscriptionStatus: string
  welcomeEmailType: string
  merchantCreatedAt: string
  accountType: string
  googlePublishability: boolean
  firstActivatedAt: string
  firstCompletedWizardAt: string
  firstConfirmedBusinessInfoAt: string
  optimizeForReviews: boolean
  publishForwardingNumber: number
  publishHours: number
  shouldForwardNumber: number
  suppressAddress: number
  hasNcc: boolean
  activeEmailAccounts: number
  activeReadBodiesEmailAccounts: number
  errorEmailAccounts: number
  totalConfirmedReviewSites: number
  /**
   * Product Subscription Status
   * Possible values:
   * - 'Expired', 'Canceling', 'Detached' for Expired status
   * - 'Future', 'Active', 'Past Due' for Active status
   * - 'Never Subscribed'
   */
  currentSubscriptionStatus:
    | 'Expired'
    | 'Never Subscribed'
    | 'Active'
    | 'Detached'
    | 'Canceling'
    | 'Past Due'
    | 'Future'
  contractCompletionAt: string
  currentBillingAmountInCents: number
  nextBillingAmountInCents: number
  nextBillingDate: string
  currentSubscriptionBillingFrequencyMonths: number
  currentServiceTier: string
  nextSubscriptionBillingFrequencyMonths: number
  createdAt: string
  updatedAt: string
  activeCustomers: number
  activeConstantContactCustomers: number
  activeMailchimpCustomers: number
  activeSubscribedCustomers: number
  activeSmsCustomers: number
  activeQuickBooksOnlineCustomers: number
  activeSquareCustomers: number
  totalMarketableCustomers: number
  positiveFeedback: number
  totalFeedback: number
  totalPositiveReviews: number
  totalConfirmedReviews: number
  totalReviewEmailOpens: number
  totalReviewReminderEmailOpens: number
  totalFeedbackEmailOpens: number
  totalOfferEmailOpens: number
  totalGetReferralsEmailOpens: number
  totalMessageEmailOpens: number
  totalMessageReminderEmailOpens: number
  totalScheduledOfferEmailOpens: number
  totalLoyaltyEmailOpens: number
  totalReviewEmailSent: number
  totalReviewReminderEmailSent: number
  totalFeedbackEmailSent: number
  totalOfferEmailSent: number
  totalGetReferralsEmailSent: number
  totalMessageEmailSent: number
  totalMessageReminderEmailSent: number
  totalScheduledOfferEmailSent: number
  totalLoyaltyEmailSent: number
  totalReviewSmsSent: number
  totalFeedbackSmsSent: number
  totalOfferSmsSent: number
  totalGetReferralsSmsSent: number
  totalLoyaltySmsSent: number
  totalMessageSmsSent: number
  totalMessageReminderSmsSent: number
  totalScheduledOfferSmsSent: number
  totalEmailSent: number
  totalCustomersReferred: number
  totalNewCustomerOffersClaimed: number
  totalOffersClaimed: number
  totalMessageConversions: number
  totalOutcomes: number
  totalRevenue: number
  totalRatingStars: number
  totalFeedbackRatings: number
  promoters: number
  detractors: number
  totalAppointments: number
  totalPrepaidOfferRevenue: number
  totalPrepaidOfferSales: number
  activeAppointmentCustomers: number
  activeEmailSyncCustomers: number
  activeFacebookCustomers: number
  activeFacebookAdsCustomers: number
  activeFreshBooksCustomers: number
  activeHousecallProCustomers: number
  activeJobNimbusCustomers: number
  activeLandingPageCustomers: number
  activeManualAddCustomers: number
  activePublicApiCustomers: number
  activePublicApiIntegrationCustomers: number
  activePhoneSyncCustomers: number
  activePrepaidOfferCustomers: number
  activeQuickBooksDesktopCustomers: number
  activeSynchroteamCustomers: number
  activeTransactionSyncCustomers: number
  activeUploadedCustomers: number
  activeCloverCustomers: number
  activePayPalCustomers: number
  activeHomeAdvisorCustomers: number
  activeStripeCustomers: number
  activeZapierCustomers: number
  activeLeapCustomers: number
  activeAgentzCustomers: number
  positiveFeedbackPercent: number
  netPromoterScore: number
  totalConversions: number
  averageFeedbackRating: number
  '7DayActiveCustomersCreated': number
  '30DayActiveCustomersCreated': number
  '7DayEmailOpens': number
  '7DayEmailSent': number
  '7DaySmsSent': number
  '7DayFeedbackEmailSent': number
  '30DayFeedbackEmailSent': number
  '7DayFeedbackEmailOpens': number
  '30DayFeedbackEmailOpens': number
  '7DayFeedbackSmsSent': number
  '30DayFeedbackSmsSent': number
  '7DayGetReferralsEmailSent': number
  '30DayGetReferralsEmailSent': number
  '7DayGetReferralsEmailOpens': number
  '30DayGetReferralsEmailOpens': number
  '7DayGetReferralsSmsSent': number
  '30DayGetReferralsSmsSent': number
  '7DayMessageEmailSent': number
  '30DayMessageEmailSent': number
  '7DayMessageEmailOpens': number
  '30DayMessageEmailOpens': number
  '7DayMessageSmsSent': number
  '30DayMessageSmsSent': number
  '7DayScheduledOfferEmailSent': number
  '30DayScheduledOfferEmailSent': number
  '7DayScheduledOfferEmailOpens': number
  '30DayScheduledOfferEmailOpens': number
  '7DayScheduledOfferSmsSent': number
  '30DayScheduledOfferSmsSent': number
  '7DayOfferEmailSent': number
  '30DayOfferEmailSent': number
  '7DayOfferEmailOpens': number
  '30DayOfferEmailOpens': number
  '7DayOfferSmsSent': number
  '30DayOfferSmsSent': number
  '7DayReviewEmailSent': number
  '30DayReviewEmailSent': number
  '7DayReviewEmailOpens': number
  '30DayReviewEmailOpens': number
  '7DayReviewSmsSent': number
  '30DayReviewSmsSent': number
  '7DayRevenue': number
  '30DayRevenue': number
  '7DayTransactions': number
  '28DayRevenueSunday': number
  '28DayRevenueMonday': number
  '28DayRevenueTuesday': number
  '28DayRevenueWednesday': number
  '28DayRevenueThursday': number
  '28DayRevenueFriday': number
  '28DayRevenueSaturday': number
  '7DayCustomersReferred': number
  '30DayCustomersReferred': number
  '7DayFeedbackSubmissions': number
  '30DayFeedbackSubmissions': number
  '7DayNewCustomerOffersClaimed': number
  '30DayNewCustomerOffersClaimed': number
  '7DayScheduledOffersClaimed': number
  '30DayScheduledOffersClaimed': number
  '7DayFacebookAdOffersClaimed': number
  '30DayFacebookAdOffersClaimed': number
  '7DayMessageConversions': number
  '30DayMessageConversions': number
  '7DayPositiveReviews': number
  '30DayPositiveReviews': number
  '30DayPositiveFeedback': number
  '7DayAppointments': number
  '7DayAppointmentCustomersCreated': number
  '1DayPrepaidOfferRevenue': number
  '7DayPrepaidOfferRevenue': number
  '1DayPrepaidOfferSales': number
  '7DayPrepaidOfferSales': number
  '30DayConversions': number
  '7DayConversions': number
  '30DayOutcomes': number
  '7DayOutcomes': number
  agencyId: number
  agencyIsHearst: boolean
  agencySubdomain: string
  webhookUrl: string
  nationalGroup: string
  partnerLocationExternalId: number
  partnerMerchantExternalId: number
  partnerMerchantExternalId2: number
  website: string | null
  tollFreePhone: string
  forwardingPhone: string
  facebookUrl: string
  facebookLeadsSegmentId: number
  yelpUsername: string
  onboarderUserId: number
  resetProactiveAutosendScheduleAt: string
  accountManagerId: number
  accountManagerName: string
  googlePlusEmail: string
  firstAttemptedContactUploadAt: string
  firstSubmittedContactUploadAt: string
  firstSuccessfullyOnboardedAt: string
  description: string
  googlePlaceExternalId: string
  googlePlusId: number
  disablePhoneForwardingAt: string
  yelpUrl: string
  yelpReviewUrl: string
  logoImageId: number
  logoImageUid: number
  webmasterName: string
  webmasterEmail: string
  webmasterPhoneNumber: string
  expirationDate: string
  greetingUrl: string
  mostRecentNonNcoClaimAt: string
  facebookAutoresponse: string
  missedCallAutoresponse: string
  homeadvisorAutoresponse: string
  googleMessagesAutoresponse: string
  thumbtackAutoresponse: string

  smsAutoresponse: string
  hours: LocationBusinessHours
  paymentMethods: PaymentMethods[]
  users: {
    id: string
    firstName: string
    lastName: string
    email: string
    isAgencyUser: boolean
    lastMobileActivityAt: string
  }[]
  yelpCategories: UnknownObject[]
  categoryIds: UnknownObject[]
  segmentNames: UnknownObject[]
  reviewSiteRatings: ReviewSiteRatings[]
  emailAccounts: UnknownObject[]
  integrations: IntegrationsInterface[]
  locationReviewSites: LocationReviewSites[]
  loopConfigurations: LoopConfigurationsInterface[]
  connectedIntegrations: string[]
  features: {
    featureCode: LocationFeature
    enabled: boolean
    quantity: number
    userFacing: boolean
  }[]
  forwardedMerchantNumbers: UnknownObject[]
  address: LocationAddressInterface
  branding: {
    city: string
    emailAddress: string
    phoneNumber: string
    state: string
    strategy: string
    street1: string
    street2: string
    website: string
    zip: string
    logoImageUid: string
  }
  emailPreferences: {
    senderName: string
    senderEmail: string
    emailFooterIncludeWebsite: boolean
    emailFooterIncludeAddress: boolean
    emailFooterIncludePhone: boolean
    emailFooterIncludeYelpUrl: boolean
    emailFooterIncludeFacebookUrl: boolean
    emailFooterIncludeGooglePlusUrl: boolean
    emailSignature: string
    emailHeaderFooterColor: string
    emailTitleColor: string
    primaryCustomizationColor: string
    secondaryCustomizationColor: string
  }
  reviewSiteStatistics: UnknownObject[]
  segmentStatistics: UnknownObject[]
  securusPhoneNumber: string
}

export type LocationBusinessHours = [
  LocationBusinessDayHours,
  LocationBusinessDayHours,
  LocationBusinessDayHours,
  LocationBusinessDayHours,
  LocationBusinessDayHours,
  LocationBusinessDayHours,
  LocationBusinessDayHours
]

export interface BusinessInfoInterface
  extends Pick<
    LocationLegacy,
    | 'address'
    | 'description'
    | 'hours'
    | 'locationId'
    | 'paymentMethods'
    | 'website'
  > {
  publishAddress?: boolean
  publishHours?: boolean
}

interface ContentTypeInterface {
  recipientInstructions: string
  /**
   * Available when content type is:
   * - `Constants.Messages.ContentType.NEWSLETTER`
   * - `Constants.Messages.ContentType.ANNOUNCEMENT`
   */
  headline?: string
  /**
   * Avaliable when content type is:
   * - `Constants.Messages.ContentType.NEWSLETTER`
   * - `Constants.Messages.ContentType.ANNOUNCEMENT`
   */
  body?: string
  /**
   * Avaliable when content type is:
   * - `Constants.Messages.ContentType.OFFER`
   */
  shortDescription?: string
  /**
   * Avaliable when content type is:
   * - `Constants.Messages.ContentType.OFFER`
   */
  longDescription?: string
  finePrint: {
    onePerPerson: false
    mustShowOffer: false
    cannotBeCombined: false
    noCashValue: false
    otherRestrictionsMay: false
    callForDetails: false
    subjectToAvailability: false
    limitationsApply: false
    participatingOnly: false
    subjectToChange: false
    additional: string
  }
  expiration: {
    date: string
  }
}

/** TODO: Enhance this interface with the correct types */
export interface MessageInterface {
  uploadedAt: string
  documentType: string
  id: string
  messageId: number
  locationIds: number[]
  potentialOfferId: null
  suggestedOfferDateId: null
  signpostId: string
  emailTitle: null
  emailSubject: null
  imageEnabled: null
  imageUid: null
  imageWidth: null
  imageHeight: null
  buttonEnabled: null
  buttonText: null
  buttonUrl: null
  body: null
  description: null
  finePrint: null
  price: null
  sendAt: null
  nextScheduledAt: null
  lastSentAt: null
  status: null
  title: null
  expirationOffset: null
  shortDescription: null
  saleStart: null
  saleEnd: null
  availableEnd: null
  canceledAt: null
  sendEmail: null
  sendSms: null
  postToFacebook: null
  createdAt: null
  updatedAt: string
  type: 'GPC' | string
  redemptionInstructions: null
  expiresAt: null
  sendIntervalDays: null
  phoneNumber: null
  goal: string
  variations: unknown[]
  availableStartDate: string
  availableEndDate: null
  content: {
    version: number
    legacyTagline: string
    ctaText: string
    url: null
    image: {
      enabled: boolean
      uid: null
      width: null
      height: null
    }
    subject?: {
      text: string
      showPersonalizedGreeting: boolean
    }
    contentType: keyof typeof Constants.Messages.ContentType
    [Constants.Messages.ContentType.ANNOUNCEMENT]?: ContentTypeInterface
    [Constants.Messages.ContentType.NEWSLETTER]?: ContentTypeInterface
    [Constants.Messages.ContentType.OFFER]?: ContentTypeInterface
  }
  reminderOptions: null
  lifecycleStage: null
  blasts: {
    id: number
    scheduledAt: string
    sendOnDate: string
    sendAsap: boolean
    timingOptions: {
      version: number
    }
    targetingOptions: {
      version: number
      postToFacebook: boolean
      sendSms: boolean
      sendEmail: boolean
      segmentNames: null
      lifecycleStages: null
    }
    reminderOptions: {
      version: number
      schedule: unknown[]
    }
    sentAsLegacyScheduledCampaign: boolean
  }[]
  provisioned: boolean
  twilioMessageServiceExternalId: null
  hidden: boolean
  totalEmailOpens: number
  totalReminderEmailOpens: number
  totalEmailSent: number
  totalReminderEmailSent: number
  totalSmsSent: number
  totalReminderSmsSent: number
  totalEmailClaims: number
  totalSmsClaims: number
  totalConversions: number
  totalCtaClicks: number
  totalOutcomes: number
  totalPurchases: number
  locationStatistics: {
    locationId: number
    conversions: number
    smsClaims: number
  }[]
}
export interface ErrorObject extends Error {
  status: number
  failureDetails: {
    reason?: string
  }
  data: {
    fingerprint: string
    message?: string
  }
}

export interface PublicReviews {
  id: number
  authorName: string
  date: string
  externalId: string
  reportedAt: string
  reviewText: string
  reviewUrl: string
  drivenBySignpost: boolean
  locationId: number
  rating: number
  hiddenFromUi: boolean
  reviewSiteId: number
}

export interface Feedback {
  uploadedAt: string
  documentType: string
  id: string
  feedbackId: number
  channelId: number
  contactId: number
  locationId: number
  merchantId: number
  consumerEmailAddressId: number
  consumerPhoneNumberId: number
  isPositive: boolean
  rating: number
  feedbackText: string
  feedbackSubmissions: {
    feedbackSubmissionId: number
    isPositive: boolean
    rating: number
    ratingDenominator: number
    text: string
    customerConsent: boolean
    submittedAt: string
  }[]
  feedbackRatingDenominator: number
  submittedAt: string
  timestamp: string
  customerConsent: boolean
  merchantApproved: boolean
  isConversation: number
  createdAt: string
  updatedAt: string
}

export interface LocationPublicReviews {
  locationId: number
  publicReviews: PublicReviews[]
}

export type AllSettledResponse<T = unknown> = [
  fulfilled: PromiseFulfilledResult<T>[],
  rejected: PromiseRejectedResult[]
]

export interface WebhookPageEventDocumentsInterface {
  contactId: number
  documentType: string
  id: string
  locationId: number
  /** ISO String
   * For example: `"2022-10-13T19:41:30.000Z"`
   */
  timestamp: string
}

export type WebSocketPageEvent = {
  type: string
  documents: WebhookPageEventDocumentsInterface[]
}[]

export interface SearchDTO {
  search: string
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortDTO {
  direction?: SortDirection
  field?: string
}

export class PaginationDTO {
  take?: number
  skip?: number
  token?: string
}
