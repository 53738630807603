import React from 'react'
import styled, { useTheme } from 'styled-components'
import useConfirmBusinessInfoMutation from 'src/client/hooks/mutations/useConfirmBusinessInfoMutation'
import useUpdateBusinessInfoMutation from 'src/client/hooks/mutations/useUpdateBusinessInfoMutation'
import {
  BusinessInfoInterface,
  LocationAddressInterface,
  LocationBusinessHours,
} from 'src/client/interfaces/Common'
import { LocationAddress } from 'src/client/interfaces/Locations'
import { SmsRegistrationStatus } from 'src/client/interfaces/SmsRegistration'
import BusinessTerminologySection from 'src/components/Settings/Business/BusinessTerminologySection'
import StatusIndicator from 'src/components/Settings/Business/StatusIndicator'
import HelperTooltip, { HelperTooltipWrapper } from 'src/stories/HelperTooltip'
import WarningCard from 'src/components/Settings/common/WarningCard'
import PageLayout from 'src/components/WafLayout/PageLayout'
import AddressForm from 'src/containers/Settings/Business/forms/AddressForm'
import DescriptionForm from 'src/containers/Settings/Business/forms/DescriptionForm'
import HoursForm, {
  displayHoursFormatter,
} from 'src/containers/Settings/Business/forms/HoursForm'
import PaymentsForm, {
  PAYMENT_METHODS,
} from 'src/containers/Settings/Business/forms/PaymentsForm'
import WebsiteForm from 'src/containers/Settings/Business/forms/WebsiteForm'
import { MutationBusinessProps } from 'src/containers/Settings/Business/types'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'
import EditableFormContainer from 'src/stories/EditableFormContainer'
import SectionContainer, { SectionAction } from 'src/stories/SectionContainer'
import { formatAddress, generateLocationUrl } from 'src/utils'
import Constants from 'src/lib/Constants'
import { Body, Heading } from 'src/stories/typography'

const StyledHoursWrapper = styled.span(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: theme.space(62),
}))

const StyledHours = styled.span(({ theme }) => ({
  width: theme.space(35),
}))

const StyledSeparator = styled.div(({ theme }) => ({
  height: theme.space(8),
}))

const SmsRegistrationTitle = () => {
  return (
    <HelperTooltipWrapper>
      SMS Registration
      <HelperTooltip>
        To register your account for texting and SMS marketing, you need to
        provide your 9-digit Employer Identification Number (EIN), which can be
        found on your tax preparation documents.
      </HelperTooltip>
    </HelperTooltipWrapper>
  )
}

const SmsRegistrationSubtitle = () => {
  return (
    <span>
      To use text marketing features for generating reviews and texting with
      customers, register your rented number with your business. This ensures
      compliance with The Campaign Registry's A2P 10DLC regulation.
    </span>
  )
}

const replaceNullsWithEmptyStrings = <TReturn, TParam extends object>(
  obj: TParam
) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value ?? ''])
  ) as TReturn

interface FormConfigProps {
  disabled: boolean
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
}

export type GetFormConfigProps = (formName: string) => FormConfigProps

const RegistrationStatusText = {
  [SmsRegistrationStatus.NOT_STARTED]: 'Not registered ',
  [SmsRegistrationStatus.IN_PROGRESS]:
    'Registration submitted. Currently pending approval.',
  [SmsRegistrationStatus.PENDING_PHONE_VERIFICATION]:
    'Registration submitted. Currently pending approval.',
  [SmsRegistrationStatus.APPROVED]: 'Registration Approved',
  [SmsRegistrationStatus.FAILED]: `Registration not approved. Please contact ${Constants.Branding.companyName} support at (855) 606-4900, Monday to Friday, 7AM - 6PM MST.`,
} as const

export type MutationBusinessInfoProps = MutationBusinessProps & {
  onSubmitFinished: () => void
}

const Business: React.FC = () => {
  const theme = useTheme()
  const baseDataAttribute = 'settings-business'
  const { activeLocationV3, locationId, merchantId } = useLocationContext()
  const {
    mutateAsync: mutateConfirmBusinessInfo,
    isLoading: isLoadingConfirmBusinessInfo,
  } = useConfirmBusinessInfoMutation()
  const { mutateAsync: mutateBusinessInfo, isLoading: isLoadingBusinessInfo } =
    useUpdateBusinessInfoMutation(locationId)
  const [currentEditingForm, setCurrentEditingForm] = React.useState('')
  const defaultBusinessInformation: BusinessInfoInterface = {
    locationId,
    address: replaceNullsWithEmptyStrings<
      LocationAddressInterface,
      LocationAddress
    >(activeLocationV3.address),
    publishAddress: activeLocationV3.address.publishAddress,
    paymentMethods: activeLocationV3.businessInfo.paymentMethods,
    publishHours: !!activeLocationV3.businessInfo.publishHours,
    description: activeLocationV3.businessInfo.description || '',
    hours: activeLocationV3.businessInfo.operatingHours.map((h) => ({
      from: h.isClosed || h.isInvalid ? 'Closed' : h.from,
      to: h.isClosed || h.isInvalid ? 'Closed' : h.to,
    })) as LocationBusinessHours,
    website: activeLocationV3.businessInfo.website ?? null,
  }

  const getFormConfig: GetFormConfigProps = (formName) => ({
    disabled:
      (currentEditingForm !== '' && currentEditingForm !== formName) ||
      isLoadingBusinessInfo,
    isEditing: currentEditingForm === formName,
    setIsEditing: (action) => setCurrentEditingForm(action ? formName : ''),
  })

  const mutationBusinessInfoProps: MutationBusinessInfoProps = {
    update: (newValue: Partial<BusinessInfoInterface>) =>
      mutateBusinessInfo({
        ...defaultBusinessInformation,
        ...newValue,
      }),
    isLoading: isLoadingBusinessInfo,
    onSubmitFinished: () => setCurrentEditingForm(''),
  }

  const registrationStatus =
    activeLocationV3.registrationStatus === SmsRegistrationStatus.NOT_STARTED
      ? SmsRegistrationStatus.IN_PROGRESS // NOT_STARTED API status: began on API but pending for Twilio send
      : activeLocationV3.registrationStatus || SmsRegistrationStatus.NOT_STARTED

  const smsRegistrationPage = generateLocationUrl(
    merchantId,
    locationId,
    '/settings/business/sms-registration'
  )

  return (
    <PageLayout
      title="Business Settings"
      subtitle="View and update your business information."
      baseDataAttribute={baseDataAttribute}
    >
      <SectionContainer
        title="Business Info"
        childrenStyle={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.space(4),
        }}
      >
        {!activeLocationV3.firstConfirmedBusinessInfoAt && (
          <WarningCard content="Review your business info and confirm that it is correct">
            <Button
              label="Yes, this is correct"
              action="accent"
              style={{ width: 'auto' }}
              loading={isLoadingConfirmBusinessInfo}
              onClick={() => mutateConfirmBusinessInfo(locationId)}
            />
          </WarningCard>
        )}
        <EditableFormContainer
          {...getFormConfig('website')}
          formComponent={
            <WebsiteForm
              {...mutationBusinessInfoProps}
              baseDataAttribute={baseDataAttribute}
            />
          }
        >
          <Heading as="h3">Website</Heading>
          <Body>{activeLocationV3.businessInfo.website || 'Not provided'}</Body>
        </EditableFormContainer>
        <EditableFormContainer
          {...getFormConfig('address')}
          formComponent={
            <AddressForm
              {...mutationBusinessInfoProps}
              baseDataAttribute={baseDataAttribute}
            />
          }
        >
          <Heading as="h3">Address</Heading>
          <Body>
            {formatAddress(
              activeLocationV3.address.street1,
              activeLocationV3.address.street2 ?? '',
              activeLocationV3.address.city,
              activeLocationV3.address.state,
              activeLocationV3.address.zip
            ) || 'Not provided'}
          </Body>
        </EditableFormContainer>
        <EditableFormContainer
          {...getFormConfig('payments')}
          formComponent={
            <PaymentsForm
              {...mutationBusinessInfoProps}
              baseDataAttribute={baseDataAttribute}
            />
          }
        >
          <Heading as="h3">Payments</Heading>
          <Body>
            {activeLocationV3.businessInfo.paymentMethods.length > 0
              ? activeLocationV3.businessInfo.paymentMethods
                  .map((value) => PAYMENT_METHODS[value])
                  .join(', ')
              : 'Not provided'}
          </Body>
        </EditableFormContainer>
        <EditableFormContainer
          {...getFormConfig('description')}
          formComponent={
            <DescriptionForm
              {...mutationBusinessInfoProps}
              baseDataAttribute={baseDataAttribute}
            />
          }
        >
          <Heading as="h3">Description</Heading>
          <Body>
            {activeLocationV3.businessInfo.description || 'Not provided'}
          </Body>
        </EditableFormContainer>
        <EditableFormContainer
          {...getFormConfig('hours')}
          formComponent={
            <HoursForm
              {...mutationBusinessInfoProps}
              baseDataAttribute={baseDataAttribute}
            />
          }
        >
          <Heading as="h3">Hours</Heading>
          <Body>
            {displayHoursFormatter(
              activeLocationV3.businessInfo.operatingHours
            ).map((day) => (
              <StyledHoursWrapper key={day.dayOfWeek}>
                {day.dayOfWeek}
                <StyledHours>{day.label}</StyledHours>
              </StyledHoursWrapper>
            ))}
          </Body>
        </EditableFormContainer>
      </SectionContainer>

      <StyledSeparator />

      <SectionContainer
        title={<SmsRegistrationTitle />}
        subtitle={<SmsRegistrationSubtitle />}
      >
        <SectionContainer
          variant="primary"
          disabled={!!currentEditingForm}
          action={
            registrationStatus === SmsRegistrationStatus.NOT_STARTED ? (
              <SectionAction
                label="Register"
                href={smsRegistrationPage}
                disabled={!!currentEditingForm}
              />
            ) : undefined
          }
        >
          <Heading as="h3">
            Status <StatusIndicator status={registrationStatus} />
          </Heading>
          <Body>{RegistrationStatusText[registrationStatus]}</Body>
        </SectionContainer>
      </SectionContainer>

      <StyledSeparator />

      <BusinessTerminologySection
        baseDataAttribute={baseDataAttribute}
        getFormConfig={getFormConfig}
      />
    </PageLayout>
  )
}

export default Business
