import {
  createContext,
  Dispatch,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { getIframeUrl, getLocalUrl } from 'src/utils'
import { UnknownObject } from 'src/utils/interfaces'
import useAuthContext from './AuthContext'
import useAccountContext from './AccountContext'
import { useAuth0 } from '@auth0/auth0-react'
import useSessionCookies from 'src/hooks/useSessionCookies'
import Constants from 'src/lib/Constants'

interface NavigationInterceptorContextProps {
  iframeReady: boolean
  iframeSrc: string
  pageTitle: string
  setPageTitle: Dispatch<React.SetStateAction<string>>
  handleUnmountIframe: () => void
  iframeRef: RefObject<HTMLIFrameElement>
}

const NavigationInterceptorContext =
  createContext<NavigationInterceptorContextProps>({
    iframeReady: false,
    iframeSrc: '',
    pageTitle: Constants.Branding.companyName,
    setPageTitle: () => null,
    handleUnmountIframe: () => null,
    iframeRef: { current: null },
  })

const userConfigPages = ['settings', 'email-subscription']

export const NavigationInterceptorProvider: React.FCWithChildren = ({
  children,
}) => {
  const { user } = useAuthContext()
  const { getSessionCookies } = useSessionCookies()
  const { getAccessTokenSilently } = useAuth0()
  const { locations } = useAccountContext()

  const navigate = useNavigate()
  const { pathname: routePath, search, hash } = useLocation()

  const [iframeReady, setIframeReady] = useState(false)
  const [iframeSrc, setIframeSrc] = useState('')
  const [pageTitle, setPageTitle] = useState(Constants.Branding.companyName)

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const handleUnmountIframe = useCallback(() => {
    setIframeReady(false)
  }, [])

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        const { type, data } = event.data as UnknownObject<string>

        if (type === 'navigationEvent') {
          const url = getLocalUrl(data)

          // Oauth and OBW links need to navigate outside of the app using the browser's location.assign method.
          // This navigation needs to follow the same pattern used for navigation between pages loaded via Iframe,
          // by unmounting it before navigation occurs to avoid flashing errors, Legacy header visible,
          // multiple navigation events, as well as navigation happening inside of the iframe.
          if (url.includes('oauth') || /.*\/obw\/.*/.test(url)) {
            setIframeReady(false)
            setIframeSrc('')
            window.location.assign(data)
          } else {
            navigate(url)
          }
        }
      },
      false
    )

    return () => {
      window.removeEventListener('message', () => null, false)
    }
  }, [navigate])

  useEffect(() => {
    setIframeReady(false)

    let timerId: NodeJS.Timeout | undefined

    if (user) {
      // When a navigation happens within the iframe,
      // we need to wait a bit before re-setting the src,
      // otherwise the browser might interpret it as a
      // duplicated HTML request and cancel it (which
      // causes the "duplicated header" bug, since
      // the entire app will reload in the iframe).
      //
      // 500ms works for Chrome, but it might be different
      // in other browsers.
      timerId = setTimeout(async () => {
        const { bearer: foundBearer } = getSessionCookies()
        const bearer = foundBearer ?? (await getAccessTokenSilently())

        const newSrc = getIframeUrl(routePath, search, hash, bearer)
        let isUserConfigUrl = false

        userConfigPages.forEach((str) => {
          if (new RegExp(`/user/[0-9]+/${str}`).test(newSrc)) {
            isUserConfigUrl = true
          }
        })

        const isMultiLocationUrl =
          new RegExp(`/user/[0-9]+`).test(newSrc) ||
          new RegExp(`/user/[0-9]+/*`).test(newSrc)

        if (!isUserConfigUrl && isMultiLocationUrl && locations?.length === 1) {
          navigate(
            `/${locations[0].merchantId}/locations/${locations[0].locationId}/messaging-hub`
          )

          return
        }

        if (!newSrc.split('?')[0]?.includes('oauth')) {
          setIframeSrc(newSrc)
        }
        setIframeReady(true)
      }, 500)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [
    routePath,
    search,
    hash,
    user,
    locations,
    navigate,
    getAccessTokenSilently,
    getSessionCookies,
  ])

  const context = {
    iframeReady,
    iframeSrc,
    pageTitle,
    setPageTitle,
    handleUnmountIframe,
    iframeRef,
  }

  return (
    <NavigationInterceptorContext.Provider value={context}>
      {children}
    </NavigationInterceptorContext.Provider>
  )
}

const useNavigationInterceptorContext = () =>
  useContext(NavigationInterceptorContext)

export default useNavigationInterceptorContext
