import React, { MouseEventHandler } from 'react'
import styled, { CSSObject } from 'styled-components'
import Anchor from './private/Anchor'

const StyledLabel = styled.span<Pick<ButtonProps, 'maxWidth'>>(
  ({ maxWidth }) => {
    if (!!maxWidth)
      return {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }
  }
)

interface StylesSchemaInterface extends CSSObject {
  svg: CSSObject
}

const StyledButton = styled.button<
  Omit<Partial<ButtonProps>, 'loading'> & { $loading?: boolean }
>(
  ({
    theme,
    size,
    action,
    outline,
    $loading: loading,
    displayAsText,
    ...props
  }) => {
    const hoverPalette = (optionalSecondary = false) =>
      action === 'primary'
        ? theme.colors.primary_2_70
        : action === 'danger'
        ? theme.colors.critical_70
        : action === 'secondary'
        ? optionalSecondary
          ? theme.colors.base_20
          : theme.colors.base_60
        : action === 'confirm'
        ? theme.colors.positive_70
        : theme.colors.warning_70

    const actionPalette = (optionalSecondary = false) =>
      action === 'primary'
        ? theme.colors.primary_2
        : action === 'secondary'
        ? optionalSecondary
          ? theme.colors.base_20
          : theme.colors.base_60
        : action === 'danger'
        ? theme.colors.critical
        : action === 'confirm'
        ? theme.colors.positive
        : theme.colors.warning

    const hoverLoadingStyles: CSSObject = {
      backgroundColor:
        action === 'primary'
          ? theme.colors.primary_2_70
          : action === 'danger'
          ? theme.colors.critical_70
          : action === 'secondary'
          ? theme.colors.base_10
          : action === 'confirm'
          ? theme.colors.positive_70
          : theme.colors.warning_70,
      color:
        action === 'secondary' ? theme.colors.base_30 : theme.colors.base_0,

      svg: {
        fill:
          action === 'secondary' ? theme.colors.base_30 : theme.colors.base_0,
      },
    }

    const hoverStyles = (toggleOutline = false, toggleAsLink = false) => {
      let hoverStylesObject: CSSObject = { ...hoverLoadingStyles }

      if (toggleOutline) {
        hoverStylesObject = {
          ...hoverStylesObject,
          borderColor: hoverPalette(true),
          color: hoverPalette(true),
          backgroundColor: 'transparent',
          svg: {
            fill: hoverPalette(true),
          },
        }
      }

      if (toggleAsLink) {
        hoverStylesObject = {
          ...hoverStylesObject,
          backgroundColor: 'transparent',
          color: hoverPalette(true),
          svg: {
            fill: hoverPalette(true),
          },
        }
      }

      return hoverStylesObject
    }

    const loadingStyles = (
      toggleLoading = false,
      toggleAsLink = false
    ): CSSObject => {
      let loadingStylesObject: CSSObject = {}

      if (toggleLoading) {
        loadingStylesObject = {
          ':enabled': hoverLoadingStyles,
        }
      }

      if (toggleLoading && toggleAsLink) {
        loadingStylesObject = {
          ':enabled': {
            ...hoverLoadingStyles,
            backgroundColor: 'transparent',
            color: hoverPalette(true),
            svg: {
              fill: hoverPalette(true),
            },
          },
        }
      }

      return loadingStylesObject
    }

    const sizeOptions = {
      medium: {
        fontSize: '1.4rem',
        iconSize: theme.space(4),
        iconSpace: theme.space(2),
        padding: `${theme.space(outline ? 1.5 : 2)}
                  ${theme.space(outline ? 2.5 : 3)}`,
      },
      large: {
        fontSize: '1.6rem',
        iconSize: theme.space(6),
        iconSpace: theme.space(3),
        padding: `${theme.space(outline ? 2.5 : 3)}
                  ${theme.space(outline ? 3.5 : 4)}`,
      },
    }

    const stylesSchema: StylesSchemaInterface = {
      whiteSpace: props.allowLineBreak ? 'unset' : 'nowrap',
      display: displayAsText ? 'inline-flex' : 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: sizeOptions[size!].iconSpace,

      width: displayAsText ? 'initial' : '100%',
      maxWidth: props.maxWidth,
      maxHeight: props.maxHeight,
      padding: displayAsText ? theme.space(0) : sizeOptions[size!].padding,

      border: outline ? '2px solid' : 0,
      borderRadius: theme.constants.borderRadius,
      borderColor: 'transparent',
      cursor: 'pointer',
      boxSizing: 'border-box',

      fontSize: sizeOptions[size!].fontSize,
      fontWeight: 500,
      lineHeight: displayAsText
        ? sizeOptions[size!].fontSize
        : sizeOptions[size!].iconSize,
      textAlign: 'center',
      outline: 'none',
      '-webkit-appearance': 'none',
      textDecoration: 'none',
      color:
        action === 'secondary' ? theme.colors.base_50 : theme.colors.base_0,
      backgroundColor: actionPalette(true),

      svg: {
        display: 'inline',
        verticalAlign: 'text-bottom',
        fill:
          action === 'secondary' ? theme.colors.base_50 : theme.colors.base_0,
        width: sizeOptions[size!].iconSize,
        height: sizeOptions[size!].iconSize,
      },

      ':hover:enabled': hoverStyles(outline, displayAsText),
      ...loadingStyles(loading),

      ':disabled': {
        cursor: 'auto !important',
        background: theme.colors.base_10,
        svg: {
          fill: theme.colors.base_10,
        },
      },
    }

    if (outline) {
      stylesSchema.svg.fill = actionPalette()
      stylesSchema.borderColor = actionPalette()
      stylesSchema.color = actionPalette()
      stylesSchema.backgroundColor = 'transparent'

      stylesSchema[':disabled']!.opacity = '.4'
    }

    if (displayAsText) {
      stylesSchema.backgroundColor = 'transparent'
      stylesSchema.color = actionPalette()
      stylesSchema.svg.fill = actionPalette()
      stylesSchema[':disabled']!.backgroundColor = 'transparent'
      stylesSchema[':disabled']!.color = theme.colors.base_10
    }

    if (!displayAsText) {
      stylesSchema[':focus'] = {
        outline: `2px solid ${actionPalette()}`,
      }
    }

    return stylesSchema
  }
)

export interface ButtonProps {
  /**
   * The principal call to action
   */
  action?: 'primary' | 'secondary' | 'danger' | 'confirm' | 'accent'
  /**
  /**
   * Outlined style for button
   */
  outline?: boolean

  /**
   * Used to display the button inline and without text
   * decorations, such as the "underline" if "asLink" is
   * true.
   */
  displayAsText?: boolean
  /**
   * How large should the button be?
   */
  size?: 'medium' | 'large' // before creating 'small' size double check the need and accessibility
  /**
   * What type of button
   */
  type?: 'submit' | 'reset' | 'button'
  /**
   * Button contents
   */
  label: string | JSX.Element
  /**
   * Button loading state
   */
  loading?: boolean
  /**
   * Button disabled
   */
  disabled?: boolean
  /**
   * Allow line break as exception - avoid using it, prioritize text reduction
   */
  allowLineBreak?: boolean
  /**
   * Max width - required prop to show ellipsis
   */
  maxWidth?: number | string
  /**
   * Max height
   */
  maxHeight?: number | string
  /**
   * href to render an anchor and inside a button
   */
  href?: string
  /**
   * Specifies where to open the linked document
   */
  target?: string
  /**
   * Specifies the relationship between the current document and the linked document
   */
  rel?: string
  /**
   * Style properties to customize the button
   */
  style?: React.CSSProperties
  /**
   * Style properties to customize the link anchor, parent of button
   */
  linkStyle?: React.CSSProperties
  /**
   * Optional click handler
   * If href is truthy, will pass this property to the anchor
   * instead of the button.
   */
  onClick?:
    | MouseEventHandler<HTMLButtonElement>
    | MouseEventHandler<HTMLAnchorElement>
  /**
   * Icon
   */
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  /**
   * Icon in the left
   */
  endIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  /**
   * Style properties to customize the icon
   */
  iconStyles?: React.CSSProperties
  /**
   * add reference to the StyledButton
   */
  reference?: React.RefObject<HTMLButtonElement>
  /**
   * add reference to the label of the StyledButton
   */
  labelReference?: React.RefObject<HTMLLabelElement>
  /**
   * Base Data attribute.
   * Will append `-button`, `-link`, `-label` and `-icon` to containing elements
   */
  baseDataAttribute?: string
  /**
   * By default, data-dd attribute will added to the element with the value of
   * `baseDataAttribute` (which defaults to `Button`).
   * Providing this variable, this string will be used for the value of the
   * data-dd attribute.
   */
  overrideDataDdAction?: string
  /**
   * Name of the form that the button will be submitting for.
   *
   * e.g. https://github.com/signpost/web-app-frontend/blob/d56d87f8d057dc7dfb01ab7282afd66887f1e83e/src/stories/Modal.stories.tsx#L116
   */
  form?: string
}

interface ExcludeProps {
  /** Use `baseDataAttribute` prop instead */
  'data-cy'?: never
  /** Use `overrideDataDdAction` prop instead */
  'data-dd'?: never
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps & ExcludeProps> = ({
  action = 'primary',
  outline = false,
  size = 'medium',
  type = 'button',
  loading = false,
  href = '',
  onClick,
  icon: Icon,
  endIcon: EndIcon,
  iconStyles,
  label,
  style = {},
  linkStyle = {},
  reference,
  labelReference,
  disabled = false,
  baseDataAttribute = 'Button',
  overrideDataDdAction,
  target,
  rel,
  ...props
}) => {
  const button = (
    <StyledButton
      type={type}
      size={size}
      style={style}
      ref={reference}
      action={action}
      outline={outline}
      $loading={loading}
      disabled={!!disabled || !!loading}
      onClick={onClick as MouseEventHandler<HTMLButtonElement>}
      data-cy={baseDataAttribute + '-button'}
      data-dd={overrideDataDdAction ?? baseDataAttribute}
      {...props}
    >
      {Icon && (
        <Icon data-cy={baseDataAttribute + '-icon'} style={iconStyles} />
      )}
      {label && (
        <StyledLabel
          ref={labelReference}
          data-cy={baseDataAttribute + '-label'}
          {...props}
        >
          {label}
        </StyledLabel>
      )}
      {EndIcon && (
        <EndIcon data-cy={baseDataAttribute + '-icon'} style={iconStyles} />
      )}
    </StyledButton>
  )

  return (
    <>
      {href && !disabled ? ( // This validation is necessary because hyperlinks do not have a "disabled" attribute
        <Anchor
          data-cy={baseDataAttribute + '-link'}
          href={href}
          style={{
            maxWidth: props.maxHeight,
            maxHeight: props.maxHeight,
            display: props.displayAsText ? undefined : 'flex',
            textDecoration: 'none',
            ...linkStyle,
          }}
          data-dd={overrideDataDdAction ?? baseDataAttribute}
          target={target}
          rel={rel}
        >
          {button}
        </Anchor>
      ) : (
        button
      )}
    </>
  )
}
