import Highcharts, {
  FormatterCallbackFunction,
  Point,
  Series,
} from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import styled, { ThemeProvider, useTheme } from 'styled-components'

import useInsightsContext from 'src/contexts/InsightsContext'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { InsightsWidget } from './InsightsWidget'
import { StyledLegendItem, StyledWidgetContentContainer } from './styled'
import { WidgetProps } from './types'

const StyledChartContainer = styled.div(({ theme }) => ({
  display: 'inline-grid',
}))

const LRCallsByWrapupCodeWidget: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const { isLargeScreen, isExtraLargeScreen } = useScreenSizes()
  const { liveReceptionistReportingData, isLiveReceptionistLoading } =
    useInsightsContext()

  const { callNumbersByWrapupCode } = liveReceptionistReportingData

  const data = Object.entries(callNumbersByWrapupCode).map((key) => {
    switch (key[0]) {
      case 'callBackRequest':
        return { name: 'Call Back', y: key[1] }
      case 'billing':
        return { name: 'Billing', y: key[1] }
      case 'complaint':
        return { name: 'Complaint', y: key[1] }
      case 'other':
        return { name: 'Other', y: key[1] }
      case 'quote':
        return { name: 'Quote', y: key[1] }
      case 'scheduling':
        return { name: 'Scheduling', y: key[1] }
      case 'solicitor':
        return { name: 'Solicitor', y: key[1] }
      default:
        return { name: key[0], y: key[1] }
    }
  })

  const sortedData = data.sort((first, second) => {
    return second.y - first.y
  })

  // using "function ()" here rather than a fat arrow function so that "this" for name
  // and percentage is referenced within the correct context of the highcharts object.
  const labelFormatter: FormatterCallbackFunction<Point | Series> =
    function () {
      return renderToStaticMarkup(
        <ThemeProvider theme={theme}>
          <StyledLegendItem
            name={this.name}
            percentage={(this as Point).percentage ?? 0}
          />
        </ThemeProvider>
      )
    }

  const options: Highcharts.Options = {
    title: {
      text: '',
    },
    colors: [
      theme.colors.primary_1,
      theme.colors.primary_2,
      theme.colors.primary_2_70,
      theme.colors.primary_1_45,
      theme.colors.primary_1_25,
      theme.colors.primary_1_10,
      theme.colors.base_30,
      theme.colors.base_20,
    ],
    chart: {
      type: 'pie',
      marginTop: !(!isExtraLargeScreen && isLargeScreen) ? 0 : 30,
      height: 200,
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      enabled: !(!isExtraLargeScreen && isLargeScreen),
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginBottom: 8,
      labelFormatter,
      useHTML: true,
      symbolRadius: 0,
    },
    plotOptions: {
      pie: {
        cursor: 'pointer',
        dataLabels: {
          enabled: !isExtraLargeScreen && isLargeScreen,
        },
        showInLegend: !(!isExtraLargeScreen && isLargeScreen),
      },
    },
    series: [
      {
        colorByPoint: true,
        name: 'Calls',
        data: sortedData,
        type: 'pie',
        states: {
          hover: {
            enabled: true,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    ],
    accessibility: { enabled: false },
    credits: {
      enabled: false,
    },
  }

  if (isLiveReceptionistLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  return (
    <InsightsWidget
      baseDataAttribute={baseDataAttribute}
      title="Call Breakdown"
    >
      <StyledWidgetContentContainer
        data-cy={baseDataAttribute + '-calls-by-wrap-up-code'}
      >
        <StyledChartContainer>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </StyledChartContainer>
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default LRCallsByWrapupCodeWidget
