import { AxiosInstance } from 'axios'

import Resource from '../resource'
import { CustomFeaturePackagesRequests } from 'src/client/interfaces/FeaturePackages'

const actions = (client: AxiosInstance): CustomFeaturePackagesRequests => ({
  findLocationFeaturePackages: ({ locationId }) => {
    return client.get(`/v3/locations/${locationId}/feature-packages`)
  },
})

export default Resource(actions)
