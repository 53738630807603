import styled, { useTheme } from 'styled-components'

import Constants from 'src/lib/Constants'
import {
  FacebookMessengerIcon,
  AngiLeadsIcon,
  SmsIcon,
  ReviewIcon,
  FeedbackIcon,
  StarIcon,
  GoogleIcon as GoogleConversationalIcon,
  FacebookIcon,
  YelpIcon as YelpConversationalIcon,
  UnreadIcon,
  PhoneIcon,
  ThumbtackIcon,
  WebchatIcon,
} from 'src/stories/assets'

import { extractFeedbackOutcome } from 'src/components/MessagingHub/styled'
import {
  ConversationListItem as ConversationListItemType,
  isConversationResource,
  isPublicReview,
} from 'src/contexts/ConversationsListContext/types'
import { conversationEventTypePredicate } from 'src/client/interfaces/Conversations'
import useAccountContext from 'src/contexts/AccountContext'
import { useParams } from 'react-router'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { getActiveLocation } from 'src/utils'
import { useFormattedTimestamp } from '../hooks'
import {
  getConversationItemNameTitle,
  getConversationItemPreviewErrorText,
  getConversationItemPreviewText,
  getConversationItemTimestamp,
} from 'src/contexts/ConversationsListContext/utils'
import {
  StyledContactNameText,
  StyledFormattedTimeText,
  StyledMessageTitleText,
  StyledPreviewMessageText,
  StyledPreviewMessageErrorText,
} from 'src/components/MessagingHub/ConversationsPane/ConversationListItem/typography'
import Checkbox from 'src/stories/Checkbox'

const unreadIconSize = 3
const columnSpace = 2
const reservedUnreadSpace = unreadIconSize + columnSpace
const checkboxSize = 5
const iconSize = 4

const StyledContainer = styled.div<{ active: boolean }>(
  ({ active, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.space(3)} ${theme.space(4)}`,
    color: theme.colors.base_50,
    cursor: 'pointer',
    backgroundColor: active ? theme.colors.primary_1_10 : theme.colors.base_0,

    '&:hover': {
      backgroundColor: theme.colors.primary_1_10,
    },
  })
)

const StyledContentContainer = styled.div<{ editMode?: boolean }>(
  ({ theme, editMode }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: editMode
      ? `calc(100% - ${theme.space(checkboxSize + columnSpace)})`
      : '100%',
    rowGap: theme.space(1),
    paddingLeft: theme.space(reservedUnreadSpace),
  })
)

const StyledContentRowContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(columnSpace),
  '> *': { whiteSpace: 'nowrap' },
}))

const StyledIconWrapper = styled.div<{
  customSize?: number
  isVisible?: boolean
}>(({ theme, customSize = iconSize, isVisible = true }) => ({
  height: theme.space(customSize),
  width: theme.space(customSize),
  visibility: isVisible ? 'visible' : 'hidden',
}))

const StyledSeparator = styled.div(({ theme }) => ({
  borderBottom: `2px solid ${theme.colors.base_5}`,
}))

export interface ConversationListItemProps {
  conversationItem: ConversationListItemType
  selected: boolean
  index?: number
  isEditDisabled: boolean
  onClick: () => void
  editMode?: boolean
  toggleSelectConversation: (conversationId: number, checked: boolean) => void
  checked: boolean
}

const ConversationListItem: React.FC<ConversationListItemProps> = ({
  conversationItem,
  selected,
  index,
  isEditDisabled,
  onClick,
  editMode,
  toggleSelectConversation,
  checked,
}) => {
  const theme = useTheme()
  const { locationId } = useParams<UseLocationRouteParams>()
  const { locations } = useAccountContext()
  const activeLocation = getActiveLocation(locations, locationId)
  const time = useFormattedTimestamp(
    getConversationItemTimestamp(conversationItem)
  )

  const iconProps = {
    color: theme.colors.base_100,
    height: theme.space(iconSize),
    width: theme.space(iconSize),
    position: 'relative',
  }

  let Icon = <SmsIcon fill={theme.colors.base_100} {...iconProps} />
  let ReviewFeedbackConversationalIcon: JSX.Element
  let feedbackDisplayText: string
  let reviewRating: number

  const isReviewConversation =
    (isConversationResource(conversationItem) &&
      (conversationEventTypePredicate(
        conversationItem.mostRecentEvent,
        'REVIEW'
      ) ||
        conversationEventTypePredicate(
          conversationItem.mostRecentEvent,
          'PUBLIC_REVIEW'
        ))) ||
    isPublicReview(conversationItem)

  if (conversationItem.id === 0) {
    Icon = <WebchatIcon fill={theme.colors.base_100} {...iconProps} />
  } else {
    if (isReviewConversation) {
      Icon = <ReviewIcon {...iconProps} />
      let siteId = 0

      if (isPublicReview(conversationItem)) {
        reviewRating = conversationItem.rating
        siteId = conversationItem.reviewSiteId
      } else {
        if (
          conversationEventTypePredicate(
            conversationItem.mostRecentEvent,
            'REVIEW'
          )
        ) {
          reviewRating =
            conversationItem.mostRecentEvent.reviewEventData.rating ?? 5
        } else if (
          conversationEventTypePredicate(
            conversationItem.mostRecentEvent,
            'PUBLIC_REVIEW'
          )
        ) {
          reviewRating =
            conversationItem.mostRecentEvent.publicReviewEventData.rating ?? 5
        }
      }

      if (siteId === Constants.ReviewSites.GOOGLE.id) {
        ReviewFeedbackConversationalIcon = (
          <GoogleConversationalIcon {...iconProps} />
        )
      } else if (siteId === Constants.ReviewSites.FACEBOOK.id) {
        ReviewFeedbackConversationalIcon = <FacebookIcon {...iconProps} />
      } else if (siteId === Constants.ReviewSites.YELP.id) {
        ReviewFeedbackConversationalIcon = (
          <YelpConversationalIcon {...iconProps} />
        )
      }
    } else if (isConversationResource(conversationItem)) {
      const { mostRecentEvent } = conversationItem

      if (conversationEventTypePredicate(mostRecentEvent, 'AGENTZ')) {
        Icon = <WebchatIcon fill={theme.colors.base_100} {...iconProps} />
      } else if (conversationEventTypePredicate(mostRecentEvent, 'ANGILEADS')) {
        Icon = <AngiLeadsIcon {...iconProps} />
      } else if (conversationEventTypePredicate(mostRecentEvent, 'FACEBOOK')) {
        Icon = <FacebookMessengerIcon {...iconProps} />
      } else if (conversationEventTypePredicate(mostRecentEvent, 'FEEDBACK')) {
        Icon = <FeedbackIcon {...iconProps} />
        const denominator = activeLocation?.feedbackRatingDenominator

        const { feedbackIcon, feedbackOutcomeText } = extractFeedbackOutcome(
          denominator ?? 5,
          true,
          undefined,
          mostRecentEvent.feedbackEventData.rating
        )

        ReviewFeedbackConversationalIcon = feedbackIcon
        feedbackDisplayText = feedbackOutcomeText
      } else if (conversationEventTypePredicate(mostRecentEvent, 'GOOGLE')) {
        Icon = <GoogleConversationalIcon {...iconProps} />
      } else if (
        conversationEventTypePredicate(mostRecentEvent, 'LIVE_RECEPTIONIST') ||
        conversationEventTypePredicate(mostRecentEvent, 'PHONE_CALL')
      ) {
        Icon = <PhoneIcon fill={theme.colors.base_100} {...iconProps} />
      } else if (conversationEventTypePredicate(mostRecentEvent, 'THUMBTACK')) {
        Icon = <ThumbtackIcon {...iconProps} />
      }
    }
  }

  const unread =
    isConversationResource(conversationItem) &&
    !conversationItem.isConversationRead

  const nameTitle = getConversationItemNameTitle(conversationItem)

  const preview = getConversationItemPreviewText(conversationItem)

  const previewError = getConversationItemPreviewErrorText(conversationItem)

  return (
    <>
      <StyledContainer
        active={selected}
        data-cy={`${unread ? 'unread-' : ''}conversation-item-${index ?? ''}`}
      >
        {editMode && (
          <Checkbox
            data-cy={`mh-conversations-pane-conversation-item-${
              index ?? ''
            }-checkbox`}
            name="checkbox"
            size="medium"
            disabled={isEditDisabled}
            style={{
              marginRight: theme.space(columnSpace),
            }}
            onChange={(e) => {
              toggleSelectConversation(conversationItem.id, e.target.checked)
            }}
            checked={checked}
          />
        )}
        <StyledContentContainer onClick={onClick} editMode={editMode}>
          <StyledContentRowContainer
            style={{ marginLeft: `-${theme.space(reservedUnreadSpace)}` }}
          >
            <StyledIconWrapper isVisible={unread} customSize={unreadIconSize}>
              <UnreadIcon
                height={theme.space(unreadIconSize)}
                width={theme.space(unreadIconSize)}
              />
            </StyledIconWrapper>
            <StyledIconWrapper>{Icon}</StyledIconWrapper>
            <StyledContactNameText
              data-cy={`mh-conversations-pane-conversation-item-${
                index ?? ''
              }-preview-name`}
            >
              {nameTitle}
            </StyledContactNameText>
            <StyledFormattedTimeText
              data-cy={`mh-conversations-pane-conversation-item-${
                index ?? ''
              }-preview-time`}
              style={{ marginLeft: 'auto' }}
            >
              {time}
            </StyledFormattedTimeText>
          </StyledContentRowContainer>

          {isReviewConversation && (
            <StyledContentRowContainer>
              <StyledIconWrapper>
                {ReviewFeedbackConversationalIcon!}
              </StyledIconWrapper>
              <StyledMessageTitleText>
                Wrote a {reviewRating!}-star review
              </StyledMessageTitleText>
              <div>
                {Array.from(Array(reviewRating!)).map((r, i) => (
                  <StarIcon
                    key={`mh-review-star-${index ?? ''}-${i}`}
                    fill={theme.colors.accent_1}
                    {...iconProps}
                  />
                ))}
              </div>
            </StyledContentRowContainer>
          )}

          {isConversationResource(conversationItem) &&
            conversationEventTypePredicate(
              conversationItem.mostRecentEvent,
              'FEEDBACK'
            ) && (
              <StyledContentRowContainer>
                <StyledIconWrapper>
                  {ReviewFeedbackConversationalIcon!}
                </StyledIconWrapper>
                <StyledMessageTitleText>
                  {feedbackDisplayText!}
                </StyledMessageTitleText>
              </StyledContentRowContainer>
            )}

          {preview && (
            <StyledPreviewMessageText
              data-cy={`mh-conversations-pane-conversation-item-${
                index ?? ''
              }-preview-message`}
            >
              {preview}
            </StyledPreviewMessageText>
          )}

          {previewError && (
            <StyledPreviewMessageErrorText
              data-cy={`mh-conversations-pane-conversation-item-${
                index ?? ''
              }-preview-error-message`}
            >
              {previewError}
            </StyledPreviewMessageErrorText>
          )}
        </StyledContentContainer>
      </StyledContainer>
      <StyledSeparator />
    </>
  )
}

export default ConversationListItem
