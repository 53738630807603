import React, { useRef } from 'react'
import styled from 'styled-components'

import PasswordLoginForm from 'src/components/Login/PasswordLoginForm'
import Card from 'src/stories/Card'
import Dropdown, { StyledNavigationItem } from 'src/stories/Dropdown'
import { Col, Row, SidebarContextProps } from 'src/stories/Layout'
import WafLayout from 'src/components/WafLayout'
import { SidebarRoutes } from 'src/stories/Sidebar'

const StyledArrow = styled.button(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  marginRight: theme.space(5),
  cursor: 'pointer',
}))

const StyledLabels = styled.span({
  fontSize: '1.5rem',
})

const StyledDropdownInnerContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.space(2)} 0`,
}))

const StyledDiv = styled.div(({ theme }) => ({
  marginBottom: theme.space(5),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}))

const StyledSpacer = styled.div(({ theme }) => ({
  height: theme.space(10),
  backgroundColor: theme.colors.primary_2,
  margin: `0 ${theme.space(1)}`,
  marginBottom: theme.space(5),
}))

const testRoutes: SidebarRoutes[] = [
  {
    groupTitle: 'Test group Title',
    routes: [
      {
        to: '#Button',
        label: 'Cta Button',
        button: true,
        buttonProps: {
          action: 'primary',
          label: 'Button',
        },
      },
      {
        to: '#Button',
        label: 'Cta Button',
        button: true,
        buttonProps: {
          action: 'primary',
          label: 'Button',
          outline: true,
        },
      },
      {
        to: '#Subheader',
        label: 'Subheader',
        subHeader: true,
      },
    ],
  },
  {
    groupTitle: 'My integrations',
    routes: [
      { to: '#connected', label: 'Connected' },
      { to: '#suggested', label: 'Suggested' },
    ],
  },
  {
    groupTitle: 'More',
    routes: [
      { to: '#messaging', label: 'Messaging' },
      { to: '#payments', label: 'Payments' },
      { to: '#other', label: 'Other' },
    ],
  },
]

const LayoutPageExample: React.FC = () => {
  const formRef = useRef<HTMLButtonElement>(null)
  const navRef = useRef<HTMLButtonElement>(null)

  return (
    <WafLayout
      sidebarTitle="Integrations"
      sidebarRoutes={testRoutes}
      styleOptions={{ withMultiLocationHeader: true }}
    >
      {/* You can use this pattern to access the location hash, and use it to handle the display of your layout content
      based on hash navigation. Or just use a normal JSX children. Both will work */}
      {({ locationHash }: SidebarContextProps) => (
        <>
          <StyledDiv>
            {/* Place the anchor element in the DOM and pass a react ref to it and to the right <Dropdown /> element. The anchor element should be a button */}
            <StyledLabels>Nav Elements</StyledLabels>
            <StyledArrow ref={navRef}>v</StyledArrow>
            <button ref={formRef}>Form</button>
          </StyledDiv>

          <Row>
            <Col>
              <Card title="Card Example">
                Card Content Some card content indeed
              </Card>
              <Card>{`You can use the locationHash to handle your layout content based on hash navigation: ${locationHash}`}</Card>
            </Col>
          </Row>

          <Row>
            <h2>As said by Ariana Grande, Side to side</h2>
          </Row>
          <Row>
            <Col>
              <Card>I've been here all night</Card>
            </Col>
            <Col>
              <Card>I've been here all day</Card>
            </Col>
          </Row>

          <Row>
            <h2>More examples</h2>
          </Row>

          <Row>
            <Col>
              <Card>1/3</Card>
            </Col>
            <Col>
              <Card>1/3</Card>
            </Col>
            <Col>
              <Card>1/3</Card>
            </Col>
          </Row>

          <Row>
            <Col w={2}>
              <Card>1/2</Card>
            </Col>
            <Col>
              <Card>1/4</Card>
            </Col>
            <Col>
              <Card>1/4</Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>1/4</Card>
            </Col>
            <Col w={2}>
              <Card>1/2</Card>
            </Col>
            <Col>
              <Card>1/4</Card>
            </Col>
          </Row>

          <Row>
            <Col w={3}>
              <Card>3/4</Card>
            </Col>
            <Col>
              <Card>1/4</Card>
            </Col>
          </Row>

          <Row>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
          </Row>

          <Row>
            <Col w={2} unspaced>
              <StyledSpacer>2/12</StyledSpacer>
            </Col>
            <Col w={4} unspaced>
              <StyledSpacer>4/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
            <Col unspaced>
              <StyledSpacer>1/12</StyledSpacer>
            </Col>
          </Row>

          <Card title="Don't want to use Rows and Cols?">
            It "might" work. Not recommended, just don't do it..
          </Card>

          <Dropdown anchor={formRef}>
            <StyledDropdownInnerContainer>
              <PasswordLoginForm />
            </StyledDropdownInnerContainer>
          </Dropdown>
          <Dropdown anchor={navRef}>
            <StyledDropdownInnerContainer>
              <StyledNavigationItem to="#account">Account</StyledNavigationItem>
              <StyledNavigationItem to="#comm-preferences">
                Communication Preferences
              </StyledNavigationItem>
              <StyledNavigationItem to="#logout">Logout</StyledNavigationItem>
            </StyledDropdownInnerContainer>
          </Dropdown>
        </>
      )}
    </WafLayout>
  )
}

export default LayoutPageExample
