import styled from 'styled-components'
import Input from 'src/stories/Input'

export const BottomSpace = styled.div(({ theme }) => ({
  height: theme.space(3),
}))

export const StyledCellAnchor = styled.a(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  textWrap: 'nowrap',
  color: theme.colors.primary_2,
}))

export const StyledCheckbox = styled(Input)(({ theme }) => ({
  height: theme.space(5),
  width: theme.space(5),
  marginBottom: '0px',
}))

export const CheckboxContainer = styled.div(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}))
