import styled, { DefaultTheme } from 'styled-components'

import {
  BodyProps,
  HeadingProps,
  SubtitleProps,
  TextProps,
  BaseProps,
} from './Typography.types'

const BaseStyled = styled.p<BaseProps>(({ textAlign = 'left', ellipsis }) => {
  const base = {
    textAlign,
    margin: 0,
  }

  if (ellipsis)
    return {
      ...base,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }

  return { ...base }
})

export const TextStyled = styled(BaseStyled)<TextProps>(
  ({
    theme,
    fontSize = theme.space(3),
    fontWeight,
    textTransform,
    customColor,
    color = 'medium',
  }) => {
    let colorValue

    if (color) {
      const isTypographyColor = Object.keys(theme.typography.colors).includes(
        color
      )

      colorValue = isTypographyColor
        ? theme.typography.colors[
            color as keyof DefaultTheme['typography']['colors']
          ]
        : theme.colors[color as keyof DefaultTheme['colors']]
    }

    return {
      fontSize,
      fontWeight: fontWeight ? theme.typography.weights[fontWeight] : undefined,
      textTransform,
      color: customColor ? theme.colors[customColor] : colorValue,
    }
  }
)

export const HeadingStyled = styled(BaseStyled)<HeadingProps>(
  ({ theme, size = 'medium' }) => ({
    ...theme.typography.heading[size],
  })
)

export const SubtitleStyled = styled(BaseStyled)<SubtitleProps>(
  ({ theme, size = 'medium' }) => ({
    ...theme.typography.subtitle[size],
  })
)

export const BodyStyled = styled(BaseStyled)<BodyProps>(
  ({ theme, size = 'medium', fontWeight, textTransform, color }) => {
    let colorValue

    if (color) {
      const isTypographyColor = Object.keys(theme.typography.colors).includes(
        color
      )

      colorValue = isTypographyColor
        ? theme.typography.colors[
            color as keyof DefaultTheme['typography']['colors']
          ]
        : theme.colors[color as keyof DefaultTheme['colors']]
    }

    return {
      ...theme.typography.body[size],
      fontWeight: fontWeight ? theme.typography.weights[fontWeight] : undefined,
      textTransform,
      ...(colorValue && { color: colorValue }),
    }
  }
)
