import React from 'react'
import Constants from 'src/lib/Constants'
import styled from 'styled-components'

type Props = {
  integrationName: string
}

const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledTitle = styled.span(({ theme }) => ({
  fontSize: '1.6rem',
  fontWeight: 450,
}))

const StyledLabel = styled.span(({ theme }) => ({
  color: theme.colors.base_40,
  marginTop: theme.space(3),
  marginBottom: theme.space(3),
}))

const StyledBlueLabel = styled.u(({ theme }) => ({
  color: theme.colors.primary_2,
  fontWeight: 450,
}))

const ConnectionFailedModal: React.FC<Props> = ({ integrationName }) => {
  return (
    <Container>
      <StyledTitle>{`Unable to connect ${integrationName}`}</StyledTitle>

      <StyledLabel>
        {`There was an error trying to connect to your ${integrationName} account.`}
      </StyledLabel>
      <StyledLabel>
        {`For assistance, please contact our support team at `}
        <StyledBlueLabel>(855) 606-4900</StyledBlueLabel>
        {` or email `}
        <StyledBlueLabel>
          {Constants.Branding.companySupportEmail}
        </StyledBlueLabel>
        .
      </StyledLabel>
    </Container>
  )
}

export default ConnectionFailedModal
