import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const StyledAnchor = styled.a<Pick<AnchorProps, 'disabled'>>(
  ({ disabled }) => ({
    cursor: disabled ? 'auto' : 'pointer',
  })
)

export interface AnchorProps {
  /**
   * Attribute specifies the URL of the page the link goes to
   */
  href: string
  /**
   * Specifies where to open the linked document
   */
  target?: string
  /**
   * Defines the relationship between the current document and the linked document.
   * Utilizes a default value when the target is setted for enhanced security against window.opener exploitation attacks.
   */
  rel?: string
  /**
   * Makes click disabled and cursor auto
   */
  disabled?: boolean
  /**
   * Style properties to customize the Anchor
   */
  style?: React.CSSProperties
}

const Anchor: React.FCWithChildren<AnchorProps> = ({
  children,
  href,
  target,
  rel,
  disabled,
  ...props
}) => {
  const navigate = useNavigate()

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (disabled || target) return

      e.preventDefault()

      if (href.startsWith('mailto:') || href.startsWith('tel:')) {
        window.location.href = href
      } else {
        navigate(href)
      }
    },
    [disabled, href, navigate, target]
  )

  return (
    <StyledAnchor
      disabled={disabled}
      href={disabled ? undefined : href}
      target={target}
      rel={rel ? rel : target ? 'noopener noreferrer' : undefined}
      onClick={handleClick}
      {...props}
    >
      {children}
    </StyledAnchor>
  )
}

export default Anchor
