import { flexRender } from '@tanstack/react-table'
import React from 'react'
import styled from 'styled-components'

import TableZeroState from 'src/containers/Campaigns/Conversion/YourCampaigns/components/TableZeroState'
import { useYourCampaignsTable } from 'src/containers/Campaigns/Conversion/YourCampaigns/hooks/useYourCampaignsTable'
import { SortIcon } from 'src/stories/assets'
import { TBody, THead, Table, Td, Th, Tr } from 'src/stories/table'

interface Props {
  baseDataAttribute: string
}

const HeaderContentContainer = styled.div<{ clickable: boolean }>(
  ({ clickable }) => ({
    cursor: clickable ? 'pointer' : 'default',
  })
)

const StyledSortAscIcon = styled(SortIcon)(({ theme }) => ({
  marginLeft: theme.space(3),
  fill: theme.colors.base_50,
}))

const StyledSortDescIcon = styled(SortIcon)(({ theme }) => ({
  marginLeft: theme.space(3),
  transform: 'rotate(180deg)',
  fill: theme.colors.base_50,
}))

const YourCampaigns: React.FC<Props> = ({ baseDataAttribute }) => {
  const { columnsCount, table } = useYourCampaignsTable([])

  return (
    <div>
      <Table data-cy={baseDataAttribute + '-table'} rounded>
        <THead data-cy={baseDataAttribute + '-table-header'}>
          {table.getHeaderGroups().map((group) => (
            <Tr backgroundColor="base_10" key={group.id}>
              {group.headers.map((column) => (
                <Th
                  key={column.id}
                  style={{ width: column.getContext().column.getSize() }}
                >
                  {column.isPlaceholder ? null : (
                    <HeaderContentContainer
                      clickable={column.column.getCanSort()}
                      key={column.id}
                      onClick={column.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        column.column.columnDef.header,
                        column.getContext()
                      )}
                      {{
                        asc: <StyledSortAscIcon />,
                        desc: <StyledSortDescIcon />,
                      }[column.column.getIsSorted() as string] ?? null}
                    </HeaderContentContainer>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </THead>
        <TBody data-cy={baseDataAttribute + '-table-body'}>
          {table.getRowModel().rows.length ? (
            table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td key={cell.id} padding={4}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  )
                })}
              </Tr>
            ))
          ) : (
            <Tr data-cy={baseDataAttribute + '-table-body-no-results'}>
              <TableZeroState colSpan={columnsCount} />
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  )
}

export default YourCampaigns
