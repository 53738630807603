import { AxiosInstance } from 'axios'

import { AuthRequests } from '../interfaces/Authentication'
import Resource from '../resource'
import Constants from '../../lib/Constants'

const actions = (client: AxiosInstance): AuthRequests => ({
  login: ({ email, password, rememberMe }) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Auth',
      method: 'login',
      params: {
        email,
        password,
        rememberMe,
      },
    })
  },

  logout: () => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Auth',
      method: 'logout',
    })
  },

  loginOtp: ({ otpEmailFingerprint }) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Auth',
      method: 'loginOtp',
      params: {
        otpEmailFingerprint,
        rememberMe: true,
      },
    })
  },

  grant: (params) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Auth',
      method: 'grant',
      params,
    })
  },

  forgotPassword: (params) =>
    client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Auth',
      method: 'forgotPassword',
      params,
    }),
})

export default Resource(actions)
