import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useAuthContext from 'src/contexts/AuthContext'
import useAccountContext from 'src/contexts/AccountContext'
import { handleRedirection } from '../Login'

const Home: React.FC = () => {
  const { search } = useLocation()
  const { user } = useAuthContext()
  const { locations } = useAccountContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (locations && user) {
      handleRedirection({
        search,
        userLocations: locations,
        authorizedUser: user,
      })
    } else {
      navigate('/login')
    }
  }, [locations, user, search, navigate])

  return null
}

export default Home
