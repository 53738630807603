import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Socket } from 'socket.io-client'
import { useLocation } from 'react-router-dom'
import { compareDesc } from 'date-fns'
import { QueryObserverResult } from '@tanstack/react-query'
import { useDebounce } from 'use-debounce'

import { WebSocketPageEvent } from 'src/client/interfaces/Common'
import {
  countUnreadConversations,
  isAnyConversationUnread,
  playNotificationAudio,
  createZeroState,
  reduceSingleReviewFeedbackConversationList,
  getConversationItemTimestamp,
} from 'src/contexts/ConversationsListContext/utils'

import {
  useConversationsQuery,
  usePublicReviewsQuery,
  useSetConversationReadMutation,
  useSetConversationArchivedMutation,
} from 'src/client'
import useAuthContext from 'src/contexts/AuthContext'
import { useWebsockets } from 'src/contexts/ConversationsListContext/hooks'
import { EmptyFn } from 'src/utils/interfaces'
import useNavigationInterceptorContext from 'src/contexts/NavigationInterceptor'
import { ModalNotificationsContextProvider } from 'src/contexts/ModalNotificationsContext'
import {
  ConversationsResponseDTO,
  PublicReviewsResponseDTO,
} from 'src/client/interfaces/Conversations'
import { isConversationResource, ConversationListItem } from './types'
import { useLocationContext } from '../LocationContext'
import Constants from 'src/lib/Constants'

export interface ConversationsContextType {
  /** List of conversations to be displayed. When filter is applied, this is updated. */
  conversationsList: ConversationListItem[]
  /** Search string to filter the conversation string */
  conversationListFilter: string
  /** Flag use to prevent updates in the UI when there are requests in flight */
  preventUiUpdates: boolean
  isLoading: boolean
  isListTruncated: boolean
  socket?: Socket
  openConversationId?: number
  getConversationListItem: (id: number) => ConversationListItem | undefined
  mutateConversationRead: ReturnType<
    typeof useSetConversationReadMutation
  >['mutate']
  mutateConversationArchived: ReturnType<
    typeof useSetConversationArchivedMutation
  >['mutate']
  setPreventUiUpdates: Dispatch<SetStateAction<boolean>>
  setConversationListFilter: Dispatch<SetStateAction<string>>
  refetch: () => Promise<
    [
      QueryObserverResult<ConversationsResponseDTO, unknown>,
      QueryObserverResult<PublicReviewsResponseDTO, unknown>
    ]
  >
  setOpenConversationId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
}

const ConversationsListContext = createContext<ConversationsContextType>({
  conversationsList: [],
  conversationListFilter: '',
  preventUiUpdates: false,
  socket: undefined,
  isLoading: false,
  isListTruncated: false,
  openConversationId: undefined,
  getConversationListItem: () => undefined,
  mutateConversationRead: EmptyFn,
  mutateConversationArchived: EmptyFn,
  setPreventUiUpdates: EmptyFn,
  setConversationListFilter: EmptyFn,
  refetch: () =>
    Promise.resolve([
      {} as QueryObserverResult<ConversationsResponseDTO, unknown>,
      {} as QueryObserverResult<PublicReviewsResponseDTO, unknown>,
    ]),
  setOpenConversationId: EmptyFn,
})

export const ConversationsContextProvider: React.FCWithChildren = ({
  children,
}) => {
  // Hooks initialization
  const { user } = useAuthContext()
  const { search } = useLocation()
  const { activeLocation, locationId } = useLocationContext()
  const { pageTitle } = useNavigationInterceptorContext()
  const { socket, join } = useWebsockets()

  // useRefs
  const notificationAudio = useRef(new Audio('/message-notification.mp3'))
  const initialCountIsSet = useRef(false)
  const hasChangedFromInitialValue = useRef(false)
  const initialUnreadConversationsCount = useRef(0)

  // useStates
  const [conversationListFilter, setConversationListFilter] = useState('')
  const [openConversationId, setOpenConversationId] = useState(() => {
    const searchParam = new URLSearchParams(search).get('open-conversation')

    return !!searchParam ? parseInt(searchParam) : undefined
  })

  // preventUiUpdates should be used to prevent the browser from doing UI Updates
  // when there are requests in flight. e.g. a messaging being sent
  // OBS: useTransition should be used instead
  const [preventUiUpdates, setPreventUiUpdates] = useState(false)

  const [debouncedFilter] = useDebounce(conversationListFilter, 1000)

  const {
    data: conversationsData,
    isLoading: conversationsDataIsLoading,
    refetch: refetchConversationsData,
  } = useConversationsQuery({
    locationId,
    pagination: {},
    search: { search: debouncedFilter },
  })

  const {
    data: publicReviewsData,
    isLoading: publicReviewsDataIsLoading,
    refetch: refetchPublicReviewsData,
  } = usePublicReviewsQuery({
    locationId,
    pagination: {},
  })

  const { mutate: mutateConversationRead } = useSetConversationReadMutation()
  const { mutate: mutateConversationArchived } =
    useSetConversationArchivedMutation()

  const isLoading = useMemo(
    () => conversationsDataIsLoading || publicReviewsDataIsLoading,
    [conversationsDataIsLoading, publicReviewsDataIsLoading]
  )

  const isListTruncated = useMemo(
    () =>
      !conversationsDataIsLoading &&
      // NOTE: due to a bug in the conversations endpoint, the total is not the total
      // conversations in the DB, but just the number returned, so if it is equal to the
      // number we requested, (or larger if main-api endpoint gets fixed), then we want to
      // show that the list is truncated.
      /*(conversationsData?.data ?? []).length >*/ 200 <=
        (conversationsData?.total ?? 0),
    [conversationsDataIsLoading, conversationsData]
  )

  const conversationsList = useMemo(() => {
    let conversationList: ConversationListItem[] = [
      ...(conversationsData?.data ?? []),
      ...(debouncedFilter === ''
        ? publicReviewsData?.data?.filter((pr) => !pr.hiddenFromUi) ?? []
        : []),
    ]

    if (
      process.env.REACT_APP_REDUCE_CONVERSATION_LIST === 'true' &&
      !!conversationList
    ) {
      conversationList =
        reduceSingleReviewFeedbackConversationList(conversationList)
    }

    const zeroState = createZeroState(user.firstName ?? '', true)

    conversationList = Array.from(conversationList).sort((a, b) => {
      const timestampA = getConversationItemTimestamp(a)
      const timestampB = getConversationItemTimestamp(b)

      return compareDesc(timestampA, timestampB)
    })

    return [zeroState, ...conversationList]
  }, [
    conversationsData?.data,
    debouncedFilter,
    publicReviewsData?.data,
    user.firstName,
  ])

  // useEffects
  /**
   * WebSocket handling: connection, new data to fetch listener.
   */
  useEffect(() => {
    join({ userId: user.id, locationId: activeLocation.locationId })

    const onPage = async (event: WebSocketPageEvent) => {
      let shouldPlayNotificationAudio = false

      const eventData = event.reduce<
        {
          id: string
          contactId: number
        }[]
      >(
        (a, c) =>
          a.concat(
            ...c.documents.map(({ id, contactId }) => ({ id, contactId }))
          ),
        []
      )

      const { data: newConversationsListData } =
        await refetchConversationsData()

      const { data: newPublicReviewsData } = await refetchPublicReviewsData()

      if (newConversationsListData) {
        const contacts = eventData.map(({ contactId }) => contactId)
        const newConversations = newConversationsListData.data.filter((c) =>
          contacts.includes(c.contactId)
        )

        shouldPlayNotificationAudio = newConversations.some(
          (c) => c.mostRecentEvent.eventDirection === 'incoming'
        )
      }

      if (newPublicReviewsData) {
        const ids = eventData.map(({ id }) => id)

        const newPublicPreviews = newPublicReviewsData.data.filter((p) =>
          ids.includes(`${p.id}`)
        )

        shouldPlayNotificationAudio = newPublicPreviews.some(
          (p) => (p.hiddenFromUi = false)
        )
      }

      if (shouldPlayNotificationAudio) {
        void playNotificationAudio(notificationAudio.current)
      }
    }

    socket.on('page', onPage)

    return () => void socket.off('page', onPage)
  }, [
    join,
    locationId,
    activeLocation,
    refetchConversationsData,
    refetchPublicReviewsData,
    socket,
    user,
  ])

  /**
   * `initialCountIsSet` initialization
   */
  useEffect(() => {
    if (
      !initialCountIsSet.current &&
      conversationsDataIsLoading &&
      conversationsList.length !== -1
    ) {
      initialUnreadConversationsCount.current = countUnreadConversations(
        conversationsList.filter(isConversationResource)
      )
      initialCountIsSet.current = true
    }
  }, [conversationsList, conversationsDataIsLoading])

  /**
   * New message notification handling
   */
  useEffect(() => {
    if (initialCountIsSet.current && !hasChangedFromInitialValue.current) {
      const initial = initialUnreadConversationsCount.current
      const current = countUnreadConversations(
        conversationsList.filter(isConversationResource)
      )

      if (initial !== current) {
        hasChangedFromInitialValue.current = true
      }
    }
  }, [conversationsList])

  /**
   * Document title update handling
   */
  useEffect(() => {
    let alertInterval: NodeJS.Timeout

    if (initialCountIsSet.current) {
      const conversationResources = conversationsList.filter(
        isConversationResource
      )

      const hasUnreadMessages = isAnyConversationUnread(conversationResources)
      const unreadConvosCount = countUnreadConversations(conversationResources)

      if (hasUnreadMessages && hasChangedFromInitialValue.current) {
        alertInterval = setInterval(() => {
          if (document.title === pageTitle) {
            document.title = `${unreadConvosCount} unread ${
              unreadConvosCount > 1 ? 'conversations' : 'conversation'
            }`
          } else {
            document.title = pageTitle
          }
        }, 1000)
      } else {
        document.title = pageTitle
      }
    } else {
      document.title = pageTitle
    }

    return () => {
      clearInterval(alertInterval)
      document.title = Constants.Branding.companyName
    }
  }, [conversationsList, pageTitle])

  /**
   * Functions
   */
  const getConversationListItem = useCallback(
    (id: number) => conversationsList.find((c) => c.id === id),
    [conversationsList]
  )

  const refetch = useCallback(
    () => Promise.all([refetchConversationsData(), refetchPublicReviewsData()]),
    [refetchConversationsData, refetchPublicReviewsData]
  )

  const contextValue: ConversationsContextType = {
    conversationsList,
    conversationListFilter,
    preventUiUpdates,
    isLoading,
    isListTruncated,
    socket,
    openConversationId,
    getConversationListItem,
    mutateConversationRead,
    mutateConversationArchived,
    setPreventUiUpdates,
    setConversationListFilter,
    refetch,
    setOpenConversationId,
  }

  return (
    <ConversationsListContext.Provider value={contextValue}>
      <ModalNotificationsContextProvider>
        {children}
      </ModalNotificationsContextProvider>
    </ConversationsListContext.Provider>
  )
}

/**
 * Use this hook to use data and methods to manipulate the **conversations list**
 */
const useConversationsListContext = () => useContext(ConversationsListContext)

export default useConversationsListContext
