import React, { memo } from 'react'
import styled, { CSSObject } from 'styled-components'

import { formatPhoneNumber } from 'src/utils'
import { ConversationEventResourceType } from 'src/client/interfaces/Conversations'

const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Section = styled.div<{
  removeBottom?: boolean
}>(({ removeBottom, theme }) => {
  const css: CSSObject = {}

  if (!removeBottom) {
    css.marginBottom = theme.space(5)
  }

  return css
})

const Label = styled.strong(() => ({ margin: 0 }))

const Text = styled.p(() => ({ margin: 0 }))
const Link = styled.a(() => ({ margin: 0, display: 'block' }))

const SubSection = styled.span(() => ({ margin: 0, display: 'block' }))

interface Props {
  message: ConversationEventResourceType<'ANGILEADS'>
  contactName: string
  phoneNumber?: string
  email?: string
}

const HomeAdvisorMessageContent: React.FC<Props> = memo(
  ({ message, contactName, email, phoneNumber }) => {
    const { angiLeadsEventData } = message

    return (
      <Container>
        <Section>
          <Label>Angi Summary:</Label>
        </Section>
        <Section>
          <Label>{contactName}</Label>
          {phoneNumber && (
            <Link href={`tel:${phoneNumber}`}>
              {formatPhoneNumber(phoneNumber)}
            </Link>
          )}
          {email && <Link href={`mailto:${email}`}>{email}</Link>}
        </Section>
        <Section removeBottom={!angiLeadsEventData.comments}>
          <SubSection style={{ display: 'block' }}>
            <Label>Job Description: </Label>
            <Text style={{ display: 'inline' }}>
              {angiLeadsEventData.taskName}
            </Text>
          </SubSection>
          <SubSection style={{ display: 'block' }}>
            <Label>Lead Type: </Label>
            <Text style={{ display: 'inline' }}>
              {angiLeadsEventData.leadDescription}
            </Text>
          </SubSection>
        </Section>
        <Section removeBottom>
          <Text>{angiLeadsEventData.comments}</Text>
        </Section>
      </Container>
    )
  }
)

export default HomeAdvisorMessageContent
