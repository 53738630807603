import { datadogRum } from '@datadog/browser-rum'

if (
  process.env.REACT_APP_DD_APP_ID &&
  process.env.REACT_APP_DD_CLIENT_TOKEN &&
  process.env.REACT_APP_ENVIRONMENT
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APP_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    env: process.env.REACT_APP_ENVIRONMENT,
    version: process.env.REACT_APP_SOURCE_MAP_VERSION,
    site: 'datadoghq.com',
    service: 'web-app-frontend',

    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackSessionAcrossSubdomains: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
    actionNameAttribute: 'data-dd',
  })

  datadogRum.startSessionReplayRecording()
}
