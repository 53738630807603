import { useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import NotFound from 'src/components/NotFound'
import useAuthContext from 'src/contexts/AuthContext'
import { RouteParams, RouteParamsKeys } from 'src/utils/interfaces'
import { isInternalUser } from 'src/utils'
import logger from 'src/utils/logger'

interface PrivateRouteProps {
  validateRouteParams?: RouteParamsKeys[]
  allowedParams: RouteParams[]
}

export const PrivateRoute: React.FCWithChildren<PrivateRouteProps> = ({
  validateRouteParams = [],
  allowedParams = [],
  children,
}) => {
  const { user, handleTokenLogout } = useAuthContext()
  const params = useParams()

  useEffect(() => {
    if (!user) {
      logger.debug('Not logged in, handling logout')
      handleTokenLogout()
    }
  }, [handleTokenLogout, user])

  const hasRouteParam = allowedParams.some((allowedParam) =>
    validateRouteParams.every(
      /**
       * Ignoring this TS warning because allowedParams will be any key from RouteParams,
       * which, for now, is LocationRouteParams or UserRouteParams.
       * Since the string will have values that will no overlap or be key of the interface
       * TS reports the warning. This will not be an issue since the props are related to each
       * other.
       */
      (routeParam) =>
        // @ts-expect-error See description above
        allowedParam[routeParam] &&
        // @ts-expect-error See description above
        allowedParam[routeParam] === params[routeParam]
    )
  )

  if (!user) return <Navigate to="/login" />

  if (isInternalUser(user) || hasRouteParam) {
    return <>{children}</>
  }

  return <NotFound />
}
