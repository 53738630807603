import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import styled, { useTheme } from 'styled-components'

import { Button } from 'src/stories/Button'
import {
  StyledMainStatContainer,
  StyledWidgetContentContainer,
  StyledWidgetMainStat,
} from 'src/components/Insights/Widgets/styled'
import { generateLocationUrl } from 'src/utils'
import { InsightsResource } from 'src/client'
import ProgressBar from 'src/stories/ProgressBar'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import { WidgetProps } from 'src/components/Insights/Widgets//types'
import { InsightsWidget } from 'src/components/Insights/Widgets/InsightsWidget'
import { ReactComponent as ZeroStateBanner } from 'src/assets/total-instant-response-sent-zero-state.svg'
import { mockDemoTotalInstantResponsesSent } from 'src/contexts/InsightsContext/demo-data'

const StyledText = styled.p<{ colored?: boolean }>(
  ({ theme, colored = false }) => ({
    fontWeight: 500,
    fontSize: '1.4rem',
    color: colored ? theme.colors.primary_1 : theme.colors.base_40,
    margin: 0,
    marginBottom: theme.space(2),
  })
)

const StyledZeroText = styled.p(({ theme }) => ({
  fontWeight: 400,
  fontSize: '1.4rem',
  textAlign: 'center',
  width: '95%',
  margin: 0,
  marginTop: theme.space(4),
  marginBottom: theme.space(6),
}))

const StyledZeroContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.space(2),
}))

const StyledProgressContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: `${theme.space(9)} 0 ${theme.space(2)} 0`,
}))

const StyledProgressDataContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.space(4),
  justifyContent: 'space-between',
}))

const TotalInstantResponsesSentWidget: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const { locationId, merchantId } = useParams<UseLocationRouteParams>()

  const [respondedPercentage, setRespondedPercentage] = useState(0)

  const isDemoLocation =
    !!locationId && locationId === process.env.REACT_APP_DEMO_LOCATION_ID

  const { data, isError, isLoading } = useQuery({
    queryKey: [
      'instantResponsesTotalInstantResponsesSent',
      {
        locationId,
      },
    ],
    queryFn: async () => {
      let resourceData =
        await InsightsResource.getInstantResponsesTotalAndReplied(+locationId!)

      if (isDemoLocation) {
        resourceData = mockDemoTotalInstantResponsesSent
      }

      setRespondedPercentage(
        (resourceData?.totalInstantResponsesRepliedByUser * 100) /
          resourceData?.totalInstantResponsesSent
      )

      return resourceData
    },
    staleTime: 60_000,
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <InsightsWidget
      addDivider
      baseDataAttribute={baseDataAttribute}
      title="Total Instant Response sent"
      subtitle={
        isError || data?.totalInstantResponsesSent === 0 ? undefined : (
          <StyledMainStatContainer
            data-cy={baseDataAttribute + '-main-stat-container'}
          >
            <StyledWidgetMainStat data-cy={baseDataAttribute + '-main-stat'}>
              {data?.totalInstantResponsesSent}
            </StyledWidgetMainStat>
          </StyledMainStatContainer>
        )
      }
    >
      <StyledWidgetContentContainer
        data-cy={baseDataAttribute + '-review-sites'}
      >
        {isError || data?.totalInstantResponsesSent === 0 ? (
          <StyledZeroContainer>
            <ZeroStateBanner style={{ marginTop: theme.space(2) }} />
            <StyledZeroText>
              78% of jobs go to the business that responds first. Reply to every
              lead without lifting a finger with Instant Responders.
            </StyledZeroText>
            <Button
              label="Set up Instant Responders"
              baseDataAttribute={baseDataAttribute + '-to-instant-responders'}
              href={generateLocationUrl(
                merchantId!,
                locationId!,
                `/settings#section-settings-instant-responders`
              )}
              style={{
                maxWidth: theme.space(55),
              }}
            />
          </StyledZeroContainer>
        ) : (
          <>
            <Button
              label="➜ View and reply to messages"
              baseDataAttribute={baseDataAttribute + '-to-messaging-hub'}
              href={generateLocationUrl(
                merchantId!,
                locationId!,
                `/messaging-hub`
              )}
              displayAsText
              style={{
                height: theme.space(5),
              }}
            />

            <StyledProgressContainer>
              <ProgressBar
                color="primary_2"
                progress={respondedPercentage}
                heightUnits={4}
                dataCy={baseDataAttribute + '-progress-bar'}
              />

              <StyledProgressDataContainer>
                <div>
                  <StyledText>Responded to:</StyledText>
                  <StyledText
                    colored
                  >{`${data?.totalInstantResponsesRepliedByUser} (${respondedPercentage}%)`}</StyledText>
                </div>

                <div>
                  <StyledText>Messages received:</StyledText>
                  <StyledText
                    colored
                  >{`${data?.totalInstantResponsesSent} (100%)`}</StyledText>
                </div>
              </StyledProgressDataContainer>
            </StyledProgressContainer>
          </>
        )}
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default TotalInstantResponsesSentWidget
